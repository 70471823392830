import { inject } from 'mobx-react';
import React from 'react';

import VideoConference from 'src/components/pages/pageElements/videoConference';

/**
 * Wrapper around a VideoConference that fetches metadata
 */
class ChatVc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
    };
  }

  async componentDidMount() {
    const message = await this.props.rootStore.chat.getMessage(
      this.props.rootStore.routerStore.routerState.params.message,
    );
    this.setState({ message });
  }

  render() {
    const { message } = this.state;

    if (!message) {
      return null;
    }

    return <VideoConference sessionId={message.vc.sessionId} token={message.vc.token} />;
  }
}

export default inject('rootStore')(ChatVc);
