import gql from 'graphql-tag';

import { CLAIM_ROW } from 'src/stores/fragments/claims';

export const LOAD_CLAIM_ROWS = gql`
  query ClaimRows($first: Int, $skip: Int, $where: ClaimWhereInput) {
    items: claims(where: $where, paging: { first: $first, skip: $skip }) {
      ...ClaimRow
    }
  }

  ${CLAIM_ROW}
`;
