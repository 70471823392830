import { chartPropertyContext } from '@boulder-package/expressions';

import { evaluateConditions } from 'src/nightingale/conditionals';
import { convertChartProperty } from 'src/nightingale/summarization/conversion';
import type { ListChartProperty } from 'src/nightingale/types/types';

/**
 * Gets a calculated conditional map for a List Chart Property item (i.e. one item of many in the
 * list), using a context that only contains a `$currentItem` for the given list item value.
 */
export const getConditionalMap = (
  definition: Pick<ListChartProperty, 'properties' | 'conditions'>,
  currentItemValues: {
    [key: string]: any;
  },
): Record<string, boolean> => {
  // Compile a special reference to this current item's values that we can
  // include in the ConditionalContext, so that the service designer can
  // reference this item's values without knowing what index it has.
  const currentItem = chartPropertyContext(
    definition.properties.map(convertChartProperty),
    Object.keys(currentItemValues).map(propertyName => ({
      isEmpty: null,
      notes: null,
      propertyName,
      value: currentItemValues[propertyName],
    })),
  );

  return evaluateConditions(definition.conditions, { $currentItem: currentItem });
};
