import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';

const ActionsPopup = ({ actions = [], parentElement, onClose }) => (
  <Menu
    open
    onClose={() => onClose()}
    anchorEl={parentElement}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  >
    {actions.map(action => {
      if (action.isDisplayed) {
        const IconComponent = action.iconComponent;
        return (
          <Tooltip title={action.tooltip || ''} placement="top" key={action.label}>
            <div style={{ outline: 'none' }}>
              <MenuItem onClick={() => action.onClick?.()} disabled={action.isDisabled}>
                <ListItemIcon>
                  <IconComponent />
                </ListItemIcon>
                <ListItemText primary={action.label} />
              </MenuItem>
            </div>
          </Tooltip>
        );
      } else {
        return null;
      }
    })}
  </Menu>
);

export default ActionsPopup;
