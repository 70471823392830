import { draftJsToTaggedText, taggedTextToDraftJs } from 'src/util/taggedText';

const isEmpty = res => {
  return !res || !Object.keys(res);
};

const walkSchema = (
  schema,
  model,
  startField,
  shouldApplyTransform,
  transformCb,
  excludeIfEmpty,
) => {
  // Walk the list of subfields (if startField is empty then getSubfields returns all of the top
  // level fields) and return a new object where fields are transformed using the
  // supplied shouldApplyTransform and transformCb.
  /* eslint-disable no-param-reassign */
  return schema.getSubfields(startField).reduce(
    (newModel, field) => {
      const fullFieldKey = startField ? `${startField}.${field}` : field;
      const fieldSpec = schema.getField(fullFieldKey);
      if (shouldApplyTransform(fieldSpec, model, field)) {
        // Transform the model
        newModel[field] = transformCb(model[field], fullFieldKey, schema, excludeIfEmpty);
      } else if (
        fieldSpec.type === 'object' &&
        fieldSpec.properties &&
        schema.getSubfields(fullFieldKey).length &&
        model[field]
      ) {
        // Recurse the subfields
        newModel[field] = walkSchema(
          schema,
          model[field],
          fullFieldKey,
          shouldApplyTransform,
          transformCb,
          excludeIfEmpty,
        );
      }

      if (isEmpty(newModel[field]) && excludeIfEmpty) {
        delete newModel[field];
      }

      return newModel;
    },
    { ...model },
  );
  /* eslint-enable */
};

const walkSelectWithDependentsSchema = (schema, model, startField, transformCb, excludeIfEmpty) => {
  // Walk the list of subfields (if startField is empty then getSubfields returns all of the top
  // level fields) and return a new object where any 'selectable' fields are transformed using the
  // supplied transformCb.
  const shouldApplyTransform = (fieldSpec, subModel, field) =>
    fieldSpec.format === 'selectable' && subModel[field];
  return walkSchema(schema, model, startField, shouldApplyTransform, transformCb, excludeIfEmpty);
};

const walkTaggedTextSchema = (schema, model, transformCb) => {
  // Walk the list of subfields and return a new object where any 'taggedText' fields are
  // transformed using the supplied transformCb.
  const shouldApplyTransform = (fieldSpec, subModel, field) =>
    fieldSpec.taggedText && subModel[field];
  return walkSchema(schema, model, null, shouldApplyTransform, transformCb);
};

const flatten = (model, fullFieldKey, schema, excludeIfEmpty) => {
  // Take the first key found in the form value. If there are multiple keys (which there should
  // never be if using SelectWithDependents) then the other keys will be ignored.
  const selection = Object.keys(model).pop();
  return selection
    ? {
        selectionKey: selection,
        additional:
          // If the field doesn't have any children (which is the case when a select option doesn't
          // have any subfields) then just return null. Otherwise, recurse over the children.
          !model[selection] ||
          (model[selection].constructor === Object && Object.entries(model[selection]).length === 0)
            ? null
            : walkSelectWithDependentsSchema(
                schema,
                model[selection],
                `${fullFieldKey}.${selection}`,
                flatten,
                excludeIfEmpty,
              ),
      }
    : null;
};

const explode = (subModel, fullFieldKey, schema) => {
  // If the selectionKey doesn't exist then we're dealing with an object structure that isn't
  // recognized (which can happen when this function gets called multiple times with the same
  // object) so pass the original value back.
  if (subModel.selectionKey) {
    const res = {};
    if (!isEmpty(subModel.additional)) {
      res[subModel.selectionKey] = walkSelectWithDependentsSchema(
        schema,
        subModel.additional,
        `${fullFieldKey}.${subModel.selectionKey}`,
        explode,
      );
    } else {
      res[subModel.selectionKey] = null;
    }
    return res;
  } else {
    return subModel;
  }
};

/**
 * Convert any 'selectable' fields from a structure of `{ [selectedKey]: { ... }}` to
 * `{ selectedKey: '...', additional: {...} }`
 */
export const fromSelectWithDependentsFieldFormat = (schema, model, excludeIfEmpty) =>
  walkSelectWithDependentsSchema(schema, model, null, flatten, excludeIfEmpty);

/**
 * Convert any 'selectable' fields from a structure of
 * `{ selectedKey: '...', additional: {...} }` to `{ [selectedKey]: { ... }}`
 */
export const toSelectWithDependentsFieldFormat = (schema, model) =>
  walkSelectWithDependentsSchema(schema, model, null, explode);

export const taggedTextToDraftJsFieldFormat = (schema, model) =>
  walkTaggedTextSchema(schema, model, taggedTextToDraftJs);

export const taggedTextFromDraftJsFieldFormat = (schema, model) =>
  walkTaggedTextSchema(schema, model, draftJsToTaggedText);
