import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const PocTestingTabIconOutlined = ({ className }: { className: string }) => {
  return (
    <SvgIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path d="M12 22.0542C10.6167 22.0542 9.4375 21.5667 8.4625 20.5917C7.4875 19.6167 7 18.4375 7 17.0542V8.0542C6.45 8.0542 5.97917 7.85837 5.5875 7.4667C5.19583 7.07503 5 6.6042 5 6.0542V4.0542C5 3.5042 5.19583 3.03337 5.5875 2.6417C5.97917 2.25003 6.45 2.0542 7 2.0542H17C17.55 2.0542 18.0208 2.25003 18.4125 2.6417C18.8042 3.03337 19 3.5042 19 4.0542V6.0542C19 6.6042 18.8042 7.07503 18.4125 7.4667C18.0208 7.85837 17.55 8.0542 17 8.0542V17.0542C17 18.4375 16.5125 19.6167 15.5375 20.5917C14.5625 21.5667 13.3833 22.0542 12 22.0542ZM7 6.0542H17V4.0542H7V6.0542ZM12 20.0542C12.8333 20.0542 13.5417 19.7625 14.125 19.1792C14.7083 18.5959 15 17.8875 15 17.0542H12V15.0542H15V13.0542H12V11.0542H15V8.0542H9V17.0542C9 17.8875 9.29167 18.5959 9.875 19.1792C10.4583 19.7625 11.1667 20.0542 12 20.0542Z" />
    </SvgIcon>
  );
};

export default PocTestingTabIconOutlined;
