import { format, lastDayOfMonth, startOfMonth, subMonths } from 'date-fns';

import type { Provider as ProviderInstance } from 'src/shared/stores/resource';
import { CLINICIAN_EVENT_SUBTYPES, RN_EVENT_SUBTYPES } from 'src/shared/util/events';

export function patientsQueryParamsForUser(user: ProviderInstance | null) {
  return user?.teamRolePropertyName ? { [user.teamRolePropertyName]: user.id } : {};
}

export function claimsQueryParams() {
  const lastMonth = subMonths(new Date(), 1);
  const startDate = format(startOfMonth(lastMonth), 'yyyy-MM-dd');
  const endDate = format(lastDayOfMonth(lastMonth), 'yyyy-MM-dd');
  return {
    startDate,
    endDate,
    eventSubType: [...CLINICIAN_EVENT_SUBTYPES, ...RN_EVENT_SUBTYPES],
    meta_status: ['needs-update', 'rejected'],
  };
}

export function myPebblesQueryParams(user: ProviderInstance) {
  return { assignee: user.id, status: ['new', 'acknowledged', 'on_hold', 'in_progress'] };
}
