import { TableHead } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { format } from 'date-fns';
import React, { useState, useCallback } from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import { Duration } from 'src/dropInClinic/Duration';
import { PatientDetails } from 'src/dropInClinic/PatientDetails';
import { ProviderName } from 'src/dropInClinic/ProviderName';
import { RequestType } from 'src/dropInClinic/RequestType';
import type { CompletedVisit } from 'src/dropInClinic/hooks/useCompletedVisits';
import { useInterval } from 'src/dropInClinic/hooks/useInterval';
import { useStyles } from 'src/dropInClinic/styles';

export const CompletedVisitTable = ({ completedVisits }: { completedVisits: CompletedVisit[] }) => {
  const classes = useStyles();
  const [currentTime, setCurrentTime] = useState(new Date());
  const tick = useCallback(() => {
    setCurrentTime(new Date());
  }, [setCurrentTime]);
  useInterval(tick, 60000);

  return (
    <div className={classes.root}>
      {completedVisits.length > 0 && (
        <TableContainer>
          <Table aria-label="completed visits table">
            <TableHead>
              <TableRow>
                <TableCell>Patient</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Visit Type</TableCell>
                <TableCell style={{ textAlign: 'right' }}>Completion Time</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedVisits.map(entry => {
                const { categories, event, patient, provider, requestId, requestType } = entry;

                return (
                  <TableRow key={requestId}>
                    <TableCell>
                      <PatientDetails patient={patient} categories={categories} />
                    </TableCell>
                    <TableCell>
                      <ProviderName provider={provider ?? undefined} />
                    </TableCell>
                    <TableCell>
                      <RequestType requestType={requestType} />
                    </TableCell>
                    <TableCell align="right" className={classes.queueTime}>
                      {event?.completedAt && currentTime && (
                        <>
                          <Duration date={event.completedAt} currentTime={currentTime} /> ago
                        </>
                      )}
                      {event?.completedAt &&
                        !currentTime &&
                        format(event.completedAt, 'h:m a, MMM dd')}
                    </TableCell>
                    <TableCell>
                      {event?.id && (
                        <a href={generateRouteUrl('showEvent', { id: event?.id })}>View visit</a>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {completedVisits.length === 0 && (
        <div>
          <br />
          No completed visits today
        </div>
      )}
    </div>
  );
};
