import { withStyles } from '@material-ui/core';
import { Field as FormikField } from 'formik';
import React from 'react';

const Field = ({ classes, ...props }) => <FormikField className={classes.formControl} {...props} />;

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

export default withStyles(styles)(Field);
