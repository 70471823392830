import { observer } from 'mobx-react';
import React from 'react';

import PatientTasks from 'src/components/pages/pageElements/PatientTasks';
import PatientLogout from 'src/components/pages/pageElements/patientDebug/PatientLogout';
import SmsMessages from 'src/components/pages/pageElements/patientDebug/SmsMessages';
import WebMessenger from 'src/components/pages/pageElements/patientDebug/WebMessenger';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

const PatientDebug = ({ data, patientId }) => {
  return (
    <>
      <PageTitle>Patient Debug</PageTitle>
      {/* Error Links */}
      <div>
        <a href={data?.errorLink}>{data?.errorLink}</a>
      </div>
      {/* Logout Patient Button */}
      <PatientLogout patientId={patientId} />
      {/* Task Creator */}
      <PatientTasks patientId={patientId} />
      {/* SMS Messages */}
      <SmsMessages patientId={patientId} />
      {/* Web Messenger */}
      <WebMessenger patientId={patientId} />
    </>
  );
};

export default observer(PatientDebug);
