import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/styles';
import React, { useContext } from 'react';

import Colors from 'src/nightingale/Colors';
import PatientPebblesCountContext from 'src/pebbles/components/PatientPebblesCountContext/PatientPebblesCountContext';

const PatientPebblesCountBadge: React.FC = () => {
  const { count } = useContext(PatientPebblesCountContext);
  const classes = useStyles();

  return (
    <Badge
      classes={{ badge: classes.quietBadgeColor }}
      badgeContent={count}
      data-testid="patient-menu-pebbles-badge"
    >
      Pebbles
    </Badge>
  );
};

const useStyles = makeStyles({
  quietBadgeColor: {
    color: 'white',
    backgroundColor: Colors.Blue4,
  },
});

export default PatientPebblesCountBadge;
