import Typography from '@material-ui/core/Typography';
import React from 'react';

const autocompleteNotes = key => ({
  component: ({ model, classes }) => (
    <>
      {model[key]?.text && (
        <Typography variant="body2" className={classes.multiLineText}>
          {model[key].text}
        </Typography>
      )}
    </>
  ),
});

const taggedText = {
  type: 'object',
  taggedText: true,
  properties: {},
};

export default {
  type: 'object',
  title: 'Prescriber: Objective',
  properties: {
    vitals: {
      ...taggedText,
      renderer: autocompleteNotes('vitals'),
      hashTags: ['None taken via telemedicine.'],
      default: {
        text: 'None taken via telemedicine.',
        tags: ['None taken via telemedicine.'],
      },
    },
    constitutional: {
      ...taggedText,
      renderer: autocompleteNotes('constitutional'),
      hashTags: [
        'Well appearing.',
        'No acute distress.',
        'Tired-appearing.',
        'Ill-appearing.',
        'Disheveled.',
        'Chronically-ill appearing.',
      ],
      default: {
        text: 'Well appearing. No acute distress.',
        tags: ['Well appearing.', 'No acute distress.'],
      },
    },
    head: {
      ...taggedText,
      renderer: autocompleteNotes('head'),
      hashTags: ['Normocephalic and atraumatic.'],
      default: {
        text: 'Normocephalic and atraumatic.',
        tags: ['Normocephalic and atraumatic.'],
      },
    },
    eyes: {
      ...taggedText,
      renderer: autocompleteNotes('eyes'),
      hashTags: [
        'Pupils equal, sclerae clear.',
        'Pupils dilated.',
        'Unable to assess due to poor video quality.',
        'Sclerae injected.',
        'Sclerae icteric.',
      ],
      default: {
        text: 'Pupils equal, sclerae clear.',
        tags: ['Pupils equal, sclerae clear.'],
      },
    },
    pulmonaryChest: {
      ...taggedText,
      renderer: autocompleteNotes('pulmonaryChest'),
      title: 'Pulmonary/Chest',
      hashTags: ['Effort normal.', 'No respiratory distress.'],
      default: {
        text: 'Effort normal. No respiratory distress.',
        tags: ['Effort normal.', 'No respiratory distress.'],
      },
    },
    neurological: {
      ...taggedText,
      renderer: autocompleteNotes('neurological'),
      hashTags: ['Alert, awake, oriented to person, place and time.'],
      default: {
        text: 'Alert, awake, oriented to person, place and time.',
        tags: ['Alert, awake, oriented to person, place and time.'],
      },
    },
    skin: {
      ...taggedText,
      renderer: autocompleteNotes('skin'),
      hashTags: ['Does not appear diaphoretic.', 'No visible rashes or lesions.', 'Diaphoretic.'],
      default: {
        text: 'Does not appear diaphoretic. No visible rashes or lesions.',
        tags: ['Does not appear diaphoretic.', 'No visible rashes or lesions.'],
      },
    },
    psychiatric: {
      ...taggedText,
      renderer: autocompleteNotes('psychiatric'),
      hashTags: [
        'Judgment normal.',
        'Mood and affect normal.',
        'Normal thought process and content.',
        'Demonstrates appropriate insight.',
        'Limited judgment.',
        'Anxious-appearing.',
        'Mood is depressed, no suicidal ideations.',
        'No suicidal ideations.',
        'Limited insight.',
        'Tangential.',
      ],
      default: {
        text: 'Judgment normal. Mood and affect normal. Normal thought process and content. Demonstrates appropriate insight.',
        tags: [
          'Judgment normal.',
          'Mood and affect normal.',
          'Normal thought process and content.',
          'Demonstrates appropriate insight.',
        ],
      },
    },
  },
  required: [],
};
