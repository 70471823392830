import React from 'react';

import PatientOverviewLink from 'src/components/general/patientOverviewLink';

export default {
  type: 'object',
  title: 'Onboarding: RCV Setup Intro',
  disabled: true,
  properties: {
    confirmedServiceAreaEligibility: { type: 'boolean' },
    confirmedDesireForOudTreatment: {
      title: 'Confirmed desire for OUD treatment',
      type: 'boolean',
    },
    rcvProcessReviewed: { title: 'RCV Process Reviewed', type: 'boolean' },
    discussedInsuranceEstimates: {
      title: 'Discussed Insurance Estimates',
      label: (
        <span>
          Discussed{' '}
          <PatientOverviewLink section="reference">Insurance Estimates</PatientOverviewLink>
        </span>
      ),
      type: 'boolean',
    },
    boulderReferralSource: { type: 'string', tooltip: 'How did you hear about Boulder?' },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
