"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectAsyncOptionsSource = exports.PhiType = exports.INTERNAL_LIST_ITEM_ID_PROPERTY_NAME = exports.ChartPropertyType = exports.ChartPropertyKind = void 0;
const INTERNAL_LIST_ITEM_ID_PROPERTY_NAME = exports.INTERNAL_LIST_ITEM_ID_PROPERTY_NAME = '_internalListItemId';
let ChartPropertyType = exports.ChartPropertyType = function (ChartPropertyType) {
  ChartPropertyType["Text"] = "TEXT";
  ChartPropertyType["LongText"] = "LONG_TEXT";
  ChartPropertyType["TaggedText"] = "TAGGED_TEXT";
  ChartPropertyType["Select"] = "SELECT";
  ChartPropertyType["SelectMulti"] = "SELECT_MULTI";
  ChartPropertyType["SelectAsync"] = "SELECT_ASYNC";
  ChartPropertyType["SelectMultiAsync"] = "SELECT_MULTI_ASYNC";
  ChartPropertyType["Boolean"] = "BOOLEAN";
  ChartPropertyType["CheckboxList"] = "CHECKBOX_LIST";
  ChartPropertyType["Int"] = "INT";
  ChartPropertyType["Date"] = "DATE";
  ChartPropertyType["Time"] = "TIME";
  ChartPropertyType["Datetime"] = "DATETIME";
  ChartPropertyType["Email"] = "EMAIL";
  ChartPropertyType["Phone"] = "PHONE";
  ChartPropertyType["Object"] = "OBJECT";
  ChartPropertyType["List"] = "LIST";
  return ChartPropertyType;
}({});
let ChartPropertyKind = exports.ChartPropertyKind = function (ChartPropertyKind) {
  ChartPropertyKind["Patient"] = "PATIENT";
  ChartPropertyKind["Interaction"] = "INTERACTION";
  return ChartPropertyKind;
}({});
let PhiType = exports.PhiType = function (PhiType) {
  PhiType["Safe"] = "SAFE";
  PhiType["Text"] = "TEXT";
  PhiType["City"] = "CITY";
  PhiType["Code"] = "CODE";
  PhiType["Company"] = "COMPANY";
  PhiType["Date"] = "DATE";
  PhiType["Dob"] = "DOB";
  PhiType["Email"] = "EMAIL";
  PhiType["FirstName"] = "FIRST_NAME";
  PhiType["FullName"] = "FULL_NAME";
  PhiType["Ip"] = "IP";
  PhiType["LastName"] = "LAST_NAME";
  PhiType["Phone"] = "PHONE";
  PhiType["Street"] = "STREET";
  PhiType["Url"] = "URL";
  PhiType["Uuid"] = "UUID";
  PhiType["Zip"] = "ZIP";
  return PhiType;
}({});
let SelectAsyncOptionsSource = exports.SelectAsyncOptionsSource = function (SelectAsyncOptionsSource) {
  SelectAsyncOptionsSource["ICD10Codes"] = "ICD10_CODES";
  SelectAsyncOptionsSource["MedicalProblems"] = "MEDICAL_PROBLEMS";
  SelectAsyncOptionsSource["Medications"] = "MEDICATIONS";
  SelectAsyncOptionsSource["Patients"] = "PATIENTS";
  SelectAsyncOptionsSource["Payors"] = "PAYORS";
  SelectAsyncOptionsSource["Providers"] = "PROVIDERS";
  return SelectAsyncOptionsSource;
}({});