import React, { useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import { TURN_OFF_PEBBLES } from 'src/featureFlags/currentFlags';
import PatientPebblesTable from 'src/pebbles/components/PatientPebblesTable';

interface PatientPebblesProps {
  patientId: string;
}

const PatientPebbles: React.FC<PatientPebblesProps> = ({ patientId }) => {
  const flags = useContext(FeatureFlagContext);
  return (
    <div data-testid="pebbles-table">
      <TitleBar title="Pebbles" />
      {!flags[TURN_OFF_PEBBLES] && <PatientPebblesTable patientId={patientId} />}
      {flags[TURN_OFF_PEBBLES] && <div>Pebbles are turned off</div>}
    </div>
  );
};

export default PatientPebbles;
