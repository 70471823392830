import { inject, observer } from 'mobx-react';
import React from 'react';

import RouteLink from 'src/components/general/routeLink';
import type { RootStore } from 'src/stores/root';

const PatientOverviewLink = ({
  children,
  rootStore,
  section,
}: {
  children: string | React.ReactElement;
  rootStore?: RootStore;
  section?: string;
}) => {
  if (!rootStore) {
    throw new Error('somehow inject() failed to provide a rootStore');
  }
  const {
    patients: { patient },
  } = rootStore;

  return patient ? (
    <RouteLink
      route="showPatient"
      routeParams={[{ id: patient.id }, { scrollTo: section }]}
      newWindow
    >
      {children}
    </RouteLink>
  ) : (
    <>{children}</>
  );
};

export default inject('rootStore')(observer(PatientOverviewLink));
