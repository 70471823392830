import { Theme, withStyles } from '@material-ui/core/styles';
import { ClassNameMap, Styles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import type { MouseEventHandler } from 'react';

import PatientReference from 'src/components/forms/controls/PatientReference';
import DateTimePicker from 'src/components/forms/controls/dateTimePicker';
import ProviderReference from 'src/components/forms/controls/providerReference';
import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import FlexibleBox from 'src/components/general/flexibleBox';
import { PebbleCountForPatient } from 'src/components/pages/pageElements/PebbleCountForPatient';
import { PebblesUpdateContext } from 'src/pebbles/PebblesUpdateContext';
import { usePebbleTopics } from 'src/pebbles/usePebbleTopics';
import { PEBBLE_PRIORITY_OPTIONS, PEBBLE_STATUS_OPTIONS, PEBBLE_VALIDATOR } from 'src/util/pebbles';

type MightHaveId = {
  id?: string;
};

// Hard-coded width for the edit form
const DIALOG_WIDTH = 500;
// Height that would show the entire edit form
const PREFERRED_DIALOG_HEIGHT = 660;
// Space we'd like to allocate above/below the form
const PREFERRED_DIALOG_PADDING = 30;
// Hard-coded height of the top nav
const TOP_NAV_HEIGHT = 64;

const CreatePebbleDialog: React.FC<{
  classes: ClassNameMap;
  defaultValues?: Partial<{
    link: string;
    note: string;
    participant: unknown;
    priority: string;
    status: string;
    title: string;
    topic: string;
  }>;
  onSubmit: (values: unknown) => Promise<unknown>;
  onCancel: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
}> = ({ classes, defaultValues = {}, onSubmit, onCancel }) => {
  // Calculate how tall the form should be based on the current window height.
  // This is effectively the PREFERRED_DIALOG_HEIGHT when there's enough space for it,
  // where "enough space" checks for the top nav's height and space for padding above/below
  const initialVerticalSpace =
    document.body.clientHeight - TOP_NAV_HEIGHT - PREFERRED_DIALOG_PADDING;
  const initialHeight = Math.min(initialVerticalSpace, PREFERRED_DIALOG_HEIGHT);

  // initialX and initialY only accept pixel amounts, so we use the document.body dimensions to
  // approximate the math we'd otherwise get from percentages/CSS calculations
  const initialX = document.body.clientWidth - 600;
  const initialY = Math.min(initialVerticalSpace - initialHeight, 100);

  const [currentParticipant, setCurrentParticipant] = useState<MightHaveId | null>(
    (defaultValues.participant ?? {}) as MightHaveId,
  );

  const { updateLastUpdate } = useContext(PebblesUpdateContext);

  const topics = usePebbleTopics();

  return (
    <FlexibleBox
      // Specifically prevent dragging from any of the input fields
      cancel=".MuiFormControl-root"
      width={DIALOG_WIDTH}
      initialX={initialX}
      initialY={initialY}
      initialHeight={initialHeight}
      onClose={onCancel}
      heading={
        <div className={classes.headingContainer}>
          Create Pebble
          {currentParticipant?.id && (
            <PebbleCountForPatient participantId={currentParticipant?.id} />
          )}
        </div>
      }
      data-testid="create-pebble-dialog"
    >
      <EditForm
        formClass={classes.editForm}
        item={defaultValues}
        validationSchema={PEBBLE_VALIDATOR}
        onSave={async event => {
          await onSubmit(event);
          updateLastUpdate();
        }}
        onCancel={onCancel}
        saveButtonText="Create"
        formId="create-pebble"
      >
        <FormikEffect
          onChange={current => {
            setCurrentParticipant(current.values.participant);
          }}
        />
        <Field
          label="Title"
          name="title"
          component={TextControl}
          placeholder="Type a title..."
          inputProps={{ autoFocus: true }}
        />
        <Field label="Topic" name="topic" component={SelectControl} options={topics} />
        <Field
          label="Participant"
          name="participant"
          component={PatientReference}
          placeholder="Select Participant..."
        />
        <Field
          label="Assigned to"
          name="assignee"
          component={ProviderReference}
          placeholder="Select Provider..."
        />
        <Field
          label="Priority"
          name="priority"
          options={PEBBLE_PRIORITY_OPTIONS}
          component={SelectControl}
          placeholder="Select Priority..."
        />
        <Field
          name="reminder"
          component={({ form: { setFieldValue }, field: { value } }) => (
            <DateTimePicker
              onChange={val => setFieldValue('reminder', val)}
              value={value}
              label="Reminder"
            />
          )}
        />
        <Field
          label="Monitored By"
          name="monitors"
          isMulti
          component={ProviderReference}
          placeholder="Select Provider(s)..."
        />
        <Field
          name="status"
          component={SelectControl}
          label="Status"
          options={PEBBLE_STATUS_OPTIONS}
        />
        <Field name="note" component={TextControl} multiline label="Notes" />
      </EditForm>
    </FlexibleBox>
  );
};

const styles: Styles<Theme, any> = () => ({
  headingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editForm: {
    padding: 20,

    '& > *': {
      marginTop: 10,
      marginBottom: 10,

      // Right align the buttons to be more normal
      '&:last-of-type': {
        display: 'flex',
        flexDirection: 'row-reverse',
      },
    },
  },
  formContainer: {
    '& > *': {
      marginTop: 16,
    },
  },
  paper: {
    padding: 20,
  },
});

export default withStyles(styles)(observer(CreatePebbleDialog));
