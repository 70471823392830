import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { isAfter, addDays } from 'date-fns';
import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import type ReactSelect from 'react-select';
import useSWR from 'swr';

import Autocomplete from 'src/components/forms/controls/autocomplete';
import { states } from 'src/components/forms/schemas/definitions';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { SubType } from 'src/events/visitTypes';
import TimeSlotTable from 'src/scheduling/components/TimeSlotTable';
import { usePayorsForState } from 'src/scheduling/hooks/usePayorsForState';
import { useStateAndPayor } from 'src/scheduling/hooks/useStateAndPayor';
import {
  GET_AVAILABLE_TIME_SLOTS,
  QueryResult as TimeSlotsQueryResult,
} from 'src/scheduling/queries/getAvailableTimeSlots';
import type { TimeSlot } from 'src/scheduling/types';
import Attendee from 'src/scheduling/types/Attendee';
import { Priority } from 'src/scheduling/types/TimeSlot';
import { CLINICIAN_EVENT_SUBTYPES, RN_EVENT_SUBTYPES } from 'src/shared/util/events';

const STATE_OPTIONS = states.map(state => ({ label: state, value: state }));

type TimeslotSelectionControlProps = {
  onSelectedTimeSlot: (timeSlot: TimeSlot) => void;
  patientId?: string;
  start: Date;
  subType: SubType;
  timezone: string;
  duration: number;
  providerIds: string[];
};

const TimeslotSelectionControl: FunctionComponent<TimeslotSelectionControlProps> = ({
  onSelectedTimeSlot,
  patientId,
  start,
  subType,
  timezone,
  duration,
  providerIds,
}) => {
  const stateRef = useRef<ReactSelect>();
  const payorRef = useRef<ReactSelect>();
  const [confirmedState, setConfirmedState] = useState<string | null>(null);
  const [confirmedPayor, setConfirmedPayor] = useState<string | null>(null);
  const [shouldLoadSlots, setShouldLoadSlots] = useState<boolean>(false);
  const [displayClickToLoadSlots, setDisplayClickToLoadSlots] = useState<boolean>(true);
  const [backupToggleChecked, setBackupToggleChecked] = useState<boolean>(false);

  useEffect(() => {
    if (providerIds.length === 0) {
      setDisplayClickToLoadSlots(true);
    }
  }, [providerIds]);

  const payorsByKey = usePayorsForState(confirmedState);
  const payors = payorsByKey ? Object.values(payorsByKey) : [];

  // /**
  //  * Allow click to load time slots for nurse event subtypes after they confirm state or
  //  * for clinician event subtypes after they confirm payor
  //  */
  const canLoadSlots = Boolean(
    (RN_EVENT_SUBTYPES.includes(subType) && confirmedState) ||
      (confirmedState && confirmedPayor && payorsByKey[confirmedPayor]),
  );

  const { apolloClient } = useContext(ApolloClientContext);
  if (!apolloClient) {
    return null;
  }

  const {
    data: timeSlotsResult,
    error: timeSlotsError,
    isValidating,
  } = useSWR<TimeSlotsQueryResult, Error>(
    canLoadSlots && shouldLoadSlots
      ? [
          GET_AVAILABLE_TIME_SLOTS,
          {
            credentialCriteria: {
              credentialId: confirmedPayor ? payorsByKey[confirmedPayor].credential.id : null,
              state: confirmedState,
            },
            selectedProviderIds: providerIds,
            subType,
            start,
            duration,
          },
        ]
      : null,
    null,
    { use: [searchWindowMiddleware] },
  );

  const isLoading = shouldLoadSlots && isValidating;

  const allTimeSlots = useMemo<TimeSlot[]>(
    () =>
      timeSlotsResult?.getAvailableTimeSlots.map(
        ({
          start: slotStart,
          duration: slotDuration,
          provider: { providerId, firstName = '', lastName = '', restrictions = '' },
          priority = '',
        }) =>
          ({
            start: new Date(slotStart),
            duration: slotDuration,
            providerId,
            firstName,
            lastName,
            restrictions,
            priority,
          }) as TimeSlot,
      ) || [],
    [timeSlotsResult],
  );

  const timeSlotsByPriority = useMemo(
    () =>
      allTimeSlots.reduce(
        (accumulator, timeSlot) => {
          if (timeSlot.priority === Priority.Available) {
            accumulator.available.push(timeSlot);
          } else if (timeSlot.priority === Priority.Backup) {
            accumulator.backup.push(timeSlot);
          }
          return accumulator;
        },
        { available: [], backup: [] } as { available: TimeSlot[]; backup: TimeSlot[] },
      ),
    [allTimeSlots],
  );

  const timeSlotsForTable = useMemo(() => {
    if (!allTimeSlots.length) {
      return [];
    }

    const slots = backupToggleChecked
      ? [...timeSlotsByPriority.available, ...timeSlotsByPriority.backup].sort((s1, s2) =>
          s1.start < s2.start ? -1 : 1,
        )
      : timeSlotsByPriority.available;

    return slots;
  }, [allTimeSlots, backupToggleChecked, timeSlotsByPriority]);

  useEffect(() => {
    setConfirmedState(null);
    setConfirmedPayor(null);
    setShouldLoadSlots(false);
  }, [patientId]);

  const { patientState, patientPayorName, patientPayorKey } = useStateAndPayor(patientId, start);

  useEffect(() => {
    if (!stateRef.current) return;
    if (patientState) {
      setConfirmedState(patientState);
      stateRef.current.select.selectOption({ label: patientState, value: patientState });
    } else if (stateRef.current.select.hasValue()) {
      stateRef.current.select.clearValue();
    }

    if (!payorRef.current) return;
    if (patientPayorName && patientPayorKey) {
      setConfirmedPayor(patientPayorKey);
      payorRef.current.select.selectOption({ label: patientPayorName, value: patientPayorKey });
    } else if (payorRef.current.select.hasValue()) {
      payorRef.current.select.clearValue();
    }
  }, [patientState, patientPayorName, patientPayorKey]);

  const {
    availabilitySection,
    availabilitySectionBackground,
    availabilityHeader,
    availabilitySubHeader,
    disabled,
    loadSlotsButton,
  } = useStyles();

  return (
    <div className={availabilitySectionBackground} data-testid="time-slot-selection-control">
      <div className={availabilitySection}>
        <div>
          <h5 className={availabilityHeader}>Availability for Visits</h5>
          <p className={availabilitySubHeader}>
            Confirm patient&apos;s state and payor to load slots
          </p>
        </div>
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2} alignContent="flex-start">
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                data-testid="time-slot-selection-control-dropdown-state"
              >
                <Autocomplete
                  aria-labelledby="confirmState"
                  className={patientId ? disabled : ''}
                  isDisabled={Boolean(patientId)}
                  label="Confirm State"
                  labelFn={option => option.label}
                  name="state"
                  onChange={option => {
                    if (!option?.value) return;
                    const { value } = option;
                    // clear out selected payor option when state changes
                    setConfirmedPayor(null);
                    setShouldLoadSlots(false);
                    setConfirmedState(value);
                    if (payorRef.current?.select.hasValue()) {
                      payorRef.current.select.clearValue();
                    }
                  }}
                  options={STATE_OPTIONS}
                  valueFn={option => option.value}
                  ref={stateRef}
                  defaultValue={STATE_OPTIONS[0]}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                data-testid="time-slot-selection-control-dropdown-payor"
              >
                {CLINICIAN_EVENT_SUBTYPES.includes(subType) && (
                  <Autocomplete
                    aria-labelledby="confirmPayor"
                    className={!confirmedState ? disabled : ''}
                    isDisabled={!confirmedState}
                    label="Confirm Payor"
                    labelFn={option => option.label}
                    name="payor"
                    onChange={option => {
                      if (!option?.value) return;
                      const { value } = option;
                      setConfirmedPayor(value);
                    }}
                    options={payors
                      .map(payor => ({ label: payor.name, value: payor.key }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    valueFn={option => option.value}
                    ref={payorRef}
                  />
                )}
              </Grid>
              <Grid item xs={4} sm={4} md={4} className={loadSlotsButton}>
                <Button
                  onClick={() => {
                    setShouldLoadSlots(true);
                    setDisplayClickToLoadSlots(false);
                  }}
                  color="primary"
                  variant="outlined"
                  disabled={!canLoadSlots}
                >
                  Click to load slots
                </Button>
                <FormControlLabel
                  label="Show backup time slots"
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={event => setBackupToggleChecked(event.target.checked)}
                      disabled={displayClickToLoadSlots}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TimeSlotTable
          timeSlots={timeSlotsForTable}
          onClick={onSelectedTimeSlot}
          timezone={timezone}
          isLoading={isLoading}
          error={timeSlotsError}
          displayClickToLoadSlots={displayClickToLoadSlots}
          numSearchWindowDays={getNumSearchWindowDays(subType)}
        />
      </div>
    </div>
  );
};

const findPatient = attendees => {
  return attendees?.find(attendee => attendee.__typename === 'Patient');
};

const findProviders = attendees => {
  return attendees?.filter(attendee => attendee.__typename === 'Provider') || [];
};

// Searching for timeslots can be fairly slow, so narrow our search window when looking at event
// types that typically have a short lead time for scheduling.
const getNumSearchWindowDays = (subType: string): number => {
  switch (subType) {
    case 'prescriber_bridge_care':
    case 'prescriber_reengagement_visit':
      return 14;
    case 'prescriber_initial':
    case 'registered_nurse':
      return 21;
    default:
      return 28;
  }
};

const getSearchWindow = (
  subType: string,
  paramStart: Date = new Date(),
): { start: Date; end: Date } => {
  const start = isAfter(paramStart, new Date()) ? paramStart : new Date();

  return {
    start,
    end: addDays(start, getNumSearchWindowDays(subType)),
  };
};

const searchWindowMiddleware = useSwrNext => (key, fetcher, config) =>
  useSwrNext(
    key,
    ([query, params]) =>
      fetcher([
        query,
        {
          ...params,
          searchWindow: getSearchWindow(params.subType, params.start),
        },
      ]),
    config,
  );

export type TimeSlotSelectionProps = {
  attendees: Attendee[];
  setFieldValue: (name: string, value: any) => void;
  start: Date;
  subType: SubType;
  timezone: string;
  duration: number;
};

const TimeSlotSelection: FunctionComponent<TimeSlotSelectionProps> = ({
  attendees,
  setFieldValue,
  start,
  subType,
  timezone,
  duration,
}) => {
  const patient = useMemo(() => findPatient(attendees), [attendees]);
  const providers = useMemo(() => findProviders(attendees), [attendees]);

  const setEventFields = useCallback(
    ({ start: slotStart, duration: slotDuration, providerId, firstName, lastName }: TimeSlot) => {
      const teamRole = RN_EVENT_SUBTYPES.includes(subType) ? 'registered_nurse' : 'clinician';
      const newProvider = {
        id: providerId,
        firstName,
        lastName,
        teamRole,
        __typename: 'Provider',
      };
      const newAttendees = patient ? [patient, newProvider] : [newProvider];

      setFieldValue('start', slotStart);
      setFieldValue('duration', slotDuration);
      setFieldValue('attendees', newAttendees);
    },
    [patient, setFieldValue],
  );

  return (
    <TimeslotSelectionControl
      start={start}
      timezone={timezone}
      patientId={patient?.id}
      subType={subType}
      duration={duration}
      providerIds={providers.map(provider => provider.id)}
      onSelectedTimeSlot={setEventFields}
    />
  );
};

const useStyles = makeStyles(theme => ({
  availabilitySectionBackground: {
    background: '#FAFAFA',
    marginLeft: -25,
    marginRight: -25,
    marginTop: 5,
    marginBottom: 15,
  },
  availabilitySection: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 25,
  },
  availabilityHeader: {
    marginBottom: 0,
  },
  availabilitySubHeader: {
    marginTop: 0,
    fontSize: 12,
  },
  loadSlotsButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  disabled: {
    opacity: '40%',
  },
}));

export default TimeSlotSelection;

export const __test__ = {
  TimeslotSelectionControl,
};
