import { gql } from 'src/generated/gql';

export const GET_CLINIC_QUEUE = gql(`
  query ClinicDropInQueue($providerId: ID!) {
    staff_getClinicDropInQueue {
      request {
        id
        requestTime
        eventId
        status
        requestType
        reason {
          details
          reason
          questionOrConcern
          medicationsToRefill
          moreThanTwoDaysBup
        }
      }
      categories
      patient {
        id
        firstName
        fyi
        lastName
        preferredName
        medicationRunOutDate
        homeState
        dob
      }
      preparedBy {
        firstName
        lastName
      }
      acceptableForProvider(providerId: $providerId) {
        ...clinicAcceptabilityDetails
      }
      acceptableForAvailableProviders {
        ...clinicAcceptabilityDetails
      }
      scheduleSuggestion {
        outcome
        details{
          startTime
          duration
          provider {
            id
            firstName
            lastName
          }
        }
      }
    }
    getActiveDropInClinicVisits(includeZombieVisits: true) {
      categories
      event {
        id
        start
        status
      }
      patient {
        id
        firstName
        lastName
        preferredName
        homeState
        dob
      }
      queuedAt
      requestId
      requestType
      provider {
        firstName
        lastName
      }
    }
  }

  
  
  fragment clinicAcceptabilityDetails on staff_ClinicAcceptabilityDetails{
    providerId
    acceptable
    insuranceIsAcceptable
    stateIsAcceptable
    acceptableForRole
  }
`);
