import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  loader: {
    margin: '1rem',
    color: Colors.BlueSpruce,
  },
});

/**
 * Fallback component for us to use when lazy-loading React components
 */
const LazyLoadingSpinner: React.FC = () => {
  const styles = useStyles();

  return <CircularProgress className={styles.loader} />;
};

export default LazyLoadingSpinner;
