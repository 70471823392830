import { createStyles, withStyles } from '@material-ui/core/styles';
import type { ClassNameMap } from '@material-ui/styles';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { ReactComponent as MagnifySvg } from 'src/patientHeader/icons/magnify.svg';

const magnifyIconStyles = () =>
  createStyles({
    wrapper: {
      border: `0.5px solid ${Colors.Gray8}`,
      borderRadius: '4px',
      backgroundColor: 'rgba(255, 255, 255, 0.75)',

      position: 'absolute',
      bottom: 0,
      right: -4,

      height: 14,
      width: 14,
    },
  });

export const MagnifyIcon = withStyles(magnifyIconStyles)(({
  classes,
}: {
  classes: ClassNameMap;
}) => {
  return (
    <div className={classes.wrapper}>
      <MagnifySvg />
    </div>
  );
});
