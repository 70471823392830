import { MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';

import type { RootStore } from 'src/stores/root';

export default function useRootStore() {
  const { rootStore } = useContext(MobXProviderContext);

  return rootStore as RootStore;
}
