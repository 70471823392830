import Typography from '@material-ui/core/Typography';
import React from 'react';

const discussed = {
  type: 'object',
  title: 'Yes',
  properties: {
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
};

const didNotDiscuss = {
  type: 'object',
  title: 'No',
  properties: {
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
};

export default {
  type: 'object',
  title: 'Peer Coach: Visit',
  properties: {
    situation: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
    intervention: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
    plan: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
    discussedReUsePrevention: {
      type: 'object',
      title: 'Discussed Re-Use Prevention',
      format: 'selectable',
      renderer: {
        component: ({ model, classes }) => {
          return (
            <>
              {model.discussedReUsePrevention.selectionKey === 'discussed' ||
              model.discussedReUsePrevention.selectionKey === 'discussedWithNotes'
                ? 'Yes'
                : 'No'}
              {model.discussedReUsePrevention.additional?.notes && (
                <Typography variant="body2" className={classes.multiLineText}>
                  {model.discussedReUsePrevention.additional.notes}
                </Typography>
              )}
            </>
          );
        },
      },
      properties: {
        discussed,
        didNotDiscuss,
      },
    },
  },
  required: [],
};
