// Version imports
import v100 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.0.0';
import v110 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.1.0';
import v111 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.1.1';
import v120 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.2.0';
import v130 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.3.0';
import v140 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.4.0';
import v150 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.5.0';
import v160 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.6.0';
import v170 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.7.0';
import v180 from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.8.0';

export default {
  latest: v180,
  // All Versions
  '1.8.0': v180,
  '1.7.0': v170,
  '1.6.0': v160,
  '1.5.0': v150,
  '1.4.0': v140,
  '1.3.0': v130,
  '1.2.0': v120,
  '1.1.1': v111,
  '1.1.0': v110,
  '1.0.0': v100,
};
