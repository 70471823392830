"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datediff = datediff;
var _dateFns = require("date-fns");
var _dateCompatabilityShim = require("./dateCompatabilityShim");
function datediff(date, unit) {
  let normalizedUnit = (0, _dateCompatabilityShim.normalizeDateUnit)(unit);
  if (!normalizedUnit) {
    return undefined;
  }
  const now = new Date();
  const then = (0, _dateCompatabilityShim.parseUnknownDate)(date);
  if (!(0, _dateFns.isValid)(then)) {
    return undefined;
  }
  switch (normalizedUnit) {
    case 'years':
      return (0, _dateFns.differenceInYears)(now, then);
    case 'months':
      return (0, _dateFns.differenceInMonths)(now, then);
    case 'weeks':
      return (0, _dateFns.differenceInWeeks)(now, then);
    case 'days':
      return (0, _dateFns.differenceInDays)(now, then);
    case 'hours':
      return (0, _dateFns.differenceInHours)(now, then);
    case 'minutes':
      return (0, _dateFns.differenceInMinutes)(now, then);
    case 'seconds':
      return (0, _dateFns.differenceInSeconds)(now, then);
    default:
      return undefined;
  }
}