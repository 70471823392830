import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';

import { TimeSlot } from 'src/scheduling/types';

export type CreateSuggestedEventInput = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  timeSlot: TimeSlot;
  patientId: string;
  visitTitle?: string | null;
  timezone: string;
};

export const createSuggestedEvent = ({
  apolloClient,
  timeSlot,
  patientId,
  timezone,
  visitTitle,
}: CreateSuggestedEventInput) =>
  apolloClient?.mutate({
    mutation: gql`
      mutation ($data: EventInput!) {
        createEvent(data: $data) {
          id
          start
          timezone
        }
      }
    `,
    variables: {
      data: {
        type: 'appointment_virtual',
        subType: timeSlot.subType,
        title: visitTitle ?? 'Regular Visit',
        attendees: [timeSlot.providerId, patientId],
        start: timeSlot.start,
        duration: timeSlot.duration,
        timezone,
      },
    },
  });
