import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { observer } from 'mobx-react';
import React from 'react';

import { TEAM_ROLE_OPTIONS } from 'src/components/forms/schemas/definitions';
import SortableList from 'src/components/general/sortableList';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const Avatar: React.FC<{ item: { avatar: { url: string } } }> = ({ item }) => (
  <div>
    {item.avatar && (
      <img
        src={item.avatar.url}
        alt="avatar"
        style={{
          width: 50,
          height: 50,
          objectFit: 'cover',
          borderRadius: '50%',
        }}
      />
    )}
  </div>
);

const Providers: React.FC<{ rootStore: RootStore }> = ({
  rootStore: {
    providers: { list },
  },
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <TitleBar title="Providers" actionTooltip="Add Provider" routeName="createProvider" />
      </div>
      <div className={classes.spacer} />
      <SortableList
        resourceList={list}
        routes={{
          providerCalendar: {
            routeName: 'showProvider',
            getRouteParams: item => ({ id: item.id }),
          },
          providerEdit: {
            routeName: 'editProvider',
            getRouteParams: item => ({ id: item.id }),
          },
        }}
        tableHeadRows={[
          {
            fields: [
              {
                label: 'First',
                sortField: 'firstName',
              },
              {
                label: 'Last',
                sortField: 'lastName',
              },
              {
                label: 'Email',
                sortField: 'email',
              },
              {
                label: 'Role',
              },
              {
                label: 'Avatar',
              },
              {
                label: ' ',
              },
            ],
          },
        ]}
        tableBodyRows={[
          {
            key: 'details',
            routeKey: 'providerCalendar',
            fields: [
              {
                label: 'First',
                value: ({ item }) => item.firstName,
              },
              {
                label: 'Last',
                value: ({ item }) => item.lastName,
              },
              {
                label: 'Email',
                value: ({ item }) => item.email,
              },
              {
                label: 'Role',
                value: ({ item }) => (item.teamRole ? TEAM_ROLE_OPTIONS[item.teamRole] : ''),
              },
              {
                label: 'Avatar',
                value: Avatar,
              },
              {
                label: ' ',
                routeKey: 'providerEdit',
                component: () => (
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                ),
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  root: {
    padding: theme.spacing(3),
  },
}));

export default inject<typeof Providers>('rootStore')(observer(Providers));
