import { Theme } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import classNames from 'classnames';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import React from 'react';
import type { DateLocalizer } from 'react-big-calendar';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import * as dates from 'react-big-calendar/lib/utils/dates';
import { inRange } from 'react-big-calendar/lib/utils/eventLevels';

import { getAllSubTypes, TYPES, EVENT_STATUSES } from 'src/shared/util/events';
import type { EventInstance } from 'src/stores/models/event';

type AgendaProps = {
  accessors: any;
  localizer: DateLocalizer;
  classes: { [key: string]: string };
  components: { rowClickHandler; showPatientColumn; time };
  length: number;
  date: Date;
  events: EventInstance[];
};

type StaticMethods = {
  navigate: (date: Date, action: 'PREV' | 'NEXT' | 'DATE') => Date;
  title: (
    date: Date,
    options: { formats: string[]; culture?: string | undefined; [propName: string]: any },
  ) => string;
};

export type AgendaPropsWithStatics = React.FC<AgendaProps> & StaticMethods;

// Taken from the original Agenda.js
// https://github.com/intljusticemission/react-big-calendar/blob/v0.22.1/src/Agenda.js#L219
const Agenda: AgendaPropsWithStatics = ({
  accessors,
  localizer,
  classes,
  components: { rowClickHandler, showPatientColumn, time },
  length,
  date,
  events,
}) => {
  const timeRangeLabel = (day, event) => {
    let labelClass = '';
    const TimeComponent = time;
    let label = localizer.messages.allDay;

    const end = accessors.end(event);
    const start = accessors.start(event);

    if (!accessors.allDay(event)) {
      if (dates.eq(start, end)) {
        label = localizer.format(start, 'agendaTimeFormat', 'en-us');
      } else if (dates.eq(start, end, 'day')) {
        /* @ts-expect-error types for react-big-calendar not maintained: https://github.com/jquense/react-big-calendar/issues/1332#issuecomment-494043469 */
        label = localizer.format({ start, end }, 'agendaTimeRangeFormat', 'en-us');
      } else if (dates.eq(day, start, 'day')) {
        label = localizer.format(start, 'agendaTimeFormat', 'en-us');
      } else if (dates.eq(day, end, 'day')) {
        label = localizer.format(end, 'agendaTimeFormat', 'en-us');
      }
    }

    if (dates.gt(day, start, 'day')) labelClass = 'rbc-continues-prior';
    if (dates.lt(day, end, 'day')) labelClass += ' rbc-continues-after';

    return (
      <span className={labelClass.trim()}>
        {TimeComponent ? <TimeComponent event={event} day={day} label={label} /> : label}
      </span>
    );
  };

  const end = dates.add(date, length, 'day');
  const range = dates.range(date, end, 'day');
  const allEventSubTypes = getAllSubTypes();

  const filteredEvents = events.filter(event => {
    return inRange(event, date, end, accessors, localizer);
  });
  filteredEvents.sort((a, b) => +accessors.start(a) - +accessors.start(b));

  return (
    <div>
      {events.length !== 0 ? (
        <>
          <div className="rbc-agenda-content">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classNames(classes.header, classes.dateColumn)}>
                    Date
                  </TableCell>
                  <TableCell className={classNames(classes.header, classes.timeColumn)}>
                    Time
                  </TableCell>
                  <TableCell className={classNames(classes.header, classes.typeColumn)}>
                    Type
                  </TableCell>
                  <TableCell className={classNames(classes.header, classes.titleColumn)}>
                    Title
                  </TableCell>
                  {showPatientColumn && (
                    <TableCell className={classNames(classes.header, classes.patientColumn)}>
                      Patient
                    </TableCell>
                  )}
                  <TableCell className={classNames(classes.header, classes.providerColumn)}>
                    Provider(s)
                  </TableCell>
                  <TableCell className={classNames(classes.header, classes.statusColumn)}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {range.map(day =>
                  filteredEvents
                    .filter(event =>
                      inRange(
                        event,
                        dates.startOf(day, 'day'),
                        dates.endOf(day, 'day'),
                        accessors,
                        localizer,
                      ),
                    )
                    .map(event => (
                      <TableRow
                        key={event.id}
                        className={classNames(
                          classes.clickableRow,
                          event.status &&
                            [EVENT_STATUSES.CANCELED, EVENT_STATUSES.RESCHEDULED].includes(
                              event.status,
                            ) &&
                            classes.inactiveEvent,
                        )}
                        onClick={evt => rowClickHandler(event, evt)}
                      >
                        <TableCell className={classNames(classes.cell, classes.dateColumn)}>
                          {format(day, 'PP')}
                        </TableCell>
                        <TableCell className={classNames(classes.cell, classes.timeColumn)}>
                          {timeRangeLabel(day, event)}
                        </TableCell>
                        <TableCell className={classNames(classes.cell, classes.typeColumn)}>
                          {event.type && TYPES[event.type].label}
                        </TableCell>
                        <TableCell className={classNames(classes.cell, classes.titleColumn)}>
                          {event.subType
                            ? allEventSubTypes[event.subType].label +
                              (event.title ? `: ${event.title}` : '')
                            : event.title}
                        </TableCell>
                        {showPatientColumn && (
                          <TableCell className={classNames(classes.cell, classes.patientColumn)}>
                            {event.patientAttendee?.exactFullName}
                          </TableCell>
                        )}
                        <TableCell className={classNames(classes.cell, classes.providerColumn)}>
                          {event.providerAttendees.map((provider, index) => (
                            <>
                              {provider.exactFullName}
                              {index + 1 < event.providerAttendees.length && ', '}
                            </>
                          ))}
                        </TableCell>
                        <TableCell className={classNames(classes.cell, classes.statusColumn)}>
                          {event.status && capitalize(event.status)}
                        </TableCell>
                      </TableRow>
                    )),
                )}
              </TableBody>
            </Table>
          </div>
        </>
      ) : (
        <span className="rbc-agenda-empty">{localizer.messages.noEventsInRange}</span>
      )}
    </div>
  );
};

const DEFAULT_LENGTH = 30;

Agenda.defaultProps = {
  length: DEFAULT_LENGTH,
};

Agenda.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -30, 'day');

    case navigate.NEXT:
      return dates.add(date, 30, 'day');

    default:
      return date;
  }
};

Agenda.title = (start, { length = DEFAULT_LENGTH, localizer }) => {
  const end = dates.add(start, length, 'day');
  return localizer.format({ start, end }, 'agendaHeaderFormat');
};

const styles: Styles<Theme, any> = () => ({
  header: {
    color: 'rgba(0,0,0,0.4)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  cell: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  inactiveEvent: {
    opacity: 0.5,
    textDecoration: 'line-through',
  },
  dateColumn: {},
  timeColumn: {},
  typeColumn: {},
  titleColumn: {
    fontWeight: 500,
  },
  patientColumn: {},
  providerColumn: {},
  statusColumn: {},
});

export default withStyles(styles)(Agenda);
