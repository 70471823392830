import { types } from 'mobx-state-tree';
import type { Instance, SnapshotOut } from 'mobx-state-tree';

import { MonitoredProvider } from 'src/shared/stores/resource';
import { DateType } from 'src/stores/chat/date';
import { UserDisplay } from 'src/stores/models/userDisplay';

const User = types.model({
  id: types.string,
  firstName: types.optional(types.string, ''),
  lastName: types.optional(types.string, ''),
  pronouns: types.maybeNull(types.string),
  avatar: types.maybeNull(
    types.model({
      url: types.string,
    }),
  ),
  __typename: types.maybeNull(types.string), // make the type (Provider/Patient) available
});

// TODO: Copy-pasted solution from resource.js to support the preferredName on Patients only,
// unsure of why we aren't just utilizing the User mobx object defined there.
const Patient = User.named('Patient').props({
  preferredName: types.maybeNull(types.string),
});
export const Provider = User.named('Provider').props({
  patientFacingDisplayName: types.maybeNull(types.string),
  providersMonitored: types.maybeNull(types.array(MonitoredProvider)),
});
export type ProviderInstance = Instance<typeof Provider>;

/**
 * Mobx-state-tree needs a way to figure out the type of a given object. Use the built-in
 * graphql __typename.
 */
const dispatcher = snapshot => (snapshot && snapshot.__typename === 'Patient' ? Patient : Provider);
export const Resource = types.union({ dispatcher }, Provider, Patient);
export type ResourceInstance = Instance<typeof Resource>;

export function convertResourceToUserDisplay(
  user: ResourceInstance | SnapshotOut<typeof Resource>,
) {
  return UserDisplay.create({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    pronouns: user.pronouns,
    preferredName: 'preferredName' in user ? user.preferredName : undefined,
    userType: user.__typename,
    patientFacingDisplayName:
      'patientFacingDisplayName' in user ? user.patientFacingDisplayName : undefined,
  });
}

export const ConversationUser = types
  .model('ConversationUser', {
    userDisplay: UserDisplay,
    writing: types.optional(types.boolean, false),
    lastRead: types.maybeNull(DateType),
    archived: types.optional(types.boolean, false),
  })
  .views(self => ({
    /**
     * Details about the user for display purposes. Still used by other parts of the app.
     *
     * @deprecated Use `userDisplay` instead.
     */
    get user() {
      return {
        ...self.userDisplay,
        // `__typename` is defined in the GraphQL standard for discriminating members of union
        // types. We use it here to understand which type of user we're dealing with (e.g. to find
        // the patient in a list of event attendees)
        __typename: self.userDisplay.userType,
      };
    },
  }))
  .actions(self => ({
    setArchived: function setArchived(newArchived) {
      self.archived = newArchived;
    },
  }));
