import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import React from 'react';

import DateAndTimesControl from 'src/components/forms/controls/dateAndTimes';
import RecurrenceControl from 'src/components/forms/controls/recurrence';
import SelectControl from 'src/components/forms/controls/select';
import EditForm from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import { EVENT_VALIDATOR } from 'src/shared/client/forms/validation/event';
import {
  getAvailabilitySubTypesForRole,
  roleHasAvailabilitySubTypes,
} from 'src/shared/util/events';

const EditAvailability = props => {
  const subTypeItems = getAvailabilitySubTypesForRole(props.rootStore.providers.provider?.teamRole);
  const hasSubTypes = roleHasAvailabilitySubTypes(props.rootStore.providers.provider?.teamRole);
  return (
    <EditForm {...props} validationSchema={EVENT_VALIDATOR}>
      <Field
        allowHours
        startName="start"
        durationName="duration"
        timezoneName="timezone"
        component={DateAndTimesControl}
        label="Date"
        allDay={() => false}
      />
      {hasSubTypes && (
        <Field
          name="subType"
          className={props.classes.dropdown}
          component={SelectControl}
          label="Sub Type *"
          labelFn={option => subTypeItems[option].label}
          valueFn={option => option}
          options={Object.keys(subTypeItems)}
        />
      )}
      <Field
        render={({ form: { values, errors } }) => (
          <RecurrenceControl
            label="Repeats"
            recurrence={values.recurrence}
            isValid={!errors.recurrence}
            start={values.start}
            duration={values.duration}
            timezone={values.timezone}
            allDay={false}
          />
        )}
      />
    </EditForm>
  );
};

const styles = () => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',

    // Keep the buttons visible at the bottom of the modal
    position: 'sticky',
    backgroundColor: 'white',
    bottom: -8, // Accounting for 8px somewhere for some reason
    padding: '20px 0px',
    right: 0,
    zIndex: 10, // Sit on top of 9 index calendar column
  },
  dropdown: {
    width: '48%',
  },
});

export default withStyles(styles)(inject('rootStore')(observer(EditAvailability)));
