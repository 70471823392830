import isNil from 'lodash/fp/isNil';
import type { RouterStore } from 'mobx-state-router';

export type QueryState<T> = {
  value?: T | null;
  setValue: (value: T) => void;
};

export type ParamStateOptions<T> = {
  defaultValue?: T | null;
  serializer?: (value?: T | null) => string | undefined;
  deserializer?: (paramValue?: string) => T | undefined;
  replaceHistory?: boolean;
};

const defaultDeserializer = <T>(paramValue?: string): T | undefined => {
  if (paramValue === undefined || paramValue === '') {
    return undefined;
  }
  return JSON.parse(paramValue);
};

const defaultSerializer = <T>(value?: T | null) => {
  if (isNil(value)) {
    return undefined;
  }
  return JSON.stringify(value);
};

/**
 * Returns the observed parameter value and a setter to modify it.
 *
 * The caller must be a mobx observer, otherwise the component will
 * not update when the router state changes.
 *
 * Typically the router is retrieved via `useRouterStore`, or from
 * the instance in the mobx root store.
 * @param router mobx-state-router instance
 * @param paramName
 * @param options
 * @returns
 */
export const observedParamState = <T>(
  router: RouterStore,
  paramName: string,
  options: ParamStateOptions<T>,
): QueryState<T> => {
  const { routerState } = router;
  const deserializer = options?.deserializer || defaultDeserializer;
  const serializer = options?.serializer || defaultSerializer;
  const value = deserializer(routerState.queryParams[paramName]);
  return {
    value:
      value === undefined && options?.defaultValue !== undefined ? options.defaultValue : value,
    setValue: (newValue: T) => {
      const stringValue = serializer(newValue);
      const newParams = { ...routerState.queryParams };
      if (stringValue) {
        newParams[paramName] = stringValue;
      } else {
        delete newParams[paramName];
      }
      router.goTo(routerState.routeName, {
        params: routerState.params,
        queryParams: newParams,
        replaceHistory: !!options?.replaceHistory,
      });
    },
  };
};
