import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';

import * as patientReviewParqRenderer from 'src/components/forms/renderers/patientReviewParq';
import v100 from 'src/components/forms/schemas/eventResult/prescriberPlanInitial/1.0.0';
import PatientOverviewLink from 'src/components/general/patientOverviewLink';

const TooltipBoolean = ({ model, classes, schema }) => {
  return (
    <div className={classes.itemDetails}>
      <span>{schema.title}</span>
      {schema.tooltip && (
        <Typography
          key="tooltip"
          variant="body2"
          className={classNames(classes.multiLineText, classes.tooltip)}
        >
          {schema.tooltip}
        </Typography>
      )}
      {Object.keys(schema.properties).map(prop => {
        const field = schema.properties[prop];
        switch (field.type) {
          case 'boolean':
            return (
              model[prop] === true && (
                <div key={prop} className={classes.marginTop}>
                  <span key={field.title}>{field.title}: Yes</span>
                  {field.tooltip && (
                    <Typography
                      key={`${field.title}-tooltip`}
                      variant="body2"
                      className={classNames(classes.multiLineText, classes.tooltip)}
                    >
                      {field.tooltip}
                    </Typography>
                  )}
                </div>
              )
            );
          case 'string':
            return (
              model[prop] && (
                <div key={prop} className={classes.marginTop}>
                  <Typography variant="body2" className={classes.multiLineText}>
                    {model[prop]}
                  </Typography>
                </div>
              )
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default {
  type: 'object',
  title: 'Prescriber: Plan (Initial)',
  properties: {
    oudPlan: {
      type: 'object',
      title: 'OUD Plan?',
      format: 'selectable',
      properties: {
        oudPlanReview: {
          type: 'object',
          title: 'OUD Plan Review',
          renderer: patientReviewParqRenderer,
          properties: v100.properties.patientReviewParq.properties,
        },
        notRelevant: true,
      },
    },
    audPlan: {
      title: 'AUD Plan?',
      type: 'object',
      format: 'selectable',
      tooltip: (
        <>
          <p>
            Discussed significant risks associated with alcohol withdrawal including, but not
            limited to, risk of death, seizure, delirium.
          </p>
          <p>
            By history available, patient <strong>does require</strong> withdrawal management that
            is outside of the scope of telehealth practice, and the recommendation for this
            treatment was made.
          </p>
          <p>
            By comprehensive history and evaluation, patient <strong>does not require</strong>{' '}
            withdrawal management (no history of/no current withdrawal symptoms when discontinues
            alcohol, or &gt;7-d since last drink, or does not desire alcohol cessation, just
            reduction).
          </p>
        </>
      ),
      properties: {
        acceptsReferral: {
          type: 'object',
          title: 'Requires withdrawal management, patient ACCEPTS referral',
          renderer: {
            component: TooltipBoolean,
          },
          tooltip: `Discussed significant risks associated with alcohol withdrawal including, but not limited to, risk of death, seizure, delirium. By history available, patient does require withdrawal management that is outside of the scope of telehealth practice, and the recommendation for this treatment was made.`,
          properties: {
            completedWarmHandoffToPeerCoach: {
              type: 'boolean',
              title: 'Completed warm handoff to Peer Coach',
              tooltip: `Patient agrees to withdrawal management recommendation. Peer Coach contacted and warm handoff made to Peer Coach to assist in coordination of care to monitored withdrawal management.`,
            },
            discussedBoulderHandlingFollowUpCare: {
              type: 'boolean',
              title: 'Discussed Boulder handling follow up care',
              tooltip: `Following withdrawal management in appropriate monitored setting, patient will follow up with me to coordinate release of records from that care, and begin MAT for AUD.`,
            },
            patientAgreedToGoToLocalEdForAcuteWithdrawal: {
              type: 'boolean',
              title: 'Patient agreed to go to local ED for acute withdrawal',
              tooltip: `Patient agrees to access local ED for acute withdrawal in the case that alternatives for withdrawal management are not timely or feasible.`,
            },
            acceptsReferralNotes: {
              type: 'string',
              title: 'AUD Plan Notes',
              uniforms: {
                multiline: true,
              },
            },
          },
        },
        declinesReferral: {
          type: 'object',
          title: 'Requires withdrawal management, patient DECLINES referral',
          renderer: {
            component: TooltipBoolean,
          },
          tooltip: `Discussed significant risks associated with alcohol withdrawal including, but not limited to, risk of death, seizure, delirium. By history available, patient does require withdrawal management that is outside of the scope of telehealth practice, and the recommendation for this treatment was made.`,
          properties: {
            discussedRisksAndRecommendations: {
              type: 'boolean',
              title: 'Discussed risks and recommendations',
              tooltip: `In spite of counseling about the risks associated with alcohol withdrawal and my recommendation for inpatient withdrawal management, patient is declining referral. I let the patient know that withdrawal management is outside the acceptable scope of telehealth practice and poses a potential significant risk to the health and safety of the patient. Recommendation was made for slow reduction in alcohol intake without abrupt cessation, and utilization of the ED in the case of withdrawal symptoms.`,
            },
            followUpWithBoulderEncouraged: {
              type: 'boolean',
              title: 'Follow up with Boulder encouraged',
              tooltip: `Patient made aware they can follow up with Boulder in the case they have completed, or have decided to pursue recommended monitored withdrawal management.`,
            },
            declinesReferralNotes: {
              type: 'string',
              title: 'AUD Plan Notes',
              uniforms: {
                multiline: true,
              },
            },
          },
        },
        doesNotRequireWithdrawalManagement: {
          type: 'object',
          title: 'DOES NOT require withdrawal management',
          renderer: {
            component: TooltipBoolean,
          },
          tooltip: `Discussed significant risks associated with alcohol withdrawal including, but not limited to, risk of death, seizure, delirium. By comprehensive history and evaluation, patient does not require withdrawal management (no history of/no current withdrawal symptoms when discontinues alcohol, or >7-d since last drink, or does not desire alcohol cessation, just reduction).`,
          properties: {
            discussedMedicationTreatmentOptionsRisksBenefitsAndAlternatives: {
              type: 'boolean',
              title: 'Discussed medication treatment options, risks, benefits, and alternatives',
              tooltip: `Discussed options for treatment of AUD including naltrexone PO and IM, acamprosate, disulfiram, and off-label use of gabapentin and topiramate. We discussed the risks, benefits and alternatives.`,
            },
            discussedPsychosocialSupportOptions: {
              type: 'boolean',
              title: 'Discussed psychosocial support options',
              tooltip: `Discussed the role of psychosocial support in treatment of AUD (counseling, peer support, mutual help groups).`,
            },
            givenRisksPatientWantsToProceed: {
              type: 'boolean',
              title: 'Given risks, patient wants to proceed',
              tooltip: `Considering the above information, patient wishes to proceed with initiation of treatment.`,
            },
            discussedTestingMonitoringPlanIfNecessary: {
              type: 'boolean',
              title: 'Discussed testing/monitoring plan, if necessary',
              tooltip: `Plan for lab testing/monitoring if necessary discussed with patient.`,
            },
            reviewedHowToContactCareTeam: {
              type: 'boolean',
              title: 'Reviewed how to contact Care Team',
              tooltip: `Reviewed how and when to contact the care team.`,
            },
            audPlanNotes: {
              type: 'string',
              title: 'AUD Plan Notes',
              uniforms: {
                multiline: true,
              },
            },
          },
        },
        notRelevant: true,
      },
    },
    rx: {
      type: 'string',
      renderer: {
        component: ({ model, classes }) => {
          return (
            <Typography variant="body1" className={classes.multiLineText}>
              {model.rx}
            </Typography>
          );
        },
      },
    },
    oft: {
      type: 'string',
      title: 'OFT/MAM/Pill Count',
      renderer: {
        component: ({ model, classes }) => {
          return (
            <Typography variant="body1" className={classes.multiLineText}>
              {model.oft}
            </Typography>
          );
        },
      },
    },
    naloxone: {
      type: 'string',
      allowOther: true,
      enum: [
        'Already has',
        'Will receive in Boulder Box',
        'Resend',
        'Prescribed today',
        'Prescription needed',
      ],
    },
    reviewedUpdatedSerologies: {
      type: 'boolean',
      renderer: {
        title: 'Reviewed/updated Serologies Tested in the Past in Labs',
      },
      label: (
        <span>
          Reviewed/updated Serologies Tested in the Past in{' '}
          <PatientOverviewLink section="labs">&ldquo;Labs&rdquo;</PatientOverviewLink>{' '}
        </span>
      ),
    },
    labs: {
      type: 'object',
      title: 'Order Labs',
      renderer: {
        component: ({ model, schema }) => {
          const labLabels = Object.keys(schema.properties).reduce(
            (labels, field) => ({
              ...labels,
              [field]: schema.properties[field].title,
            }),
            {},
          );
          const labs =
            model.labs && Object.keys(model.labs).length > 0
              ? Object.keys(model.labs)
                  .filter(key => !!model.labs[key])
                  .map(key => labLabels[key])
              : [];
          return labs.length && <span>{labs.join(', ')}</span>;
        },
      },
      properties: {
        hiv: { type: 'boolean', title: 'HIV' },
        hepB: { type: 'boolean', title: 'Hep B' },
        hepC: { type: 'boolean', title: 'Hep C' },
        syphilis: { type: 'boolean', title: 'Syphilis' },
        lfts: { type: 'boolean', title: 'LFTs' },
        upt: { type: 'boolean', title: 'UPT' },
        bmp: { type: 'boolean', title: 'BMP' },
      },
    },
    followup: {
      type: 'string',
      title: 'Follow Up',
      allowOther: true,
      enum: ['1 week', '2 weeks', '1 month'],
    },
    previousRecords: {
      type: 'string',
      title: 'Previous records',
      allowOther: true,
      enum: ['Obtained', 'In-progress', 'Request', 'N/A'],
    },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: ['oudPlan', 'audPlan'],
};
