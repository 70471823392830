import React from 'react';

const getFilteredSymptoms = model =>
  Object.keys(model)
    .map(key => (Object.keys(titles).includes(key) && model[key] === true ? titles[key] : null))
    .filter(x => x);

const baseField = {
  type: 'boolean',
  hideRendering: true,
};

const schema = {
  type: 'object',
  title: 'Assessment: DSM-5 OUD',
  properties: {
    diagnosis: {
      displayOnly: true,
      renderer: {
        component: ({ model }) => {
          const symptoms = getFilteredSymptoms(model);
          let diagnosis = 'No OUD';
          if (symptoms.length >= 6) {
            diagnosis = 'Severe OUD';
          } else if (symptoms.length >= 4) {
            diagnosis = 'Moderate OUD';
          } else if (symptoms.length >= 2) {
            diagnosis = 'Mild OUD';
          }
          return <>{diagnosis}</>;
        },
      },
    },
    symptomsReported: {
      displayOnly: true,
      renderer: {
        component: ({ model }) => {
          const symptoms = getFilteredSymptoms(model);
          return (
            <>
              {symptoms.length > 0 ? (
                <ul>
                  {symptoms.map(symptom => (
                    <li key={symptom}>{symptom}</li>
                  ))}
                </ul>
              ) : (
                <span>None</span>
              )}
            </>
          );
        },
      },
    },
    usedMoreOrLongerThanIntended: {
      ...baseField,
      title: 'Used more or longer than intended',
      tooltip: 'Have you taken opioids in a larger amount or over a longer time than you planned?',
    },
    triedToCutBackOrStop: {
      ...baseField,
      title: 'Tried to cut back or stop',
      tooltip: 'Have you tried down to cut back or stop using on your own but been unsuccessful?',
    },
    timeSpentObtainingUsingRecovering: {
      ...baseField,
      title: 'Time spent obtaining, using, recovering',
      tooltip:
        'Do you spend a lot of your time figuring out how to get opioids, using opioids, or recovering from their effects?',
    },
    cravings: {
      ...baseField,
      title: 'Cravings',
      tooltip: 'Do you have strong cravings/desire to use opioids?',
    },
    hazardousUse: {
      ...baseField,
      title: 'Hazardous use',
      tooltip: 'Has your use led to problems at work, school, or home?',
    },
    continuedUseDespiteProblems: {
      ...baseField,
      title: 'Continued use despite problems',
      tooltip:
        'Have you kept using opioids even after having ongoing or repeated problems in your life as a result of your use?',
    },
    stoppedHobbies: {
      ...baseField,
      title: 'Stopped hobbies',
      tooltip:
        'Have you stopped doing or less frequently engage in activities (e.g. hobbies) because of your use?',
    },
    physicalDanger: {
      ...baseField,
      title: 'Physical danger',
      tooltip:
        'Has your use put you in physically dangerous situations (e.g. overdose, driving while under the influence, etc.)?',
    },
    continuedUseDespiteHealth: {
      ...baseField,
      title: 'Continued use despite health',
      tooltip:
        'Have you continued to use opioids even though they have caused you significant physical or mental health consequences?',
    },
    tolerance: {
      ...baseField,
      title: 'Tolerance',
      tooltip: 'Do you need to take more of the opioid to get high/feel euphoric?',
    },
    withdrawal: {
      ...baseField,
      title: 'Withdrawal',
      tooltip: 'When you stop taking opioids do you get withdrawal symptoms?',
    },
    preventingWithdrawal: {
      ...baseField,
      title: 'If no, preventing withdrawal',
      renderer: { title: 'Preventing withdrawal' },
      tooltip:
        'IF NO to above: "Do you keep taking opioids to prevent or get rid of withdrawal symptoms?"',
    },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
  omitFields: ['diagnosis', 'symptomsReported'],
};

const titles = Object.keys(schema.properties).reduce((booleanTitles, key) => {
  if (
    schema.properties[key].type === 'boolean' &&
    (schema.properties[key].title || schema.properties[key].renderer?.title)
  ) {
    const title = schema.properties[key].renderer?.title || schema.properties[key].title;
    return { ...booleanTitles, [key]: title };
  }
  return booleanTitles;
}, {});

export default schema;
