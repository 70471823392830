import { gql } from 'src/generated/gql';

export const GET_PATIENT_DROP_IN_HOURS = gql(`
  query DropInClinicPatientHours($patientId: String!) {
    getPatientDropInClinicHours(patientId: $patientId) {
      OFT
      FOLLOW_UP
      INTAKE
      PEER_VISIT
      REENGAGEMENT
      CASE_MANAGER_VISIT
    }
  }
`);
