import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type ApolloClient from 'apollo-client';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import { mutate, Key } from 'swr';

import { DEFAULT_CHART_PROPERTY_VALUE } from 'src/nightingale/data/ChartProperty.utils';
import { QueryResult as ChartDefinitionQueryResult } from 'src/nightingale/data/queries/getChartDefinitionSchemaQuery';
import { QueryResult as InteractionChartPropertyValuesQueryResult } from 'src/nightingale/data/queries/getChartPropertyValuesForInteraction';
import {
  SAVE_CHART_PROPERTIES,
  QueryResult as SavePropertyValueQueryResult,
} from 'src/nightingale/data/queries/saveChartProperties';
import { getSWRKey as getSWROverviewKey } from 'src/nightingale/data/useSWRChartOverview';
import { getSWRChartPropertyValuesKey } from 'src/nightingale/data/useSWRInteraction';
import type { ChartPropertyValue } from 'src/nightingale/types/types';

/**
 * Update sets of chart properties
 */
export const updateChartPropertyValues = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  patientId: string,
  values: ChartPropertyValue[],
  interactionReferenceId?: string,
) => {
  if (values.length < 0) return;

  // Serialize values to appease the BFF
  const serializedValues = values.map(({ value, ...rest }) => ({
    value: JSON.stringify(safeValue(value)),
    ...rest,
  }));

  const swrKey = interactionReferenceId
    ? getSWRChartPropertyValuesKey(patientId, interactionReferenceId)
    : getSWROverviewKey(patientId);

  // Optimistically update local cache
  const { previousValues } = await mutateSWRChartPropertyValues(swrKey, serializedValues);

  try {
    // Update values on the server
    const { data } = await apolloClient.mutate<SavePropertyValueQueryResult>({
      mutation: SAVE_CHART_PROPERTIES,
      variables: { patientId, properties: serializedValues, interactionReferenceId },
    });

    if (!data?.chartPropertyValues) {
      throw new Error('No chart property values returned from server');
    }

    // Update local cache with revalidated values from the server
    await mutateSWRChartPropertyValues(swrKey, data.chartPropertyValues);
  } catch (err) {
    // Roll back to last seen values from BFF and throw an error
    await mutateSWRChartPropertyValues(swrKey, previousValues);
    throw err;
  } finally {
    mutate(`PATIENT_LIMITED-${patientId}`);
  }
};

/**
 * Mutate SWR's Chart Property Value cache for the Ovierview
 * @see {@link https://swr.vercel.app/docs/mutation#mutate-based-on-current-data}
 */
const mutateSWRChartPropertyValues = async (
  swrKey: Key,
  values: ChartPropertyValue[],
): Promise<{ previousValues: ChartPropertyValue[]; values: ChartPropertyValue[] }> => {
  let previousValues: ChartPropertyValue[] = [];

  await mutate(
    swrKey,
    async (
      data: ChartDefinitionQueryResult | InteractionChartPropertyValuesQueryResult,
    ): Promise<ChartDefinitionQueryResult | InteractionChartPropertyValuesQueryResult> => {
      if (!data?.chartPropertyValues) return {} as ChartDefinitionQueryResult;

      previousValues = values.map(({ propertyName }) => ({
        ...DEFAULT_CHART_PROPERTY_VALUE,
        ...data.chartPropertyValues.find(value => value.propertyName === propertyName),
        propertyName,
      }));

      return {
        ...data,
        chartPropertyValues: [
          // filter out entries that exist in the `values` array
          ...data.chartPropertyValues.filter(({ propertyName }) =>
            values.every(value => value.propertyName !== propertyName),
          ),
          ...values,
        ],
      };
    },
    false,
  );

  return { previousValues, values };
};

/**
 * Convert values to be formatted as the API expects them to be.
 * E.g. an empty string should be `null`.
 */
const safeValue = (value: any) => {
  if (typeof value === 'string') return value.trim() || null;
  // Don't include __typename, which can be appended to objects by the
  // update/create mutations and can cause errors. This is a known issue:
  // https://github.com/apollographql/apollo-client/issues/1564
  if (isPlainObject(value)) return mapValues(omit(value, '__typename'), safeValue);
  if (Array.isArray(value)) return value.map(safeValue);
  return value;
};

export const __test__ = {
  mutateSWRChartPropertyValues,
  safeValue,
};
