import cloneDeep from 'lodash/cloneDeep';

import v170Schema from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.7.0';

const v180Schema = cloneDeep(v170Schema);

// add `outcome` as a required field
v180Schema.required = ['callType', 'outcome'];

export default v180Schema;
