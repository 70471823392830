import v110Schema from 'src/components/forms/schemas/eventResult/subjectiveInitial/1.1.0';

// Remove the old assessmentNotes property which will be replaced by `notes`.
const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  experienceBuprenorphine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buprenorphineAllergy,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  infectionConcern,
  ...v110Properties
} = v110Schema.properties;

export default {
  ...v110Schema,
  properties: {
    substanceUseHistory: {
      title: 'Summary of substance use history',
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
    treatmentExperience: {
      title: 'Summary of treatment experience',
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
    ...v110Properties,
  },
};
