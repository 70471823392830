import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { PhoneDialerEnabled, PhoneDialerDisabled } from 'src/components/featureflags/phoneDialer';
import { SUPPORT_NUMBER } from 'src/shared/util/constants';
import { colors } from 'src/util/colors';

const WelcomeMask = ({
  children,
  classes,
  headerClassName,
  headerText = 'Welcome to Boulder Care!',
  headerIcon,
}) => (
  <div className={classes.maskContainer}>
    <img className={classes.boulderIcon} src="/logotype_white.png" alt="Boulder" />
    <div className={classes.welcomeContents}>
      {headerIcon}
      {headerText && <h1 className={(classes.welcomeMessage, headerClassName)}>{headerText}</h1>}
      {children}
    </div>
    <div className={classes.welcomeFooter}>
      <strong>Questions?</strong> Give us a call at{' '}
      <PhoneDialerEnabled>
        <a className={classes.phoneLink} href={`tel:${SUPPORT_NUMBER.raw}`}>
          {SUPPORT_NUMBER.label}
        </a>
        .
      </PhoneDialerEnabled>
      <PhoneDialerDisabled>{SUPPORT_NUMBER.label}.</PhoneDialerDisabled>
    </div>
  </div>
);

const styles = () => ({
  maskContainer: {
    backgroundColor: colors.darkGray,
    height: '100vh',
    position: 'absolute',
    width: '100vw',
  },
  boulderIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    maxWidth: 150,
    padding: 20,
  },
  phoneLink: {
    color: 'inherit',
    '&:hover': {
      color: '#90C7A4',
    },
  },
  welcomeContents: {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    fontFamily: '"Roboto", sans-serif',
    textAlign: 'center',
  },
  welcomeMessage: { fontSize: 36 },
  welcomeFooter: {
    bottom: 0,
    fontStyle: 'italic',
    color: 'white',
    marginBottom: 20,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
});

export default withStyles(styles)(WelcomeMask);
