import {
  titleCase,
  withImplicitLabel as chartPropertyWithImplicitLabel,
} from 'src/nightingale/data/ChartProperty.utils';
import { summarization } from 'src/nightingale/summarization';
import type { Context } from 'src/nightingale/summarization/types';
import {
  ChartElement,
  ChartPropertyValue,
  AnyChartProperty,
  ChartPropertyAction,
  ChartPropertyWithValue,
} from 'src/nightingale/types/types';

/**
 * Add standardized label to the ChartElement object
 */
export const withLabel = (element: ChartElement): ChartElement => {
  const properties = element.properties?.map(chartPropertyWithImplicitLabel) ?? null;
  const label = element.label || titleCase(element.name);

  // Overwrite first ChartProperty's label with chart Element's label (display
  // name) if it's the only ChartProperty in the Element.
  if (properties.length === 1) properties[0].label = label;

  return {
    ...element,
    label,
    properties,
  };
};

/**
 * Add parsed summarization results to the ChartElement object
 */
export const withSummarization = (element: ChartElement, summarizationContext: Context) => ({
  ...element,
  summarization: summarization(element.summary, {
    ...summarizationContext,
    $chartElement: element,
  }),
  parsedInlineHelp: summarization(element.inlineHelp ?? '', {
    ...summarizationContext,
    $chartElement: element,
  }),
});

/**
 * Add Chart Property Values to a Chart Property
 */
export const withValue = (
  property: AnyChartProperty,
  value: ChartPropertyValue,
): ChartPropertyWithValue => {
  return {
    ...property,
    action: value ? value.action : ChartPropertyAction.Update,
    isEmpty: !!(value || {}).isEmpty,
    value: value ? JSON.parse(value.value) : null,
    notes: value?.notes ?? undefined,
    interactionId: value?.interactionId,
  };
};

/**
 * Add Chart Property Values to Chart Properties in the given Chart Element
 *
 * TODO: `properties` should have a return type of ChartPropertyWithValue[], but this has
 * side effects that spread to many files. Asserting as an AnyChartProperty array for now.
 */
export const withValues = (
  element: ChartElement,
  chartValues: Record<string, ChartPropertyValue>,
): ChartElement => ({
  ...element,
  properties: element.properties.map(property => {
    const value = chartValues[property.name];
    return withValue(property, value);
  }) as AnyChartProperty[],
});
