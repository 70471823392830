import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';

import { usePrevious } from 'src/nightingale/hooks/usePrevious';

/**
 * `useState` hook that updates when initializer changes
 * @see {@link https://stackoverflow.com/a/54866051}
 */
export const useControlState = <S>(value: S): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const [internalValue, setInternalValue] = useState(value);

  const prevValue = usePrevious(value);
  useEffect(() => {
    if (!isEqual(prevValue, value)) setInternalValue(value);
  }, [value, prevValue]);

  return [internalValue, setInternalValue];
};
