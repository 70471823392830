import Typography from '@material-ui/core/Typography';
import React from 'react';

import PatientOverviewLink from 'src/components/general/patientOverviewLink';
import ProviderCalendarLink from 'src/components/general/providerCalendarLink';

const notes = {
  type: 'string',
  uniforms: {
    multiline: true,
  },
};

const selectWithNotes = {
  type: 'object',
  format: 'selectable',
  properties: {
    yes: {
      type: 'object',
      properties: { notes },
    },
    no: true,
  },
};

const renderer = key => ({
  component: ({ model, classes }) => (
    <div className={classes.itemDetails}>
      <span>{model[key].selectionKey === 'yes' ? 'Yes' : 'No'}</span>
      <span>
        {model[key].additional && (
          <Typography variant="body2" className={classes.multiLineText}>
            {model[key].additional.notes}
          </Typography>
        )}
      </span>
    </div>
  ),
});

export default {
  type: 'object',
  title: 'Care Advocate: Patient Check In',
  properties: {
    techChallenges: {
      ...selectWithNotes,
      renderer: renderer('techChallenges'),
    },
    newRecentStressOrChallenges: {
      ...selectWithNotes,
      renderer: renderer('newRecentStressOrChallenges'),
    },
    daysOfMedicationLeft: {
      type: 'integer',
      min: 0,
      uniforms: {
        fullWidth: true,
        margin: 'dense',
      },
    },
    confirmedDiagnosis: {
      type: 'boolean',
      label: (
        <span>
          Confirmed <PatientOverviewLink section="substanceUse">Diagnosis</PatientOverviewLink>
        </span>
      ),
    },
    updatedSubstanceUseHistory: {
      type: 'boolean',
      label: (
        <span>
          Updated{' '}
          <PatientOverviewLink section="substanceUse">Substance Use History</PatientOverviewLink>
        </span>
      ),
    },
    confirmedMedicalInformation: {
      type: 'boolean',
      label: (
        <span>
          Confirmed <PatientOverviewLink section="medical">Medical Information</PatientOverviewLink>
        </span>
      ),
    },
    confirmedPharmacy: {
      type: 'boolean',
      title: 'Confirmed Pharmacy',
      label: (
        <span>
          Confirmed <PatientOverviewLink section="services">Pharmacy</PatientOverviewLink>
        </span>
      ),
    },
    confirmedNextVisit: {
      type: 'boolean',
      title: 'Confirmed Next Visit',
      label: (
        <span>
          Confirmed <ProviderCalendarLink>Next Visit</ProviderCalendarLink>
        </span>
      ),
    },
    questionsConcerns: {
      ...notes,
      title: 'Questions/Concerns',
    },
    additionalNotes: notes,
  },
  required: [],
};
