import { Box } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { format } from 'date-fns-tz';
import React, { ChangeEvent, FunctionComponent } from 'react';

export type TelemetrySignalSection = {
  label: 'string'; // section summary
  // data grouped by section
  values: Array<{
    label: string; // telemetry property
    value: string; // telemetry value
  }>;
};

interface SignalConnectionTelemetryDataPanelProps {
  connectionId: string;
  telemetryData: TelemetrySignalSection[] | undefined;
  lastUpdated: Date | undefined;
  expanded: string | boolean;
  handleChange: (
    connectionId: string,
  ) => ((event: ChangeEvent<Record<string, unknown>>, expanded: boolean) => void) | undefined;
}

const SignalConnectionTelemetryDataPanel: FunctionComponent<
  SignalConnectionTelemetryDataPanelProps
> = ({ telemetryData, connectionId, lastUpdated, expanded, handleChange }) => {
  const classes = useStyles();
  return (
    <MuiAccordion
      id={`patientTelemetryPanel-${connectionId}`}
      classes={{
        root: classes.accordionWrapper,
        expanded: classes.accordionWrapperExpanded,
      }}
      onChange={handleChange(connectionId)}
      expanded={expanded === connectionId}
      TransitionProps={{ timeout: 0 }}
    >
      <MuiAccordionSummary classes={{ root: classes.accordionSummaryWrapper }}>
        <InfoIcon />
        {expanded === connectionId && (
          <div className={classes.lastUpdatedInfo}>
            Last Updated: {lastUpdated ? format(lastUpdated, 'yyyy-MM-dd HH:mm:ss zzz') : '-'}
          </div>
        )}
      </MuiAccordionSummary>
      <MuiAccordionDetails
        classes={{
          root: classes.accordionDetailsWrapper,
        }}
      >
        <Box className={classes.details} display="flex" flexDirection="column" flexGrow={1}>
          {telemetryData !== undefined
            ? telemetryData.map(section => (
                <Box
                  id={`patientTelemetryPanel-${section}-section`}
                  className={classes.section}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box
                    id={`patientTelemetryPanel-${section}-section-header`}
                    className={classes.row}
                    display="flex"
                    flexGrow={1}
                  >
                    <Box className={classes.sectionHeaderLabel} flex={1} flexGrow={1}>
                      {section.label}
                    </Box>
                    <Box className="empty" flex={1} flexGrow={1} />
                  </Box>

                  <Box
                    id={`patientTelemetryPanel-${section}-section-data`}
                    className={classes.sectionData}
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                  >
                    {section?.values?.map(data => (
                      <Box className={classes.row} display="flex" flexGrow={1}>
                        <Box className={classes.sectionDataLabel} flex={1} flexGrow={1}>
                          {data.label}:
                        </Box>
                        <Box className={classes.sectionDataValue} flex={1} flexGrow={1}>
                          {data.value}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))
            : null}
        </Box>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};
const useStyles = makeStyles({
  accordionWrapper: {
    backgroundColor: 'rgb(0 0 0 / 55%)',
    backdropFilter: 'blur(5px)',
    color: 'white',

    margin: '24px',
    padding: '0px',

    borderRadius: '100px !important', // override MuiAccordion

    width: '48px',
    minWidth: '48px',
    minHeight: '48px',

    '&:before': {
      display: 'none',
    },
  },
  accordionWrapperExpanded: {
    // NOTE: expanding needs to override styles, maybe there is an api on component?
    margin: '24px !important', // override MuiAccordion
    padding: '0px !important', // override MuiAccordion
    borderRadius: '12px !important', // override MuiAccordion

    // responsive width
    width: 'calc(100% - 240px)',
    minWidth: '380px',
    maxWidth: '640px',
  },
  accordionSummaryWrapper: {
    padding: '0px 12px', // fixes icon horizontal position
    minHeight: '48px !important',
  },
  accordionDetailsWrapper: {
    // scrollable
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 'calc(70vh - 70px)', // do not go past controls
  },
  lastUpdatedInfo: {
    width: '100%',
    textAlign: 'right' as const,
    margin: 'auto',
    paddingRight: '7px',
    fontSize: '14px',
    fontStyle: 'italic',
  },
  details: {},
  section: {
    margin: '12px 0',
  },
  row: {
    gap: '20px',
    margin: '3px',
  },
  sectionHeaderLabel: {
    textAlign: 'right' as const,
    fontWeight: 600,
    padding: '3px',
  },
  sectionData: {},
  sectionDataLabel: {
    textAlign: 'right' as const,
    fontStyle: 'italic',
    fontWeight: 300,
  },
  sectionDataValue: {
    fontWeight: 600,
  },
});

export default SignalConnectionTelemetryDataPanel;
