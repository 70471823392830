import { format, parseISO } from 'date-fns';
import React from 'react';

export default {
  type: 'object',
  title: 'Care Advocate: Synchronous MAM',
  properties: {
    sentPrepMessage1DayBefore: {
      type: 'boolean',
      htmlTooltip: `
      <p>"The purpose of the medication monitoring visit is to make sure you are taking your medication correctly. You will be asked to show your care team member  your pill bottle/box of strips, and confirm the medication inside. Then you will take your medication and allow it to dissolve completely. The visit could last  up to 20 minutes (or more), depending on how long it takes your medication to dissolve.  Your face has to be visible during the entire time.</p>
      <p>Here are a few things you can do to make sure the visit goes as quickly as possible:</p>
      <ol>
        <li>Drink water ahead of time to keep your mouth from being too dry</li>
        <li>Avoid smoking a cigarette right before taking your medication to help you absorb more of it</li>
        <li>Prop your phone on something stable and sit in front of it in a comfortable spot</li>
        <li>Have your pill bottle/box ready</li>
        <li>Consider getting a book or setting up the TV ahead of time to distract you while we wait"</li>
      </ol>
      `,
    },
    readIntroScript: {
      type: 'boolean',
      htmlTooltip: `
      <p>"Thank you for meeting for this medication monitoring visit.</p>
      <p>During this visit I will ask you to show me your pill bottle (or strip box), count your pills (strips), and confirm the type of medication you have. Then I will watch you put your medication under your tongue, and wait with you while the medication gets absorbed, which could take 20 minutes or more.</p>
      <p>It is very important that your face continues to be visible to the camera during the entire visit.</p>
      <p>Therefore, if you need to use the restroom, or take care of anything in the next 20 minutes, please do so now."</p>
      `,
    },
    bottle: {
      type: 'object',
      renderer: {
        component: ({ model, classes }) => (
          <div className={classes.itemDetails}>
            <span>
              Confirmed Patient Name: {model.bottle.confirmedPatientName === true ? 'Yes' : 'No'}
            </span>
            <span>
              Confirmed Prescriber: {model.bottle.confirmedPrescriber === true ? 'Yes' : 'No'}
            </span>
            <span>
              Confirmed Medication and Dose:{' '}
              {model.bottle.confirmedMedicationAndDose === true ? 'Yes' : 'No'}
            </span>
            {model.bottle.dateOnBottle && (
              <span>Date on Bottle: {format(parseISO(model.bottle.dateOnBottle), 'PP')}</span>
            )}
          </div>
        ),
      },
      properties: {
        confirmedPatientName: { type: 'boolean' },
        confirmedPrescriber: { type: 'boolean' },
        confirmedMedicationAndDose: { type: 'boolean' },
        dateOnBottle: {
          type: 'string',
          format: 'date',
        },
      },
    },
    pillStrip: {
      type: 'object',
      renderer: {
        component: ({ model, classes }) => {
          // In view mode, if expected count and actual count are present, calculate
          // actual/expected, and display as N%: OK (if >=80%) or N%: Notify Prescriber
          // (if <80%). Round N to nearest whole number.
          const percent =
            !model.pillStrip.expectedCount || !model.pillStrip.actualCount
              ? null
              : Math.floor((model.pillStrip.actualCount / model.pillStrip.expectedCount) * 100);
          return (
            <div className={classes.itemDetails}>
              <span>
                Confirmed Color, Shape and Imprint:{' '}
                {model.pillStrip.confirmedColorShapeAndImprint === true ? 'Yes' : 'No'}
              </span>
              {model.pillStrip.expectedCount !== undefined && (
                <span>Expected Count: {model.pillStrip.expectedCount}</span>
              )}
              {model.pillStrip.actualCount !== undefined && (
                <span>Actual Count: {model.pillStrip.actualCount}</span>
              )}
              {percent && (
                <span>
                  {percent}%: {percent >= 80 ? 'OK' : 'Notify Prescriber'}
                </span>
              )}
            </div>
          );
        },
      },
      properties: {
        confirmedColorShapeAndImprint: {
          type: 'boolean',
          title: 'Confirmed Color, Shape and Imprint',
          label: (
            <span>
              Confirmed{' '}
              <a
                href="https://www.notion.so/boulder/Medication-Monitoring-52f440ab21f74c3ca75128f16d48caed"
                target="_blank"
                rel="noopener noreferrer"
              >
                Color, Shape and Imprint
              </a>
            </span>
          ),
        },
        expectedCount: {
          type: 'integer',
          min: 0,
          uniforms: {
            fullWidth: true,
            margin: 'dense',
          },
        },
        actualCount: {
          type: 'integer',
          min: 0,
          uniforms: {
            fullWidth: true,
            margin: 'dense',
          },
        },
      },
    },
    educatedAboutPrecipiatedWithdrawal: {
      type: 'boolean',
      label: (
        <span>
          Educated about{' '}
          <a
            href="https://www.notion.so/boulder/MAM-Precipitated-Withdrawal-Check-c505d0f468304da2a088c542e94dd8bf"
            target="_blank"
            rel="noopener noreferrer"
          >
            precipitated withdrawal
          </a>
        </span>
      ),
    },
    concernForPrecipitatedWithdrawal: {
      type: 'string',
      enum: ['No', 'Yes (do not proceed)'],
    },
    mam: {
      type: 'object',
      renderer: {
        component: ({ model, classes }) => (
          <div className={classes.itemDetails}>
            <span>
              Observed Dose Leave Bottle:{' '}
              {model.mam.observedDoseLeaveBottle === true ? 'Yes' : 'No'}
            </span>
            <span>
              Confirmed Dose Color, Shape and Imprint:{' '}
              {model.mam.confirmedDoseColorShapeAndImprint === true ? 'Yes' : 'No'}
            </span>
            <span>
              Observed Dose Under Tongue:{' '}
              {model.mam.observedDoseUnderTongue === true ? 'Yes' : 'No'}
            </span>
            <span>
              Observed Dose Dissolved: {model.mam.observedDoseDissolved === true ? 'Yes' : 'No'}
            </span>
          </div>
        ),
      },
      properties: {
        observedDoseLeaveBottle: { type: 'boolean' },
        confirmedDoseColorShapeAndImprint: {
          type: 'boolean',
          title: 'Confirmed Dose Color, Shape and Imprint',
          label: (
            <span>
              Confirmed{' '}
              <a
                href="https://www.notion.so/boulder/Medication-Monitoring-52f440ab21f74c3ca75128f16d48caed"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dose Color, Shape and Imprint
              </a>
            </span>
          ),
        },
        observedDoseUnderTongue: { type: 'boolean' },
        observedDoseDissolved: { type: 'boolean' },
      },
    },
    completedSuccessfully: {
      type: 'object',
      format: 'selectable',
      properties: {
        yes: {
          type: 'object',
          renderer: {
            component: ({ model, classes }) => (
              <div className={classes.itemDetails}>
                {model.completionTime && <span>Completion Time: {model.completionTime}</span>}
                {model.testDuration !== undefined && (
                  <span>Test Duration (minutes): {model.testDuration}</span>
                )}
              </div>
            ),
          },
          properties: {
            completionTime: {
              type: 'string',
              title: 'Completion Time (eg 12:00PM PST)',
            },
            testDuration: {
              type: 'integer',
              title: 'Test Duration (minutes)',
              min: 0,
              uniforms: {
                fullWidth: true,
                margin: 'dense',
              },
            },
          },
        },
        no: true,
      },
    },
    notesOrConcerns: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
