import { makeStyles } from '@material-ui/core';
import React from 'react';
import type { ErrorInfo } from 'react';

import boulderLogo from 'src/nightingale/assets/boulder-logo-white-140x31.png';

const ErrorScreen: React.FC<{
  error: Error;
  info: ErrorInfo;
}> = ({ error, info }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <nav className={classes.navBar}>
        <a href="/patients">
          <img src={boulderLogo} className={classes.logo} />
        </a>
      </nav>
      <div className={classes.content}>
        <div className={classes.message}>
          <p>An error has occurred. We&apos;re so sorry about the inconvenience.</p>
          <p>
            Please submit a{' '}
            <a
              className={classes.helpdeskLink}
              href="https://bouldercare.atlassian.net/servicedesk/customer/portal/2"
              rel="noreferrer"
              target="_blank"
            >
              help desk ticket
            </a>{' '}
            with a screenshot of your entire browser screen, including the error messages below, and
            a description of what steps you were taking when this error occurred. For example,
            <em>
              &quot;I was on the Patient Overview trying to update a birthdate when I saw this
              error.&quot;
            </em>{' '}
            It all helps us find and fix the issue!
          </p>
        </div>
        <pre className={classes.trace}>
          <strong>{`URL: ${window.location}`}</strong>
          {`
`}
          <strong>{`Release: ${process.env.REACT_APP_COMMIT_SHA}`}</strong>
          {`

`}
          <strong>{`${error.name}: ${error.message}`}</strong>
          {`
          ${info.componentStack}`}
        </pre>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    backgroundColor: '#EDF0F2',
    boxSizing: 'border-box',
    fontFamily: 'Nunito Sans',
    minHeight: '100vh',
  },
  navBar: {
    backgroundColor: '#002441',
    padding: '0 30px',
    minHeight: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logo: {
    width: 70,
    paddingRight: 20,
    position: 'relative',
    top: 1, // optical vertical align
  },
  content: {
    padding: '30px 30px',
  },
  message: {
    maxWidth: '40em',
    fontWeight: 'bold',
    '& p': {
      marginTop: 16,
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  helpdeskLink: {
    color: '#466C8A',
  },
  trace: {
    backgroundColor: '#FFFFFF',
    overflowX: 'auto',
    padding: 16,
    fontSize: 12,
  },
});

export default ErrorScreen;
