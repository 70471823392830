import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type ApolloClient from 'apollo-client';
import { mutate } from 'swr';

import type { QueryResult as RefreshPatientSnapshotTimeQueryResult } from 'src/nightingale/data/queries/refreshPatientSnapshotTime';
import { REFRESH_PATIENT_SNAPSHOT_TIME } from 'src/nightingale/data/queries/refreshPatientSnapshotTime';
import { getSWRInteractionSchemaKey } from 'src/nightingale/data/useSWRInteraction';
import logger from 'src/shared/util/logger';

export const refreshPatientSnapshotTime = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  interactionReferenceId: string,
) => {
  try {
    // Update values on the server
    await apolloClient.mutate<RefreshPatientSnapshotTimeQueryResult>({
      mutation: REFRESH_PATIENT_SNAPSHOT_TIME,
      variables: { interactionReferenceId },
    });

    // Update local cache to let the page know there was an update
    await mutate(getSWRInteractionSchemaKey(interactionReferenceId));
  } catch (err) {
    logger.error(`Error refreshing patient snapshot time: ${err}`);
    throw err;
  }
};
