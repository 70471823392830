import { makeStyles } from '@material-ui/styles';

export const useFormStyles = makeStyles({
  button: { marginLeft: 5 },
  form: {
    marginBottom: 20,
    display: 'flex',
  },
  labelInstruction: {
    fontSize: '0.75rem',
  },
});
