import gql from 'graphql-tag';
import stringify from 'json-stringify-safe';

const LOG_REMOTE = gql`
  query ($message: String!, $source: LogSource!, $level: LogLevel!) {
    clientLog(message: $message, source: $source, level: $level)
  }
`;

function formatLogMessage(args) {
  return args
    .map(arg => {
      if (arg instanceof Error) {
        const errorMessage = arg.message ? stringify(arg.message) : '';
        const errorStack = arg.stack || '';
        return `${errorMessage}\n${errorStack}`;
      }
      return stringify(arg);
    })
    .join(' ');
}

class Logger {
  client: any;
  source: unknown;

  addRemoteClient(client, source) {
    this.client = client;
    this.source = source;
  }

  log(level, ...args) {
    if (level in console) {
      console[level](...args);
    }

    if (this.client) {
      const message = formatLogMessage(args);

      try {
        this.client.query({
          query: LOG_REMOTE,
          variables: { message, source: this.source, level },
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
}

const loggerInstance = new Logger();

const LEVELS = ['debug', 'info', 'warn', 'error'] as const;
type Levels = typeof LEVELS[number];

export default {
  ...(LEVELS.reduce((acc, i) => {
    acc[i] = (...msg) => loggerInstance.log(i, ...msg);
    return acc;
  }, {}) as {
    [key in Levels]: (...args: unknown[]) => void;
  }),
  setClient: (client, source) => loggerInstance.addRemoteClient(client, source),
};
