import { types, flow, Instance } from 'mobx-state-tree';

import { Patient, Provider } from 'src/shared/stores/resource';
import { Pebble, PebblePriority, PebbleStatus, PebbleTopic } from 'src/stores/pebbles/domain';

export const DefaultPebbleValues = types.model('DefaultPebbleValues', {
  assignee: types.maybeNull(Provider),
  link: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  status: PebbleStatus,
  participant: types.maybeNull(Patient),
  priority: PebblePriority,
  title: types.maybeNull(types.string),
  topic: types.maybeNull(PebbleTopic),
});

export type DefaultPebbleValuesType = Instance<typeof DefaultPebbleValues>;

const defaultValues = {
  status: 'new',
  priority: 'normal',
};

const PebblesUi = types
  .model('PebblesUi', {
    createDialogOpen: types.optional(types.boolean, false),
    selectedPebbleId: types.maybeNull(types.string),
    selectedPebble: types.maybeNull(Pebble),
    createDialogDefaults: types.optional(DefaultPebbleValues, defaultValues),
  })
  .actions(self => ({
    toggleCreateDialog(initialPebbleContext?: Partial<DefaultPebbleValuesType>) {
      if (self.createDialogOpen) {
        self.createDialogOpen = false;
        return;
      }
      self.createDialogDefaults = { ...self.createDialogDefaults, ...initialPebbleContext };
      self.createDialogOpen = true;
    },
    cancelCreateDialog() {
      self.createDialogOpen = false;
    },
    selectPebble(id) {
      self.selectedPebbleId = id;
    },
    setSelectedPebble(pebble) {
      self.selectedPebble = pebble;
    },
    submitCreateDialog: flow(function* submitCreateDialog(doSubmit) {
      yield doSubmit();
      self.createDialogOpen = false;
    }),
  }));

export type PebblesUiType = Instance<typeof PebblesUi>;

export default PebblesUi;
