import gql from 'graphql-tag';

export const USER_SUMMARY = gql`
  fragment UserSummary on User {
    id
    firstName
    lastName
    timezone
    pronouns
    ... on Provider {
      providersMonitored {
        id
        firstName
        lastName
      }
      teamRole
      unreadMessageNotifications
      patientFacingDisplayName
    }
  }
`;

export interface UserSummary {
  id: string;
  firstName: string;
  lastName: string;
  timezone: string | null;
  pronouns: string | null;
  providersMonitored?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  teamRole?:
    | 'clinician'
    | 'care_advocate'
    | 'peer_coach'
    | 'billing_specialist'
    | 'case_manager'
    | 'registered_nurse'
    | 'enrollment_specialist'
    | 'navigator'
    | 'other_staff'
    | null;
  unreadMessageNotifications?: boolean | null;
  patientFacingDisplayName?: string | null;
}

export const USER_SUMMARY_LIMITED = gql`
  fragment UserSummaryLimited on User {
    id
    firstName
    lastName
    pronouns
  }
`;

export const USER_LIMITED_DISPLAY = gql`
  fragment UserLimitedDisplay on UserDisplay {
    id
    firstName
    lastName
    pronouns
    preferredName
    patientFacingDisplayName
  }
`;

export interface UserLimitedDisplay {
  id: string;
  firstName: string;
  lastName: string;
  pronouns: string | null;
  preferredName: string | null;
  patientFacingDisplayName: string | null;
}
