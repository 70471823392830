import gql from 'graphql-tag';

export const GET_ALL_CHART_PROPERTIES = gql`
  query getAllChartProperties {
    allChartProperties: allChartPropertiesJson
  }
`;

export type QueryResult = {
  allChartProperties: string;
};
