import DeleteIcon from '@material-ui/icons/Delete';
import { format, isPast } from 'date-fns';
import React from 'react';

import ActionsPopup from 'src/components/general/actionsPopup';
import { EVENT_STATUSES } from 'src/shared/util/events';
import { parseUnknownDate } from 'src/util/parseUnknownDate';

// Currently, the tooltips are only disabled if the instance is in the past. If that changes in the
// future then it will be necessary to change this from a constant to a property that's passed into
// the component.
const DISABLED_TOOLTIP = 'Past events cannot be edited';

const RecurrenceActionsPopup = ({ event, parentElement, onClose, onCancelRecurrence }) => {
  const isEventPast = isPast(parseUnknownDate(event.start));
  const isDisabled = isEventPast || event.status === EVENT_STATUSES.COMPLETED;

  return (
    <ActionsPopup
      parentElement={parentElement}
      onClose={onClose}
      actions={[
        {
          isDisplayed: true,
          isDisabled,
          onClick: () => onCancelRecurrence(event),
          iconComponent: DeleteIcon,
          tooltip: isDisabled && DISABLED_TOOLTIP,
          label: `Cancel Recurrence Starting ${format(parseUnknownDate(event.start), 'M/d')} ...`,
        },
      ]}
    />
  );
};

export default RecurrenceActionsPopup;
