import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { connectField, filterDOMProps } from 'uniforms';

class ImageField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      uploadProgress: 0,
    };
  }

  handleSelectFile = async event => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ uploading: true });
      const upload = await this.props.rootStore.uploadFile(event.target.files[0], progress => {
        this.setState({ uploadProgress: progress.loaded / progress.total });
      });
      this.props.onChange(upload);
      this.setState({ uploading: false });
    }
  };

  handleCancel = () => {
    this.props.rootStore.cancelUpload();
  };

  render() {
    const {
      classes,
      disabled,
      error,
      errorMessage,
      helperText,
      label,
      name,
      onChange,
      placeholder,
      showInlineError,
      value,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      rootStore,
      ...others
    } = this.props;
    const { uploading, uploadProgress } = this.state;

    return (
      <div className={classes.container}>
        {uploading && (
          <div className={classes.progressbar} style={{ width: `${uploadProgress * 100}%` }} />
        )}
        <TextField
          readOnly
          error={!!error}
          helperText={(error && showInlineError && errorMessage) || helperText}
          label={label}
          name={name}
          value={value ? value.filename : ''}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {uploading ? (
                  // TODO: Reshow after upgrading graphql-yoga
                  <Button
                    component="span"
                    size="small"
                    onClick={this.handleCancel}
                    style={{ display: 'none' }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <span style={{ display: 'inline-flex' }}>
                    {value && (
                      <CloseIcon
                        fontSize="small"
                        className={classes.miniIconButton}
                        onClick={() => onChange(null)}
                      />
                    )}
                    <label htmlFor="file-selector">
                      <FileIcon fontSize="small" className={classes.miniIconButton} />
                    </label>
                  </span>
                )}
              </InputAdornment>
            ),
          }}
          placeholder={placeholder}
          type="text"
          {...filterDOMProps(others)}
        />
        {error && showInlineError && errorMessage}
        <input
          disabled={!!disabled}
          style={{ display: 'none' }}
          accept="image"
          id="file-selector"
          type="file"
          onChange={newValue => disabled || this.handleSelectFile(newValue)}
        />
        {value && <img src={value.url} alt="preview" className={classes.previewImage} />}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    position: 'relative',
  },
  progressbar: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.3,
  },
  previewImage: {
    width: '100%',
    objectFit: 'contain',
  },
  miniIconButton: {
    cursor: 'pointer',
    color: 'hsl(0,0%,80%)',
    '&:hover': {
      color: 'hsl(0,0%,60%)',
    },
    padding: 5,
  },
});

export default connectField(withStyles(styles)(inject('rootStore')(observer(ImageField))));
