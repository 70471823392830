import keyBy from 'lodash/keyBy';
import useSWR from 'swr';

import { withLabel, withSummarization, withValues } from 'src/nightingale/data/ChartElement.utils';
import { DEFAULT_CHART_PROPERTY_VALUE, titleCase } from 'src/nightingale/data/ChartProperty.utils';
import {
  GET_CHART_DEFINITION_SCHEMA,
  QueryResult,
} from 'src/nightingale/data/queries/getChartDefinitionSchemaQuery';
import { makeSummarizationContext } from 'src/nightingale/summarization';
import { Context } from 'src/nightingale/summarization/types';
import {
  AnyChartProperty,
  ChartOverviewSection,
  ChartPropertyValue,
} from 'src/nightingale/types/types';
import useRootStore from 'src/stores/useRootStore';

type ChartOverview = {
  sections: ChartOverviewSection[];
};

/**
 * Stitches the chart overview definition schema and chart property values
 * together.
 */
const chartOverviewWithValues = (
  overview: ChartOverview,
  chartPropertyValues: ChartPropertyValue[],
  summarizationContext: Context,
): ChartOverview => {
  const chartValues = keyBy(chartPropertyValues, 'propertyName');
  const sections = overview.sections.map(({ elements, ...section }) => ({
    ...section,
    label: section.label || titleCase(section.name),
    elements: elements.map(element =>
      withSummarization(withLabel(withValues(element, chartValues)), summarizationContext),
    ),
  }));
  return { ...overview, sections };
};

/**
 * SWR cache identifier. Note that these are also the arguments that get passed to the
 * global fetcher.
 */
export const getSWRKey = (patientId: string) => [GET_CHART_DEFINITION_SCHEMA, { patientId }];

/**
 * Hook that gets the chart definition schema and all chart property values
 * from the BFF, stiches them together and adds parsed summaries.
 */
export const useSWRChartOverview = (
  patientId: string,
): {
  data?: {
    overview: ChartOverview;
    context: Context;
    propertiesByName: Record<string, AnyChartProperty>;
    missingRequiredFields: string[];
  };
  error: any;
  mutate: () => Promise<void>;
} => {
  const {
    data: {
      overview,
      chartProperties,
      chartPropertyValues,
      patient = { id: patientId, enrollmentDate: null, treatmentStartDate: null },
      missingRequiredFields,
    } = {},
    error,
    mutate,
  } = useSWR<QueryResult>(getSWRKey(patientId));
  const rootStore = useRootStore();

  let parsedData:
    | {
        overview: ChartOverview;
        context: Context;
        propertiesByName: Record<string, AnyChartProperty>;
        missingRequiredFields: string[];
      }
    | undefined;

  if (overview) {
    let parsedProperties: AnyChartProperty[];
    let parsedOverview: ChartOverview;

    try {
      parsedProperties = chartProperties ? JSON.parse(chartProperties) : [];
      parsedOverview = JSON.parse(overview);
      // Thorough typechecking would be prohibitively slow to execute (or maintain), but we can at
      // least give it a cursory look
      if (!Array.isArray(parsedProperties) || !Array.isArray(parsedOverview.sections)) {
        throw new Error('received invalid chart definitions response');
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
        mutate: async () => {
          await mutate();
        },
      };
    }

    const summarizationContext = makeSummarizationContext(
      chartPropertyValues || [],
      parsedProperties,
      {
        patient,
        provider: {
          id: rootStore.auth.user?.id || 'unknown',
          timezone: rootStore.auth.user?.timezone || 'America/Los_Angeles',
        },
      },
    );

    parsedData = {
      overview: chartOverviewWithValues(
        parsedOverview,
        chartPropertyValues || [],
        summarizationContext,
      ),
      context: summarizationContext,
      propertiesByName: keyBy(parsedProperties, 'name'),
      missingRequiredFields: missingRequiredFields || [],
    };
  }

  return {
    data: parsedData,
    error,
    mutate: async () => {
      await mutate();
    },
  };
};

export const __test__ = {
  DEFAULT_CHART_PROPERTY_VALUE,
  chartOverviewWithValues,
  getSWRKey,
};
