import { format, isValid, parseISO } from 'date-fns';
import compact from 'lodash/compact';

export const parseNumericQueryParam = (value: string) => {
  const numericValue = parseInt(value, 10);
  if (Number.isNaN(numericValue)) {
    console.warn(`Unable to parse numeric query param: "${value}"`);
    return undefined;
  }

  return numericValue;
};

export const unrecognizedQueryParam = (key: string) => {
  return `Unrecognized query param: "${key}"`;
};

export const parseDateOnlyQueryParam = (value: string) => {
  const dateValue = parseISO(value);
  if (!isValid(dateValue)) {
    console.warn(`Unable to parse date-formatted query param: "${value}"`);
    return undefined;
  }
  return format(dateValue, 'yyyy-MM-dd');
};

export const parseArrayQueryParam = <Output = unknown>(
  possibleArrayValue,
  subParser: (item: string) => Output,
) => {
  const arrayValue = Array.isArray(possibleArrayValue) ? possibleArrayValue : [possibleArrayValue];
  const ret: Output[] = [];

  arrayValue.forEach(item => {
    const parsedValue = subParser(item);

    if (parsedValue) {
      ret.push(parsedValue);
    }
  });

  return ret;
};

export const compactArray = <T>(param: T | T[]): T[] =>
  compact(Array.isArray(param) ? param : [param]);
