import useTypedSWR from 'src/components/general/useTypedSWR';
import { PATIENT_ORDERS_WITH_SHIPMENTS_QUERY } from 'src/labs/useSWRPatientOrdersWithShipments.gql';

export const useSWRPatientOrdersWithShipments = (patientId: string) => {
  const { data, error, mutate } = useTypedSWR([PATIENT_ORDERS_WITH_SHIPMENTS_QUERY, { patientId }]);

  return {
    data: data?.staff_listLabOrdersWithShipments ?? [],
    error,
    isLoading: !data && !error,
    mutate,
  };
};
