import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import InputLabel from '@material-ui/core/InputLabel';
import type { Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import React, { ChangeEvent, useState } from 'react';

import FindATimeControl, { FindATimeProps } from 'src/scheduling/components/findATime';

/**
 * Wrapper around the FindATime component, to put it in a collapsible box
 *
 * This could be generalized for use with any child component, probably.
 *
 * @param props The props for the FindATime, which will simply be passed through.
 */
export const CollapsibleFindATimeControl: React.FC<FindATimeProps> = props => {
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<Record<string, unknown>>, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  return (
    <div className={classes.accordionContainer}>
      <InputLabel shrink>Compare Availabilities</InputLabel>
      <Accordion onChange={handleChange} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
          {expanded ? 'Collapse calendars' : 'Expand to view calendars'}
        </AccordionSummary>
        <AccordionDetails>
          <FindATimeControl {...props} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export const useStyles = makeStyles<Theme>(theme => ({
  accordionContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionDetails-root': {
      paddingTop: 0,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummary: {
    color: '#999999',
    fontStyle: 'italic',
    fontSize: '90%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',

    // Keep the buttons visible at the bottom of the modal
    position: 'sticky',
    backgroundColor: 'white',
    bottom: -8, // Accounting for 8px somewhere for some reason
    padding: '20px 0px',
    right: 0,
    zIndex: 10, // Sit on top of 9 index calendar column
  },
  dropdown: {
    width: '48%',
  },
  dropdownSpacing: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
