import gql from 'graphql-tag';
import useSWR from 'swr';

export enum AutomationJobStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  CONDITION_NOT_MET = 'CONDITION_NOT_MET',
}

export interface AutomationJob {
  rule?: {
    id: string;
    title: string;
  };
  triggerContext: Record<string, unknown>;
  patientId?: string;
  date: string;
  status: AutomationJobStatus;
  failureReason?: string;
  results?: {
    testMode: boolean;
    condition?: string;
    conditionPassed: boolean;
    actionResults: Array<{
      type: string;
      config: Record<string, unknown>;
    }>;
  };
}

export type AutomationJobsFilter = {
  status?: AutomationJobStatus;
  rule?: string;
  patient?: string;
  date_gte?: string; // eslint-disable-line camelcase
};

interface AutomationJobsResult {
  automationJobs: AutomationJob[];
}

interface AutomationJobsVariables {
  where?: AutomationJobsFilter;
  limit?: number;
  offset?: number;
}

const QUERY = gql`
  query ($where: AutomationJobWhereInput, $limit: Int, $offset: Int) {
    automationJobs(where: $where, limit: $limit, offset: $offset) {
      rule {
        id
        title
      }
      triggerContext
      patientId
      date
      status
      failureReason
      results {
        condition
        conditionPassed
        testMode
        actionResults {
          type
          config
        }
      }
    }
  }
`;

export const useSWRAutomationJobs = (
  variables: AutomationJobsVariables,
): {
  data?: AutomationJobsResult;
  error?: Error;
} => {
  return useSWR([QUERY, variables]);
};
