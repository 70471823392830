import type { TooltipProps } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { makeStyles } from '@material-ui/styles';
import { RouterLink } from 'mobx-state-router';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import { getProviderId } from 'src/myDayToday/getProviderId';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

const TitleBar: React.FC<{
  actionIcon?: React.ReactElement;
  actionTooltip?: TooltipProps['title'];
  'data-testid'?: string;
  onAction?: React.MouseEventHandler<HTMLButtonElement>;
  routeName?: string;
  title: string;
  showMainCalendarIcon?: boolean;
}> = ({
  'data-testid': dataTestId,
  actionIcon = <AddIcon />,
  actionTooltip = '',
  onAction,
  routeName,
  title,
  showMainCalendarIcon,
}) => {
  const classes = useStyles();
  const providerId = getProviderId();
  return (
    <header className={classes.titleBar}>
      <div className={classes.title}>
        <PageTitle>{title}</PageTitle>
      </div>
      <div className={classes.spacer} />
      {onAction && (
        <div>
          <Tooltip title={actionTooltip}>
            <Fab
              size="small"
              color="secondary"
              onClick={onAction}
              className={classes.button}
              data-testid="title-bar-action-button"
            >
              {actionIcon}
            </Fab>
          </Tooltip>
        </div>
      )}
      {routeName && (
        <div>
          <RouterLink routeName={routeName}>
            <Tooltip title={actionTooltip}>
              <Fab
                size="small"
                color="secondary"
                className={classes.button}
                data-testid={dataTestId}
              >
                {actionIcon}
              </Fab>
            </Tooltip>
          </RouterLink>
        </div>
      )}
      {showMainCalendarIcon && providerId && (
        <div>
          <RouterLink
            routeName="showProvider"
            params={{ id: providerId }}
            queryParams={{ view: 'week' }}
          >
            <Tooltip title="Weekly View">
              <Fab
                size="small"
                color="secondary"
                className={classes.button}
                data-testid={dataTestId}
              >
                <CalendarToday />
              </Fab>
            </Tooltip>
          </RouterLink>
        </div>
      )}
    </header>
  );
};

const useStyles = makeStyles({
  titleBar: {
    display: 'flex',
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginLeft: 15,
    marginTop: -6,
  },
});

export default TitleBar;
