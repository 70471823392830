import { multilineStringDef } from 'src/components/forms/schemas/util';

export default {
  type: 'object',
  title: 'General: Visit Assessment',
  properties: {
    patientLossConcern: {
      type: 'string',
      title: 'Worried patient will be lost from care in the next 3 months?',
      enum: ['Extremely worried', 'Somewhat worried', 'Not worried', 'Unsure'],
    },
    notes: {
      ...multilineStringDef,
    },
  },
  required: [],
};
