import TextField from '@material-ui/core/TextField';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { connectField, filterDOMProps } from 'uniforms';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const Text = ({
  disabled,
  error,
  errorMessage,
  helperText,
  inputRef,
  label,
  name,
  onChange,
  placeholder,
  showInlineError,
  type,
  value,
  ...props
}) => (
  <TextField
    disabled={!!disabled}
    error={!!error}
    helperText={(error && showInlineError && errorMessage) || helperText}
    label={label}
    name={name}
    fullWidth={props.fullWidth || props.field.format === 'phone'}
    onChange={event => {
      if (!disabled) {
        let newValue = event.target.value;

        if (props.field.format === 'phone') {
          newValue = newValue.replace(/\D+/g, '');
        }

        // An empty string is considered an intentional value, and will get validated.
        // Let's override this and signal that an empty string is the same as un-setting the value.
        // See BA-364 for more details.
        if (newValue === '') {
          newValue = null;
        }

        onChange(newValue);
      }
    }}
    InputProps={
      props.field.format === 'phone'
        ? {
            shrink: String(!!label),
            inputComponent: TextMaskCustom,
          }
        : {}
    }
    placeholder={placeholder}
    ref={inputRef}
    type={props.field.format === 'phone' ? 'tel' : type}
    value={value}
    {...filterDOMProps(props)}
  />
);
Text.defaultProps = {
  fullWidth: true,
  margin: 'dense',
  type: 'text',
};

export default connectField(Text);
