import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import React, { Suspense, useCallback, useEffect } from 'react';

import Nav from 'src/components/app/nav';
import Login from 'src/components/pages/login';
import UnsupportedBrowser from 'src/components/pages/unsupportedBrowser';
import LazyLoadingSpinner from 'src/nightingale/components/common/LazyLoadingSpinner/LazyLoadingSpinner';
import type { RootStore } from 'src/stores/root';
import { isUnsupportedBrowser } from 'src/util';
import { inject } from 'src/util/inject';

function getContents(routeName: string, user) {
  if (isUnsupportedBrowser()) {
    return <UnsupportedBrowser />;
  }

  switch (routeName) {
    case 'login':
      return <Login />;
    case 'unsupportedBrowser':
      return <UnsupportedBrowser />;
    default:
      // Make sure we never render the app if the user isn't logged in!
      return user && <Nav />;
  }
}

/**
 * Switch between the login screen and the main app depending on the auth state.
 */
const Main: React.FC<{
  rootStore: RootStore;
}> = ({
  rootStore: {
    auth: { user },
    clearFlashMessage,
    routerStore,
    flashMessage,
    isEditing,
    flashDuration,
    showFlashMessage,
  },
}) => {
  const classes = useStyles();

  const handleUnload = useCallback(
    (event: Event) => {
      if (!isEditing) {
        return undefined;
      }

      // We can't configure the messaging that displays in the alert
      // because it's considered a security risk, but we still need to
      // return a non-empty string here to inform chrome to trigger
      // the default "potential unsaved changes" alert
      const confirmationMessage = 'stop';

      // Note: Do NOT delete this next line - this is what actually
      // informs the browser to display the confirmation dialog.
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      // eslint-disable-next-line no-param-reassign
      (event || window.event).returnValue = confirmationMessage as unknown as any; // Gecko + IE

      event.preventDefault();

      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    },
    [isEditing],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [handleUnload]);

  return (
    <Suspense fallback={<LazyLoadingSpinner />}>
      {/* TODO: find a way to show the transition spinner - see CARE-612 */}
      <Modal open={false} className={classes.progressContainer}>
        <CircularProgress className={classes.progress} />
      </Modal>
      {getContents(routerStore.routerState.routeName, user)}
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showFlashMessage}
          autoHideDuration={flashDuration}
          onClose={clearFlashMessage}
          message={<span>{flashMessage}</span>}
          action={[
            <IconButton color="inherit" onClick={clearFlashMessage} key="close">
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    </Suspense>
  );
};

const useStyles = makeStyles({
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    width: 100,
    height: 100,
    outline: 'none',
  },
});

export default inject<typeof Main>('rootStore')(observer(Main));
