import { isLocalhost } from 'src/shared/util/helpers';

/*
 * Retrieve the feature flag configuration for the local environment.
 * This looks for a local JSON file, configured with specific flags
 * that we want to enable/disable.
 */
export const getDevFeatureFlags = (): Record<string, boolean> => {
  if (!isLocalhost) {
    return {};
  }

  let devFlags = {};
  try {
    // eslint-disable-next-line global-require, import/no-unresolved
    devFlags = require('src/.featureFlags.json');
  } catch (err) {
    console.info('No local dev feature flags found.');
  }
  return devFlags;
};

/*
 * Retrieve feature flag config overrides from the URL query params.
 * This looks for the specific query params at the end of the URL, e.g.
 *      /patients/:ID?ff-careTierMigration=true&ff-participantHistory=false
 *
 * This should work as expected even if there are other query params, e.g.
 *      /providers/:ID?date=2020-11-16&view=week&ff-recurrenceSimplification=off
 * Both on/off and true/false are currently accepted for convenience.
 */
export const getUrlOverrideFeatureFlags = (): Record<string, boolean> => {
  const overriddenFlags = {};

  if (window.location.search) {
    const queryParams = window.location.search.replace('?', '').split('&');
    queryParams.forEach(queryParam => {
      const [paramName, paramValue] = queryParam.split('=', 2);
      if (paramName.substring(0, 3) === 'ff-') {
        overriddenFlags[paramName.substring(3)] = ['on', 'true'].includes(paramValue);
      }
    });
  }

  return overriddenFlags;
};
