import { makeStyles } from '@material-ui/core';

const scheduleTableStyles = makeStyles({
  controlRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  buttonRow: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export default scheduleTableStyles;
