import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import React from 'react';

import Autocomplete from 'src/components/forms/controls/autocomplete';

class ReferenceControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultOptions: null,
    };
  }

  async componentDidMount() {
    const defaultOptions = await this.props.loadOptions();
    this.setState({ defaultOptions });
  }

  render() {
    const {
      field: { name, value },
      form: { touched, errors, setFieldValue, setFieldTouched },
      label,
      labelFn,
      formatLabelFn,
      valueFn,
      onValueClick,
      loadOptions,
      isMulti,
      fixed,
      placeholder,
    } = this.props;
    const { defaultOptions } = this.state;

    // We don't want to use `undefined` as a value, since that ends up keeping
    // the current UI control state, whatever it may be.
    let finalValue = value || null;
    let isFrozen = null;

    if (isMulti && fixed) {
      const fixedValues = fixed.map(valueFn);
      isFrozen = item => fixedValues.includes(valueFn(item));
      const union = unionBy(finalValue, fixed, valueFn);
      finalValue = sortBy(union, item => (isFrozen(item) ? 0 : 1));
    }

    return (
      <Autocomplete
        name={name}
        value={finalValue}
        error={errors[name] && touched[name]}
        helperText={touched[name] && errors[name]}
        onBlur={() => setFieldTouched(name)}
        onChange={newValue => setFieldValue(name, newValue)}
        onValueClick={onValueClick}
        loadOptions={loadOptions}
        getOptionLabel={labelFn}
        getOptionValue={valueFn}
        formatOptionLabel={formatLabelFn}
        label={label}
        placeholder={placeholder}
        isClearable
        async
        defaultOptions={defaultOptions}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        isFrozen={isFrozen}
      />
    );
  }
}

export default ReferenceControl;
