import { gql } from 'src/generated/gql';

export const LOAD_MENU_PATIENT = gql(`
  query LoadMenuPatient($id: ID!) {
    menuPatient(id: $id) {
      id
      consentsToVoicemails
      dob
      firstName
      homeAddress {
        street1
        street2
        city
        state
        zip
      }
      isOnPayorWaitlist
      lastName
      phone
      preferredName
      profilePhoto
      pronouns
      timezone
      verificationStatus
      zendeskUrl
    }
  }
`);
