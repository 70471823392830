import gql from 'graphql-tag';

import { PROVIDER_ALL } from 'src/stores/fragments/providers';

export const LOAD_PROVIDER = gql`
  query Provider($id: ID) {
    provider(id: $id) {
      ...ProviderAll
      providersMonitored {
        id
        firstName
        lastName
        unreadPatientConversationCount
      }
    }
  }
  ${PROVIDER_ALL}
`;

export const LOAD_PROVIDERS = gql`
  query Providers($first: Int, $skip: Int, $orderBy: ProviderOrderByInput) {
    items: providers(orderBy: $orderBy, paging: { first: $first, skip: $skip }) {
      ...ProviderAll
    }
  }
  ${PROVIDER_ALL}
`;

export const LOAD_PROVIDERS_BY_NPI = gql`
  query ProvidersByNpi($where: ProviderWhereInput) {
    providers(where: $where) {
      id
      firstName
      lastName
      npi
    }
  }
`;

export type ProviderNpiLookupResult = {
  id: string;
  firstName: string;
  lastName: string;
  npi: string | null;
};
