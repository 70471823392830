import React from 'react';

import { getEventSubTypeIcon } from 'src/events/getEventSubtypeIcon';

export type EventSubTypeIconProps = {
  subType: string;
  size?: number;
};

export const EventSubTypeIcon = ({ subType, size }: EventSubTypeIconProps) => {
  const Icon = getEventSubTypeIcon(subType);
  const effectiveSize = size ?? 24;

  return <Icon style={{ width: effectiveSize, height: effectiveSize }} />;
};
