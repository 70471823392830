"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ne = ne;
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ne(a, b, options) {
  return !(0, _isEqual.default)(a, b) ? options.fn(this) : options.inverse(this);
}