import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connectField, filterDOMProps, BaseField } from 'uniforms';
import { SelectField } from 'uniforms-material';

class SelectWithOtherField extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    const { value, allowedValues } = props;
    this.state = { isMounted: false, lastOtherValue: allowedValues.includes(value) ? null : value };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentDidUpdate({ value: prevValue }) {
    const { allowedValues } = this.props;
    const { lastOtherValue } = this.state;
    if (this.shouldShowOther()) {
      // If the user just selected 'Other', move the focus to the text field
      if (allowedValues.includes(prevValue)) {
        const { current } = this.inputRef;
        if (current) {
          current.focus();
          current.select();
        }
      }
    } else if (lastOtherValue !== prevValue && !allowedValues.includes(prevValue)) {
      // Keep track of the last other value so we can put it in the text field
      // if the user switches away and then back.
      // (When we upgrade react a better place for this would be getDerivedStateFromProps)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ lastOtherValue: prevValue });
    }
  }

  shouldShowOther = () => {
    const { value, allowedValues } = this.props;
    return value !== null && value !== undefined && !allowedValues.includes(value);
  };

  render() {
    const { onChange, allowedValues, autoFocus, name, value, placeholder, classes, ...props } =
      this.props;
    const { lastOtherValue, isMounted } = this.state;

    const selectValues = !allowedValues.includes('Other')
      ? allowedValues.concat('Other')
      : allowedValues;
    const showOther = this.shouldShowOther();

    return (
      <div>
        <SelectField
          autoFocus={autoFocus}
          allowedValues={selectValues}
          onChange={newValue => {
            // Uniforms select will call onChange on the initial render
            // but we only want to call onChange when the value actually
            // changes
            if (isMounted) {
              if (newValue === 'Other') {
                onChange(lastOtherValue || '');
              } else {
                onChange(newValue);
              }
            }
          }}
          name={name}
          placeholder={placeholder}
          value={showOther ? 'Other' : value}
          {...filterDOMProps(props)}
        />
        {showOther && (
          <div className={classes.otherFieldContainer}>
            <TextField
              inputRef={this.inputRef}
              name={`${name}.other`}
              onChange={evt => onChange(evt.target.value)}
              value={value}
              {...filterDOMProps(props)}
            />
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  otherFieldContainer: {
    paddingLeft: 18,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: '#dddddd',
    marginTop: 10,
  },
};

SelectWithOtherField.defaultProps = {
  fullWidth: true,
};

SelectWithOtherField.contextTypes = BaseField.contextTypes;

export default withStyles(styles)(
  connectField(SelectWithOtherField, {
    includeInChain: false,
    overrideValue: true,
  }),
);
