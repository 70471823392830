import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { getAffirmation, generateAttribution } from 'src/shared/affirmations';
import { colors } from 'src/util/colors';

export default function DailyAffirmation() {
  const classes = useDailyAffirmationStyles();

  const affirmation = getAffirmation();
  const attribution = generateAttribution(affirmation);

  return (
    <div className={classes.affirmation}>
      <Typography align="right" className={classes.affirmationLabel}>
        Daily Affirmation
      </Typography>
      <Typography className={classes.quote}>{affirmation.quote}</Typography>
      <Typography align="right" className={classes.source}>
        {attribution}
      </Typography>
    </div>
  );
}

const useDailyAffirmationStyles = makeStyles({
  affirmation: {
    color: colors.darkGray,
    lineHeight: '150%',
    maxWidth: '50vw',
  },
  affirmationLabel: {
    fontSize: 12,
    color: '#929499',
  },
  quote: {
    fontSize: 14,
    fontStyle: 'italic',
    marginBottom: 2,
  },
  source: {
    fontSize: 11,
    letterSpacing: 1,
  },
});
