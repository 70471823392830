import Typography from '@material-ui/core/Typography';
import React from 'react';

export default {
  type: 'object',
  title: 'Care Advocate: Synchronous OFT',
  properties: {
    completedSuccessfully: {
      type: 'object',
      format: 'selectable',
      properties: {
        yes: {
          type: 'object',
          renderer: {
            component: ({ model, classes }) => (
              <div className={classes.itemDetails}>
                {model.completionTime && <span>Completion Time: {model.completionTime}</span>}
                {model.testDuration !== undefined && (
                  <span>Test Duration (minutes): {model.testDuration}</span>
                )}
                {model.labBarcode && <span>Lab Barcode: {model.labBarcode}</span>}
                {model.expectedResults && (
                  <>
                    <span>Expected Results:</span>
                    <span>
                      <Typography variant="body2" className={classes.multiLineText}>
                        {model.expectedResults}
                      </Typography>
                    </span>
                  </>
                )}
              </div>
            ),
          },
          properties: {
            completionTime: { type: 'string', title: 'Completion Time (eg 12:00PM PST)' },
            testDuration: {
              type: 'integer',
              title: 'Test Duration (minutes)',
              uniforms: { fullWidth: true },
            },
            labBarcode: { type: 'string' },
            expectedResults: { type: 'string', uniforms: { multiline: true } },
          },
        },
        no: true,
      },
    },
    notes: { type: 'string', uniforms: { multiline: true } },
    testKitsRemaining: {
      type: 'integer',
      uniforms: { fullWidth: true },
      tooltip: 'If 1 left, have more tests sent!',
    },
  },
  required: [],
};
