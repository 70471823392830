import gql from 'graphql-tag';

export const ACTIVITY_PEBBLES_QUERY = gql`
  query PatientActivityPebbles($where: PebbleWhereInput) {
    pebbles(where: $where) {
      id
      createdAt
      status
      title
      topic
      updatedAt
    }
  }
`;
