import React from 'react';

import PatientDocumentLink from 'src/components/general/patientDocumentLink';

export default {
  type: 'object',
  title: 'Care Advocate: Telehealth Precheck',
  properties: {
    checkedPatientConsents: {
      type: 'boolean',
      renderer: { trueValue: 'Yes' },
      title: 'Checked Patient Consents',
      label: (
        <span>
          Checked <PatientDocumentLink>Patient Consents</PatientDocumentLink>
        </span>
      ),
      tooltip:
        'If the patient is new, ensure that the patient has signed all 4 consents before their first provider visit',
    },
    confirmedPatientPrivacy: {
      type: 'boolean',
      tooltip:
        '"Are you alone? If not, who are you with, and are you comfortable speaking in front of them?"',
    },
  },
  required: [],
};
