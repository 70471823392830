import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import React from 'react';

import { PebbleHistory as History } from 'src/pebbles/types';
import { getExactFullName } from 'src/shared/stores/resource';

const PebbleHistory: React.FC<{ history: History }> = ({ history: { note = [] } = {} }) => {
  const classes = useStyles();

  return note ? (
    <div>
      {note.map(({ updatedAt, updatedBy, value }) => (
        <div key={updatedAt?.valueOf()}>
          <div className={classes.bylineContainer}>
            <span className={classes.bylineAuthor}>
              {updatedBy ? getExactFullName(updatedBy) : null}
            </span>
            <span className={classes.bylineDate}>{updatedAt ? format(updatedAt, 'PP') : null}</span>
          </div>
          <div className={classes.note}>{value}</div>
        </div>
      ))}
    </div>
  ) : null;
};

const useStyles = makeStyles(() => ({
  bylineContainer: {},
  bylineDate: {
    fontSize: 12,
    color: '#818181',
    marginLeft: 3,
  },
  bylineAuthor: {
    fontSize: 12,
    color: '#818181',
    fontWeight: 'bold',
  },
  note: {
    marginTop: 3,
    marginBottom: 12,
  },
}));

export default PebbleHistory;
