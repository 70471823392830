// This file was originally copied from 'uniforms-material/NestField'.
// Since CustomAutoField references this file as well, we have to disable
// the lint rule for dependency cycles.
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connectField, injectName, joinName } from 'uniforms';

/* eslint-disable import/no-cycle */
import AutoField from 'src/components/forms/fields/autoField';
import { nestedStyle } from 'src/components/forms/schemas/util';

/* eslint-enable */

const NestField = ({ autoFocus, children, classes, fields, itemProps, label, name, list }) => (
  <div className={classes.nestedFieldContainer}>
    {label && <label className={classes.nestedLabel}>{label}</label>}
    <div className={list ? classes.nestedFieldsInList : classes.nestedFields}>
      {children
        ? injectName(name, children)
        : fields.map((key, index) => (
            <AutoField
              key={key}
              name={joinName(name, key)}
              autoFocus={autoFocus && index === 0}
              {...itemProps}
            />
          ))}
    </div>
  </div>
);

NestField.defaultProps = {
  fullWidth: true,
  margin: 'dense',
};

export const styles = {
  nestedFieldContainer: {
    width: '100%',
  },
  nestedLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
  },
  nestedFields: {
    ...nestedStyle,
    marginTop: 10,

    '& > *': {
      marginTop: 10,
    },

    '& :first-child': {
      marginTop: 0,
    },
  },
  nestedFieldsInList: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderRadius: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#dddddd',
    padding: 15,
  },
};

export default withStyles(styles)(
  connectField(NestField, {
    ensureValue: false,
    includeInChain: false,
  }),
);
