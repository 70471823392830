import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import React from 'react';

import { getFieldTitle } from 'src/components/forms/renderers/base';
import FieldGroup from 'src/components/general/fieldGroup';

const titles = {
  usedMoreOrLongerThanIntended: 'Used more or longer than intended',
  triedToCutBackOrStop: 'Tried to cut back or stop',
  timeSpentObtainingUsingRecovering: 'Time spent obtaining, using, recovering',
  cravings: 'Cravings',
  hazardousUse: 'Hazardous use',
  continuedUseDespiteProblems: 'Continued use despite problems',
  stoppedHobbies: 'Stopped hobbies',
  physicalDanger: 'Physical danger',
  continuedUseDespiteHealth: 'Continued use despite health',
  tolerance: 'Tolerance',
  withdrawal: 'Withdrawal',
};

const tooltips = {
  aud: {
    usedMoreOrLongerThanIntended:
      'In the past year, have you had times when you ended up drinking more, or longer than you intended?',
    triedToCutBackOrStop: `In the past year, have you more than once wanted to cut down or stop drinking, or tried to, but couldn't?`,
    timeSpentObtainingUsingRecovering:
      'In the past year, have you spent a lot of time drinking? Or being sick or getting over the aftereffects?',
    cravings: 'In the past year, have you experienced craving—a strong need, or urge, to drink?',
    hazardousUse:
      'In the past year, have you found that drinking—or being sick from drinking—often interfered with taking care of your home or family? Or caused job troubles? Or school problems?',
    continuedUseDespiteProblems:
      'In the past year, have you continued to drink even though it was causing trouble with your family or friends?',
    stoppedHobbies:
      'In the past year, have you given up or cut back on activities that were important or interesting to you, or gave you pleasure, in order to drink?',
    physicalDanger:
      'In the past year, have you more than once gotten into situations while or after drinking that increased your chances of getting hurt (such as driving, swimming, using machinery, walking in a dangerous area, or having unsafe sex)?',
    continuedUseDespiteHealth:
      'In the past year, have you continued to drink even though it was making you feel depressed or anxious or adding to another health problem? Or after having had a memory blackout?',
    tolerance:
      'In the past year, have you had to drink much more than you once did to get the effect you want? Or found that your usual number of drinks had much less effect than before?',
    withdrawal:
      'In the past year, have you found that when the effects of alcohol were wearing off, you had withdrawal symptoms, such as trouble sleeping, shakiness, irritability, anxiety, depression, restlessness, nausea, or sweating? Or sensed things that were not there?',
  },
  oud: {
    usedMoreOrLongerThanIntended:
      'Have you taken opioids in a larger amount or over a longer time than you planned?',
    triedToCutBackOrStop:
      'Have you tried to cut back or stop using on your own but been unsuccessful?',
    timeSpentObtainingUsingRecovering:
      'Do you spend a lot of your time figuring out how to get opioids, using opioids, or recovering from their effects?',
    cravings: 'Do you have strong cravings/desire to use opioids?',
    hazardousUse: 'Has your use led to problems at work, school, or home?',
    continuedUseDespiteProblems:
      'Have you kept using opioids even after having ongoing or repeated problems in your life as a result of your use?',
    stoppedHobbies:
      'Have you stopped doing or less frequently engage in activities (e.g. hobbies) because of your use?',
    physicalDanger:
      'Has your use put you in physically dangerous situations (e.g. overdose, driving while under the influence, etc.)?',
    continuedUseDespiteHealth:
      'Have you continued to use opioids even though they have caused you significant physical or mental health consequences?',
    tolerance: 'Do you need to take more of the opioid to get high/feel euphoric?',
    withdrawal: 'When you stop taking opioids do you get withdrawal symptoms?',
  },
};

const generateFields = type =>
  mapValues(titles, (title, key) => {
    return {
      type: 'boolean',
      title,
      tooltip: tooltips[type][key],
    };
  });

/**
 * Reduce the form data to an array of the titles from the symptom options that were selected.
 * @param {object} model The form data which should be an object with the field keys and a boolean
 *   value representing whether the field was checked or not.
 */
const getFilteredSymptoms = model =>
  Object.keys(model)
    .filter(key => model[key] === true && key in titles)
    .map(key => titles[key]);

const Diagnosis = ({ title, symptoms }) => {
  let diagnosis = `No ${title}`;
  if (symptoms.length >= 6) {
    diagnosis = `Severe ${title}`;
  } else if (symptoms.length >= 4) {
    diagnosis = `Moderate ${title}`;
  } else if (symptoms.length >= 2) {
    diagnosis = `Mild ${title}`;
  }
  return (
    <FieldGroup label={`${title} Diagnosis`}>
      <span>{diagnosis}</span>
    </FieldGroup>
  );
};

const Symptoms = ({ title, symptoms }) => {
  return (
    <FieldGroup label={`${title} Symptoms Reported`}>
      {symptoms.length > 0 ? (
        <ul>
          {symptoms.map(symptom => (
            <li key={symptom}>{symptom}</li>
          ))}
        </ul>
      ) : (
        <span>None</span>
      )}
    </FieldGroup>
  );
};

/**
 * The `props` param is expected to contain a model property with the following structure:
 * ```
 * {
 *   assessments: [
 *     {
 *        [aud|oud]: { properties from SelectWithDependents field }
 *     }
 *   ]
 * }
 * ```
 * @param {object} props
 */
const Assessments = ({ model, schema, classes }) => {
  // Flatten the list assessments
  const flattenedAssessments = model.assessments.reduce((acc, assessment) => {
    return { ...acc, ...omit(assessment, '_id') };
  }, {});
  const assessmentTitles = mapValues(flattenedAssessments, (_, key) =>
    getFieldTitle(schema.items.properties[key], key),
  );

  return (
    <>
      {Object.entries(flattenedAssessments).map(([key, assessment], index) => {
        const assessmentSchema = schema.items?.properties[key];
        const symptoms = getFilteredSymptoms(assessment, assessmentSchema);
        return (
          <div key={key} className={classNames({ [classes.topSeparator]: index > 0 })}>
            <FieldGroup label="Assessment Type">{assessmentTitles[key]}</FieldGroup>
            <Diagnosis
              model={assessment}
              title={assessmentTitles[key]}
              schema={assessmentSchema}
              symptoms={symptoms}
            />
            <Symptoms
              model={assessment}
              title={assessmentTitles[key]}
              schema={assessmentSchema}
              symptoms={symptoms}
            />
            {assessment.notes && (
              <FieldGroup label="Notes">
                <Typography variant="body2" className={classes.multiLineText}>
                  {assessment.notes}
                </Typography>
              </FieldGroup>
            )}
          </div>
        );
      })}
    </>
  );
};

const notesField = {
  type: 'string',
  uniforms: {
    multiline: true,
  },
};

export default {
  type: 'object',
  title: 'Assessment: DSM-5 OUD/AUD',
  properties: {
    assessments: {
      title: 'DSM-5 Assessments',
      type: 'array',
      renderer: {
        component: Assessments,
      },
      items: {
        type: 'object',
        format: 'selectable',
        title: 'Assessment Type',
        properties: {
          oud: {
            title: 'OUD',
            type: 'object',
            properties: {
              ...generateFields('oud'),
              notes: notesField,
            },
          },
          aud: {
            title: 'AUD',
            type: 'object',
            properties: {
              ...generateFields('aud'),
              notes: notesField,
            },
          },
        },
      },
    },
  },
  required: [],
};
