import { IconInfoCircle } from '@tabler/icons-react';
import { differenceInMinutes } from 'date-fns';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import { EventTiming } from 'src/myDayToday/components/useScheduledEventTiming';

const POP_IN_RECOMMENDATION_TEXT =
  'This recommendation is based on clinician credentialing, licensing, availability time, types of visit clinician has seen so far today, and other factors.';

export const PopInVisitCardHeader = (props: {
  currentTime: Date;
  start: Date;
  timing: EventTiming;
}) => {
  const minuteDifference = differenceInMinutes(props.start, props.currentTime);

  if (props.timing === EventTiming.Imminent) {
    return (
      <>
        <div>
          Pop-in visit recommendation ready <span className="time">now</span>
        </div>
        <Tooltip title={POP_IN_RECOMMENDATION_TEXT} className="infoIcon">
          <IconInfoCircle />
        </Tooltip>
      </>
    );
  }

  if (props.timing === EventTiming.Late) {
    return (
      <>
        <div>
          Pop-in visit recommendation ready{' '}
          <span className="time">{Math.abs(minuteDifference)} min ago</span>
        </div>
        <Tooltip title={POP_IN_RECOMMENDATION_TEXT} className="infoIcon">
          <IconInfoCircle />
        </Tooltip>
      </>
    );
  }

  if (props.timing === EventTiming.Soon) {
    return (
      <>
        <div>
          Pop-in visit recommendation ready <span className="time">now</span>
        </div>
        <Tooltip title={POP_IN_RECOMMENDATION_TEXT} className="infoIcon">
          <IconInfoCircle />
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <div>
        Pop-in visit recommendation ready in <span className="time">{minuteDifference} min</span>
      </div>
      <Tooltip title={POP_IN_RECOMMENDATION_TEXT} className="infoIcon">
        <IconInfoCircle />
      </Tooltip>
    </>
  );
};
