import { withStyles, MenuItem } from '@material-ui/core/';
import React from 'react';
import Highlighter from 'react-highlight-words';

// TODO add unit tests
function findHighlightChunks({ searchWords, textToHighlight }) {
  const lcTextToHightlight = textToHighlight.toLowerCase();
  // we know we are only searching for one word/phrase - the thing the user is typing
  const searchWord = searchWords[0].toLowerCase();
  if (!searchWord) {
    return [];
  }
  // find every word that starts with our search word
  const chunks = [];
  let startIndex = 0;
  let index = lcTextToHightlight.indexOf(searchWord);
  while (index !== -1) {
    if (index === 0 || lcTextToHightlight.charAt(index - 1) === ' ') {
      chunks.push({
        highlight: true,
        start: index,
        end: index + searchWord.length,
      });
    }
    startIndex += searchWord.length;
    index = lcTextToHightlight.indexOf(searchWord, startIndex);
  }
  return chunks;
}

const SuggestionListItem = ({ item, current, onClick, classes }) => (
  <MenuItem
    component="div"
    selected={current}
    className={classes.suggestionListItem}
    onClick={onClick}
  >
    <Highlighter
      highlightClassName={classes.highlight}
      searchWords={[item.matched]}
      textToHighlight={item.option}
      autoEscape
      findChunks={findHighlightChunks}
    />
  </MenuItem>
);

const styles = theme => ({
  suggestionListItem: {
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.current': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  highlight: {
    textDecoration: 'underline',
    backgroundColor: 'transparent',
  },
});

export default withStyles(styles)(SuggestionListItem);
