"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapHelper = mapHelper;
var _map = _interopRequireDefault(require("lodash/map"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function mapHelper(context, path) {
  return (0, _map.default)(context, path);
}