import flatMap from 'lodash/flatMap';
import always from 'lodash/fp/always';

import { ChartElement, FlowStepType } from 'src/nightingale/types/types';
import type { FlowStep } from 'src/nightingale/types/types';

/**
 * Deduce what type of element a FlowStep is.
 * This logic depends on the `__typename` attribute, which is a graphql mechanism for indicating the
 * concrete type when responses include a union-type.
 * @see {@link FlowStep src/nightingale/types/types}
 * @param FlowStep
 */
export const getFlowStepType = (flowStep: FlowStep): FlowStepType => {
  switch (flowStep.__typename) {
    case 'Flow':
    case 'FlowRecord':
      return FlowStepType.Flow;
    case 'ChartElement':
    case 'InteractionChartElement':
    case 'ChartElementRecord':
    case 'InteractionChartElementRecord':
      return FlowStepType.ChartElement;
    default:
      return undefined;
  }
};

/**
 * Returns a function which examines a FlowStep (possibly recursively if it contains
 * other flows) to find any ChartElements inside it that satisfy the given predicate
 * function.
 *
 * @param predicate A function used to filter chart elements
 * @param rootStep The first step in the flow to examine
 * @returns An array of ChartElements that satisified the given predicate
 */
export const createElementMatcher =
  (predicate: (element: ChartElement) => boolean) =>
  (rootStep: FlowStep): ChartElement[] => {
    const relevantElements = (step: FlowStep): ChartElement[] => {
      if ('elements' in step) {
        return flatMap(step.elements, relevantElements);
      }

      return predicate(step) ? [step] : [];
    };

    return relevantElements(rootStep);
  };

export const allElementMatcher = createElementMatcher(always(true));
