import { makeStyles } from '@material-ui/styles';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import * as Yup from 'yup';

import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import type { EditFormProps } from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import Colors from 'src/nightingale/Colors';
import { ChartInteractionContext } from 'src/nightingale/components/ChartInteraction/ChartInteractionContext';
import type { AnyChartProperty } from 'src/nightingale/types/types';

const COMPLETE_EVENT_VALIDATOR = Yup.object().shape({
  appointmentNotes: Yup.string().nullable(),
});

const CompleteEvent: React.FC<Partial<EditFormProps> & { missingRequiredFields?: string[] }> = ({
  missingRequiredFields,
  ...rest
}) => {
  const { properties } = useContext(ChartInteractionContext);

  if (missingRequiredFields?.length) {
    // Yes; display error message with name of missing fields and disable buttons
    const classes = useStyles();
    return (
      <EditForm {...rest} savingDisabled cancelDisabled={false}>
        <div className={classes.errorHeader}>
          <b>
            There are outstanding required fields that must be completed before you can sign and
            complete this visit.
          </b>
        </div>
        <div className={classes.error}>
          <em>
            If you intended to leave a field blank or there is no answer, click on the three dots in
            the top right to choose &quot;Mark as None&quot;.
          </em>
        </div>
        <div className={classes.error}>
          Missing fields
          <ul>
            {missingRequiredFields.map(propName => {
              return (
                <li key={propName}>
                  {properties ? getLabelFromPropName(propName, properties) : propName}
                </li>
              );
            })}
          </ul>
        </div>
      </EditForm>
    );
  } else {
    // No; display appointment notes field
    return (
      <EditForm {...rest} validationSchema={COMPLETE_EVENT_VALIDATOR}>
        <Field
          name="appointmentNotes"
          component={TextControl}
          label="Primary condition(s) addressed, significant treatment plan changes, priorities for next visit"
          onKeyDown={e => e.stopPropagation()}
          multiline
        />
      </EditForm>
    );
  }
};

const getLabelFromPropName = (propName: string, properties: Record<string, AnyChartProperty>) => {
  if (propName.indexOf('.') > -1) {
    const [parentPropName, childPropName] = propName.split('.');
    const parentProp: AnyChartProperty = get(properties, parentPropName);

    if (!parentProp || !('properties' in parentProp)) return propName;

    const childProp = parentProp.properties.find(prop => prop.name === childPropName);

    if (!childProp) return propName;

    return `${parentProp.label || parentProp.name} (${childProp.label || childProp.name})`;
  }

  const prop = get(properties, propName);
  return prop?.label || prop?.name || propName;
};

const useStyles = makeStyles({
  errorHeader: {
    color: Colors.Coral,
    fontSize: 18,
    marginBottom: 10,
  },
  error: {
    color: Colors.Coral,
    fontSize: 14,
    marginBottom: 10,
  },
});

export default observer(CompleteEvent);
