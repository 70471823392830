export default [
  {
    quote: 'Forgive yourself.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      "Compassion is not a virtue — it is a commitment. It's not something we have or don't have — it's something we choose to practice.",
    source: 'Brene Brown',
    sourceDocument: 'I Thought It Was Just Me',
  },
  {
    quote: 'Character consists of what you do on the third and fourth tries.',
    source: 'James A. Michener',
    sourceDocument: '',
  },
  {
    quote: 'One step at a time.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'I am valuable.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'I never knew how strong I was until I had to forgive someone who wasn’t sorry, and accept an apology I never received.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      'Progress is impossible without change and those who cannot change their minds cannot change anything. ',
    source: 'George Bernard Shaw',
    sourceDocument: '',
  },
  {
    quote: 'Be open.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'If you focus on the hurt you will continue to suffer. If you focus on the lesson you will continue to grow.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'I am brave. ',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'Be the energy you want to attract.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: "If it doesn’t challenge you, it won't change you.",
    source: 'Fred DeVito',
    sourceDocument: '',
  },
  {
    quote: 'You can control how much of yesterday you will let take up today.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Positive mind. Positive life.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'I accept my lack of power over others and my power over myself.',
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: 'I am kind.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'No matter what happens, or how bad it seems today, life does go on, and it will be better tomorrow.',
    source: 'Maya Angelou',
    sourceDocument: '',
  },
  {
    quote: 'Awareness is the greatest agent for change. ',
    source: 'Eckhart Tolle',
    sourceDocument: 'A New Earth',
  },
  {
    quote: 'Inaction generates fear. Action generates hope. ',
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: 'It takes 10,000 hours to develop talent. How many hours have you clocked today?',
    source: 'Celestine Chua',
    sourceDocument: '',
  },
  {
    quote: 'If you want to soar in life you must first learn to FLY (First Love Yourself).',
    source: 'Mark Sterling',
    sourceDocument: '',
  },
  {
    quote:
      'Instead of worrying about what you cannot control, shift your energy to what you can create.',
    source: 'Roy T. Bennett',
    sourceDocument: 'The Light in the Heart',
  },
  {
    quote: 'Changing behaviors is not a race and takes effort and daily dedication.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      "Living a life in recovery is not always easy, but it's never as hard as living a life in active addiction.",
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: "You miss 100% of the shots you don't take. ",
    source: 'Wayne Gretzky',
    sourceDocument: '',
  },
  {
    quote: 'I am special.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'When we deny our emotion, it owns us. When we own our emotion, we can rebuild and find our way through the pain.',
    source: 'Brene Brown',
    sourceDocument: 'Braving the Wilderness',
  },
  {
    quote: 'Don’t start your day with broken pieces of yesterday.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      'You have to say I am forgiven again and again until it becomes the story you believe about yourself.',
    source: 'Cheryl Strayed',
    sourceDocument: 'Tiny Beautiful Things',
  },
  {
    quote: 'I want to.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'There are only two days in the year that nothing can be done. One is called Yesterday and the other is called Tomorrow. ',
    source: 'Dalai Lama XIV',
    sourceDocument: '',
  },
  {
    quote: 'Believe you can and you’re halfway there.',
    source: 'Theodore Roosevelt',
    sourceDocument: '',
  },
  {
    quote: 'If you own this story you get to write the ending.',
    source: 'Brene Brown',
    sourceDocument: 'Daring Greatly',
  },
  {
    quote: 'Life is simple. Everything happens for you, not to you.',
    source: 'Byron Katie',
    sourceDocument: 'A Thousand Names for Joy',
  },
  {
    quote: 'When given the choice between being right and being kind, choose kind.',
    source: 'Wayne W. Dyer',
    sourceDocument: '',
  },
  {
    quote: 'Keep going.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'Never allow yourself to be defined by someone else’s opinion of you.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: "Happiness is determined more by one's state of mind than by external events.",
    source: 'Dalai Lama XIV',
    sourceDocument: 'The Art of Happiness',
  },
  {
    quote: '"No" is a complete sentence.',
    source: 'Anne Lamott',
    sourceDocument: '',
  },
  {
    quote:
      'The greatest challenge in life is discovering who you are. The second greatest is being happy with what you find.',
    source: 'Auliq Ice',
    sourceDocument: '',
  },
  {
    quote: "If you can't change your fate, change your attitude.",
    source: 'Amy Tan',
    sourceDocument: '',
  },
  {
    quote: 'Just one small positive thought in the morning can change your whole day.',
    source: 'Dalai Lama XIV',
    sourceDocument: '',
  },
  {
    quote: 'Transformation is not accomplished by tentative wading at the edge.',
    source: 'Robin Wall Kimmerer',
    sourceDocument: 'Braiding Sweetgrass',
  },
  {
    quote: 'Your body and mind have been hurt. Give yourself permission to heal.',
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: 'I am worthy.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'How can you improve the quality of your life today for a better tomorrow?',
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: 'I am determined.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'The world is a dangerous place, not because of those who do evil, but because of those who look on and do nothing. ',
    source: 'Albert Einstein',
    sourceDocument: '',
  },
  {
    quote: 'Happiness is not something ready made. It comes from your own actions.',
    source: 'Dalai Lama XIV',
    sourceDocument: '',
  },
  {
    quote: 'Acceptance is a small, quiet room.',
    source: 'Cheryl Strayed',
    sourceDocument: 'Tiny Beautiful Things',
  },
  {
    quote: 'When they go low, we go high.',
    source: 'Michelle Obama',
    sourceDocument: '',
  },
  {
    quote:
      'It gets harder before it gets easier and it does get easier. Hang in here. You will see the benefits of your hard work.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'This is how you get unstuck. You reach.',
    source: 'Cheryl Strayed',
    sourceDocument: 'Tiny Beautiful Things',
  },
  {
    quote: 'Life begins at the end of your comfort zone.',
    source: 'Neale Donald Walsch',
    sourceDocument: '',
  },
  {
    quote:
      'In the beginning, it feels impossible to go even a day without using. Each day of recovery gets easier and easier.',
    source: 'Venus W. ',
    sourceDocument: '',
  },
  {
    quote: 'The past is no longer an excuse.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'I will face whatever comes today with a positive attitude.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'If you think you are too small to make a difference, try sleeping with a mosquito.',
    source: 'Dalai Lama XIV',
    sourceDocument: '',
  },
  {
    quote: 'Don’t fear failure. Fear being in the exact same place next year as you are today.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: "Go as far as you can see; when you get there, you'll be able to see farther.",
    source: 'J. P. Morgan',
    sourceDocument: '',
  },
  {
    quote: 'You alone are enough. You have nothing to prove to anybody.',
    source: 'Maya Angelou',
    sourceDocument: '',
  },
  {
    quote: "It's okay to look back at the past, just don't stare.",
    source: 'Benjamin Dover',
    sourceDocument: '',
  },
  {
    quote: 'Follow your dreams. They know the way. ',
    source: 'Kobi Yamada',
    sourceDocument: '',
  },
  {
    quote: 'I believe in miracles.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'The more you are motivated by love, the more fearless & free your action will be.',
    source: 'Dalai Lama XIV',
    sourceDocument: '',
  },
  {
    quote:
      'You are imperfect, you are wired for struggle, but you are worthy of love and belonging.',
    source: 'Brene Brown',
    sourceDocument: 'The Power of Vulnerability',
  },
  {
    quote: 'The secret of getting ahead is getting started.',
    source: 'Mark Twain',
    sourceDocument: '',
  },
  {
    quote: 'I have been given endless talents which I begin to utilize today.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      'At what point do you give up — decide enough is enough? There is only one answer really. Never.',
    source: 'Tabitha Suzuma',
    sourceDocument: 'Forbidden',
  },
  {
    quote: 'I choose joy.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'Don’t be afraid. Be focused. Be determined. Be hopeful. Be empowered.',
    source: 'Michelle Obama',
    sourceDocument: '',
  },
  {
    quote: 'Do what you can, with what you have, where you are.',
    source: 'Theodore Roosevelt',
    sourceDocument: '',
  },
  {
    quote: 'I am important.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'I am beautiful.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'Never give up believing in yourself.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Humility is not thinking less of yourself but thinking of yourself less. ',
    source: 'C. S. Lewis',
    sourceDocument: '',
  },
  {
    quote: 'Fear is a lonely dark place. Gratitude brings strength.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      "Don't start your day with broken pieces of the past. Yesterday ended last night. Today is a brand new day and it's yours.",
    source: 'Zig Ziglar',
    sourceDocument: '',
  },
  {
    quote: 'I am not defined by my past.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Because you are alive, everything is possible.',
    source: 'Thich Nhat Hanh',
    sourceDocument: 'Living Buddha, Living Christ',
  },
  {
    quote: 'We must accept finite disappointment, but never lose infinite hope.',
    source: 'Martin Luther King, Jr.',
    sourceDocument: '',
  },
  {
    quote: 'Be kind whenever possible. It is always possible.',
    source: 'Dalai Lama XIV',
    sourceDocument: '',
  },
  {
    quote: 'My determination sets me apart from the rest',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Believe.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'When the winds of change blow, some people build walls and others build windmills.',
    source: 'Chinese proverb',
    sourceDocument: '',
  },
  {
    quote: 'Slow down.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'Do not give your past the power to define your future.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'The best preparation for tomorrow is doing your best today.',
    source: 'H. Jackson Brown, Jr.',
    sourceDocument: 'P.S. I Love You',
  },
  {
    quote: 'Be yourself. Everyone else is already taken.',
    source: 'Oscar Wilde',
    sourceDocument: '',
  },
  {
    quote: 'I have hope. ',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'Take time to plan out your day. Staying focused does not come without effort.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Change the way you look at things and the things you look at change.',
    source: 'Wayne W. Dyer',
    sourceDocument: 'The Power of Intention',
  },
  {
    quote: 'You’re going through this today so you can help someone else tomorrow.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'We need never be hopeless because we can never be irreperably broken.',
    source: 'John Green',
    sourceDocument: 'Looking for Alaska',
  },
  {
    quote: 'Love is the absence of judgment.',
    source: 'Dalai Lama XIV',
    sourceDocument: '',
  },
  {
    quote:
      "Don't think about what do you have to do today, think about what you want to get out of today.",
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: "The most common way people give up their power is by thinking they don't have any.",
    source: 'Alice Walker',
    sourceDocument: '',
  },
  {
    quote: 'Nothing will work unless you do.',
    source: 'Maya Angelou',
    sourceDocument: '',
  },
  {
    quote: 'Cherish each breath.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'I am bold.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'Break down each minute. Be present. Feel powerful.',
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: 'I am enough.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'For every minute you are angry you lose sixty seconds of happiness.',
    source: 'Ralph Waldo Emerson',
    sourceDocument: '',
  },
  {
    quote: 'Perseverance is not a long race; it is many short races one after the other.',
    source: 'Walter Elliot',
    sourceDocument: '',
  },
  {
    quote: 'Courage is like a muscle, it is strengthened by use.',
    source: 'Ruth Gordon',
    sourceDocument: '',
  },
  {
    quote: 'Who I was is not who I am.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Within all beings there is the seed of perfection. ',
    source: 'Dalai Lama XIV',
    sourceDocument: 'The Art of Happiness',
  },
  {
    quote:
      "However bad life may seem, there is always something you can do and succeed at. Where there's life, there's hope.",
    source: 'Stephen Hawking',
    sourceDocument: '',
  },
  {
    quote: 'Be present.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: "Recovery isn't always easy, but it's always worth it.",
    source: 'Julee D.',
    sourceDocument: '',
  },
  {
    quote:
      'Happiness is not something you postpone for the future; it is something you design for the present.',
    source: 'Jim Rohn',
    sourceDocument: '',
  },
  {
    quote: 'The curious paradox is that when I accept myself just as I am, then I can change.',
    source: 'Carl Rogers',
    sourceDocument: 'On Becoming a Person',
  },
  {
    quote: 'We can never obtain peace in the outer world until we make peace with ourselves.',
    source: 'Dalai Lama XIV',
    sourceDocument: '',
  },
  {
    quote: 'Wordless, spirit speaks. In silence, we hear all answers.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Always help someone. You may be the only one who does.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Let it go.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'An overnight success is ten years in the making.',
    source: 'Tom Clancy',
    sourceDocument: '',
  },
  {
    quote:
      'If you want others to be happy, practice compassion. If you want to be happy, practice compassion.',
    source: 'Dalai Lama XIV',
    sourceDocument: 'The Art of Happiness',
  },
  {
    quote: 'Focus on what you want and let go of the attachment to the outcome.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Do not let the memories of your past limit the potential of your future.',
    source: 'Roy T. Bennett',
    sourceDocument: 'The Light in the Heart',
  },
  {
    quote:
      'Painful memories from past mistakes makes mastering the art of self-forgiveness a necessity to create a healthier future.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Great minds discuss ideas. Average minds discuss events. Small minds discuss people.',
    source: 'Eleanor Roosevelt',
    sourceDocument: '',
  },
  {
    quote: 'Fear holds you back. Courage moves you forward.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      'It is impossible for you to go on as you were before, so you must go on as you never have.',
    source: 'Cheryl Strayed',
    sourceDocument: 'Tiny Beautiful Things',
  },
  {
    quote:
      'The diamond cannot be polished without friction. You are the diamond, embrace the friction.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'For every minute you are angry you lose sixty seconds of happiness.',
    source: 'Ralph Waldo Emerson',
    sourceDocument: '',
  },
  {
    quote: "A setback is never a bad experience, it’s another one of life's lessons.",
    source: 'Richard Branson',
    sourceDocument: '',
  },
  {
    quote: 'The two things in life you have total control over are your attitude and your effort.',
    source: 'Billy Cox',
    sourceDocument: '',
  },
  {
    quote: 'I am strong.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'If you’ve lasted this long, you have courage. Redirect it to live the life you wanted when you were young.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Change your thought, change your action, change your outcome.',
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: 'But I know, somehow, that only when it is dark enough can you see the stars.',
    source: 'Martin Luther King, Jr.',
    sourceDocument: '',
  },
  {
    quote:
      'The people who are crazy enough to think that they can change the world are the ones who do.',
    source: 'Steve Jobs',
    sourceDocument: '',
  },
  {
    quote:
      'To hope is to give yourself to the future - and that commitment to the future is what makes the present inhabitable.',
    source: 'Rebecca Solnit',
    sourceDocument: 'Hope in the Dark',
  },
  {
    quote: 'Can you set a small goal today?',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      "Growth is painful. Change is painful. But nothing is as painful as staying stuck somewhere you don't belong.",
    source: 'Mandy Hale',
    sourceDocument: '',
  },
  {
    quote: 'My life was my life; I would have to stare it down, somehow, and make it work for me.',
    source: 'Paula McLain',
    sourceDocument: 'The Paris Wife',
  },
  {
    quote: 'You are not an observer, you are a participant.',
    source: 'Thich Nhat Hanh',
    sourceDocument: 'Being Peace',
  },
  {
    quote: 'In recovery, first steps seem hard, but they lead to great destinations.',
    source: 'Casey D.',
    sourceDocument: '',
  },
  {
    quote: 'What is your mission?',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'What is your purpose?',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'If you don’t get out there and define yourself, you’ll be quickly and inaccurately defined by others.',
    source: 'Michelle Obama',
    sourceDocument: 'Becoming',
  },
  {
    quote: 'When you react, you let other control you. When you respond, you are in control.',
    source: 'Bodhi Sanders',
    sourceDocument: 'Martial Arts Wisdom',
  },
  {
    quote:
      'Do the next right thing and you’ll always experience the right result–even if it’s not what you think it should be.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      'The 3 Cs of Life: Choices, Chances, Changes. You must make a choice to take a chance or your life will never change.',
    source: 'Zig Ziglar',
    sourceDocument: '',
  },
  {
    quote: 'He who treads the path of love walks a thousand miles as if it were only one. ',
    source: 'Japanese proverb',
    sourceDocument: '',
  },
  {
    quote: 'I believe in myself. ',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'Sometimes your joy is the source of your smile, but sometimes your smile can be the source of your joy.',
    source: 'Thich Nhat Hanh',
    sourceDocument: '',
  },
  {
    quote: 'What are you waiting for?',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'If you keep looking back you’ll miss what is right in front of you: choices.',
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: 'I am loved.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'Be careful with words because once they are said, they can only be forgiven, not forgotten.',
    source: 'Carl Sandburg',
    sourceDocument: '',
  },
  {
    quote: 'Every new beginning comes from some other beginning’s end.',
    source: 'Seneca',
    sourceDocument: '',
  },
  {
    quote: 'Go as far as you can today. Tomorrow you can go a little further.',
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote: 'What do you have to lose?',
    source: 'Casey D.',
    sourceDocument: '',
  },
  {
    quote:
      'Progress is impossible without change and those who cannot change their minds cannot change anything. ',
    source: 'George Bernard Shaw',
    sourceDocument: '',
  },
  {
    quote: 'I am in control of my reactions.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      "Part of being optimistic is keeping one's head pointed toward the sun, one's feet moving forward. ",
    source: 'Nelson Mandela',
    sourceDocument: 'Long Walk to Freedom',
  },
  {
    quote: 'Get curious.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      "No matter how many mistakes you make or how slow your progress, you’re still way ahead of everyone who isn't trying.",
    source: 'Tony Robbins',
    sourceDocument: '',
  },
  {
    quote: "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.",
    source: 'Roy T. Bennett',
    sourceDocument: 'The Light in the Heart',
  },
  {
    quote: 'It takes as much energy to wish as it does to plan.',
    source: 'Eleanor Roosevelt',
    sourceDocument: '',
  },
  {
    quote: 'Service to others is the rent you pay for your room here on Earth.',
    source: 'Muhammad Ali',
    sourceDocument: '',
  },
  {
    quote: 'Every person on this planet has a special gift. Discover yours.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Wounds are what break open the soul to plant the seeds of a deeper growth.',
    source: 'Ann Voskamp',
    sourceDocument: 'The Broken Way',
  },
  {
    quote: 'If today was your last day, what would your regrets be?',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'There are opportunities even in the most difficult moments.',
    source: 'Wangari Maathai',
    sourceDocument: 'Unbowed',
  },
  {
    quote: 'Believe in yourself and you will be unstoppable.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      'The past is a place of reference, not a place of residence; the past is a place of learning, not a place of living.',
    source: 'Roy T. Bennett',
    sourceDocument: 'The Light in the Heart',
  },
  {
    quote: 'Change “You can’t do this” to “You can do this.”',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      'Resilience is born by grounding yourself in your own loveliness, hitting notes you thought were way out of your range.',
    source: 'Gregory Boyle',
    sourceDocument: 'Tattoos on the Heart',
  },
  {
    quote:
      'Owning our story can be hard but not nearly as difficult as spending our lives running from it.',
    source: 'Brene Brown',
    sourceDocument: 'The Gifts of Imperfection',
  },
  {
    quote: 'Your story is what you have, what you will always have. It is something to own.',
    source: 'Michelle Obama',
    sourceDocument: 'Becoming',
  },
  {
    quote: 'I am powerful.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'May your choices reflect your hopes, not your fears.',
    source: 'Nelson Mandela',
    sourceDocument: '',
  },
  {
    quote: 'Just breathe.',
    source: '',
    sourceDocument: '',
  },
  {
    quote:
      'The key to happiness is letting each situation be what it is instead of what you think it should be.',
    source: 'Mandy Hale',
    sourceDocument: '',
  },
  {
    quote: 'There’s strength in numbers. Find a support group you like.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote:
      'There are so many people who will tell you that you can’t. What you’ve got to do is turn around and say “watch me.”',
    source: 'Layne Beachley',
    sourceDocument: '',
  },
  {
    quote:
      'If you think someone is strong enough to bring you down, show them you are strong enough to get back up.',
    source: 'A. Josland',
    sourceDocument: '',
  },
  {
    quote: 'You must do the thing you think you cannot do.',
    source: 'Eleanor Roosevelt',
    sourceDocument: 'You Learn by Living',
  },
  {
    quote: 'You are braver than you believe, stronger than you seem, and smarter than you think.',
    source: 'A. A. Milne',
    sourceDocument: '',
  },
  {
    quote: 'I am smart. ',
    source: '',
    sourceDocument: '',
  },
  {
    quote: 'You cannot swim for new horizons until you have courage to lose sight of the shore.',
    source: 'William Faulkner',
    sourceDocument: '',
  },
  {
    quote:
      'You may not control all the events that happen to you, but you can decide not to be reduced by them.',
    source: 'Maya Angelou',
    sourceDocument: 'Letter to My Daughter',
  },
  {
    quote: 'In 2 days tomorrow will be yesterday.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'Be patient.',
    source: '',
    sourceDocument: '',
  },
  {
    quote: "By allowing myself to be happy, I'm making the world a better place.",
    source: 'Ginger Ross',
    sourceDocument: '',
  },
  {
    quote:
      'If you focus on the hurt you will continue to suffer. If you focus on the lesson you will continue to grow.',
    source: 'Unknown',
    sourceDocument: '',
  },
  {
    quote: 'It is never too late to be what you might have been.',
    source: 'George Eliot',
    sourceDocument: '',
  },
];
