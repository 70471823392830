import gql from 'graphql-tag';

import { MESSAGE_ALL } from 'src/stores/fragments/messages';

// This is used the first time we attempt to load multiple
// messages in any conversation across the app (both chat and
// participant chat alike). This temporarily includes a fetch
// for otherProviders as that's been removed from the initial call
export const LOAD_MESSAGES = gql`
  query Messages($conversationId: ID!, $first: Int!) {
    conversations(orderBy: lastMessageAt_DESC, where: { id: $conversationId }) {
      lastMessageText
      otherProviders {
        userDisplay {
          id
          pronouns
          firstName
          lastName
          userType
          patientFacingDisplayName
        }
        writing
        lastRead
      }
    }
    messages(
      orderBy: createdAt_DESC
      where: { conversation: $conversationId }
      paging: { first: $first }
    ) {
      ...MessageAll
    }
  }
  ${MESSAGE_ALL}
`;

export const LOAD_LATEST_MESSAGES = gql`
  query LatestMessages($conversationId: ID!, $before: String) {
    messages(
      orderBy: createdAt_DESC
      where: { conversation: $conversationId }
      paging: { before: $before }
    ) {
      ...MessageAll
    }
    conversations(orderBy: lastMessageAt_DESC, where: { id: $conversationId }) {
      lastMessageText
    }
  }
  ${MESSAGE_ALL}
`;

export const LOAD_EARLIER_MESSAGES = gql`
  query EarlierMessages($conversationId: ID!, $after: String, $first: Int!) {
    messages(
      orderBy: createdAt_DESC
      where: { conversation: $conversationId }
      paging: { first: $first, after: $after }
    ) {
      ...MessageAll
    }
  }
  ${MESSAGE_ALL}
`;

export const LOAD_MESSAGE = gql`
  query Message($messageId: ID!) {
    message(id: $messageId) {
      ...MessageAll
    }
  }
  ${MESSAGE_ALL}
`;
