import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';

import { ChatTeamIcon } from 'src/chat/ChatTeamIcon';
import { useChatTeams } from 'src/chat/useChatTeams';

type ChatTeamMenuProps = {
  anchorEl: Element | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  onChange: (teamId: string | null) => void;
};

export const ChatTeamMenu = ({ isOpen, anchorEl, onClose, onChange }: ChatTeamMenuProps) => {
  const chatTeams = useChatTeams();

  const menuItemElements = Object.values(chatTeams).map(team => (
    <MenuItem key={team.id} onClick={() => onChange(team.id)}>
      <ChatTeamIcon backgroundColor={team.color} iconName={team.icon} size={20} />
      <span style={{ marginLeft: '0.5em' }}>{team.name}</span>
    </MenuItem>
  ));

  return (
    <Menu open={isOpen} anchorEl={anchorEl} onClose={onClose}>
      <MenuItem onClick={() => onChange(null)}>
        <ChatTeamIcon size={20} />
        <span style={{ marginLeft: '0.5em' }}>Unassign</span>
      </MenuItem>
      {menuItemElements}
    </Menu>
  );
};
