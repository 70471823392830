import { parseISO } from 'date-fns';

import type { Maybe, MyDayTodayPatient } from 'src/generated/gql/graphql';
import { EventCardPatient } from 'src/myDayToday/types/EventCardPatient';

/**
 * Transforms a patient object returned from the server into an object that can be displayed on the
 * My Day Today page.
 */
export function transformPatient(patient: Maybe<MyDayTodayPatient>): EventCardPatient {
  const lastVisit = patient?.lastVisit ? parseISO(patient?.lastVisit) : undefined;
  const medicationRunOutDate = patient?.medicationRunOutDate
    ? parseISO(patient.medicationRunOutDate)
    : undefined;

  return {
    avatarUrl: patient?.profileUrl || undefined,
    drugScreeningStatus: patient?.dspStatus || undefined,
    firstName: patient?.firstName || '',
    lastName: patient?.lastName || '',
    lastVisit,
    medicationRunOutDate,
    patientId: patient?.id || '',
    preferredName: patient?.preferredName,
    state: patient?.state || '',
    visitCadence: patient?.carePlan || undefined,
  };
}
