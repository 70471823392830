import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import RedoIcon from '@material-ui/icons/Redo';
import React from 'react';

import { getEventSubTypeIcon } from 'src/events/getEventSubtypeIcon';
import {
  EVENT_STATUSES,
  EVENT_COLORS,
  isPast,
  BLOCKED_EVENT_SUB_TYPES,
} from 'src/shared/util/events';

/**
 * A single event on the EventCalendar
 */
function CalendarEvent({ event, title: eventTitle, currentView, classes }) {
  const showIcon = ['week', 'day'].includes(currentView) && event.type !== 'availability';
  const icon = showIcon ? <EventIcon {...getEventIconProps(event)} /> : null;

  return (
    <div className={classes.eventTitle}>
      {icon}
      {eventTitle}
    </div>
  );
}

export function getEventIconProps(event) {
  const icon = getEventSubTypeIcon(event.subType);

  if ([EVENT_STATUSES.SCHEDULED, EVENT_STATUSES.CONFIRMED].includes(event.status)) {
    if (BLOCKED_EVENT_SUB_TYPES.includes(event.subType)) {
      return {
        icon,
        color: EVENT_COLORS.LIGHT_GRAY,
        width: '17px',
      };
    }

    if (isPast(event)) {
      return { icon, color: EVENT_COLORS.PEACH, width: '17px' };
    }
  }

  switch (event.status) {
    case EVENT_STATUSES.COMPLETED:
      return {
        icon: CheckIcon,
        color: EVENT_COLORS.GREEN,
      };
    case EVENT_STATUSES.RESCHEDULED:
      return {
        icon: RedoIcon,
        color: EVENT_COLORS.GRAY,
      };
    case EVENT_STATUSES.CANCELED:
      return {
        icon: DeleteIcon,
        color: EVENT_COLORS.GRAY,
      };
    case EVENT_STATUSES.SCHEDULED:
      return {
        icon,
        color: EVENT_COLORS.BLUE,
        width: '17px',
      };
    // eslint-disable-next-line no-fallthrough
    case EVENT_STATUSES.CONFIRMED:
      return {
        icon,
        color: EVENT_COLORS.GREEN,
        width: '17px',
      };
    default:
      return null;
  }
}

function EventIcon({
  icon: IconComponent,
  color,
  backgroundColor = 'transparent',
  width = '15px',
}) {
  const style = {
    width,
    color,
    background: `radial-gradient(circle, ${backgroundColor} 0%, ${backgroundColor} 100%)`,
    pointerEvents: 'none',
    height: 'min-content',
  };

  return <IconComponent style={style} />;
}

const styles = () => ({
  eventTitle: {
    display: 'flex',
    gap: '4px',
    verticalAlign: 'middle',
  },
});

export default withStyles(styles)(CalendarEvent);
