import Ajv from 'ajv';

import { PHONE, EMAIL } from 'src/shared/client/forms/validation/regexes';

const ajv = new Ajv({ allErrors: true, useDefaults: true });

ajv.addFormat('phone', PHONE);
ajv.addFormat('email', EMAIL);

ajv.addFormat('section', () => true);
ajv.addFormat('selectable', () => true);

ajv.addKeyword('allowOther', {
  statements: true,
  // This short-circuits the enum validation which will trigger if the field is set to 'Other'
  // since the 'Other' value isn't in the list of acceptable enum values.
  inline: (it, keyword, schema) =>
    !schema
      ? ''
      : `
      if (vErrors && vErrors.length) {
        vErrors = vErrors.filter(error => error.keyword !== 'enum');
        errors = vErrors.length;
      }
    `,
});

ajv.addKeyword('taggedText', {
  validate: function taggedTextValidation(schema, data) {
    if (schema === 'required' && data.text === '') {
      taggedTextValidation.errors = [];
      taggedTextValidation.errors.push({
        keyword: 'taggedText',
        message: 'This field may not be empty',
        params: {},
      });
      return false;
    } else {
      return true;
    }
  },
  errors: true,
});

export default function createValidator(schema) {
  const validate = ajv.compile(schema);
  return model => {
    validate(model);
    if (validate.errors?.length) {
      const errors = {
        details: validate.errors,
      };
      throw errors;
    }
  };
}
