import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import ProviderReference from 'src/components/forms/controls/providerReference';
import Field from 'src/components/forms/field';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';

const MONITOR_PROVIDER_VALIDATOR = Yup.object().shape({
  monitorProvider: Yup.object().required().nullable(),
});

const AddMonitoredProviderDialog = ({ loadOptions, onReset, onSubmit }) => {
  return (
    <Formik
      onReset={onReset}
      onSubmit={onSubmit}
      validationSchema={MONITOR_PROVIDER_VALIDATOR}
      initialValues={{}}
    >
      {({ handleSubmit, handleReset, isValid, dirty }) => (
        <ConfirmDialog
          maxWidth="sm"
          title="Monitor a provider's conversations..."
          submitLabel="Ok"
          onCancel={handleReset}
          onSubmit={handleSubmit}
          disabled={!isValid || !dirty}
        >
          <Field
            label="Provider"
            name="monitorProvider"
            component={ProviderReference}
            placeholder="Select Provider..."
            loadOptions={loadOptions}
          />
        </ConfirmDialog>
      )}
    </Formik>
  );
};

export default AddMonitoredProviderDialog;
