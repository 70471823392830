import { makeStyles } from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { SelectControl } from 'src/nightingale/components/ChartProperty/controls/Select/SelectControl';
import { SelectOption } from 'src/nightingale/types/types';

export const messages = [
  {
    label: 'Reengage previous Boulder patient',
    value: `Hi from Boulder! It's been a while since your last visit. We want to make sure you're doing okay, and we're here to help. Head to your app and send us a message and we'll get you set up with an appointment.`,
  },
  {
    label: 'Patient missed their intake today',
    value: `Hi from Boulder Care! We missed you at your first appointment — we're still here to help. Head to your app to to be connected with our next available clinician.`,
  },
  {
    label: 'Time for next visit, come to Pop-In',
    value: `It's time for your next visit. Come to our Pop-In Clinic any day this week to see a provider.`,
  },
  {
    label: 'Pop-in clinic: Patient missed their scheduled visit',
    value: `Hi from Boulder! You missed your appointment, but you can still join our Pop-in Clinic to see a clinician. We're here for you. Check your home screen for availability and how to join.`,
  },
  {
    label: 'Pop-in clinic: Clinic is open',
    value: `Wait times are currently short for the Pop-In Clinic! Join the queue to see our next available provider.`,
  },
  {
    label: 'Pop-in clinic: Patient eligible but has not yet showed up',
    value: `Hi from Boulder Care! Are you still interested in enrolling? We know the first step can sometimes be a hurdle, but we're here to help. When you're ready, head to your app and visit our virtual Pop-In Clinic and we'll get you set up with our next available provider.`,
  },
  {
    label: 'Pop-in clinic: Reach out to patients unable to join a full queue',
    value: `Hi from Boulder Care! We saw that you were unable to get into our virtual Pop-In Clinic. Join today to be seen by a clinician. We're sorry that happened and we're still here to help.`,
  },
  {
    label: 'Pop-in clinic: Come on Friday',
    value: `It's almost time for your visit with Boulder. Join the Pop-In Clinic this Friday when wait times are lower than usual and see a provider quickly.`,
  },
  {
    label: 'Pop-in clinic: Come on Thursday',
    value: `It's almost time for your visit with Boulder. Join the Pop-In Clinic this Thursday when wait times are lower than usual and see a provider quickly.`,
  },
  {
    label: 'Pop-in clinic: Come on Wednesday',
    value: `It's almost time for your visit with Boulder. Join the Pop-In Clinic this Wednesday when wait times are lower than usual and see a provider quickly.`,
  },
  {
    label: 'Pop-in clinic: Come on Tuesday',
    value: `It's almost time for your visit with Boulder. Join the Pop-In Clinic this Tuesday when wait times are lower than usual and see a provider quickly.`,
  },
  {
    label: 'Pop-in clinic: Come on Monday',
    value: `It's almost time for your visit with Boulder. Join the Pop-In Clinic this Monday when wait times are lower than usual and see a provider quickly.`,
  },
  {
    label: 'Pop-in clinic: Come tomorrow morning',
    value: `It's almost time for your visit with Boulder. Join the Pop-In Clinic tomorrow morning when wait times are lower than usual and see a provider quickly.`,
  },
  {
    label: 'Pop-in clinic: Come tomorrow afternoon',
    value: `It's almost time for your visit with Boulder. Join the Pop-In Clinic tomorrow afternoon when wait times are lower than usual and see a provider quickly.`,
  },
  {
    label: 'Eligible off waitlist, no time limit',
    value: `Hi from Boulder Care! Are you still interested in enrolling? You're now eligible from our waitlist. When you're ready, head to your app and visit our virtual Pop-In Clinic and we'll get you set up with our next available provider.`,
  },
  {
    label: 'Eligible off waitlist, 2 week time limit',
    value: `Hi from Boulder Care! Are you still interested in enrolling? You're now eligible from our waitlist and can enroll any time in the next two weeks. When you're ready, head to your app and visit our virtual Pop-In Clinic and we'll get you set up with our next available provider.`,
  },
  {
    label: 'Eligible off waitlist, 2 week time limit, come Thursday or Friday',
    value: `Hi from Boulder Care! Are you still interested in enrolling? You're now eligible from our waitlist and can enroll for the next two weeks. We have openings for new patient visits on Thursdays and Fridays. Head to your app on those days to visit our virtual Pop-In Clinic and we'll get you set up with our next available provider.`,
  },
  {
    label: 'Eligible off waitlist, 2 week time limit, call us',
    value: `Hi from Boulder Care! Are you still interested in enrolling? You're now eligible from our waitlist and can enroll any time in the next two weeks. When you're ready, please call us at 888-608-0836 to provide your insurance information and get started.`,
  },
  {
    label: 'Eligible off waitlist, no time limit, call us',
    value: `Hi from Boulder Care! Are you still interested in enrolling? You're now eligible from our waitlist. When you're ready, please call us at 888-608-0836 to provide your insurance information and get started.`,
  },
  {
    label: 'Experiencing technical issues, stand by',
    value: `The Boulder Care app is experiencing issues right now. We'll let you know as soon as these issues are resolved. We appreciate your patience.`,
  },
  {
    label: 'Technical issues resolved',
    value: `Thanks for your patience! The Boulder Care app is now up and running again.`,
  },
];

const messagesByValue = keyBy(messages, 'value');

const MessageSelector = ({ message, setMessage }) => {
  const classes = useStyles();

  return (
    <div>
      <SelectControl
        options={messages}
        onChangeValue={(option: SelectOption) => {
          if (option?.value) {
            setMessage(option.value);
          } else if (message !== undefined) {
            setMessage(undefined);
          }
        }}
        allowMultiple={false}
        value={message && messagesByValue[message]}
        label="Select a message to send to patients via sms"
      />
      {message && (
        <div className={classes.messagePreviewContainer}>
          <span className={classes.messagePreviewLabel}>Message Preview</span>
          <span className={classes.messagePreviewBody}>{message}</span>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  messagePreviewContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    '& span': {
      display: 'block',
    },
  },
  messagePreviewLabel: {
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '14.5px',
    letterSpacing: `.12em`,
    color: Colors.Gray6,
    textTransform: 'uppercase',
    transform: 'scale(1)',
    position: 'static',
  },
  messagePreviewBody: {
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    color: Colors.Gray6,
    fontStyle: 'italic',
  },
});

export default MessageSelector;
