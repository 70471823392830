import gql from 'graphql-tag';

import { Interaction } from 'src/nightingale/types/types';

export const REFRESH_FLOW_ON_INTERACTION = gql`
  mutation RefreshFlowOnInteraction($interactionReferenceId: ID!, $interactionKey: String!) {
    refreshFlowOnInteraction(
      interactionReferenceId: $interactionReferenceId
      interactionKey: $interactionKey
    ) {
      id
    }
  }
`;

export type QueryResult = {
  refreshFlowOnInteraction: Interaction;
};
