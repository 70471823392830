import React from 'react';

import * as patientReviewParqRenderer from 'src/components/forms/renderers/patientReviewParq';
import PatientOverviewLink from 'src/components/general/patientOverviewLink';

export default {
  type: 'object',
  title: 'Prescriber: Plan (Initial)',
  properties: {
    patientReviewParq: {
      type: 'object',
      title: 'Patient Review/PARQ',
      renderer: patientReviewParqRenderer,
      properties: {
        reviewedMedicationOptions: {
          type: 'boolean',
          title: 'Reviewed medication options',
          tooltip: 'Buprenorphine, Methadone, ER-Naltrexone, Abstinence',
        },
        reviewedRisks: {
          type: 'boolean',
          title: 'Reviewed risks',
          tooltip:
            'Risks include: Accidental overdose (rare - when using IV, high dose and with alcohol/bzds), addiction, dependence, tolerance, withdrawal, respiratory depression (less common with buprenorphine), endocrine issues, itching, nausea/vomiting, constipation, difficulty urinating, sedation, mood issues, allergic reactions including anaphylaxis and death (rare).  Can be fatal if non-tolerant are exposed (i.e. children).  If pregnant or planning pregnancy, the baby may be born dependent as well.  Routinely used in pregnancy as well as methadone, so not a contraindication, but need to be aware.',
        },
        reviewedSafeStorage: {
          type: 'boolean',
          title: 'Reviewed safe storage',
        },
        reviewedDrivingAndMachineryGuidelines: {
          type: 'boolean',
          title: 'Reviewed driving and machinery guidelines',
          tooltip:
            'Recommend not driving or operating machinery until on a steady dose and understand effects',
        },
        reviewedOtherSubstanceUse: {
          type: 'boolean',
          title: 'Reviewed other substance use',
          tooltip:
            'Recommended caution with alcohol, benzodiazepines, and sedative hypnotics as these may increase overdose risk. Combining with cannabis may further impair cognition so recommended prudent use as well, ensuring perceived benefits outweigh risks for *** if *** continues to use.',
        },
        reviewedLowestDose: {
          type: 'boolean',
          title: 'Reviewed lowest dose',
          tooltip:
            'FDA recommends lowest effective dose of these medications or cessation if possible.',
        },
        reviewedMedicationInductionPlan: {
          type: 'boolean',
          title: 'Reviewed medication induction plan',
        },
        reviewedAbstinenceBasedApproaches: {
          type: 'boolean',
          title: 'Reviewed abstinence-based approaches',
          tooltip:
            'Abstinence based approach (12 steps, SMART, etc) can be combined with medication.',
        },
        reviewedCounselingOpportunities: {
          type: 'boolean',
          title: 'Reviewed counseling opportunities',
        },
        reviewedNarcanAccessUse: {
          type: 'boolean',
          title: 'Reviewed Narcan access/use',
          tooltip:
            'Use intranasally q3 min PRN opioid overdose.  Call 911 for continued support. Place patient in recovery position (on side). Administer CPR/rescue breaths if unsuccessful reversal, appropriately trained and safe to do so.',
        },
        reviewedHowToContactCareTeam: {
          type: 'boolean',
          title: 'Reviewed how to contact Care Team',
        },
        givenRisksPatientWantsToProceed: {
          type: 'boolean',
          title: 'Given risks, patient wants to proceed',
        },
        reviewedUpdatedTeam: {
          type: 'boolean',
          renderer: {
            title: 'Reviewed/updated Team',
          },
          label: (
            <span>
              Reviewed/updated{' '}
              <PatientOverviewLink section="team">&ldquo;Team&rdquo;</PatientOverviewLink>{' '}
            </span>
          ),
        },
        questionsNotes: {
          type: 'string',
          title: 'Questions/Notes',
          uniforms: {
            multiline: true,
          },
        },
      },
    },
    rx: {
      type: 'string',
      allowOther: true,
      enum: [
        'buprenorphine-naloxone 8-2mg #1 tab SL daily x 7 days',
        'buprenorphine 8mg #1 tab SL daily x 7 days',
        'buprenorphine-naloxone 8-2mg #2 tabs SL daily x 7 days',
        'buprenorphine 8mg #2 tabs SL daily x 7 days',
        'buprenorphine-naloxone 8-2mg #3 tabs SL daily x 7 days',
        'buprenorphine 8mg #3 tabs SL daily x 7 days',
        'buprenorphine-naloxone 8-2mg per induction instructions with up to 16mg daily until follow up in 7 days',
        'buprenorphine 8mg per induction instructions with up to 16mg daily until follow up in 7 days',
        'buprenorphine-naloxone 8-2mg per induction instructions with up to 16mg in the first 24hrs, then up to 24mg daily until follow up in 7 days',
        'buprenorphine 8mg per induction instructions with up to 16mg in the first 24hrs, then up to 24mg daily until follow up in 7 days',
      ],
    },
    osdt: {
      type: 'string',
      title: 'OFT',
      allowOther: true,
      enum: ['Weekly', 'Semi-Weekly', 'Monthly'],
    },
    naloxone: {
      type: 'string',
      allowOther: true,
      enum: ['Already has', 'Will receive in Boulder Box', 'Resend', 'Prescription needed'],
    },
    reviewedUpdatedSerologies: {
      type: 'boolean',
      renderer: {
        title: 'Reviewed/updated Serologies Tested in the Past in Labs',
      },
      label: (
        <span>
          Reviewed/updated Serologies Tested in the Past in{' '}
          <PatientOverviewLink section="labs">&ldquo;Labs&rdquo;</PatientOverviewLink>{' '}
        </span>
      ),
    },
    labs: {
      type: 'object',
      title: 'Order Labs',
      renderer: {
        component: ({ model, schema }) => {
          const labLabels = Object.keys(schema.properties).reduce(
            (labels, field) => ({
              ...labels,
              [field]: schema.properties[field].title,
            }),
            {},
          );
          const labs =
            model.labs && Object.keys(model.labs).length > 0
              ? Object.keys(model.labs)
                  .filter(key => !!model.labs[key])
                  .map(key => labLabels[key])
              : [];
          return labs.length && <span>{labs.join(', ')}</span>;
        },
      },
      properties: {
        hiv: { type: 'boolean', title: 'HIV' },
        hepB: { type: 'boolean', title: 'Hep B' },
        hepC: { type: 'boolean', title: 'Hep C' },
        syphilis: { type: 'boolean', title: 'Syphilis' },
        lfts: { type: 'boolean', title: 'LFTs' },
        upt: { type: 'boolean', title: 'UPT' },
      },
    },
    followup: {
      type: 'string',
      title: 'Follow Up',
      allowOther: true,
      enum: ['1 week', '2 weeks', '1 month'],
    },
    previousRecords: {
      type: 'string',
      title: 'Previous records',
      allowOther: true,
      enum: ['Obtained', 'In-progress', 'Request', 'N/A'],
    },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
