/**
 * Utility for building graphql queries dynamically in code. Typically a query is defined by a
 * string that's parsed with the gql utility. This works great for one-off but queries, but
 * sometimes it's useful to dynamically generate a query, as we do in server/crud.js. Dynamically
 * building a query string and then parsing it feels really kludgy, so instead this library lets
 * you easily construct a query on the fly.
 *
 * The graphql-ast-types library lets you construct the underlying AST that the graphql client uses,
 * but it's at a super low-level and hard to use, so this library wraps it in some higher level
 * functions that are slightly simpler to use.
 */
import * as gqlTypes from 'graphql-ast-types';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';

/**
 * Convert a value for a single argument to a graphql type. Values can be a variety of types
 * including objects like {where: {id: 'X'}}, variable references like {id: '$id'}, or a combination
 * of those like {where: {id: '$id'}}.
 */
function valueToAST(value) {
  if (isPlainObject(value)) {
    return gqlTypes.objectValue(
      Object.entries(value).map(([key, childValue]) =>
        gqlTypes.objectField(gqlTypes.name(key), valueToAST(childValue)),
      ),
    );
  }
  if (value.startsWith('$')) {
    return gqlTypes.variable(gqlTypes.name(value.substring(1)));
  }
  // Add additional types from https://github.com/imranolas/graphql-ast-types/blob/master/api.md
  // as needed
  throw new Error('Unsupported value type');
}

/**
 * Convert a list of arguments to graphql types. Arguments are the values passed to a graphql
 * selector, so for example if you're building a query that calls patients(first: 5), this converts
 * {first: 5} to the appropriate data.
 */
function argumentsToAST(args) {
  return Object.entries(args).map(([key, value]) =>
    gqlTypes.argument(gqlTypes.name(key), valueToAST(value)),
  );
}

/**
 * Convert a list of selector definitions to a list of graphql types. Selectors represent the things
 * we're querying for, either properties we want to return, or queries/mutations we want to call. To
 * call a method (query or mutation), pass an object containing the name, alias, arg values and
 * selectors. For example the object {name: 'patients', alias: 'items', args: {first, '$first'},
 * selectors: ['id']} maps to the following graphql query:
 *
 * items: patients(first: $first) {
 *   id
 * }
 *
 * As a convenience, for simple selectors that are just fields names, instead of passing
 * {name: 'id'}, you can just pass the property name as a string. This lets you specify a list of
 * fields to be returns as a simple list: ['id', 'firstName', 'lastName'].
 */
function selectorsToAST(selectorsArg) {
  return gqlTypes.selectionSet(
    selectorsArg.map(selector => {
      if (isPlainObject(selector)) {
        const { name, alias, args, selectors } = selector;
        return gqlTypes.field(
          gqlTypes.name(name),
          alias ? gqlTypes.name(alias) : null,
          args ? argumentsToAST(args) : null,
          null, // directives
          selectors ? selectorsToAST(selectors) : null,
        );
      }
      return gqlTypes.field(gqlTypes.name(selector));
    }),
  );
}

/**
 * Convert variable definitions to graphql types. Graphql requires that all variables used in the
 * query be defined before using them, so this converts something like {id: ID, data: DataType} to
 * the appropriate definitions to be used in a query like:
 *
 * mutation doSomething(id: ID, data: DataType)
 */
function variablesToAST(variables) {
  return Object.entries(variables).map(([name, type]) =>
    gqlTypes.variableDefinition(
      gqlTypes.variable(gqlTypes.name(name)),
      gqlTypes.namedType(gqlTypes.name(type)),
    ),
  );
}

/**
 * Convert a top level query or mutation to graphql. Accepts a type ('query' or 'mutation'), a set
 * of variables used in the operation as a map from the variable name to the variable type, and a
 * list of selectors that define the methods to be called. For example, the following could be used
 * to query list of patients:
 *
 * type: 'query',
 * variables: { where: 'PatientWhereInput', first: 'Int'},
 * selectors: [{
 *   name: 'patients',
 *   alias: 'items',
 *   args: {where: '$where', first: '$first'},
 *   selectors: ['id', 'firstName', 'lastName'],
 * }]
 *
 * This maps to the following GraphQL query:
 *
 * query data($where: PatientWhereInput, $first: Int) {
 *   items: patients(where: $where, first: $first) {
 *     id
 *     firstName
 *     lastName
 *   }
 * }
 */
function operationToAST(type, { variables, selectors }) {
  let name = 'data';
  if (selectors?.length === 1 && selectors[0].name) {
    name = selectors[0].name[0].toUpperCase() + selectors[0].name.slice(1);
  }
  return gqlTypes.document([
    gqlTypes.operationDefinition(
      type,
      selectorsToAST(selectors),
      gqlTypes.name(name), // top level operation name
      variablesToAST(variables),
    ),
  ]);
}

/**
 * Convenience wrapper around operationToAST. See comments there.
 */
export function query(params) {
  return operationToAST('query', params);
}

/**
 * Convenience wrapper around operationToAST. See comments there.
 */
export function mutation(params) {
  return operationToAST('mutation', params);
}

export function nullToUndefined(model) {
  if (isPlainObject(model)) {
    return mapValues(model, nullToUndefined);
  }
  if (isArray(model)) {
    return model.map(nullToUndefined);
  }
  if (model === null) {
    return undefined;
  }
  return model;
}
