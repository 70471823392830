import { isValidPatientIdFormat } from 'src/smsCommunications/domain/patientIdValidation';

/**
 * Evaluates a list of patientIds
 * @param list arbitrary list of strings (expected to be patientIds)
 * @returns a new list of all valid patientIds from given list
 */
export const validPatientIds = (list: string[]) =>
  list
    .filter(patientId => patientId.length > 0) // do not send empty entries
    .filter(patientId => isValidPatientIdFormat(patientId));

/**
 * Evaluates a list of patientIds
 * @param list arbitrary list of strings (expected to be patientIds)
 * @returns a new list of all invalid patientIds from given list
 */
export const invalidPatientIds = (list: string[]) =>
  list
    .filter(patientId => patientId.length > 0) // do not evaluate empty entries
    .filter(patientId => !isValidPatientIdFormat(patientId));

/**
 * Evaluates a list of patientIds
 * @param list arbitrary list of strings (expected to be patientIds)
 * @returns a new list of all duplicate valid patientIds from given list
 */
export const duplicatePatientIds = (list: string[]) =>
  list
    .filter((patientId, pos) => list.indexOf(patientId) !== pos)
    .filter(patientId => isValidPatientIdFormat(patientId));
