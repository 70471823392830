import Input from '@material-ui/core/Input';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import React, { useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';

import Tooltip from 'src/components/general/Tooltip';
import { useStyles } from 'src/nightingale/components/Flow/Flow.Search.styles';
import { Flow } from 'src/nightingale/types/types';

export type SelectOption = {
  label: string;
  value: string;
};

export const FlowSearch: React.FC<{
  onSubmit: (value: SelectOption, parentFlowIndex: number) => void;
  flows: Flow[];
  parentFlowIndex: number;
}> = props => {
  const { onSubmit, flows, parentFlowIndex } = props;

  const [searchOpen, setSearchOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const styles = useStyles({
    searchOpen,
    optionsOpen,
  });
  const [inputValue, setInputValue] = useState('');

  const flowOptions: SelectOption[] = useMemo(
    () => flows.map(({ id, label, name }) => ({ label: label || name, value: id })),
    [flows],
  );

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      autoHighlight: true,
      clearOnBlur: true,
      getOptionLabel: (option: SelectOption) => option.label,
      inputValue,
      isOptionEqualToValue: () => false,
      onChange: (_event, value: SelectOption) => {
        onSubmit(value, parentFlowIndex);
        toggleSearch(false);
      },
      onClose: () => setOptionsOpen(false),
      onInputChange: (_event, value, type) => {
        if (type === 'reset') {
          setInputValue('');
        } else {
          setInputValue(value);
        }
      },
      onOpen: () => setOptionsOpen(true),
      open: optionsOpen,
      options: flowOptions,
      value: null,
    });

  const inputProps = getInputProps();

  const toggleSearch = (state = !searchOpen) => {
    setOptionsOpen(state);
    setSearchOpen(state);
    if (state) {
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
  };

  return (
    <section className={styles.wrapper} {...getRootProps()} data-testid="flow-search">
      <div className={styles.cta}>
        <Tooltip title="Add a section">
          <button
            className={styles.button}
            data-testid="flow-search-button"
            onClick={event => {
              event.preventDefault();
              toggleSearch();
            }}
            type="button"
          >
            Add a section
          </button>
        </Tooltip>
      </div>
      <fieldset
        className={styles.dropdown}
        onBlur={event => {
          const { currentTarget } = event;

          // @src https://muffinman.io/blog/catching-the-blur-event-on-an-element-and-its-children/
          // Give browser time to focus the next element
          requestAnimationFrame(() => {
            // Check if the new focused element is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
              toggleSearch(false);
            }
          });
        }}
      >
        <div className={styles.inputContainer}>
          <Input
            autoComplete="off"
            autoFocus={searchOpen}
            classes={{
              root: styles.inputRoot,
              underline: styles.inputUnderline,
            }}
            fullWidth
            inputProps={{
              ...inputProps,
              'data-testid': 'flow-search-input',
            }}
            ref={inputRef}
            placeholder="Search for sections to add..."
          />
        </div>
        <ol {...getListboxProps()} className={styles.optionList}>
          {(groupedOptions as SelectOption[]).map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
              className={styles.optionItem}
              data-testid="flow-search-option"
            >
              <Highlighter
                autoEscape
                highlightClassName={styles.mark}
                searchWords={[(inputProps as { value: string }).value]}
                textToHighlight={option?.label}
              />
            </li>
          ))}
          {groupedOptions.length < 1 && searchOpen && optionsOpen && (
            <li className={styles.optionItem}>
              {flows.length === 0 ? 'No addable sections available.' : 'No sections found.'}
            </li>
          )}
        </ol>
      </fieldset>
    </section>
  );
};
