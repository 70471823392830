import { Theme } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import classNames from 'classnames';
import sum from 'lodash/sum';
import { observer } from 'mobx-react';
import React, { ComponentPropsWithoutRef, CSSProperties, FunctionComponent } from 'react';

import Tooltip from 'src/components/general/Tooltip';
import RouterLink from 'src/components/general/routerLink';
import { colors } from 'src/util/colors';

const ListCellError = () => <>Invalid component passed to ListCell</>;

const ListCell = ({ item, field }) => {
  if (typeof field?.value !== 'function') {
    return <ListCellError />;
  }
  const Component = field.value;
  return <Component item={item} />;
};

class SortableList<TItem extends ListItem> extends React.Component<SortableListProps<TItem>> {
  handleRequestSort = (orderBy: string | null) => {
    const { resourceList } = this.props;
    let order: SortOrder = 'desc';

    if (resourceList.orderBy === orderBy && resourceList.order === 'desc') {
      order = 'asc';
    }

    if (this.props.handleRequestSort) {
      this.props.handleRequestSort(orderBy, order);
    } else {
      resourceList.update({ order, orderBy });
    }
  };

  handleChangePage = page => {
    if (this.props.handleChangePage) {
      this.props.handleChangePage(page);
    } else {
      this.props.resourceList.update({ page });
    }
  };

  handleChangeRowsPerPage = rowsPerPage => {
    if (this.props.handleChangeRowsPerPage) {
      this.props.handleChangeRowsPerPage(rowsPerPage);
    } else {
      this.props.resourceList.update({ rowsPerPage });
    }
  };

  setHoverStyles = evt => {
    // If hovering over text or an icon, the target will be the child element
    // itself, which doesn't have the data that we need, so we'll look up the
    // chain until we find the table cell that does.
    const routeName = evt.target.closest('td').getAttribute('data-route-key');
    if (routeName) {
      const hoveredCells = document.querySelectorAll(`td[data-route-key="${routeName}"]`);
      for (let i = 0; i < hoveredCells.length; i++) {
        const cell = hoveredCells[i];
        if (cell instanceof HTMLElement) {
          cell.style.background = 'rgba(0, 0, 0, 0.04)';
        }
      }
    }
  };

  clearHoverStyles = () => {
    const allCells = document.querySelectorAll('td[data-route-key]');
    for (let i = 0; i < allCells.length; i++) {
      const cell = allCells[i];
      if (cell instanceof HTMLElement) {
        cell.style.background = 'none';
      }
    }
  };

  render() {
    const {
      classes,
      resourceList: { order, orderBy, page, rowsPerPage, items },
      routes,
      tableHeadRows,
      tableBodyRows,
    } = this.props;
    const emptyRows = rowsPerPage - items.length;

    return (
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              {tableHeadRows.map(row => (
                <TableRow key={row.key}>
                  {row.fields.map(field => (
                    <TableCell
                      key={field.key || (field.label as string)}
                      className={classNames(
                        classes.tableHeadCells,
                        field.noCellBorder && classes.noCellBorder,
                      )}
                      sortDirection={orderBy === field.sortField ? order : false}
                      rowSpan={field.rowSpan || 1}
                      colSpan={field.colSpan || 1}
                    >
                      {field.sortField ? (
                        <Tooltip
                          title="Sort"
                          placement={field.numeric ? 'bottom-end' : 'bottom-start'}
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === field.sortField}
                            direction={order}
                            onClick={() => this.handleRequestSort(field.sortField ?? null)}
                          >
                            {field.label}
                          </TableSortLabel>
                        </Tooltip>
                      ) : (
                        <span>{field.label}</span>
                      )}
                      {field.underline && <hr className={classes.horizontalLine} />}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody className={classes.tableBody}>
              {items.length > 0 ? (
                items.map(item =>
                  tableBodyRows.map(row => (
                    <TableRow
                      key={`${item.id}-${row.key}`}
                      className={classes.tableRow}
                      style={row.style?.(item)}
                    >
                      {row.fields.map(field => {
                        const routeKey = field.routeKey || row.routeKey;
                        const itemRouteKey = `${item.id}-${routeKey}`;
                        const CellComponent = field.component || ListCell;
                        let tableCellProps: HoverGroupTableCell &
                          ListRowField &
                          ComponentPropsWithoutRef<typeof TableCell> = {
                          key: `${item.id}-${field.key || field.label}`,
                          rowSpan: field.rowSpan || 1,
                        };

                        const tableContentsProps: React.HTMLAttributes<HTMLElement> = {};

                        if (field.width) {
                          tableCellProps.style = {};
                          tableContentsProps.style = {};

                          if (field.width.exact) {
                            tableCellProps.width = `${field.width.exact}px`;
                            // Don't let the HTML table pretend responsiveness is helpful here
                            tableContentsProps.style.minWidth = `${field.width.exact}px`;
                          }
                          if (field.width.min) {
                            tableContentsProps.style.minWidth = `${field.width.min}px`;
                          }
                          if (field.width.max) {
                            tableContentsProps.style.maxWidth = `${field.width.max}px`;
                          }
                        }

                        if (routeKey) {
                          const route = routes[routeKey];
                          const { routeName, getRouteParams } = route;
                          const params = getRouteParams?.(item) || {};

                          tableCellProps = {
                            ...tableCellProps,
                            className: classNames({
                              [classes.tableCell]: true,
                              [classes.clickableCell]: true,
                              [classes.noCellBorder]: field.noCellBorder,
                            }),
                            'data-route-key': itemRouteKey,
                            onMouseEnter: this.setHoverStyles,
                            onMouseLeave: this.clearHoverStyles,
                          };
                          return (
                            <TableCell {...tableCellProps}>
                              <RouterLink
                                {...{
                                  ...(tableContentsProps as React.HTMLAttributes<HTMLAnchorElement>),
                                  style: {
                                    ...tableContentsProps.style,
                                    // Usually handled by RouterLink, but gets overridden with this setting
                                    textDecoration: 'none',
                                  },
                                }}
                                className={classNames(
                                  classes.tableCellContents,
                                  classes.clickableCellContents,
                                  field.alignContentsTop
                                    ? classes.alignContentsTop
                                    : classes.alignContentsCenter,
                                )}
                                routeName={routeName}
                                params={params}
                              >
                                <CellComponent item={item} field={field} />
                              </RouterLink>
                            </TableCell>
                          );
                        } else {
                          tableCellProps = {
                            ...tableCellProps,
                            className: classNames(
                              classes.tableCell,
                              field.noCellBorder && classes.noCellBorder,
                            ),
                          };
                          return (
                            <TableCell {...tableCellProps}>
                              <CellComponent
                                {...tableContentsProps}
                                className={classes.tableCellContents}
                                item={item}
                                field={field}
                              />
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  )),
                )
              ) : (
                <TableRow>
                  <TableCell className={classes.emptyRow}>No results found.</TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={sum(tableHeadRows[0].fields.map(f => f.colSpan || 1))} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          onChangePage={(event, newPage) => this.handleChangePage(newPage)}
          onChangeRowsPerPage={event => this.handleChangeRowsPerPage(event.target.value)}
          page={page}
          count={-1}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25]}
          labelDisplayedRows={({ from, to }) => `${from}–${to}`}
        />
      </div>
    );
  }
}

const styles: Styles<Theme, any> = theme => ({
  titleBar: {
    display: 'flex',
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  root: {
    width: '100%',
  },
  table: {
    // Need height of 100% set from TD child all the way up to Table element
    // in order for the anchor tag to span the full height of the cell, making
    // empty cells fully clickable
    height: '100%',
  },
  tableBody: {
    height: '100%',
  },
  tableHeadCells: {
    color: colors.taupe,
    fontSize: 12,
    lineHeight: theme.typography.body2.lineHeight,
    padding: 10,
    verticalAlign: 'top',
  },
  horizontalLine: {
    borderWidth: 2,
    marginTop: 0,
  },
  tableRow: {
    height: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  edit: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
  tableCell: {
    height: '100%',
    padding: '20px 0',
    wordBreak: 'break-word',
  },
  tableCellContents: {
    padding: '4px 10px',
  },
  clickableCell: {
    cursor: 'pointer',
  },
  noCellBorder: {
    borderBottom: 'none',
    paddingBottom: 0,
  },
  alignContentsTop: {
    paddingTop: 0,
  },
  alignContentsCenter: {
    alignItems: 'center',
  },
  clickableCellContents: {
    color: 'inherit',
    display: 'flex',
    height: '100%',
  },
  emptyRow: {
    borderBottom: 'none',
  },
});

type SortOrder = 'asc' | 'desc';

type ListViewParameters = {
  order: SortOrder;
  orderBy: string | null;
  page: number;
  rowsPerPage: number;
};

/**
 * What's needed to present a sortable list
 *
 * See createListModel() for the inspiration.
 */
type SortableListProps<TItem extends ListItem> = {
  classes: Record<string, string>;
  resourceList: {
    items: TItem[];
    where: unknown;
    update: (o: Partial<ListViewParameters>) => void;
  } & ListViewParameters;
  handleRequestSort?: (orderBy: string | null, order: SortOrder) => void;
  handleChangePage?: (newPage: number) => void;
  handleChangeRowsPerPage?: (newRowsPerPage: number) => void;
  routes: Record<
    string,
    {
      routeName: string;
      getRouteParams: (item: TItem) => Record<string, string>;
    }
  >;
  tableHeadRows: ListHeadRow[];
  tableBodyRows: Array<ListBodyRow<TItem>>;
};

export type ListHeadRow = ListRow & {
  fields: ListHeadField[];
};

export type ListBodyRow<TItem extends ListItem> = ListRow & {
  fields: Array<ListBodyField<TItem>>;
  routeKey?: string;
  style?: (item: TItem) => CSSProperties;
};

type ListHeadField = ListRowField & {
  colSpan?: number;
  numeric?: boolean;
  sortField?: string;
  underline?: boolean;
};

type ListBodyField<TItem extends ListItem> = ListRowField & {
  alignContentsTop?: boolean;
  component?: FunctionComponent<{
    className?: string;
    item: TItem;
    field: ListBodyField<TItem>;
  }>;
  routeKey?: string;
  value?: FunctionComponent<{
    item: TItem;
  }>;
  width?: {
    exact?: number;
    min?: number;
    max?: number;
  };
};

type ListRowField = {
  key?: string;
  label?: string | JSX.Element;
  noCellBorder?: boolean;
  rowSpan?: number;
};

type ListItem = {
  id: string | null;
};

/**
 * Things all table rows have
 */
type ListRow = {
  key: string;
};

/**
 * A table cell that's part of a group that should be highlighted together when hovered over
 */
type HoverGroupTableCell = {
  'data-route-key'?: string;
};

export default withStyles(styles)(observer(SortableList));
