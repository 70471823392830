import { Theme, makeStyles } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

export const useButtonStyles = makeStyles<Theme>(theme => ({
  button: {
    borderColor: `${Colors.Gray6}55`,
    borderRadius: theme.spacing(0.5),
    color: Colors.Gray6,
    fontSize: theme.typography.fontSize,
    fontFamily: 'Nunito Sans',
    textTransform: 'none',
    padding: '1px 5px',
    minWidth: 0,
  },
  iconButton: {
    border: 'solid 1px',
    padding: '1px 1.5px',
  },
  neutral: {
    borderColor: Colors.Blue3,
    color: Colors.Gray8,
  },
  success: {
    borderColor: Colors.Blue3,
    color: Colors.Gray8,
  },
  warning: {
    borderColor: Colors.Bronze,
    color: Colors.Bronze,
    backgroundColor: Colors.Cream,
    fontWeight: 600,
  },
  error: {
    borderColor: Colors.NewRed,
    color: Colors.NewRed,
    backgroundColor: Colors.NewLightRed,
    fontWeight: 600,
  },
}));
