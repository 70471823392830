import { formatDistance, isValid } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';

import { ChatTeamIcon } from 'src/chat/ChatTeamIcon';
import { ChatTeamMenu } from 'src/chat/ChatTeamMenu';
import { getChatItemTitle } from 'src/chat/getChatItemTitle';
import { useChatStyles } from 'src/chat/useChatStyles';
import { useChatTeams } from 'src/chat/useChatTeams';
import Tooltip from 'src/components/general/Tooltip';
import { getPreferredFullNameWithType } from 'src/shared/stores/resource';
import { ConversationInstance } from 'src/stores/chat';
import { getDisplayNameWithPatients, getDisplayNameWithoutPatients } from 'src/util/chat';

type ConversationItemProps = {
  conversation: ConversationInstance;
  currentConversation: ConversationInstance;
  enableChatTeamAssignments: boolean;
  isOtherConversations: boolean;
  teamId: string | null;
  onChangeTeamAssignment: (conversationId: string, teamId: string | null) => void;
  onClick: () => void;
};

const ConversationItem = (props: ConversationItemProps) => {
  const now = new Date();

  const classes = useChatStyles();

  const {
    conversation,
    currentConversation,
    enableChatTeamAssignments,
    isOtherConversations,
    teamId,
    onChangeTeamAssignment = () => {},
    onClick,
  } = props;

  const chatIconRef = useRef<HTMLDivElement>(null);

  const chatTeams = useChatTeams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const classList: string[] = [];

  if (conversation?.hasUnread && !isOtherConversations) {
    classList.push(classes.hasUnread);
  }

  if (conversation === currentConversation) {
    classList.push(classes.selected);
  }

  if (conversation?.hasPatients) {
    classList.push('is-patient-conversation');
  }

  const updateTeamAssignment = nextTeamId => {
    setIsMenuOpen(false);
    onChangeTeamAssignment(conversation.id, nextTeamId);
  };

  const teamColor = teamId ? chatTeams[teamId]?.color : undefined;
  const teamIcon = teamId ? chatTeams[teamId]?.icon : undefined;

  return (
    <div className={classList.join(' ')}>
      <div onClick={onClick} className={classes.conversationItem}>
        <div className={classes.teamIconContainer}>
          <ChatTeamIcon
            iconName={teamIcon}
            ref={chatIconRef}
            size={40}
            backgroundColor={teamColor}
            onClick={() => setIsMenuOpen(enableChatTeamAssignments && true)}
            isInteractive={enableChatTeamAssignments}
          />
          <ChatTeamMenu
            isOpen={isMenuOpen}
            anchorEl={chatIconRef.current}
            onChange={updateTeamAssignment}
            onClose={() => setIsMenuOpen(false)}
          />
        </div>
        <Tooltip
          title={conversation.otherUsers
            .map(otherUser => getPreferredFullNameWithType(otherUser.user))
            .join(', ')}
        >
          <div className={classes.conversationTitle}>
            {getChatItemTitle(
              conversation,
              isOtherConversations,
              getDisplayNameWithPatients,
              getDisplayNameWithoutPatients,
            )}
            <div className={classes.lastActivity}>
              {isValid(conversation.lastMessageAt) &&
                `${formatDistance(conversation.lastMessageAt, now)} ago`}
            </div>
          </div>
        </Tooltip>
        <div className={classes.lastMessage}>{conversation.lastMessageText}</div>
      </div>
    </div>
  );
};

export default observer(ConversationItem);
