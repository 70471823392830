import { connect } from 'formik';
import React, { useMemo } from 'react';

import TimeslotSelectionControl, {
  TimeSlotSelectionProps,
} from 'src/scheduling/components/timeSlotSelectionControl';

const TimeslotSelection = connect<
  Record<string, never>,
  Pick<TimeSlotSelectionProps, 'attendees' | 'subType' | 'timezone' | 'start' | 'duration'>
>(({ formik }) =>
  useMemo(
    () => (
      <TimeslotSelectionControl
        attendees={formik.values.attendees}
        duration={formik.values.duration}
        setFieldValue={formik.setFieldValue}
        start={formik.values.start}
        subType={formik.values.subType}
        timezone={formik.values.timezone}
      />
    ),
    [
      formik.values.attendees,
      formik.values.duration,
      formik.setFieldValue,
      formik.values.start,
      formik.values.subType,
      formik.values.timezone,
    ],
  ),
);

export default TimeslotSelection;
