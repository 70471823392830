import * as React from 'react';

import * as CurrentFlags from 'src/featureFlags/currentFlags';

type FlagName = (typeof CurrentFlags)[keyof typeof CurrentFlags];

export type FlagsContext = {
  [K in FlagName]?: boolean;
};

const FeatureFlagContext = React.createContext<FlagsContext>({});

export default FeatureFlagContext;
