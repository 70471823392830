import { Accordion as MaterialUiAccordion } from '@material-ui/core';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState, FunctionComponent, ReactNode } from 'react';

type AccordionProps = {
  additionalButtons?: Array<ButtonProps & { label: string }>;
  cancelLabel?: string;
  children: ReactNode | JSX.Element;
  defaultExpanded?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitLabel?: string;
  submitDisabled?: boolean;
  summary?: ReactNode | JSX.Element;
};

const Accordion: FunctionComponent<AccordionProps> = ({
  additionalButtons = [],
  cancelLabel = 'Cancel',
  children,
  defaultExpanded = false,
  onCancel,
  onSubmit,
  submitLabel = 'Submit',
  submitDisabled = false,
  summary = null,
}) => {
  const classes = useAccordionStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);

  function onSummaryClick() {
    setExpanded(!expanded);
  }

  function onCancelAction() {
    if (onCancel) {
      onCancel();
    }
    setExpanded(false);
  }

  function onSubmitAction() {
    if (onSubmit) {
      onSubmit();
    }
    setExpanded(false);
  }

  return (
    <MaterialUiAccordion className={classes.accordion} expanded={expanded}>
      <AccordionSummary
        aria-label="Toggle Accordion"
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
        onClick={onSummaryClick}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
      <AccordionActions>
        {onCancel && (
          <Button aria-label={cancelLabel} variant="outlined" size="small" onClick={onCancelAction}>
            {cancelLabel}
          </Button>
        )}
        {additionalButtons.map(button => (
          <Button
            key={button.label}
            aria-label={button.label}
            variant={button.variant || 'outlined'}
            size={button.size || 'small'}
            color={button.color || 'default'}
            onClick={button.onClick}
            disabled={button.disabled}
          >
            {button.label}
          </Button>
        ))}
        {onSubmit && (
          <Button
            aria-label={submitLabel}
            variant="contained"
            size="small"
            color="primary"
            onClick={onSubmitAction}
            disabled={submitDisabled}
          >
            {submitLabel}
          </Button>
        )}
      </AccordionActions>
    </MaterialUiAccordion>
  );
};

/*
 * 1 - Uses !important to overrides default material UI styling that appears when expanded
 */
const useAccordionStyles = makeStyles({
  accordion: {
    // See 1
    margin: '0px !important',
  },
  accordionSummary: {
    // See 1
    minHeight: '48px !important',

    '& > *': {
      // See 1
      margin: '12px 0px !important',
    },
  },
});

export default Accordion;
