import { useState } from 'react';

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  function toggleModal(forceVisible?: boolean) {
    setIsVisible(forceVisible !== undefined ? forceVisible : !isVisible);
  }
  return {
    isVisible,
    toggleModal,
  };
};
