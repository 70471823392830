export enum EventResultFieldTypes {
  OBJECT = 'object',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  STRING = 'string',
}

export interface EventResult {
  type: EventResultFieldTypes;
  title?: string;
  properties?: {
    [key: string]: EventResult;
  };
}
