import { gql } from 'src/generated/gql';

export const ACTIVITY_EVENTS_QUERY = gql(`
  query PatientActivityEventInstances($patientId: ID!) {
    patientActivityEvents(patientId: $patientId) {
      appointmentNotes
      attendees {
        id
        firstName
        lastName
        ... on Provider {
          teamRole
        }
      }
      duration
      id
      rescheduledTo {
        id
      }
      scheduleChangeNotes
      scheduleChangeReason
      signedAt
      signedByDisplay {
        ... on UserDisplay {
          preferredName
          firstName
          lastName
        }
      }
      start
      status
      subType
      timezone
      type
      updatedAt
      updatedByDisplay {
        ... on UserDisplay {
          preferredName
          firstName
          lastName
        }
      }
    }
  }
`);
