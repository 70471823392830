import Typography from '@material-ui/core/Typography';
import React from 'react';

import FieldGroup from 'src/components/general/fieldGroup';

export const nestedStyle = {
  paddingLeft: 18,
  borderLeftStyle: 'solid',
  borderLeftWidth: 1,
  borderLeftColor: '#dddddd',
};

export const nestedSelectFieldStyleDef = {
  wrapperStyle: {
    borderLeftWidth: 1,
    borderLeftColor: '#ddd',
    borderLeftStyle: 'solid',
  },
  uniforms: {
    style: {
      marginLeft: 18,
      width: 'auto',
    },
    labelProps: {
      style: {
        paddingLeft: 18,
      },
    },
  },
};

export const multilineStringDef = {
  type: 'string',
  uniforms: {
    multiline: true,
  },
};

export const Rx = ({ rxText, rx, notes, classes }) => (
  <>
    {rxText && (
      <Typography variant="body2" className={classes.multiLineText}>
        {rxText}
        {rx && (
          <span>
            :<br />
            {rx}
          </span>
        )}
      </Typography>
    )}
    {notes && (
      <FieldGroup label="RX Notes">
        <Typography variant="body2" className={classes.multiLineText}>
          {notes}
        </Typography>
      </FieldGroup>
    )}
  </>
);
