import { makeStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useRouterStore } from 'mobx-state-router';
import React from 'react';

import { View } from 'src/components/pages/patientActivity/types';
import Colors from 'src/nightingale/Colors';

export const ACTIVITY_VIEW_QUERY_PARAM = 'view';

const ViewsButtonGroup: React.FC<{
  view: View | null;
  setView: React.Dispatch<React.SetStateAction<View>>;
}> = ({ view, setView }) => {
  const routerStore = useRouterStore();
  const handleChange = (event, selectedView) => {
    setView(selectedView || View.Default);
    routerStore.goTo(routerStore.routerState.routeName, {
      params: routerStore.routerState.params,
      queryParams: { [ACTIVITY_VIEW_QUERY_PARAM]: selectedView },
    });
  };
  const styles = useStyles();

  return (
    <ToggleButtonGroup className={styles.group} exclusive onChange={handleChange} value={view}>
      <ToggleButton className={styles.button} value={View.Visits}>
        🧑‍⚕️ Visits
      </ToggleButton>
      <ToggleButton className={styles.button} value={View.MedsManagement}>
        💊 Meds Management
      </ToggleButton>
      <ToggleButton className={styles.button} value={View.ClinicalVisit}>
        🩺 Clinical Visits
      </ToggleButton>
      <ToggleButton className={styles.button} value={View.Detailed}>
        🕵️ Detailed
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles({
  group: {
    marginBottom: 25,
  },
  button: {
    alignItems: 'center',
    backgroundColor: Colors.Stillwater,
    border: 'none',
    borderLeft: `1px solid ${Colors.ChartGray} !important`,
    borderRadius: 0,
    color: Colors.White,
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    padding: 8,
    textTransform: 'uppercase',

    '&.Mui-selected, &:hover': {
      backgroundColor: Colors.BlueSpruce,
      color: Colors.White,
    },

    '&.Mui-selected:hover': {
      backgroundColor: Colors.BlueSpruce,
      color: Colors.White,
    },
  },
});

export default ViewsButtonGroup;
