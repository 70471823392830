import React from 'react';

import { RequiredChartPropertiesConfig } from 'src/nightingale/types/types';

/**
 * Stores the array of required chart properties for a nested flow or chart overview section.
 */
export const RequiredChartPropertiesContext = React.createContext<{
  requiredChartProperties?: RequiredChartPropertiesConfig;
}>({});

export const RequiredChartPropertiesContextProvider: React.FC<{
  requiredChartProperties?: RequiredChartPropertiesConfig;
}> = ({ requiredChartProperties, children }) => (
  <RequiredChartPropertiesContext.Provider value={{ requiredChartProperties }}>
    {children}
  </RequiredChartPropertiesContext.Provider>
);
