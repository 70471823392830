import reduce from 'lodash/reduce';

import { getUrlOverrideFeatureFlags } from 'src/featureFlags/devFlags';

function getFeatureFlagHeader() {
  return reduce<Record<string, boolean>, string[]>(
    getUrlOverrideFeatureFlags(),
    (accum, value, key) => accum.concat(`${key}=${value}`),
    [],
  ).join(',');
}

export default getFeatureFlagHeader;
