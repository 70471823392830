import gql from 'graphql-tag';

import type { Interaction } from 'src/nightingale/types/types';

/**
 * Query for fetching an Interaction
 *
 * NOTE: The chartPropertyValues in this query aren't filtered by the referenceId because the entire set
 * of latest CPVs is used in the summarization context.
 *
 * NOTE: This also includes nested flows via `elements` (which is an array of Flows or ChartElements),
 * and they are returned from the server as JSON (which are then parsed by Apollo into objects again).
 * This means that nested flows will contain *everything* in the server-side Flow type, whereas the
 * top-level flow must be queried normally. Keep this in mind, that the `flow` part of this query will
 * need to be updated, when expanding features to also be supported in the top-level flow.
 */
export const GET_INTERACTION_SCHEMA = gql`
  query GetInteraction($referenceId: ID!, $interactionKey: String!, $kind: String) {
    interaction: interaction(
      referenceId: $referenceId
      interactionKey: $interactionKey
      kind: $kind
    ) {
      id
      referenceId
      kind
      patientSnapshotTime
      flow {
        elements
        header
        id
        label
        name
        conditions {
          displayIf
          target {
            id
          }
        }
        requiredChartPropertiesConfig {
          name
          child
          requiredWhen
        }
      }
    }
  }
`;

export type QueryResult = {
  interaction: Interaction;
};
