import { multilineStringDef } from 'src/components/forms/schemas/util';

export default {
  type: 'object',
  title: 'Prescriber: Ohio Checklist',
  properties: {
    reviewedMedication: {
      type: 'boolean',
      title:
        'Reviewed medication risks, benefits and alternatives as outlined in the Treatment Agreement',
    },
    consentedToTreatment: {
      type: 'boolean',
      title: 'Patient consented to treatment and signed Treatment Agreement in Patient App',
    },
    reviewedBehavioralHealthMeetings: {
      type: 'boolean',
      title: 'Reviewed behavioral health meetings weekly for the first 90-d of treatment',
    },
    reviewedClinicianFollowups: {
      type: 'boolean',
      title:
        'Reviewed schedule for follow up visits with clinician weekly until stabilized on medication and then at least biweekly for first 90-d, monthly thereafter',
    },
    reviewedNarcan: {
      type: 'boolean',
      title: 'Reviewed Narcan access/use and prescribed if needed',
    },
    reviewedOft: {
      type: 'boolean',
      title:
        'Reviewed drug screening plan including initial oral fluid testing and at least twice per quarter for the first year, then at least once per quarter thereafter (for Naltrexone prescribing OFT every 3 months for first year, then every 6 months thereafter)',
    },
    reviewedPregnancyTesting: {
      type: 'boolean',
      title: 'Reviewed requirement for pregnancy testing if pregnancy risk',
    },
    reviewedInfectionScreening: {
      type: 'boolean',
      title:
        'Reviewed plans for obtaining testing or testing results of recent HIV, Hepatitis C, Hepatitis B and STI screening as indicated',
    },
    precepted: {
      type: 'boolean',
      title: 'Precepted Visit with Collaborating Physician',
    },
    notes: multilineStringDef,
  },
  required: [],
};
