import gql from 'graphql-tag';

import { ChartPropertyValue } from 'src/nightingale/types/types';

export const SAVE_CHART_PROPERTIES = gql`
  mutation saveChartPropertyValues(
    $properties: [ChartPropertyValueInput!]!
    $patientId: ID!
    $interactionReferenceId: ID
  ) {
    chartPropertyValues: saveChartProperties(
      patientId: $patientId
      properties: $properties
      interactionReferenceId: $interactionReferenceId
    ) {
      action
      propertyName
      value
      notes
      isEmpty
      interactionId
    }
  }
`;

export type QueryResult = {
  chartPropertyValues: ChartPropertyValue[];
};
