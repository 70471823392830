import has from 'lodash/has';

import schemas from 'src/components/forms/schemas';

/**
 * Check whether or not the function passed in was called with valid
 * name and version properties
 */
export function addNameAndVersionChecks(fn) {
  return (schema, name, version) => {
    if (!(name in schemas[schema])) {
      throw new Error(`Undefined schema name: ${name}`);
    }

    if (version && !has(schemas[schema][name], version)) {
      throw new Error(`Invalid ${name} schema version given: ${version}`);
    }

    return fn(schema, name, version);
  };
}
