import gql from 'graphql-tag';

export const GET_CHAT_TEAMS_FOR_CONVERSATIONS_QUERY = gql`
  query ($conversationIdList: [ID!]!) {
    getChatTeamsForConversations(conversationIdList: $conversationIdList) {
      conversationId
      teamId
    }
  }
`;

export const MONITOR_CHAT_TEAM_ASSIGNMENTS_QUERY = gql`
  subscription {
    monitorChatTeamAssignments {
      conversationId
      teamId
    }
  }
`;

export const SET_CONVERSATION_TEAM_ASSIGNMENT_MUTATION = gql`
  mutation ($conversationId: ID!, $teamId: ID) {
    setChatTeamForConversation(conversationId: $conversationId, teamId: $teamId) {
      teamId
    }
  }
`;
