import { makeStyles, Snackbar } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  root: {
    pointerEvents: 'none',
    '& *': {
      pointerEvents: 'auto',
    },
    '@media': {
      '&.MuiSnackbar-anchorOriginBottomCenter': {
        left: 'calc(130px + 52%)',
        transform: 'none',
        paddingRight: '3%',
      },
    },
  },
  button: {
    alignItems: 'center',
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    padding: 0,
    textTransform: 'uppercase',
  },
  cancelButton: {
    marginRight: '22px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 4,
  },
  snackbarContents: {
    cursor: 'pointer',
    maxWidth: 600,
    width: 'auto',
    marginTop: 60,
    backgroundColor: Colors.BlueSpruce,
  },
});

/**
 * Similar to the original SnapshotSnackbar component except for some styling changes. This is used
 * when a visit note is displayed side-by-side.
 */
export const SideBySideSnapshotSnackbar: React.FC<{
  onAccept: () => void;
  onDismiss: () => void;
  updatedPropertyLabels: string[];
}> = ({ onAccept, onDismiss, updatedPropertyLabels }) => {
  const styles = useStyles();

  return (
    <Snackbar
      action={
        <>
          <button
            className={`${styles.button} ${styles.cancelButton}`}
            data-testid="snapshot-snackbar-dismiss"
            type="button"
            onClick={onDismiss}
          >
            Dismiss
          </button>
          <button
            className={styles.button}
            data-testid="snapshot-snackbar-cta"
            type="button"
            onClick={onAccept}
          >
            Load new data
            <LoopIcon classes={{ root: styles.iconContainer }} />
          </button>
        </>
      }
      ContentProps={{ className: styles.snackbarContents }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      classes={{ root: styles.root }}
      data-testid="side-by-side-snapshot-snackbar"
      message={
        <>
          This patient&apos;s information has been updated:
          <ul>
            {updatedPropertyLabels.sort().map(label => (
              <li>{label}</li>
            ))}
          </ul>
        </>
      }
      open
    />
  );
};
