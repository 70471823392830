import useTypedSWR from 'src/components/general/useTypedSWR';
import { LOAD_PATIENT_HEADER } from 'src/patientHeader/hooks/usePatientHeaderData.gql';

export const usePatientHeaderData = (patientId: string) => {
  const { data } = useTypedSWR([LOAD_PATIENT_HEADER, { id: patientId }], {
    revalidateOnFocus: false,
  });

  const patient = data?.menuPatient ?? undefined;
  return { patient };
};
