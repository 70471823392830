import { gql } from 'src/generated/gql';

export const PATIENT_ORDERS_WITH_SHIPMENTS_QUERY = gql(`
  query PatientOrdersWithShipments($patientId: ID!) {
    staff_listLabOrdersWithShipments(patientId: $patientId) {
      order {
        createdAt
        id
      }
      shipment {
        carrier
        destinationAddress
        id
        status
        trackingNumber
      }
    }
  }
`);
