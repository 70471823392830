import React from 'react';
import useSWR from 'swr';

import { ACTIVITY_EVENT_DETAIL_QUERY } from 'src/components/pages/patientActivity/EventDetail.gql';
import VisitSummary from 'src/components/pages/visitSummary';
import type { EventAll } from 'src/stores/fragments/events';
import { Event } from 'src/stores/models/event';

const EventDetail: React.VFC<{
  eventId: string;
  patientId: string;
  classes: Record<string, string>;
}> = ({ eventId, patientId, classes }) => {
  const { data: { eventInstance } = {} } = useSWR<{
    eventInstance: EventAll;
  }>([ACTIVITY_EVENT_DETAIL_QUERY, { eventId }], {
    revalidateOnFocus: false,
  });
  return eventInstance ? (
    <div className={classes.summary}>
      <VisitSummary event={Event.create(eventInstance)} patientId={patientId} />
    </div>
  ) : null;
};

export default EventDetail;
