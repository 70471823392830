import { Button, TextField } from '@material-ui/core';
import startCase from 'lodash/fp/startCase';
import React, { useCallback, useEffect, useState } from 'react';

import { useFormStyles } from 'src/dropInClinic/queueSettings/form.styles';

type PrioritizationSettingNumberFormProps = {
  name: string;
  initialValue: number;
  onSubmit: (newSetting: { name: string; value: number }) => Promise<void>;
};

const PrioritizationSettingNumberForm: React.FC<PrioritizationSettingNumberFormProps> = ({
  name,
  initialValue,
  onSubmit,
}) => {
  const classes = useFormStyles();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      onSubmit({ name, value: Number(value) });
    },
    [name, value, onSubmit],
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
  }, []);

  return (
    <form key={name} onSubmit={handleSubmit} className={classes.form}>
      <TextField name={name} value={value} onChange={handleChange} label={startCase(name)} />
      <Button variant="contained" color="primary" type="submit" className={classes.button}>
        Update
      </Button>
    </form>
  );
};

export default PrioritizationSettingNumberForm;
