import React from 'react';

import { ChartContext as TChartContext } from 'src/nightingale/components/ChartContext/types';

export const ChartContext = React.createContext<TChartContext>({
  patientId: '',
});

export const ChartContextProvider: React.FC<TChartContext> = ({
  children,
  interactionId,
  interactionKind,
  interactionReferenceId,
  isReadOnly,
  onRefreshSnapshot,
  patientId,
}) => (
  <ChartContext.Provider
    value={{
      interactionId,
      interactionKind,
      interactionReferenceId,
      isReadOnly,
      onRefreshSnapshot,
      patientId,
    }}
  >
    {children}
  </ChartContext.Provider>
);
