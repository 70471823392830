import React from 'react';

import { Rx } from 'src/components/forms/schemas/util';
import PatientOverviewLink from 'src/components/general/patientOverviewLink';

export default {
  type: 'object',
  title: 'Prescriber: Plan (Follow Up)',
  properties: {
    rx: {
      type: 'object',
      title: 'RX',
      format: 'selectable',
      properties: {
        represcribe: {
          type: 'object',
          title: 'Continue current prescription',
          renderer: {
            component: ({ model, classes }) => {
              return (
                <Rx
                  rxText="Continue current prescription"
                  rx={model.rx}
                  notes={model.rxNotes}
                  classes={classes}
                />
              );
            },
          },
          properties: {
            rx: {
              type: 'string',
              title: 'RX',
              uniforms: {
                multiline: true,
              },
            },
            rxNotes: {
              type: 'string',
              title: 'Notes',
              uniforms: {
                multiline: true,
              },
            },
          },
        },
        updatePrescription: {
          type: 'object',
          title: 'Change prescription',
          renderer: {
            component: ({ model, classes }) => (
              <Rx
                rxText="Change prescription"
                rx={model.newRx}
                notes={model.newRxNotes}
                classes={classes}
              />
            ),
          },
          properties: {
            newRx: {
              type: 'string',
              title: 'New RX',
              uniforms: {
                multiline: true,
              },
            },
            newRxNotes: {
              type: 'string',
              title: 'Notes',
              uniforms: {
                multiline: true,
              },
            },
          },
        },
      },
    },
    osdt: {
      type: 'string',
      title: 'OFT/MAM/Pill Count',
      uniforms: {
        multiline: true,
      },
    },
    followup: {
      type: 'string',
      title: 'Follow Up',
      allowOther: true,
      enum: ['1 week', '2 weeks', '3 weeks', '4 weeks', '1 month', '2 months', '3 months'],
    },
    patientReview: {
      type: 'object',
      title: 'Patient Review',
      renderer: {
        component: ({ model, classes }) => (
          <div className={classes.itemDetails}>
            <span>
              Reviewed medication safety:{' '}
              {model.patientReview.reviewedMedicationSafety === true ? 'Yes' : 'No'}
            </span>
            <span>
              Reviewed Narcan access:{' '}
              {model.patientReview.reviewedNarcanAccess === true ? 'Yes' : 'No'}
            </span>
            <span>
              Reviewed how to contact Care Team:{' '}
              {model.patientReview.reviewedHowToContactCareTeam === true ? 'Yes' : 'No'}
            </span>
          </div>
        ),
      },
      properties: {
        reviewedMedicationSafety: {
          type: 'boolean',
          title: 'Reviewed medication safety',
          tooltip: 'Safe storage, risks, benefits',
        },
        reviewedNarcanAccess: {
          type: 'boolean',
          title: 'Reviewed Narcan access',
          label: (
            <span>
              Reviewed{' '}
              <PatientOverviewLink section="substanceUse">Narcan access</PatientOverviewLink>
            </span>
          ),
        },
        reviewedHowToContactCareTeam: {
          type: 'boolean',
          title: 'Reviewed how to contact Care Team',
        },
      },
    },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
