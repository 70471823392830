import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import React from 'react';

/**
 * Based on eventShow.BannerComponent, but simpler.
 */
export const AccessRevokedBanner: React.FC<{
  open: boolean;
}> = ({ open }) => {
  const classes = useBannerStyles();
  return (
    <Snackbar
      className={classes.wrapper}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
    >
      <SnackbarContent
        classes={{ root: classes.content }}
        message={<div className={classes.banner}>This user&apos;s account access is revoked</div>}
      />
    </Snackbar>
  );
};

const useBannerStyles = makeStyles({
  wrapper: {
    marginTop: 50,
  },
  banner: {
    display: 'flex',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
  },
  content: { backgroundColor: '#c3505d' },
});
