import { gql } from 'src/generated/gql';

export const GET_STATE_AND_PAYOR = gql(`
  query GetStateAndPayor($patientId: ID!, $effectiveDate: DateTime) {
    getPatientStateAndPayor(patientId: $patientId, effectiveDate: $effectiveDate) {
      state
      effectiveInsurance {
        label
        value
      }
    }
  }
`);
