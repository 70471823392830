import { makeStyles, CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import classNames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import chunk from 'lodash/chunk';
import React, { useState, useMemo, FunctionComponent, useEffect } from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import type { TimeSlot } from 'src/scheduling/types';
import { Priority } from 'src/scheduling/types/TimeSlot';

const TIMESLOTS_PER_PAGE = 5;

export type TimeSlotTableProps = {
  timeSlots: TimeSlot[];
  onClick: (timeSlot: TimeSlot) => void;
  timezone: string;
  isLoading: boolean;
  error: Error | undefined;
  displayClickToLoadSlots: boolean;
  numSearchWindowDays?: number;
  withDurations?: boolean;
};

const TimeSlotTable: FunctionComponent<TimeSlotTableProps> = ({
  timeSlots,
  onClick,
  timezone,
  isLoading,
  error,
  displayClickToLoadSlots,
  numSearchWindowDays,
  withDurations = false,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const pages = useMemo(() => chunk(timeSlots, TIMESLOTS_PER_PAGE), [timeSlots]);

  const loadingDisplay = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (!displayClickToLoadSlots && timeSlots.length === 0) {
      return (
        <Typography align="center">
          No availability found
          {numSearchWindowDays && ` in the next ${numSearchWindowDays} days`}
        </Typography>
      );
    }

    return <Typography align="center">Click button to load slots</Typography>;
  };

  useEffect(() => setCurrentPage(0), [timeSlots]);
  const classes = useStyles();

  if (error) {
    return <ErrorAlert message="Error loading timeslots." error={error} />;
  }
  return (
    <>
      <div className={classes.header}>
        <Pagination
          count={pages.length}
          onChange={(e, page) => setCurrentPage(page - 1)}
          page={currentPage + 1} // increment is necessary because the component uses 1-based indexing
        />
      </div>
      {timeSlots.length && !isLoading ? (
        <Table size="small">
          <TableBody>
            {pages[currentPage]?.map(timeSlot => {
              const { start, duration, firstName, lastName, restrictions } = timeSlot;
              const rowKey = `${start.toISOString()}-${firstName}-${lastName}`;
              return (
                <TableRow
                  selected={rowKey === selectedRow}
                  hover
                  className={classNames(classes.rowStyling)}
                  key={`${start.toISOString()}-${firstName}-${lastName}`}
                  data-testid="next-availability-row"
                  onClick={() => {
                    onClick(timeSlot);
                    setSelectedRow(rowKey);
                  }}
                >
                  <TableCell className={classes.date}>
                    {formatInTimeZone(start, timezone, "EEEE, MMM d 'at' p (zz)")}
                  </TableCell>
                  {withDurations && <TableCell>{duration} min</TableCell>}
                  <TableCell>
                    {firstName} {lastName}
                  </TableCell>
                  <TableCell className={classes.additionalInfo}>{restrictions}</TableCell>
                  <TableCell className={classes.additionalInfo}>
                    {timeSlot.priority === Priority.Backup ? 'Backup' : ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        loadingDisplay()
      )}
    </>
  );
};

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0',
  },
  rowStyling: {
    cursor: 'pointer',
    '& td': {
      padding: '8px 8px',
    },
  },

  '@global': {
    '.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
      backgroundColor: '#91ACC2',
    },

    '.MuiTableRow-root:first-child .MuiTableCell-root': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
  },

  date: {
    fontWeight: 'bold',
    width: 300,
  },
  additionalInfo: {
    fontSize: '70%',
    color: '#596066',
  },
});

export default TimeSlotTable;
