export enum UserType {
  Provider = 'Provider',
  Patient = 'Patient',
}

/**
 * The type of a Provider's role, accessed by Provider.teamRole
 *
 * Should match ProviderRole in boulder-server/src/auth/types.ts
 */
export enum ProviderRole {
  Clinician = 'clinician',
  CareAdvocate = 'care_advocate',
  PeerCoach = 'peer_coach',
  BillingSpecialist = 'billing_specialist',
  CaseManager = 'case_manager',
  RegisteredNurse = 'registered_nurse',
  EnrollmentSpecialist = 'enrollment_specialist',
  Navigator = 'navigator',
  OtherStaff = 'other_staff',
}
