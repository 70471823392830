import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { CLINICIAN_REQUEST_TYPES } from 'src/dropInClinic/ClinicRequestQueue';
import type { DropInQueueEntry } from 'src/dropInClinic/types';
import Colors from 'src/nightingale/Colors';
import { ProviderRole } from 'src/stores/users/userType';

export const EndorsementNotifications = ({
  entry: { stateIsAcceptable, insuranceIsAcceptable, requestType },
  providerRole,
}: {
  entry: Pick<DropInQueueEntry, 'stateIsAcceptable' | 'insuranceIsAcceptable' | 'requestType'>;
  providerRole: ProviderRole | null;
}) => {
  const classes = useStyles();
  const displayLicenseMessage =
    providerRole !== ProviderRole.CareAdvocate &&
    CLINICIAN_REQUEST_TYPES.includes(requestType) &&
    !stateIsAcceptable;
  const displayCredentialMessage =
    providerRole !== ProviderRole.CareAdvocate &&
    CLINICIAN_REQUEST_TYPES.includes(requestType) &&
    !insuranceIsAcceptable;

  return (
    <>
      {displayLicenseMessage && (
        <div className={classes.unacceptable}>Not licensed to see patient</div>
      )}
      {displayCredentialMessage && (
        <div className={classes.unacceptable}>Not credentialed to see patient</div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  unacceptable: {
    color: Colors.Coral,
  },
});
