import { makeStyles } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

export const useVideoEndDialogStyles = makeStyles({
  loading: { padding: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' },
  container: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '500px', // Set your width here
      },
    },
  },
  title: {
    '& .MuiTypography-root': {
      color: Colors.Gray7,
      fontFamily: 'Tenor Sans',
      fontSize: 26,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.45,
      letterSpacing: 0.2,
    },
    padding: '16px 0px 16px 0px',
    margin: '0px 24px 0px 24px',
    borderBottom: `1px solid ${Colors.Gray3}`,
  },
  content: {
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    color: Colors.Gray8,
    fontWeight: 400,
  },
  prompt: {
    fontSize: 18,
    color: Colors.BlueSpruce,
    fontWeight: 600,
    marginBottom: 0,
  },
  promptSubText: {
    fontWeight: 600,
  },
  promptHelperText: {
    color: Colors.Gray7,
    fontStyle: 'italic',
  },
  upperCase: { textTransform: 'uppercase' },
  subPrompt: {
    marginTop: 18,
    fontSize: 10,
    color: Colors.Gray6,
    fontWeight: 600,
    marginBottom: 0,
    textTransform: 'uppercase',
    letterSpacing: `.12em`,
  },
  checkboxGroup: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  checkbox: { paddingTop: 2, paddingBottom: 2 },
  otherExplanation: {
    paddingTop: 4,
    paddingLeft: 32,
  },
  formGroup: { width: '100%' },
  footer: {
    paddingTop: 24,
    display: 'flex',
    justifyContent: 'right',
    gap: 4,
  },
});
