import v110Schema from 'src/components/forms/schemas/eventResult/careAdvocateTelehealthPrecheck/1.1.0';

const {
  checkedPatientConsents,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  confirmedPatientPrivacy,
  ...v110Properties
} = v110Schema.properties;

export default {
  ...v110Schema,
  properties: {
    confirmedPatientIdentity: {
      title: 'Confirmed Patient Identity',
      type: 'boolean',
      tooltip: '"Can you please confirm your name and date of birth?"',
    },
    checkedPatientConsents,
    confirmedPatientPrivacy: {
      title: 'Confirmed Patient Privacy',
      type: 'boolean',
      tooltip:
        '"Are you alone? If not, who are you with, and are you comfortable speaking in front of them?"',
    },
    ...v110Properties,
  },
  required: ['currentLocation'],
};
