import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import React, { useContext } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';

const LOGOUT_PATIENT = gql`
  mutation LogoutPatient($id: ID!) {
    logoutPatient(id: $id)
  }
`;

const PatientLogout = ({ patientId }) => {
  const { apolloClient } = useContext(ApolloClientContext);

  const handlePatientLogout = async () => {
    await apolloClient?.mutate({
      mutation: LOGOUT_PATIENT,
      variables: { id: patientId },
    });
  };

  return (
    <Button
      onClick={handlePatientLogout}
      color="primary"
      variant="outlined"
      size="small"
      style={styles.logoutButton}
    >
      Log out patient
    </Button>
  );
};

const styles = {
  logoutButton: {
    marginTop: 24,
  },
} as const;

export default PatientLogout;
