import React from 'react';

import PatientOverviewLink from 'src/components/general/patientOverviewLink';

export default {
  type: 'object',
  title: 'Onboarding: RCV Wrap Up',
  disabled: true,
  properties: {
    discussedInsuranceEstimates: {
      type: 'boolean',
      title: 'Discussed Insurance Estimates',
      label: (
        <span>
          Discussed{' '}
          <PatientOverviewLink section="reference">Insurance Estimates</PatientOverviewLink>
        </span>
      ),
    },
    discussedPeerCoachReachingOut: { type: 'boolean' },
    followUpVisitScheduled: { type: 'boolean' },
    prescriptionsSent: { type: 'boolean' },
    welcomeEmailSent: { type: 'boolean' },
    welcomeMessageSent: { type: 'boolean' },
    peerCoachingTeamNotified: {
      type: 'boolean',
      title: 'Peer Coaching team notified of new enrollee',
    },
    onboardingChecklistCreated: { type: 'boolean' },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
