import cloneDeep from 'lodash/cloneDeep';

import v140Schema from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.4.0';

const v150Schema = cloneDeep(v140Schema);
v150Schema.properties.callType.enum.push('In-App Enrollment');
v150Schema.properties.referrer.properties.referralSourceType.enum.push('My Employer');
v150Schema.properties.referrer.properties.referralSourceType.enum.push('Billboard');
v150Schema.properties.referrer.properties.referralSourceType.enum.push('Radio');
v150Schema.properties.referrer.properties.referralSourceType.enum.push('In-Person Event');

export default v150Schema;
