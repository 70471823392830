import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import type { FieldInputProps, FormikValues } from 'formik';
import get from 'lodash/get';
import React from 'react';

type Option = { label: string; value: any; disabled?: boolean };

const SelectControl: React.FC<{
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  displayEmpty?: boolean;
  field: FieldInputProps<unknown>;
  form: FormikValues;
  fullWidth?: boolean;
  label?: string;
  labelFn?: (option: Option) => string;
  multiple?: boolean;
  options: readonly Option[];
  valueFn?: (option: Option) => any;
}> = ({
  className,
  'data-testid': dataTestid,
  disabled = false,
  displayEmpty = false,
  field,
  form: { touched, errors },
  fullWidth = true,
  label,
  labelFn = option => option.label,
  multiple = false,
  options,
  valueFn = option => option.value,
}) => {
  const errorMessage = get(touched, field.name) ? get(errors, field.name) : null;

  const shrink = displayEmpty ? { shrink: true } : {};

  // Filters out deprecated options. If a deprecated option is the current value, it is displayed as disabled
  // in the dropdown.
  const filteredOptions = options.filter(
    option => !option.disabled || option.value === field.value,
  );

  return (
    <FormControl
      fullWidth={fullWidth}
      className={className}
      disabled={disabled}
      error={errors[field.name] && touched[field.name]}
    >
      <InputLabel {...shrink}>{label}</InputLabel>
      <Select
        multiple={multiple}
        error={errorMessage}
        {...field}
        value={field.value === null || field.value === undefined ? '' : field.value}
        displayEmpty={displayEmpty}
        data-testid={dataTestid}
      >
        {filteredOptions.map(option => (
          <MenuItem value={valueFn(option)} key={valueFn(option)} disabled={!!option.disabled}>
            {labelFn(option)}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText error={errorMessage}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default SelectControl;
