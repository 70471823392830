// Please maintain the sort order when adding to this list
export const ACTIVITY_VIEWS = 'activityViews';
export const ADD_IDS_TO_LIST_CHART_PROPERTY_ITEMS = 'addIdsToListChartPropertyItems';
export const CHAT_PEBBLE_BUTTON = 'chatPebbleButton';
export const CHAT_TEAM_ASSIGNMENTS = 'chatTeamAssignments';
export const CLINIC_ACTIONS_OVERRIDE = 'clinicActionsOverride';
/* Display progress of completed visits in relation to visit limits */
export const COMPLETED_VISITS_PROGRESS = 'completedVisitsProgress';
export const COUNTERSIGN = 'countersign';
export const DISABLE_PEBBLE_CREATION_SHORTCUT = 'disablePebbleCreationShortcut';
export const DISPLAY_STRAIGHTFORWARD_REQUEST_ICON = 'displayStraightforwardRequestIcon';
/* Show UI elements for "air traffic control" users */
export const DROP_IN_ATC_INTERFACE = 'dropInQueueSettings';
export const ENABLE_DISCHARGE_EVENTS_UI = 'enableDischargeEventsUI';
export const INCLUDE_PEBBLES_IN_PATIENT_ACTIVITY = 'includePebblesInPatientActivity';
/** Enables a ListChartProperty to be sorted based on a Handlebars expression + sort order (CHX-945) */
export const LIST_SORTING = 'listSorting';
export const OLD_ACTIVITY_FILTERS = 'oldActivityFilters';
export const PATIENT_CHART_SUMMARY_BAR = 'patientChartSummaryBarV2';
export const PAYOR_BASED_WAITLIST_PROMOTION = 'payorBasedWaitlistPromotion';
export const PEBBLE_TOPIC_BRIDGE_PRESCRIPTION_REQUEST = 'pebbleTopicBridgePrescriptionRequest';
/** Supports ordering POC kits and viewing statuses of existing orders */
export const POC_TESTING = 'pocTesting';
export const PROMOTE_WAITLISTED_PATIENTS = 'promoteWaitlistedPatients';
export const REFRESH_FLOW_ON_INTERACTION = 'refreshFlowOnInteraction';
export const REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY = 'removeLegacyChatStage1ViewOnly';
export const REMOVE_LEGACY_CHAT_STAGE2_HIDE_DISPLAY = 'removeLegacyChatStage2HideDisplay';
export const REQUEST_A_PEER = 'requestAPeer';
export const SHORTER_OFT_VISIT_SLOTS = 'shorterOftVisitSlots';
export const SHOW_PATIENT_PEBBLES_TABLE = 'showPatientPebblesTable';
export const SHOW_PEBBLE_INTERACTION = 'showPebbleInteraction';
export const SHOW_PRE_VISIT_DATA_DISPLAY = 'showPreVisitDataDisplay';
export const SHOW_VIDEO_CALL_TIMER = 'showVideoCallTimer';
export const SMS_COMMUNICATIONS = 'smsCommunications';
export const STAFF_APP_GOOGLE_ANALYTICS = 'staffAppGoogleAnalytics';
export const TURN_OFF_PEBBLES = 'turnOffPebbles';
/** Intakes in Pop-In Clinic involve first a prep visit with a CA, then the clinical visit (ORCA-83) */
export const TWO_PART_PIC_INTAKES = 'twoPartPicIntakes';
/** Video (ORCA-309) */
export const VIDEO_END_DIALOG_V2 = 'videoEndDialogV2';
export const VIDEO_FEEDBACK = 'videoFeedbackForm';
/** Supports displaying a link to a patient's Zendesk tickets in the patient left menu */
export const ZENDESK_PATIENT_SIDEBAR_LINK = 'zendeskPatientSidebarLink';
export const ZENDESK_WEB_MESSENGER_DEBUGGING_TOOL = 'zenDeskWebWidgetDebuggingTool';
// Please maintain the sort order when adding to this list
export const CHART_ELEMENT_V2 = 'chartElementV2';
