import { format, parseISO } from 'date-fns';
import merge from 'lodash/merge';
import React from 'react';

import v100 from 'src/components/forms/schemas/eventResult/careAdvocateSynchronousMam/1.0.0';

export default merge({}, v100, {
  properties: {
    bottle: {
      renderer: {
        component: ({ model, classes }) => (
          <div className={classes.itemDetails}>
            <span>
              Confirmed Patient Name: {model.bottle.confirmedPatientName === true ? 'Yes' : 'No'}
            </span>
            <span>
              Confirmed Prescriber: {model.bottle.confirmedPrescriber === true ? 'Yes' : 'No'}
            </span>
            {model.bottle.confirmedMedicationAndDose && (
              <span>Confirmed Medication and Dose: {model.bottle.confirmedMedicationAndDose}</span>
            )}
            {model.bottle.dateOnBottle && (
              <span>Date on Bottle: {format(parseISO(model.bottle.dateOnBottle), 'PP')}</span>
            )}
            {model.bottle.lastDose && (
              <span>Last Dose: {format(parseISO(model.bottle.lastDose), 'PP')}</span>
            )}
          </div>
        ),
      },
      properties: {
        confirmedMedicationAndDose: {
          type: 'string',
          enum: ['Matches Prescription', 'Does Not Match Prescription'],
        },
        lastDose: {
          type: 'string',
          format: 'date',
        },
      },
    },
  },
});
