export default class ValidationError extends Error {
  errors: { [key: string]: { path: string; kind: string; message: string } };

  constructor() {
    super();
    this.errors = {};
  }

  addError(path: string, kind: string, message: string) {
    this.errors[path] = { path, kind, message };
  }
}
