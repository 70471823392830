import { useRouterStore } from 'mobx-state-router';
import React from 'react';

import { PageContainer } from 'src/nightingale/components/common/PageContainer/PageContainer';
import Pebble from 'src/pebbles/components/Pebble';

const PebblePage: React.FC = () => {
  const {
    routerState: {
      params: { id: pebbleId },
    },
  } = useRouterStore();

  return (
    <PageContainer>
      <div style={{ maxWidth: 730 }}>
        <Pebble id={pebbleId} />
      </div>
    </PageContainer>
  );
};

export default PebblePage;
