import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import { makeStyles, ClassNameMap } from '@material-ui/styles';
import { formatInTimeZone } from 'date-fns-tz';
import MaterialTable from 'material-table';
import React, { useContext, useState } from 'react';

import { PrimaryButton } from 'src/components/general/PrimaryButton';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { Staff_ShipfusionShipmentStatus as ShipfusionShipmentStatus } from 'src/generated/gql/graphql';
import { OrderNewKitDialog } from 'src/labs/OrderNewKitDialog';
import { SUBMIT_LAB_ORDER } from 'src/labs/submitLabOrder.gql';
import type { PatientOrderWithShipment } from 'src/labs/types';
import Colors from 'src/nightingale/Colors';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

const statusLabels: Record<ShipfusionShipmentStatus, string> = {
  [ShipfusionShipmentStatus.BackOrder]: 'Back Order',
  [ShipfusionShipmentStatus.Cancelled]: 'Cancelled',
  [ShipfusionShipmentStatus.InProcess]: 'In Process',
  [ShipfusionShipmentStatus.InQueue]: 'In Queue',
  [ShipfusionShipmentStatus.OnHold]: 'On Hold',
  [ShipfusionShipmentStatus.Shipped]: 'Shipped',
};

export const OrderLogTable: React.FC<{
  data: PatientOrderWithShipment[];
  isLoading: boolean;
  onOrderPlaced: () => void;
  patientId: string;
}> = ({ data, isLoading, onOrderPlaced, patientId }) => {
  const { apolloClient } = useContext(ApolloClientContext);

  const [showOrderModal, setShowOrderModal] = useState<boolean>(false);
  const [isLoadingNewOrder, setIsLoadingNewOrder] = useState(false);

  const styles = useStyles();

  return (
    <div className={styles.table}>
      <MaterialTable<PatientOrderWithShipment>
        columns={[
          {
            title: 'Date',
            field: 'order.createdAt',
            cellStyle,
            defaultSort: 'desc',
            render: ({ order }) =>
              formatInTimeZone(
                order.createdAt,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                'MMM d, yyyy h:mma zzz',
              ),
          },
          {
            title: 'Shipment ID',
            field: 'shipment.id',
            cellStyle,
          },
          {
            title: 'Order ID',
            field: 'order.id',
            cellStyle,
          },
          {
            title: 'Destination Address',
            field: 'shipment.destinationAddress',
            cellStyle,
            render: rowData => (
              <pre style={{ fontFamily: 'inherit' }}>{rowData.shipment?.destinationAddress}</pre>
            ),
          },
          {
            title: 'Status',
            field: 'shipment.status',
            cellStyle,
            render: ({ shipment }) => (
              <>
                {shipment?.status ? <div>{statusLabels[shipment.status]}</div> : null}
                {shipment?.trackingNumber ? (
                  <div>{trackingInfo(shipment.trackingNumber, shipment.carrier, styles)}</div>
                ) : null}
              </>
            ),
          },
        ]}
        data={data}
        isLoading={isLoading}
        options={{
          headerStyle: { ...cellStyle, fontWeight: 'bold' },
          pageSize: 5,
          search: false,
        }}
        title="Order log"
        components={{
          Toolbar: () => (
            <div className={styles.toolbar}>
              <PageTitle>Order Log</PageTitle>
              <PrimaryButton startIcon={<PlaylistAdd />} onClick={() => setShowOrderModal(true)}>
                Order new kit
              </PrimaryButton>
            </div>
          ),
        }}
      />

      <OrderNewKitDialog
        isLoadingNewOrder={isLoadingNewOrder}
        isOpen={showOrderModal}
        onPlaceOrder={async () => {
          setIsLoadingNewOrder(true);
          await apolloClient?.mutate({
            mutation: SUBMIT_LAB_ORDER,
            variables: {
              patientId,
            },
          });
          onOrderPlaced();
          setShowOrderModal(false);
          setIsLoadingNewOrder(false);
        }}
        overviewAddressUrl={`/patients/${patientId}/overview#homeAddress`}
        patientId={patientId}
        setShowModal={setShowOrderModal}
      />
    </div>
  );
};

function trackingInfo(
  trackingNumber: string,
  carrier: string | null,
  styles: ClassNameMap,
): React.ReactElement | null {
  const urlPrefix = trackingUrlPrefix(carrier);
  if (urlPrefix) {
    return (
      <a href={urlPrefix + trackingNumber} target="_blank" rel="noreferrer">
        Tracking
      </a>
    );
  }

  if (trackingNumber && carrier) {
    // We don't recognize this carrier (probably one Shipfusion has added since we wrote this code),
    // so just render the carrier name and tracking number, without a link.
    return (
      <div>
        <div>Tracking: {carrier}</div>
        <div className={styles.tracking}>{trackingNumber}</div>
      </div>
    );
  }

  return null;
}

function trackingUrlPrefix(carrier: string | null): string | undefined {
  switch (carrier) {
    case 'DHLGlobalMail':
    case 'DHLGlobalmailInternational':
      return 'http://webtrack.dhlglobalmail.com/?trackingnumber=';
    case 'DHLExpress':
      return 'http://www.dhl-usa.com/content/us/en/express/tracking.shtml?brand=DHL&AWB=';
    case 'USPS':
      return 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=';
    case 'UPS':
      return 'https://www.ups.com/track?loc=en_US&tracknum=';
    case 'FedEx':
    case 'FedExSmartPost':
      return 'https://www.fedex.com/fedextrack/?trknbr=';
    case 'CanadaPost':
      return 'https://www.canadapost-postescanada.ca/track-reperage/en#/search?searchFor=';
    default:
      return undefined;
  }
}

const cellStyle = {
  fontFamily: 'Nunito Sans',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '145%',
  '& a': {
    color: 'inherit',
  },
};

const useStyles = makeStyles({
  table: {
    '& .MuiToolbar-gutters': {
      padding: 16,
      minHeight: 0,
      width: 'auto',
    },
    '& .MuiTypography-h6': {
      color: Colors.Gray6,
      fontFamily: 'Tenor Sans',
      fontSize: 26,
      fontWeight: 300,
      lineHeight: 1.45,
      margin: 0,
      textTransform: 'capitalize',
    },
    '& .MuiTableCell-root': cellStyle,
  },
  tracking: {
    fontSize: 11,
  },
  toolbar: {
    width: 'calc(100% - 32px)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    alignItems: 'center',
    '& h1': {
      marginBottom: '0 !important',
    },
  },
});
