"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.small = small;
var _handlebars = _interopRequireDefault(require("handlebars"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function small(options) {
  const content = options.fn(this);
  if (!content) return '';
  return new _handlebars.default.SafeString(`
<div class="summarization__small">

${content}

</div>
`);
}