import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { format, isValid, parseISO } from 'date-fns';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import React from 'react';

import FieldGroup from 'src/components/general/fieldGroup';
import PhoneNumber from 'src/components/general/phoneNumber';

const EMPTY_VALUES = [null, undefined, ''];

/**
 * Return a string representation of the field's title.
 *
 * The title can be defined in several locations in the field definition and can include HTML. This
 * function finds the most relevant title in the definition and strips any HTML out of it.
 * @param {object} field - the schema definition for the field
 * @param {string} key - the field's property key
 * @returns {string}
 */
export const getFieldTitle = (field, key) => {
  const title = field?.renderer?.title ?? field?.title ?? startCase(key);
  // Strip out any html tags. For a full removal of html tags this regular expression would be
  // insufficient, but for the limited use of html in titles this should sufice.
  return title?.replace(/<\/?[^>]+(>|$)/g, '');
};

export const hasDefinedProperties = (properties, obj) =>
  obj &&
  properties.some(key => key in obj && !!obj[key] && (!Array.isArray(obj[key]) || obj[key].length));

function getRenderer(field, classes, model, key) {
  return (
    getRendererForFormat(field, classes, model, key) ||
    getRendererForType(field, classes, model, key)
  );
}

function reformatDateTime(value) {
  let parsed = parseISO(value);
  if (!isValid(parsed)) {
    // Unfortunately, some date-time data was serialized with toString() rather than toISOString().
    // Digging out all of the dirty dates and fixing them is impractical, so we need to continue
    // supporting that format indefinitely.
    parsed = new Date(value);
  }
  return <>{format(parsed, 'PP h:mma')}</>;
}

function getRendererForFormat(field, classes, model, key) {
  const value = get(model, key);
  switch (field.format) {
    case 'date':
      return <>{format(parseISO(value), 'PP')}</>;
    case 'date-time':
      return reformatDateTime(value);
    case 'email':
      return <a href={`mailto:${value}`}>{value}</a>;
    case 'phone':
      return <PhoneNumber number={value} />;
    case 'selectable':
      if (value.selectionKey && field.properties && value.selectionKey in field.properties) {
        if (typeof field.properties[value.selectionKey] === 'object') {
          return (
            <ObjectRenderer
              model={value.additional || {}}
              classes={classes}
              schema={field.properties[value.selectionKey]}
            />
          );
        } else if (field.properties[value.selectionKey] === true) {
          return <>{startCase(value.selectionKey)}</>;
        }
      }
      return null;
    default:
      return null;
  }
}

function getBooleanRenderValue(value, field) {
  let { trueValue } = field.renderer || {};
  const { falseValue = 'No', useTitle } = field.renderer || {};
  if (!trueValue) {
    trueValue = useTitle ? field.title : 'Yes';
  }
  return value ? trueValue : falseValue;
}

function getRendererForType(field, classes, model, key) {
  const value = get(model, key);

  if (!field.type || EMPTY_VALUES.includes(value)) {
    return null;
  }

  switch (field.type) {
    case 'string':
      return <>{value}</>;
    case 'integer':
      return <>{value}</>;
    case 'boolean':
      return <>{getBooleanRenderValue(value, field)}</>;
    case 'array':
      /* eslint-disable react/no-array-index-key */
      return (
        <ul>
          {value.map((item, index) => (
            <li key={`${key}-${index}`}>
              {field.items.renderer
                ? field.items.renderer.component({ model: item, classes, key })
                : getRenderer(field.items, classes, model, `${key}[${index}]`)}
            </li>
          ))}
        </ul>
      );
    /* eslint-enable */
    case 'object':
      return <ObjectRenderer key={key} model={value} classes={classes} schema={field} />;
    default:
      return null;
  }
}

const ObjectRenderer = ({ model, classes, schema, toplevel }) => {
  if (!schema || !model || typeof schema !== 'object') {
    return null;
  }
  if (schema?.renderer?.component) {
    return schema.renderer.component({ model, classes, schema });
  } else {
    const { explicitEmptyField } = schema;

    return (
      <>
        {schema &&
          Object.keys(schema.properties).map(key => {
            const field = schema.properties[key];

            // No data to show, unless this is a display only field
            if (EMPTY_VALUES.includes(model[key]) && !field.displayOnly) {
              return null;
            }

            // This field is not supposed to be shown
            if (field.hideRendering) {
              return null;
            }

            // Nothing is defined on any of the child properties
            if (
              field.type === 'object' &&
              !hasDefinedProperties(Object.keys(field.properties), model[key]) &&
              (field.format !== 'selectable' || !model[key].selectionKey) &&
              (!field.taggedText || !model[key].text)
            ) {
              return null;
            }

            // Nothing set in the array
            if (field.type === 'array' && !model[key]?.length) {
              return null;
            }

            // Properties used to denote "explicitly empty" arrays should
            // only be displayed if nothing is defined in the associated array
            if (
              key === explicitEmptyField &&
              Object.keys(schema.properties).find(
                otherKey =>
                  otherKey !== explicitEmptyField &&
                  schema.properties[otherKey].type === 'array' &&
                  model[otherKey] &&
                  model[otherKey].length > 0,
              )
            ) {
              return null;
            }

            let renderComponent;
            // Custom renderer specified for this field
            if (field.renderer?.component) {
              renderComponent = field.renderer.component({ model, classes, key, schema: field });
            } else if (field.image && model[key].url) {
              renderComponent = (
                <img src={model[key].url} alt={`${key}-preview`} className={classes.previewImage} />
              );
            } else if (field.uniforms?.multiline) {
              renderComponent = (
                <Typography variant="body2" className={classes.multiLineText}>
                  {model[key]}
                </Typography>
              );
            } else {
              renderComponent = getRenderer(field, classes, model, key);
            }

            if (!renderComponent) {
              return null;
            }

            if (toplevel) {
              return (
                <FieldGroup
                  label={!field.hideLabel && getFieldTitle(field, key)}
                  key={key}
                  alwaysShow={field.renderer?.alwaysShow}
                >
                  {renderComponent}
                </FieldGroup>
              );
            } else {
              return <React.Fragment key={key}>{renderComponent}</React.Fragment>;
            }
          })}
      </>
    );
  }
};

const styles = () => ({
  itemDetails: {
    '& span': {
      display: 'table',
    },
  },
  multiLineText: {
    whiteSpace: 'pre-line',
  },
  previewImage: {
    width: '20%',
    objectFit: 'contain',
  },
  disabled: {
    opacity: 0.5,
  },
  strikethrough: {
    textDecoration: 'line-through',
  },
  marginTop: {
    marginTop: 10,
  },
  tooltip: {
    fontStyle: 'italic',
  },
  topSeparator: {
    borderTop: '1px solid gray',
    marginTop: 20,
  },
});

export default withStyles(styles)(ObjectRenderer);
