import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  column: {
    maxWidth: 600,
  },
});

export const PageColumn: React.FC = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.column}>{children}</div>;
};
