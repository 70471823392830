import { addDays, endOfDay, endOfWeek, startOfDay, startOfWeek, subDays } from 'date-fns';
import { firstVisibleDay, lastVisibleDay } from 'react-big-calendar/lib/utils/dates';

import { createLocalizer } from 'src/calendars/localizer';

const localizer = createLocalizer();

/**
 * Returns a tuple representing the range of dates that should be represented on the specified
 * calendar view that includes the given date.
 *
 * @export
 * @param {Date} date The date reflected in the calendar view
 * @param {string} view The name of the view (e.g. day, week, month, agenda, customAgenda)
 * @returns {[Date, Date]}
 */
export function getCalendarRange(date: Date, view: string): [Date, Date] {
  switch (view) {
    case 'day':
      return [startOfDay(date), endOfDay(date)];
    case 'week':
      return [subDays(startOfWeek(date), 1), addDays(endOfWeek(date), 1)];
    case 'month':
      return [firstVisibleDay(date, localizer), lastVisibleDay(date, localizer)];
    case 'agenda':
    case 'customAgenda':
      return [startOfDay(date), addDays(endOfDay(date), 30)];
    default:
      throw new Error(`Unknown view: ${view}`);
  }
}
