import { chartPropertyContext } from '@boulder-package/expressions';
import isNil from 'lodash/isNil';

import { FlagsContext } from 'src/components/featureflags/featureFlagContext';
import { DspStatus } from 'src/drugScreening/types';
import { ChartContext } from 'src/nightingale/components/ChartContext/types';
import { getConditionalMap as getListConditionalMap } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.conditions';
import { ConditionalMap, evaluateConditions } from 'src/nightingale/conditionals';
import RequiredChartProperties from 'src/nightingale/requiredChartProperties/RequiredChartProperties';
import { convertChartProperty } from 'src/nightingale/summarization/conversion';
import {
  ChartElementKind,
  ChartPropertyAction,
  ChartPropertyTypes,
  LabelType,
  SelectOption,
  ChartElement as TChartElement,
  AnyChartProperty,
  ListChartProperty,
  ChartPropertyWithValue,
  ObjectChartProperty,
} from 'src/nightingale/types/types';

/**
 * Encapsulates our domain logic around how ChartElements need to behave, what with confirmation
 * and refreshing and whatnot
 */
export default class ChartElement {
  private readonly conditionalMap: Record<string, boolean>;

  private readonly flags: FlagsContext;

  /**
   * Gets us a ChartElement API
   *
   * @param {TChartElement} definition The definition from Dato, augmented with values from the patient's
   * latest corresponding CPV
   * @param {ChartContext} chartContext The Context in which this ChartElement lies
   * @param {RequiredChartProperties} [requiredChartProperties] API to evaluate e.g. whether required chart
   * properties in this ChartElement are missing their values. If not provided, assumes none are required (and
   * therefore also that none are missing).
   * @param {FlagsContext} flags The currently active feature flags, which affect things like required fields
   */
  constructor(
    private definition: TChartElement,
    private chartContext: ChartContext,
    private requiredChartProperties?: RequiredChartProperties,
    flags?: FlagsContext,
  ) {
    this.conditionalMap = getConditionalMap(definition);
    this.flags = flags ?? {};
  }

  /**
   * The kind of ChartElement this is
   *
   * Depends on the `__typename` attribute of the definition, which is a graphql mechanism for indicating
   * the concrete type when responses include a union-type.
   */
  get kind() {
    switch (this.definition.__typename) {
      case 'ChartElement':
      case 'ChartElementRecord':
        return ChartElementKind.Patient;
      case 'InteractionChartElement':
      case 'InteractionChartElementRecord':
        return ChartElementKind.Interaction;
      default:
        return undefined;
    }
  }

  get isPatientChartElementInFlow() {
    return this.kind === ChartElementKind.Patient && this.chartContext.interactionReferenceId;
  }

  get onRefreshSnapshot() {
    return this.kind === ChartElementKind.Patient ? this.chartContext.onRefreshSnapshot : undefined;
  }

  get isReadOnly() {
    return (!!this.onRefreshSnapshot || this.chartContext.isReadOnly) ?? false;
  }

  get needsConfirmation() {
    return (
      !!this.chartContext.interactionReferenceId &&
      this.kind === ChartElementKind.Patient &&
      !this.onRefreshSnapshot &&
      !this.isReadOnly &&
      !this.isUpdated &&
      this.hasResponse
    );
  }

  /**
   * Is there a response to one or more properties?
   *
   * A response is:
   * - A non-nil `value`, or
   * - `isEmpty` is true (i.e. "marked as none")
   */
  get hasResponse() {
    return this.definition.properties.some(property => {
      if (property.isEmpty) return true;
      if (isNil(property.value)) return false;

      switch (property.type) {
        case ChartPropertyTypes.Object:
          return Object.values(property.value).some(value => !isNil(value));
        case ChartPropertyTypes.List:
          return property.value.some(item => Object.values(item).some(value => !isNil(value)));
        default:
          // The isNil-check above guarantees value to be non-nil when we reach
          // this point.
          return true;
      }
    });
  }

  get isUpdated() {
    return this.definition.properties.some(
      property =>
        property.action === ChartPropertyAction.Update &&
        this.chartContext.interactionId === property.interactionId,
    );
  }

  get isConfirmed() {
    return this.definition.properties
      .filter(p => this.isPropertyShown(p.id))
      .every(
        property =>
          property.action === ChartPropertyAction.Confirm &&
          this.chartContext.interactionId === property.interactionId,
      );
  }

  /**
   * Has this chart element been flagged for special prominence?
   *
   * Currently, we can either check a box in the FYI chart element or select a drug screening status
   * of "regulatory pause" to cause this.
   */
  get isFlagged() {
    return this.isFyiFlagged() || this.isDspRegulatoryPause();
  }

  /** Are any of the chart properties (or child chart properties) required and missing values? */
  get hasMissingRequiredProperties(): boolean {
    return this.definition.properties.some(prop => {
      // If a required chart property is hidden, it does not need to meet the required criteria
      if (!this.isPropertyShown(prop.id)) {
        return false;
      }

      if (isListChartProperty(prop)) {
        return (
          this.requiredChartProperties?.isMissing(prop as ChartPropertyWithValue) ||
          this.listPropertyHasItemWithMissingRequiredProperty(prop)
        );
      }

      if (isObjectChartProperty(prop)) {
        return (
          this.requiredChartProperties?.isMissing(prop as ChartPropertyWithValue) ||
          this.objectPropertyHasMissingRequiredProperties(prop)
        );
      }

      // We set the value already elsewhere, so it's okay to cast and assume it's there
      return this.requiredChartProperties?.isMissing(prop as ChartPropertyWithValue);
    });
  }

  /**
   * Does this chart element have a condition that we need to warn providers about
   *
   * Currently, selecting a drug screening status of "at risk" can cause this.
   */
  get hasWarning() {
    return this.isDspAtRisk();
  }

  get labelType(): LabelType {
    if (this.isPatientChartElementInFlow) {
      return 'patient-header-with-icon';
    } else if (this.kind === ChartElementKind.Interaction) {
      return 'interaction-header-with-icon';
    }
    return 'patient-header';
  }

  private isPropertyShown(propertyId: string): boolean {
    return !(this.conditionalMap[propertyId] === false);
  }

  private isFyiFlagged() {
    return (
      this.definition.name === 'fyi' &&
      this.definition.properties.some(
        property => property.name === 'fyiFlagged' && property.value === true,
      )
    );
  }

  private isDspRegulatoryPause(): boolean {
    return (
      this.definition.name === 'drugScreeningPlan' &&
      this.definition.properties.some(
        property =>
          property.name === 'dspStatus' &&
          (property.value as SelectOption)?.value === DspStatus.RegulatoryPause,
      )
    );
  }

  private isDspAtRisk(): boolean {
    return (
      this.definition.name === 'drugScreeningPlan' &&
      this.definition.properties.some(
        property =>
          property.name === 'dspStatus' &&
          (property.value as SelectOption)?.value === DspStatus.AtRisk,
      )
    );
  }

  private listPropertyHasItemWithMissingRequiredProperty(prop: ListChartProperty): boolean {
    if (!prop.value || prop.value.length === 0) return false; // No items means no missing children.

    return prop.value.some(listItem => {
      const conditionalMap = getListConditionalMap(
        { properties: prop.properties, conditions: prop.conditions },
        listItem,
      );

      return prop.properties.some(childProperty => {
        if (childProperty.id in conditionalMap) {
          // Child Property is conditional
          if (!conditionalMap[childProperty.id]) {
            // Skip check for missing value - Child Property is hidden or conditional list children feature flag is disabled
            return false;
          }
        }

        return this.requiredChartProperties?.isMissing(
          { ...childProperty, value: listItem[childProperty.name] },
          prop,
        );
      });
    });
  }

  private objectPropertyHasMissingRequiredProperties(prop: ObjectChartProperty): boolean {
    const childValues = prop.value;
    if (!childValues) return false; // No value means no missing children.

    return prop.properties.some(
      childProperty =>
        this.requiredChartProperties?.isMissing(
          { ...childProperty, value: childValues[childProperty.name] },
          prop,
        ),
    );
  }
}

/**
 * Calculates the ChartElement's conditional map based on its definition (with values)
 *
 * Copied and modified from ListControl.conditions.ts.
 */
function getConditionalMap(
  definition: Pick<TChartElement, 'properties' | 'conditions'>,
): ConditionalMap {
  if (!definition.conditions) {
    return {};
  }

  const context = chartPropertyContext(
    definition.properties.map(convertChartProperty).filter(notNull),
    definition.properties.map(p => ({
      propertyName: p.name,
      value: p.value,
    })),
  );

  return evaluateConditions(definition.conditions, context);
}

function isListChartProperty(prop: AnyChartProperty): prop is ListChartProperty {
  return prop.type === ChartPropertyTypes.List;
}

function isObjectChartProperty(prop: AnyChartProperty): prop is ObjectChartProperty {
  return prop.type === ChartPropertyTypes.Object;
}

function notNull<T>(p: T | null): p is T {
  return p !== null;
}
