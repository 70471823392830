import { types, Instance } from 'mobx-state-tree';

import { getPreferredFullName } from 'src/shared/stores/resource';

export type UserDisplayType = Instance<typeof UserDisplay>;
export const UserDisplay = types
  .model({
    id: types.string,
    firstName: types.string,
    lastName: types.string,
    preferredName: types.maybeNull(types.string),
    pronouns: types.maybeNull(types.string),
    userType: types.maybeNull(types.string),
    patientFacingDisplayName: types.maybeNull(types.string),
  })
  .views(self => ({
    get preferredFullName(): string {
      return getPreferredFullName(self);
    },
  }));
