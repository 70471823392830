import { ChartPropertyType } from '@boulder-package/chart-definition-types';
import * as expressions from '@boulder-package/expressions';
import { ContextSource } from '@boulder-package/expressions';
import Handlebars, { Exception } from 'handlebars';
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import isArray from 'lodash/isArray';
import mapKeys from 'lodash/mapKeys';
import mergeWith from 'lodash/mergeWith';

import { convertChartProperty, convertValue } from 'src/nightingale/summarization/conversion';
import type {
  Context,
  Output,
  Template,
  AdditionalContext,
} from 'src/nightingale/summarization/types';
import {
  ChartPropertyValue,
  AnyChartProperty,
  ChartPropertyTypes,
} from 'src/nightingale/types/types';
import logger from 'src/shared/util/logger';

export type { SummarizationPatient, Context } from 'src/nightingale/summarization/types';

function formatException(err: Exception): Output {
  return Handlebars.escapeExpression(`ERROR in summary template: ${err.message}`);
}

export function summarization(template: Template, context?: Context): Output {
  try {
    return expressions.evaluateHtmlExpression(template, {
      ...context,
      $source: ContextSource.Staff,
    });
  } catch (err) {
    return formatException(err);
  }
}

export function evaluateBooleanExpression(template: Template, context?: Context): boolean {
  try {
    return expressions.evaluateBooleanExpression(template, {
      ...context,
      $source: ContextSource.Staff,
    });
  } catch (err) {
    logger.warn(`ERROR in boolean expression: ${err.message}`);
    return false;
  }
}

export function makeSummarizationContext(
  chartPropertyValues: ChartPropertyValue[],
  chartProperties: AnyChartProperty[],
  additionalContext?: AdditionalContext,
): Context {
  return {
    ...expressions.chartPropertyContext(
      compact(chartProperties.map(convertChartProperty)), // compact drops nulls from invalid types
      chartPropertyValues.map(convertValue),
    ),
    // creates special dollar-prefixed values in context like $patient
    ...mapKeys(additionalContext || {}, (value, key) => `$${key}`),
  };
}

/**
 * Merges the partial data from `chartPropertyValues` into the data from `context` and returns the result
 *
 * @param {Context} context The original summarization context that we want to get an updated copy of.
 * @param {Partial<ChartPropertyValue>[]} chartPropertyValues The CPVs we want to merge into the context.
 *  Each property name may or may not be present, and within each property, each part of the value (e.g.
 *  isEmpty, notes) may or not be present.
 * @param {AnyChartProperty[]} chartProperties The chart property definitions to use when building the context.
 *  These should be aligned with the chartPropertyValues parameter. Having any missing means conversion cannot
 *  be accomplished, and having extraneous ones will result in overwriting with null values.
 * @returns {Context} A new copy of the context, updated to include the given CPVs.
 */
export function getUpdatedSummarizationContext(
  context: Context,
  chartPropertyValues: ChartPropertyValue[],
  chartProperties: AnyChartProperty[],
): Context {
  const newPartialValues = expressions.chartPropertyContext(
    compact(chartProperties.map(convertChartProperty)), // compact drops nulls from invalid types
    chartPropertyValues,
  );

  return createMergedContext(context, newPartialValues);
}

export function textValue(
  type: ChartPropertyTypes,
  value: any,
  context?: expressions.ValueRenderingContext,
) {
  return expressions.textValue(type as string as ChartPropertyType, value, context);
}

export const __test__ = {
  formatException,
};

/**
 * Returns a merged copy of the given contexts
 *
 * Does not alter the given contexts.
 */
function createMergedContext(
  context: Context,
  newPartialValues: expressions.ChartPropertyContext,
): Context {
  return mergeWith(cloneDeep(context), newPartialValues, (_, srcValue) =>
    // Always overwrite arrays with the source value. Plain `merge` recurses into arrays, so when the source is empty,
    // the existing array is returned unchanged. This enables us to overwrite with an empty array.
    isArray(srcValue) ? srcValue : undefined,
  );
}
