import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChatIcon from '@material-ui/icons/ChatBubble';
import CloseIcon from '@material-ui/icons/Close';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import React from 'react';

import featureFlagContext from 'src/components/featureflags/featureFlagContext';
import AddMonitoredProviderDialog from 'src/components/pages/pageElements/addMonitoredProviderDialog';
import ChatComponent from 'src/components/pages/pageElements/chat';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import CreateConversationDialog from 'src/components/pages/pageElements/createConversationDialog';
import SendBulkMessageDialog from 'src/components/pages/pageElements/sendBulkMessageDialog';
import { REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY } from 'src/featureFlags/currentFlags';
import { User } from 'src/shared/stores/resource';
import { ProviderRole } from 'src/stores/users/userType';
import { colors } from 'src/util/colors';

/**
 @deprecated hidden behind the REMOVE_LEGACY_CHAT_STAGE2_HIDE_DISPLAY FF. Delete with flag.
 */
class Chat extends React.Component {
  static contextType = featureFlagContext;

  constructor(props) {
    super(props);
    this.state = {
      addMonitoredConversation: false,
      createConversationWith: null,
      removeMonitoredConversationFor: null,
      sendBulkMessage: false,
    };
  }

  handleSelectConversation = conversation => {
    this.props.rootStore.routerStore.goTo('chatConversation', {
      params: { conversation: conversation.id },
    });
  };

  handleSelectNewConversation = () => {
    this.props.rootStore.routerStore.goTo('chatConversation', {
      params: { conversation: 'create' },
    });
  };

  handleOpenVideoConversation = message => {
    const generatedUrl = this.props.rootStore.generateRouteUrl('chatVc', {
      conversation: this.props.rootStore.routerStore.routerState.params.conversation,
      message: message.id,
    });
    window.open(generatedUrl, '_blank');
  };

  handleFinishAddMonitor = async ({ monitorProvider }) => {
    const {
      rootStore: { chat, providers },
    } = this.props;

    const { id, providersMonitored = [] } = providers.provider;

    if (monitorProvider.id !== id && !providersMonitored.find(p => p.id === monitorProvider.id)) {
      await providers.monitorProvider(monitorProvider);
      await providers.reload();
      // We store the list of monitored providers in at least three different places and two of them
      // (chat and providers stores) need to be made aware of this change in order for this list
      // of providers to update correctly and for the conversations to load.
      chat.monitorProvider(monitorProvider);
    }

    this.setState({ addMonitoredConversation: false });
  };

  /**
   * Autocomplete search function to hide the current user and
   * any providers that are already monitored.
   */
  searchProviders = async q => {
    const {
      rootStore: { providers: providersStore, searchProviders },
    } = this.props;

    const providers = await searchProviders(q);

    const { id, providersMonitored = [] } = providersStore.provider;

    return providers
      .filter(p => p.id !== id)
      .filter(p => !providersMonitored.find(monitored => p.id === monitored.id));
  };

  /**
   * Autocomplete search function to omit the current logged in user only.
   */
  searchAdditionalProviders = async q => {
    const {
      rootStore: {
        auth: { user },
        searchProviders,
      },
    } = this.props;

    const providers = await searchProviders(q);

    return providers.filter(p => p.id !== user.id);
  };

  unsubscribeFrom = async () => {
    const { removeMonitoredConversationFor } = this.state;
    const {
      rootStore: { chat, providers },
    } = this.props;
    const { provider } = providers;
    const { id, providersMonitored } = provider;

    const providerIndex = providersMonitored.findIndex(
      monitoredProvider => monitoredProvider.id === removeMonitoredConversationFor.id,
    );
    if (providerIndex > -1) {
      await providers.saveProvider({
        id,
        providersMonitored:
          providerIndex + 1 === providersMonitored.length
            ? [...providersMonitored.slice(0, providerIndex)]
            : [
                ...providersMonitored.slice(0, providerIndex),
                ...providersMonitored.slice(providerIndex + 1, providersMonitored.length),
              ],
      });
      await providers.reload();
      await chat.removeMonitoredConversationFor(removeMonitoredConversationFor);
    }
    this.setState({ removeMonitoredConversationFor: null });
  };

  toggleMutedNotifications = async () => {
    const {
      rootStore: { auth, providers },
    } = this.props;
    await providers.saveProvider({
      id: auth.user.id,
      unreadMessageNotifications: !auth.user?.unreadMessageNotifications,
    });
    await auth.loadUserInfo();
  };

  handleCreateStaffConversation = () => {
    this.setState({ createConversationWith: 'staff' });
  };

  handleCreatePatientConversation = () => {
    this.setState({ createConversationWith: 'patient' });
  };

  handleCancelCreate = () => {
    this.setState({ createConversationWith: null });
  };

  handleFinishCreate = ({ conversationPatient, conversationProviders }) => {
    let users = [];
    if (conversationPatient) {
      users.push(User.create(conversationPatient));
    }
    if (conversationProviders) {
      users = users.concat(conversationProviders.map(provider => User.create(provider)));
    }
    const conversation = this.props.rootStore.chat.conversationWith(...users);
    this.handleCancelCreate();

    if (conversation.id) {
      this.handleSelectConversation(conversation);
    } else {
      this.handleSelectNewConversation();
    }
  };

  handleSendBulkMessage = async data => {
    await this.props.rootStore.chat.sendBulkMessage(
      data.message,
      data.participants.map(participant => participant.id),
      data.providers ? data.providers.map(provider => provider.id) : [],
    );
    this.setState({ sendBulkMessage: false });
  };

  render() {
    const flags = this.context;
    const legacyChatViewOnlyModeFF = flags[REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY] ?? false;

    const {
      classes,
      rootStore: {
        auth: { user },
        chat,
        flash,
        providers: { provider },
        patients: { chat: participantChat },
      },
    } = this.props;
    const {
      addMonitoredConversation,
      createConversationWith,
      removeMonitoredConversationFor,
      sendBulkMessage,
    } = this.state;

    const allOtherConversationsWithId = participantChat?.allConversationsWithId;

    const staffConversationsList = {
      key: 'staff-messages',
      chatOwner: chat.chatOwner,
      heading: `My Staff Messages${
        chat.numWithStaffUnread > 0 ? ` (${chat.numWithStaffUnread})` : ''
      }`,
      conversations: chat.sortedStaffConversations,
      hasNewConversation: chat.newConversation && !chat.newConversation.hasPatients,
    };

    /**
     * The "Add new Staff Conversation" button is deprecated and is now hidden behind the REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY FF
     */
    if (!legacyChatViewOnlyModeFF) {
      staffConversationsList.headerIcon = <AddCircleOutlineIcon />;
      staffConversationsList.headerIconAction = this.handleCreateStaffConversation;
      staffConversationsList.headerIconTooltip = 'Add new Staff Conversation';
    }

    const numUnreadRecent = () =>
      chat.numWithUnarchivedPatientUnread > 0 ? ` (${chat.numWithUnarchivedPatientUnread})` : '';

    const recentPatientConversationsList = {
      key: 'patient-messages',
      chatOwner: chat.chatOwner,
      defaultExpanded: true,
      heading: `My Recent Participant Messages${numUnreadRecent()}`,
      conversations: chat.sortedUnarchivedPatientConversations,
      hasNewConversation: chat.newConversation?.hasPatients,
      warn: true,
    };

    /**
     * The "Add new Patient Conversation" button is deprecated and is now hidden behind the REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY FF
     */
    if (!legacyChatViewOnlyModeFF) {
      recentPatientConversationsList.headerIcon = <AddCircleOutlineIcon />;
      recentPatientConversationsList.headerIconAction = this.handleCreatePatientConversation;
      recentPatientConversationsList.headerIconTooltip = 'Add new Patient Conversation';
    }

    const archivedPatientConversationsList = {
      key: 'archived-patient-messages',
      chatOwner: chat.chatOwner,
      heading: `My Archived Participant Messages`,
      dataLoader: () => chat.loadProviderConversations(true),
      defaultExpanded: chat.currentConversation?.isArchivedForCurrentUser,
      warn: true,
      conversations: chat.sortedArchivedPatientConversations,
    };

    const conversationListButtons = [];

    /**
     * @deprecated: Bulk chat and Monitored conversation features are hidden behind REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY FF
     */
    if (!legacyChatViewOnlyModeFF) {
      conversationListButtons.push({
        icon: GroupAddIcon,
        label: 'Monitor additional providers',
        onClick: () => this.setState({ addMonitoredConversation: true }),
      });
      conversationListButtons.push({
        icon: RecentActorsIcon,
        label: 'Message multiple participants',
        onClick: () => this.setState({ sendBulkMessage: true }),
      });
    }

    if (user?.teamRole === ProviderRole.Clinician) {
      const isActive = user?.unreadMessageNotifications === false;
      conversationListButtons.push({
        icon: () => (
          <>
            <figure className={classes.muteChatIcon}>
              <ChatIcon />
              {!isActive && <span className={classes.muteChatIconSlash} />}
            </figure>
          </>
        ),
        isActive,
        label: isActive ? 'Unmute message notifications' : 'Mute message notifications',
        onClick: this.toggleMutedNotifications,
      });
    }

    /**
     * @deprecated: monitored conversations feature is hidden behind REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY FF
     */
    const isMonitoredConversationsEnabled = !legacyChatViewOnlyModeFF && provider;
    const monitoredConversationsList = isMonitoredConversationsEnabled
      ? provider.providersMonitored.map(monitoredProvider => {
          const unreads = chat.monitoredChatsFor(monitoredProvider.id)
            ? chat.monitoredChatsFor(monitoredProvider.id).numWithPatientUnread
            : monitoredProvider.unreadPatientConversationCount;
          return {
            key: monitoredProvider.id,
            chatOwner: monitoredProvider,
            defaultExpanded: chat
              .monitoredChatsFor(monitoredProvider.id)
              ?.conversationsById.has(chat.currentConversationId),
            heading: `${monitoredProvider.firstName}'s Messages${
              unreads > 0 ? ` (${unreads})` : ''
            }`,
            headerIcon: <CloseIcon />,
            headerIconAction: () =>
              this.setState({ removeMonitoredConversationFor: monitoredProvider }),
            headerIconTooltip: `Unsubscribe from ${monitoredProvider.firstName}'s Messages`,
            warn: true,
            conversations:
              chat.monitoredChatsFor(monitoredProvider.id)?.sortedPatientConversations || [],
            dataLoader: () => chat.loadMonitoredConversations(monitoredProvider.id),
          };
        })
      : [];

    return (
      <div className={classes.root}>
        <ChatComponent
          currentConversation={chat.currentConversation}
          newConversation={chat.newConversation}
          conversationId={this.props.rootStore.routerStore.routerState.params.conversation}
          conversationLists={[
            recentPatientConversationsList,
            ...monitoredConversationsList,
            archivedPatientConversationsList,
            staffConversationsList,
          ]}
          conversationListButtons={conversationListButtons}
          user={user}
          handleSelectConversation={this.handleSelectConversation}
          handleSelectNewConversation={this.handleSelectNewConversation}
          handleOpenVideoConversation={this.handleOpenVideoConversation}
          allConversationsWithId={chat.allConversationsWithId}
          allOtherConversationsWithId={allOtherConversationsWithId}
          showErrorMessage={flash}
        />

        {createConversationWith && (
          <CreateConversationDialog
            onReset={this.handleCancelCreate}
            onSubmit={this.handleFinishCreate}
            fixedAttendees={[getSnapshot(user)]}
            createConversationWith={createConversationWith}
          />
        )}
        {addMonitoredConversation && (
          <AddMonitoredProviderDialog
            onReset={() => this.setState({ addMonitoredConversation: false })}
            onSubmit={this.handleFinishAddMonitor}
            loadOptions={this.searchProviders}
          />
        )}

        {removeMonitoredConversationFor && (
          <ConfirmDialog
            isDestructive
            submitLabel="Ok"
            onCancel={() => this.setState({ removeMonitoredConversationFor: null })}
            onSubmit={this.unsubscribeFrom}
          >
            {`Are you sure you want to stop monitoring ${removeMonitoredConversationFor.firstName}'s messages?`}
          </ConfirmDialog>
        )}

        {sendBulkMessage && (
          <SendBulkMessageDialog
            searchAdditionalProviders={this.searchAdditionalProviders}
            onReset={() => this.setState({ sendBulkMessage: false })}
            onSubmit={this.handleSendBulkMessage}
          />
        )}
      </div>
    );
  }
}

const styles = () => ({
  root: {
    paddingTop: 64,
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
  },
  muteChatIcon: {
    display: 'inline-block',
    height: 24,
    margin: 0,
    padding: 0,
    paddingRight: 5,
    position: 'relative',
  },
  muteChatIconSlash: {
    borderBottom: `2px solid ${colors.taupe}`,
    borderTop: '2px solid #F3F3F3',
    content: '',
    height: 0,
    left: -3,
    position: 'absolute',
    top: 8,
    transform: 'rotate(225deg)',
    width: 28,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(Chat)));
