import { Button, Tooltip } from '@material-ui/core';
import { IconCalendarTime } from '@tabler/icons-react';
import classNames from 'classnames';
import React from 'react';

import { useButtonStyles } from 'src/components/general/PatientMenu/highlights/useButtonStyles';
import { useSWRPatientHighlights } from 'src/components/general/PatientMenu/highlights/useSWRPatientHighlights';
import useTooltipStyles from 'src/components/general/PatientMenu/useTooltipStyles';
import { PatientHighlightVisitCadence } from 'src/generated/gql/graphql';
import { theme as appTheme } from 'src/theme';

const VisitCadence: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const tooltipStyles = useTooltipStyles();
  const styles = useButtonStyles();
  const buttonClassNames = [styles.button];

  const { data: highlightsData } = useSWRPatientHighlights(patientId);

  if (!highlightsData) return null; // loading

  const visitCadence = highlightsData?.patientHighlights?.visitCadence;
  const tooltipText = getTooltipText(visitCadence);
  const label = getLabelText(visitCadence);

  return (
    <Tooltip
      title={tooltipText}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a href={`/patients/${patientId}/overview#clinicalCarePlan`}>
        <Button
          className={classNames(buttonClassNames)}
          fullWidth
          size="small"
          startIcon={<IconCalendarTime size={appTheme.typography.fontSize} />}
          variant="outlined"
        >
          {label}
        </Button>
      </a>
    </Tooltip>
  );
};

const shortenedLabels = {
  declined_care: 'decl.',
  ineligible_for_clinical_care: 'inelig.',
};

function getLabelText(visitCadence: PatientHighlightVisitCadence | undefined | null): string {
  if (!visitCadence) {
    return '+';
  } else if (visitCadence.value === 'other') {
    return 'other';
  } else if (visitCadence.value && shortenedLabels[visitCadence.value]) {
    return shortenedLabels[visitCadence.value];
  }

  return visitCadence.label;
}

function getTooltipText(visitCadence: PatientHighlightVisitCadence | undefined | null) {
  if (!visitCadence) {
    return <em>Click to add visit cadence</em>;
  } else if (visitCadence.value === 'other') {
    // Remove 'Other:' from beginning of label
    const formattedLabel = visitCadence.label.replace(/^Other: /, '');
    return `Visit cadence: ${formattedLabel}`;
  }

  return `Visit cadence: ${visitCadence.label}`;
}

export default VisitCadence;
