import { Theme, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';

import Colors from 'src/nightingale/Colors';

const DISPLAY_LIMIT = 2;

export const RequiredFieldsWarning: React.VFC<{ items: string[] }> = ({ items }) => {
  const [showFullList, setShowFullList] = useState<boolean>(false);
  const styles = useStyles();

  return (
    <Alert
      classes={{ icon: styles.icon, message: styles.message, root: styles.container }}
      data-testid="error-container"
      severity="error"
    >
      <strong>Please fill out the following required fields:</strong>
      <ul className={styles.list}>
        {(showFullList ? items : items.slice(0, DISPLAY_LIMIT)).map(item => (
          <li>{item}</li>
        ))}
      </ul>
      {items.length > DISPLAY_LIMIT ? (
        <Button
          className={styles.button}
          data-testid="required-fields-expand-button"
          disableRipple
          onClick={() => setShowFullList(!showFullList)}
        >
          {showFullList ? (
            <>
              See less <ExpandLess />
            </>
          ) : (
            <>
              See more <ExpandMore />
            </>
          )}
        </Button>
      ) : null}
    </Alert>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  button: {
    color: Colors.NewDarkGrey,
    fontSize: theme.typography.fontSize,
    fontWeight: 'normal',
    padding: 0,
    textDecoration: 'underline',
    textTransform: 'none',
    '&:hover, &:active': {
      background: Colors.NewLightRed,
      textDecoration: 'underline',
    },
  },
  container: {
    background: Colors.NewLightRed,
    border: `2px solid ${Colors.NewRed}`,
    borderRadius: 0,
    color: Colors.NewRed,
    fontFamily: 'Nunito Sans',
    marginBottom: 20,
  },
  icon: {
    color: `${Colors.NewRed} !important`,
  },
  message: {
    fontSize: theme.typography.fontSize + 2,
  },
  list: {
    fontSize: theme.typography.fontSize,
  },
}));
