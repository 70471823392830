import React from 'react';

import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { TextInput } from 'src/nightingale/components/ChartProperty/controls/Text/TextInput';
import { useDidUpdateEffect } from 'src/nightingale/hooks/useDidUpdateEffect';
import { useShowValidationError } from 'src/nightingale/hooks/useShowValidationError';
import { useValidationForPropertyType } from 'src/nightingale/hooks/useValidation';
import { ChartPropertyTypes, ControlProps, EmailChartProperty } from 'src/nightingale/types/types';

export type EmailControlProps = ControlProps<EmailChartProperty> & {
  id?: string;
};

/**
 * Email Control component. Text Control component with email format validation.
 */
export const EmailControl: React.FC<EmailControlProps> = ({
  description,
  hasEmptyValue,
  isRequired,
  label,
  onChangeValue,
  onError,
  value,
  ...rest
}) => {
  const validationError = useValidationForPropertyType(value, ChartPropertyTypes.Email);
  const [showError, setIsTouched, setHasSeenErrors] = useShowValidationError(value);
  useDidUpdateEffect(() => {
    const hasValidationError = !!validationError?.errors?.length;
    if (hasValidationError) setHasSeenErrors(true);
    if (onError) onError(hasValidationError);
  }, [validationError]);

  const hasError = showError && !!validationError?.errors?.length;
  const errorMessage = showError ? validationError?.message : '';

  return (
    <LabeledFormControl
      error={hasError}
      errorMessage={errorMessage}
      hasEmptyValue={hasEmptyValue}
      htmlFor={rest.id}
      isRequired={isRequired}
      label={label}
    >
      <TextInput
        onBlur={() => setIsTouched(true)}
        onChange={onChangeValue}
        placeholder={description}
        value={value}
        {...rest}
      />
    </LabeledFormControl>
  );
};
