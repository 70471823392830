import React from 'react';

import Engaged from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/renderers/Engaged';
import NoteField from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/renderers/NoteField';
import ReferralSource from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/renderers/ReferralSource';
import PatientOverviewLink from 'src/components/general/patientOverviewLink';
import RouteLink from 'src/components/general/routeLink';

const boolean = { type: 'boolean' };
const notes = {
  notes: {
    type: 'string',
    uniforms: {
      multiline: true,
    },
  },
};
const noteField = {
  type: 'object',
  renderer: {
    component: NoteField,
  },
  properties: notes,
};

export default {
  type: 'object',
  title: 'Inquiry: Potential Patient',
  properties: {
    ...notes,
    referrer: {
      type: 'object',
      title: 'How did you hear about Boulder?',
      renderer: {
        component: ReferralSource,
      },
      properties: {
        referralSourceType: {
          type: 'string',
          allowOther: true,
          enum: [
            'Callback from a previous inquiry',
            'Provider',
            'Insurer',
            'Current Patient',
            'Boulder Peer Coach',
          ],
        },
        referralSourceName: {
          type: 'string',
        },
      },
      required: ['referralSourceType'],
    },
    outcome: {
      type: 'object',
      format: 'selectable',
      properties: {
        engaged: {
          type: 'object',
          title: 'Engaged (first Clinician visit scheduled)',
          renderer: {
            component: Engaged,
          },
          properties: {
            confirmedSeekingOudTreatment: {
              ...boolean,
              title: 'Confirmed seeking OUD treatment',
            },
            confirmedServiceAreaEligibility: boolean,
            confirmedOver18YearsOfAge: boolean,
            patientProfileCreated: {
              ...boolean,
              renderer: {
                title: 'Patient profile created and added to this event',
              },
              label: (
                <span>
                  <RouteLink route="createPatient" newWindow>
                    Patient profile
                  </RouteLink>{' '}
                  created and added to this event
                </span>
              ),
            },
            discussedInsurance: {
              ...boolean,
              renderer: {
                title: 'Discussed Insurance',
              },
              label: (
                <span>
                  Discussed <PatientOverviewLink section="reference">Insurance</PatientOverviewLink>
                </span>
              ),
            },
            confirmedAccessToSmartphone: boolean,
            confirmedAccessToWifi: boolean,
            confirmedAccessToTransportation: boolean,
            confirmedSafeHousing: boolean,
            clinicianVisitScheduled: boolean,
            appSetupComplete: boolean,
            consentsSigned: boolean,
            onboardingChecklistCreated: boolean,
            ...notes,
          },
        },
        ineligibleAge: {
          title: 'Ineligible (age)',
          ...noteField,
        },
        ineligibleGeography: {
          title: 'Ineligible (geography)',
          ...noteField,
        },
        ineligibleNotSeekingOudTreatment: {
          title: 'Ineligible (not seeking OUD treatment)',
          ...noteField,
        },
        unsureRequestedACallback: {
          title: 'Unsure, requested a callback',
          ...noteField,
        },
        notInterestedAtThisTime: {
          title: 'Not interested at this time',
          ...noteField,
        },
        other: {
          title: 'Other',
          ...noteField,
        },
      },
    },
  },
  required: ['outcome'],
};
