import Typography from '@material-ui/core/Typography';
import React from 'react';

const notes = {
  type: 'string',
  uniforms: {
    multiline: true,
  },
};

const renderer = key => ({
  component: ({ model, classes }) => (
    <div className={classes.itemDetails}>
      <span>{model[key].selectionKey === 'yes' ? 'Yes' : 'No'}</span>
      <span>
        {model[key].additional && (
          <Typography variant="body2" className={classes.multiLineText}>
            {model[key].additional.notes}
          </Typography>
        )}
      </span>
    </div>
  ),
});

export default {
  type: 'object',
  title: 'Prescriber: Assessment (Initial)',
  disabled: true,
  properties: {
    ivduRisk: {
      type: 'object',
      title: 'IV Drug Use Risk',
      format: 'selectable',
      renderer: renderer('ivduRisk'),
      properties: {
        yes: {
          type: 'object',
          properties: { notes },
        },
        no: {
          type: 'object',
          properties: { notes },
        },
      },
    },
    pregnancyRisk: {
      type: 'object',
      title: 'Pregnancy Risk',
      format: 'selectable',
      renderer: renderer('pregnancyRisk'),
      properties: {
        yes: {
          type: 'object',
          properties: { notes },
        },
        no: {
          type: 'object',
          properties: { notes },
        },
      },
    },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
