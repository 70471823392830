import { withStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useState } from 'react';

import ConversationList, {
  ConversationListProps,
} from 'src/components/pages/pageElements/conversationList';
import { MaterialStyle } from 'src/shared/types/styles';
import type { ConversationInstance } from 'src/stores/chat';

type DeferredConversationListProps = {
  dataLoader: () => Promise<ConversationInstance[]>;
  onChange?: (event: any, expanded: boolean) => void;
  defaultExpanded?: boolean;
};

const DeferredConversationList: FunctionComponent<
  DeferredConversationListProps & ConversationListProps & MaterialStyle<typeof styles>
> = ({
  classes,
  dataLoader,
  onChange,
  conversations = [],
  defaultExpanded,
  ...conversationListProps
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string>();

  const listOnChange = async (event, expanded) => {
    if (expanded && !isLoading && !conversations.length) {
      setIsLoading(true);
      try {
        await dataLoader();
      } catch (e) {
        setLoadingError(`An error occurred while loading this provider's conversations.`);
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
    if (onChange) {
      onChange(event, expanded);
    }
  };

  return (
    <ConversationList
      {...conversationListProps}
      defaultExpanded={conversations.length ? defaultExpanded : false}
      onChange={listOnChange}
      conversations={isLoading ? [] : conversations}
    >
      {isLoading && <div className={classes.loading}>Loading...</div>}
      {loadingError && <div className={classes.error}>{loadingError}</div>}
    </ConversationList>
  );
};

const styles = {
  loading: {
    backgroundColor: '#F3F3F3',
    padding: '30px 20px',
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    backgroundColor: '#F3F3F3',
    padding: '30px 20px',
    display: 'flex',
    justifyContent: 'center',
    color: '#FF6666',
  },
};

export default withStyles(styles)(DeferredConversationList);
