import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connectField, joinName } from 'uniforms';

/* eslint-disable import/no-cycle */
import AutoField from 'src/components/forms/fields/autoField';
import { styles } from 'src/components/forms/fields/nestField';

/* eslint-enable */

class ExplicitEmptyField extends React.Component {
  constructor(props) {
    super(props);
    this.isFieldDisabled = this.isFieldDisabled.bind(this);
  }

  hasPopulatedFields() {
    const {
      value,
      field: { explicitEmptyField },
    } = this.props;

    return Object.keys(value)
      .filter(field => field !== explicitEmptyField)
      .some(
        field =>
          (Array.isArray(value[field]) && value[field].length > 0) ||
          (!Array.isArray(value[field]) && !value[field]),
      );
  }

  isFieldDisabled(key) {
    const {
      value,
      field: { explicitEmptyField },
    } = this.props;

    // If there is no value, then do not disable any fields.
    if (!value || typeof value !== 'object') return false;
    if (key === explicitEmptyField) {
      // Since the field we're looking at is the field that dictates whether the rest of the fields
      // are explicitly empty, we need to check all other fields for values, and if any are set then
      // this field should be disabled otherwise there could be a mutual exclusivity violation.
      return !value[explicitEmptyField] && this.hasPopulatedFields();
    } else {
      // If the exlicitEmptyField is not set then all other fields should be enabled. Even though
      // it shouldn't be possible to have the explicit empty field be set to true and have a value
      // in any of the other fields, it's worth checking for that case and allowing the populated
      // field to stay editable in that scenario.
      return !!value[explicitEmptyField] && !this.hasPopulatedFields();
    }
  }

  render() {
    const { fields, label, classes, itemProps, name } = this.props;
    return (
      <div className={classes.nestedFieldContainer}>
        {label && <label className={classes.nestedLabel}>{label}</label>}
        <div className={classes.nestedFields}>
          {fields.map(key => {
            return (
              <AutoField
                key={key}
                name={joinName(name, key)}
                disabled={this.isFieldDisabled(key)}
                {...itemProps}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  connectField(ExplicitEmptyField, {
    ensureValue: false,
    includeInChain: false,
  }),
);
