export default {
  type: 'object',
  title: 'General Visit',
  properties: {
    visitLength: {
      type: 'integer',
      min: 0,
      title: 'Visit Length (minutes)',
    },
    notes: {
      type: 'string',
      title: 'Notes',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
