import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import { inject, observer } from 'mobx-react';
import React from 'react';

// The default behavior when rendering the result of the file upload is to render nothing. This can
// be overridden by passing in a custom rendering component via the renderComponent prop.
const RenderComponent = () => <></>;

class File extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: 0,
      uploading: false,
    };
  }

  handleSelectFile = async event => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ uploading: true });
      const upload = await this.props.rootStore.uploadFile(event.target.files[0], progress => {
        this.setState({ uploadProgress: progress.loaded / progress.total });
      });
      this.handleChange(upload);
      this.setState({ uploading: false, uploadProgress: 0 });
    }
  };

  handleCancel = () => {
    this.setState({ uploading: false, uploadProgress: 0 });
    this.props.rootStore.cancelUpload();
  };

  handleChange = value => {
    this.props.form.setFieldValue(this.props.field.name, value);
    // We never really focus, so mark touched immediately on change
    this.props.form.setFieldTouched(this.props.field.name);
  };

  render() {
    const {
      field: { value, name, onBlur },
      form: { touched, errors },
      classes,
      label,
      accept,
      renderComponent: Renderer = RenderComponent,
    } = this.props;
    const { uploading, uploadProgress } = this.state;
    const errorMessage = touched[name] && errors[name];
    const fieldId = `file-selector-${name}`;
    return (
      <FormControl fullWidth error={errors[name] && touched[name]}>
        {uploading && (
          <div className={classes.progressbar} style={{ width: `${uploadProgress * 100}%` }} />
        )}
        <InputLabel>{label}</InputLabel>
        <Input
          readOnly
          type="text"
          name={name}
          value={value ? value.filename : ''}
          onBlur={onBlur}
          endAdornment={
            <InputAdornment position="end">
              {uploading ? (
                <Button component="span" size="small" onClick={this.handleCancel} disabled>
                  Cancel
                </Button>
              ) : (
                <span style={{ display: 'inline-flex' }}>
                  {value && (
                    <CloseIcon
                      fontSize="small"
                      className={classes.miniIconButton}
                      onClick={() => this.handleChange(null)}
                    />
                  )}
                  <label htmlFor={fieldId}>
                    <FileIcon fontSize="small" className={classes.miniIconButton} />
                  </label>
                </span>
              )}
            </InputAdornment>
          }
        />
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        <input
          style={{ display: 'none' }}
          accept={accept}
          className={classes.input}
          id={fieldId}
          type="file"
          onChange={this.handleSelectFile}
        />
        {Renderer && <Renderer file={value} />}
      </FormControl>
    );
  }
}

const styles = theme => ({
  progressbar: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.3,
  },
  miniIconButton: {
    cursor: 'pointer',
    color: 'hsl(0,0%,80%)',
    '&:hover': {
      color: 'hsl(0,0%,60%)',
    },
    padding: 5,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(File)));
