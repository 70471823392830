import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ZendeskTabIcon = ({ className }: { className: string }) => {
  return (
    <SvgIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7027 8.25024V21.2069H4.02271L11.7027 8.25024Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7027 4.52515C11.7027 7.48909 9.98354 9.89157 7.86268 9.89157C5.74181 9.89157 4.02271 7.48909 4.02271 4.52515H11.7027Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5203 21.0287C12.5203 18.0644 14.2396 15.6616 16.3606 15.6616C18.4818 15.6616 20.2003 18.0644 20.2003 21.0287H12.5203Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5205 17.4844V4.52515H20.2005L12.5205 17.4844Z"
      />
    </SvgIcon>
  );
};

export default ZendeskTabIcon;
