import v120Schema from 'src/components/forms/schemas/eventResult/peerCoachTelehealthPrecheck/1.2.0';

const { ...v120Properties } = v120Schema.properties;

export default {
  ...v120Schema,
  properties: {
    confirmedPatientIdentity: {
      title: 'Confirmed Patient Identity',
      type: 'boolean',
      tooltip: '"Can you please confirm your name and date of birth?"',
    },
    ...v120Properties,
  },
  required: ['currentLocation'],
};
