import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { BoulderColors } from 'src/util/brand';

const useInformationRowStyles = makeStyles({
  informationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'flex-start',

    '& .link': {
      textDecoration: 'underline',
      cursor: 'pointer',
      float: 'right',
      color: BoulderColors.Blue4,
    },

    '& .value': {
      '& ul': {
        margin: 0,
        paddingLeft: '1em',
      },

      '& .label': {
        fontWeight: 700,
        '&::after': {
          content: '" "',
        },
      },
    },

    '& .collapsed': {
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },

    '& .rowLabel': {
      // These labels should all be a fixed size, which is
      // the width of the widest label, plus the icon width
      // (16px) and the padding on the left and right of the
      // text (8px and 16px).
      flexBasis: 155 + 8 + 16 + 16,
      flexGrow: 0,
      flexShrink: 0,
      fontSize: 10,
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: 1.2,
      marginRight: 16,
      color: BoulderColors.Gray6,
      lineHeight: '100%',

      display: 'flex',
      alignItems: 'center',

      '& svg': {
        width: 18,
        height: 18,
        marginRight: 8,
      },
    },
  },
});

type InformationRowProps = {
  icon: React.ReactNode;
  label: string;
  value?: string | React.ReactNode;
};

export const InformationRow = ({ icon, label, value }: InformationRowProps) => {
  const classes = useInformationRowStyles();

  const ref = React.createRef<HTMLDivElement>();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [showLink, setShowLink] = React.useState(false);

  React.useLayoutEffect(() => {
    if (ref.current && ref.current.offsetHeight < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref]);

  const onViewMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classes.informationRow}>
      <div className="rowLabel">
        {icon}
        {label}
      </div>
      <div>
        <div ref={ref} className={isExpanded ? 'value' : 'collapsed value'}>
          {value ?? '--'}
        </div>
        {showLink && (
          <div className="link" onClick={onViewMore}>
            {isExpanded ? 'Show less' : 'Show more'}
          </div>
        )}
      </div>
    </div>
  );
};
