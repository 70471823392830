import gql from 'graphql-tag';

import { Interaction } from 'src/nightingale/types/types';

export const REFRESH_PATIENT_SNAPSHOT_TIME = gql`
  mutation RefreshPatientSnapshotTime($interactionReferenceId: String!) {
    refreshPatientSnapshotTime(interactionReferenceId: $interactionReferenceId) {
      id
    }
  }
`;

export type QueryResult = {
  refreshPatientSnapshotTime: Interaction;
};
