import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import isNil from 'lodash/isNil';
import React from 'react';

import { colors } from 'src/util/colors';

const ChipItem = ({
  classes,
  itemValue,
  filterKey,
  removeLocalFilter,
  setShowQuickFilter,
  value,
}) => {
  return (
    <span key={filterKey} className={classes.summaryItemValue}>
      {itemValue}
      <IconButton
        className={classes.removeFilterbutton}
        size="small"
        color="inherit"
        onClick={evt => {
          // Required to prevent the accordion from expanding/collapsing
          evt.stopPropagation();
          removeLocalFilter(filterKey, value);
          setShowQuickFilter(true);
        }}
        key="close"
      >
        <CloseIcon />
      </IconButton>
    </span>
  );
};

const FilterSummary = ({
  filterFields,
  localValues = {},
  hasUnappliedChanges,
  onFilter,
  removeLocalFilter,
  setShowQuickFilter,
  showQuickFilter,
}) => {
  const selectedLocalFilters = filterFields.filter(
    filterField => !isNil(localValues[filterField.key]),
  );
  const classes = useFilterSummaryStyles();

  // Both of these need to stop the event from propagating, or else
  // the Accordion will expand/collapse unexpectedly.
  const quickFilterClick = evt => {
    evt.stopPropagation();
    if (hasUnappliedChanges) {
      onFilter(localValues);
    }
  };
  const quickFilterFocus = evt => evt.stopPropagation();

  return (
    <div className={classes.summaryContainer} data-testid="filter-summary-container">
      <Typography>
        {selectedLocalFilters.length === 0
          ? 'No filters selected'
          : selectedLocalFilters.map(filterField => {
              const { key } = filterField;
              const value = localValues[key];
              if (!value || value.length === 0) return null;

              if (Array.isArray(value)) {
                const values = value;
                return (
                  <span key={key} className={classes.summaryItem}>
                    <>{`${filterField.props.label}: `}</>
                    {values.map(val => {
                      const itemValue = val.label || val.name || `${val.firstName} ${val.lastName}`;
                      return (
                        <ChipItem
                          classes={classes}
                          itemValue={itemValue}
                          filterKey={`${key}-${itemValue}`}
                          key={`${key}-${itemValue}`}
                          removeLocalFilter={removeLocalFilter}
                          setShowQuickFilter={setShowQuickFilter}
                          value={val}
                        />
                      );
                    })}
                  </span>
                );
              } else {
                const itemValue =
                  value.label ||
                  filterField.labelFn?.(value) ||
                  (value.firstName || value.lastName
                    ? `${value.firstName} ${value.lastName}`
                    : value);
                return (
                  <span key={key} className={classes.summaryItem}>
                    <>{`${filterField.props.label}: `}</>
                    <ChipItem
                      classes={classes}
                      itemValue={itemValue}
                      filterKey={key}
                      removeLocalFilter={removeLocalFilter}
                      setShowQuickFilter={setShowQuickFilter}
                      value={value}
                    />
                  </span>
                );
              }
            })}
        {hasUnappliedChanges && (
          <b className={classes.unappliedWarning}>(filters not yet applied)</b>
        )}
      </Typography>
      {showQuickFilter && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.quickFilterButton}
          onClick={quickFilterClick}
          onFocus={quickFilterFocus}
        >
          Filter
        </Button>
      )}
    </div>
  );
};

const useFilterSummaryStyles = makeStyles({
  summaryContainer: {
    // Pushes the quick filter button to the far right
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  summaryItem: {
    display: 'inline-block',
    padding: '5px 8px 5px 0px',

    '&:last-of-type': {
      // unappliedWarning class will account for it's own padding
      paddingRight: 0,
    },
  },
  summaryItemValue: {
    // Imitates the same styling used in the multiselect attendee control
    background: '#E0E0E0',
    borderRadius: 16,
    // Account for the padding included by IconButton
    padding: '5px 5px 5px 12px',
    marginRight: 3,
  },
  quickFilterButton: {
    height: 30,
    alignSelf: 'center',
  },
  removeFilterbutton: {
    // Vertically centers with the text label
    paddingBottom: 5,
  },
  unappliedWarning: {
    color: colors.warningHover,
    paddingLeft: 8,
  },
});

export default FilterSummary;
