import { Theme, makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isNil from 'lodash/isNil';
import React from 'react';

import type { HistoricalValue } from 'src/nightingale/components/ChartPropertyHistory/types';
import type {
  AnyChartProperty,
  ObjectChartProperty,
  ListChartProperty,
} from 'src/nightingale/types/types';
import { ChartPropertyTypes, ChartPropertyAction } from 'src/nightingale/types/types';
import { TaggedTextValue } from 'src/util/taggedText';

export const ChartPropertyHistoryEntryValue: React.FC<{
  definition: AnyChartProperty;
  entry: HistoricalValue;
}> = ({ definition, entry }) => {
  const styles = useValueEntryStyles();
  const noValueText = (isEmpty: boolean) => (isEmpty ? 'Set as none' : 'No value');

  const propertyType = definition.type;
  if (!propertyType) {
    return null;
  }

  if (entry.action === ChartPropertyAction.Confirm) {
    return <span className={styles.descriptionText}>Confirmed previous value</span>;
  }

  if (
    ['', undefined, null].includes(entry.value) ||
    (propertyType === ChartPropertyTypes.TaggedText &&
      (isNil(entry.value?.text) || entry.value?.text === '')) ||
    (propertyType === ChartPropertyTypes.Object &&
      Object.values(entry.value).every(childValue => isNil(childValue))) ||
    (Array.isArray(entry.value) && entry.value.length === 0)
  ) {
    return <span className={styles.descriptionText}>{noValueText(entry.isEmpty)}</span>;
  }

  return (
    <ChartPropertyHistoryValueRenderer
      definition={definition}
      type={propertyType}
      value={entry.value}
    />
  );
};

const useValueEntryStyles = makeStyles<Theme>({
  descriptionText: {
    fontStyle: 'italic',
  },
});

const ChartPropertyHistoryValueRenderer: React.FC<{
  definition?: AnyChartProperty;
  type: ChartPropertyTypes;
  value: unknown;
  topLevel?: boolean;
}> = ({ definition, topLevel = true, type, value }) => {
  const styles = useStyles({ topLevel });
  switch (type) {
    case ChartPropertyTypes.Boolean:
      return <>{(value as boolean) ? 'Yes' : 'No'}</>;
    case ChartPropertyTypes.Date:
      return <>{format(parse(value as string, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')}</>;
    case ChartPropertyTypes.Email:
    case ChartPropertyTypes.Integer:
    case ChartPropertyTypes.LongText:
    case ChartPropertyTypes.Text:
      return <>{value as string}</>;
    case ChartPropertyTypes.TaggedText:
      return <>{(value as TaggedTextValue).text}</>;
    case ChartPropertyTypes.List: {
      const listValue = value as Array<Record<string, unknown>>;
      return listValue.length ? (
        <div className={styles.complexProperty}>
          <ul className={styles.listPropertyList}>
            {listValue.map((item, index) => {
              const key = definition
                ? `${(definition as ListChartProperty).properties?.[index]?.name}`
                : index.toString();
              return (
                <li key={key}>
                  <ChartPropertyHistoryValueRenderer
                    definition={definition}
                    topLevel={false}
                    type={ChartPropertyTypes.Object}
                    value={item}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <span className={styles.descriptionText}>Empty list.</span>
      );
    }
    case ChartPropertyTypes.Object: {
      if (!definition) return <>No definition provided.</>;
      const childNameOrder = (definition as ObjectChartProperty).properties.map(p => p.name);
      const objectValue = value as Record<string, unknown>;
      return (
        <div className={styles.complexProperty}>
          {childNameOrder.map(childPropertyName => {
            if (!(childPropertyName in objectValue)) {
              return null;
            }
            const childDefinition = (definition as ObjectChartProperty).properties.find(
              p => p.name === childPropertyName,
            );
            if (!childDefinition) return null;
            const childValue = objectValue[childPropertyName];
            return childValue ? (
              <div className={styles.keyValuePair} key={childPropertyName}>
                <span className={styles.key}>{childDefinition.label}</span>
                <br />
                <div className={styles.value}>
                  <ChartPropertyHistoryValueRenderer
                    type={childDefinition.type}
                    value={childValue}
                  />
                </div>
              </div>
            ) : null;
          })}
        </div>
      );
    }
    case ChartPropertyTypes.Select:
    case ChartPropertyTypes.SelectAsync: {
      const selectValue = value as { label: string; value: string };
      return <>{selectValue.label}</>;
    }
    case ChartPropertyTypes.CheckboxList:
    case ChartPropertyTypes.SelectMulti:
    case ChartPropertyTypes.SelectMultiAsync: {
      const selectValues = value as Array<{ label: string; value: string }>;
      return <>{selectValues.map(option => option.label).join(', ')}</>;
    }
    case ChartPropertyTypes.Phone: {
      const phone = value as string;
      return (
        <>
          {phone.length === 10
            ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
            : phone}
        </>
      );
    }
    default:
      return <>{JSON.stringify(value)}</>;
  }
};

const useStyles = makeStyles<Theme, { topLevel?: boolean }>(theme => ({
  complexProperty: {
    backgroundColor: ({ topLevel }) => (topLevel ? 'rgba(0, 0, 0, 0.05)' : 'transparent'),
    padding: '8px',
  },
  keyValuePair: {
    marginBottom: '2pt',
  },
  key: {
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    fontSize: '10px',
    letterSpacing: '.12em',
    textTransform: 'uppercase',
    color: '#7E848A',
  },
  value: {
    fontFamily: 'Nunito Sans',
  },
  descriptionText: {
    fontStyle: 'italic',
  },
  listPropertyList: {
    paddingLeft: theme.spacing(3),
    listStyleType: 'disc',
  },
}));
