import { makeStyles, IconButton, Input } from '@material-ui/core';
import classNames from 'classnames';
import range from 'lodash/range';
import React from 'react';

import Colors from 'src/nightingale/Colors';

interface LikertScaleInputProps {
  selectedNumber: number | null;
  selectNumber: (selection: number) => void;
  max?: number;
  minText?: string;
  maxText?: string;
}

export const LikertScaleInput: React.VFC<LikertScaleInputProps> = ({
  selectedNumber,
  selectNumber,
  max = 5,
  minText,
  maxText,
}) => {
  const styles = useStyles({ max });
  return (
    <span>
      <Input
        color="primary"
        disableUnderline
        fullWidth
        margin="dense"
        classes={{ root: styles.input }}
        inputComponent={InnerInput}
        inputProps={{
          styles,
          selectNumber,
          selectedNumber,
          max,
          minText,
          maxText,
        }}
      />
    </span>
  );
};

const InnerInput: React.FC<{
  selectNumber: (number: number) => void;
  selectedNumber: number | null;
  styles: ReturnType<typeof useStyles>;
  max: number;
  minText?: string;
  maxText?: string;
}> = ({ selectNumber, selectedNumber, styles, max, minText, maxText }) => {
  const scale = range(1, max + 1);

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <div style={{ gridRow: 1 }} />
        {scale.map(number => (
          <>
            <IconButton
              data-testid={`likert-scale-input-${number}`}
              onClick={() => selectNumber(number)}
              classes={{
                root:
                  selectedNumber === number
                    ? classNames(styles.button, styles.selectedButton)
                    : styles.button,
              }}
            >
              <div className={styles.buttonText}>{number}</div>
            </IconButton>
            {minText && number === scale[0] && <p className={styles.scaleText}>{minText}</p>}
            {maxText && number === scale.at(-1) && (
              <p className={classNames(styles.scaleText, styles.scaleTextEnd)}>{maxText}</p>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

interface StyleProps {
  max: number;
}
const useStyles = makeStyles({
  container: {
    marginTop: 5,
    alignItems: 'center',
  },
  gridContainer: {
    display: 'grid',
    // Create a grid with 2 * number of buttons + 2 columns, where each button takes up 2 columns
    // and each helper text takes up 4 columns. The additional 2 columns are for an additional column
    // at the start and an additional column at the end used to center helper text under the first and
    // last buttons
    gridTemplateColumns: ({ max }: StyleProps) => `repeat(${2 * max + 2}, 20px)`,
    justifyItems: 'center',
  },
  buttonText: {
    fontSize: 16,
    color: 'white',
  },
  button: {
    fontSize: 12,
    height: 34,
    width: 34,
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
    '&:focus': {
      outline: `2px solid ${Colors.Stillwater}`,
    },
    backgroundColor: Colors.Gray3,
    gridColumn: 'span 2',
    gridRow: 1,
  },
  scaleText: {
    gridRow: 2,
    gridColumn: 'span 4',
    fontSize: 10,
    textAlign: 'center',
    color: Colors.Gray6,
    textTransform: 'uppercase',
    margin: 0,
    lineHeight: 1.5,
    paddingTop: 4,
    letterSpacing: `.12em`,
  },
  // The helper text under the last button is centered within the last four columns of the grid.
  // You can style an element to be within the columns by using this `x / y` syntax where x represents
  // the leftmost border of first column and y represents the rightmost border of the last column.
  scaleTextEnd: {
    gridColumn: ({ max }: StyleProps) => {
      const lastColumnBorder = 2 * max + 3;
      return `${lastColumnBorder - 4} / ${lastColumnBorder}`;
    },
  },
  selectedButton: {
    backgroundColor: Colors.BlueSpruce,
  },
  input: {
    marginTop: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
