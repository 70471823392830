import useTypedSWR from 'src/components/general/useTypedSWR';
import { PATIENT_SHIPPING_ADDRESS_QUERY } from 'src/labs/useSWRPatientShippingAddress.gql';

export const useSWRPatientShippingAddress = (patientId: string) => {
  const { data, error } = useTypedSWR([PATIENT_SHIPPING_ADDRESS_QUERY, { patientId }]);

  return {
    data: data?.staff_getPatientShippingAddress ?? null,
    isLoading: !data && !error,
    error,
  };
};
