import { ConversationInstance } from 'src/stores/chat';
import { ConversationUser } from 'src/stores/chat/user';

type ChatNameFn = (user: typeof ConversationUser) => string;

export function getChatItemTitle(
  conversation: ConversationInstance,
  isOtherConversations: boolean,
  getNameWithPatients: ChatNameFn,
  getNameWithoutPatients: ChatNameFn,
) {
  let additionalProviders;
  let title = '';

  if (!conversation.id) {
    title = 'New chat with ';
  }

  if (conversation.hasPatients) {
    additionalProviders = conversation.otherProviderUsers;
    title +=
      isOtherConversations && additionalProviders?.length
        ? getNameWithPatients(additionalProviders[0].user)
        : getNameWithPatients(conversation.patient.user);

    if (additionalProviders.length > 0) {
      const numAdditionalProviders = isOtherConversations
        ? additionalProviders.length - 1
        : additionalProviders.length;
      if (numAdditionalProviders > 0) {
        title += ` (+${numAdditionalProviders})`;
      }
    }
  } else {
    title += conversation.otherUsers
      .map(otherUser => getNameWithoutPatients(otherUser.user))
      .join(' & ');
  }

  return title;
}
