import { makeStyles, Theme } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

type StyleProps = { searchOpen: boolean; optionsOpen: boolean };

export const useStyles = makeStyles<Theme, StyleProps>({
  wrapper: {
    position: `relative`,
  },

  cta: {
    display: ({ searchOpen }) => (searchOpen ? 'none' : 'flex'),
    justifyContent: `center`,
    overflow: `hidden`,
    padding: 0,
    position: `relative`,
  },

  button: {
    alignItems: `center`,
    background: Colors.Stillwater,
    border: `none`,
    borderRadius: `100%`,
    color: Colors.White,
    cursor: `pointer`,
    display: `flex`,
    height: 18,
    justifyContent: `center`,
    overflow: `hidden`,
    padding: 0,
    position: `relative`,
    textIndent: `100%`,
    whiteSpace: `nowrap`,
    width: 18,

    '&::before, &::after': {
      content: `''`,
      display: `block`,
      position: `absolute`,
      transition: `0.1s`,
      backgroundColor: Colors.ChartGray,
      height: 2,
      right: 5,
      top: `50%`,
      transform: `translateY(-50%)`,
      width: 8,
    },

    '&::after': {
      transform: `translateY(-50%) rotateZ(90deg)`,
    },
  },

  dropdown: {
    border: `none`,
    margin: 0,
    opacity: ({ searchOpen }) => (searchOpen ? 1 : 0),
    padding: 0,
    position: ({ searchOpen }) => (searchOpen ? 'static' : 'absolute'),
    top: `100%`,
  },

  inputContainer: {
    backgroundColor: Colors.BlueSpruce,
    border: `none`,
    boxSizing: `border-box`,
    color: Colors.White,
    fontFamily: `inherit`,
    fontSize: 16,
    fontWeight: 300,
    height: 45,
    margin: 0,
    opacity: ({ searchOpen }) => (searchOpen ? 1 : 0),
    overflow: `hidden`,
    padding: 0,
    paddingLeft: 17,
    paddingRight: 17,
    transition: `0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000)`,
    width: `100%`,
  },

  inputRoot: {
    color: Colors.White,
    fontFamily: `Nunito Sans`,
    fontSize: 16,
    fontWeight: 400,
    height: `100%`,
    lineHeight: `145%`,
    paddingTop: 2,

    '& ::placeholder': {
      color: Colors.White,
      fontStyle: `italic`,
    },
  },

  inputUnderline: {
    '&.MuiInput-underline:before': {
      border: `none`,
    },
    '&.MuiInput-underline:after': {
      border: `none`,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: `none`,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):after': {
      border: `none`,
    },
  },

  optionList: {
    boxSizing: `border-box`,
    backgroundColor: `#FFF`,
    boxShadow: `0 1px 3px rgba(0, 0, 0, 0.07)`,
    listStyleType: `none`,
    margin: 0,
    maxHeight: 300,
    opacity: ({ optionsOpen }) => (optionsOpen ? 1 : 0),
    overflow: `auto`,
    padding: 0,
    transition: `0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000)`,
    width: `100%`,
    zIndex: 2,
  },

  optionItem: {
    alignItems: `center`,
    boxSizing: `border-box`,
    color: Colors.Black,
    cursor: `pointer`,
    display: `flex`,
    fontFamily: `Nunito Sans`,
    fontSize: 16,
    fontWeight: 400,
    height: 45,
    justifyContent: `flex-start`,
    padding: `0 16px`,
    width: `100%`,

    '&:hover': {
      backgroundColor: Colors.Gray1,
    },
  },

  mark: {
    backgroundColor: `transparent`,
    textDecoration: `underline`,
  },
});
