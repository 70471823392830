import { multilineStringDef } from 'src/components/forms/schemas/util';

export default {
  type: 'object',
  title: 'Case Manager: Note',
  properties: {
    chiefComplaint: {
      ...multilineStringDef,
    },
    patientGoals: {
      ...multilineStringDef,
    },
    patientSymptoms: {
      ...multilineStringDef,
    },
    patientPresentation: {
      ...multilineStringDef,
      title: 'Patient presentation (verbal and nonverbal)',
    },
    intervention: {
      ...multilineStringDef,
    },
    referrals: {
      ...multilineStringDef,
      title: 'Referral(s)',
    },
    notes: {
      ...multilineStringDef,
    },
  },
  required: [],
};
