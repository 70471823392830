import Typography from '@material-ui/core/Typography';
import { format, parseISO, add } from 'date-fns';
import React from 'react';

const notApplicable = {
  type: 'object',
  title: 'N/A',
  properties: {
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
};

const reviewedWithNotes = {
  type: 'object',
  title: 'Reviewed',
  properties: {
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
};

const ReviewedWithNotes = ({ model, classes }) => (
  <>
    {model.selectionKey === 'reviewed' || model.selectionKey === 'reviewedWithNotes'
      ? 'Reviewed'
      : 'Not Applicable'}
    {model.additional?.notes && (
      <Typography variant="body2" className={classes.multiLineText}>
        {model.additional.notes}
      </Typography>
    )}
  </>
);

export default {
  type: 'object',
  title: 'Prescriber: Visit Prep',
  properties: {
    previousVisitsAndRecords: {
      type: 'object',
      title: 'Previous Visits and Records',
      format: 'selectable',
      renderer: {
        component: ({ model, classes }) => (
          <ReviewedWithNotes model={model.previousVisitsAndRecords} classes={classes} />
        ),
      },
      properties: {
        reviewedWithNotes,
        notApplicable,
      },
    },
    pdmp: {
      type: 'object',
      title: 'PDMP',
      format: 'selectable',
      renderer: {
        component: ({ model, classes }) => (
          <>
            {model.pdmp.selectionKey === 'reviewed' && (
              <>
                Reviewed
                {model.pdmp.additional && (
                  <Typography variant="body2" className={classes.itemDetails}>
                    {model.pdmp.additional.lastFill && (
                      <span>
                        Last Fill: {format(parseISO(model.pdmp.additional.lastFill), 'PP')}
                      </span>
                    )}
                    {typeof model.pdmp.additional.daysFilled === 'number' && (
                      <span>Days Filled: {model.pdmp.additional.daysFilled}</span>
                    )}
                    {model.pdmp.additional.lastFill &&
                      typeof model.pdmp.additional.daysFilled === 'number' && (
                        <span>
                          Next Due:{' '}
                          {format(
                            add(parseISO(model.pdmp.additional.lastFill), {
                              days: model.pdmp.additional.daysFilled,
                            }),
                            'PP',
                          )}
                        </span>
                      )}
                    {model.pdmp.additional.notes && (
                      <span className={classes.multiLineText}>{model.pdmp.additional.notes}</span>
                    )}
                  </Typography>
                )}
              </>
            )}
            {model.pdmp.selectionKey === 'notApplicable' && (
              <>
                Not Applicable
                {model.pdmp.additional?.notes && (
                  <Typography variant="body2" className={classes.multiLineText}>
                    {model.pdmp.additional.notes}
                  </Typography>
                )}
              </>
            )}
          </>
        ),
      },
      properties: {
        reviewed: {
          type: 'object',
          properties: {
            lastFill: {
              type: 'string',
              title: 'Last Fill',
              format: 'date',
            },
            daysFilled: {
              type: 'integer',
              title: 'Days Filled',
              min: 0,
              uniforms: {
                fullWidth: true,
                margin: 'dense',
              },
            },
            notes: {
              type: 'string',
              uniforms: {
                multiline: true,
              },
            },
          },
        },
        notApplicable,
      },
    },
    osdt: {
      type: 'object',
      title: 'OFT',
      format: 'selectable',
      renderer: {
        component: ({ model, classes }) => (
          <ReviewedWithNotes model={model.osdt} classes={classes} />
        ),
      },
      properties: {
        reviewedWithNotes,
        notApplicable,
      },
    },
    labsAndImaging: {
      type: 'object',
      title: 'Labs and Imaging',
      format: 'selectable',
      renderer: {
        component: ({ model, classes }) => (
          <ReviewedWithNotes model={model.labsAndImaging} classes={classes} />
        ),
      },
      properties: {
        reviewedWithNotes,
        notApplicable,
      },
    },
    schedulingNotes: {
      type: 'string',
      title: 'Scheduling Notes',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
