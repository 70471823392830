import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default () => (
  <SvgIcon>
    <svg
      className="MuiSvgIcon-root"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2ZM13 3.5V9H18.5L13 3.5ZM13.0001 16.5H11.0001V18.5H13.0001V16.5ZM11.0001 10.5H13.0001V15.5H11.0001V10.5Z"
      />
    </svg>
  </SvgIcon>
);
