import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type ApolloClient from 'apollo-client';
import { Subscription } from 'apollo-client/util/Observable';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';
import {
  DoseSpotCurrentNotificationCountQuery,
  DoseSpotNotificationCountSubscriptionSubscription,
} from 'src/generated/gql/graphql';
import { DoseSpotNotificationsContext } from 'src/prescribing/doseSpot/components/DoseSpotNotificationsContext';
import {
  SUBSCRIBE_TO_DOSE_SPOT_NOTIFICATION_COUNT,
  DOSE_SPOT_CURRENT_NOTIFICATION_COUNT,
} from 'src/prescribing/doseSpot/queries.gql';
import logger from 'src/shared/util/logger';

export const DoseSpotNotificationsProvider: React.FC = ({ children }) => {
  const [count, setCount] = useState(0);
  const subscription = useRef<Subscription>();

  const { apolloClient } = useContext(ApolloClientContext);

  useEffect(() => {
    if (!apolloClient) return;
    const server = new ServerHelper(apolloClient);

    server
      .fetchCount()
      .then(c => setCount(c))
      .catch(err => console.error(err));
  }, [apolloClient]);

  useEffect(() => {
    if (!apolloClient) return;
    const server = new ServerHelper(apolloClient);

    subscription.current = server.subscribeToCountsTotal(total => setCount(total));

    // Only need to destruct if we subscribe
    // eslint-disable-next-line consistent-return
    return () => {
      subscription?.current?.unsubscribe();
    };
  }, [apolloClient]);

  return (
    <DoseSpotNotificationsContext.Provider value={{ count }}>
      {children}
    </DoseSpotNotificationsContext.Provider>
  );
};

/**
 * Encapsulates server interactions in order to keep the component clean and tidy
 */
class ServerHelper {
  constructor(private graphql: ApolloClient<NormalizedCacheObject>) {}

  /**
   * Fetches the sum of the notification counts from DoseSpot
   */
  async fetchCount(): Promise<number> {
    const { data } = await this.graphql.query<DoseSpotCurrentNotificationCountQuery>({
      query: DOSE_SPOT_CURRENT_NOTIFICATION_COUNT,
    });

    return data.getCurrentNotificationCount;
  }

  subscribeToCountsTotal(listener: (count: number) => void) {
    return this.graphql
      ?.subscribe<DoseSpotNotificationCountSubscriptionSubscription>({
        query: SUBSCRIBE_TO_DOSE_SPOT_NOTIFICATION_COUNT,
      })
      .subscribe({
        next({ data }) {
          if (!data) return;

          listener(data.doseSpotNotificationCount.count);
        },
        error(err) {
          logger.error('Received error getting DoseSpot notification count', err);
        },
      });
  }
}
