export const MOTIVATIONAL_PATIENT_QUOTES: Array<{ quote: string; author?: string }> = [
  { quote: "I don't plan on going anywhere no time soon because I've found my home." },
  {
    quote: "It's only because of people like you that I even have a partial grip back on my life.",
  },
  { quote: 'Your staff is so much better than ANYWHERE I have ever been.' },
  { quote: "That's why I picked Boulder, I don't want to choose between my job and treatment" },
  {
    quote:
      "I'm so thankful for Boulder. I can say 100% that I continue to stay sober because of their support and help.",
  },
  { quote: "Everyone I've met here has been so wonderful!" },
  { quote: "I think I'm healing." },
  { quote: "I would not have gotten help if you [all] weren't here" },
  {
    quote:
      "You're giving people the opportunity to take their lives back. You people are AMAZING! Thank you for saving my life!!",
  },
  {
    quote:
      'You guys are awesome! This program has changed my life! I started a new job this week and things just keep getting better!',
  },
];
