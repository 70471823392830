import { Instance, flow, getEnv, types } from 'mobx-state-tree';

import createListModel from 'src/shared/stores/createListModel';
import { DateOnlyType, DateType } from 'src/shared/stores/resource';
import { REGENERATE_CLAIM, SUBMIT_CLAIM, TRANSLATE_CLAIM } from 'src/stores/mutations/claims';
import { LOAD_CLAIM_ROWS } from 'src/stores/queries/claims';
import { transformClaimForMaterialTable } from 'src/util/claims';

const ClaimAddress = types.model({
  streetLine1: types.maybeNull(types.string),
  streetLine2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  zip: types.maybeNull(types.string),
});

const BillingProvider = types.model({
  address: types.maybeNull(ClaimAddress),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  middleName: types.maybeNull(types.string),

  /**
   * National Provider Identifier administrative standard from HIPAA
   *
   * @see https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand
   */
  npi: types.maybeNull(types.string),

  signatureOnFile: types.maybeNull(types.string),
  taxId: types.maybeNull(types.string),
});

const PatientInformation = types.model({
  address: types.maybeNull(ClaimAddress),
  controlNumber: types.maybeNull(types.string),
  dateOfBirth: types.maybeNull(DateOnlyType),
  firstName: types.maybeNull(types.string),
  isDeceased: types.maybeNull(types.boolean),
  lastName: types.maybeNull(types.string),
  relationshipToSubscriberCode: types.maybeNull(types.string),
  sex: types.maybeNull(types.string),
});

const PayerInformation = types.model({
  address: types.maybeNull(ClaimAddress),
  groupNumber: types.maybeNull(types.string),
});

const Payor = types.model({
  key: types.maybeNull(types.integer),
  name: types.maybeNull(types.string),
});

const RenderingProviderInformation = types.model({
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  middleName: types.maybeNull(types.string),
  npi: types.maybeNull(types.string),
  taxId: types.maybeNull(types.string),
});

const Section24 = types.model({
  datesOfService: types.maybeNull(DateType),
});

const Meta = types.model({
  status: types.string,
  type: types.string,
});

const SubscriberInformation = types.model({
  address: types.maybeNull(ClaimAddress),
  dateOfBirth: types.maybeNull(DateOnlyType),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  sex: types.maybeNull(types.string),
  subscriberId: types.maybeNull(types.string),
});

const FieldError = types.model({
  fieldDescription: types.maybeNull(types.string),
  errorMessage: types.maybeNull(types.string),
});

const CopyableJson = types.model({
  json: types.string,
  errors: types.optional(types.array(FieldError), []),
});

export const Claim = types.model('Claim', {
  billingProvider: types.maybeNull(BillingProvider),
  changeControlNumber: types.maybeNull(types.string),
  chargeAmount: types.maybeNull(types.integer),
  copyableJson: CopyableJson,
  cptCodes: types.optional(types.array(types.string), []),
  eventId: types.maybeNull(types.string),
  eventSubType: types.maybeNull(types.string),
  filingCode: types.maybeNull(types.string),
  hasReleaseOfInformation: types.maybeNull(types.boolean),
  icd10Codes: types.optional(types.array(types.string), []),
  id: types.string,
  localDate: types.maybeNull(DateOnlyType),
  meta: Meta,
  patientId: types.string,
  patientInformation: types.maybeNull(PatientInformation),
  payerInformation: types.maybeNull(PayerInformation),
  payor: types.maybeNull(Payor),
  renderingProviderInformation: types.maybeNull(RenderingProviderInformation),
  section24: types.maybeNull(Section24),
  subscriberInformation: types.maybeNull(SubscriberInformation),
  timezone: types.maybeNull(types.string),
});

const ClaimsList = createListModel('ClaimsList', Claim, LOAD_CLAIM_ROWS).views(self => ({
  get materialTableDataFormat() {
    return self.items.map(transformClaimForMaterialTable);
  },
}));

const ClaimsStore = types
  .model('Claims', {
    list: types.optional(ClaimsList, {}),
  })
  .volatile<{
    apiErrors: { [key: string]: ApiError[] };
  }>(() => ({
    /**
     * Stores errors for validation and submission requests we get back from the server. Keyed by
     * claim ID.
     */
    apiErrors: {},
  }))
  .actions(self => {
    return {
      regenerateClaimById: flow(function* regenerateClaimById(claimId) {
        yield getEnv(self).apolloClient.mutate({
          mutation: REGENERATE_CLAIM,
          variables: { claimId },
        });

        // Refresh the list?
        self.list.load();
      }),
      submitClaim: flow(function* submitClaim(claimId, claimData) {
        // remove previous errors so they're not stale and misleading
        self.apiErrors = {
          ...self.apiErrors,
          [claimId]: undefined,
        };

        let claimErrors;
        try {
          const response = yield getEnv(self).apolloClient.mutate({
            mutation: SUBMIT_CLAIM,
            variables: { claimId, claimData },
          });

          const results = response.data.submitClaim;
          claimErrors = [];

          console.info('Claim submission successful:', results);
        } catch (error) {
          if (
            !error.graphQLErrors ||
            error.graphQLErrors.some(e => !Array.isArray(e.candidApiErrors))
          ) {
            throw error;
          }

          claimErrors = error.graphQLErrors.flatMap(e => e.candidApiErrors);

          console.info('Claim submission returned errors:', claimErrors);
        }

        self.apiErrors = {
          ...self.apiErrors,
          [claimId]: claimErrors,
        };
      }),
      translateClaim: flow(function* translateClaim(claimId) {
        yield getEnv(self).apolloClient.mutate({
          mutation: TRANSLATE_CLAIM,
          variables: { claimId },
        });
        self.list.load();
      }),
    };
  });

export type ClaimInstance = Instance<typeof Claim>;

export type ClaimsStoreInstance = Instance<typeof ClaimsStore>;

export type ApiError = {
  description: string;
  field: string;
};

export default ClaimsStore;
