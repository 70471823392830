import { FormControl, FormHelperText, FormLabel, withStyles } from '@material-ui/core/';
import classNames from 'classnames';
import { Editor, EditorState } from 'draft-js';
import Autocomplete from 'draft-js-autocomplete';
import 'draft-js/dist/Draft.css';
import React from 'react';
import { connectField } from 'uniforms';

import InsertedTag from 'src/components/forms/fields/richTextField/InsertedTag';
import SuggestionList from 'src/components/forms/fields/richTextField/SuggestionList';
import SuggestionListItem from 'src/components/forms/fields/richTextField/SuggestionListItem';

const HASH_TAG_PREFIX_DEFAULT = '#';

function getExistingTags(contentState) {
  const existing = new Set();
  contentState.getBlockMap().forEach(block => {
    block.findEntityRanges(
      character => character.getEntity() !== null,
      start => {
        const entityKey = block.getEntityAt(start);
        const entity = contentState.getEntity(entityKey);
        if (entity.getType() === 'TAG') {
          existing.add(entity.getData().option);
        }
      },
    );
  });
  return existing;
}

class RichTextField extends React.Component {
  handleChange = editorState => {
    this.props.onChange(editorState);
  };

  getHashTagAutocomplete = editorState => {
    const { hashTagPrefix = HASH_TAG_PREFIX_DEFAULT, hashTags } = this.props;
    return {
      prefix: hashTagPrefix,
      type: 'TAG',
      mutability: 'IMMUTABLE',
      onMatch: text => {
        const existingTags = getExistingTags(editorState.getCurrentContent());
        let tagChoices = hashTags.filter(option => !existingTags.has(option));

        if (text) {
          const lcText = text.toLowerCase();
          tagChoices = tagChoices.filter(option => {
            const lcOption = option.toLowerCase();
            let startIndex = 0;
            let index = lcOption.indexOf(lcText);
            while (index !== -1) {
              if (index === 0 || option.charAt(index - 1) === ' ') {
                return true;
              }
              startIndex += lcText.length;
              index = lcOption.indexOf(lcText, startIndex);
            }
            return false;
          });
        }

        return tagChoices.map(option => ({
          option,
          matched: text,
        }));
      },
      component: InsertedTag,
      listComponent: SuggestionList,
      itemComponent: SuggestionListItem,
      format: item => `${item.option}`,
    };
  };

  render() {
    const {
      classes,
      label,
      error,
      errorMessage,
      required,
      value,
      fullWidth = true,
      hashTagPrefix = HASH_TAG_PREFIX_DEFAULT,
    } = this.props;
    const editorState = value instanceof EditorState ? value : null;
    const hashTagAutocomplete = this.getHashTagAutocomplete(editorState);

    return editorState ? (
      <FormControl fullWidth={fullWidth} error={!!error} required={required}>
        <div className={classNames(classes.container, classes.underline)}>
          <FormLabel className={classes.label}>
            {`${label} `}
            <span className={classes.labelInstruction}>
              (type {hashTagPrefix} to access templates)
            </span>
          </FormLabel>
          <Autocomplete
            editorState={editorState}
            onChange={this.handleChange}
            autocompletes={[hashTagAutocomplete]}
          >
            <Editor className={classes.editor} />
          </Autocomplete>
        </div>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    ) : null;
  }
}

const styles = theme => ({
  container: {
    position: 'relative',
    paddingBottom: 7,
  },
  error: {
    color: theme.palette.error,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
    paddingBottom: 6,
  },
  labelInstruction: {
    textTransform: 'capitalize',
  },
  // See https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui/src/Input/Input.js#L34
  // for the source of these rules
  underline: {
    '& .DraftEditor-editorContainer': {
      marginTop: 7,
    },
    '&:after': {
      left: 0,
      bottom: 0,
      // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
      content: '""',
      position: 'absolute',
      right: 0,
      transform: 'scaleX(0)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
      pointerEvents: 'none', // Transparent to the hover style.
    },
    '&:focus-within:after': {
      transform: 'scaleX(1)',
    },
    '&$error:after': {
      borderBottomColor: theme.palette.error.main,
      transform: 'scaleX(1)', // error is always underlined in red
    },
    '&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      left: 0,
      bottom: 0,
      // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
      content: '"\\00a0"',
      position: 'absolute',
      right: 0,
      transition: theme.transitions.create('border-bottom-color', {
        duration: theme.transitions.duration.shorter,
      }),
      pointerEvents: 'none', // Transparent to the hover style.
    },
    '&:hover:not($disabled):before': {
      borderBottom: `2px solid ${theme.palette.text.primary}`,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
    },
    '&$disabled:before': {
      borderBottomStyle: 'dotted',
    },
  },
});

export default withStyles(styles)(connectField(RichTextField));
