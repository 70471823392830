import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  tooltip: {
    color: Colors.White,
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    fontSize: 8,
    lineHeight: '11.6px',
    padding: '3px 6px',
    background: 'rgba(70, 108, 138, 0.6)',
    borderRadius: '3px',
  },
  tooltipPlacementBottom: {
    margin: 0,
  },
  iconButton: {
    padding: 0,
    color: Colors.BlueSpruce,
    '& svg': {
      opacity: 0,
      transition: 'opacity 0.5s',
      transform: 'scale(0.90)',
    },
    '&:hover svg': {
      opacity: 1,
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
});

export const ChartElementEditIcon: React.FC = () => {
  const styles = useStyles();

  return (
    <Tooltip
      title="Edit"
      placement="bottom"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: styles.tooltip,
        tooltipPlacementBottom: styles.tooltipPlacementBottom,
      }}
    >
      <IconButton
        type="button"
        classes={{ root: styles.iconButton }}
        data-testid="chart-element-edit"
        tabIndex={-1}
      >
        <CreateIcon className="pencil-icon" />
      </IconButton>
    </Tooltip>
  );
};
