import { withStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import React, { FunctionComponent } from 'react';

import Address from 'src/components/general/address';
import { getAllSubTypes } from 'src/shared/util/events';
import { TableRowData } from 'src/util/claims';

type ClaimRowDetailProps = {
  rowData: TableRowData;
  classes: {
    heading: string;
    contents: string;
    contentLabel: string;
  };
};

// TODO: Fill in those "NEED TO ADD" gaps that are now available
const ClaimRowDetail: FunctionComponent<ClaimRowDetailProps> = ({ classes, rowData }) => {
  interface Section {
    heading: string;
    items: any[];
  }

  const sections: Section[] = [
    {
      heading: 'Claim Information',
      items: [
        {
          name: 'Visit Subtype',
          value: getAllSubTypes()[rowData.claim.eventSubType]
            ? getAllSubTypes()[rowData.claim.eventSubType].label
            : 'N/A',
        },
        { name: 'Control Number', value: rowData.claim.changeControlNumber },
        { name: 'ICD-10', value: rowData.claim.icd10Codes },
        {
          name: 'Total Charge Amount',
          value: `$${
            rowData.claim.chargeAmount ? (rowData.claim.chargeAmount / 100).toFixed(2) : '0.00'
          }`,
        },
        // Code is currently hard-coded to 1 in our translation
        // This will show 7 when it's a resubmitted claim (and we handle that flow here)
        { name: 'Claim Frequency', value: '1 (First claim)' },
      ],
    },
    {
      heading: 'Billing Provider',
      items: [
        { name: 'Org Name', value: 'Boulder Care Provider Group P.A.' },
        { name: 'Address 1', value: rowData.claim.billingProvider?.address?.streetLine1 },
        { name: 'Address 2', value: rowData.claim.billingProvider?.address?.streetLine2 },
        { name: 'City', value: rowData.claim.billingProvider?.address?.city },
        { name: 'State', value: rowData.claim.billingProvider?.address?.state },
        { name: 'Zip', value: rowData.claim.billingProvider?.address?.zip },
        { name: 'Signature on File', value: rowData.claim.billingProvider?.signatureOnFile },
        { name: 'NPI', value: rowData.claim.billingProvider?.npi },
        { name: 'Tax ID', value: rowData.claim.billingProvider?.taxId },
      ],
    },
    {
      heading: 'Payor Information',
      items: [
        { name: 'Payor', value: rowData.payor },
        { name: 'Group Number', value: rowData.claim.payerInformation?.groupNumber },
        {
          name: 'Address',
          value: rowData.claim.payerInformation?.address ? (
            <Address address={rowData.claim.payerInformation.address} displayInline />
          ) : null,
        },
      ],
    },
    {
      heading: 'Rendering Provider',
      items: [
        { name: 'First Name', value: rowData.claim.renderingProviderInformation?.firstName },
        { name: 'Middle Name', value: rowData.claim.renderingProviderInformation?.middleName },
        { name: 'Last Name', value: rowData.claim.renderingProviderInformation?.lastName },
        { name: 'NPI', value: rowData.claim.renderingProviderInformation?.npi },
        { name: 'Tax ID', value: rowData.claim.renderingProviderInformation?.taxId },
      ],
    },
    {
      heading: '1500 Form',
      items: [
        {
          name: 'Dates of Service',
          value: rowData.claim.section24?.datesOfService
            ? format(rowData.claim.section24.datesOfService, 'MMMM d, yyyy')
            : '',
        },
        { name: 'CPT Code', value: rowData.cptCodes?.join(', ') },
        {
          name: 'Charges',
          value: `$${
            rowData.claim.chargeAmount ? (rowData.claim.chargeAmount / 100).toFixed(2) : 0.0
          }`,
        },
      ],
    },
    {
      heading: 'Service Facility',
      items: [
        { name: 'Org Name', value: 'Boulder Care Provider Group P.A.' },
        { name: 'Address 1', value: '208 SW Harvey Milk St' },
        { name: 'Address 2', value: 'STE 200' },
        { name: 'City', value: 'Portland' },
        { name: 'State', value: 'OR' },
        { name: 'Zip', value: '97204-2611' },
        { name: 'NPI', value: '1649838590' },
      ],
    },
    {
      heading: 'Patient Information',
      items: [
        { name: 'Last Name', value: rowData.claim.patientInformation?.lastName },
        { name: 'First Name', value: rowData.claim.patientInformation?.firstName },
        {
          name: 'Address',
          value: rowData.claim.patientInformation?.address ? (
            <Address address={rowData.claim.patientInformation.address} displayInline />
          ) : null,
        },
        { name: 'Sex', value: rowData.claim.patientInformation?.sex },
        {
          name: 'Date of Birth',
          value: rowData.claim.patientInformation?.dateOfBirth
            ? format(parseISO(rowData.claim.patientInformation.dateOfBirth), 'P')
            : '',
        },
        {
          name: 'Relationship to Subscriber Code',
          value: rowData.claim.patientInformation?.relationshipToSubscriberCode,
        },
        { name: 'Patient Control #', value: rowData.claim.patientInformation?.controlNumber },
        {
          name: 'Release of Information',
          value: rowData.claim.hasReleaseOfInformation === true ? 'Yes' : 'No',
        },
      ],
    },
    {
      heading: 'Submitter and Receiver',
      items: [
        { name: 'Contact Name', value: 'NEED TO ADD' },
        { name: 'Boulder Phone Number', value: 'NEED TO ADD' },
        { name: 'Submitter Org Name', value: 'Boulder Care Provider Group P.A.' },
        { name: 'Submitter Name', value: 'NEED TO ADD' },
        { name: 'Submitter Phone Number', value: 'NEED TO ADD' },
        { name: 'Receiver Organization Name', value: 'NEED TO ADD' },
        { name: 'Trading Partner Service ID', value: 'NEED TO ADD' },
      ],
    },
    {
      heading: 'Subscriber Information',
      items: [
        { name: 'Last Name', value: rowData.claim.subscriberInformation?.lastName },
        { name: 'First Name', value: rowData.claim.subscriberInformation?.firstName },
        {
          name: 'Address',
          value: rowData.claim.subscriberInformation?.address ? (
            <Address address={rowData.claim.subscriberInformation.address} displayInline />
          ) : null,
        },
        { name: 'Subscriber ID', value: rowData.claim.subscriberInformation?.subscriberId },
        { name: 'Sex', value: rowData.claim.subscriberInformation?.sex },
        {
          name: 'Date of Birth',
          value: rowData.claim.subscriberInformation?.dateOfBirth
            ? format(parseISO(rowData.claim.subscriberInformation.dateOfBirth), 'P')
            : '',
        },
      ],
    },
    {
      heading: 'Misc',
      items: [{ name: 'Claim Filing Code', value: rowData.claim.filingCode }],
    },
  ];

  return (
    <>
      {sections.map(section => (
        <ClaimSection classes={classes} heading={section.heading} key={section.heading}>
          {section.items.map(item => (
            <ClaimItem classes={classes} {...item} key={item.name} />
          ))}
        </ClaimSection>
      ))}
    </>
  );
};

function ClaimSection({ classes, heading, children }: ClaimsSectionProps) {
  return (
    <div>
      <h3 className={classes.heading}>{heading}</h3>
      <dl className={classes.contents}>{children}</dl>
    </div>
  );
}

function ClaimItem({ classes, name, value }: ClaimItemProps) {
  return (
    <dt key={name}>
      <span className={classes.contentLabel}>{name}</span>: {value}
    </dt>
  );
}

type ClaimsSectionProps = React.PropsWithChildren<{
  heading: string;
  classes: {
    heading: string;
    contents: string;
  };
}>;

type ClaimItemProps = {
  name: string;
  value: string;
  classes: { contentLabel: string };
};

const styles = {
  heading: {
    marginBottom: 0,
  },
  contents: {
    marginTop: 0,
  },
  contentLabel: {
    color: '#666',
    fontWeight: 500,
  },
};

export default withStyles(styles)(ClaimRowDetail);
