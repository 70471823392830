import React from 'react';

import AutoForm from 'src/components/forms/autoForm';
import { nullToUndefined } from 'src/shared/util/graphqlBuilder';
import ValidationError from 'src/shared/util/validationErrors';

export default class ServerValidatedAutoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validationError: props.error,
    };

    this.handleSubmitFailure = this.handleSubmitFailure.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(key, value) {
    // Clear any server validation errors that exist for the changed field, and any identical server
    // validation errors that exist on other fields.
    // If the field is submitted again with a bad value then it will be revalidated through
    // the normal validation process.
    if (this.state.validationError?.details) {
      let errors = this.state.validationError.details;
      const clearedErrors = errors.map(err => err.path === key && err.message);
      errors = errors.filter(err => !clearedErrors.includes(err.message));
      if (errors.length === 0) {
        this.setState({ validationError: undefined });
      } else {
        this.setState({
          validationError: {
            details: errors,
          },
        });
      }
    }

    if (this.props.onChange) {
      this.props.onChange(key, value);
    }
  }

  handleSubmitFailure(error) {
    const validationError = { details: [] };
    // If the save failed because of a server error then the crudService will have thrown an
    // instance of ValidationError that needs to be translated into the below format which is
    // parsable by the JSONSchemaBridge. JSONSchemaBridge is ultimately what tells the form if a
    // field is in an error state or not via the getError method that it defines.
    if (error instanceof ValidationError && error.errors) {
      Object.values(error.errors).forEach(err => {
        const dataPaths = err.path.includes(',') ? err.path.split(',') : [err.path];
        dataPaths.forEach(dataPath => {
          validationError.details.push({
            path: dataPath,
            dataPath: `.${dataPath}`,
            keyword: err.kind,
            message: err.message,
            params: {},
          });
        });
      });
      this.setState({
        validationError,
      });
    }

    this.props.onSubmitFailure(error);
  }

  transformModel(mode, model) {
    // GraphQL returns fields without a value defined as 'null' ('undefined' is considered a field
    // error), but Uniforms considers 'null' to be a valid field value. Because of this difference
    // in definition it's necessary to do a translation of null -> undefined so that fields that
    // are empty don't throw an error.
    if (mode === 'form' || mode === 'validate') {
      return nullToUndefined(model);
    }
    return model;
  }

  render() {
    return (
      <AutoForm
        {...this.props}
        formRef={this.props.formRef}
        modelTransform={this.transformModel}
        error={this.state.validationError}
        onSubmitFailure={this.handleSubmitFailure}
        onChange={this.handleChange}
      />
    );
  }
}
