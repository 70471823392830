import React from 'react';

import { CheckboxListInput } from 'src/nightingale/components/ChartProperty/controls/CheckboxList/CheckboxListInput';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { ControlProps, CheckboxListChartProperty } from 'src/nightingale/types/types';

export type CheckboxListControlProps = ControlProps<CheckboxListChartProperty>;

/**
 * CheckboxList Control component
 */
export const CheckboxListControl: React.FC<CheckboxListControlProps> = ({
  hasEmptyValue,
  isRequired,
  label,
  value,
  ...rest
}) => (
  <LabeledFormControl hasEmptyValue={hasEmptyValue} isRequired={isRequired} label={label}>
    <CheckboxListInput selectedItems={value} {...rest} />
  </LabeledFormControl>
);
