import gql from 'graphql-tag';

export const LOAD_FIND_A_TIME = gql`
  query FindATime($attendees: [ID!], $start_gte: DateTime, $start_lt: DateTime) {
    events(
      where: {
        attendees_in: $attendees
        start_gte: $start_gte
        start_lt: $start_lt
        active_only: true
      }
    ) {
      id
      title
      type
      subType
      start
      duration
      timezone
      status
      attendees {
        id
        firstName
        lastName
        ... on Patient {
          preferredName
        }
      }
    }
  }
`;
