import { zonedTimeToUtc } from 'date-fns-tz';

import { startOfDayInTimezone } from 'src/businessHours/startOfDayInTimezone';
import { BOULDER_TIMEZONE } from 'src/businessHours/timezone';

/**
 * @param dateString A YYYY-MM-DD string
 *
 * Converts a date-only string to a Date object representing the start of that day in Boulder's time
 * zone.
 *
 * Example:
 * * Input: '2024-04-08'
 * * Output: Date('2024-04-08T00:00:00-07:00')
 */
export function dateStringToBoulderStartOfDay(dateString: string): Date {
  return startOfDayInTimezone(zonedTimeToUtc(dateString, BOULDER_TIMEZONE), BOULDER_TIMEZONE);
}
