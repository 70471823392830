import React from 'react';

const booleanRenderer = ({ model, classes, key, schema }) => {
  return (
    <div className={classes.itemDetails}>
      {Object.keys(schema.properties).map(prop => {
        const field = schema.properties[prop];
        return (
          <span key={field.title}>
            {field.title}
            {': '}
            {model[key][prop] === true ? 'Yes' : 'No'}
          </span>
        );
      })}
    </div>
  );
};

export default {
  type: 'object',
  title: 'Onboarding: Checklist',
  properties: {
    prescriberInitialVisit: {
      type: 'object',
      renderer: { component: booleanRenderer },
      properties: {
        prescriberFollowUpScheduled: {
          type: 'boolean',
          title: `'Clinician: Follow Up' visit scheduled`,
        },
        firstPrescriptionSent: {
          type: 'boolean',
          title: 'First prescription sent',
        },
        welcomeMessageSent: {
          type: 'boolean',
          title: 'Welcome message sent',
        },
        peerCoacingTeamNotified: {
          type: 'boolean',
          title: 'Peer Coaching team notified of new enrollee',
        },
      },
    },
    prescriberInitialVisitNotes: {
      type: 'string',
      title: 'Notes',
      uniforms: {
        multiline: true,
      },
    },
    afterPrescriberInitialVisit: {
      type: 'object',
      renderer: { component: booleanRenderer },
      properties: {
        peerCoachAssigned: {
          type: 'boolean',
          title: 'Peer Coach has been assigned in Care Team',
        },
        clinicianInductionMessage: {
          type: 'boolean',
          title: 'Clinician has messaged to check in on induction',
        },
        clinicianReceivedResponse: {
          type: 'boolean',
          title: 'Clinician has received a response to induction check-in',
        },
        insuranceInformationGatheringComplete: {
          type: 'boolean',
          title: 'Insurance information gathering complete and benefits discussed',
        },
        releaseOfInformationComplete: {
          type: 'boolean',
          title: 'Release of Information complete',
        },
      },
    },
    afterPrescriberInitialVisitNotes: {
      type: 'string',
      title: 'Notes',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
