import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { SvgIconComponent } from '@material-ui/icons';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import GroupIcon from '@material-ui/icons/Group';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React, { useCallback } from 'react';

import type { CalendarBounds } from 'src/calendars';
import {
  AVAILABILITY_SUB_TYPE_ITEMS,
  getAvailabilitySubTypesForRole,
} from 'src/shared/util/events';
import type { RootStore } from 'src/stores/root';
import { ProviderRole } from 'src/stores/users/userType';

const TYPE_ICONS: Record<keyof typeof AVAILABILITY_SUB_TYPE_ITEMS, SvgIconComponent> = {
  rapid_access: PersonAddIcon,
  panel_care: GroupIcon,
  drop_in_clinic: LocalHospitalIcon,
  scheduled_visits: CalendarTodayIcon,
};

const AvailabilityTypeSelectMenu: React.FC<{
  rootStore: RootStore;
  position?: CalendarBounds;
  availabilityOptions?: Record<string, any>;
  handleClose?: () => void;
}> = ({ position, handleClose = () => {}, availabilityOptions = {}, rootStore }) => {
  const selectSubType = useCallback((subTypeKey, options) => {
    rootStore.events.createEvent(
      rootStore.providers.provider,
      { type: 'availability', subType: subTypeKey, ...options },
      false,
    );

    handleClose();
  }, []);
  const teamRole = rootStore.providers.provider?.teamRole as ProviderRole | undefined;
  if (!teamRole) {
    return null;
  }
  const items = Object.entries(getAvailabilitySubTypesForRole(teamRole));
  return (
    <Menu
      open
      anchorPosition={position ?? { top: 0, left: 0 }}
      anchorReference="anchorPosition"
      onClose={handleClose}
    >
      {items.map(([key, type]) => {
        const Icon = TYPE_ICONS[key];
        return (
          <MenuItem onClick={() => selectSubType(key, availabilityOptions)} key={key}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={type.label} />
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default AvailabilityTypeSelectMenu;
