import React from 'react';

import { states } from 'src/components/forms/schemas/definitions';
import Address from 'src/components/general/address';

export default {
  type: 'object',
  title: 'Prescriber: Telehealth Precheck',
  properties: {
    credentials: {
      type: 'boolean',
      title: 'Gave Identification and Credentials',
      tooltip:
        '"I\'m [name], a physician licensed to practice in [states] and board certified in [certifications]."',
    },
    identity: {
      type: 'boolean',
      title: 'Confirmed Patient Identity',
      tooltip: '"Can you please confirm your name and date of birth?"',
    },
    privacy: {
      type: 'boolean',
      title: 'Confirmed No Privacy Concerns',
      tooltip:
        '"Are you alone? If not, who are you with, and are you comfortable speaking in front of them?"',
    },
    location: {
      type: 'object',
      title: 'Patient Location',
      renderer: {
        component: ({ model }) => (
          <>
            <Address address={model.location} displayInline />
            <br />
            <span>
              Provider is Appropriately Licensed: {model.location.licensed === true ? 'Yes' : 'No'}
            </span>
          </>
        ),
      },
      properties: {
        city: {
          type: 'string',
        },
        state: {
          type: 'string',
          enum: states,
        },
        licensed: {
          type: 'boolean',
          title: 'Provider is Appropriately Licensed',
          tooltip: "Provider is licensed to practice in patient's current state",
        },
      },
    },
  },
  required: [],
};
