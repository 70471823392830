import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { ChartPropertyTypes } from 'src/nightingale/types/types';
import { ValidationSchemas } from 'src/nightingale/validation/schemas';

type IntegerOptions = {
  min: number;
  max: number;
};

type AnyOptions = IntegerOptions;

export const useValidation = (value?: any, validationSchema?: yup.SchemaOf<any>) => {
  const [error, setError] = useState<yup.ValidationError>();
  let isMounted = false;

  useEffect(() => {
    isMounted = true;
    const unmount = () => {
      isMounted = false;
    };

    if (!value || !validationSchema) {
      setError(undefined);
    } else {
      validationSchema
        .validate(value)
        .then(() => setError(undefined))
        .catch((validationError: yup.ValidationError) => {
          if (isMounted) setError(validationError);
        });
    }

    return unmount;
  }, [value]);

  return error;
};

export const useValidationForPropertyType = (
  value?: any,
  propertyType?: ChartPropertyTypes,
  options?: AnyOptions,
) => {
  return useValidation(value, ValidationSchemas[propertyType](options));
};
