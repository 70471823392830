import React from 'react';

import { ChartInteractionContextProvider } from 'src/nightingale/components/ChartInteraction/ChartInteractionContext';
import { ChartInteractionView } from 'src/nightingale/components/ChartInteraction/ChartInteractionView';
import { InteractionKind } from 'src/nightingale/types/types';

const TaskDetail: React.VFC<{
  classes: Record<string, string>;
  name: string;
  patientId: string;
  taskId: string;
}> = ({ classes, name, patientId, taskId }) => {
  return (
    <div className={classes.summary} data-testid="task-detail">
      <ChartInteractionContextProvider
        patientId={patientId}
        interactionReferenceId={taskId}
        isNightingale
        interactionKey={name}
        kind={InteractionKind.Task}
      >
        <ChartInteractionView disableEdit />
      </ChartInteractionContextProvider>
    </div>
  );
};

export default TaskDetail;
