import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  loader: {
    color: Colors.BlueSpruce,
  },
  obstructionist: {
    alignItems: 'center',
    background: 'rgba(255,255,255,0.5)',
    bottom: 0,
    cursor: 'wait',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    margin: 0,
    padding: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
});

export const ChartElementSaveSpinner: React.FC = () => {
  const styles = useStyles();

  return (
    <figure className={styles.obstructionist} data-testid="chart-element-editor-obstructionist">
      <CircularProgress className={styles.loader} size="2rem" />
    </figure>
  );
};
