import { Button, makeStyles } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import React, { useContext, useEffect, useRef } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import Colors from 'src/nightingale/Colors';
import { ChartContext } from 'src/nightingale/components/ChartContext/ChartContext';
import { ChartElementSummary } from 'src/nightingale/components/ChartElement/ChartElement.Summary';
import { InlineHelp } from 'src/nightingale/components/ChartElement/InlineHelp';
import LabelIcon from 'src/nightingale/components/ChartElement/LabelIcon';
import ChartElement from 'src/nightingale/components/ChartElement/domain/ChartElement';
import { useStyles as useLabelStyles } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { HighlightedText } from 'src/nightingale/components/ChartSearch/HighlightedText';
import useRequiredChartProperties from 'src/nightingale/requiredChartProperties/useRequiredChartProperties';
import { ChartElement as TChartElement } from 'src/nightingale/types/types';

/**
 * Styles
 */
const useStyles = makeStyles({
  container: {
    backgroundColor: Colors.White,
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: 10,
    padding: 16,
  },
  inlineHelp: {
    marginTop: -5,
    marginBottom: 5,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconButton: {
    alignItems: 'center',
    backgroundColor: Colors.Stillwater,
    border: 'none',
    borderRadius: 0,
    color: Colors.White,
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    margin: '16px 16px 0 0',
    padding: '8px 16px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
  },
  content: {
    marginTop: 8,
  },
  contentWithIcon: {
    marginLeft: 23,
  },
});

export const ChartElementConfirm: React.FC<{
  definition: TChartElement;
  onConfirm: () => void;
  onEdit: () => void;
}> = ({ definition, onConfirm, onEdit }) => {
  const chartContext = useContext(ChartContext);
  const requiredChartProperties = useRequiredChartProperties();
  const flags = useContext(FeatureFlagContext);

  const element = new ChartElement(definition, chartContext, requiredChartProperties, flags);

  const styles = useStyles();
  const labelStyles = useLabelStyles({ labelType: element.labelType });
  const { label, parsedInlineHelp, summarization } = definition;

  const confirmButtonRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (confirmButtonRef.current) confirmButtonRef.current.focus();
  }, []);

  return (
    <div className={styles.container} data-testid="chart-element-confirm">
      <div className={labelStyles.header} data-testid="chart-element-confirm-header">
        <LabelIcon
          hasMissingRequiredFields={element.hasMissingRequiredProperties}
          labelType={element.labelType}
        />
        {label ? <HighlightedText text={label} /> : null}
      </div>
      <div
        className={classNames(styles.content, {
          [styles.contentWithIcon]: element.labelType.includes('with-icon'),
        })}
      >
        {parsedInlineHelp && (
          <div className={styles.inlineHelp}>
            <InlineHelp helpText={parsedInlineHelp} />
          </div>
        )}
        {summarization ? <ChartElementSummary summarization={summarization} /> : null}
      </div>
      <div className={styles.buttonRow}>
        <Button
          data-testid="chart-element-confirm-button"
          ref={confirmButtonRef}
          onClick={onConfirm}
          classes={{ root: styles.iconButton }}
          startIcon={<DoneAllIcon />}
        >
          Confirm
        </Button>
        <Button
          data-testid="chart-element-edit-button"
          onClick={onEdit}
          classes={{ root: styles.iconButton }}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </div>
    </div>
  );
};
