import React from 'react';

import PatientOverviewLink from 'src/components/general/patientOverviewLink';

export default {
  type: 'object',
  title: 'Prescriber: Assessment',
  properties: {
    currentDiagnosis: {
      type: 'string',
      title: 'Current diagnosis',
      enum: [
        'Opioid Use Disorder',
        'Opioid Use Disorder in early remission',
        'Opioid Use Disorder in sustained remission',
        'Opioid Dependence',
      ],
    },
    updatedTier: {
      type: 'boolean',
      title: 'Updated tier',
      label: <PatientOverviewLink section="general">Updated tier</PatientOverviewLink>,
    },
    assessmentNotes: {
      type: 'string',
      title: 'Assessment Notes',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
