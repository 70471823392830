import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { format, isValid, parseISO } from 'date-fns';
import { Formik } from 'formik';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import startCase from 'lodash/startCase';
import React, { useState, FunctionComponent } from 'react';

import DateControl from 'src/components/forms/controls/date';
import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import {
  AutomationJobsFilter,
  AutomationJobStatus,
} from 'src/components/pages/automation/useSWRAutomationJobs';
import { AutomationRule } from 'src/components/pages/automation/useSWRAutomationRules';
import Accordion from 'src/components/pages/pageElements/accordion';
import FilterSummary from 'src/components/pages/pageElements/filterSummary';
import { getSingleSelectFilterField } from 'src/util/filters';

type FiltersProps = {
  filterValues: AutomationJobsFilter;
  rules: AutomationRule[];
  handleFiltersChange: (filter: AutomationJobsFilter) => void;
};

const Filters: FunctionComponent<FiltersProps> = ({
  filterValues,
  handleFiltersChange,
  rules = [],
}) => {
  const classes = useFiltersStyles();

  // Form values keep track of what we're currently selecting in the UI, so that
  // we may kick off another search request when we're ready
  const [localValues, setLocalValues] = useState(filterValues);

  const hasUnappliedChanges = !isEqual(omitBy(filterValues, isNil), omitBy(localValues, isNil));

  const filterFields = [
    getSingleSelectFilterField(
      'Rule',
      'rule',
      props => (
        <SelectControl
          {...props}
          valueFn={(rule: AutomationRule) => rule.id}
          labelFn={(rule: AutomationRule) => rule.title}
          options={rules}
        />
      ),
      ruleId => rules.find(r => r.id === ruleId)?.title || 'Unknown',
    ),
    getSingleSelectFilterField('Patient', 'patient', TextControl),
    getSingleSelectFilterField('Status', 'status', props => (
      <SelectControl
        {...props}
        valueFn={s => s}
        labelFn={(s: string) => startCase(s.toLowerCase())}
        options={Object.keys(AutomationJobStatus)}
      />
    )),
    getSingleSelectFilterField('From Date', 'date_gte', DateControl, (date: string) => {
      const parsedDate = parseISO(date);
      if (!isValid(parsedDate)) return '';
      return format(parsedDate, 'PP');
    }),
  ];

  return (
    <FormControl fullWidth className={classes.accordionContainer}>
      <div className={classes.formLabel}>
        <InputLabel shrink>Filtered By</InputLabel>
      </div>
      <Accordion
        additionalButtons={[
          {
            label: 'Clear All',
            onClick: () => setLocalValues(fromPairs(filterFields.map(field => [field.key, null]))),
          },
        ]}
        submitLabel="Filter"
        submitDisabled={!hasUnappliedChanges}
        summary={
          <FilterSummary
            filterFields={filterFields}
            localValues={localValues}
            hasUnappliedChanges={hasUnappliedChanges}
            removeLocalFilter={filterKey => setLocalValues({ ...localValues, [filterKey]: null })}
            onFilter={handleFiltersChange}
            showQuickFilter={hasUnappliedChanges}
            setShowQuickFilter={() => {}} // for simplicity always show it if there are local changes
          />
        }
        onCancel={() => setLocalValues(filterValues)}
        onSubmit={() => handleFiltersChange(localValues)}
      >
        <Formik
          initialValues={localValues}
          enableReinitialize
          onSubmit={() => {}} // not submittable
        >
          {() => (
            <div className={classes.formContainer}>
              <FormikEffect
                onChange={(current, prev) => {
                  // Update local values for fields that don't offer onChange but use `form.setFieldValue()` deeper down (i.e. ReferenceControl)
                  if (current.values !== prev.values) {
                    setLocalValues(current.values);
                  }
                }}
              />
              {filterFields.map(filterField => (
                <Field
                  key={filterField.key}
                  component={filterField.component}
                  value={localValues[filterField.key]}
                  {...filterField.props}
                />
              ))}
            </div>
          )}
        </Formik>
      </Accordion>
    </FormControl>
  );
};

const useFiltersStyles = makeStyles({
  accordionContainer: {
    // Required to make input label appear
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    '& > *': {
      display: 'inline-flex',
      marginBottom: 30,
      marginRight: 30,
      verticalAlign: 'inherit',
      width: 300,
    },
  },
  formLabel: {
    height: 20,
  },
});

export default Filters;
