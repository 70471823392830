import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { add, format } from 'date-fns';
import React from 'react';

const getRoiRequestStatus = roiRequest => {
  if (roiRequest.recipient?.name.toLowerCase() === 'unknown') {
    return 'Invalid';
  } else if (roiRequest.isDeleted) {
    return 'Deleted';
  } else if (!roiRequest.completedAt && roiRequest.createdAt) {
    return `Requested ${format(new Date(roiRequest.createdAt), 'PP')}`;
  } else if (roiRequest.completedAt) {
    return `Signed ${format(new Date(roiRequest.completedAt), 'PP')}`;
  } else {
    return 'Invalid';
  }
};

const getRoiRequestExpiration = roiRequest => {
  if (roiRequest.recipient) {
    return roiRequest.completedAt
      ? format(add(new Date(roiRequest.completedAt), { years: 1 }), 'PP')
      : null;
  } else {
    return roiRequest.completedAt ? 'Never' : null;
  }
};

const InsuranceRoiRequestsTable = ({ classes, insuranceRoiRequests }) => (
  <Table className={classes.table}>
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableHead}>Recipient</TableCell>
        <TableCell className={classNames(classes.tableHead, classes.typeColumn)}>Type</TableCell>
        <TableCell className={classNames(classes.tableHead, classes.typeColumn)}>Status</TableCell>
        <TableCell className={classNames(classes.tableHead, classes.typeColumn)}>Expires</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {insuranceRoiRequests.map(doc => (
        <TableRow key={doc.requestId}>
          <TableCell>{doc.recipient ? doc.recipient.name : 'Universal Release'}</TableCell>
          <TableCell>Release of Information (v{doc.documentVersion})</TableCell>
          <TableCell>{getRoiRequestStatus(doc)}</TableCell>
          <TableCell>{getRoiRequestExpiration(doc)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const styles = {
  table: {
    tableLayout: 'fixed',
  },
  tableHead: {
    padding: '4px 10px',
    textTransform: 'uppercase',
  },
};

export default withStyles(styles)(InsuranceRoiRequestsTable);
