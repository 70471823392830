import { withStyles } from '@material-ui/core/';
import React from 'react';

const InsertedTag = ({ children, classes }) => (
  <span className={classes.insertedTag}>{children}</span>
);

const styles = theme => ({
  insertedTag: {
    backgroundColor: theme.palette.grey[300],
  },
});

export default withStyles(styles)(InsertedTag);
