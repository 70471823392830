import { addNameAndVersionChecks } from 'src/components/forms/addNameAndVersionChecks';
import schemas from 'src/components/forms/schemas';

/* This function determines the version number corresponding with the schema that should be used
 * in newly created data.
 */
export const getLatestVersion = addNameAndVersionChecks((schema, name) => {
  return getVersionNumber(schema, name, 'latest');
});

// This function returns an array containing every version number associated with the named schema
export function getSchemaVersions(schema, name) {
  return Object.keys(schemas[schema][name]).filter(version => version !== 'latest');
}

// This function determines the version number that corresponds to a version with a key that is
// different from the version number e.g. 'careTierMigrationOff' as versionName would return '1.2.0'.
// This ensures the version number is what is saved in the db.
function getVersionNumber(schema, name, versionName) {
  return Object.keys(schemas[schema][name]).find(version => {
    return (
      version !== versionName &&
      schemas[schema][name][version] === schemas[schema][name][versionName]
    );
  });
}
