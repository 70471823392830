import { TableHead, Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { format, addMinutes, parseISO, isValid } from 'date-fns';
import React, { useState, useCallback } from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { AUTO_REFRESH_TIME_MS } from 'src/dropInClinic/ClinicRequestQueue';
import { Duration } from 'src/dropInClinic/Duration';
import { requestTypeMap } from 'src/dropInClinic/RequestType';
import { useInterval } from 'src/dropInClinic/hooks/useInterval';
import { GET_BLOCK_LIST } from 'src/dropInClinic/queries/getBlockList.gql';
import { GetBlockListQuery } from 'src/generated/gql/graphql';
import Colors from 'src/nightingale/Colors';

type ScheduleBlock = GetBlockListQuery['getClinicBlockList'][number]['blockList'][number];

export const ProviderSuggestedAssignments = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  const tick = useCallback(() => {
    setCurrentTime(new Date());
  }, [setCurrentTime]);
  useInterval(tick, 60000);

  const { data: suggestionData, error: suggestionError } = useTypedSWR(GET_BLOCK_LIST, {
    refreshInterval: AUTO_REFRESH_TIME_MS,
  });

  if (suggestionError) {
    return <ErrorAlert message="Error fetching suggested scheduling" error={suggestionError} />;
  }

  if (!suggestionData) {
    return <div>Loading queue</div>;
  }

  const suggestedBlockList = suggestionData?.getClinicBlockList ?? [];

  return (
    <div>
      {suggestedBlockList.map(({ provider, blockList }) => (
        <div style={{ paddingTop: 12, width: 800 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <Typography style={{ fontWeight: 'bold', paddingLeft: 12, paddingTop: 12 }}>
                  {provider.firstName} {provider.lastName}
                </Typography>
                <TableRow>
                  <TableCell>Patient</TableCell>

                  <TableCell align="right">Visit Type</TableCell>
                  <TableCell align="right">Time in Queue</TableCell>
                  <TableCell align="right">Start</TableCell>
                  <TableCell align="right">End</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blockList.map(block => {
                  return <Block block={block} currentTime={currentTime} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
      {suggestedBlockList.length === 0 && (
        <div>
          <br />
          Suggested assignment list is empty
        </div>
      )}
    </div>
  );
};

const deserializeBlock = (block: ScheduleBlock) => ({
  ...block,
  startTime: parseISO(block.startTime),
});

const Block = ({ block, currentTime }: { block: ScheduleBlock; currentTime: Date }) => {
  const { startTime, duration, patient, request, hasSingleProvider } = deserializeBlock(block);
  const endTime = addMinutes(startTime, duration);
  const patientName = patient
    ? `${patient.preferredName || patient.firstName} ${patient.lastName}`
    : null;
  const requestTime = request ? parseISO(request?.requestTime) : null;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <span
          style={
            patientName && hasSingleProvider ? { fontWeight: 'bold', color: Colors.Coral } : {}
          }
        >
          {patientName || <i>Unscheduled</i>}{' '}
        </span>
        {hasSingleProvider && `(Single Provider)`}
      </TableCell>
      <TableCell align="right">{request ? requestTypeMap[request.requestType] : null}</TableCell>
      <TableCell align="right">
        {requestTime && isValid(requestTime) ? (
          <Duration date={requestTime} currentTime={currentTime} />
        ) : null}
      </TableCell>
      <TableCell align="right">{format(startTime, 'p')}</TableCell>
      <TableCell align="right">{format(endTime, 'p')}</TableCell>
    </TableRow>
  );
};
