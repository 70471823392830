"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequiredWhen = void 0;
let RequiredWhen = exports.RequiredWhen = function (RequiredWhen) {
  RequiredWhen["Always"] = "always";
  RequiredWhen["Once"] = "once";
  return RequiredWhen;
}({});