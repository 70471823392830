import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';

export const GET_PROVIDER_ENDORSEMENTS = gql`
  query getProviderEndorsements($providerId: String!) {
    providerEndorsements(providerId: $providerId) {
      credentials
      licensedStates
    }
  }
`;

export type EndorsementsQueryResult = {
  providerEndorsements: {
    licensedStates: [string];
    credentials: [string];
  };
};

export enum EndorsementError {
  /**
   * The selected patient does not have a home state charted. This is required for scheduling.
   */
  PatientWithoutHomeState = 'PatientWithoutHomeState',
  /**
   * The selected patient does not have a insurance information charted. This is required for scheduling.
   */
  PatientWithoutInsurance = 'PatientWithoutInsurance',
  /**
   * The selected provider(s) are not licensed in the patient's state. This is required for scheduling
   */
  ProvidersUnlicensed = 'ProvidersUnlicensed',
  /**
   * The selected provider(s) are not credentialed with the patient's insurance.
   */
  ProvidersUncredentialed = 'ProvidersUncredentialed',
}

export const getEndorsementError = async (
  patientId: string | null,
  isMedicalVisit: boolean,
  selectedMedicalProviders: string[],
  apolloClient: ApolloClient<NormalizedCacheObject>,
  patientState: string | null,
  credentialId: string | null,
): Promise<EndorsementError | null> => {
  // If we don't have the required data, we can't check for endorsement errors
  if (!(patientId && selectedMedicalProviders.length > 0)) {
    return Promise.resolve(null);
  }
  // If it's not a medical visit, we don't need to check for endorsements
  if (!isMedicalVisit) {
    return Promise.resolve(null);
  }

  if (!patientState) {
    return Promise.resolve(EndorsementError.PatientWithoutHomeState);
  }
  if (!credentialId) {
    return Promise.resolve(EndorsementError.PatientWithoutInsurance);
  }

  const endorsementPromises = selectedMedicalProviders.map(providerId => {
    return apolloClient?.query<EndorsementsQueryResult>({
      query: GET_PROVIDER_ENDORSEMENTS,
      variables: { providerId },
    });
  });

  return Promise.all(endorsementPromises).then(ret => {
    const credentialList = ret.flatMap(response => response?.data.providerEndorsements.credentials);
    const stateList = ret.flatMap(response => response?.data.providerEndorsements.licensedStates);

    if (!patientState || !stateList.includes(patientState)) {
      return EndorsementError.ProvidersUnlicensed;
    } else if (!credentialList.includes(credentialId)) {
      return EndorsementError.ProvidersUncredentialed;
    }

    return null;
  });
};
