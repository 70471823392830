import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const PocTestingTabIcon = ({ className }: { className: string }) => {
  return (
    <SvgIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M12 22C10.6167 22 9.4375 21.5125 8.4625 20.5375C7.4875 19.5625 7 18.3833 7 17V8C6.45 8 5.97917 7.80417 5.5875 7.4125C5.19583 7.02083 5 6.55 5 6V4C5 3.45 5.19583 2.97917 5.5875 2.5875C5.97917 2.19583 6.45 2 7 2H17C17.55 2 18.0208 2.19583 18.4125 2.5875C18.8042 2.97917 19 3.45 19 4V6C19 6.55 18.8042 7.02083 18.4125 7.4125C18.0208 7.80417 17.55 8 17 8V17C17 18.3833 16.5125 19.5625 15.5375 20.5375C14.5625 21.5125 13.3833 22 12 22ZM12 20C12.8333 20 13.5417 19.7083 14.125 19.125C14.7083 18.5417 15 17.8333 15 17H12V15H15V13H12V11H15V8H9V17C9 17.8333 9.29167 18.5417 9.875 19.125C10.4583 19.7083 11.1667 20 12 20Z" />
    </SvgIcon>
  );
};

export default PocTestingTabIcon;
