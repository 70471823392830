import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';

import { UserDocument } from 'src/components/pages/patientShow/documents/types';
import useTableStyles from 'src/components/pages/patientShow/documents/useTableStyles';
import { parseUnknownDate } from 'src/util/parseUnknownDate';

const ConsentDocumentsTable: React.FC<{ signedDocuments: UserDocument[] }> = ({
  signedDocuments,
}) => {
  const styles = useStyles();
  const tableStyles = useTableStyles();

  return (
    <Table className={tableStyles.table}>
      <TableHead>
        <TableRow>
          <TableCell className={tableStyles.tableHead}>Title</TableCell>
          <TableCell className={classNames(tableStyles.tableHead, styles.dateColumn)}>
            Date Signed
          </TableCell>
          <TableCell className={classNames(tableStyles.tableHead, styles.actionColumn)} />
        </TableRow>
      </TableHead>
      <TableBody>
        {signedDocuments.map((doc, idx) => (
          <TableRow
            key={
              doc.document
                ? `${doc.document.name}${doc.document.version}`
                : `signed-document-${idx}`
            }
          >
            <TableCell className={tableStyles.tableCell}>
              {doc.document ? `${doc.document.name} (v${doc.document.version})` : 'Signed Document'}
            </TableCell>
            <TableCell className={tableStyles.tableCell}>
              {format(parseUnknownDate(doc.signedAt), 'PP')}
            </TableCell>
            <TableCell className={tableStyles.tableCell} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const useStyles = makeStyles({
  dateColumn: {
    width: 128,
  },
  actionColumn: {
    width: 128,
  },
});

export default ConsentDocumentsTable;
