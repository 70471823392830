import { Chip, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import AlbumOutlinedIcon from '@material-ui/icons/AlbumOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PeopleIcon from '@material-ui/icons/People';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { Styles } from '@material-ui/styles';
import classNames from 'classnames';
import { compareAsc, format, isBefore, isValid, parse, parseISO } from 'date-fns';
import debounce from 'lodash/debounce';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import type { RouterStore } from 'mobx-state-router';
import React, { useEffect, useState } from 'react';

import PebblesListFilters from 'src/components/pages/pageElements/pebblesListFilters';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import { configCatFlags } from 'src/featureFlags/configCat';
import { TURN_OFF_PEBBLES } from 'src/featureFlags/currentFlags';
import { FlowMappingsContextProvider } from 'src/nightingale/components/Flow/FlowMappingsContext';
import { InteractionKind } from 'src/nightingale/types/types';
import PebbleDetailsForm from 'src/pebbles/components/PebbleDetailsForm';
import type { Resource } from 'src/shared/stores/resource';
import type { PebblesDomainType } from 'src/stores/pebbles/domain';
import type { PebblesUiType } from 'src/stores/pebbles/ui';
import { colors } from 'src/util/colors';
import { PEBBLE_STATUS_MAP, PebbleDisplayData } from 'src/util/pebbles';

type StatusStylingObject = { [key: string]: React.CSSProperties | any };

const EDITING_SECTION_NAME = 'Pebble Details';

const getStatusStyling = (
  status: string,
  classes: { [key: string]: string },
): StatusStylingObject => {
  const statusStylingObj: StatusStylingObject = {};
  statusStylingObj.style = { fontWeight: 'bold', color: colors.taupe };

  const icons = {
    new: AddCircleOutlineIcon,
    acknowledged: AlbumOutlinedIcon,
    in_progress: TimelapseIcon,
    on_hold: PauseCircleOutlineIcon,
    completed: CheckCircleIcon,
    wont_do: CancelIcon,
  };
  const Icon = icons[status];

  if (status === 'completed') {
    statusStylingObj.icon = <Icon className={classNames(classes.icon, classes.completedStatus)} />;
    statusStylingObj.style.color = colors.primary;
  } else if (status === 'wont_do') {
    statusStylingObj.icon = <Icon className={classNames(classes.icon, classes.wontDoStatus)} />;
    statusStylingObj.style.color = '#888888';
  } else if (status === 'new') {
    statusStylingObj.icon = <Icon className={classNames(classes.icon, classes.mailIcon)} />;
  } else {
    statusStylingObj.icon = <Icon className={classNames(classes.icon, classes.statusIcon)} />;
  }

  return statusStylingObj;
};

const isPastDue = (rowData: PebbleDisplayData): boolean => {
  if (!rowData.reminder) {
    return false;
  }
  const reminderDate = parse(rowData.reminder, 'MM/dd/yyyy hh:mm a', rowData.createdAt);
  return (
    isBefore(reminderDate, new Date()) && !['wont_do', 'completed'].includes(rowData.status ?? '')
  );
};

const getTitleIcon = (rowData: PebbleDisplayData, userId: string | null) => {
  const isNew = !!userId && rowData.isNewlyAssigned(userId);

  if (isPastDue(rowData) || rowData.priority === 'urgent') {
    return <NotificationsIcon data-testid="notifications-icon" />;
  } else if (isNew) {
    return <MailIcon data-testid="mail-icon" />;
  }

  return null;
};

const UNSAVED_CHANGES_WARNING = 'Switch pebble? Changes you made may not be saved.';

type PebblesProps = {
  classes: { [key: string]: string };
  rootStore: {
    auth: { user: Resource };
    pebbles: {
      domain: PebblesDomainType;
      ui: PebblesUiType;
    };
    routerStore: RouterStore;
    setEditing: (name: string, value: any) => void;
    isEditing: boolean;
    isEditingByName: Map<string, boolean>;
  };
};

const Pebbles = ({
  classes,
  rootStore,
  rootStore: {
    auth: { user },
    pebbles: {
      domain: { list, updatePebble, getFullPebble },
      ui: { toggleCreateDialog, selectPebble, selectedPebbleId, selectedPebble, setSelectedPebble },
    },
    routerStore,
    routerStore: { routerState },
    setEditing: rootStoreSetEditing,
    isEditing: rootStoreIsEditing,
  },
}: PebblesProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  let queryParamsChangeBatch = {};

  const debouncedQueryParamsChange = debounce(() => {
    updateQueryParams(queryParamsChangeBatch);
    queryParamsChangeBatch = {};
  }, 0);

  useEffect(() => {
    const getPebble = async id => {
      const res = await getFullPebble(id);
      setSelectedPebble(res);
    };

    if (selectedPebbleId) {
      getPebble(selectedPebbleId);
    }
  }, [selectedPebbleId]);

  const cancelEditing = () => {
    rootStoreSetEditing(EDITING_SECTION_NAME, false);
  };

  if (configCatFlags.latestFlags[TURN_OFF_PEBBLES]) {
    return <div className={classes.root}>Pebbles are turned off.</div>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <TitleBar
          title="Pebbles"
          onAction={() => toggleCreateDialog()}
          actionTooltip="Add Pebble"
        />
      </div>
      <div className={classes.filtersContainer}>
        <PebblesListFilters
          queryParams={routerState.queryParams}
          handleFiltersChange={handleFiltersChange}
          rootStore={rootStore}
        />
      </div>
      <div className={classes.columns}>
        <div className={classes.column}>
          <MaterialTable<PebbleDisplayData>
            columns={[
              {
                title: 'Patient',
                field: 'patientName',
                render: rowData => {
                  const icon = getTitleIcon(rowData, user.id);

                  return (
                    <>
                      <div
                        className={
                          isPastDue(rowData) || rowData.priority === 'urgent'
                            ? classNames(classes.pebbleTitle, classes.urgent)
                            : classes.pebbleTitle
                        }
                        data-testid="pebble-title"
                      >
                        <div className={classes.titleIcon}>{icon}</div>
                        {rowData.title}
                      </div>
                      {rowData.patientName && (
                        <div className={classes.description}>
                          <AssignmentIndIcon className={classes.icon} />
                          <div>
                            <div>{rowData.patientName}</div>
                            <div className={classes.descriptionRow}>
                              {rowData.patientDob && (
                                <Chip
                                  size="small"
                                  label={format(parseISO(rowData.patientDob), 'MM/dd/yyyy')}
                                />
                              )}
                              {rowData.patientState && (
                                <Chip size="small" label={rowData.patientState} />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                },
              },
              {
                title: 'Assigned to',
                field: 'assignee',
                headerStyle: { whiteSpace: 'nowrap' },
                render: rowData => (
                  <div className={classes.description}>
                    <PeopleIcon className={classes.icon} />
                    <div className={classes.splitDescription}>{rowData.assignee}</div>
                  </div>
                ),
              },
              {
                title: 'Last Updated',
                field: 'updatedAt',
                headerStyle: { whiteSpace: 'nowrap' },
                render: rowData => (
                  <>
                    {rowData.updatedAt && (
                      <>
                        <div className={classes.description}>
                          <AlarmIcon className={classes.icon} />
                          <div>
                            <div className={classes.date}>
                              {rowData.updatedAt ? format(rowData.updatedAt, 'MMM d p') : ''}
                            </div>
                            <div className={classes.secondaryDescription}>
                              by {rowData.updatedBy}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ),
              },
              {
                title: 'Reminder',
                field: 'reminderDate',
                customSort: compareReminderDates,
                headerStyle: { whiteSpace: 'nowrap' },
                render: rowData => (
                  <>
                    {isValid(rowData.reminderDate) && (
                      <>
                        <div className={classes.description}>
                          <AlarmIcon className={classes.icon} />
                          <div>
                            <div className={classes.date}>
                              {rowData.reminderDate
                                ? format(rowData.reminderDate as Date, 'MMM d p')
                                : ''}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ),
              },
              {
                title: 'Created',
                field: 'createdAt',
                headerStyle: { whiteSpace: 'nowrap' },
                render: rowData => (
                  <>
                    <div className={classes.description}>
                      <AlarmIcon className={classes.icon} />
                      <div>
                        <div className={classes.date}>
                          {rowData.createdAt ? format(rowData.createdAt, 'MMM d p') : ''}
                        </div>
                        <div className={classes.secondaryDescription}>by {rowData.createdBy}</div>
                      </div>
                    </div>
                  </>
                ),
              },
              {
                title: 'Status',
                field: 'status',
                render: rowData => {
                  if (!rowData.status) return <div className={classes.description}>—</div>;

                  const statusStyling = getStatusStyling(rowData.status, classes);

                  return (
                    <div className={classes.description}>
                      {statusStyling.icon}
                      <div style={statusStyling.style}>{PEBBLE_STATUS_MAP[rowData.status]}</div>
                    </div>
                  );
                },
              },
            ]}
            data={list.materialTableDataFormat}
            page={list.page}
            totalCount={Infinity}
            onRowClick={(event, rowData) => {
              const { id } = rowData ?? { id: null };
              setIsEditing(false);
              if (rootStoreIsEditing) {
                /* eslint-disable-next-line no-alert, no-restricted-globals */
                if (!confirm(UNSAVED_CHANGES_WARNING)) {
                  return;
                }
                cancelEditing();
              }
              selectPebble(id);
            }}
            onChangePage={changePageHandler}
            onChangeRowsPerPage={changeRowsPerPageHandler}
            options={{
              toolbar: false,
              paging: true,
              pageSize: list.rowsPerPage || 10,
              search: false,
              rowStyle: rowData => ({
                backgroundColor: selectedPebbleId === rowData.id ? '#EEE' : '#FFF',
                verticalAlign: 'baseline',
              }),
              headerStyle: { fontSize: 12 },
              showFirstLastPageButtons: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to}',
              },
            }}
          />
        </div>
        <div className={classes.column}>
          {selectedPebble && (
            <div className={classNames(classes.detail, classes.stickyView)}>
              <FlowMappingsContextProvider kind={InteractionKind.Pebble}>
                <PebbleDetailsForm
                  key={selectedPebble.id}
                  pebble={selectedPebble}
                  onEdit={() => {
                    if (isEditing) {
                      cancelEditing();
                    }
                    setIsEditing(!isEditing);
                  }}
                  isEditing={isEditing}
                  isEditingPebbleDetails={!!rootStore.isEditingByName.get(EDITING_SECTION_NAME)}
                  setEditing={rootStore.setEditing}
                  editingSectionName={EDITING_SECTION_NAME}
                  onSave={newValues => {
                    return updatePebble(newValues, selectedPebble)
                      .then(res => setSelectedPebble(res))
                      .then(() => {
                        setIsEditing(false);
                        cancelEditing();
                      });
                  }}
                  onCancel={() => {
                    setIsEditing(false);
                    cancelEditing();
                  }}
                  formId="pebble-details-form"
                  canEditInteraction
                />
              </FlowMappingsContextProvider>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function changePageHandler(page) {
    enqueueQueryParamsChange({ page });
  }

  function changeRowsPerPageHandler(rowsPerPage) {
    enqueueQueryParamsChange({ rowsPerPage });
  }

  function enqueueQueryParamsChange(queryParamsToAdd) {
    Object.assign(queryParamsChangeBatch, queryParamsToAdd);
    debouncedQueryParamsChange();
  }

  function getQueryFilterValue(value) {
    if (typeof value === 'string') {
      return value;
    }
    return value?.id || value?.value || value?.key;
  }

  function handleFiltersChange(newValue) {
    const filtersQueryParams = Object.keys(newValue).reduce((result, key) => {
      // eslint-disable-next-line no-param-reassign
      result[key] = Array.isArray(newValue[key])
        ? newValue[key].map(val => getQueryFilterValue(val))
        : getQueryFilterValue(newValue[key]);
      return result;
    }, {});

    updateQueryParams({ ...filtersQueryParams, page: 0 });
  }

  function updateQueryParams(queryParamsChanges) {
    routerStore.goTo(routerState.routeName, {
      params: routerState.params,
      queryParams: {
        ...routerState.queryParams,
        ...queryParamsChanges,
      },
    });
  }
};

const styles: Styles<Theme, any> = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  filtersContainer: {
    paddingBottom: 25,
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flexBasis: '100%',
    flex: 1,
  },
  stickyView: {
    position: 'sticky',
    top: 64, // Current min-height for the top navbar in most breakpoints
  },
  detail: {
    paddingLeft: theme.spacing(3),
  },
  title: {
    display: 'flex',
    flex: '0 0 auto',
  },
  pebbleTitle: {
    fontSize: 20,
    position: 'absolute',
    left: 0,
    paddingLeft: 17,
    color: colors.taupe,
    fontWeight: 500,
    maxWidth: '95%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  highlightedPebbleTitle: {
    color: colors.orange,
    fontWeight: 700,
  },
  description: {
    display: 'flex',
    fontSize: 14,
    color: colors.darkGray,
    flex: 1,
    paddingTop: 40,
    whiteSpace: 'nowrap',
  },
  secondaryDescription: {
    fontSize: 12,
    color: '#888888',
    fontStyle: 'italic',
  },
  icon: {
    paddingRight: 5,
    fontSize: 18,
    paddingTop: 1,
    color: '#888888',
  },
  statusIcon: {
    color: colors.taupe,
  },
  statusText: {
    fontWeight: 'bold',
    color: colors.taupe,
  },
  wontDoStatus: {
    color: '#888888',
  },
  completedStatus: {
    color: colors.primary,
  },
  splitDescription: {
    maxWidth: 'min-content',
    whiteSpace: 'normal',
  },
  urgent: {
    color: '#EE7A60',
  },
  titleIcon: {
    paddingRight: 5,
    paddingTop: 3,
  },
  descriptionRow: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    '& div': {
      marginRight: '1ex',
      fontSize: 12,
    },
  },
});

function compareReminderDates(rowData1, rowData2) {
  if (!rowData1.reminderDate && !rowData2.reminderDate) return 0;
  if (!rowData1?.reminderDate) return 1;
  if (!rowData2?.reminderDate) return -1;
  return compareAsc(rowData1.reminderDate, rowData2.reminderDate);
}

export const __test__ = {
  compareReminderDates,
};

export default withStyles(styles)(inject('rootStore')(observer(Pebbles)));
