import React from 'react';

import { BooleanInput } from 'src/nightingale/components/ChartProperty/controls/Boolean/BooleanInput';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { useControlState } from 'src/nightingale/hooks/useControlState';
import { useDidUpdateEffect } from 'src/nightingale/hooks/useDidUpdateEffect';
import { ControlProps, BooleanChartProperty } from 'src/nightingale/types/types';

export type BooleanControlProps = ControlProps<BooleanChartProperty>;

/**
 * Boolean Control
 */
export const BooleanControl: React.FC<BooleanControlProps> = ({
  autoFocus,
  canBeUndefined = true,
  hasEmptyValue,
  isRequired,
  label,
  onChangeValue,
  value,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useControlState<boolean | null>(value ?? null);

  useDidUpdateEffect(() => {
    if (onChangeValue) onChangeValue(internalValue ?? null);
  }, [internalValue]);

  return (
    <LabeledFormControl hasEmptyValue={hasEmptyValue} isRequired={isRequired} label={label}>
      <BooleanInput
        autoFocus={autoFocus}
        onChange={selectedValue => {
          let newValue: boolean | null | undefined = selectedValue;
          if (internalValue === newValue) {
            if (!canBeUndefined) return;
            newValue = null;
          }
          setInternalValue(newValue);
        }}
        isSelected={internalValue}
        {...rest}
      />
    </LabeledFormControl>
  );
};
