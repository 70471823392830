export default {
  type: 'object',
  title: 'General: Telehealth Wrap Up',
  properties: {
    visitDuration: {
      type: 'integer',
      title: 'Visit Duration (min)',
    },
    audioVideoQuality: {
      type: 'string',
      title: 'Audio/Video Quality',
      enum: ['Very Good', 'Good', 'Fine', 'Bad', 'Very Bad'],
    },
    audioVideoQualityDetails: {
      type: 'string',
      title: 'Audio/Video Quality Details',
      uniforms: {
        multiline: true,
      },
      tooltip:
        'Additional information about any technical issues with the call. Was the patient on wifi or cellular? What could have been better?',
    },
    followups: {
      type: 'string',
      title: 'Follow Ups',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
