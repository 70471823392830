import Colors from 'src/nightingale/Colors';

export const colors = {
  primary: Colors.BoulderBlue,
  secondary: Colors.BlueSpruce,
  error: '#97302D',
  errorLight: '#EB5757',
  warning: '#FFC4A6',
  warningHover: '#FFA385',
  taupe: '#696158',
  darkGray: '#4b4b4b',
  orange: '#FF7357',
  mediumGray: '#929499',
  muiRed: '#F47070',
};
