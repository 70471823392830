import capitalize from 'lodash/capitalize';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import { getEventIconProps } from 'src/components/pages/pageElements/calendarEvent';

const EventStatusIcon = ({ event }) => {
  const eventIcon = getEventIconProps(event);
  const toolTip = event.status === 'scheduled' ? 'Unknown' : capitalize(event.status);
  return (
    <Tooltip title={toolTip}>
      <eventIcon.icon style={{ color: eventIcon.color }} />
    </Tooltip>
  );
};
export default EventStatusIcon;
