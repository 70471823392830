import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import FeatureFlag, { FeatureFlagNotTargeted } from 'src/components/featureflags/featureFlag';
import { getSchemaBridge, getSchema, getRenderer, getSchemaNames } from 'src/components/forms';
import EditableModel from 'src/components/forms/editableModel';
import EditableSectionHeader from 'src/components/forms/editableSectionHeader';
import EventDetailsForm from 'src/components/forms/eventDetailsForm';
import { getLatestVersion } from 'src/components/forms/schemas/versions';
import SelectableModel from 'src/components/forms/selectableModel';
import { ExistingPatientBanner } from 'src/components/pages/eventShow/ExistingPatientBanner';
import { BannerComponent } from 'src/components/pages/eventShow/eventShow.BannerComponent';
import { useStyles } from 'src/components/pages/eventShow/eventShow.styles';
import { SchedulingSuggestionsSection } from 'src/components/pages/pageElements/SchedulingSuggestionsSection';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import EventHeader from 'src/components/pages/pageElements/eventHeader';
import PreVisitDataDisplay from 'src/events/components/PreVisitDataDisplay';
import { SHOW_PRE_VISIT_DATA_DISPLAY } from 'src/featureFlags/currentFlags';
import { ChartInteractionContextProvider } from 'src/nightingale/components/ChartInteraction/ChartInteractionContext';
import { ChartInteractionView } from 'src/nightingale/components/ChartInteraction/ChartInteractionView';
import { SideBySideSnapshotSnackbar } from 'src/nightingale/components/SnapshotSnackbar/SideBySideSnapshotSnackbar';
import { SnapshotSnackbar } from 'src/nightingale/components/SnapshotSnackbar/SnapshotSnackbar';
import { eventIsUneditable, getAllSubTypes } from 'src/shared/util/events';
import type { InstantiatedEvent, EventInstance } from 'src/stores/models/event';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const VisitNotes: React.FC<{
  rootStore: RootStore;
  snackbarComponent?: typeof SnapshotSnackbar | typeof SideBySideSnapshotSnackbar;
}> = ({ rootStore, snackbarComponent = SnapshotSnackbar }) => {
  const {
    events: { event: maybeEvent, eventUpdated },
  } = rootStore;
  const [addingEventResultSection, setAddingEventResultSection] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editingEventDetails, setEditingEventDetails] = useState(false);

  const classes = useStyles({ isNightingale: !!maybeEvent?.isNightingale });

  // Gotta put this after all the hooks fyi
  if (!maybeEvent?.id) {
    return null;
  }

  const event = maybeEvent as InstantiatedEvent;
  const patient = maybeEvent?.patientAttendee;
  const disableEdit = eventIsUneditable(event);

  return (
    <ChartInteractionContextProvider
      patientId={patient?.id ?? undefined}
      interactionReferenceId={event.id}
      interactionKey={event.subType}
      isNightingale={!!event.isNightingale}
      additionalSummarizationContext={{
        event: {
          id: event.id,
          start: event.start,
        },
      }}
    >
      <BannerComponent className={classes.snackbarTop} open={eventUpdated}>
        This event&apos;s status or contents were recently edited in another tab or by another user.
        Your changes will not be saved. Please refresh the page and check the status, or open a new
        tab if you need to copy information to a new visit note.
      </BannerComponent>

      {!event.isNightingale ? (
        <ExistingPatientBanner eventUpdated={eventUpdated} eventResults={event.eventResults} />
      ) : null}

      <div className={classes.contents}>
        <div className={classes.header}>
          <EventHeader event={event} hideRecurrenceDetails />
        </div>
        <div className={classes.visitNotes}>
          <FeatureFlagNotTargeted name={SHOW_PRE_VISIT_DATA_DISPLAY}>
            <EditableSectionHeader
              title="Event Details"
              isEditing={editingEventDetails}
              onEdit={() => setEditingEventDetails(true)}
              onSave={() => {
                /* No handler, button just submits the form via id
              triggering the form's onSave below */
              }}
              onCancel={() => setEditingEventDetails(false)}
              formId="event-details-form"
              disableEdit={disableEdit}
              savingDisabled={eventUpdated}
            />
            <EventDetailsForm
              event={event}
              isEditing={editingEventDetails}
              onSave={async (saveEvent: Partial<EventInstance>) => {
                await rootStore.events.saveEvent(saveEvent);
                setEditingEventDetails(false);
              }}
              onCancel={() => setEditingEventDetails(false)}
              formId="event-details-form"
              savingDisabled={eventUpdated}
            />
          </FeatureFlagNotTargeted>
          <FeatureFlag name={SHOW_PRE_VISIT_DATA_DISPLAY}>
            <EditableSectionHeader
              isEditing={editingEventDetails}
              onEdit={() => setEditingEventDetails(true)}
              onSave={() => {
                /* No handler, button just submits the form via id
              triggering the form's onSave below */
              }}
              onCancel={() => setEditingEventDetails(false)}
              formId="pre-visit-data-display"
              disableEdit={disableEdit}
              savingDisabled={eventUpdated}
            />
            <PreVisitDataDisplay
              event={event}
              isEditing={editingEventDetails}
              onSave={async (saveEvent: Partial<EventInstance>) => {
                await rootStore.events.saveEvent(saveEvent);
                setEditingEventDetails(false);
              }}
              onCancel={() => setEditingEventDetails(false)}
              formId="pre-visit-data-display"
              savingDisabled={eventUpdated}
            />
          </FeatureFlag>
        </div>
        {event.patientResults && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.labels}>Results</TableCell>
                <TableCell className={classes.values} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.labels}>Device Barcode</TableCell>
                <TableCell>{event.patientResults.barcode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.labels}>Device Barcode Image</TableCell>
                <TableCell>
                  {event.patientResults.barcodePhoto && (
                    <img
                      alt="Barcode"
                      width="300"
                      height="auto"
                      src={event.patientResults.barcodePhoto.url}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.labels}>Photos</TableCell>
                <TableCell>
                  {event.patientResults.photos.map(photo => (
                    <img
                      alt="Result Snapshot"
                      key={photo.id}
                      width="100"
                      height="auto"
                      src={photo.url}
                    />
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {event.subType && (
          <div className={classes.visitNotes}>
            {event.eventResults &&
              event.eventResults.length > 0 &&
              event.eventResults.map((result, index) => {
                try {
                  const RenderComponent = getRenderer('eventResult', result.type, result.version);
                  const schemaBridge = getSchemaBridge('eventResult', result.type, result.version);
                  const schema = getSchema('eventResult', result.type, result.version);
                  const allEventSubTypes = getAllSubTypes();
                  const isMandatoryResult =
                    !!event.subType &&
                    allEventSubTypes[event.subType].mandatorySections?.includes(result.type);

                  return (
                    <EditableModel
                      name={result.type}
                      schema={schemaBridge}
                      model={result.results}
                      onSubmit={data => {
                        rootStore.events.updateEventResultAndSave(data, index);
                      }}
                      onDelete={
                        isMandatoryResult || eventUpdated
                          ? null
                          : () => {
                              setSelectedIndex(index);
                              setConfirmDelete(true);
                            }
                      }
                      key={`${result.type}-${result.version}`}
                      disableEdit={disableEdit || schema.disableEdit}
                      disableTooltip={schema.disableEdit && 'This type of form cannot be edited'}
                      savingDisabled={eventUpdated}
                    >
                      <RenderComponent model={result.results || {}} schema={schema} toplevel />
                    </EditableModel>
                  );
                } catch (e) {
                  // This case can occur if an unrecognized schema type or version ends up in the db.
                  // It should be very rare, but it seems better to ignore the section rather than
                  // have the app fall over.
                  return null;
                }
              })}
            {addingEventResultSection && (
              <SelectableModel
                currentSchemaTypes={event.eventResults?.map(result => result.type) || []}
                handleCancel={() => setAddingEventResultSection(false)}
                handleSubmit={(resultType, model) => {
                  rootStore.events.addEventResultAndSave({
                    type: resultType,
                    version: getLatestVersion('eventResult', resultType, null),
                    results: model,
                  });
                }}
                handleSubmitSuccess={() => setAddingEventResultSection(false)}
                labelFn={name => getSchema('eventResult', name).title}
                rendererFn={name => getRenderer('eventResult', name)}
                savingDisabled={eventUpdated}
                schemaBridgeFn={name =>
                  getSchemaBridge('eventResult', name, getLatestVersion('eventResult', name, null))
                }
                schemaFn={name =>
                  getSchema('eventResult', name, getLatestVersion('eventResult', name, null))
                }
                schemaNames={getSchemaNames('eventResult')}
              />
            )}
            {event.isNightingale && (
              <div className={classes.nightingaleInteraction}>
                <ChartInteractionView
                  disableEdit={disableEdit}
                  snackbarComponent={snackbarComponent}
                />
              </div>
            )}
            {!event.isNightingale && !addingEventResultSection && !disableEdit && (
              <Button
                color="secondary"
                variant="contained"
                className={classes.addButton}
                onClick={() => setAddingEventResultSection(true)}
              >
                + Add Section
              </Button>
            )}
          </div>
        )}
        {confirmDelete && (
          <ConfirmDialog
            isDestructive
            onSubmit={() => {
              rootStore.events.deleteEventResultAndSave(selectedIndex);
              setConfirmDelete(false);
            }}
            onCancel={() => setConfirmDelete(false)}
            submitLabel="Delete"
          >
            Are you sure you want to delete this section?
          </ConfirmDialog>
        )}
        {patient && (
          <SchedulingSuggestionsSection
            className={classes.visitNotes}
            event={event}
            patient={patient}
          />
        )}
      </div>
    </ChartInteractionContextProvider>
  );
};

export default inject<typeof VisitNotes>('rootStore')(observer(VisitNotes));
