import { withStyles, Paper } from '@material-ui/core/';
import React from 'react';

const SuggestionList = ({ children, classes }) => (
  <Paper elevation={2} className={classes.suggestionList}>
    {children}
  </Paper>
);

const styles = theme => ({
  suggestionList: {
    zIndex: 1000,
    left: 0,
    right: 0,
    marginTop: theme.spacing(1),
    position: 'absolute',
  },
});

export default withStyles(styles)(SuggestionList);
