import { makeStyles, Snackbar as MuiSnackbar, Theme } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const Snackbar: React.FC<{
  message: string;
  onDismiss: () => void;
  color?: 'primary' | 'error';
}> = ({ message, onDismiss, color = 'primary' }) => {
  const styles = useStyles({ color });

  return (
    <MuiSnackbar
      action={
        <>
          <button
            className={`${styles.button} ${styles.cancelButton}`}
            data-testid="snapshot-snackbar-dismiss"
            type="button"
            onClick={onDismiss}
          >
            Dismiss
          </button>
        </>
      }
      ContentProps={{ className: styles.snackbarContents }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      classes={{ root: styles.root }}
      data-testid="snackbar"
      message={message}
      open
    />
  );
};

export interface StyleProps {
  color: 'primary' | 'error';
}

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    pointerEvents: 'none',

    '& *': {
      pointerEvents: 'auto',
    },
  },
  button: {
    alignItems: 'center',
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    padding: 0,
    textTransform: 'uppercase',
  },
  cancelButton: {
    marginRight: '22px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 4,
  },
  snackbarContents: {
    cursor: 'pointer',
    width: 600,
    marginTop: 60,
    backgroundColor: ({ color }) => (color === 'primary' ? Colors.BlueSpruce : Colors.Coral),
  },
});

export default Snackbar;
