import React, { memo } from 'react';

export const requestTypeMap = {
  FOLLOW_UP: 'Follow-up',
  INTAKE: 'Intake',
  OFT: 'OFT',
  PEER_VISIT: 'Peer Visit',
  REENGAGEMENT: 'Reengagement',
  CASE_MANAGER_VISIT: 'Case Manager Visit',
};

type RequestTypeProps = {
  requestType: string;
};

export const RequestType = memo(({ requestType }: RequestTypeProps) => {
  return <span>{requestTypeMap[requestType] ?? requestType}</span>;
});
