/**
 * The primary source of truth for these colors is Figma. They're enumerated here for ease of
 * reference, but in the event of conflicts, the definitions in Figma take priority.
 * Where colors have a defined role, it's noted, but not all colors have one, and colors that do
 * have a role might also be used in other contexts.
 */
enum Colors {
  /**
   * The most common blue in nightingale components, used for section headers, icons, and many other
   * UI elements.
   */
  BlueSpruce = '#466C8A',

  /**
   * A dark blue used in framing elements.
   */
  BoulderBlue = '#003057',

  /**
   * A reddish-orange used for errors.
   */
  Coral = '#F07562',

  /**
   * Pale blues
   */
  Mist = '#DCE6EB',
  Stillwater = '#98BECE',
  LightCyan = '#E8F4FA',

  /**
   * Medium blues
   */
  Ocean = '#335979',
  SlateBlue = '#6D91AB',

  /**
   * A dark brown used in some headers.
   */
  Taupe = '#696158',

  /**
   * A dark blue used in some headers.
   */
  Navy = '#002441',

  /**
   * An earthy orange used in some headers.
   */
  Terracotta = '#A45014',

  /**
   * A gray used specifically for the patient chart overview.
   */
  ChartGray = '#EDF0F2',

  /**
   * Assorted grays and off-grays.
   */
  White = '#FFFFFF',
  Gray1 = '#F5F5F5',
  Gray2 = '#E6E9EB',
  Gray3 = '#D2D6D9',
  Gray4 = '#ABAFB2',
  Gray5 = '#999DA1',
  Gray6 = '#7E848A',
  Gray7 = '#596066',
  Gray8 = '#363E45',
  Gray9 = '#272B2E',
  Black = '#000000',

  /**
   * A yellow derived from the Coral
   *
   * Used for highlighting chart elements when they are in a warning condition.
   *
   * WCAG AA against Gray7 and darker, AAA at Gray8:
   *   https://webaim.org/resources/contrastchecker/?fcolor=596066&bcolor=F0DE62
   *   https://webaim.org/resources/contrastchecker/?fcolor=363E45&bcolor=F0DE62
   *
   * Calculated via https://color.adobe.com/create/color-wheel. Square harmony, no further modifications.
   */
  YellowUiWarn = '#F0DE62',

  /**
   * A yellow derived from the Coral
   *
   * Used for coloring patient highlights when they are in a warning condition.
   */
  Ronchi = '#EFC44C',

  /**
   * A green derived from the Coral
   *
   * Used for coloring patient highlights when they are in a success condition.
   *
   * Derived from our Coral color, but with only the hue adjusted, using the HSLuv color space (tool
   * here: https://www.hsluv.org/)
   */
  FruitSalad = '#43AF47',

  /** Possibly part of a new color scheme for the staff app, might delete later. */
  NewLightRed = '#f9e6e8',

  /** Possibly part of a new color scheme for the staff app, might delete later. */
  NewRed = '#9e0012',

  /** Possibly part of a new color scheme for the staff app, might delete later. */
  NewDarkGrey = '#3d3934',

  Cream = '#FDF8E0',
  Bronze = '#775f00',

  /** Called the same elsewhere in the app */
  Blue3 = '#AEC5D4',
  Blue4 = '#507796',

  Stormcloud = '#CED8E0',
}

export default Colors;
