import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import classNames from 'classnames';
import { format } from 'date-fns';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';

import { getDisplayName } from 'src/components/pages/patientShow/documents/displayHelpers';
import type { ChartDocument } from 'src/components/pages/patientShow/documents/types';
import useTableStyles from 'src/components/pages/patientShow/documents/useTableStyles';
import { parseUnknownDate } from 'src/util/parseUnknownDate';

const SORTBY = {
  title: (doc: ChartDocument) => (getDisplayName(doc) || '').toLowerCase(),
  type: (doc: ChartDocument) => doc.type,
  date: (doc: ChartDocument) => parseUnknownDate(doc.date),
};

const DocumentsTable: React.FC<{
  chartDocuments: ChartDocument[];
  onDelete: (doc: ChartDocument) => void;
  onEdit: (doc: ChartDocument) => void;
  onSelect: (doc: ChartDocument) => void;
  onSend: (doc: ChartDocument) => void;
}> = ({ chartDocuments, onDelete, onEdit, onSelect, onSend }) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('date');

  const styles = useStyles();
  const tableStyles = useTableStyles();

  const changeSort = field => {
    if (field === orderBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrder('asc');
      setOrderBy(field);
    }
  };

  const sortedDocuments = sortBy(chartDocuments, SORTBY[orderBy || 'date']);
  if (order === 'desc') {
    sortedDocuments.reverse();
  }

  return (
    <Table className={tableStyles.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classNames(tableStyles.tableHead, styles.documentsColumn)}>
            <TableSortLabel
              active={orderBy === 'title'}
              direction={order}
              onClick={() => changeSort('title')}
            >
              Title
            </TableSortLabel>
          </TableCell>
          <TableCell className={classNames(tableStyles.tableHead, styles.documentsColumn)}>
            <TableSortLabel
              active={orderBy === 'description'}
              direction={order}
              onClick={() => changeSort('description')}
            >
              Description
            </TableSortLabel>
          </TableCell>
          <TableCell className={classNames(tableStyles.tableHead, styles.documentsColumn)}>
            <TableSortLabel
              active={orderBy === 'type'}
              direction={order}
              onClick={() => changeSort('type')}
            >
              Type
            </TableSortLabel>
          </TableCell>
          <TableCell className={classNames(tableStyles.tableHead, styles.documentsColumn)}>
            <TableSortLabel
              active={orderBy === 'date'}
              direction={order}
              onClick={() => changeSort('date')}
            >
              Date
            </TableSortLabel>
          </TableCell>
          <TableCell className={classNames(tableStyles.tableHead, styles.documentsColumn)} />
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedDocuments.map((doc, idx) => (
          <TableRow
            key={doc.file && doc.title ? doc.file.id + doc.title : `document-${idx}`}
            className={tableStyles.tableRow}
            onClick={() => onSelect(doc)}
          >
            <TableCell className={tableStyles.tableCell}>{getDisplayName(doc)}</TableCell>
            <TableCell className={tableStyles.tableCell}>{doc.description}</TableCell>
            <TableCell className={classNames(tableStyles.tableCell, styles.typeData)}>
              {doc.type?.replace('_', ' ')}
            </TableCell>
            <TableCell className={tableStyles.tableCell}>
              {format(parseUnknownDate(doc.date), 'PP')}
            </TableCell>
            <TableCell>
              <menu className={tableStyles.tableActions}>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    onEdit(doc);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <Tooltip title="Message document link to patient">
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      onSend(doc);
                    }}
                  >
                    <SendIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(doc);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </menu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles({
  documentsColumn: {
    width: '20%',
  },
  typeData: {
    textTransform: 'capitalize',
  },
});

export default DocumentsTable;
