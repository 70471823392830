import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';

import { ChartPropertyMenu } from 'src/nightingale/components/ChartProperty/ChartProperty.Menu';
import {
  AnyControlProps,
  ControlFactory,
  createControlProps,
} from 'src/nightingale/components/ChartProperty/controls/ControlFactory';
import { ListControlProps } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.types';
import { TextControl } from 'src/nightingale/components/ChartProperty/controls/Text/TextControl';
import { ChartPropertyHistoryModal } from 'src/nightingale/components/ChartPropertyHistory/ChartPropertyHistory.Modal';
import {
  getEmptyValueForChartProperty,
  isEmptyValue,
} from 'src/nightingale/data/ChartProperty.utils';
import useRequiredChartProperties from 'src/nightingale/requiredChartProperties/useRequiredChartProperties';
import { ChartPropertyTypes, ChartPropertyWithValue } from 'src/nightingale/types/types';
import type { AnyChartProperty, ChartPropertyValue } from 'src/nightingale/types/types';

/**
 * Styles
 */
const useStyles = makeStyles({
  container: {
    border: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
  },
  menuContainer: {
    height: '18px',
    width: '100%',
    position: 'absolute',
    top: 0,
    '&:hover': {
      '& .three-dot-icon': {
        opacity: 0.5,
      },
    },
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  notes: {
    marginTop: 16,
  },
  cardHeaderContainer: {
    padding: 0,
  },
});

const NonedProperty: React.FC<{
  isRequired?: boolean;
  label: string;
}> = ({ isRequired, label }) => {
  return (
    <TextControl
      isRequired={isRequired}
      label={label}
      name={`none-${label}`}
      value="None"
      disabled
    />
  );
};

const Notes: React.FC<{
  disabled?: boolean;
  id?: string;
  name: string;
  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
}> = ({ disabled, id, name, note, setNote }) => {
  const styles = useStyles();

  return (
    <div className={styles.notes}>
      <TextControl
        disabled={disabled}
        readOnly={disabled}
        label="Notes"
        id={id ? `${id}-notes` : undefined}
        name={`${name}-notes`}
        onChangeValue={setNote}
        value={note}
      />
    </div>
  );
};

/**
 * ChartProperty Composition Factory
 */
export const ChartProperty: React.FC<AnyChartProperty> = chartProperty => {
  const {
    afterMarkAsNone,
    allowNone,
    alwaysShowNotes,
    default: defaultValue,
    disabled,
    id,
    isEmpty,
    label,
    name,
    notes,
    onError,
    setPropertyValue,
    type,
    value,
  } = chartProperty;

  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [previouslyFocusedElement, setPreviousFocusedElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setPreviousFocusedElement(document.activeElement as HTMLElement);
  }, []);
  useEffect(() => {
    if (!showHistoryModal && previouslyFocusedElement) previouslyFocusedElement.focus();
  }, [showHistoryModal, previouslyFocusedElement]);

  const styles = useStyles();

  const [showNotes, setShowNotes] = useState(false);

  // Keep track of internal values, ie. controlled components
  const [isListEditing, setIsListEditing] = useState<boolean>(false);
  const isDisabled = !!disabled;

  const requiredChartProperties = useRequiredChartProperties();

  const secureSetPropertyValue = useCallback(
    (cpv: Partial<Pick<ChartPropertyValue, 'value' | 'notes' | 'isEmpty'>>) => {
      if (isDisabled) return;
      if (!setPropertyValue) return;
      setPropertyValue(cpv);
    },
    [isDisabled, setPropertyValue],
  );

  /**
   * Compose control props
   */
  const controlProps: AnyControlProps & {
    isInteraction?: boolean;
    onChangeMode?: React.Dispatch<React.SetStateAction<boolean>>;
  } = {
    ...createControlProps(chartProperty),
    disabled: isDisabled,
    // We set the value already (if one was available), so it's okay to cast
    hasEmptyValue: requiredChartProperties.isMissing(chartProperty as ChartPropertyWithValue),
    isRequired: requiredChartProperties.isRequired(chartProperty.name),
    onChangeValue: newValue => {
      secureSetPropertyValue({ value: newValue });
    },
    onError,
    readOnly: isDisabled,
    value: value ?? getEmptyValueForChartProperty(type, defaultValue?.value),
  };

  if (type === ChartPropertyTypes.List) {
    controlProps.onChangeMode = setIsListEditing;
    (controlProps as ListControlProps).listOrder = chartProperty.listOrder;
    (controlProps as ListControlProps).interactionId = chartProperty.interactionId;
  }

  const isNoneable = (allowNone || isEmpty) && !isDisabled;
  const [initialNoteValue] = useState(notes);
  const showingNotes = alwaysShowNotes || showNotes || !!initialNoteValue;

  return (
    <fieldset className={styles.container} data-testid="chart-property">
      <>
        {!isEmpty ? (
          <ControlFactory type={type} props={controlProps} />
        ) : (
          <NonedProperty isRequired={controlProps.isRequired} label={label as string} />
        )}
        {showingNotes && !isListEditing ? (
          <Notes
            disabled={isDisabled}
            id={id}
            name={name}
            note={notes || getEmptyValueForChartProperty(ChartPropertyTypes.Text)}
            setNote={note => {
              secureSetPropertyValue({ notes: note as string });
            }}
          />
        ) : null}
      </>

      <div className={styles.menuContainer}>
        <ChartPropertyMenu
          showingNotes={showingNotes}
          setShowNotes={setShowNotes}
          allowNone={!!isNoneable}
          isEmpty={!!isEmpty}
          isEmptyValue={isEmptyValue(value)}
          markAsNone={() => {
            secureSetPropertyValue({ isEmpty: !isEmpty });
            afterMarkAsNone?.();
          }}
          setShowHistoryModal={() => {
            setShowHistoryModal(true);
          }}
        />
      </div>

      {showHistoryModal ? (
        <ChartPropertyHistoryModal
          setShowHistoryModal={setShowHistoryModal}
          updateProperty={history => {
            secureSetPropertyValue(history);
            setShowNotes(!!history.notes);
          }}
          definition={chartProperty}
          readOnly={isDisabled}
        />
      ) : null}
    </fieldset>
  );
};
