import { connect } from 'formik';
import React from 'react';

import { CollapsibleFindATimeControl } from 'src/components/forms/resources/editEvent.CollapsibleFindATimeControl';
import { FindATimeProps } from 'src/scheduling/components/findATime';

const FindATime = connect<
  Record<string, never>,
  Pick<FindATimeProps, 'attendees' | 'duration' | 'start'>
>(({ formik }) => (
  <CollapsibleFindATimeControl {...formik.values} setFieldValue={formik.setFieldValue} />
));

export default FindATime;
