// Version imports
import v100 from 'src/components/forms/schemas/eventResult/careAdvocateSynchronousMam/1.0.0';
import v110 from 'src/components/forms/schemas/eventResult/careAdvocateSynchronousMam/1.1.0';

export default {
  latest: v110,
  // All Versions
  '1.1.0': v110,
  '1.0.0': v100,
};
