import { differenceInMinutes } from 'date-fns';
import React from 'react';

import { EventTiming } from 'src/myDayToday/components/useScheduledEventTiming';

export const ScheduledEventCardHeader = (props: {
  currentTime: Date;
  start: Date;
  timing: EventTiming;
}) => {
  const minuteDifference = differenceInMinutes(props.start, props.currentTime);

  if (props.timing === EventTiming.Imminent) {
    return (
      <div>
        Starts <span className="time">now</span>
      </div>
    );
  }

  if (props.timing === EventTiming.Late) {
    return (
      <div>
        Started <span className="time">{Math.abs(minuteDifference)} min ago</span>
      </div>
    );
  }

  return (
    <div>
      Starts in <span className="time">{minuteDifference} min</span>
    </div>
  );
};
