/**
 * Context for notifying other components that any pebble has been updated in any way.
 *
 * The value isn't important; this serves more like an event emitter that components can track to
 * update themselves. A Date is just something that's easily updated with a new unique value.
 */
import React, { useState } from 'react';

export const PebblesUpdateContext = React.createContext<{
  lastUpdate: Date | undefined;
  updateLastUpdate: () => void;
}>({
  lastUpdate: undefined,
  updateLastUpdate: () => {},
});

export const PebblesUpdateContextProvider: React.FC = ({ children }) => {
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>(undefined);
  return (
    <PebblesUpdateContext.Provider
      value={{
        lastUpdate,
        updateLastUpdate: () => {
          setLastUpdate(new Date());
        },
      }}
    >
      {children}
    </PebblesUpdateContext.Provider>
  );
};
