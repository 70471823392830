import React, { createContext, useState } from 'react';

export type MiniEvent = {
  allDay: boolean;
  duration: number | null;
  id: string;
  start: Date;
};

type EventsByPatient = Record<string, MiniEvent[]>;

const HISTORY_LENGTH = 3;

export const LastPatientVisitContext = createContext<{
  addLastPatientVisit: (patientId: string, event: MiniEvent) => void;
  lastPatientVisits: EventsByPatient;
}>({
  addLastPatientVisit: () => {},
  lastPatientVisits: {},
});

export const LastPatientVisitContextProvider: React.FC = ({ children }) => {
  const [lastPatientVisits, setLastPatientVisits] = useState<EventsByPatient>({});

  const addLastPatientVisit = (patientId: string, event: MiniEvent) => {
    const events = lastPatientVisits[patientId]?.length
      ? lastPatientVisits[patientId].slice(-HISTORY_LENGTH)
      : [];
    if (events.some(e => e.id === event.id)) return;

    setLastPatientVisits({
      ...lastPatientVisits,
      [patientId]: [event, ...events.slice(-(HISTORY_LENGTH - 1))],
    });
  };

  return (
    <LastPatientVisitContext.Provider
      value={{
        addLastPatientVisit,
        lastPatientVisits,
      }}
    >
      {children}
    </LastPatientVisitContext.Provider>
  );
};
