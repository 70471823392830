/**
 * Mobx-state-tree types that represent our core graphql types. This could eventually be generated
 * from the graphql schema; it's hardcoded for now to save time.
 */
import { parseISO, format, isValid } from 'date-fns';
import { types, Instance, SnapshotOrInstance } from 'mobx-state-tree';

import { TEAM_ROLE_OPTIONS } from 'src/components/forms/schemas/definitions';

export const DateType = types.custom<string, Date>({
  name: 'Date',
  fromSnapshot(value) {
    let parsed = parseISO(value);
    if (!isValid(parsed)) {
      console.warn(`Couldn't parse value as ISO date, falling back to new Date(value): "${value}"`);
      parsed = new Date(value);
    }
    return parsed;
  },
  toSnapshot(value) {
    return value.toISOString();
  },
  isTargetType(value) {
    return value instanceof Date;
  },
  getValidationMessage(value) {
    return !Number.isNaN(Date.parse(value)) ? '' : `${value} doesn't look like a date`;
  },
});

// YYYY-MM-DD string
export const DateOnlyType = types.custom<string, string>({
  name: 'DateOnly',
  fromSnapshot(value) {
    return value;
  },
  toSnapshot(value) {
    return value;
  },
  isTargetType(value) {
    return true; // snapshot is equivalent to target
  },
  getValidationMessage(value) {
    return format(parseISO(value), 'yyyy-MM-dd') === value
      ? ''
      : `${value} must be formatted yyyy-MM-dd`;
  },
});

export type Recurrence = Instance<typeof Recurrence>;
export const Recurrence = types.model('Recurrence').props({
  type: types.string,
  freq: types.string,
  interval: types.maybeNull(types.integer),
  byDay: types.maybeNull(types.array(types.integer)),
  byMonthDay: types.maybeNull(types.integer),
  bySetPos: types.maybeNull(types.integer),
  byDayForMonth: types.maybeNull(types.integer),
  until: types.maybeNull(DateOnlyType),
});

/**
 * Every item extends Updatable to allow simple writes.
 */
export const Updatable = types.model('Updatable', {}).actions(self => ({
  /**
   * Assign a set of values to the object.
   */
  update(updates: unknown) {
    Object.assign(self, updates);
  },
}));

export type File = Instance<typeof File>;
export const File = types.model('File', {
  id: types.maybeNull(types.string),
  filename: types.maybeNull(types.string), // always set, but may not requested if not needed
  url: types.string,
});

const Address = types.model({
  street1: types.maybeNull(types.string),
  street2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  zip: types.maybeNull(types.string),
});

// We define this type to avoid circular type references within the User model
export type UserNames = {
  preferredName?: string | null;
  firstName: string | null;
  lastName: string | null;
};

export type UserNamesWithType = UserNames & {
  __typename: string | null;
};

export function getPreferredFirstName(user: UserNames): string | null {
  return user.preferredName || user.firstName;
}

export function getExactFirstName(user: UserNames): string | null {
  return user.preferredName ? `${user.preferredName} [${user.firstName}]` : user.firstName;
}

export function getFullName(user: UserNames): string {
  return user.preferredName
    ? `${user.firstName} "${user.preferredName}" ${user.lastName}`
    : `${user.firstName} ${user.lastName}`;
}

export function getPreferredFullName(user: UserNames): string {
  return `${getPreferredFirstName(user)} ${user.lastName}`;
}

export function getExactFullName(user: UserNames): string {
  return `${getExactFirstName(user)} ${user.lastName}`;
}

export function getPreferredFullNameWithType(user: UserNamesWithType): string {
  return `${getPreferredFullName(user)} (${user.__typename})`;
}

export function getExactFullNameWithType(user: UserNamesWithType): string {
  return `${getExactFullName(user)} (${user.__typename})`;
}

export type User = Instance<typeof User>;
export const User = Updatable.named('User')
  .props({
    id: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    preferredName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    avatar: types.maybeNull(File),
    timezone: types.maybeNull(types.string),
    pronouns: types.maybeNull(types.string),
    __typename: types.maybeNull(types.string), // TODO clean this up
  })
  .views(self => ({
    get preferredFirstName(): string | null {
      return getPreferredFirstName(self);
    },
    get exactFirstName(): string | null {
      return getExactFirstName(self);
    },
    get preferredFullName(): string {
      return getPreferredFullName(self);
    },
    get exactFullName(): string {
      return getExactFullName(self);
    },
    get preferredFullNameWithType(): string {
      return getPreferredFullNameWithType(self);
    },
    get exactFullNameWithType(): string {
      return getExactFullNameWithType(self);
    },
  }));

const rolesToFilter = {
  care_advocate: 'coordinator',
  peer_coach: 'peer',
  clinician: 'prescriber',
  case_manager: 'caseManager',
};

export const providerTeamRoles = Object.keys(TEAM_ROLE_OPTIONS);

export type Provider = Instance<typeof Provider>;
export const MonitoredProvider = User.named('MonitoredProvider').props({
  patientFacingDisplayName: types.maybeNull(types.string),
  unreadPatientConversationCount: types.maybeNull(types.number),
});
export const Provider = User.named('Provider')
  .props({
    middleName: types.maybeNull(types.string),
    dob: types.maybeNull(DateOnlyType),
    address: types.maybeNull(Address),
    npi: types.maybeNull(types.string),
    taxId: types.maybeNull(types.string),
    taxonomyCode: types.maybeNull(types.string),
    providersMonitored: types.maybeNull(types.array(MonitoredProvider)),
    teamRole: types.maybeNull(types.enumeration(providerTeamRoles)),
    isInactive: types.maybeNull(types.boolean),
    bio: types.maybeNull(types.string),
    patientFacingDisplayName: types.maybeNull(types.string),
    unreadMessageNotifications: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get teamRolePropertyName(): string | null {
      return self.teamRole && rolesToFilter[self.teamRole];
    },
  }));

const TeamActivity = types.model({
  latestCompletedVisit: types.maybeNull(
    types.model({
      start: types.maybeNull(DateType),
      status: types.maybeNull(types.string),
      scheduleChangeReason: types.maybeNull(types.string),
      scheduleChangeNotes: types.maybeNull(types.string),
    }),
  ),
  latestMissedVisit: types.maybeNull(
    types.model({
      start: types.maybeNull(DateType),
      status: types.maybeNull(types.string),
      scheduleChangeReason: types.maybeNull(types.string),
      scheduleChangeNotes: types.maybeNull(types.string),
    }),
  ),
  nextVisit: types.maybeNull(
    types.model({
      start: types.maybeNull(DateType),
    }),
  ),
});

const PrescriberActivity = TeamActivity.named('PrescriberActivity');
const PeerActivity = TeamActivity.named('PeerActivity');
const CoordinatorActivity = TeamActivity.named('CoordinatorActivity');

export type Patient = Instance<typeof Patient>;
export const Patient = User.named('Patient').props({
  // General
  dob: types.maybeNull(DateOnlyType),
  enrollmentStatus: types.maybeNull(
    types.model({
      status: types.maybeNull(types.string),
      notes: types.maybeNull(types.string),
    }),
  ),
  clinicalCarePlan: types.maybeNull(
    types.model({
      plan: types.maybeNull(types.string),
      currentRx: types.maybeNull(types.string),
      notes: types.maybeNull(types.string),
    }),
  ),
  careAdvocateCarePlan: types.maybeNull(
    types.model({
      oftPlan: types.maybeNull(types.string),
      mamPlan: types.maybeNull(types.string),
      notes: types.maybeNull(types.string),
    }),
  ),
  peerCoachCarePlan: types.maybeNull(
    types.model({
      plan: types.maybeNull(types.string),
      notes: types.maybeNull(types.string),
    }),
  ),
  prescriberActivity: types.maybeNull(PrescriberActivity),
  peerActivity: types.maybeNull(PeerActivity),
  coordinatorActivity: types.maybeNull(CoordinatorActivity),
  fyi: types.maybeNull(types.string),

  // Contact
  consentsToVoicemails: types.maybeNull(types.string),
  homeAddress: types.maybeNull(Address),
  mailingAddress: types.maybeNull(Address),

  // Team
  prescriber: types.maybeNull(Provider),
  peer: types.maybeNull(Provider),
  coordinator: types.maybeNull(Provider),
  caseManager: types.maybeNull(Provider),

  preferenceSendHrqolSurveys: types.optional(types.boolean, false, [undefined, null]),

  treatmentAgreement: types.maybeNull(
    types.model({
      createdAt: DateType,
      documentVersion: types.string,
      completedAt: types.maybeNull(DateType),
    }),
  ),
  authenticationBlocked: types.maybeNull(types.boolean),
});

/**
 * Mobx-state-tree needs a way to figure out the type of a given object. Use the built-in
 * graphql __typename.
 */
const dispatcher = (snapshot: SnapshotOrInstance<typeof User>) =>
  snapshot && snapshot.__typename === 'Patient' ? Patient : Provider;

export type Resource = Instance<typeof Resource>;
export const Resource = types.union({ dispatcher }, Provider, Patient);

export type Document = Instance<typeof Document>;
export const Document = types.model('Document').props({
  key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.integer),
  doctype: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
});

export default Resource;
