import { makeStyles, Theme } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

type StyleProps = {
  hasHeader: boolean;
  isHighlighted: boolean;
  isRemovable: boolean;
};

export const useStyles = makeStyles<Theme, StyleProps>({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },

  container: {
    position: `relative`,
    paddingTop: 5,
    paddingBottom: 5,
    animation: `$fadeIn 500ms ease-out`,

    '&::before': {
      backgroundColor: ({ isHighlighted }) => (isHighlighted ? Colors.Mist : Colors.ChartGray),
      opacity: ({ isHighlighted }) => (isHighlighted ? 1 : 0),
      bottom: 0,
      content: `''`,
      display: `block`,
      left: -5,
      position: `absolute`,
      right: -5,
      top: 0,
      transition: `0.1s`,
      zIndex: 0,
    },
  },

  content: {
    paddingTop: ({ hasHeader }) => (hasHeader ? 0 : 25),
    position: `relative`,
  },

  header: {
    width: ({ isRemovable }) => (isRemovable ? 'calc(100% - 25px)' : '100%'),
  },

  removeBtn: {
    backgroundColor: ({ isHighlighted }) => (isHighlighted ? Colors.Mist : 'transparent'),
    border: `none`,
    boxSizing: `border-box`,
    cursor: `pointer`,
    display: `block`,
    height: 18,
    overflow: `hidden`,
    padding: 0,
    position: `absolute`,
    right: 0,
    textIndent: `100%`,
    top: 1,
    transition: `0.1s`,
    whiteSpace: `nowrap`,
    width: 25,

    '&::before, &::after': {
      content: `''`,
      display: `block`,
      position: `absolute`,
      transition: `0.1s`,
    },

    '&::before': {
      border: `2px solid`,
      borderColor: ({ isHighlighted }) => (isHighlighted ? Colors.BlueSpruce : Colors.Stillwater),
      borderRadius: `100%`,
      height: 14,
      right: 0,
      top: 0,
      width: 14,
    },

    '&::after': {
      backgroundColor: ({ isHighlighted }) =>
        isHighlighted ? Colors.BlueSpruce : Colors.Stillwater,
      height: 2,
      right: 5,
      top: `50%`,
      transform: `translateY(-50%)`,
      width: 8,
    },
  },
});
