export default {
  type: 'object',
  title: 'Prescriber: Assessment (Initial)',
  disabled: true,
  properties: {
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
