import GraphqlAdapter from 'src/data/types/GraphqlAdapter';
import { LOAD_FIND_A_TIME } from 'src/scheduling/queries/findATime';
import { FindATimeEvent, FindATimeEvents } from 'src/scheduling/types/findATime';

/**
 * Service for retrieving data needed by Find-a-Time
 */
export default class FindATimeService {
  constructor(private graphql: GraphqlAdapter) {}

  /**
   * Queries and transforms events for the Find-a-Time feature
   *
   * @param attendees The IDs of each provider to include
   * @param after Beginning of search window (inclusive)
   * @param before End of search window (exclusive)
   */
  async getFindATimeEvents(
    attendees: string[],
    after: Date,
    before: Date,
  ): Promise<FindATimeEvents> {
    const {
      data: { events },
    } = await this.graphql.query({
      query: LOAD_FIND_A_TIME,
      variables: {
        attendees,
        start_gte: after,
        start_lt: before,
      },
    });

    const eventsByAttendee: FindATimeEvents = {};
    events?.forEach((event: FindATimeEvent) => {
      event.attendees.forEach(attendee => {
        if (!attendees.includes(attendee.id)) {
          return;
        }

        if (!eventsByAttendee[attendee.id]) {
          eventsByAttendee[attendee.id] = [];
        }

        eventsByAttendee[attendee.id].push(event);
      });
    });

    return eventsByAttendee;
  }
}
