import { makeStyles } from '@material-ui/core';
import React from 'react';

import ScheduleQueue from 'src/dropInClinic/components/ScheduleQueue';
import ScheduleTimeline from 'src/dropInClinic/components/ScheduleTimeline/ScheduleTimeline';
import UnscheduledRequests from 'src/dropInClinic/components/UnscheduledRequests';
import type { ScheduleLog } from 'src/dropInClinic/hooks/useScheduleLogs';

const ClinicScheduleLog = ({ log }: { log: ScheduleLog['log'] }) => {
  const styles = useStyles();
  const { scheduleMap, queueBeforePrioritization, prioritizedQueue, unscheduledRequests } = log;

  return (
    <div className={styles.container}>
      <div>
        <div>
          <h3>Initial Queue</h3>
          <ScheduleQueue queue={queueBeforePrioritization} />
        </div>
        <div>
          <h3>Prioritized Queue</h3>
          <ScheduleQueue queue={prioritizedQueue} />
        </div>
        <div>
          <h3>Unscheduled Requests</h3>
          <UnscheduledRequests unscheduledRequests={unscheduledRequests} />
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <h3>Schedule Map</h3>
        <ScheduleTimeline scheduleMap={scheduleMap} queue={prioritizedQueue} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
    padding: 12,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& > :first-child': {
      paddingRight: 12,
    },
    '& .tooltip-content': {
      padding: 10,
    },
  },
});

export default ClinicScheduleLog;
