import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import innerText from 'react-innertext';

class FieldGroup extends React.Component<
  {
    label: string;
    children: any;
    classes: any;
    alwaysShow: boolean;
    noUpperCase: boolean;
  },
  {
    hasChildren: boolean;
  }
> {
  childrenRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.childrenRef = React.createRef();
    this.state = {
      hasChildren: true,
    };
  }

  componentDidMount() {
    // If the resulting string for the children is empty then hide the field.
    if (this.childrenRef.current && this.childrenRef.current?.textContent?.trim() === '') {
      this.setState({
        hasChildren: false,
      });
    }
  }

  render() {
    const { label, children, classes, alwaysShow, noUpperCase } = this.props;
    const { hasChildren } = this.state;

    const renderLabel = React.isValidElement(label) ? innerText(label) : label;

    return !children || (!hasChildren && !alwaysShow) ? null : (
      <div className={classes.container}>
        <div className={noUpperCase ? classes.label : classNames(classes.label, classes.upperCase)}>
          {renderLabel}
        </div>
        <div className={classes.value}>
          <div ref={this.childrenRef}>{children || '--'}</div>
        </div>
      </div>
    );
  }
}

const styles: Styles<Theme, any> = () => ({
  container: {
    marginTop: 20,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    // The labels in the form are 16px normally, then transformed by 0.75 when shrunk
    fontSize: 16 * 0.75,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  value: {
    marginTop: 5,
    '& ul': {
      margin: 0,
    },
  },
});

export default withStyles(styles)(FieldGroup);
