import { Provider } from 'src/shared/stores/resource';
import useRootStore from 'src/stores/useRootStore';

export default function useMyDayToday() {
  const rootStore = useRootStore();
  const {
    auth: { user },
  } = rootStore;

  return (user as Provider)?.teamRole === 'clinician';
}
