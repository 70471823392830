import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type ApolloClient from 'apollo-client';
import { Subscription } from 'apollo-client/util/Observable';
import React, { useEffect, useRef, useState } from 'react';

import PatientPebblesCountContext from 'src/pebbles/components/PatientPebblesCountContext/PatientPebblesCountContext';
import {
  PEBBLE_COUNT_QUERY,
  PebblesCountGqlResult,
  SUBSCRIBE_TO_PATIENT_PEBBLE_UPDATED,
  PatientPebbleUpdatedSubscriptionResult,
} from 'src/pebbles/queries';
import useApolloClient from 'src/shared/client/useApolloClient';
import logger from 'src/shared/util/logger';

const PatientPebblesCountProvider: React.FC<{ patientId: string }> = ({ patientId, children }) => {
  const [count, setCount] = useState(0);
  const subscription = useRef<Subscription>();

  const apolloClient = useApolloClient();

  useEffect(() => {
    const server = new ServerHelper(apolloClient);
    server
      .fetchCount(patientId)
      .then(c => setCount(c))
      .catch(err => console.error(err));
  }, [apolloClient]);

  useEffect(() => {
    const server = new ServerHelper(apolloClient);
    subscription.current = server.subscribeToCountsTotal(patientId, total => setCount(total));

    // Only need to destruct if we subscribe
    // eslint-disable-next-line consistent-return
    return () => {
      subscription?.current?.unsubscribe();
    };
  }, [apolloClient]);

  return (
    <PatientPebblesCountContext.Provider value={{ count }}>
      {children}
    </PatientPebblesCountContext.Provider>
  );
};

/**
 * Encapsulates server interactions in order to keep the component clean and tidy
 */
class ServerHelper {
  constructor(private graphql: ApolloClient<NormalizedCacheObject>) {}

  /**
   * Fetches the number of open pebbles for a patient
   */
  async fetchCount(patientId: string): Promise<number> {
    const { data: pebblesCountResult } = await this.graphql.query<PebblesCountGqlResult>({
      query: PEBBLE_COUNT_QUERY,
      variables: {
        participantId: patientId,
      },
    });
    const openPebblesCount = !pebblesCountResult ? 0 : pebblesCountResult.pebblesCount;

    return openPebblesCount;
  }

  subscribeToCountsTotal(patientId: string, listener: (count: number) => void) {
    return this.graphql
      ?.subscribe<PatientPebbleUpdatedSubscriptionResult>({
        query: SUBSCRIBE_TO_PATIENT_PEBBLE_UPDATED,
      })
      .subscribe({
        next: async ({ data }) => {
          if (!data) return;

          const {
            patientPebbleUpdated: { patientId: updatedPebblePatientId },
          } = data;

          // Only update count if current patient matches the patient in the updated pebble.
          if (updatedPebblePatientId === patientId) {
            const updatedCount = await this.fetchCount(patientId);

            listener(updatedCount);
          }
        },
        error(err) {
          logger.error('Received error getting patient pebble counts', err);
        },
      });
  }
}

export default PatientPebblesCountProvider;
