import gql from 'graphql-tag';

import { PATIENT_SUMMARY } from 'src/stores/fragments/patients';
import { USER_SUMMARY_LIMITED } from 'src/stores/fragments/users';

export const PEBBLE_LIMITED = gql`
  fragment PebbleLimited on Pebble {
    id
    createdAt
    createdBy {
      ...UserSummaryLimited
    }
    updatedAt
    updatedBy {
      ...UserSummaryLimited
    }
    title
    assignee {
      ...UserSummaryLimited
    }
    participant {
      ...PatientSummary
    }
    status
    priority
    topic
    note
    reminder
    link
  }
  ${PATIENT_SUMMARY}
  ${USER_SUMMARY_LIMITED}
`;

export const PEBBLE_ALL = gql`
  fragment PebbleAll on Pebble {
    ...PebbleLimited
    monitors {
      ...UserSummaryLimited
    }
    history {
      title {
        updatedAt
        updatedBy {
          ...UserSummaryLimited
        }
        value
      }
      assignee {
        updatedAt
        updatedBy {
          ...UserSummaryLimited
        }
        value {
          ...UserSummaryLimited
        }
      }
      status {
        updatedAt
        updatedBy {
          ...UserSummaryLimited
        }
        value
      }
      note {
        updatedAt
        updatedBy {
          ...UserSummaryLimited
        }
        value
      }
    }
  }
  ${PEBBLE_LIMITED}
  ${USER_SUMMARY_LIMITED}
`;
