import gql from 'graphql-tag';

export const CLAIM_ROW = gql`
  fragment ClaimRow on Claim {
    id
    copyableJson {
      json
      errors {
        fieldDescription
        errorMessage
      }
    }
    billingProvider {
      address {
        streetLine1
        streetLine2
        city
        state
        zip
      }
      firstName
      lastName
      middleName
      npi
      signatureOnFile
      taxId
    }
    changeControlNumber
    chargeAmount
    cptCodes
    eventId
    eventSubType
    filingCode
    hasReleaseOfInformation
    icd10Codes
    meta {
      status
      type
    }
    patientId
    patientInformation {
      address {
        streetLine1
        streetLine2
        city
        state
        zip
      }
      controlNumber
      dateOfBirth
      firstName
      isDeceased
      lastName
      relationshipToSubscriberCode
      sex
    }
    payerInformation {
      address {
        streetLine1
        streetLine2
        city
        state
        zip
      }
      groupNumber
    }
    payor {
      key
      name
    }
    renderingProviderInformation {
      firstName
      lastName
      middleName
      npi
      taxId
    }
    section24 {
      datesOfService
    }
    subscriberInformation {
      address {
        streetLine1
        streetLine2
        city
        state
        zip
      }
      dateOfBirth
      firstName
      lastName
      sex
      subscriberId
    }
    timezone
    localDate
  }
`;
