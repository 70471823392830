import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React, { MouseEventHandler, useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { TURN_OFF_PEBBLES } from 'src/featureFlags/currentFlags';
import Colors from 'src/nightingale/Colors';
import type { Pebble as PebbleType } from 'src/pebbles/types';
import type { PebbleType as MobxPebbleType } from 'src/stores/pebbles/domain';

export type EditPebbleFormProps = {
  item?: Partial<PebbleType | MobxPebbleType>;
  buttonSize?: 'large' | 'medium' | 'small';
  cancelDisabled?: boolean;
  formId?: string;
  onCancel?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onDelete?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onSave?: (values: unknown) => Promise<unknown>;
  resetAfterCancel?: boolean;
  resetAfterSave?: boolean;
  saveButtonText?: string;
  savingDisabled?: boolean;
  validationSchema?: any;
  warningMessage?: string;
};

const EditPebbleForm: React.FC<EditPebbleFormProps> = ({
  buttonSize = 'medium',
  cancelDisabled,
  children,
  formId,
  item,
  onCancel,
  onDelete,
  onSave,
  resetAfterCancel = false,
  resetAfterSave = false,
  saveButtonText = 'Save',
  savingDisabled = false,
  validationSchema,
  warningMessage,
}) => {
  const classes = useStyles();
  const flags = useContext(FeatureFlagContext);

  return (
    <Formik
      initialValues={item as PebbleType | MobxPebbleType}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        if (flags[TURN_OFF_PEBBLES]) {
          // Shouldn't be reachable since we hide the buttons,
          // but on the off chance there's some keyboard shortcut or something...
          return undefined;
        }
        return onSave
          ? onSave(values)
              .then(() => {
                if (resetAfterSave) {
                  // The notes section of a pebble displays all of the previous note values.
                  // This means that when we save a note, the new value is displayed above the
                  // input as part of the notes history. Instead of updating the initial values
                  // to include the new note value (like we do for selects), clear the input
                  // value on save. This way we're not displaying the new note value in the
                  // input field and the history above.
                  const newInitialValues = values.note ? { ...values, note: '' } : values;
                  actions.resetForm({ values: newInitialValues });
                }
              })
              .catch(err => {
                if (err.graphQLErrors?.[0].validationErrors) {
                  actions.setErrors(
                    mapValues(err.graphQLErrors[0].validationErrors, value => value.message),
                  );
                  return true;
                } else {
                  return Promise.reject(err);
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              })
          : undefined;
      }}
    >
      {({ handleSubmit, isSubmitting, handleReset }) => (
        <form className={classes.form} id={formId} onSubmit={handleSubmit}>
          {children}

          <span className={classes.buttons}>
            <div>
              <div className={classes.warningMessage}>{warningMessage}</div>
              {!flags[TURN_OFF_PEBBLES] && onDelete && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size={buttonSize}
                  className={classes.button}
                  onClick={onDelete}
                  disabled={isSubmitting || savingDisabled}
                >
                  Delete
                  <DeleteIcon className={classes.rightIcon} />
                </Button>
              )}
              {!flags[TURN_OFF_PEBBLES] && onSave && (
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className={classes.button}
                  disabled={isSubmitting || savingDisabled}
                  type="submit"
                  data-testid="edit-form-save"
                >
                  {saveButtonText}
                  <SaveIcon className={classes.rightIcon} />
                </Button>
              )}
              {!flags[TURN_OFF_PEBBLES] && onCancel && (
                <Button
                  variant="outlined"
                  color="secondary"
                  tabIndex="-1"
                  size={buttonSize}
                  className={classes.button}
                  onClick={event => (resetAfterCancel ? handleReset() : onCancel(event))}
                  disabled={
                    isSubmitting || (cancelDisabled === undefined ? savingDisabled : cancelDisabled)
                  }
                >
                  Cancel
                  <CancelIcon className={classes.rightIcon} />
                </Button>
              )}
              {flags[TURN_OFF_PEBBLES] && (
                <div>Pebbles are turned off, saving changes is disabled</div>
              )}
            </div>
          </span>
        </form>
      )}
    </Formik>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    marginTop: 20,
  },
  button: {
    marginRight: 5,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  warningMessage: {
    marginRight: theme.spacing(2),
    textAlign: 'right',
    color: Colors.Coral,
  },
}));

export default EditPebbleForm;
