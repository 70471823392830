import React from 'react';

export type SideEffectsDetails = {
  experiencingSideEffects?: boolean;
  sideEffectsDetails?: string;
  sideEffectsSeverity?: number;
};

type SideEffectsProps = {
  visitReasonDetails: SideEffectsDetails;
};

export const SideEffects = ({ visitReasonDetails }: SideEffectsProps) => {
  if (!visitReasonDetails.experiencingSideEffects) {
    return null;
  }

  const formattedDetailText = [
    visitReasonDetails.sideEffectsSeverity,
    visitReasonDetails.sideEffectsDetails,
  ]
    .filter(x => x)
    .join(', ');

  return (
    <li>
      <span className="label">Med side effect(s):</span> {formattedDetailText}
    </li>
  );
};
