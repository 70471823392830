import gql from 'graphql-tag';

import { PEBBLE_LIMITED, PEBBLE_ALL } from 'src/stores/fragments/pebbles';

export const COUNT_PEBBLES_FOR_BADGE = gql`
  query CountPebblesForBadge(
    $newPebblesWithFutureRemindersWhere: PebbleWhereInput
    $newPebblesWithoutRemindersWhere: PebbleWhereInput
    $pastDuePebblesWhere: PebbleWhereInput
  ) {
    newPebblesWithFutureRemindersCount: pebblesCount(where: $newPebblesWithFutureRemindersWhere)
    newPebblesWithoutRemindersCount: pebblesCount(where: $newPebblesWithoutRemindersWhere)
    pastDuePebblesCount: pebblesCount(where: $pastDuePebblesWhere)
  }
`;

export const LOAD_PEBBLES = gql`
  query Pebbles($first: Int, $skip: Int, $where: PebbleWhereInput, $orderBy: PebbleOrderByInput) {
    items: pebbles(where: $where, orderBy: $orderBy, paging: { first: $first, skip: $skip }) {
      ...PebbleLimited
    }
  }

  ${PEBBLE_LIMITED}
`;

export const LOAD_FULL_PEBBLE = gql`
  query FullPebble($id: ID!) {
    pebble(id: $id) {
      ...PebbleAll
    }
  }
  ${PEBBLE_ALL}
`;
