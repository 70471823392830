import Typography from '@material-ui/core/Typography';
import React from 'react';

import { getFieldTitle } from 'src/components/forms/renderers/base';

export function component({ model, classes, schema, key }) {
  // The Presciber: Plan Initial v1.0.0 schema depends on the key being set this is necessary for
  // backwards compatibility.
  const value = key ? model[key] : model;
  return (
    <div className={classes.itemDetails}>
      {Object.keys(schema.properties).map(prop => {
        const field = schema.properties[prop];
        switch (field.type) {
          case 'boolean':
            return (
              value[prop] === true && <span key={prop}>{getFieldTitle(field, prop)}: Yes</span>
            );
          case 'string':
            return (
              value[prop] && (
                <div key={prop} className={classes.marginTop}>
                  <Typography variant="body2" className={classes.multiLineText}>
                    {value[prop]}
                  </Typography>
                </div>
              )
            );
          default:
            return null;
        }
      })}
    </div>
  );
}
