import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';

import FeatureFlag, { FeatureFlagNotTargeted } from 'src/components/featureflags/featureFlag';
import { getSchema, getRenderer } from 'src/components/forms';
import EditableSectionHeader from 'src/components/forms/editableSectionHeader';
import EventDetailsForm from 'src/components/forms/eventDetailsForm';
import EventHeader from 'src/components/pages/pageElements/eventHeader';
import PreVisitDataDisplay from 'src/events/components/PreVisitDataDisplay';
import { SHOW_PRE_VISIT_DATA_DISPLAY } from 'src/featureFlags/currentFlags';
import { ChartInteractionContextProvider } from 'src/nightingale/components/ChartInteraction/ChartInteractionContext';
import { ChartInteractionView } from 'src/nightingale/components/ChartInteraction/ChartInteractionView';
import { EventInstance } from 'src/stores/models/event';

/**
 * A lighter-weight read-only version of pages/eventShow. This avoids the complexity of making
 * that top-level page configurable to take an event instead of read it directly from the
 * root store.
 */

const VisitSummary = ({
  event,
  patientId,
  classes,
}: {
  event: EventInstance;
  patientId: string;
  classes: { [key: string]: string };
}) => {
  return (
    <div>
      <EventHeader event={event} hideRecurrenceDetails showActionButtons />
      <div>
        <FeatureFlagNotTargeted name={SHOW_PRE_VISIT_DATA_DISPLAY}>
          <EditableSectionHeader title="Event Details" hideEditIcon />
          <EventDetailsForm event={event} />
        </FeatureFlagNotTargeted>
        <FeatureFlag name={SHOW_PRE_VISIT_DATA_DISPLAY}>
          <PreVisitDataDisplay event={event} />
        </FeatureFlag>
      </div>
      {event.eventResults?.map(result => {
        try {
          const RenderComponent = getRenderer('eventResult', result.type, result.version);
          const schema = getSchema('eventResult', result.type, result.version);
          return (
            <div key={result.type} className={classes.formSection}>
              <EditableSectionHeader title={schema.title} hideEditIcon />
              <RenderComponent model={result.results || {}} schema={schema} toplevel />
            </div>
          );
        } catch (e) {
          // This case can occur if an unrecognized schema type or version ends up in the db.
          // It should be very rare, but it seems better to ignore the section rather than
          // have the app fall over.
          return null;
        }
      })}
      {event.isNightingale && (
        <ChartInteractionContextProvider
          patientId={patientId}
          interactionReferenceId={event.id}
          interactionKey={event.subType}
          isNightingale={event.isNightingale}
          additionalSummarizationContext={{
            event: {
              id: event.id,
              start: event.start,
            },
          }}
        >
          <ChartInteractionView disableEdit />
        </ChartInteractionContextProvider>
      )}
    </div>
  );
};

const styles = () => ({
  formSection: {
    marginBottom: 30,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(VisitSummary)));
