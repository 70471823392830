import { ActivityType, View } from 'src/components/pages/patientActivity/types';
import type { ActivityData } from 'src/components/pages/patientActivity/types';
import { SubType } from 'src/events/visitTypes/subType';
import { ScheduleChangeReason } from 'src/generated/gql/graphql';
import {
  CASE_MANAGER_EVENT_SUBTYPES,
  CLINICIAN_EVENT_SUBTYPES,
  RN_EVENT_SUBTYPES,
  SCHEDULE_CHANGE_REASONS,
} from 'src/shared/util/events';
import { PebbleTopic } from 'src/util/pebbles';

const CLINICAL_VISIT_TYPES = [
  ...CLINICIAN_EVENT_SUBTYPES,
  ...RN_EVENT_SUBTYPES,
  ...CASE_MANAGER_EVENT_SUBTYPES,
  'peer_recovery_group',
  'peer_followup',
  'peer_intake',
];

/**
 * Filters the activity data based on the view. Includes complicated business logic,
 * see [CHX-861 for details](https://bouldercare.atlassian.net/browse/CHX-861).
 */
export const filterActivityData = (data: ActivityData[], view: View): ActivityData[] => {
  switch (view) {
    /**
     * The default view. Displays most results, but filters out some extraneous
     * items that are rarely useful.
     */
    case View.Default:
    default:
      return data.filter(activity => {
        const hiddenRescheduleReasons: Array<keyof typeof SCHEDULE_CHANGE_REASONS> = [
          ScheduleChangeReason.CanceledRecurrence,
          ScheduleChangeReason.SchedulingError,
          ScheduleChangeReason.SeenInDropIn,
        ];
        const videoTestSubtype: SubType = 'video_test';
        if (
          activity.type === ActivityType.Event &&
          activity.status === 'canceled' &&
          activity.event.rescheduledTo !== null &&
          activity.event.scheduleChangeReason &&
          hiddenRescheduleReasons.includes(activity.event.scheduleChangeReason)
        ) {
          return false;
        }
        if (activity.type === ActivityType.Event && activity.status === 'rescheduled') {
          return false;
        }
        if (activity.type === ActivityType.Event && activity.event.subType === videoTestSubtype) {
          return false;
        }
        if (activity.type === ActivityType.Pebble && activity.name === PebbleTopic.Personal) {
          return false;
        }

        return true;
      });

    /**
     * Displays all event results, no pebbles or tasks.
     */
    case View.Visits:
      return data.filter(activity => activity.type === ActivityType.Event);

    /**
     * Displays all events with clinical personnel attending, and all RxIssue pebbles.
     */
    case View.MedsManagement:
      return data.filter(activity => {
        if (
          activity.type === ActivityType.Event &&
          activity.attendees.some(
            user => user.teamRole && ['clinician', 'registered_nurse'].includes(user.teamRole),
          )
        ) {
          return true;
        }

        if (
          activity.type === ActivityType.Pebble &&
          [PebbleTopic.RxIssue, PebbleTopic.BridgePrescriptionRequest].includes(
            activity.name as PebbleTopic,
          )
        ) {
          return true;
        }

        return false;
      });

    /**
     * Displays all clinical events.
     */
    case View.ClinicalVisit:
      return data.filter(
        activity =>
          activity.type === ActivityType.Event &&
          activity.event.subType &&
          CLINICAL_VISIT_TYPES.includes(activity.event.subType),
      );

    /**
     * All content, no filters.
     */
    case View.Detailed:
      return data;
  }
};
