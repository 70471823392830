import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useCallback, useMemo } from 'react';

import {
  invalidPatientIds,
  duplicatePatientIds,
} from 'src/smsCommunications/domain/patientIdListValidation';

export const testId = 'bulk-patient-id-text-input';

/**
 * Transforms the raw values from a text input into a list of potential patientIds
 * @param input the raw text from the input
 * @returns a list of expected patientId strings
 */
export const storeInputAsPatientIdsList = (input: string) => {
  // when input is empty, empty the whole list
  if (input.length === 0) {
    return [];
  }
  // parse input before storing to clean up and read as a list
  return input
    .replace(/ +?/g, '') // remove all whitespace in input except for newline
    .split('\n'); // input expects to read a patientId on each newline
  // NOTE: empty strings/newlines returned allows for empty newlines in the input
};

/**
 * Transforms a list of potential patientIds into a format used by a text input
 * @param patientIds list of expected patientId strings
 * @returns the raw value used by the text input
 */
export const parsePatientIdsListToText = (patientIds: string[]) => patientIds.join('\n');

/**
 * Determines whether the input contains: no invalid, no duplicates, and
 *  the minimum and maximum number of valid patientIds
 * @param patientIds list of expected patientId strings
 * @returns true if all values are valid patientIds, and total is within range
 */
export const isInputValid = (patientIds: string[]) => {
  // range of bulk patientIds (1-250)
  const hasMinimumNumberOfPatients = patientIds.length > 0;
  const hasMaximumNumberOfPatients = patientIds.length <= 250;

  // ensure that the input has no invalid or duplicate patientIds
  const hasInvalidInput =
    invalidPatientIds(patientIds).length > 0 || duplicatePatientIds(patientIds).length > 0;
  return !hasInvalidInput && hasMinimumNumberOfPatients && hasMaximumNumberOfPatients;
};

const BulkPatientIdTextInput = ({ patientIds, setPatientIds }) => {
  const classes = useStyles();

  // to access patientIds
  const currentInput = useMemo(() => parsePatientIdsListToText(patientIds), [patientIds]);

  // to set patientIds
  const updateCurrentInput = useCallback(
    event => {
      const { value } = event.target;
      setPatientIds(storeInputAsPatientIdsList(value));
    },
    [setPatientIds],
  );

  // In this case the inputProps and InputProps define different values and both are needed
  /* eslint-disable react/jsx-no-duplicate-props */
  return (
    <div className={classes.wrapper}>
      <TextField
        type="text"
        className={classes.textField}
        value={currentInput}
        multiline
        rows={2}
        rowsMax={10}
        onChange={updateCurrentInput}
        inputProps={{ 'data-testid': testId }}
        InputProps={{
          spellCheck: 'false',
          classes: {
            input: classes.textFieldInput,
          },
        }}
        onPaste={updateCurrentInput}
      />
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
  },
  textField: { width: '255px' },
  textFieldInput: {
    fontFamily: 'Courier',
    fontSize: 14,
  },
});

export default BulkPatientIdTextInput;
