import { Paper } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import type { ScheduleLogQueue } from 'src/dropInClinic/hooks/useScheduleLogs';

const ScheduleQueue = ({ queue }: { queue: ScheduleLogQueue[] }) => {
  return (
    <div>
      {queue.map((request: ScheduleLogQueue) => {
        const toolTip = (
          <>
            Patient: {request.patient.firstName} {request.patient.lastName} <br />
            Request ID: {request.requestId} <br />
            Request Type: {request.requestType} <br />
            Queued At: {format(new Date(request.queuedAt), 'p')} <br />
            Bonuses: {JSON.stringify(request.bonuses, null, 2)} <br />
          </>
        );
        return (
          <Tooltip title={toolTip}>
            <Paper
              elevation={2}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <div style={{ padding: 4, justifyContent: 'center' }}>{request.patient.id}</div>
            </Paper>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ScheduleQueue;
