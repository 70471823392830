import React from 'react';

import v100Schema from 'src/components/forms/schemas/eventResult/peerCoachVisit/1.0.0';
import PatientOverviewLink from 'src/components/general/patientOverviewLink';

// Add updatedEnrollmentAndCarePlan property
const { ...v100Properties } = v100Schema.properties;

export default {
  ...v100Schema,
  properties: {
    ...v100Properties,
    updatedEnrollmentAndCarePlan: {
      type: 'boolean',
      title: 'Updated Enrollment Status and Care Plan',
      label: (
        <PatientOverviewLink section="general">
          Updated Enrollment Status and Care Plan
        </PatientOverviewLink>
      ),
    },
  },
};
