// eslint-disable-next-line no-control-regex
const ASCII_REGEX = /^[\x00-\x7F]*$/;
const normalize: (string) => string = str => {
  if (ASCII_REGEX.test(str)) {
    // this string is all from the ASCII character range, which contains no diacritics
    return str.toLowerCase();
  }
  // letters with diacritics can sometimes be represented by a single unicode character,
  // but can also be represented by two characters where the second character is the
  // diacritic to be applied to the first character.
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize#description
  // use normalize('NFD') to ensure the second form, and then strip out the diacritical characters.
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

/**
 * Determine which menu items are shown when the user types something in the input. This
 * is intended as a performance improvement over
 * https://github.com/JedWatson/react-select/blob/v2.1.2/src/filters.js#L16
 */
function filterMatches({ label, value }: { label: string; value: string }, rawInput: string) {
  if (!rawInput) return true;
  const trimmedInput = rawInput.trim();
  if (!trimmedInput) return true;
  const input = normalize(trimmedInput).split(/\s+/);
  const candidate = normalize(`${label} ${value}`);
  for (let i = 0, start = 0; i < input.length; i++) {
    const matchIndex = candidate.indexOf(input[i], start);
    if (matchIndex === -1) {
      return false;
    }
    start = matchIndex + input[i].length;
  }
  return true;
}

export default filterMatches;
