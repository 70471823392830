import React from 'react';
import { connectField, filterDOMProps } from 'uniforms';

import DateTimePicker from 'src/components/forms/controls/dateTimePicker';

const DateTimeField = ({
  disabled,
  error,
  errorMessage,
  label,
  name,
  onChange,
  placeholder,
  showInlineError,
  value,
  ...props
}) => {
  return (
    <DateTimePicker
      disabled={!!disabled}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      errorMessage={errorMessage}
      label={label}
      showInlineError={showInlineError}
      placeholder={placeholder}
      {...filterDOMProps(props)}
    />
  );
};

export default connectField(DateTimeField);
