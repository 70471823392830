import { makeStyles, MenuItem } from '@material-ui/core/';
import React from 'react';
import Highlighter from 'react-highlight-words';

import Colors from 'src/nightingale/Colors';
import { findHighlightChunks } from 'src/nightingale/components/ChartProperty/controls/TaggedText/TaggedTextControl.utils';

export const SuggestionListItem: React.VFC<{
  current?: boolean;
  item?: {
    option: string;
    matched: string;
  };
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ current, item, onClick }) => {
  const styles = useStyles();
  return (
    <MenuItem
      component="div"
      selected={current}
      className={styles.suggestionListItem}
      onClick={onClick}
    >
      <Highlighter
        highlightClassName={styles.highlight}
        searchWords={[item.matched]}
        textToHighlight={item.option}
        autoEscape
        findChunks={findHighlightChunks}
      />
    </MenuItem>
  );
};

const useStyles = makeStyles({
  suggestionListItem: {
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.current': {
      backgroundColor: Colors.Gray2,
    },
  },
  highlight: {
    textDecoration: 'underline',
    backgroundColor: 'transparent',
  },
});
