import gql from 'graphql-tag';

export const SUBSCRIBE_TO_UPDATED_EVENT = gql`
  subscription ($eventId: ID!) {
    eventUpdated(eventId: $eventId) {
      id
    }
  }
`;

export const SUBSCRIBE_TO_PATIENT_JOINED_CALL = gql`
  subscription ($providerId: ID!) {
    patientJoinedCall(providerId: $providerId) {
      eventId
      providerId
    }
  }
`;
