import { PatientActivityEventInstancesQuery, Provider } from 'src/generated/gql/graphql';
import type { EventAll } from 'src/stores/fragments/events';

export enum ActivityType {
  Event = 'event',
  Task = 'task',
  Pebble = 'pebble',
  Unknown = 'unknown',
}

export enum View {
  Default = 'default',
  Visits = 'visits',
  MedsManagement = 'medsManagement',
  ClinicalVisit = 'clinicalVisit',
  Detailed = 'detailed',
}

export type ProviderAttendee = Pick<Provider, 'id' | 'firstName' | 'lastName' | 'teamRole'>;

export interface BaseActivityData {
  name: string; // Internal identifier for the event/task/pebble
  label: string; // Human-readable label for the event/task/pebble
  type: ActivityType;
  start: string;
  attendees: ProviderAttendee[];
  eventNotes: string;
  status: NonNullable<EventAll['status']> | 'unknown';
  eventStatusInfo: Pick<EventAll, 'status' | 'start' | 'allDay' | 'duration'>;
  sortDateEpoch: number;
}

export interface ActivityDataEvent extends BaseActivityData {
  eventId: string;
  event: PatientActivityEvent;
  type: ActivityType.Event;
}

export interface ActivityDataTask extends BaseActivityData {
  taskId: string;
  type: ActivityType.Task;
}

export interface ActivityDataPebble extends BaseActivityData {
  pebbleId: string;
  type: ActivityType.Pebble;
}

export type ActivityData = ActivityDataEvent | ActivityDataTask | ActivityDataPebble;

export type PatientActivityEvent =
  PatientActivityEventInstancesQuery['patientActivityEvents'][number];
