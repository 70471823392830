import { Theme, makeStyles } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

export const useTooltipStyles = makeStyles<Theme>(theme => ({
  tooltip: {
    background: Colors.Gray8,
    borderRadius: '3px',
    color: Colors.White,
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 600,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginLeft: theme.spacing(1.25),
  },
}));
