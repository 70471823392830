import gql from 'graphql-tag';

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $fromId: ID!
    $conversationId: ID!
    $text: String
    $videoConference: Boolean
    $displayMode: MessageDisplayMode
  ) {
    createMessage(
      data: {
        from: $fromId
        conversation: $conversationId
        text: $text
        videoConference: $videoConference
        displayMode: $displayMode
      }
    ) {
      id
    }
  }
`;

export const SEND_MESSAGE_IN_NEW_CONVERSATION = gql`
  mutation SendMessageInNewConversation(
    $fromId: ID!
    $toIds: [ID!]!
    $text: String
    $videoConference: Boolean
    $displayMode: MessageDisplayMode
  ) {
    createMessage(
      data: {
        from: $fromId
        to: $toIds
        text: $text
        videoConference: $videoConference
        displayMode: $displayMode
      }
    ) {
      id
      conversation {
        id
      }
    }
  }
`;

export const SEND_BULK_MESSAGE = gql`
  mutation SendBulkMessage($fromId: ID!, $toIds: [ID!], $otherRecipientIds: [ID!], $text: String!) {
    createMessages(
      data: {
        fromId: $fromId
        to: $toIds
        otherRecipients: $otherRecipientIds
        applyTo: some_patients
        text: $text
      }
    ) {
      createdMessages {
        id
      }
    }
  }
`;
