import gql from 'graphql-tag';

export const SEARCH_ICD10_CODES = gql`
  query SearchICD10CodesOptions($q: String!) {
    options: icd10Codes(q: $q) {
      key
      name
    }
  }
`;

export const SEARCH_MEDICATIONS = gql`
  query SearchMedicationsOptions($q: String!) {
    options: medications(q: $q) {
      key
      name
    }
  }
`;

export const SEARCH_PAYORS = gql`
  query SearchPayorsOptions($q: String!) {
    options: payors(q: $q) {
      key
      name
    }
  }
`;

export const SEARCH_PROBLEMS = gql`
  query SearchProblemsOptions($q: String!) {
    options: problems(q: $q) {
      key
      name
    }
  }
`;
