import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { IconPillOff } from '@tabler/icons-react';
import classNames from 'classnames';
import { isValid, differenceInDays, intlFormatDistance } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';

import {
  startOfDayInBoulderTimezone,
  startOfTodayInBoulderTimezone,
} from 'src/businessHours/timezone';
import { useButtonStyles } from 'src/patientHeader/components/badges/styles/useButtonStyles';
import { useTooltipStyles } from 'src/patientHeader/components/badges/styles/useTooltipStyles';
import { useBupeRunOut } from 'src/patientHeader/components/badges/useBupeRunOut';
import { theme as appTheme } from 'src/theme';

const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const BupeRunOut: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const tooltipStyles = useTooltipStyles();
  const buttonStyles = useButtonStyles();
  const styles = useStyles();

  const runOutDateOnly = useBupeRunOut(patientId);
  if (!runOutDateOnly) return null;

  const runOutDate = startOfDayInBoulderTimezone(runOutDateOnly);
  if (!isValid(runOutDate)) return null;

  const today = startOfTodayInBoulderTimezone();

  const daysUntilRunOut = differenceInDays(runOutDate, today);
  const buttonClassNames = [buttonStyles.button];
  if (daysUntilRunOut < 0) {
    buttonClassNames.push(buttonStyles.error);
  } else if (daysUntilRunOut <= 2) {
    buttonClassNames.push(buttonStyles.warning);
  }

  const timeDistance =
    Math.abs(daysUntilRunOut) >= 1
      ? intlFormatDistance(runOutDate, today, { unit: 'day' })
      : 'today';

  return (
    <Tooltip
      title={`Bupe run out day: ${timeDistance}`}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a href={`/patients/${patientId}/overview#activeMedications`}>
        <Button
          classes={{ startIcon: styles.startIcon }}
          className={classNames(buttonClassNames)}
          fullWidth
          size="small"
          startIcon={<IconPillOff size={appTheme.typography.fontSize} />}
          variant="outlined"
        >
          {formatInTimeZone(runOutDate, localZone, 'MM/dd/yyyy')}
        </Button>
      </a>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  startIcon: {
    marginRight: 4,
  },
});
