import type { SubType } from 'src/events/visitTypes';

/**
 * The information the staff app needs to present a provider time slot
 */
type TimeSlot = {
  start: Date;
  duration: number;
  providerId: string;
  firstName: string;
  lastName: string;
  restrictions: string;
  subType: SubType;
  priority?: Priority;
};

export enum Priority {
  Available = 'available',
  Backup = 'backup',
  Unavailable = 'unavailable',
}

export default TimeSlot;
