import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, isValid, parseISO } from 'date-fns';
import { getIn } from 'formik';
import React from 'react';

const DateControl = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  className,
}) => (
  <KeyboardDatePicker
    name={name}
    value={value ? parseISO(value) : null}
    className={className}
    onChange={newValue => {
      let newFieldValue = null;
      if (newValue) {
        newFieldValue = isValid(newValue) ? format(newValue, 'yyyy-MM-dd') : newValue.toString();
      }
      setFieldValue(name, newFieldValue);
    }}
    onBlur={() => setFieldTouched(name)}
    error={getIn(errors, name) && getIn(touched, name)}
    label={label}
    emptyLabel=""
    invalidLabel=""
    format="MM/dd/yyyy"
    placeholder="MM/DD/YYYY"
    invalidDateMessage="Date must be formatted like: 01/31/2019"
    autoOk
    fullWidth
  />
);

export default DateControl;
