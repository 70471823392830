import React, { useEffect, useState } from 'react';

import { usePrevious } from 'src/nightingale/hooks/usePrevious';

/**
 * Returns a boolean representing if any validation errors should be shown,
 * depending on user interaction:
 *   1. If the input has been touched (ie. blur'ed), then validation errors
 *   should show.
 *   2. If there's a validation error on the initial value, before the input is
 *   dirty (ie. has changed), validation errors should show.
 *
 * @see {@link https://bouldercare.slack.com/archives/C02ACM0N6PL/p1641319073089000}
 */
export const useShowValidationError = (
  value: any,
): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [isTouched, setIsTouched] = useState(false);
  const [hasSeenErrors, setHasSeenErrors] = useState(false);

  const [isDirty, setIsDirty] = useState(false);
  const previousValue = usePrevious(value);
  useEffect(() => {
    if (previousValue !== undefined && previousValue !== value) setIsDirty(true);
  }, [previousValue, value]);

  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if ((!isDirty && hasSeenErrors) || isTouched) setShowError(true);
  }, [isTouched, isDirty, hasSeenErrors]);

  return [showError, setIsTouched, setHasSeenErrors];
};
