import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { format } from 'date-fns';
import { inject, observer } from 'mobx-react';
import React from 'react';

import { parseUnknownDate } from 'src/util/parseUnknownDate';

const HrqolResults = ({ completedHrqolSurveys, classes }) => {
  return completedHrqolSurveys?.length ? (
    <Table className={classes.table} data-testid="hrqol-results-data">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHead} width="12.5%">
            Survey Date
          </TableCell>
          <TableCell className={classNames(classes.tableHead, classes.typeColumn)} width="12.5%">
            General Health
          </TableCell>
          <TableCell className={classNames(classes.tableHead, classes.typeColumn)} width="12.5%">
            Poor Physical Health (days)
          </TableCell>
          <TableCell className={classNames(classes.tableHead, classes.typeColumn)} width="12.5%">
            Poor Mental Health (days)
          </TableCell>
          <TableCell className={classNames(classes.tableHead, classes.typeColumn)} width="12.5%">
            Missed Activities (days)
          </TableCell>
          <TableCell className={classNames(classes.tableHead, classes.typeColumn)} width="12.5%">
            Recovery Progress
          </TableCell>
          <TableCell className={classNames(classes.tableHead, classes.typeColumn)} width="25%">
            Additional Info
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {completedHrqolSurveys.map(survey => (
          <TableRow key={survey.id}>
            <TableCell>{format(parseUnknownDate(survey.completedAt), 'PP')}</TableCell>
            <TableCell>{survey.generalHealth}</TableCell>
            <TableCell>{survey.physicalHealth}</TableCell>
            <TableCell>{survey.mentalHealth}</TableCell>
            <TableCell>{survey.missedActivities}</TableCell>
            <TableCell>{survey.recoveryProgress}</TableCell>
            <TableCell>{survey.additionalInfo}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <span data-testid="hrqol-results-placeholder">No check-ins completed.</span>
  );
};

const styles = {
  table: {
    tableLayout: 'fixed',
  },
  tableHead: {
    padding: '4px 10px',
    textTransform: 'uppercase',
    lineHeight: 1.3,
    color: 'rgba(0, 0, 0, 0.54)',
    // The labels in the form are 16px normally, then transformed by 0.75 when shrunk
    fontSize: 16 * 0.75,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
};

export default withStyles(styles)(inject('rootStore')(observer(HrqolResults)));
