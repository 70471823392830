import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import WelcomeMask from 'src/components/pages/pageElements/welcomeMask';

const UnsupportedBrowser: React.FC = () => {
  const classes = useStyles();
  return (
    <WelcomeMask
      headerIcon={<ErrorOutlineIcon className={classes.errorIcon} />}
      headerClassName={classes.errorMessage}
      headerText="Unsupported Web Browser"
    >
      <div className={classes.instructions}>
        Unfortunately, this web browser is not supported by Boulder Care&#39;s video visit system.
        We recommend downloading or upgrading{' '}
        <a
          className={classes.googleChrome}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/chrome/"
        >
          Google Chrome
        </a>
        , and trying to access this visit again.
      </div>
    </WelcomeMask>
  );
};

const useStyles = makeStyles({
  errorIcon: {
    color: '#FF6666',
    fontSize: 60,
  },
  errorMessage: {
    color: '#FF6666',
  },
  instructions: {
    fontSize: 24,
    maxWidth: 700,
  },
  googleChrome: {
    color: 'inherit',
    '&:hover': {
      color: '#90C7A4',
    },
  },
});

export default UnsupportedBrowser;
