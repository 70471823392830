// Version imports
import v100 from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.0.0';
import v110 from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.1.0';
import v120 from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.2.0';
import v130 from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.3.0';
import v140 from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.4.0';

export default {
  latest: v140,
  // All Versions
  '1.4.0': v140,
  '1.3.0': v130,
  '1.2.0': v120,
  '1.1.0': v110,
  '1.0.0': v100,
};
