import isNil from 'lodash/isNil';
import * as Yup from 'yup';

import { getParentType } from 'src/events/visitTypes';
import type { SubType } from 'src/events/visitTypes';
import { isAllDay, roleHasAvailabilitySubTypes } from 'src/shared/util/events';

Yup.setLocale({
  mixed: {
    required: 'required',
  },
});

type ValidationRule = {
  min?: number;
  max?: number;
  exact?: number;
};

const attendeeRules = new Map<SubType, { patient?: ValidationRule; provider?: ValidationRule }>([
  // "other" subtypes
  ['case_manager_note', { patient: { exact: 1 }, provider: { min: 1 } }],
  ['case_manager_consultation', { patient: { exact: 1 }, provider: { min: 1 } }],
  ['inquiry_other', { patient: { max: 1 } }],
  ['inquiry_inapp_enrollment', { patient: { max: 1 } }],
  ['inquiry_webform', { patient: { max: 1 } }],
  ['inquiry_referrer', { patient: { max: 1 } }],
  ['inquiry_covid19_screening', { patient: { max: 1 } }],
  ['inquiry_current_patient', { patient: { exact: 1 } }],
  ['inquiry_potential_patient', { patient: { max: 1 } }],
  ['rcv_setup', {}],
  ['phone_call', { patient: { max: 1 }, provider: { min: 1 } }],
  ['onboarding_checklist', { patient: { exact: 1 }, provider: { min: 1 } }],
  ['note', { patient: { max: 1 }, provider: { min: 1 } }],
  ['peer_note', { patient: { exact: 1 }, provider: { min: 1 } }],
  ['prescriber_note', { patient: { exact: 1 }, provider: { min: 1 } }],
  ['bridge_prescription_request', { patient: { exact: 1 }, provider: { min: 1 } }],
  ['discharge_summary', { patient: { exact: 1 }, provider: { min: 1 } }],
  // appointment_virtual subtypes with nonstandard validation rules
  ['general', { patient: { max: 1 }, provider: { min: 1 } }],
  ['staff_meeting', { patient: { exact: 0 }, provider: { min: 2 } }],
  ['rcv', {}],
  ['inquiry_webform_call', { patient: { max: 1 } }],
  ['inquiry_inapp_call', { patient: { max: 1 } }],
  ['prescriber_covid19_screening', { patient: { max: 1 } }],
  ['blocked', { provider: { min: 1 } }],
  ['blocked_admin', { provider: { min: 1 } }],
  ['blocked_drop_in_admin', { provider: { min: 1 } }],
  ['blocked_flex_holiday', { provider: { min: 1 } }],
  ['blocked_holiday', { provider: { min: 1 } }],
  ['blocked_internal', { provider: { min: 1 } }],
  ['blocked_meeting', { provider: { min: 1 } }],
  ['blocked_personal', { provider: { min: 1 } }],
  ['case_manager_intake', { patient: { exact: 1 }, provider: { exact: 1 } }],
  ['case_manager_follow_up', { patient: { exact: 1 }, provider: { exact: 1 } }],
  ['case_manager_transition', { patient: { exact: 1 }, provider: { exact: 1 } }],
  ['hold_new_patient', { provider: { exact: 1 } }],
  ['hold_paneling_follow_up', { provider: { exact: 1 } }],
  // "inperson" rule retained for backward-compatibility
  ['inperson_general', { patient: { exact: 1 }, provider: { min: 1 } }],
]);

function patientValidation(subType: SubType): ValidationRule {
  const explicitRule = attendeeRules.get(subType);
  if (explicitRule) {
    return explicitRule.patient ?? {};
  }
  if (getParentType(subType) === 'appointment_virtual') {
    return { exact: 1 };
  }
  if (getParentType(subType) === 'availability') {
    return { exact: 0 };
  }
  throw new Error(`No patient-attendee validation rule defined for ${subType} events`);
}

function providerValidation(subType: SubType): ValidationRule {
  const explicitRule = attendeeRules.get(subType);
  if (explicitRule) {
    return explicitRule.provider ?? {};
  }
  if (getParentType(subType) === 'appointment_virtual') {
    return { min: 1 };
  }
  if (getParentType(subType) === 'availability') {
    return { exact: 1 };
  }
  throw new Error(`No provider-attendee validation rule defined for ${subType} events`);
}

export function isPatientRequired(subType: SubType): boolean {
  const rule = patientValidation(subType);
  return (rule.exact ?? rule.min ?? 0) >= 1;
}

// AppliesTo informs the attendees control which dropdown should display the error
// message (patient and provider are displayed in separate controls)
export const EVENT_ATTENDEE_CONSTRAINTS = {
  'has-at-least-one-provider': {
    name: 'has-at-least-one-provider',
    message: 'must have at least one provider',
    test: values => values?.filter(value => value.__typename === 'Provider').length >= 1,
    appliesTo: 'Provider',
  },
  'has-exactly-one-patient': {
    name: 'has-exactly-one-patient',
    message: 'must have exactly one patient',
    test: values => values?.filter(value => value.__typename === 'Patient').length === 1,
    appliesTo: 'Patient',
  },
  'has-maximum-one-patient': {
    name: 'has-maximum-one-patient',
    message: 'may only have up to one patient',
    test: values => values?.filter(value => value.__typename === 'Patient').length <= 1,
    appliesTo: 'Patient',
  },
  'has-at-least-two-providers': {
    name: 'has-at-least-two-providers',
    message: 'must have at least two providers',
    test: values => values?.filter(value => value.__typename === 'Provider').length >= 2,
    appliesTo: 'Provider',
  },
  'has-no-patients': {
    name: 'has-no-patients',
    message: 'must have no patients',
    test: values => values?.filter(value => value.__typename === 'Patient').length === 0,
    appliesTo: 'Patient',
  },
  'has-exactly-one-provider': {
    name: 'has-exactly-one-provider',
    message: 'must have exactly one provider',
    test: values => values?.filter(value => value.__typename === 'Provider').length === 1,
    appliesTo: 'Provider',
  },
};

// TODO: This would cleaner if it could just calculate the validation on the event at
// runtime, instead of generating all this beforehand. Unfortunately, that might
// require a Yup upgrate to 0.29.1+, which introduces a "this.parent" for us to access
// the type and subType selected in the form.
// Note that if we're able to build this^, we'd also have to support a different way for
// the attendees control to understand which error messages are associated with the
// patient versus provider dropdown.

const title = Yup.string().nullable();
const subType = Yup.string()
  .nullable()
  .when('type', {
    is: type => !['lab', 'availability'].includes(type),
    then: Yup.string().required('required').typeError('required'),
  })
  .when('type', {
    is: 'availability',
    then: Yup.string()
      .nullable()
      .test({
        message: 'Availability subtype required for some team roles',
        test: function test() {
          return roleHasAvailabilitySubTypes(this.parent.attendees[0].teamRole)
            ? this.parent.subType?.length > 0
            : isNil(this.parent.subType) || this.parent.subType === '';
        },
      }),
  });
const attendees = Yup.array()
  // Participant validation
  .when(['subType', 'type'], {
    is: (subType, type) => type === 'availability' || patientValidation(subType).exact === 0,
    then: Yup.array().test(EVENT_ATTENDEE_CONSTRAINTS['has-no-patients']),
  })
  .when(['subType'], {
    is: subType => patientValidation(subType).exact === 1,
    then: Yup.array().test(EVENT_ATTENDEE_CONSTRAINTS['has-exactly-one-patient']),
  })
  .when(['subType'], {
    is: subType => patientValidation(subType).max === 1,
    then: Yup.array().test(EVENT_ATTENDEE_CONSTRAINTS['has-maximum-one-patient']),
  })

  // Provider validation
  .when(['subType', 'type'], {
    is: (subType, type) => type === 'availability' || providerValidation(subType).exact === 1,
    then: Yup.array().test(EVENT_ATTENDEE_CONSTRAINTS['has-exactly-one-provider']),
  })
  .when(['subType'], {
    is: subType => providerValidation(subType).min === 1,
    then: Yup.array().test(EVENT_ATTENDEE_CONSTRAINTS['has-at-least-one-provider']),
  })
  .when(['subType'], {
    is: subType => providerValidation(subType).min === 2,
    then: Yup.array().test(EVENT_ATTENDEE_CONSTRAINTS['has-at-least-two-providers']),
  });

export const EVENT_VALIDATOR = Yup.object().shape({
  title,
  type: Yup.string().required(),
  subType,
  start: Yup.date().nullable().required(),
  duration: Yup.number()
    .integer()
    .nullable()
    .min(0, 'invalid time')
    .when('type', {
      is: type => !isAllDay(type),
      then: Yup.number().required(),
    }),
  timezone: Yup.string()
    .nullable()
    .when('type', {
      is: type => !isAllDay(type),
      then: Yup.string().required(),
    }),
  attendees,
  recurrence: Yup.object()
    .nullable()
    .default({})
    .shape({
      type: Yup.string().nullable(),
      freq: Yup.string()
        .nullable()
        .when('type', {
          is: type => type === 'scheduled',
          then: Yup.string()
            .nullable()
            .required()
            .test('has-freq', 'required', function test() {
              return !isNil(this.parent.type);
            }),
        }),
      interval: Yup.number()
        .nullable()
        .min(1, 'must be positive')
        .when('freq', {
          is: freq => freq !== null && freq !== undefined,
          then: Yup.number().required().typeError('required'),
        }),
      byDay: Yup.array()
        .nullable()
        .of(Yup.number().integer())
        .when(['type', 'freq'], {
          is: (type, freq) => type === 'scheduled' && freq === 'weekly',
          then: Yup.array().required(),
        }),
      byMonthDay: Yup.number()
        .nullable()
        .integer()
        .when(['type', 'freq'], {
          is: (type, freq) => type === 'scheduled' && freq === 'monthly',
          then: Yup.number()
            .nullable()
            .test('has-month-day', 'required', function test() {
              return !isNil(this.parent.byMonthDay) || !isNil(this.parent.bySetPos);
            }),
        }),
      bySetPos: Yup.number()
        .nullable()
        .integer()
        .when(['type', 'freq'], {
          is: (type, freq) => type === 'scheduled' && freq === 'monthly',
          then: Yup.number()
            .nullable()
            .test('has-month-day', 'required', function test() {
              return !isNil(this.parent.byMonthDay) || !isNil(this.parent.bySetPos);
            }),
        }),
      byDayForMonth: Yup.number()
        .nullable()
        .integer()
        .when('bySetPos', {
          is: bySetPos => bySetPos !== null && bySetPos !== undefined,
          then: Yup.number().required(),
          otherwise: Yup.number().nullable(),
        }),
      until: Yup.date()
        .nullable()
        .when('freq', {
          is: freq => freq !== null && freq !== undefined,
          then: Yup.date().required().typeError('required'),
        }),
    }),
});

export const EVENT_DETAILS_VALIDATOR = Yup.object().shape({
  title,
  attendees,
});

export const __test__ = { patientValidation, providerValidation };
