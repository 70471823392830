import { Button, TextField } from '@material-ui/core';
import startCase from 'lodash/fp/startCase';
import React, { useCallback, useEffect, useState } from 'react';

import { useFormStyles } from 'src/dropInClinic/queueSettings/form.styles';

type PrioritizationSettingKeyValueFormProps = {
  name: string;
  initialValue: Record<string, number>;
  onSubmit: (newSetting: { name: string; value: Record<string, number> }) => Promise<void>;
};

const PrioritizationSettingKeyValueForm: React.FC<PrioritizationSettingKeyValueFormProps> = ({
  name,
  initialValue,
  onSubmit,
}) => {
  const classes = useFormStyles();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      onSubmit({ name, value });
    },
    [name, value, onSubmit],
  );

  return (
    <>
      <span className={classes.labelInstruction}>{startCase(name)}</span>
      <form key={name} onSubmit={handleSubmit} className={classes.form}>
        {Object.entries(value).map(([key, val]) => {
          return (
            <TextField
              name={key}
              value={val}
              onChange={event => setValue({ ...value, [key]: Number(event.target.value) })}
              label={startCase(key)}
            />
          );
        })}
        <Button variant="contained" color="primary" type="submit" className={classes.button}>
          Update
        </Button>
      </form>
    </>
  );
};

export default PrioritizationSettingKeyValueForm;
