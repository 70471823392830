import { Button, makeStyles, Tooltip } from '@material-ui/core';
import Location from '@material-ui/icons/LocationOn';
import classNames from 'classnames';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import isNil from 'lodash/fp/isNil';
import React from 'react';

import { type MenuPatient } from 'src/generated/gql/graphql';
import { useButtonStyles } from 'src/patientHeader/components/badges/styles/useButtonStyles';
import { useTooltipStyles } from 'src/patientHeader/components/badges/styles/useTooltipStyles';
import { getStateAbbreviation } from 'src/util/usStates';

const TooltipBody = ({ patient }) => {
  const styles = useStyles();
  const location = [
    patient.homeAddress?.city,
    getStateAbbreviation(patient.homeAddress?.state),
    patient.homeAddress?.zip,
    patient.timezone ? `(${formatInTimeZone(new Date(), patient.timezone, 'z')})` : null,
  ]
    .filter(locationString => !isNil(locationString))
    .join(', ');
  return (
    <>
      <div className={styles.locationTitle}>
        <Location /> Location
      </div>
      <div>{location}</div>
    </>
  );
};

export const PatientLocation: React.FC<{
  patient: Pick<MenuPatient, 'homeAddress' | 'timezone' | 'id'>;
}> = ({ patient }) => {
  const tooltipStyles = useTooltipStyles();
  const buttonStyles = useButtonStyles();
  const styles = useStyles();
  return (
    <Tooltip
      title={<TooltipBody patient={patient} />}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a href={`/patients/${patient.id}/overview#homeAddress`}>
        <Button
          classes={{ startIcon: styles.startIcon }}
          className={classNames([buttonStyles.button, buttonStyles.neutral])}
          variant="outlined"
          startIcon={<Location />}
          size="small"
        >
          {getStateAbbreviation(patient.homeAddress?.state)}
        </Button>
      </a>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  startIcon: {
    marginRight: 2,
  },
  locationTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 8,
  },
});
