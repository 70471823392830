import { format } from 'date-fns';

import { EVENT_STATUSES } from 'src/shared/util/events';
import type { EventInstance } from 'src/stores/models/event';

export function getEventToolTip(event: EventInstance, title: string) {
  if (event.status === EVENT_STATUSES.RESCHEDULED) {
    const newDate = format(event.rescheduledTo.start, 'eee, MMM dd, yyyy p');
    return `${title} | Rescheduled to ${newDate}`;
  }
  return title;
}
