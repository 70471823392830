import { gql } from 'src/generated/gql';

export type { PatientHighlightsQuery } from 'src/generated/gql/graphql';

export const PATIENT_HIGHLIGHTS_QUERY = gql(`
  query PatientHighlights($patientId: ID!) {
    patientHighlights(patientId: $patientId) {
      bupeRunOutDate
      dspStatus {
        label
        value
      }
      fyi {
        content
        isFlagged
      }
      hasOudDiagnosis
      lastCompletedVisit {
        date
        id
        providers
      }
      visitCadence {
        label
        value
      }
    }
  }
`);
