import gql from 'graphql-tag';

import { EVENT_INSTANCE_ALL, EVENT_INSTANCE_LIMITED } from 'src/stores/fragments/events';
import type { EventAll } from 'src/stores/fragments/events';

export const LOAD_EVENT_INSTANCES_LIMITED = gql`
  query EventInstancesLimited($user: ID, $after: DateTime, $before: DateTime, $timezone: String!) {
    eventInstances(
      timezone: $timezone
      where: { attendees_some: $user, start_gt: $after, start_lt: $before, active_only: false }
    ) {
      ...EventInstanceLimited
    }
  }
  ${EVENT_INSTANCE_LIMITED}
`;

export const LOAD_EVENT_INSTANCES_ALL = gql`
  query EventInstancesAll(
    $user: ID
    $after: DateTime
    $before: DateTime
    $timezone: String!
    $activeOnly: Boolean
  ) {
    eventInstances(
      timezone: $timezone
      where: {
        attendees_some: $user
        start_gt: $after
        start_lt: $before
        active_only: $activeOnly
      }
      orderBy: start_DESC
    ) {
      ...EventInstanceAll
    }
  }
  ${EVENT_INSTANCE_ALL}
`;

export interface EventInstancesAllResponse {
  eventInstances: Array<EventAll | null>;
}

export const LOAD_EVENT_INSTANCE = gql`
  query EventInstance($eventId: ID) {
    eventInstance(id: $eventId) {
      ...EventInstanceAll
    }
  }
  ${EVENT_INSTANCE_ALL}
`;

export const LOAD_EVENTS = gql`
  query Events($first: Int, $skip: Int, $orderBy: EventOrderByInput, $where: EventWhereInput) {
    items: events(orderBy: $orderBy, paging: { first: $first, skip: $skip }, where: $where) {
      ...EventInstanceAll
    }
  }
  ${EVENT_INSTANCE_ALL}
`;

export const EVENT_SUBTYPE_HAS_INTERACTION_FLOW_MAPPING = gql`
  query EventSubTypeHasIfm($eventSubType: String!) {
    eventSubTypeHasIfm(eventSubType: $eventSubType)
  }
`;

export const LOAD_EVENT_INSTANCES_FOR_CALENDAR_VIEW = gql`
  query EventInstancesForCalendarView(
    $user: ID
    $after: DateTime
    $before: DateTime
    $timezone: String!
  ) {
    eventInstances(
      timezone: $timezone
      where: { attendees_some: $user, start_gt: $after, start_lt: $before, active_only: false }
    ) {
      id
      attendees: attendeesDisplay {
        id
        firstName
        lastName
        preferredName
        timezone
        userType
      }
      duration
      start
      status
      subType
      title
      type
      timezone
      recurrence {
        type
        freq
        interval
        byDay
        byMonthDay
        bySetPos
        byDayForMonth
        until
      }
      rescheduledTo {
        id
        type
        start
        duration
        timezone
      }
      createdVia
      createdAt
    }
  }
`;
