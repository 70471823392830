import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { ApolloError } from 'apollo-client';
import useSWR, { BareFetcher, SWRConfiguration, SWRResponse } from 'swr';

/**
 * SWR key format we use in boulder-staff
 *
 * Mirrors how we set up our `defaultSWRFetcher` in App.tsx. By using `TypedDocumentNode` in
 * this way, the query value we pass in is mapped by TypeScript (using src/generated/gql/gql.ts)
 * to the correct `TResult` and `TVariables` types, and we get the benefit of them in our code.
 */
type Key<TResult, TVariables> =
  | [TypedDocumentNode<TResult, TVariables> | null, TVariables]
  | TypedDocumentNode<TResult, TVariables>;

export default function useTypedSWR<
  TResult,
  TVariables = Record<string, unknown>,
  TError = ApolloError, // Since the default fetcher uses GraphQL via Apollo
>(
  key: Key<TResult, TVariables> | null,
  config?: SWRConfiguration<TResult, TError, BareFetcher<TResult>>,
): SWRResponse<TResult, TError> {
  return useSWR(key, config);
}
