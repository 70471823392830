// Version imports
import v100 from 'src/components/forms/schemas/eventResult/inquiryFormDetails/1.0.0';
import v110 from 'src/components/forms/schemas/eventResult/inquiryFormDetails/1.1.0';
import v120 from 'src/components/forms/schemas/eventResult/inquiryFormDetails/1.2.0';

export default {
  latest: v120,
  // All Versions
  '1.2.0': v120,
  '1.1.0': v110,
  '1.0.0': v100,
};
