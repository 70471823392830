import { NotInterested } from '@material-ui/icons';
import {
  IconCalendarShare,
  IconCircle,
  IconDropletCheck,
  IconFriends,
  IconRotate2,
  IconUserCircle,
  IconUserPlus,
  TablerIconsProps,
} from '@tabler/icons-react';
import { ReactComponentElement } from 'react';

import { EventSubTypes } from 'src/shared/util/events';

type IconFn = (props: TablerIconsProps) => ReactComponentElement<'svg', TablerIconsProps>;

const EVENT_SUBTYPE_ICON: Partial<Record<keyof EventSubTypes, IconFn>> = {
  case_manager_drop_in_visit: IconFriends,
  case_manager_intake: IconFriends,
  case_manager_follow_up: IconFriends,
  case_manager_consultation: IconFriends,
  case_manager_transition: IconFriends,

  care_advocate_intake: IconUserPlus,
  care_advocate_drop_in_intake: IconUserPlus,
  care_advocate_drop_in_oft: IconDropletCheck,

  oft_visit: IconDropletCheck,

  peer_coach_visit: IconUserCircle,
  peer_followup: IconUserCircle,
  peer_intake: IconUserCircle,
  peer_recovery_group: IconUserCircle,
  peer_coach_drop_in_visit: IconUserCircle,

  prescriber_followup: IconCalendarShare,
  prescriber_initial: IconUserPlus,
  prescriber_reengagement_visit: IconRotate2,
  prescriber_paneling_followup: IconCalendarShare,
  prescriber_drop_in_followup: IconCalendarShare,
  prescriber_drop_in_intake: IconUserPlus,
  prescriber_drop_in_reengagement: IconRotate2,
  prescriber_mental_health_assessment: IconCalendarShare,

  registered_nurse_drop_in_followup: IconCalendarShare,
  registered_nurse_drop_in_reengagement: IconRotate2,
  registered_nurse_reengagement: IconRotate2,

  blocked: NotInterested,
  blocked_admin: NotInterested,
  blocked_drop_in_admin: NotInterested,
  blocked_flex_holiday: NotInterested,
  blocked_holiday: NotInterested,
  blocked_internal: NotInterested,
  blocked_meeting: NotInterested,
  blocked_personal: NotInterested,
};

export const getEventSubTypeIcon = (subtype: string) => EVENT_SUBTYPE_ICON[subtype] ?? IconCircle;
