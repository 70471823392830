import gql from 'graphql-tag';

import { CONVERSATION_ALL, CONVERSATION_LIMITED } from 'src/stores/fragments/conversations';

export const LOAD_CONVERSATION = gql`
  query Conversation($id: ID!) {
    conversations(orderBy: lastMessageAt_DESC, where: { id: $id }) {
      ...ConversationAll
    }
  }
  ${CONVERSATION_ALL}
`;

export const LOAD_DIRECT_CONVERSATIONS = gql`
  query DirectConversations($id: ID!) {
    conversations(orderBy: lastMessageAt_DESC, where: { users_some: $id }) {
      ...ConversationAll
    }
  }
  ${CONVERSATION_ALL}
`;

export const LOAD_PROVIDER_CONVERSATIONS = gql`
  query ProviderConversations($id: ID!, $archived: Boolean) {
    providerConversations(
      orderBy: lastMessageAt_DESC
      where: { archived: $archived, users_some: $id }
    ) {
      ...ConversationLimited
    }
  }
  ${CONVERSATION_LIMITED}
`;

export const LOAD_MONITORED_CONVERSATIONS = gql`
  query MonitoredConversations($id: ID!, $providerId: ID) {
    monitoredConversations(userId: $id, providerId: $providerId) {
      ...ConversationAll
    }
  }
  ${CONVERSATION_ALL}
`;

export const NUM_CONVERSATIONS_UNREAD = gql`
  query NumConversationsUnread {
    numConversationsUnread
  }
`;
