/**
 * Higher level service that wraps an apollo client and provides standard CRUD operations without
 * having to write one-off queries. This assumes the API follows the OpenCRUD standard (which ours
 * does).
 */
import camelCase from 'lodash/camelCase';
import pluralize from 'pluralize';

import { prepareData } from 'src/shared/services/crud/prepareData';
import { query, mutation } from 'src/shared/util/graphqlBuilder';
import ValidationError from 'src/shared/util/validationErrors';

function getValidationErrors(errors) {
  const validationErrors = new ValidationError();
  errors.forEach(error => {
    const origValidationErrors = error.validationErrors || error.extensions?.validationErrors;
    if (origValidationErrors) {
      Object.keys(origValidationErrors).forEach(field => {
        validationErrors.addError(
          field,
          origValidationErrors[field].kind,
          origValidationErrors[field].message,
        );
      });
    }
  });
  return validationErrors;
}

export default apolloClient => ({
  getOne: async (type, id, selectors) => {
    const response = await apolloClient.query({
      query: query({
        variables: { id: 'ID' },
        selectors: [
          {
            name: camelCase(type),
            alias: 'item',
            args: { id: '$id' },
            selectors,
          },
        ],
      }),
      variables: { id },
    });
    return response.data.item;
  },

  getMany: async (
    type,
    {
      orderBy,
      where,
      first,
      skip,
    }: {
      orderBy?: string;
      where?: {
        q?: string | null;
      };
      first?: number;
      skip?: number;
    },
    selectors,
  ) => {
    const queryName = pluralize(camelCase(type));
    const response = await apolloClient.query({
      query: query({
        variables: {
          orderBy: `${type}OrderByInput`,
          where: `${type}WhereInput`,
          first: 'Int',
          skip: 'Int',
        },
        selectors: [
          {
            name: queryName,
            alias: 'items',
            args: {
              orderBy: '$orderBy',
              where: '$where',
              paging: {
                first: '$first',
                skip: '$skip',
              },
            },
            selectors,
          }
        ],
      }),
      variables: {
        orderBy,
        where,
        first,
        skip,
      },
    });
    return { items: response.data.items };
  },

  create: async (type, item) => {
    const results = await apolloClient.mutate({
      mutation: mutation({
        variables: { data: `${type}Input!` },
        selectors: [
          {
            name: `create${type}`,
            alias: 'data',
            args: { data: '$data' },
            selectors: ['id'],
          },
        ],
      }),
      errorPolicy: 'all',
      variables: { data: prepareData(item, null) },
    });
    if (results.errors) {
      throw getValidationErrors(results.errors);
    }
    return results.data.data;
  },

  update: async (type, item, originalItem, selectors = ['id']) => {
    const results = await apolloClient.mutate({
      mutation: mutation({
        variables: { id: 'ID!', data: `${type}Input!` },
        selectors: [
          {
            name: `update${type}`,
            alias: 'data',
            args: {
              id: '$id',
              data: '$data',
            },
            selectors,
          },
        ],
      }),
      errorPolicy: 'all',
      variables: { id: item.id, data: prepareData(item, originalItem) },
    });
    if (results.errors) {
      throw getValidationErrors(results.errors);
    }
    return results.data.data;
  },

  remove: (type, id) =>
    apolloClient.mutate({
      mutation: mutation({
        variables: { id: 'ID!' },
        selectors: [
          {
            name: `delete${type}`,
            args: { id: '$id' },
            selectors: ['id'],
          },
        ],
      }),
      variables: { id },
    }),
});
