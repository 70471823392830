import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

const useStyles = makeStyles({
  banner: {
    display: 'flex',
    textAlign: 'left',
  },
  content: { backgroundColor: '#F07562' },
});

export const BannerComponent: React.FC<{
  className?: string;
  open: boolean;
}> = ({ className, open, children: message }) => {
  const classes = useStyles();
  return (
    <Snackbar
      className={className}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
    >
      <SnackbarContent
        classes={{ root: classes.content }}
        message={
          <div className={classes.banner}>
            <ErrorIcon fontSize="small" style={{ paddingRight: 10 }} />
            {message}
          </div>
        }
      />
    </Snackbar>
  );
};
