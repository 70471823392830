import gql from 'graphql-tag';

import type { Flow } from 'src/nightingale/types/types';

export const GET_ADDABLE_FLOWS = gql`
  query GetAddableFlows {
    addableFlows {
      header
      id
      label
      name
    }
  }
`;

export type QueryResult = {
  addableFlows: Flow[];
};
