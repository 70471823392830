import { gql } from 'src/generated/gql';

export const DOSE_SPOT_SSO_URL = gql(`
  query DoseSpotSsoUrl($patientId: ID) {
    doseSpotSsoUrl(patientId: $patientId)
  }
`);

export const DOSE_SPOT_PRESCRIBING_DATA_FOR_PATIENT = gql(`
  query DoseSpotPrescribingDataForPatient($patientId: ID!) {
    lastSuccessfulSyncForPatient(patientId: $patientId)
    prescribingStateForPatient(patientId: $patientId)
  }
`);

export const SUBSCRIBE_TO_DOSE_SPOT_NOTIFICATION_COUNT = gql(`
  subscription DoseSpotNotificationCountSubscription {
    doseSpotNotificationCount {
      providerId
      count
    }
  }
`);

export const DOSE_SPOT_CURRENT_NOTIFICATION_COUNT = gql(`
  query DoseSpotCurrentNotificationCount {
    getCurrentNotificationCount
  }
`);
