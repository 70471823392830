import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Styles } from '@material-ui/styles';
import classNames from 'classnames';
import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import type { Instance } from 'mobx-state-tree';
import React, { FunctionComponent, MouseEventHandler, useState } from 'react';

import Colors from 'src/nightingale/Colors';
import type { EventInstance } from 'src/stores/models/event';

export type EditFormProps = {
  item?: Instance<EventInstance>;
  classes?: {
    form?: string;
    buttons?: string;
    actions?: string;
    button?: string;
    rightIcon?: string;
    warningMessage?: string;
  };
  buttonSize?: 'large' | 'medium' | 'small';
  cancelDisabled?: boolean;
  formClass?: string;
  formId?: string;
  onCancel?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onDelete?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onNavigate?: (value: unknown) => void;
  onSave?: (values: unknown) => Promise<unknown>;
  resetAfterCancel?: boolean;
  resetAfterSave?: boolean;
  saveButtonText?: string;
  savingDisabled?: boolean;
  validationSchema?: any;
  warningMessage?: string;
};

const EditForm: FunctionComponent<EditFormProps> = ({
  buttonSize = 'medium',
  cancelDisabled,
  children,
  classes = {},
  formClass,
  formId,
  item,
  onCancel,
  onDelete,
  onNavigate,
  onSave,
  resetAfterCancel = false,
  resetAfterSave = false,
  saveButtonText = 'Save',
  savingDisabled = false,
  validationSchema,
  warningMessage,
}) => {
  const [viewButton, setViewButton] = useState(false);
  return (
    <Formik
      initialValues={item as Instance<EventInstance>}
      validationSchema={validationSchema}
      onSubmit={(values, actions) =>
        onSave
          ? onSave(values)
              .then(value => {
                if (resetAfterSave) {
                  actions.resetForm({ values });
                }
                // If on New Event modal, opens event after it is created when 'Save & View' selected
                if (viewButton && onNavigate) {
                  if (values.recurrence) {
                    onNavigate((value as any)[0]);
                  } else {
                    onNavigate(value);
                  }
                }
              })
              .catch(err => {
                if (err.graphQLErrors?.[0].validationErrors) {
                  actions.setErrors(
                    mapValues(err.graphQLErrors[0].validationErrors, value => value.message),
                  );
                  return true;
                } else {
                  return Promise.reject(err);
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              })
          : undefined
      }
    >
      {({ handleSubmit, isSubmitting, handleReset }) => (
        <form className={classNames(classes.form, formClass)} id={formId} onSubmit={handleSubmit}>
          {children}

          <span className={classes.buttons}>
            <div>
              <div className={classes.warningMessage}>{warningMessage}</div>
              <div className={classes.actions}>
                {onDelete && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size={buttonSize}
                    className={classes.button}
                    onClick={onDelete}
                    disabled={isSubmitting || savingDisabled}
                  >
                    Delete
                    <DeleteIcon className={classes.rightIcon} />
                  </Button>
                )}
                {onSave && (
                  <Button
                    variant={onNavigate ? 'outlined' : 'contained'}
                    color={onNavigate ? 'secondary' : 'primary'}
                    size={buttonSize}
                    className={classes.button}
                    disabled={isSubmitting || savingDisabled}
                    type="submit"
                    data-testid="edit-form-save"
                  >
                    {saveButtonText}
                    <SaveIcon className={classes.rightIcon} />
                  </Button>
                )}
                {onCancel && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    tabIndex="-1"
                    size={buttonSize}
                    className={classes.button}
                    onClick={event => (resetAfterCancel ? handleReset() : onCancel(event))}
                    disabled={
                      isSubmitting ||
                      (cancelDisabled === undefined ? savingDisabled : cancelDisabled)
                    }
                  >
                    Cancel
                    <CancelIcon className={classes.rightIcon} />
                  </Button>
                )}

                {onNavigate && (
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className={classes.button}
                    onClick={() => {
                      setViewButton(true);
                      handleSubmit();
                    }}
                    disabled={isSubmitting || savingDisabled}
                  >
                    Save & View
                    <EditIcon className={classes.rightIcon} />
                  </Button>
                )}
              </div>
            </div>
          </span>
        </form>
      )}
    </Formik>
  );
};

const styles: Styles<Theme, any> = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  actions: {
    float: 'right',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  warningMessage: {
    marginRight: theme.spacing(2),
    color: Colors.Coral,
  },
});

export default withStyles(styles)(EditForm);
