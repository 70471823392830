import { parseISO } from 'date-fns';

import {
  GeneralAvailabilityGraphql,
  GeneralAvailability,
  ClinicScheduleSuggestion,
  ClinicScheduleSuggestionGraphql,
} from 'src/dropInClinic/types';

const BOULDER_STATES = ['AK', 'CO', 'NC', 'OH', 'OR', 'WA'];

export const deserializeAvailabilityGraphql = (
  rawAvailability: Record<string, GeneralAvailabilityGraphql>,
): Record<string, GeneralAvailability> => {
  const generalAvailability: Record<string, GeneralAvailability> = {};

  BOULDER_STATES.forEach(state => {
    const { overview: overviewGraphql, providerAvailability: providerAvailabiltyGraphql } =
      rawAvailability[state];

    const overview = {
      windows: overviewGraphql.windows.map(({ start, end }) => ({
        start: parseISO(start),
        end: parseISO(end),
      })),
      hasAvailability: overviewGraphql.hasAvailability,
    };

    const providerAvailability = providerAvailabiltyGraphql.map(({ availability, ...rest }) => ({
      availability: { start: parseISO(availability.start), end: parseISO(availability.end) },
      ...rest,
    }));

    generalAvailability[state] = { overview, providerAvailability };
  });

  return generalAvailability;
};

export const deserializeSuggestedSchedule = (
  suggestions: ClinicScheduleSuggestionGraphql[],
): ClinicScheduleSuggestion[] =>
  suggestions.map(({ startTime, ...rest }) => ({ startTime: parseISO(startTime), ...rest }));
