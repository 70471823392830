import { inject } from 'mobx-react';
import React from 'react';
import { connectField, filterDOMProps } from 'uniforms';

import Autocomplete from 'src/components/forms/controls/autocomplete';

class AutocompleteField extends React.Component {
  constructor(props) {
    super(props);
    this.loadSource =
      typeof props.source === 'function' ? props.source : props.rootStore[props.source];
    this.state = {
      defaultOptions: null,
    };
  }

  async componentDidMount() {
    const defaultOptions = await this.loadSource();
    this.setState({ defaultOptions });
  }

  render() {
    const {
      dependsOn,
      disabled,
      error,
      errorMessage,
      label,
      labelFn,
      name,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      placeholder,
      onChange,
      showInlineError,
      value,
      valueFn,
      dataFn,
      allowOther,
      isValidNewOptionFn,
      findDependentValue,
      ...others
    } = this.props;
    const { defaultOptions } = this.state;
    const dependentValue = findDependentValue?.(dependsOn);

    const optionLoader = q => this.loadSource(q, dependentValue);

    return (
      <Autocomplete
        name={name}
        value={value}
        error={!!error}
        helperText={error && showInlineError && errorMessage}
        isDisabled={!!disabled}
        onChange={newValue => {
          if (!disabled) {
            let returnValue = newValue;
            // In some cases it maybe necessary to translate the structure of the option. The optional
            // `dataFn` property allows that translation method to be defined.
            if (dataFn) {
              returnValue = dataFn(newValue);
            }
            onChange(returnValue);
          }
        }}
        placeholder=""
        loadOptions={optionLoader}
        getOptionLabel={labelFn}
        getOptionValue={valueFn}
        creatable={allowOther}
        label={label}
        isClearable={!others.required}
        async
        defaultOptions={defaultOptions}
        menuPortalTarget={document.body}
        formatCreateLabel={inputValue => `Other: "${inputValue}"`}
        isValidNewOption={isValidNewOptionFn}
        {...filterDOMProps(others)}
      />
    );
  }
}

export default inject('rootStore')(connectField(AutocompleteField));
