import React from 'react';

export type OtherMedicationConcernsDetails = {
  haveOtherMedicationConcerns?: boolean;
  otherMedicationConcerns?: string;
};

type OtherMedicationConcernsProps = {
  visitReasonDetails: OtherMedicationConcernsDetails;
};

export const OtherMedicationConcerns = ({ visitReasonDetails }: OtherMedicationConcernsProps) => {
  if (!visitReasonDetails.haveOtherMedicationConcerns) {
    return null;
  }

  return (
    <li>
      <span className="label">Other med concern(s):</span>{' '}
      {visitReasonDetails.otherMedicationConcerns}
    </li>
  );
};
