export default {
  type: 'object',
  title: 'Note',
  properties: {
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
