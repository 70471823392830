import {
  ChartProperty,
  ChartPropertyType,
  PhiType,
  SelectAsyncOptionsSource,
} from '@boulder-package/chart-definition-types';

import {
  AnyChartProperty,
  ChartPropertyTypes,
  SelectStaticChartProperty,
  SelectAsyncChartProperty,
  ChartPropertyValue,
} from 'src/nightingale/types/types';
import logger from 'src/shared/util/logger';

// Needed until strict null checking is turned on...
function exhaustiveCheck(property: never): null {
  logger.error('Unsupported chart property type', property);
  // Note that we return null here instead of the typical throw. In some of the test data, there
  // may be ChartProperties with unsupported ChartPropertyTypes. Rather than break the app,
  // just drop the rogue property.
  return null;
}

/**
 * boulder-staff has its own ChartProperty types that are slightly different from the shared ones
 * in chart-definition-types, so convert from one to the other.
 */
export function convertChartProperty(property: AnyChartProperty): ChartProperty | null {
  // ChartProperty has some required fields that aren't required in AnyChartProperty
  const additions = {
    alwaysShowNotes: Boolean(property.alwaysShowNotes),
    allowNone: Boolean(property.allowNone),
    phiType: property.phiType as string as PhiType,
  };

  // Typescript can't convert from one discriminated union to another, so we need to use a switch
  // to narrow the type and then return the corresponding chart-definition-type.
  switch (property.type) {
    case ChartPropertyTypes.Boolean:
      return { ...property, ...additions, type: ChartPropertyType.Boolean };
    case ChartPropertyTypes.CheckboxList:
      return { ...property, ...additions, type: ChartPropertyType.CheckboxList };
    case ChartPropertyTypes.Date:
      return { ...property, ...additions, type: ChartPropertyType.Date };
    case ChartPropertyTypes.DateTime:
      return { ...property, ...additions, type: ChartPropertyType.Datetime };
    case ChartPropertyTypes.Email:
      return { ...property, ...additions, type: ChartPropertyType.Email };
    case ChartPropertyTypes.Integer:
      return { ...property, ...additions, type: ChartPropertyType.Int };
    case ChartPropertyTypes.Phone:
      return { ...property, ...additions, type: ChartPropertyType.Phone };
    case ChartPropertyTypes.LongText:
      return { ...property, ...additions, type: ChartPropertyType.LongText };
    case ChartPropertyTypes.TaggedText:
      return { ...property, ...additions, type: ChartPropertyType.TaggedText };
    case ChartPropertyTypes.Text:
      return { ...property, ...additions, type: ChartPropertyType.Text };
    case ChartPropertyTypes.Select:
      return {
        ...property,
        ...additions,
        type: ChartPropertyType.Select,
        options: (property as SelectStaticChartProperty).options,
      };
    case ChartPropertyTypes.SelectAsync:
      return {
        ...property,
        ...additions,
        type: ChartPropertyType.SelectAsync,
        optionsSource: (property as SelectAsyncChartProperty)
          .optionsSource as string as SelectAsyncOptionsSource,
      };
    case ChartPropertyTypes.SelectMulti:
      return {
        ...property,
        ...additions,
        type: ChartPropertyType.SelectMulti,
        options: (property as SelectStaticChartProperty).options,
      };
    case ChartPropertyTypes.SelectMultiAsync:
      return {
        ...property,
        ...additions,
        type: ChartPropertyType.SelectMultiAsync,
        optionsSource: (property as SelectAsyncChartProperty)
          .optionsSource as string as SelectAsyncOptionsSource,
      };
    case ChartPropertyTypes.List:
      return {
        ...property,
        ...additions,
        type: ChartPropertyType.List,
        properties: property.properties.map(convertChartProperty),
      };
    case ChartPropertyTypes.Object:
      return {
        ...property,
        ...additions,
        type: ChartPropertyType.Object,
        properties: property.properties.map(convertChartProperty),
      };
    default:
      return exhaustiveCheck(property);
  }
}

/**
 * boulder-staff expects ChartPropertyValue.value to be JSON-encoded, and the expressions
 * library doesn't, so we need to parse the values before calling the library.
 */
export function convertValue(value: ChartPropertyValue): ChartPropertyValue {
  return {
    ...value,
    value: value.value === undefined ? undefined : JSON.parse(value.value),
  };
}
