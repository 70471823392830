import { Theme, makeStyles } from '@material-ui/core';
import React from 'react';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { PATIENT_REQUIRES_SCHEDULING_QUERY } from 'src/dropInClinic/queries/patientRequiresScheduling.gql';
import Colors from 'src/nightingale/Colors';

const RequiresSchedulingWarning: React.VFC<{ patientId: string }> = ({ patientId }) => {
  const styles = useStyles();
  const { data } = useTypedSWR([PATIENT_REQUIRES_SCHEDULING_QUERY, { patientId }]);

  // Still loading or patient does not require scheduling
  if (!data?.patientRequiresScheduling) {
    return null;
  }

  return <div className={styles.banner}>Requires scheduled visits</div>;
};

const useStyles = makeStyles<Theme>(theme => ({
  banner: {
    backgroundColor: Colors.Ronchi,
    textAlign: 'center',
    fontFamily: 'Nunito Sans',
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
    lineHeight: theme.typography.body2.lineHeight,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    margin: `${theme.spacing(2)}px 0`,
  },
}));

export default RequiresSchedulingWarning;
