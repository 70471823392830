import { Button, Tooltip } from '@material-ui/core';
import { IconPillOff } from '@tabler/icons-react';
import classNames from 'classnames';
import { isValid, differenceInDays, intlFormatDistance } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';

import {
  startOfDayInBoulderTimezone,
  startOfTodayInBoulderTimezone,
} from 'src/businessHours/timezone';
import useBupeRunOut from 'src/components/general/PatientMenu/highlights/useBupeRunOut';
import { useButtonStyles } from 'src/components/general/PatientMenu/highlights/useButtonStyles';
import useTooltipStyles from 'src/components/general/PatientMenu/useTooltipStyles';
import { theme as appTheme } from 'src/theme';

const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BupeRunOut: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const tooltipStyles = useTooltipStyles();
  const styles = useButtonStyles();

  const runOutDateOnly = useBupeRunOut(patientId);
  if (!runOutDateOnly) return null;

  const runOutDate = startOfDayInBoulderTimezone(runOutDateOnly);
  if (!isValid(runOutDate)) return null;

  const today = startOfTodayInBoulderTimezone();

  const daysUntilRunOut = differenceInDays(runOutDate, today);
  const buttonClassNames = [styles.button];
  if (daysUntilRunOut < 0) {
    buttonClassNames.push(styles.error);
  } else if (daysUntilRunOut <= 2) {
    buttonClassNames.push(styles.warning);
  }

  const timeDistance =
    Math.abs(daysUntilRunOut) >= 1
      ? intlFormatDistance(runOutDate, today, { unit: 'day' })
      : 'today';

  return (
    <Tooltip
      title={`Bupe run out day: ${timeDistance}`}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a href={`/patients/${patientId}/overview#activeMedications`}>
        <Button
          className={classNames(buttonClassNames)}
          fullWidth
          size="small"
          startIcon={<IconPillOff size={appTheme.typography.fontSize} />}
          variant="outlined"
        >
          {formatInTimeZone(runOutDate, localZone, 'MM/dd/yyyy')}
        </Button>
      </a>
    </Tooltip>
  );
};

export default BupeRunOut;
