/**
 * React-select component (https://github.com/JedWatson/react-select) styled to match Material-UI. This code
 * is copied and pasted from https://material-ui.com/demos/autocomplete/#react-select.
 */
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import type { Theme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import CancelIcon from '@material-ui/icons/Cancel';
import { createStyles } from '@material-ui/styles';
import type { ClassNameMap } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import type TReactSelect from 'react-select';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import type AsyncProps from 'react-select/lib/Async';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import CreatableSelect from 'react-select/lib/Creatable';
import type CreatableProps from 'react-select/lib/Creatable';
import type { Props as SelectProps } from 'react-select/lib/Select';
import type { Option as TOption } from 'react-select/lib/filters';

import filterMatches from 'src/components/forms/controls/autocomplete.filter';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      error={props.selectProps.error}
      helperText={props.selectProps.helperText}
      required={props.selectProps.required}
      fullWidth
      InputProps={{
        inputComponent,
        disableUnderline: props.selectProps.disableUnderline,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      variant={props.selectProps.variant}
      {...props.innerProps}
    >
      {props.selectProps.getOptionLabel(props.data)}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  // Don't show the delete button if the item is marked as frozen.
  let onDelete = props.removeProps.onClick;
  if (props.selectProps.isFrozen?.(props.data)) {
    onDelete = null;
  }

  // Support onClick on individual values in the autocomplete
  function handleOnClick(evt, data) {
    if (props.selectProps.onValueClick) {
      props.selectProps.onValueClick(evt, data);
    }
  }

  return (
    <Chip
      tabIndex={-1}
      label={props.selectProps.getOptionLabel(props.data)}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onClick={evt => handleOnClick(evt, props.data)}
      onDelete={onDelete}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper elevation={2} square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const withoutIndicatorsComponents = {
  ...components,
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

type ReactSelectProps = SelectProps<TOption> & AsyncProps<TOption> & CreatableProps<TOption>;

export type AutocompleteProps = {
  async?: boolean;
  backspaceRemovesValue?: boolean;
  className: string;
  classes: ClassNameMap;
  creatable?: boolean;
  error?: TextFieldProps['error'];
  filterOption?: (option: TOption) => boolean;
  helperText?: TextFieldProps['helperText'];
  id?: string;
  isFrozen?: boolean;
  label?: string;
  labelFn: (option: TOption) => string;
  maxMenuHeight?: number;
  name?: string;
  onBlur?: TextFieldProps['onBlur'];
  onChange: TReactSelect['onChange'];
  onMenuOpen?: () => void;
  onValueClick?: (event: unknown, data: unknown) => void;
  options: TOption[];
  value?: TOption;
  valueFn: (option: TOption) => string;
  withoutIndicators?: boolean;
} & Partial<ReactSelectProps>;

const Autocomplete = React.forwardRef<ReactSelect<TOption>, AutocompleteProps>(
  (
    {
      async,
      backspaceRemovesValue,
      classes,
      creatable,
      filterOption,
      isFrozen,
      label,
      withoutIndicators,
      ...props
    }: AutocompleteProps,
    ref,
  ) => {
    const selectStyles = {
      input: base => ({
        ...base,
        color: 'inherit',
        '& input': {
          font: 'inherit',
        },
      }),
      menuPortal: base => ({ ...base, zIndex: 9999 }),
    };

    let SelectTag;
    let defaultFilter;
    if (async) {
      SelectTag = creatable ? AsyncCreatableSelect : AsyncSelect;
      defaultFilter = null;
    } else {
      SelectTag = creatable ? CreatableSelect : ReactSelect;
      defaultFilter = filterMatches;
    }

    return (
      <SelectTag
        ref={ref}
        {...props}
        theme={null}
        styles={selectStyles}
        components={withoutIndicators ? withoutIndicatorsComponents : components}
        classes={classes}
        isFrozen={isFrozen}
        filterOption={filterOption || defaultFilter}
        onValueClick={props.onValueClick}
        textFieldProps={{
          label,
          InputLabelProps: { shrink: true },
          onKeyDown: e => {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
              return;
            }
            e.stopPropagation();
          },
        }}
        backspaceRemovesValue={backspaceRemovesValue === true}
      />
    );
  },
);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
    },
    input: {
      display: 'flex',
      height: '100%',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
    },
    chip: {
      margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  });

export default withStyles(styles, { withTheme: true })(Autocomplete);
