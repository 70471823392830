import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';

/**
 * @param {title} title pass in text or html wrapped in
 * a React.Fragment to be displayed as the tooltip when
 * the user hovers over the info icon.
 */
class IconTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
    };
  }

  render() {
    const { title, children, classes, displayInline } = this.props;
    const { showTooltip } = this.state;
    return (
      <div
        onMouseEnter={() => this.setState({ showTooltip: true })}
        onMouseLeave={() => this.setState({ showTooltip: false })}
        onFocus={() => this.setState({ showTooltip: true })}
        onBlur={() => this.setState({ showTooltip: false })}
        className={classNames(classes.wrapper, { [classes.inline]: displayInline })}
      >
        {children}
        <Tooltip title={title} placement="bottom-start" interactive>
          <InfoIcon className={classNames(classes.icon, { visible: showTooltip })} />
        </Tooltip>
      </div>
    );
  }
}

Tooltip.propTypes = {
  title: PropTypes.node,
};

const styles = theme => ({
  wrapper: {
    display: 'block',
    position: 'relative',
  },
  inline: {
    display: 'inline-block',
  },
  icon: {
    color: theme.palette.secondary.light,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.2s linear, opacity 0.2s linear',
    position: 'absolute',
    right: '100%',
    top: 10,
    '&.visible': {
      visibility: 'visible',
      opacity: 1,
    },
  },
});

export default withStyles(styles)(IconTooltip);
