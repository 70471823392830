/**
 * Data store for the resourceCreate and resourceEdit pages.
 */
import { addHours, addMonths, format, startOfHour } from 'date-fns';
import isEqual from 'lodash/isEqual';
import type { Instance } from 'mobx-state-tree';
import { types, flow, getEnv, clone, getRoot, getSnapshot, applySnapshot } from 'mobx-state-tree';

import { getLatestVersion } from 'src/components/forms/schemas/versions';
import { DateType, Resource } from 'src/shared/stores/resource';
import type { Provider } from 'src/shared/stores/resource';
import {
  EVENT_STATUSES,
  getAllSubTypes,
  getAvailabilitySubTypesForRole,
} from 'src/shared/util/events';
import logger from 'src/shared/util/logger';
import { Event } from 'src/stores/models/event';
import type { EventInstance, EventResult } from 'src/stores/models/event';
import {
  CANCEL_RECURRING_EVENT,
  COUNTERSIGN,
  INVITE_EVENT_GUEST_BY_EMAIL,
  INVITE_EVENT_GUEST_BY_VISIT_CODE,
  IS_VISIT_CODE_USED,
  RESCHEDULE_EVENT,
  SETUP_EVENT_VIDEO_CONFERENCE,
  SIGN_AND_COMPLETE,
  UNCOUNTERSIGN,
  UNSIGN_AND_UNCOMPLETE,
  UPDATE_EVENT_RECURRENCE,
  VERIFY_EVENT_VISIT_CODE,
} from 'src/stores/mutations/events';
import { SEND_VIDEO_FEEDBACK } from 'src/stores/mutations/feedback';
import {
  LOAD_EVENT_INSTANCE,
  LOAD_EVENTS,
  EVENT_SUBTYPE_HAS_INTERACTION_FLOW_MAPPING,
} from 'src/stores/queries/events';
import type { UserSearchResult } from 'src/stores/queries/userSearch';
import { SUBSCRIBE_TO_UPDATED_EVENT } from 'src/stores/subscriptions/events';
import { ProviderRole } from 'src/stores/users/userType';

export const DEFAULT_VISIT_DURATION = 30;

const NEW_EVENT_DEFAULTS = {
  type: 'appointment_virtual',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  duration: DEFAULT_VISIT_DURATION,
};

export const NEW_AVAILABILITY_DEFAULTS = {
  type: 'availability',
  title: 'Available',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  duration: 60,
};

const EventsWhere = types.model({
  // Must be on or after this date
  start_gte: types.maybeNull(DateType),

  // Must be before this date
  start_lt: types.maybeNull(DateType),

  // Which types to filter for
  type_any: types.maybeNull(types.array(types.string)),

  // Which subTypes to filter for
  subType_any: types.maybeNull(types.array(types.string)),

  // Whether or not we're showing canceled/rescheduled events
  active_only: types.maybeNull(types.boolean),
});

const INQUIRIES_WHERE = {
  start_gte: new Date(0), // AKA the beginning of time AKA Jan 1 1970 00:00 UTC
  start_lt: addMonths(new Date(), 1),
  type_any: ['other', 'appointment_virtual'],
  subType_any: [
    'inquiry_webform',
    'inquiry_webform_call',
    'inquiry_inapp_call',
    'inquiry_inapp_enrollment',
  ],
  active_only: false,
};

/**
 * @todo this provides little to no actual type safety; it's simply defined in terms of what the
 * existing code expected the rootStore to be able to do, so changes to the rootStore won't be
 * reflected here. Unfortunately, until the TS errors in root.ts are fixed it isn't possible to
 * infer a type off of the actual rootStore implementation. This type declaration at least gets this
 * code into a stable state where we have type-safety for everything other than the rootStore.
 *
 * Also important to note is if we try to reference the RootStore type in this file, we will
 * introduce a circularity, since part of the Root store's definition is the Event store's definition.
 */
type RootStore = {
  generateRouteUrl(routeName: string, routeParams: Record<string, string>): string;
  searchPatients(q: string): Promise<UserSearchResult[]>;
};

// Effectively copy-pasted from the List definition, but needed something a little
// different, and inheritence isn't working correctly on the mobx-state-tree version
// that we're on.
export const EventsList = types
  .model('EventsList', {
    items: types.optional(types.array(Event), []),
    order: types.optional(types.enumeration('Order', ['asc', 'desc']), 'desc'),
    orderBy: types.optional(types.string, 'start'),
    page: types.optional(types.integer, 0),
    rowsPerPage: types.optional(types.integer, 10),
    where: types.optional(EventsWhere, {}),
  })
  .volatile(() => ({
    query: LOAD_EVENTS,
  }))
  .actions(self => ({
    load: flow(function* load() {
      const results = yield getEnv(self).apolloClient.query({
        query: self.query,
        variables: {
          first: self.rowsPerPage,
          skip: self.rowsPerPage * self.page,
          orderBy: self.orderBy ? `${self.orderBy}_${self.order.toUpperCase()}` : 'start_DESC',
          where: self.where,
        },
      });

      self.items = results.data.items;
    }),
  }))
  .actions(self => ({
    update(updates) {
      if (!isEqual(self.where, updates.where) && updates.page === undefined) {
        // eslint-disable-next-line no-param-reassign
        updates.page = 0;
      }
      Object.assign(self, updates);
      return self.load();
    },
  }));

const eventsStore = types
  .model('Events', {
    list: types.optional(EventsList, {}),
    event: types.maybeNull(Event),
    eventFromModal: types.maybeNull(Event),
    edit: types.optional(types.boolean, false),
    fixedAttendee: types.maybeNull(Resource), // todo make this a ref
  })
  .volatile<{ eventUpdated: boolean }>(() => ({
    eventUpdated: false,
  }))
  .actions(self => {
    return {
      setEventUpdated() {
        self.eventUpdated = true;
      },
    };
  })
  .actions(self => {
    let eventUpdatedSubscription;

    return {
      unsubscribeFromUpdates() {
        if (eventUpdatedSubscription) {
          eventUpdatedSubscription.unsubscribe();
        }
      },
      // eslint-disable-next-line require-yield
      subscribeToUpdates: flow(function* subscribeToUpdates() {
        if (!self.event) return;
        eventUpdatedSubscription = getEnv(self)
          .apolloClient.subscribe({
            query: SUBSCRIBE_TO_UPDATED_EVENT,
            variables: { eventId: self.event.id },
          })
          .subscribe({
            next() {
              self.setEventUpdated();
            },
            error(err) {
              console.info(err);
            },
          });
      }),
    };
  })
  .actions(self => {
    return {
      beforeDestroy() {
        self.unsubscribeFromUpdates();
      },
      cancelEditEvent() {
        self.edit = false;
        self.fixedAttendee = null;
      },
      // Action used by other stores to watch when an event has changed in order to refresh their own
      // data. Watching editEvent doesn't work because it's async, so instead anything that touches
      // an event calls this when it's done, and then other stores can watch for this action to
      // be dispatched in order to know when to reload. TODO find a cleaner way to do this.
      eventChanged() {},
    };
  })
  .actions(self => ({
    async setMandatorySections(event: EventInstance) {
      const eventSubType = event.subType;
      if (!eventSubType) {
        // Mandatory sections are defined per subtype
        return;
      }

      const allEventSubTypes = getAllSubTypes();
      const mandatorySections = allEventSubTypes[eventSubType]?.mandatorySections;
      if (!mandatorySections?.length || event.isNightingale) {
        return;
      }

      if (event.patientAttendee) {
        const results = await getEnv(self).apolloClient.query({
          query: EVENT_SUBTYPE_HAS_INTERACTION_FLOW_MAPPING,
          variables: { eventSubType },
        });
        if (results.data?.eventSubTypeHasIfm) {
          return;
        }
      }

      // If there are mandatory section types defined for the subtype this event
      // is switching to, we need to display them a certain way.
      const mandatoryEventResults: EventResult[] = [];
      let currentEventResults: EventResult[] = [...event.eventResults];

      mandatorySections.forEach(sectionType => {
        const existingSectionIndex = currentEventResults.findIndex(
          result => result.type === sectionType,
        );
        // If there is already an existing section of this type, keep it.
        if (existingSectionIndex > -1) {
          mandatoryEventResults.push(currentEventResults[existingSectionIndex]);
          currentEventResults.splice(existingSectionIndex, 1);
        } else {
          // Otherwise, we'll add in a new one.
          mandatoryEventResults.push({
            type: sectionType,
            version: getLatestVersion('eventResult', sectionType),
            results: {},
          });
        }
      });
      currentEventResults = currentEventResults.filter(
        eventResult => eventResult.results && Object.keys(eventResult.results).length > 0,
      );

      // Show the mandatory sections (with or without any existing data) first, then
      // any sections that had data defined previously
      applySnapshot(event.eventResults, mandatoryEventResults.concat(currentEventResults));
    },
  }))
  .actions<{
    saveEvent: (updates: Partial<EventInstance>, isNewEvent?: boolean) => Promise<any>;
  }>(self => {
    return {
      saveEvent: flow(function* saveEvent(updates, isNewEvent = false) {
        let ret;
        // If the event is a new event, we'll want to use the event stored in eventFromModal.
        // Otherwise, we are updating an existing event and can use self.event.
        const event = isNewEvent ? self.eventFromModal : self.event;
        if (!event) {
          console.warn('Tried saving an event without it in the store.');
          return null;
        }

        const before = clone(event);
        event.update(updates);

        if (
          event.type === 'availability' &&
          event.subType !== null &&
          event.attendees.length === 1
        ) {
          const teamRole = (event.attendees[0] as Provider).teamRole as ProviderRole | null;
          if (teamRole && !getAvailabilitySubTypesForRole(teamRole)[event.subType]) {
            event.subType = null;
          }
        }

        if (event.subType !== before.subType) {
          yield self.setMandatorySections(event);
        }

        if (before.id) {
          ret = yield getEnv(self).crudService.update('Event', event, before);
        } else if (event.isRecurring) {
          ret = yield getEnv(self).crudService.create('RecurringEvent', event);
        } else {
          ret = yield getEnv(self).crudService.create('Event', event);
        }

        self.cancelEditEvent();
        self.eventChanged();
        return ret;
      }),
    };
  })
  .actions(self => {
    return {
      createEvent(fixedAttendee, options, openEditModal = true) {
        const defaults =
          options.type === 'availability' ? NEW_AVAILABILITY_DEFAULTS : NEW_EVENT_DEFAULTS;

        const effectiveTimezone = fixedAttendee?.timezone ?? options.timezone ?? defaults.timezone;

        // Since we can create events from any page on the Staff App, only set eventFromModal to the new event.
        // This avoids clearing out a visit note (self.event), if we decide to create an event while looking at
        // a visit note.
        self.eventFromModal = Event.create({
          ...defaults,
          timezone: effectiveTimezone,
          start: startOfHour(addHours(new Date(), 1)),
          ...options,
        });
        self.edit = openEditModal;
        if (fixedAttendee) {
          self.fixedAttendee =
            fixedAttendee.__typename === 'Patient' ? clone(fixedAttendee) : undefined;
          self.eventFromModal.attendees.push(getSnapshot(fixedAttendee));
        }

        // If we're not opening the edit modal for further changes, just save it immediately (which notifies
        // the calendar of changes). This corresponds to visits of type availability.
        if (!self.edit) {
          self.saveEvent(self.eventFromModal, true);
        }
      },
      loadInquiries() {
        return self.list.update({ where: INQUIRIES_WHERE });
      },
      loadEvent: flow(function* loadEvent(id) {
        self.unsubscribeFromUpdates();
        const results = yield getEnv(self).apolloClient.query({
          query: LOAD_EVENT_INSTANCE,
          variables: { eventId: id },
        });
        self.event = results.data.eventInstance;
        self.eventUpdated = false;
        yield self.subscribeToUpdates();
      }),
      updateEventAndSave: flow(function* updateEventAndSave(event, updates) {
        const before = clone(event);
        event.update(updates);
        if (event.subType !== before.subType) {
          yield self.setMandatorySections(event);
        }
        yield getEnv(self).crudService.update('Event', event, before);

        self.event = clone(event);
        self.eventChanged();
        return self.event;
      }),
    };
  })
  .actions(self => {
    return {
      updateEventResultAndSave(results, resultIndex) {
        if (!self.event) return Promise.resolve();
        const before = clone(self.event);
        self.event.updateEventResult(resultIndex, results);
        return getEnv(self).crudService.update('Event', self.event, before);
      },
      addEventResultAndSave(eventResult) {
        if (!self.event) return Promise.resolve();
        const before = clone(self.event);
        self.event.addEventResult(eventResult);
        return getEnv(self).crudService.update('Event', self.event, before);
      },
      deleteEventResultAndSave(resultIndex) {
        if (!self.event) return Promise.resolve();
        const before = clone(self.event);
        self.event.deleteEventResult(resultIndex);
        return getEnv(self).crudService.update('Event', self.event, before);
      },
      signAndCompleteEvent: flow(function* signAndCompleteEvent(event, appointmentNotes) {
        const {
          data: {
            signAndCompleteEvent: { signedByDisplay, signedAt, status },
          },
        } = yield getEnv(self).apolloClient.mutate({
          mutation: SIGN_AND_COMPLETE,
          variables: { id: event.id, appointmentNotes },
        });

        event.update({ signedByDisplay, signedAt, status });

        self.eventChanged();
      }),
      unsignAndUncompleteEvent: flow(function* unsignAndUncompleteEvent(event) {
        const {
          data: {
            unsignAndUncompleteEvent: {
              signedByDisplay,
              signedAt,
              countersignedAt,
              countersignedByDisplay,
              status,
            },
          },
        } = yield getEnv(self).apolloClient.mutate({
          mutation: UNSIGN_AND_UNCOMPLETE,
          variables: { id: event.id },
        });

        event.update({
          signedByDisplay,
          signedAt,
          countersignedAt,
          countersignedByDisplay,
          status,
        });

        self.eventChanged();
      }),
      countersignEvent: flow(function* countersignEvent(event) {
        const {
          data: {
            countersignEvent: { countersignedByDisplay, countersignedAt },
          },
        } = yield getEnv(self).apolloClient.mutate({
          mutation: COUNTERSIGN,
          variables: { id: event.id },
        });

        event.update({ countersignedByDisplay, countersignedAt });

        self.eventChanged();
      }),
      uncountersignEvent: flow(function* uncountersignEvent(event) {
        const {
          data: {
            uncountersignEvent: { countersignedByDisplay, countersignedAt },
          },
        } = yield getEnv(self).apolloClient.mutate({
          mutation: UNCOUNTERSIGN,
          variables: { id: event.id },
        });

        event.update({ countersignedByDisplay, countersignedAt });

        self.eventChanged();
      }),
      cancelRecurrence: flow(function* cancelRecurrence(event) {
        const originalRecurrence = event.recurringEvent || event;
        if (!originalRecurrence.recurrence) {
          throw new Error('The provided event does not have recurrence defined.');
        }
        // The DateOnlyType is a string which is why the following comparison works. It's worth
        // noting that the underlying data object that the server stores is a DateOnly object.
        // https://github.com/boblauer/dateonly/blob/master/dateonly.js#L107
        if (
          originalRecurrence.recurrence.until &&
          originalRecurrence.recurrence.until < format(new Date(), 'yyyy-MM-dd')
        ) {
          throw new Error('The recurrence already has an end date in the past.');
        }

        yield getEnv(self).apolloClient.mutate({
          mutation: CANCEL_RECURRING_EVENT,
          variables: { id: event.id },
        });
        self.eventChanged();
      }),
      rescheduleEvent: flow(function* rescheduleEvent(event, data) {
        if (event.status === EVENT_STATUSES.RESCHEDULED) {
          if (
            Object.keys(data).every(key =>
              ['scheduleChangeReason', 'scheduleChangeNotes'].includes(key),
            )
          ) {
            self.updateEventAndSave(event, data);
            return event;
          } else {
            throw new Error(
              `Attempt to reschedule a previously rescheduled event with superfluous data: ${Object.keys(
                data,
              )}`,
            );
          }
        } else {
          const result = yield getEnv(self).apolloClient.mutate({
            mutation: RESCHEDULE_EVENT,
            variables: { id: event.id, data },
          });
          event.update(result.data.rescheduleEvent.originalEvent);
          self.eventChanged();
          return result;
        }
      }),
      updateRecurrence: flow(function* updateRecurrence(event, updatedEvent) {
        // Only include the properties that are valid for this mutation
        const data: Partial<EventInstance> = {
          start: updatedEvent.start,
          timezone: updatedEvent.timezone,
          duration: updatedEvent.duration,
          title: updatedEvent.title,
          type: updatedEvent.type,
          subType: updatedEvent.subType,
          attendees: updatedEvent.attendees.map(attendee => attendee.id),
          recurrence: updatedEvent.recurrence,
          status: updatedEvent.status,
          eventResults: updatedEvent.eventResults,
          scheduleChangeNotes: updatedEvent.scheduleChangeNotes,
        };
        if (updatedEvent.scheduleChangeReason) {
          // Null isn't considered a valid reason in the enum
          data.scheduleChangeReason = updatedEvent.scheduleChangeReason;
        }

        const result = yield getEnv(self).apolloClient.mutate({
          mutation: UPDATE_EVENT_RECURRENCE,
          variables: { id: event.id, data },
        });
        event.update(result.data.updateRecurrence.originalEvent);
        self.eventChanged();
        return result;
      }),
      setupEventVideoConference: flow(function* setupEventVideoConference(event) {
        if (event.vc?.sessionId) {
          return event.vc?.sessionId;
        }
        const results = yield getEnv(self).apolloClient.mutate({
          mutation: SETUP_EVENT_VIDEO_CONFERENCE,
          variables: { id: event.id },
        });
        event.update({ vc: results.data.setupEventVideoConference.vc });
        return results.data.setupEventVideoConference.vc.sessionId;
      }),
    };
  })
  .actions(self => {
    return {
      getSessionId: flow(function* getSessionId(event) {
        // a vc needs to be instantiated for a sessionId to exist
        return yield self.setupEventVideoConference(event);
      }),
      openVideoConverence: flow(function* openVideoConference(event) {
        const sessionId = yield self.setupEventVideoConference(event);
        const generatedUrl = getRoot<RootStore>(self).generateRouteUrl('eventVc', {
          event: event.id,
        });
        window.open(generatedUrl, '_blank');
        return sessionId;
      }),
      /**
       * Utility method that retrieves a single event and returns it, rather than adding it to the
       * state. TODO whether this is a good idea.
       */
      getEventInstance: flow(function* getEventInstance(eventId) {
        const results = yield getEnv(self).apolloClient.query({
          query: LOAD_EVENT_INSTANCE,
          variables: { eventId },
        });
        return results.data.eventInstance;
      }),
      isVisitCodeUsed: flow(function* isVisitCodeUsed(visitCodeId) {
        if (!visitCodeId) {
          return false;
        }
        const results = yield getEnv(self).apolloClient.mutate({
          mutation: IS_VISIT_CODE_USED,
          variables: { visitCodeId },
        });
        return results.data.staff_isVisitCodeUsed;
      }),
      /**
       * Given an event ID, return a visit code that can be used to dial into that
       * event's video conference as an external guest.
       */
      inviteEventGuestByVisitCode: flow(function* inviteEventGuestByVisitCode(eventId) {
        if (!eventId) {
          return null;
        }
        const results = yield getEnv(self).apolloClient.mutate({
          mutation: INVITE_EVENT_GUEST_BY_VISIT_CODE,
          variables: { eventId },
        });
        return results.data.staff_inviteEventGuestByVisitCode;
      }),
      /**
       * When given an email address and event ID, try to send an email to that address
       * containing a guest video URL for the event matching that ID.
       */
      inviteEventGuestByEmail: flow(function* inviteEventGuestByEmail(email, eventId) {
        if (!email || !eventId) {
          return;
        }
        yield getEnv(self).apolloClient.mutate({
          mutation: INVITE_EVENT_GUEST_BY_EMAIL,
          variables: { email, eventId },
        });
      }),
      /**
       * Given a visit code, verify if it's valid and return the guest URL for
       * the corresponding event video if so.
       */
      verifyEventVisitCode: flow(function* verifyEventVisitCode(code) {
        if (!code) {
          return '';
        }
        try {
          const results = yield getEnv(self).apolloClient.mutate({
            mutation: VERIFY_EVENT_VISIT_CODE,
            variables: { code },
          });
          return results.data.staff_verifyEventVisitCode;
        } catch (e) {
          logger.warn(e.message);
          throw e;
        }
      }),
      sendVideoFeedback: flow(function* sendVideoFeedback(input: VideoFeedbackInput) {
        let results = '';
        try {
          results = yield getEnv(self).apolloClient.mutate({
            mutation: SEND_VIDEO_FEEDBACK,
            variables: { input },
          });
        } catch (e) {
          console.error({ e });
        }
        return results;
      }),
    };
  });

type VideoFeedbackInput = {
  sessionId: string;
  providerIds: string[];
  patientId: string;
  message: string;
};

export type EventsStore = Instance<typeof eventsStore>;
export default eventsStore;
