import gql from 'graphql-tag';

import { CONVERSATION_ALL } from 'src/stores/fragments/conversations';

export const SUBSCRIBE_TO_NEW_MESSAGES = gql`
  subscription {
    newMessage {
      conversation {
        id
      }
    }
  }
`;

export const SUBSCRIBE_TO_UPDATED_CONVERSATION = gql`
  subscription {
    conversationUpdated {
      ...ConversationAll
    }
  }
  ${CONVERSATION_ALL}
`;

export const SUBSCRIBE_TO_READ_CONVERSATION = gql`
  subscription {
    conversationRead {
      conversation {
        ...ConversationAll
      }
    }
  }
  ${CONVERSATION_ALL}
`;
