// eslint-disable-next-line no-restricted-imports
import { parseISO, format } from 'date-fns';
import * as Yup from 'yup';

import { Pebble, UserNamesOnly } from 'src/pebbles/types';
import { getExactFullName } from 'src/shared/stores/resource';

export enum PebbleStatus {
  New = 'new',
  Acknowledged = 'acknowledged',
  InProgress = 'in_progress',
  OnHold = 'on_hold',
  Completed = 'completed',
  WontDo = 'wont_do',
}

export const PEBBLE_STATUS_OPTIONS: Array<{ label: string; value: PebbleStatus }> = [
  { label: 'Created', value: PebbleStatus.New },
  { label: 'Acknowledged', value: PebbleStatus.Acknowledged },
  { label: 'In Progress', value: PebbleStatus.InProgress },
  { label: 'On Hold', value: PebbleStatus.OnHold },
  { label: 'Completed', value: PebbleStatus.Completed },
  { label: "Won't Do", value: PebbleStatus.WontDo },
];

export const PEBBLE_STATUS_MAP = PEBBLE_STATUS_OPTIONS.reduce(
  (statusMap, status) => Object.assign(statusMap, { [status.value]: status.label }),
  {} as Record<PebbleStatus, string>,
);

export enum PebblePriority {
  Normal = 'normal',
  Urgent = 'urgent',
}

export const PEBBLE_PRIORITY_OPTIONS: Array<{ label: string; value: PebblePriority }> = [
  { label: 'Normal', value: PebblePriority.Normal },
  { label: 'Urgent', value: PebblePriority.Urgent },
];

export const PEBBLE_PRIORITY_MAP = PEBBLE_PRIORITY_OPTIONS.reduce(
  (priorityMap, priority) => Object.assign(priorityMap, { [priority.value]: priority.label }),
  {} as Record<PebblePriority, string>,
);

export enum PebbleTopic {
  BridgePrescriptionRequest = 'bridge_script_request',
  CaseManagementSupport = 'case_management_support',
  Chat = 'chat',
  ClinicalAdvice = 'clinical_advice',
  DrugScreeningOrder = 'drug_screening_order',
  ExtReferralHigherLevelOfCare = 'refer_higher_care',
  ExtReferralMentalHealth = 'refer_mental_health',
  ExtReferralPrimaryCare = 'refer_primary_care',
  ExternalRecords = 'external_records',
  GeneralPatientCare = 'general_patient_care',
  HousingAssistance = 'housing_assist',
  IncomingDocument = 'incoming_document',
  IntReferralCaseManagement = 'int_referral_case_management',
  Lab = 'lab',
  LabResults = 'lab_results',
  LegalAssistance = 'legal_assist',
  NewPatientIntake = 'new_patient_intake',
  Other = 'other',
  PeerAssignment = 'peer_assignment',
  Personal = 'personal',
  PriorAuth = 'prior_auth',
  Reengagement = 'reengagement',
  RxIssue = 'rx_issue',
  Scheduling = 'scheduling',
  System = 'system',
}

const DEPRECATED_TOPICS = [
  { label: 'Chat', value: PebbleTopic.Chat },
  { label: 'Ext Referral (higher level of care)', value: PebbleTopic.ExtReferralHigherLevelOfCare },
  { label: 'Ext Referral (mental health)', value: PebbleTopic.ExtReferralMentalHealth },
  { label: 'Ext Referral (primary care)', value: PebbleTopic.ExtReferralPrimaryCare },
  { label: 'Housing Assistance', value: PebbleTopic.HousingAssistance },
  { label: 'Int Referral (case management)', value: PebbleTopic.IntReferralCaseManagement },
  { label: 'Legal Assistance', value: PebbleTopic.LegalAssistance },
  { label: 'New Patient Intake', value: PebbleTopic.NewPatientIntake },
  { label: 'Reengagement', value: PebbleTopic.Reengagement },
].map(option => ({ ...option, disabled: true }));

const ACTIVE_TOPICS = [
  { label: 'Bridge Prescription Request', value: PebbleTopic.BridgePrescriptionRequest },
  { label: 'Case Management Support', value: PebbleTopic.CaseManagementSupport },
  { label: 'Clinical Advice', value: PebbleTopic.ClinicalAdvice },
  { label: 'Drug Screening Order', value: PebbleTopic.DrugScreeningOrder },
  { label: 'External Records', value: PebbleTopic.ExternalRecords },
  { label: 'General Patient Care', value: PebbleTopic.GeneralPatientCare },
  { label: 'Incoming Document', value: PebbleTopic.IncomingDocument },
  { label: 'Lab', value: PebbleTopic.Lab },
  { label: 'Lab Results', value: PebbleTopic.LabResults },
  { label: 'Peer Assignment', value: PebbleTopic.PeerAssignment },
  { label: 'Personal', value: PebbleTopic.Personal },
  { label: 'Prior Auth', value: PebbleTopic.PriorAuth },
  { label: 'Rx Issue', value: PebbleTopic.RxIssue },
  { label: 'Scheduling', value: PebbleTopic.Scheduling },
  { label: 'System', value: PebbleTopic.System },
  { label: 'Other', value: PebbleTopic.Other },
];

// Includes deprecated options, so that we can continue to display them properly.
export const PEBBLE_TOPICS = [...ACTIVE_TOPICS, ...DEPRECATED_TOPICS];

export const PEBBLE_TOPIC_MAP = PEBBLE_TOPICS.reduce(
  (topicMap, topic) => Object.assign(topicMap, { [topic.value]: topic.label }),
  {} as Record<PebbleTopic, string>,
);

export const PEBBLE_VALIDATOR = Yup.object().shape({
  title: Yup.string().nullable(),
  assignee: Yup.object().nullable().required(),
  participant: Yup.object().nullable(),
  monitoredBy: Yup.array().nullable(),
  status: Yup.string()
    .oneOf([null, ...Object.keys(PEBBLE_STATUS_MAP)])
    .nullable()
    .required(),
  topic: Yup.string()
    .oneOf([null, ...Object.keys(PEBBLE_TOPIC_MAP)])
    .nullable()
    .required(),
  note: Yup.string().nullable(),
  reminder: Yup.date().nullable(),
  priority: Yup.string().nullable(),
});

export const PEBBLE_VIEW_VALIDATOR = Yup.object().shape({
  assignee: Yup.object().nullable().required(),
  status: Yup.string()
    .oneOf([null, ...Object.keys(PEBBLE_STATUS_MAP)])
    .nullable()
    .required(),
  priority: Yup.string()
    .oneOf([null, ...Object.keys(PEBBLE_PRIORITY_MAP)])
    .nullable(),
  note: Yup.string().nullable(),
  reminder: Yup.date().nullable(),
});

export type PebbleDisplayData = {
  id: string;
  patientName: string | null;
  patientDob: string | null;
  patientState: string | null;
  assignee: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date | null;
  updatedBy: string | null;
  status: string | null;
  priority: string;
  title: string | null;
  monitors: UserNamesOnly[];
  topic: string;
  note: string;
  reminder: string | null;
  reminderDate: Date | null;
  link: string | null;
  isNewlyAssigned: (currentUserID: string) => boolean;
};

/**
 * Converts pebble data from the store into a format more convenient
 * for display in the UI. Among other adjustments this will flatten
 * all the user objects attached to the pebble down to display names.
 */
export function transformPebbleForDisplay({
  assignee,
  createdAt,
  createdBy,
  id,
  link,
  monitors,
  note,
  participant,
  priority,
  reminder,
  status,
  title,
  topic,
  updatedAt,
  updatedBy,
}: Pebble): PebbleDisplayData {
  return {
    assignee: getExactFullName(assignee ?? { firstName: 'Missing', lastName: 'User' }),
    createdAt,
    createdBy: createdBy ? getExactFullName(createdBy) : '[name unknown]',
    id,
    isNewlyAssigned: currentUserID => {
      const isNew = status === 'new';
      const assignedToMe = assignee?.id === currentUserID;
      const createdByOtherProvider = !!createdBy && createdBy.id !== currentUserID;
      return isNew && assignedToMe && createdByOtherProvider;
    },
    link: link?.includes('event') || link?.includes('conversation') ? link : null,
    monitors: monitors ?? [],
    note: note || '',
    patientName: participant ? getExactFullName(participant) : null,
    patientDob: participant?.dob ?? null,
    patientState: participant?.homeAddress?.state ?? participant?.mailingAddress?.state ?? null,
    priority: priority || '',
    reminder: reminder
      ? format(reminder instanceof Date ? reminder : parseISO(reminder), 'MM/dd/yyyy hh:mm a')
      : null,
    reminderDate: reminder,
    status,
    title,
    topic: topic || '',
    updatedAt,
    updatedBy: updatedBy ? getExactFullName(updatedBy) : null,
  };
}
