import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';

import PatientReference from 'src/components/forms/controls/PatientReference';
import ProviderReference from 'src/components/forms/controls/providerReference';
import Field from 'src/components/forms/field';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import {
  PATIENT_CONVERSATION_VALIDATOR,
  STAFF_CONVERSATION_VALIDATOR,
} from 'src/shared/client/forms/validation/conversation';

const CreateConversationDialog = ({
  onReset,
  onSubmit,
  fixedAttendees,
  createConversationWith,
  classes,
}) => {
  return (
    <Formik
      onReset={onReset}
      onSubmit={onSubmit}
      validationSchema={
        createConversationWith === 'patient'
          ? PATIENT_CONVERSATION_VALIDATOR
          : STAFF_CONVERSATION_VALIDATOR
      }
      initialValues={{}}
    >
      {({ handleSubmit, handleReset, isValid, dirty }) => (
        <ConfirmDialog
          maxWidth="sm"
          title="Add a conversation with..."
          submitLabel="Ok"
          onCancel={handleReset}
          onSubmit={handleSubmit}
          disabled={!isValid || !dirty}
        >
          {createConversationWith === 'patient' && (
            <div className={classes.addPatientField}>
              <Field
                label="Patient"
                name="conversationPatient"
                component={PatientReference}
                placeholder="Select Patient..."
              />
            </div>
          )}
          <Field
            label={createConversationWith === 'patient' ? 'Providers' : undefined}
            name="conversationProviders"
            component={ProviderReference}
            fixed={fixedAttendees}
            placeholder="Select Providers..."
            isMulti
          />
        </ConfirmDialog>
      )}
    </Formik>
  );
};

const styles = () => ({
  addPatientField: {
    paddingBottom: 20,
  },
});

export default withStyles(styles)(observer(CreateConversationDialog));
