import { makeStyles } from '@material-ui/styles';

export const useChatStyles = makeStyles(() => ({
  selected: {
    backgroundColor: 'rgb(255, 231, 219)',
  },

  conversationItem: {
    alignItems: 'center',
    display: 'grid',
    gap: '4px',
    gridTemplateAreas: '"a b b" "a c c"',
    gridTemplateColumns: 'auto 1fr',
    height: '72px',
    paddingRight: '15px',
  },

  teamIconContainer: {
    alignContent: 'center',
    display: 'flex',
    gridArea: 'a',
    justifyContent: 'center',
    justifySelf: 'center',
    width: 75,
  },

  conversationTitle: {
    alignSelf: 'end',
    display: 'flex',
    gridArea: 'b',
    justifyContent: 'space-between',
  },

  lastActivity: {
    alignSelf: 'center',
    fontSize: '12px',
  },

  lastMessage: {
    alignSelf: 'start',
    gridArea: 'c',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  hasUnread: {
    fontWeight: 'bold',
  },
}));
