import gql from 'graphql-tag';
import useSWR from 'swr';

export interface AutomationRule {
  id: string;
  title: string;
}

const QUERY = gql`
  query {
    automationRules {
      id
      title
    }
  }
`;

export const useSWRAutomationRules = (): {
  data?: { automationRules: AutomationRule[] };
  error?: Error;
} => useSWR(QUERY);
