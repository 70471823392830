import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';

import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import { ApolloClientContext } from 'src/data/ApolloClientContext';

export const VIDEO_END_KEY = 'VIDEO_END_KEY';

const completeVideo = ({ apolloClient, eventId, isVideoComplete, videoEnd }) =>
  apolloClient.mutate({
    mutation: gql`
      mutation ($data: EventInput!, $id: ID!) {
        updateEvent(data: $data, id: $id) {
          id
        }
      }
    `,
    variables: {
      data: {
        isVideoComplete,
        videoEnd,
      },
      id: eventId,
    },
  });

const VideoEndDialogV1 = () => {
  const [eventId, setEventId] = useState(null);

  const { apolloClient } = useContext(ApolloClientContext);
  const videoEndBc = new BroadcastChannel(VIDEO_END_KEY);

  videoEndBc.onmessage = async event => {
    setEventId(event.data?.id);
  };

  const isComplete = (isVideoComplete: boolean) => {
    videoEndBc.postMessage(null);

    if (!apolloClient) {
      return;
    }

    completeVideo({
      apolloClient,
      eventId,
      isVideoComplete,
      videoEnd: new Date(),
    });
  };

  return eventId ? (
    <>
      <ConfirmDialog
        onSubmit={() => isComplete(true)}
        onCancel={() => isComplete(false)}
        submitLabel="Yes, visit complete"
        cancelLabel="No, visit incomplete"
        title="Did you complete this visit successfully?"
        requireSelection
      />
    </>
  ) : (
    <></>
  );
};
export default VideoEndDialogV1;
