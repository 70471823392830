// This file was originally copied from 'uniforms-material/ListItemField'.
// Since CustomAutoField references this file as well, we have to disable
// the lint rule for dependency cycles.
import ListItemMaterial from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { Children } from 'react';
import { connectField, joinName } from 'uniforms';
import { ListDelField } from 'uniforms-material';

/* eslint-disable import/no-cycle */
import AutoField from 'src/components/forms/fields/autoField';

/* eslint-enable */

const ListItemField = ({ dense, divider, disableGutters, classes, ...props }) => (
  <ListItemMaterial
    dense={dense}
    divider={divider}
    disableGutters={disableGutters}
    className={classes.listItem}
  >
    {props.children ? (
      Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          // Only autoFocus the first element when it appears
          autoFocus: joinName(props.autoFocus, child.props.name) && index === 0,
          key: joinName(props.name, child.props.name),
          name: joinName(props.name, child.props.name),
          label: null,
        }),
      )
    ) : (
      <AutoField {...props} list />
    )}
    <ListDelField
      className={classes.deleteButton}
      name={props.name}
      icon={<CloseIcon />}
      tabIndex="-1"
    />
  </ListItemMaterial>
);

ListItemField.defaultProps = {
  dense: true,
};

const styles = () => ({
  listItem: {
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 0,
    paddingBottom: 0,
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

export default connectField(withStyles(styles)(ListItemField), {
  includeInChain: false,
  includeParent: true,
});
