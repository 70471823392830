import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { AutoFields, SubmitField } from 'uniforms-material';

import { getSchemaBridge } from 'src/components/forms';
import AutoField from 'src/components/forms/fields/autoField';
import ServerValidatedAutoForm from 'src/components/forms/fields/serverValidatedAutoForm';

const createSchema = getSchemaBridge('patient', 'create');

class PatientCreate extends Component {
  handleSave = patient => this.props.rootStore.patients.savePatient(patient);

  handleSaveSuccess = patient => {
    this.props.rootStore.routerStore.goTo('showPatient', { params: { id: patient.id } });
  };

  handleCancel = () => {
    this.props.rootStore.routerStore.goTo('patients');
  };

  render() {
    const {
      classes,
      rootStore: {
        patients: { patient },
      },
    } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h6">Create Patient</Typography>
        <Typography variant="caption">* indicates required fields</Typography>
        {patient && (
          <ServerValidatedAutoForm
            model={patient}
            schema={createSchema}
            onSubmit={this.handleSave}
            onSubmitSuccess={this.handleSaveSuccess}
            showInlineError
          >
            <AutoFields autoField={AutoField} />
            <div className={classes.buttonContainer}>
              <SubmitField className={classes.submitButton} data-testid="create-patient-save">
                Save
              </SubmitField>
              <Button
                variant="contained"
                onClick={this.handleCancel}
                data-testid="create-patient-cancel"
              >
                Cancel
              </Button>
            </div>
          </ServerValidatedAutoForm>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 400,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(PatientCreate)));
