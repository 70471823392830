import { gql } from 'src/generated/gql';

export const WAITLIST_PAYORS = gql(`
  query ProspectivePatientCountsByPayor {
    staff_getProspectivePatientCountsByPayor {
      count
      payorId
      payorName
    }
  }
`);
