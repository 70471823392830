import v100 from 'src/components/forms/schemas/eventResult/prescriberOhioChecklist/1.0.0';

const { notes, ...inheritedProperties } = { ...v100.properties };

export default {
  type: 'object',
  title: 'Prescriber: Ohio & Virginia Checklist',
  properties: {
    ...inheritedProperties,
    virginiaNoMonoProduct: {
      type: 'boolean',
      title:
        'Virginia only: No mono product to be prescribed outside of pregnancy or as bridge to combination product for no more than 7 days',
    },
    virginiaInductionMaxSuboxone: {
      type: 'boolean',
      title:
        'Virginia only: During induction, the clinician will prescribe no more than 8mg of Suboxone unless indicated and documented in the chart',
    },
    notes,
  },
  required: [],
};
