import gql from 'graphql-tag';

// This should contain the data we need to load the
// selected conversation view
export const CONVERSATION_ALL = gql`
  fragment ConversationAll on Conversation {
    id
    users {
      userDisplay {
        id
        pronouns
        firstName
        lastName
        preferredName
        userType
        patientFacingDisplayName
      }
      writing
      lastRead
      archived
    }
    lastMessageAt
    lastMessageText
    otherProviders {
      userDisplay {
        id
        pronouns
        firstName
        lastName
        userType
        patientFacingDisplayName
      }
      writing
      lastRead
    }
    closed
  }
`;

// This should contain only the data we need to load the
// preview of the conversation in the conversation list for chat
export const CONVERSATION_LIMITED = gql`
  fragment ConversationLimited on Conversation {
    id
    users {
      userDisplay {
        id
        pronouns
        firstName
        lastName
        preferredName
        userType
        patientFacingDisplayName
      }
      lastRead
      archived
    }
    lastMessageAt
    lastMessageText
    closed
  }
`;
