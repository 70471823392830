/**
 * Data store for global auth/user related concerns.
 */
import { setUser as sentrySetUser } from '@sentry/react';
import { types, flow, getEnv } from 'mobx-state-tree';

import { Resource } from 'src/shared/stores/resource';
import logger from 'src/shared/util/logger';
import { CURRENT_USER } from 'src/stores/queries/auth';
import { apiUrl } from 'src/util';

export default types
  .model('Auth', {
    user: types.maybeNull(Resource),
  })
  .volatile(() => ({
    authRedirect: null, // where should we redirect after successful login
    initialized: false,
    loginUrl: `${apiUrl}/login/callback`,
    logoutUrl: `${apiUrl}/logout`,
  }))
  .actions(self => ({
    loadUserInfo: flow(function* loadUserInfo() {
      try {
        const response = yield getEnv(self).apolloClient.query({
          query: CURRENT_USER,
        });
        self.user = response?.data.me.user ?? null;
        if (!self.user?.id) {
          return null;
        }
        sentrySetUser({ id: self.user.id });

        return self.user;
      } catch (err) {
        logger.info(err); // eg user isn't logged in
        return null;
      }
    }),
  }))
  .actions(self => ({
    // Because we can't reliably test an async afterCreate we have moved the
    // initialize logic into its own method in order to test them separately.
    initialize: flow(function* initialize() {
      yield self.loadUserInfo();
      self.initialized = true;
    }),
  }))
  .actions(self => ({
    afterCreate() {
      self.initialize();
    },
    setAuthRedirect(value) {
      self.authRedirect = value;
    },
    logout: flow(function* logout() {
      self.user = null;
      sentrySetUser(null);

      yield fetch(self.logoutUrl, { method: 'POST', credentials: 'include' });
    }),
  }));
