import gql from 'graphql-tag';

import { Interaction } from 'src/nightingale/types/types';

export const REMOVE_FLOW_FROM_INTERACTION = gql`
  mutation RemoveFlowFromInteraction($interactionReferenceId: String!, $flowId: String!) {
    removeFlowFromInteraction(interactionReferenceId: $interactionReferenceId, flowId: $flowId) {
      id
    }
  }
`;

export type QueryResult = {
  removeFlowFromInteraction: Interaction;
};
