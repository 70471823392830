import Button from '@material-ui/core/Button';
import type { ButtonProps } from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import { makeStyles } from '@material-ui/styles';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import gql from 'graphql-tag';
import MaterialTable from 'material-table';
import { observer } from 'mobx-react';
import React, { useContext, useState, useMemo } from 'react';
import useSWR from 'swr';

import PatientDocumentLink from 'src/components/general/patientDocumentLink';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import Colors from 'src/nightingale/Colors';
import { InlineHelp } from 'src/nightingale/components/ChartElement/InlineHelp';
import { SelectControl } from 'src/nightingale/components/ChartProperty/controls/Select/SelectControl';
import { PageColumn } from 'src/nightingale/components/common/PageColumn/PageColumn';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';
import { SelectOption, ChartPropertyValue } from 'src/nightingale/types/types';

const CREATE_TASK = gql`
  mutation ($id: ID!, $interactionKey: String!) {
    createTask(patientId: $id, interactionKey: $interactionKey)
  }
`;

const GET_TASKS = gql`
  query ($patientId: ID!) {
    tasks(patientId: $patientId) {
      createdAt
      completedAt
      flow {
        label
        name
        elements
      }
      taskId
    }
  }
`;

const GET_CHART_PROPERTIES = gql`
  query ($patientId: ID!, $taskId: ID!) {
    interactionChartProperties(
      patientId: $patientId
      interactionReferenceId: $taskId
      patientSnapshotTime: null
    ) {
      propertyName
      value
    }
  }
`;

type TaskInteraction = {
  createdAt: string;
  completedAt: string;
  interactionId: string;
  flow: {
    label: string;
    name: string;
  };
  taskId: string;
};

const createTaskHelpMessage = `Clicking Send below will immediately send the selected task to the patient. Do not send more than one of each task to a patient.`;

// TODO: Pull these task definitions from mongo.
// These interaction keys need to remain in sync with the tasks defined in the
// chartDefinitions.allInteractionFlowMappings collection in mongo.
// See https://github.com/bouldercare/boulder/compare/PAX-1352-query-flow-mappings-for-task-launcher
// for an incomplete implementation on populating this list using a query through
// the staffBff.
// https://bouldercare.atlassian.net/browse/PAX-1352
const interactionKeys = [
  {
    label: 'ID Card Upload',
    value: 'id_card_upload_task',
  },
  {
    label: 'Insurance Card Upload',
    value: 'insurance_card_upload_task',
  },
  {
    label: 'Letter To Someone Starting Boulder',
    value: 'letter_to_someone_starting_boulder',
  },
  {
    label: 'Nutrition',
    value: 'nutrition_fbpt',
  },
  {
    label: 'Quick Start Feedback',
    value: 'quickStartFeedbackFbpt',
  },
  {
    label: 'SMS Consent',
    value: 'sms_consents_task',
  },
  {
    label: 'Social Background',
    value: 'social_background',
  },
  {
    label: 'Patient App Feedback',
    value: 'patient_app_feedback',
  },
];

type ActionButtonProps = ButtonProps & {
  isLoading: boolean;
};

const ActionButton = ({ isLoading, children, disabled, onClick }: ActionButtonProps) => {
  const styles = useButtonStyles();
  return (
    <Button
      data-testid="chart-element-confirm-button"
      onClick={onClick}
      classes={{ root: styles.iconButton }}
      startIcon={isLoading ? <CircularProgress size={12} color="inherit" /> : <PlaylistAdd />}
      disabled={isLoading || disabled}
    >
      {children}
    </Button>
  );
};

const useButtonStyles = makeStyles({
  iconButton: {
    alignItems: 'center',
    backgroundColor: Colors.Stillwater,
    border: 'none',
    borderRadius: 0,
    color: Colors.White,
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    margin: '16px 16px 0 0',
    padding: '8px 16px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
  },
});

const PatientTaskResults = ({ patientId, taskId, flowName = '', rowData }) => {
  const styles = useTaskResultsStyles();
  const { data: { interactionChartProperties } = { interactionChartProperties: [] } } = useSWR<{
    interactionChartProperties: ChartPropertyValue[];
  }>([GET_CHART_PROPERTIES, { patientId, taskId }]);

  // Tasks with flow name idCardUpload or insuranceCardUpload do not
  // generate CPVs. They generate file uploads that will appear in the
  // patient's document tab
  const showDocumentLink = flowName.includes('CardUpload');

  return (
    <div className={styles.container}>
      {showDocumentLink ? (
        <PatientDocumentLink>Patient Documents</PatientDocumentLink>
      ) : (
        <ul>
          {interactionChartProperties.map(cpv => {
            const propertyLabelsByName = rowData.flow.elements.reduce((props, element) => {
              const elementLabelsByName = (element.properties || []).reduce(
                (labelsByName, prop) => ({ ...labelsByName, [prop.name]: prop.label }),
                {},
              );
              return { ...props, ...elementLabelsByName };
            }, {});
            return (
              <li key={`${taskId}:${cpv.propertyName}`}>
                {`${propertyLabelsByName[cpv.propertyName] || cpv.propertyName}: ${cpv.value}`}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const useTaskResultsStyles = makeStyles({
  container: {
    padding: '8px 16px',
  },
});

const PatientTasks: React.FC<{ patientId: string }> = ({ patientId }) => {
  const styles = useStyles();
  const interactionByKey = useMemo(() => {
    return interactionKeys.reduce((acc, curr) => ({ ...acc, [curr.value]: curr }), {});
  }, [interactionKeys]);
  const [interactionKey, setInteractionKey] = useState<string | null | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { apolloClient } = useContext(ApolloClientContext);

  const { data: { tasks } = { tasks: [] }, mutate: mutateTasks } = useSWR<{
    tasks: TaskInteraction[];
  }>([GET_TASKS, { patientId }]);

  const handleCreateTask = async () => {
    if (interactionKey) {
      setIsLoading(true);
      try {
        await apolloClient?.mutate({
          mutation: CREATE_TASK,
          variables: { id: patientId, interactionKey },
        });
        setInteractionKey(undefined);
        // This will mutate the local swr cache for the GET_TASKS query
        // which will cause a re-validation of the tasks data. We do this
        // to force the task table to update after a new task has been
        // created via the task launcher.
        mutateTasks({ tasks });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <PageColumn>
      <section className={styles.section}>
        <PageTitle>Patient Tasks</PageTitle>

        <Card className={styles.cardContainer}>
          <CardHeader
            title="Add a Patient Task"
            classes={{ title: styles.header, root: styles.cardHeaderContainer }}
          />
          <CardContent className={styles.cardContentContainer}>
            <InlineHelp helpText={createTaskHelpMessage} />
            <SelectControl
              options={interactionKeys}
              onChangeValue={(option: SelectOption) => {
                if (option?.value) {
                  setInteractionKey(option.value);
                } else if (interactionKey !== undefined) {
                  setInteractionKey(undefined);
                }
              }}
              allowMultiple={false}
              value={interactionKey && interactionByKey[interactionKey]}
              label="Select a task to send to the patient"
            />
            <ActionButton
              isLoading={isLoading}
              disabled={!interactionKey}
              onClick={handleCreateTask}
            >
              Send Task to Patient
            </ActionButton>
          </CardContent>
        </Card>
      </section>
      <section className={styles.section}>
        <MaterialTable
          data={tasks}
          columns={[
            {
              title: 'Task Type',
              field: 'flow.label',
              render: rowData => <div>{rowData.flow.label}</div>,
              sorting: false,
            },
            {
              title: 'Created At',
              field: 'createdAt',
              render: rowData => (
                <div>
                  {rowData.createdAt
                    ? formatInTimeZone(
                        parseISO(rowData.createdAt),
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'yyyy-MM-dd zzz',
                      )
                    : null}
                </div>
              ),
              defaultSort: 'desc',
            },
            {
              title: 'Completed At',
              field: 'completedAt',
              render: rowData => (
                <div>
                  {rowData.completedAt
                    ? formatInTimeZone(
                        parseISO(rowData.completedAt),
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'yyyy-MM-dd zzz',
                      )
                    : null}
                </div>
              ),
            },
          ]}
          isLoading={isLoading}
          detailPanel={[
            rowData => ({
              disabled: !rowData.completedAt,
              render: () => (
                <PatientTaskResults
                  patientId={patientId}
                  taskId={rowData.taskId}
                  flowName={rowData.flow.name}
                  rowData={rowData}
                />
              ),
            }),
          ]}
          onRowClick={(event, rowData, togglePanel) =>
            rowData?.completedAt ? togglePanel?.() : () => {}
          }
          options={{
            toolbar: false,
            paging: false,
            filtering: false,
            search: false,
            headerStyle: { display: 'default' },
            sorting: true,
          }}
        />
      </section>
    </PageColumn>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    backgroundColor: Colors.White,
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: 30,
    overflow: 'visible',
    padding: 16,
  },
  cardHeaderContainer: {
    padding: 0,
  },
  cardContentContainer: {
    padding: 0,
    position: 'relative',
    '&:last-child': { paddingBottom: 0 },
  },
  section: {
    marginBottom: 60,
  },
  header: {
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '17.4px',
    '&, &.Mui-error, &.Mui-focused': {
      color: Colors.BlueSpruce,
    },
    transform: 'scale(1)',
    position: 'static',
  },
  submitButton: {
    marginTop: 10,
  },
});

export default observer(PatientTasks);
