import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import useSWR from 'swr';

import Colors from 'src/nightingale/Colors';
import { PEBBLE_COUNT_QUERY, PebblesCountGqlResult } from 'src/pebbles/queries';

const usePebbleCountStyles = makeStyles(theme => ({
  pebbleBar: {
    padding: `0 ${theme.spacing(1)}px`,
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  pebbleBarError: {
    color: Colors.Coral,
  },
}));

export const PebbleCountForPatient = ({ participantId }) => {
  const classes = usePebbleCountStyles();

  const { data, error } = useSWR<PebblesCountGqlResult>([
    PEBBLE_COUNT_QUERY,
    {
      participantId,
    },
  ]);

  if (error) {
    return (
      <div className={`${classes.pebbleBar} ${classes.pebbleBarError}`}>
        Could not load Pebble count
      </div>
    );
  }

  return <div className={classes.pebbleBar}>Active Pebbles: {data?.pebblesCount ?? '-'}</div>;
};
