import throttle from 'lodash/throttle';
import { inject, observer } from 'mobx-react';
import React from 'react';

import UserSelectItem from 'src/components/forms/UserSelectItem';
import ReferenceControl from 'src/components/forms/controls/reference';
import { getExactFullName } from 'src/shared/stores/resource';
import type { UserSearchResult } from 'src/stores/queries/userSearch';

// A search term with fewer characters than this is probably too short to have a good chance of
// finding the patient you want, so we won't even bother trying. The value is arbitrarily chosen.
const MINIMUM_SEARCH = 3;
// Don't search more often than every half-second. Again, arbitrarily chosen, just based on what
// felt right while typing.
const SEARCH_INTERVAL = 500;

const PatientReference = ({ rootStore: { searchPatients }, ...props }) => {
  const throttledSearch: (
    q: string,
    resolve: (result: UserSearchResult) => void,
    reject: (reason?: unknown) => void,
  ) => Promise<UserSearchResult> = throttle(
    (q, resolve, reject) => searchPatients(q).catch(reject).then(resolve),
    SEARCH_INTERVAL,
    {
      leading: true,
      trailing: true,
    },
  );

  const loadOptions = (searchTerm: string) => {
    if (!searchTerm || searchTerm.length < MINIMUM_SEARCH) {
      // Need to return a promise instead of a bare value or the autocomplete gets stuck on
      // "loading..." instead of "no results"
      return Promise.resolve([]);
    }

    /**
     * Debouncing async functions with { trailing: true } is a little problematic: a caller that
     * gets debounced will receive the promise returned by the most recent invocation of the
     * underlying function, and the promise returned by the trailing-edge invocation of the
     * underlying function will be discarded. This works around the problem by passing resolve and
     * reject functions into the throttled function, so the promise returned here will be resolved
     * as a side-effect. Note that intermediate calls--the ones that get a debounced result--will
     * receive a promise that never resolves. That should be fine in the case of a select since it
     * only cares about the last invocation, but it's something to keep in mind if you're thinking
     * of generalizing this code.
     * @see { @link https://whimsical.com/trailing-debounce-async-DMySJuKGvRiFPv5JNw5zZp } for a
     * hopefully-helpful diagram of the problem.
     */
    return new Promise((resolve, reject) => {
      throttledSearch(searchTerm, resolve, reject);
    });
  };

  return (
    <ReferenceControl
      {...props}
      valueFn={option => option.id}
      labelFn={option => getExactFullName(option)}
      formatLabelFn={person => <UserSelectItem person={person} />}
      loadOptions={loadOptions}
    />
  );
};
export default inject('rootStore')(observer(PatientReference));
