import React from 'react';

export const SCALE = {
  0: 'Not at all',
  1: 'A little',
  2: 'Moderately',
  3: 'Quite a bit',
  4: 'Extremely',
};

const selectTemplate = {
  type: 'string',
  enum: Object.keys(SCALE),
  uniforms: {
    transform: option => SCALE[option],
  },
  renderer: {
    component: ({ model, key }) => <>{SCALE[model[key]]}</>,
  },
};

export default {
  type: 'object',
  title: 'Subjective Opiate Withdrawal Scale (SOWS)',
  properties: {
    sowsScore: {
      type: 'integer',
      displayOnly: true,
      renderer: {
        component: ({ model }) => (
          <>{Object.values(model).reduce((a, b) => a + (b ? parseInt(b, 10) : 0), 0)}</>
        ),
      },
    },
    anxious: { ...selectTemplate, title: 'I feel anxious' },
    yawning: { ...selectTemplate, title: 'I feel like yawning' },
    perspiring: { ...selectTemplate, title: 'I am perspiring' },
    tearing: { ...selectTemplate, title: 'My eyes are tearing' },
    runny_nose: { ...selectTemplate, title: 'My nose is running' },
    goosebumps: { ...selectTemplate, title: 'I have goosebumps' },
    shaking: { ...selectTemplate, title: 'I am shaking' },
    hot_flushes: { ...selectTemplate, title: 'I have hot flushes' },
    cold_flushes: { ...selectTemplate, title: 'I have cold flushes' },
    bone_muscle_aches: { ...selectTemplate, title: 'My bones and muscles ache' },
    restless: { ...selectTemplate, title: 'I feel restless' },
    nauseous: { ...selectTemplate, title: 'I feel nauseous' },
    vomiting: { ...selectTemplate, title: 'I feel like vomiting' },
    twitch: { ...selectTemplate, title: 'My muscles twitch' },
    cramps: { ...selectTemplate, title: 'I have stomach cramps' },
    using: { ...selectTemplate, title: 'I feel like using now' },
  },
  required: [],
  omitFields: ['sowsScore'],
};
