import gql from 'graphql-tag';

import { FILE_SUMMARY } from 'src/stores/fragments/files';
import { PATIENT_ALL, PATIENT_LIMITED } from 'src/stores/fragments/patients';

export const LOAD_PATIENTS = gql`
  query Patients(
    $first: Int
    $skip: Int
    $orderBy: PatientOrderByInput
    $where: PatientWhereInput
  ) {
    items: patients(orderBy: $orderBy, paging: { first: $first, skip: $skip }, where: $where) {
      ...PatientLimited
    }
  }
  ${PATIENT_LIMITED}
`;

export const LOAD_PATIENT_LIMITED = gql`
  query PatientLimited($id: ID!) {
    patient(id: $id) {
      ...PatientLimited
    }
  }
  ${PATIENT_LIMITED}
`;

// The patient documents page currently contains the treatment agreement and hrqol components -
// features which used to exist on the patient overview but were relocated as part of nightingale
// phase 1
export const LOAD_PATIENT_DOCUMENTS = gql`
  query PatientDocuments($id: ID!) {
    patient(id: $id) {
      treatmentAgreement {
        createdAt
        completedAt
      }
      preferenceSendHrqolSurveys
    }
    signedDocuments(user: $id) {
      document {
        name
        version
      }
      signedAt
    }
    chartDocumentsByPatient(patient: $id) {
      id
      file {
        ...FileSummary
      }
      title
      type
      date
      description
    }
    insuranceRoiRequest(participantId: $id) {
      requestId
      recipient {
        name
      }
      createdAt
      completedAt
      documentVersion
      isDeleted
    }
    hrqolSurveys(patientId: $id) {
      id
      completedAt
      generalHealth
      physicalHealth
      mentalHealth
      missedActivities
      recoveryProgress
      additionalInfo
    }
  }
  ${FILE_SUMMARY}
`;

export const LOAD_PATIENT_OVERVIEW = gql`
  query PatientOverview($id: ID!) {
    patient(id: $id) {
      ...PatientAll
    }
  }
  ${PATIENT_ALL}
`;
