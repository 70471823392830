import Typography from '@material-ui/core/Typography';
import React from 'react';

import v110Schema from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.1.0';

// Due to a bug in how the CreatableSelect field works, it's necessary to translate the react-select
// `option` structure to the internal structure that we use ( `{name:..., key:...}` ). The bug is
// detailed here: https://github.com/JedWatson/react-select/issues/2630
const labelFn = option => (option.__isNew__ ? option.label : option.name);
const valueFn = option => (option.__isNew__ ? option.value : option.key);
const dataFn = newValue => (newValue?.__isNew__ ? { name: newValue.value } : newValue);
const isValidNewOptionFn = (inputValue, selectValue, selectOptions) =>
  !(inputValue.trim().length === 0 || selectOptions.find(option => option.name === inputValue));

// Remove the old currentDiagnois property
const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentDiagnosis,
  ...v110Properties
} = v110Schema.properties;

export default {
  ...v110Schema,
  properties: {
    medicalProblems: {
      type: 'array',
      title: 'Current diagnosis',
      renderer: {
        /* eslint-disable react/no-array-index-key */
        component: ({ model, classes }) => (
          <ul>
            {model.medicalProblems.map((item, index) => (
              <li key={`${item.problem?.key}-${index}`}>
                <span className={!item.current ? classes.strikethrough : null}>
                  {item.problem?.name}
                </span>
                {item.notes && (
                  <Typography variant="body2" className={classes.multiLineText}>
                    {item.notes}
                  </Typography>
                )}
              </li>
            ))}
          </ul>
        ),
        /* eslint-enable */
      },
      items: {
        type: 'object',
        properties: {
          problem: {
            type: ['null', 'object'],
            autocomplete: true,
            allowOther: true,
            uniforms: {
              backspaceRemovesValue: true,
              labelFn,
              valueFn,
              dataFn,
              isValidNewOptionFn,
              source: 'searchMedicalConditions',
            },
          },
          notes: {
            type: 'string',
            uniforms: {
              multiline: true,
            },
          },
          current: {
            type: 'boolean',
            default: true,
          },
        },
      },
    },
    ...v110Properties,
  },
};
