"use strict";

var _handlebars = _interopRequireDefault(require("handlebars"));
var _age = require("./age");
var _containsany = require("./containsany");
var _count = require("./count");
var _dateadd = require("./dateadd");
var _datediff = require("./datediff");
var _datediffcalendar = require("./datediffcalendar");
var _datediffhuman = require("./datediffhuman");
var _dateformat = require("./dateformat");
var _datetimeformat = require("./datetimeformat");
var _displayraw = require("./displayraw");
var _eq = require("./eq");
var _gt = require("./gt");
var _gte = require("./gte");
var _ifall = require("./ifall");
var _ifany = require("./ifany");
var _ifnil = require("./ifnil");
var _join = require("./join");
var _length = require("./length");
var _lt = require("./lt");
var _lte = require("./lte");
var _map = require("./map");
var _ne = require("./ne");
var _notes = require("./notes");
var _if = require("./if");
var _plural = require("./plural");
var _small = require("./small");
var _sum = require("./sum");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_handlebars.default.registerHelper('age', _age.age);
_handlebars.default.registerHelper('containsany', _containsany.containsAny);
_handlebars.default.registerHelper('count', _count.count);
_handlebars.default.registerHelper('dateadd', _dateadd.dateadd);
_handlebars.default.registerHelper('datediff', _datediff.datediff);
_handlebars.default.registerHelper('datediffcalendar', _datediffcalendar.datediffcalendar);
_handlebars.default.registerHelper('datediffhuman', _datediffhuman.datediffhuman);
_handlebars.default.registerHelper('dateformat', _dateformat.dateformat);
_handlebars.default.registerHelper('datetimeformat', _datetimeformat.datetimeformat);
_handlebars.default.registerHelper('displayraw', _displayraw.displayraw);
_handlebars.default.registerHelper('eq', _eq.eq);
_handlebars.default.registerHelper('gt', _gt.gt);
_handlebars.default.registerHelper('gte', _gte.gte);
_handlebars.default.registerHelper('if', _if.overloadIf);
_handlebars.default.registerHelper('ifall', _ifall.ifall);
_handlebars.default.registerHelper('ifany', _ifany.ifany);
_handlebars.default.registerHelper('ifnil', _ifnil.ifnil);
_handlebars.default.registerHelper('join', _join.join);
_handlebars.default.registerHelper('length', _length.length);
_handlebars.default.registerHelper('lt', _lt.lt);
_handlebars.default.registerHelper('lte', _lte.lte);
_handlebars.default.registerHelper('map', _map.mapHelper);
_handlebars.default.registerHelper('ne', _ne.ne);
_handlebars.default.registerHelper('notes', _notes.notes);
_handlebars.default.registerHelper('plural', _plural.plural);
_handlebars.default.registerHelper('small', _small.small);
_handlebars.default.registerHelper('sum', _sum.sumHelper);