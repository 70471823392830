import React from 'react';

import { labelForEventType } from 'src/shared/util/events';

type EventTypeLabelProps = {
  type?: string | null;
  subType: string;
};

export const EventTypeLabel = ({ type, subType }: EventTypeLabelProps) => {
  return <span>{labelForEventType(type ?? null, subType)}</span>;
};
