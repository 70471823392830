import noop from 'lodash/noop';
import React from 'react';

import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import PhoneInput from 'src/nightingale/components/ChartProperty/controls/Phone/PhoneInput';
import { TextInput } from 'src/nightingale/components/ChartProperty/controls/Text/TextInput';
import { useDidUpdateEffect } from 'src/nightingale/hooks/useDidUpdateEffect';
import { useShowValidationError } from 'src/nightingale/hooks/useShowValidationError';
import { useValidationForPropertyType } from 'src/nightingale/hooks/useValidation';
import { ControlProps, PhoneChartProperty, ChartPropertyTypes } from 'src/nightingale/types/types';

export type PhoneControlProps = ControlProps<PhoneChartProperty> & {
  id?: string;
  inputProps?: any;
};

/**
 * Phone Control component. Text Control component with phone format validation.
 */
export const PhoneControl: React.FC<PhoneControlProps> = ({
  description,
  hasEmptyValue,
  inputProps,
  isRequired,
  label,
  onChangeValue = noop,
  onError,
  value,
  ...rest
}) => {
  const validationError = useValidationForPropertyType(value, ChartPropertyTypes.Phone);
  const [showError, setIsTouched, setHasSeenErrors] = useShowValidationError(value);
  useDidUpdateEffect(() => {
    const hasValidationError = !!validationError?.errors?.length;
    if (hasValidationError) setHasSeenErrors(true);
    if (onError) onError(hasValidationError);
  }, [validationError]);

  const onAccept = (newVal: string) => {
    if (newVal !== value) {
      onChangeValue(newVal);
    }
  };

  const hasError = showError && !!validationError?.errors?.length;
  const errorMessage = showError ? validationError?.message : '';

  return (
    <LabeledFormControl
      error={hasError}
      errorMessage={errorMessage}
      hasEmptyValue={hasEmptyValue}
      htmlFor={rest.id}
      isRequired={isRequired}
      label={label}
    >
      <TextInput
        inputComponent={PhoneInput}
        inputProps={{ ...inputProps, onAccept }}
        onBlur={() => setIsTouched(true)}
        placeholder={description}
        value={value}
        {...rest}
      />
    </LabeledFormControl>
  );
};
