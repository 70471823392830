import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import React from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import { DropInClinicPatient } from 'src/dropInClinic/types';
import { colors } from 'src/util/colors';

type PatientDetailsProps = {
  patient: DropInClinicPatient;
  categories?: string[];
};

export const useStyles = makeStyles(() => ({
  additionalPatientInfo: {
    color: colors.mediumGray,
    fontSize: '80%',
    lineHeight: '80%',

    // Comma-separating the different infos
    '& > ::before': {
      content: '", "',
    },
    '& :first-child::before': {
      content: '""',
    },
  },
  tagChip: {
    fontSize: '75%',
    height: 'auto',
    padding: 1,
    marginLeft: 8,
    '& + &': {
      marginLeft: 4,
    },
  },
}));

export const PatientDetails = ({ patient, categories = [] }: PatientDetailsProps) => {
  const classes = useStyles();

  return (
    <div>
      <a href={generateRouteUrl('showPatient', { id: patient.id })}>
        {patient.firstName}{' '}
        {patient.preferredName && <strong>{`"${patient.preferredName}" `}</strong>}
        {patient.lastName}
      </a>
      {categories.map(category => (
        <Chip key={category} className={classes.tagChip} label={category} size="small" />
      ))}
      <div className={classes.additionalPatientInfo}>
        {patient.homeState && <strong>{patient.homeState}</strong>}
        {isValid(patient.dob) && <span>{format(patient.dob as Date, 'MM/dd/yy')}</span>}
      </div>
    </div>
  );
};
