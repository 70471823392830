import React from 'react';

import { states } from 'src/components/forms/schemas/definitions';
import v100Schema from 'src/components/forms/schemas/eventResult/peerCoachTelehealthPrecheck/1.0.0';
import PatientOverviewLink from 'src/components/general/patientOverviewLink';

// Remove the old patientLocation property to remove police phone, and add new checkbox property
const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  patientLocation,
  ...v100Properties
} = v100Schema.properties;

export default {
  ...v100Schema,
  properties: {
    ...v100Properties,
    // Note: patientLocation properties have been copied from v1.0.0, but with renderer
    // redefined to remove police phone, which is added as a separate property
    patientLocation: {
      type: 'object',
      tooltip: '"Are you at your primary residence?"',
      renderer: {
        component: ({ model, classes }) => (
          <div className={classes.itemDetails}>
            <span>
              {model.patientLocation.city}
              {model.patientLocation.city && model.patientLocation.state && ', '}
              {model.patientLocation.state}
            </span>
          </div>
        ),
      },
      properties: {
        city: {
          type: 'string',
        },
        state: {
          type: 'string',
          enum: states,
        },
      },
    },
    confirmedLocalPD: {
      type: 'boolean',
      title: 'Confirmed Local Police Department',
      label: (
        <span>
          Confirmed{' '}
          <PatientOverviewLink section="services">Local Police Department</PatientOverviewLink>
        </span>
      ),
    },
  },
};
