import gql from 'graphql-tag';

export const MARK_READ = gql`
  mutation MarkRead($conversationId: ID!, $value: DateTime!) {
    updateConversationLastRead(id: $conversationId, value: $value) {
      id
    }
  }
`;

export const UPDATE_ARCHIVED = gql`
  mutation UpdateArchived($conversationId: ID!, $value: Boolean!) {
    updateConversationArchived(id: $conversationId, value: $value) {
      id
    }
  }
`;

export const UPDATE_ARCHIVED_FOR_ALL = gql`
  mutation UpdateArchivedForAll($conversationId: ID!, $value: Boolean!) {
    updateConversationArchivedForAll(id: $conversationId, value: $value) {
      id
    }
  }
`;

export const UPDATE_CONVERSATION_CLOSED = gql`
  mutation UpdateConversationClosed($conversationId: ID!, $value: Boolean!) {
    updateConversationClosed(id: $conversationId, value: $value) {
      closed
    }
  }
`;

export type UpdateConversationClosedResult = {
  updateConversationClosed: {
    closed: boolean | null;
  };
};
