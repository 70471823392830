import { format } from 'date-fns';
import partition from 'lodash/partition';
import { types, flow, getEnv, getRoot, addMiddleware } from 'mobx-state-tree';

import { getCalendarRange } from 'src/calendars';
import { EVENT_STATUSES } from 'src/shared/util/events';
import { Event } from 'src/stores/models/event';
import { LOAD_EVENT_INSTANCES_FOR_CALENDAR_VIEW } from 'src/stores/queries/events';

export default types
  .model('Calendar', {
    userId: types.optional(types.string, ''), // todo try a ref
    start: types.optional(types.Date, new Date()),
    end: types.optional(types.Date, new Date()),
    eventInstances: types.optional(types.array(Event), []),
    availableInstances: types.optional(types.array(Event), []),
    currentDate: types.optional(types.Date, new Date()),
    currentView: types.optional(types.string, 'week'),
    showCanceledRescheduledEvents: types.optional(types.boolean, false),
    showAllDayEvents: types.optional(types.boolean, true),
  })
  .views(self => ({
    // The syntax actually used in the routerStore.goTo and the URL
    get currentDateQueryParam() {
      return self.currentDate && format(self.currentDate, 'yyyy-MM-dd');
    },
    eventInstanceById(id) {
      return self.eventInstances.find(event => event.id === id);
    },
    availableInstanceById(id) {
      return self.availableInstances.find(event => event.id === id);
    },
    instanceById(id) {
      return self.eventInstanceById(id) || self.availableInstanceById(id);
    },
  }))
  .actions(self => ({
    afterAttach() {
      addMiddleware(getRoot(self).events, (call, next) => {
        if (call.name === 'eventChanged') {
          self.load();
        }
        next(call);
      });
    },
    updateDateRange() {
      const [start, end] = getCalendarRange(self.currentDate, self.currentView);
      self.start = start;
      self.end = end;
    },
    setCurrentDateAndView(date, view) {
      if (date) {
        self.currentDate = date;
      }
      if (view) {
        self.currentView = view;
      }
      self.updateDateRange();
    },
    load: flow(function* load(after, before) {
      if (after) {
        self.start = after;
      }
      if (before) {
        self.end = before;
      }

      if (self.start && self.end && self.userId) {
        const results = yield getEnv(self).apolloClient.query({
          query: LOAD_EVENT_INSTANCES_FOR_CALENDAR_VIEW,
          variables: {
            user: self.userId,
            after: self.start,
            before: self.end,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
        const { eventInstances } = results.data;

        // A lot of old code depends on the GraphQL __typename attribute to discern patients from
        // providers. The UserDisplay type breaks that. Work around by faking the __typename.
        eventInstances.forEach(event => {
          event.attendees.forEach(attendee => {
            if (attendee?.__typename === 'UserDisplay') {
              // eslint-disable-next-line no-param-reassign
              attendee.__typename = attendee.userType;
            }
          });
        });

        const partitionedEventInstances = partition(
          eventInstances,
          eventInstance => eventInstance.type === 'availability',
        );
        let newAvailableInstances;
        [newAvailableInstances, self.eventInstances] = partitionedEventInstances;
        self.availableInstances = newAvailableInstances.filter(
          event => event.status !== EVENT_STATUSES.CANCELED,
        );
      }
    }),
    loadDateAndView: flow(function* loadDateAndView(date, view) {
      self.setCurrentDateAndView(date, view);
      yield self.load();
    }),
    setShowCanceledRescheduledEvents(showCanceled) {
      self.showCanceledRescheduledEvents = showCanceled;
    },
    setShowAllDayEvents(showAllDay) {
      self.showAllDayEvents = showAllDay;
    },
  }));
