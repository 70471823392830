import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import v110Schema from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.1.0';

// Update the copy to reflect ineligibility due to not seeking either OUD or
// AUD treatment
// Versioned so that past assessments are not taken to reflect information not
// recorded at the time (AUD treatment-seeking)
const newIneligibleCopy = 'Ineligible (not seeking OUD or AUD treatment)';
const updatePath = 'properties.outcome.properties.ineligibleNotSeekingOudTreatment.title';

const v111Schema = cloneDeep(v110Schema);
set(v111Schema, updatePath, newIneligibleCopy);

export default v111Schema;
