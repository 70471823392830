import useSWR from 'swr';

import type { Pebble, PebbleHistory } from 'src/pebbles/types';
import { LOAD_FULL_PEBBLE } from 'src/stores/queries/pebbles';

const preparePebbleHistory = (history: PebbleHistory) => {
  const transformedHistory = history;
  Object.keys(history).forEach(key => {
    if (Array.isArray(history[key])) {
      transformedHistory[key] = history[key].map(historyItem => ({
        ...historyItem,
        updatedAt: new Date(historyItem.updatedAt),
      }));
    }
  });

  return transformedHistory;
};

const preparePebble = (pebble: Pebble) => {
  return {
    ...pebble,
    ...(pebble.createdAt && { createdAt: new Date(pebble.createdAt) }),
    ...(pebble.updatedAt && { updatedAt: new Date(pebble.updatedAt) }),
    ...(pebble.history && { history: preparePebbleHistory(pebble.history) }),
  };
};

const useSWRPebble = (id: string) => {
  const { data: { pebble: pebbleResult } = {}, mutate } = useSWR([LOAD_FULL_PEBBLE, { id }], {
    revalidateIfStale: false,
  });

  return {
    data: pebbleResult ? preparePebble(pebbleResult) : undefined,
    mutatePebble: mutate,
  };
};

export const __test__ = { preparePebble };

export default useSWRPebble;
