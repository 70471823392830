import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import React, { useContext } from 'react';

import Tooltip from 'src/components/general/Tooltip';
import RouterLink from 'src/components/general/routerLink';
import { DoseSpotNotificationsContext } from 'src/prescribing/doseSpot/components/DoseSpotNotificationsContext';
import { DoseSpotNotificationsProvider } from 'src/prescribing/doseSpot/components/DoseSpotNotificationsProvider';
import { colors } from 'src/util/colors';

const NotificationsIcon = () => {
  const classes = useStyles();
  const { count } = useContext(DoseSpotNotificationsContext);

  return (
    <RouterLink routeName="erx">
      <IconButton data-testid="dose-spot-notifications-icon">
        <Badge
          badgeContent={count}
          classes={{
            badge: classes.badgeColor,
          }}
        >
          <Tooltip title="DoseSpot">
            <LocalPharmacyIcon className={classes.icon} />
          </Tooltip>
        </Badge>
      </IconButton>
    </RouterLink>
  );
};

export const DoseSpotNotificationsIcon = () => (
  <DoseSpotNotificationsProvider>
    <NotificationsIcon />
  </DoseSpotNotificationsProvider>
);

const useStyles = makeStyles({
  icon: {
    color: 'white',
    marginTop: -5,
  },
  badgeColor: {
    backgroundColor: colors.orange,
    color: 'white',
    marginTop: -3,
  },
});
