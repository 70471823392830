import {
  AutomationJobsFilter,
  AutomationJobStatus,
} from 'src/components/pages/automation/useSWRAutomationJobs';

export interface QueryParams {
  filter: AutomationJobsFilter;
  page: number;
  rowsPerPage: number;
}

export function parseQueryParams(queryParams: Record<string, string>): QueryParams {
  return {
    filter: {
      date_gte: queryParams.date_gte,
      status: queryParams.status as AutomationJobStatus,
      rule: queryParams.rule,
      patient: queryParams.patient,
    },
    page: parseInt(queryParams.page, 10) || 0,
    rowsPerPage: parseInt(queryParams.rowsPerPage, 10) || 10,
  };
}
