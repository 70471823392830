import { types } from 'mobx-state-tree';
import type { Instance } from 'mobx-state-tree';

import { DateType } from 'src/stores/chat/date';
import { Resource } from 'src/stores/chat/user';

const MessageBase = types.model('Message', {
  id: types.string,
  from: Resource,
  createdAt: types.maybeNull(DateType),
});

const TextMessage = MessageBase.named('TextMessage').props({
  text: types.string,
  displayMode: types.enumeration('MessageDisplayMode', ['system', 'default']),
});
export type TextMessageInstance = Instance<typeof TextMessage>;

const VideoConferenceMessage = MessageBase.named('VideoConferenceMessage').props({
  vc: types.maybeNull(
    types.model({
      sessionId: types.string,
      token: types.string,
    }),
  ),
});

export const Message = types.union(TextMessage, VideoConferenceMessage);
