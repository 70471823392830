import React from 'react';

import {
  multilineStringDef,
  nestedStyle,
  nestedSelectFieldStyleDef,
} from 'src/components/forms/schemas/util';
import FieldGroup from 'src/components/general/fieldGroup';

const DROPDOWN_OPTIONS = ['Yes', 'No', 'Refused', 'Patient unable to complete'];
const SUICIDALITY_PROTOCOL_URL =
  'https://www.notion.so/boulder/Suicide-Prevention-Policy-794ee31b2361483e8cc5801e5d090657';

export default {
  type: 'object',
  title: 'Patient Safety Screener (PSS-3)',
  properties: {
    introducedReason: {
      type: 'boolean',
      title: 'Introduced reason for screener',
      renderer: {
        component: ({ model }) => (model.introducedReason === true ? 'Yes' : null),
      },
      tooltip:
        'Now I’m going to ask you some questions that we ask everyone at Boulder to make sure we aren’t missing anything that might be important to your care and wellbeing.',
    },
    depression: {
      type: 'string',
      title: 'Felt down, depressed, or hopeless in past 2 weeks?',
      enum: DROPDOWN_OPTIONS,
      tooltip: 'Over the past 2 weeks, have you felt down, depressed, or hopeless?',
    },
    suicide: {
      type: 'object',
      title: 'Suicide Risk',
      hideLabel: true,
      renderer: {
        component: ({ model, schema }) => {
          const { ideation, pastAttempts, lastOccurrence } = model.suicide;
          const { ideation: ideationSchema, pastAttempts: pastAttemptsSchema } = schema.properties;
          return (
            <>
              {ideation && <FieldGroup label={ideationSchema?.title}>{ideation}</FieldGroup>}
              {pastAttempts && (
                <FieldGroup label={pastAttemptsSchema?.title}>
                  <div>{pastAttempts}</div>
                  {lastOccurrence && <div style={{ fontSize: 14 }}>{lastOccurrence}</div>}
                </FieldGroup>
              )}
            </>
          );
        },
      },
      properties: {
        ideation: {
          type: 'string',
          title: 'Thoughts of suicide in past 2 weeks?',
          enum: DROPDOWN_OPTIONS,
          tooltip: 'Over the past 2 weeks, have you had thoughts of killing yourself?',
        },
        protocol: {
          dependsOn: 'ideation',
          displayOnDependent: ideation => ideation === 'Yes',
          customField: (
            <div style={nestedStyle}>
              <a href={SUICIDALITY_PROTOCOL_URL} target="_blank" rel="noopener noreferrer">
                Suicidality Protocol
              </a>
            </div>
          ),
        },
        pastAttempts: {
          type: 'string',
          title: 'Past suicide attempts?',
          enum: DROPDOWN_OPTIONS,
          tooltip: 'Have you ever attempted to kill yourself?',
        },
        lastOccurrence: {
          type: 'string',
          title: 'When did this last happen?',
          dependsOn: 'pastAttempts',
          displayOnDependent: pastAttempts => pastAttempts === 'Yes',
          changeOnDependent: (currentValue, pastAttempts) =>
            pastAttempts === 'Yes' ? currentValue : '',
          enum: [
            'Within the past 24 hours (including today)',
            'Within the last month (but not today)',
            'Between 1 and 6 months ago',
            'More than 6 months ago',
            'Refused',
            'Patient unable to complete',
          ],
          ...nestedSelectFieldStyleDef,
        },
      },
    },
    screeningResults: {
      displayOnly: true,
      renderer: {
        component: ({ model }) => {
          const { depressionResult, suicideRiskResult } = getAssessmentResults(model);
          return (
            <>
              {depressionResult && <div>Depression: {depressionResult}</div>}
              {suicideRiskResult && <div>Suicide Risk: {suicideRiskResult}</div>}
            </>
          );
        },
      },
    },
    notes: multilineStringDef,
  },
};

export function getAssessmentResults(model) {
  // Depression Results:
  //    - positive if Yes
  //    - negative if No
  //    - display nothing if refused or patient unable to complete
  let depressionResult;
  switch (model.depression) {
    case 'Yes':
      depressionResult = 'Positive';
      break;
    case 'No':
      depressionResult = 'Negative';
      break;
    case 'Refused':
    case 'Patient unable to complete':
    default:
      depressionResult = null;
      break;
  }

  // Suicide Risk Results:
  //    - positive if Yes to suicidal ideation
  //    - positive if Yes to past attempts and last occurence was one of the following:'Within the past 24 hours (including today)',
  //      'Within the last month (but not today)', or, 'Between 1 and 6 months ago',
  //    - negative if No to suicidal ideation
  //    - display nothing if refused or patient unable to complete
  let suicideRiskResult = null;
  if (model.suicide) {
    const { ideation, pastAttempts, lastOccurrence } = model.suicide;
    if (ideation === 'Yes') {
      suicideRiskResult = 'Positive';
    } else if (
      pastAttempts === 'Yes' &&
      [
        'Within the past 24 hours (including today)',
        'Within the last month (but not today)',
        'Between 1 and 6 months ago',
      ].includes(lastOccurrence)
    ) {
      suicideRiskResult = 'Positive';
    } else if (ideation === 'No') {
      suicideRiskResult = 'Negative';
    } else {
      suicideRiskResult = null;
    }
  }

  return { depressionResult, suicideRiskResult };
}
