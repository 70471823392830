import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { getSnapshot } from 'mobx-state-tree';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import { ConversationInstance, TextMessageInstance } from 'src/stores/chat';
import { inject } from 'src/util/inject';

interface ChatPebbleCreateIconProps {
  classes: {
    addPebbleButton: string;
    addPebbleIcon: string;
  };
  conversation: ConversationInstance;
  rootStore: {
    pebbles: {
      ui: {
        toggleCreateDialog: (initialPebbleContext) => void;
      };
    };
  };
  message: TextMessageInstance;
}

const createDefaultPebbleValues = ({
  message,
  conversation,
}: {
  message: TextMessageInstance;
  conversation: ConversationInstance;
}) => {
  return {
    link: `patients/${message.from.id}/conversation/${conversation.id}`,
    note: `Message: ${message.text}`,
    participant: getSnapshot(message.from),
    title: 'chat:',
  };
};

const ChatPebbleCreateIcon: React.FC<ChatPebbleCreateIconProps> = function ChatPebbleCreateIcon({
  classes,
  conversation,
  message,
  rootStore: {
    pebbles: {
      ui: { toggleCreateDialog },
    },
  },
}) {
  const defaultDialogValues = createDefaultPebbleValues({ message, conversation });

  return (
    <Tooltip title="Create a pebble for this message">
      <IconButton
        className={classes.addPebbleButton}
        onClick={() => {
          toggleCreateDialog(defaultDialogValues);
        }}
        data-testid="chat-pebble-create-icon"
      >
        <PlaylistAddIcon className={classes.addPebbleIcon} />
      </IconButton>
    </Tooltip>
  );
};

const styles = () => ({
  addPebbleButton: {
    color: '#F5BEA9',
    height: 24,
    width: 24,
    marginLeft: 16,
    marginTop: 0,
    '&:hover': {
      color: '#ED9D7B',
      backgroundColor: 'inherit',
    },
  },
  addPebbleIcon: {
    height: 20,
    width: 20,
  },
});

export default withStyles(styles)(
  inject<typeof ChatPebbleCreateIcon>('rootStore')(ChatPebbleCreateIcon),
);
