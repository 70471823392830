import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import { usePatientInternalId } from 'src/components/pages/zendesk/usePatientInternalId';
import ChartLoadingSpinner from 'src/nightingale/components/common/ChartLoadingSpinner/ChartLoadingSpinner';
import { PageColumn } from 'src/nightingale/components/common/PageColumn/PageColumn';
import { PageContainer } from 'src/nightingale/components/common/PageContainer/PageContainer';
import { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

/**
 * We display a link in Zendesk that allows providers to easily navigate to the relevant patient overview in the
 * Staff App. This link includes the external patient ID to prevent the patient's internal Boulder ID from
 * becoming PHI. This page fetches the internal patient ID and routes to the corresponding patient overview.
 */
const ZendeskPatient: React.FC<{ rootStore: RootStore }> = ({
  rootStore: {
    routerStore,
    routerStore: {
      routerState: { params },
    },
  },
}) => {
  const { data: internalId, isLoading, error } = usePatientInternalId(params.zendeskExternalId);

  useEffect(() => {
    if (internalId) {
      routerStore.goTo('showPatient', {
        params: {
          id: internalId,
        },
      });
    }
  }, [internalId]);

  const classes = useStyles();

  return (
    <div className={classes.body}>
      <PageContainer>
        <PageColumn>
          {isLoading ? <ChartLoadingSpinner message="Loading..." /> : null}
          {!isLoading && internalId === null ? (
            <ErrorAlert
              title="Patient not found"
              message="No Boulder patient with the provided Zendesk external ID."
            />
          ) : null}
          {error ? (
            <ErrorAlert
              title="Error fetching patient's internal Boulder ID"
              message={error.message}
            />
          ) : null}
        </PageColumn>
      </PageContainer>
    </div>
  );
};

const useStyles = makeStyles({
  body: {
    '&, > main': {
      height: 'calc(100vh - 64px)',
    },
  },
});

export default inject<typeof ZendeskPatient>('rootStore')(ZendeskPatient);
