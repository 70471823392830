"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseUnknownDate = exports.normalizeDateUnit = void 0;
var _dateFns = require("date-fns");
const normalizeDateUnit = unit => {
  let normalizedUnit;
  switch (unit) {
    case 'years':
    case 'y':
      normalizedUnit = 'years';
      break;
    case 'months':
    case 'M':
      normalizedUnit = 'months';
      break;
    case 'weeks':
    case 'w':
      normalizedUnit = 'weeks';
      break;
    case 'days':
    case 'd':
      normalizedUnit = 'days';
      break;
    case 'hours':
    case 'h':
      normalizedUnit = 'hours';
      break;
    case 'minutes':
    case 'm':
      normalizedUnit = 'mintutes';
      break;
    case 'seconds':
    case 's':
      normalizedUnit = 'seconds';
      break;
    default:
      normalizedUnit = null;
      break;
  }
  return normalizedUnit;
};
exports.normalizeDateUnit = normalizeDateUnit;
const parseUnknownDate = input => {
  if (input instanceof Date) return input;
  if (typeof input === 'string') return (0, _dateFns.parseISO)(input);
  return new Date(input);
};
exports.parseUnknownDate = parseUnknownDate;