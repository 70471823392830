// This file was originally copied from 'uniforms-material/ListField'.
// Since CustomListItemField references CustomAutoField, and CustomAutoField
// references this file, we have to disable the lint rule for dependency cycles.
import ListMaterial from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Children, Component } from 'react';
import { connectField, filterDOMProps, joinName } from 'uniforms';
import { ListAddField } from 'uniforms-material';

/* eslint-disable import/no-cycle */
import ListItemField from 'src/components/forms/fields/listItemField';

/* eslint-enable */

class ListField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoFocusIndex: this.props.autoFocus ? 0 : null,
    };

    this.addItem.bind(this);
  }

  addItem(value) {
    this.setState({ autoFocusIndex: value.length }, () => this.props.onChange(value.concat([{}])));
  }

  render() {
    const {
      autoFocus,
      children,
      classes,
      dense,
      initialCount,
      itemProps,
      label,
      name,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      value,
      flatten,
      ...others
    } = this.props;
    const { autoFocusIndex } = this.state;

    return (
      <div>
        {label && !flatten && <label className={classes.subheader}>{label}</label>}
        <div className={classNames(classes.nested, !flatten && classes.bordered)}>
          <ListMaterial
            key="list"
            dense={dense}
            className={classes.listContainer}
            {...filterDOMProps(others)}
          >
            {children
              ? value.map((item, index) =>
                  Children.map(children, child =>
                    React.cloneElement(child, {
                      key: joinName(name, child.props.name?.replace('$', index)).replace(
                        /\./g,
                        '-',
                      ),
                      label: null,
                      name: joinName(name, child.props.name?.replace('$', index)),
                    }),
                  ),
                )
              : value.map((item, index) => (
                  <ListItemField
                    key={joinName(name, index).replace(/\./g, '-')}
                    label={null}
                    // If the array is configured to autoFocus and hasn't yet changed,
                    // autoFocus the first item.
                    // If the array has changed, let the last item be autofocused so the
                    // newly added item is immediately in focus
                    autoFocus={autoFocusIndex === index}
                    name={joinName(name, index)}
                    {...itemProps}
                  />
                ))}
          </ListMaterial>
          <ListAddField
            key="listAddField"
            className={classes.addButton}
            name={`${name}.$`}
            icon="+ Add New"
            initialCount={initialCount}
            autoFocus={autoFocus && (!value || value.length === 0)}
            onClick={() => this.addItem(value)}
          />
        </div>
      </div>
    );
  }
}

ListField.defaultProps = {
  dense: true,
};

const styles = () => ({
  addButton: {
    backgroundColor: '#dddddd',
    borderRadius: 5,
    width: 100,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  listContainer: {
    '& > *': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingBottom: 0,
    paddingTop: 0,
  },
  bordered: {
    borderLeft: '1px solid #dddddd',
    paddingLeft: 18,
  },
  nested: {
    marginTop: 10,
  },
  subheader: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.75rem',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingLeft: 0,
    textTransform: 'uppercase',
  },
});

export default connectField(withStyles(styles)(ListField), {
  ensureValue: false,
  includeInChain: false,
});
