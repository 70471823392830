import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react';
import React from 'react';

import { ENROLLMENT_STATUS_OPTIONS, PAYMENT_TYPES } from 'src/components/forms/schemas/definitions';
import SortableList from 'src/components/general/sortableList';
import EventStatusIcon from 'src/components/pages/pageElements/eventStatusIcon';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import { EventInstance } from 'src/stores/models/event';
import type { RootStore } from 'src/stores/root';
import { getCalendarEventStyles } from 'src/util/calendar';
import { inject } from 'src/util/inject';

const Inquiries: React.FC<{ rootStore: RootStore }> = ({
  rootStore: {
    events: { list },
  },
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <TitleBar title="Inquiries" />
      </div>
      <div className={classes.spacer} />
      <SortableList
        resourceList={list}
        routes={{
          showEvent: {
            routeName: 'showEvent',
            getRouteParams: item => ({ id: item.id }),
          },
        }}
        tableHeadRows={[
          {
            fields: [
              {
                label: 'Form Submission Time',
              },
              {
                label: 'Requested Visit Time',
              },
              {
                label: 'Request Type',
              },
              {
                label: 'Payment Type',
              },
              {
                label: 'Participant',
              },
              {
                label: 'Provider(s)',
              },
              {
                label: 'Status',
              },
              {
                label: 'Summary',
              },
            ],
          },
        ]}
        tableBodyRows={[
          {
            key: 'eventDetails',
            routeKey: 'showEvent',
            style: item => getCalendarEventStyles(item),
            fields: [
              {
                label: 'formSubmissionTime',
                value: ({ item }) =>
                  item.formDetails?.formSubmissionTime
                    ? format(parseISO(item.formDetails.formSubmissionTime), 'MMM d, p')
                    : null,
              },
              {
                label: 'requestedVisitTime',
                value: ({ item }) =>
                  item.formDetails?.requestedVisitTime
                    ? format(parseISO(item.formDetails.requestedVisitTime), 'MMM d, p')
                    : null,
              },
              {
                label: 'requestType',
                value: ({ item }) => item.formDetails?.requestType || null,
              },
              {
                label: 'paymentType',
                value: ({ item }) => PAYMENT_TYPES[item.formDetails?.paymentType] || null,
              },
              {
                label: 'participant',
                // eslint-disable-next-line react/no-unused-prop-types
                value: ({ item }: { item: EventInstance }) =>
                  (item.patientAttendee && (
                    <>
                      {item.patientAttendee.exactFullName}
                      {item.patientAttendee.enrollmentStatus?.status && (
                        <>
                          <br />
                          <i>
                            {`(${
                              ENROLLMENT_STATUS_OPTIONS[
                                item.patientAttendee.enrollmentStatus.status
                              ]
                            })`}
                          </i>
                        </>
                      )}
                    </>
                  )) ||
                  null,
              },
              {
                label: 'providers',
                value: ({ item }) =>
                  item.providerAttendees?.map(provider => provider.exactFullName).join(', ') ||
                  null,
              },
              {
                label: 'status',
                value: ({ item }) => <EventStatusIcon event={item} />,
              },
              {
                label: 'summary',
                value: ({ item }) => <div className={classes.eventNotes}>{item.eventNotes}</div>,
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  root: {
    padding: theme.spacing(3),
  },
  strikethrough: {
    textDecoration: 'line-through',
  },

  // Matches participant history styling
  eventNotes: {
    fontStyle: 'italic',
    textDecoration: 'none',
  },
}));

export default inject<typeof Inquiries>('rootStore')(observer(Inquiries));
