import { parse, startOfWeek, getDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import type { OptionsWithTZ } from 'date-fns-tz';
import enUs from 'date-fns/locale/en-US';
import memoize from 'lodash/fp/memoize';
import { dateFnsLocalizer } from 'react-big-calendar';
import type { DateLocalizer } from 'react-big-calendar';

const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const createLocalizer = memoize(
  (timeZone = localZone): DateLocalizer =>
    dateFnsLocalizer({
      format: (date: Date | string | number, fmt: string, options: OptionsWithTZ) =>
        formatInTimeZone(date, timeZone, fmt, { ...options, timeZone }),
      parse,
      startOfWeek,
      getDay,
      locales: { 'en-US': enUs },
    }),
);
