import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import { PrimaryButton } from 'src/components/general/PrimaryButton';
import ClinicScheduleLog from 'src/dropInClinic/components/ClinicScheduleLog';
import {
  type Log,
  type RequestChangeLog,
  ScheduleLogType,
  useScheduleLogs,
  type ScheduleLog,
  AvailabilityCreateLog,
  AvailabilityUpdateLog,
} from 'src/dropInClinic/hooks/useScheduleLogs';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';
import { AVAILABILITY_SUB_TYPE_ITEMS } from 'src/shared/util/events';

const AVAILABILITY_TYPE_LABEL_MAP: Record<keyof typeof AVAILABILITY_SUB_TYPE_ITEMS, string> = {
  rapid_access: 'rapid access',
  panel_care: 'panel care',
  drop_in_clinic: 'pop-in clinic',
  scheduled_visits: 'scheduled visits',
};

const ClinicScheduleLogs = () => {
  const classes = useStyles();
  const [searchDate, setSearchDate] = useState<Date | null>(null);
  const [logs, setLogs] = useState<Log[]>([]);
  const { data, error, isLoading } = useScheduleLogs(searchDate);

  useEffect(() => {
    if (data) {
      setLogs([...logs, ...data]);
    }
  }, [data]);

  if (error) {
    return <ErrorAlert message="Error loading schedule logs." error={error} />;
  }

  return (
    <div className={classes.pageContainer}>
      <PageTitle>Clinic Schedule Logs</PageTitle>
      <MaterialTable<Log>
        data={logs}
        isLoading={isLoading}
        options={{
          headerStyle: { display: 'default' },
          paging: false,
          search: false,
          sorting: false,
          toolbar: false,
        }}
        columns={[
          {
            field: 'log',
            title: 'Log',
            render: row => {
              if (row.type === ScheduleLogType.Schedule) {
                return 'Schedule update';
              }
              if (
                row.type === ScheduleLogType.RequestCreated ||
                row.type === ScheduleLogType.RequestUpdated
              ) {
                const logData = (row as RequestChangeLog).log;
                const verb = row.type === ScheduleLogType.RequestCreated ? 'Created' : 'Updated';
                return (
                  <span className={classes.logLine}>
                    <>{`${verb} ${logData.request.requestType || '(unknown type)'} request ${
                      logData.request.id
                    }`}</>
                    <br />
                    <>{`for patient ${logData.request.patientId}: ${logData.request.status}`}</>
                  </span>
                );
              }
              if (row.type === ScheduleLogType.AvailabilityCreated) {
                const logData = (row as AvailabilityCreateLog).log;
                return (
                  <span className={classes.logLine}>
                    {`Created ${logData.type} availability for provider ${logData.providerId}`}
                  </span>
                );
              }
              if (row.type === ScheduleLogType.AvailabilityUpdated) {
                const logData = (row as AvailabilityUpdateLog).log;
                return (
                  <span className={classes.logLine}>
                    {`Updated ${
                      logData.after.type
                        ? AVAILABILITY_TYPE_LABEL_MAP[logData.after.type]
                        : 'Unknown'
                    } availability for provider ${logData.after.providerId}`}
                  </span>
                );
              }
              return row.type;
            },
          },
          {
            field: 'timestamp',
            title: 'Created At',
            render: row =>
              row.timestamp ? format(new Date(row.timestamp), "MMM d 'at' h:mma") : '',
          },
          { field: 'initiatedBy.name', title: 'Created By' },
          {
            field: 'initiatedBy.role',
            title: 'Created By Role',
            render: rowData =>
              rowData.initiatedBy?.role ? formatText(rowData.initiatedBy.role) : null,
          },
        ]}
        detailPanel={[
          {
            tooltip: 'Details',
            render: rowData => {
              if (rowData.type === ScheduleLogType.Schedule) {
                return <ClinicScheduleLog log={(rowData as ScheduleLog).log} />;
              }
              return (
                <pre className={classes.rawLogJson}>{JSON.stringify(rowData.log, null, 2)}</pre>
              );
            },
          },
        ]}
      />
      <PrimaryButton
        className={classes.loadMoreButton}
        onClick={() => setSearchDate(new Date(logs[logs.length - 1].timestamp))}
      >
        Load More
      </PrimaryButton>
    </div>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    padding: 24,
  },
  loadMoreButton: {
    marginTop: 16,
    float: 'right',
  },
  logLine: {
    whiteSpace: 'nowrap',
  },
  rawLogJson: {
    marginLeft: 10,
  },
});

const formatText = (text: string) => {
  return capitalize(text.toLowerCase());
};

export default ClinicScheduleLogs;
