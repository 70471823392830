import TextField from '@material-ui/core/TextField';
import React from 'react';
import MaskedInput from 'react-text-mask';

// From https://material-ui.com/demos/text-fields/#formatted-inputs
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      guide={false}
    />
  );
}

const PhoneControl = ({ field, form: { touched, errors, setFieldValue }, label, disabled }) => (
  <TextField
    error={errors[field.name] && touched[field.name]}
    helperText={touched[field.name] && errors[field.name]}
    label={label}
    disabled={disabled}
    fullWidth
    {...field}
    onChange={evt => setFieldValue(field.name, evt.target.value.replace(/\D+/g, ''))}
    InputProps={{
      inputComponent: TextMaskCustom,
    }}
  />
);

export default PhoneControl;
