import { Checkbox, Theme, makeStyles } from '@material-ui/core';
import Input, { InputProps } from '@material-ui/core/Input';
import findIndex from 'lodash/findIndex';
import some from 'lodash/some';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { CheckboxItem } from 'src/nightingale/types/types';

const useStyles = makeStyles<Theme, { disabled?: boolean }>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: ({ disabled }) => (disabled ? 'none' : 'auto'),
  },
  checkboxListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 11,
  },
  checkbox: {
    color: Colors.Gray7,
    padding: 0,
  },
  checkboxDisabled: {
    color: Colors.Gray6,
  },
  label: {
    color: ({ disabled }) => (disabled ? Colors.Gray6 : Colors.Black),
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontWeight: 400,
    padding: 0,
    paddingLeft: 10,
  },
  input: {
    marginTop: '0 !important',

    '&.Mui-disabled': {
      cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'default'),
    },
  },
});

const CheckboxListItem: React.FC<{
  isChecked?: boolean;
  item: CheckboxItem;
  onChange: (value: CheckboxItem) => void;
  disabled?: boolean;
}> = ({ isChecked, item, onChange, disabled }) => {
  const { label } = item;
  const styles = useStyles({ disabled });

  return (
    <div className={styles.checkboxListItem}>
      <Checkbox
        checked={isChecked}
        color="default"
        disabled={disabled}
        size="small"
        id={label}
        classes={{ root: styles.checkbox, disabled: styles.checkboxDisabled }}
        onChange={() => onChange(item)}
      />
      <label className={styles.label} htmlFor={label}>
        {label}
      </label>
    </div>
  );
};

const CheckboxListInputComponent: React.FC<{
  handleCheckPress: (value: CheckboxItem) => void;
  options: CheckboxItem[];
  selectedItems: CheckboxItem[];
  disabled?: boolean;
}> = ({ handleCheckPress, options, selectedItems, disabled }) => {
  const styles = useStyles({ disabled });

  return (
    <div className={styles.container}>
      {options.map(listItem => (
        <CheckboxListItem
          isChecked={some(selectedItems, { value: listItem.value })}
          item={listItem}
          key={listItem.value}
          onChange={handleCheckPress}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

interface CheckboxListInputProps
  extends Pick<
    InputProps,
    | 'autoComplete'
    | 'autoFocus'
    | 'disabled'
    | 'error'
    | 'id'
    | 'inputRef'
    | 'inputProps'
    | 'name'
    | 'placeholder'
    | 'readOnly'
    | 'required'
  > {
  options?: CheckboxItem[];
  onChangeValue?: (value: CheckboxItem[]) => void;
  selectedItems?: CheckboxItem[];
}

export const CheckboxListInput: React.FC<CheckboxListInputProps> = ({
  options = [],
  onChangeValue,
  selectedItems,
  disabled,
  ...rest
}) => {
  const styles = useStyles({ disabled });

  const handleCheckPress = (item: CheckboxItem) => {
    const idx = findIndex(selectedItems, { value: item.value });

    if (onChangeValue) {
      if (idx === -1) {
        onChangeValue([...(selectedItems || []), item]);
      } else {
        onChangeValue(
          selectedItems?.filter(selectedItem => selectedItem.value !== item.value) || [],
        );
      }
    }
  };

  return (
    <Input
      classes={{ root: styles.input }}
      color="primary"
      disableUnderline
      fullWidth
      inputComponent={CheckboxListInputComponent}
      inputProps={{
        handleCheckPress,
        options,
        selectedItems,
      }}
      disabled={disabled}
      {...rest}
    />
  );
};
