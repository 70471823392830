import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { useVideoEndDialogStyles } from 'src/components/general/videoEndDialog/VideoEndDialog.styles';
import VideoEndDialogV1 from 'src/components/general/videoEndDialog/VideoEndDialogV1';
import { VisitDifficulty } from 'src/components/general/videoEndDialog/VisitDifficulty';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { VIDEO_END_DIALOG_V2 } from 'src/featureFlags/currentFlags';
import { BooleanInput } from 'src/patientVerification/components/booleanInput';

export const VIDEO_END_KEY = 'VIDEO_END_KEY';

const submitVisitSuccessfulSelection = async ({
  apolloClient,
  eventId,
  isVideoComplete,
  videoEnd,
}: {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  eventId: string;
  isVideoComplete: boolean;
  videoEnd: Date;
}) =>
  apolloClient.mutate({
    mutation: gql`
      mutation ($data: EventInput!, $id: ID!) {
        updateEvent(data: $data, id: $id) {
          id
        }
      }
    `,
    variables: {
      data: {
        isVideoComplete,
        videoEnd,

        // Reset the other video end fields since we are saving new results
        difficulty: null,
        difficultyExplanation: null,
        otherDifficultyExplanation: null,
      },
      id: eventId,
    },
  });

const VisitFinishedPrompt = ({ handleIsVisitSuccessfulSelection }) => {
  const styles = useVideoEndDialogStyles();

  return (
    <div>
      <p className={styles.prompt}>Did you finish this visit successfully?</p>
      <BooleanInput
        isSelected={null}
        onChange={(isFinishedSuccessfullySelection: boolean) => {
          handleIsVisitSuccessfulSelection(isFinishedSuccessfullySelection);
        }}
      />
    </div>
  );
};

const VideoEndDialog2 = () => {
  const styles = useVideoEndDialogStyles();

  const [eventId, setEventId] = useState<string | null>(null);
  const [visitType, setVisitType] = useState<string | null>(null);
  const [isFinishedSuccessfully, setIsFinishedSuccessfully] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { apolloClient } = useContext(ApolloClientContext);
  const videoEndBc = new BroadcastChannel(VIDEO_END_KEY);

  videoEndBc.onmessage = async event => {
    if (event.data) {
      setEventId(event.data.id);
      setVisitType(event.data.subType);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const closeDialog = () => {
    videoEndBc.postMessage(null);
    setIsFinishedSuccessfully(false);
    setIsOpen(false);
  };

  const handleIsVisitSuccessfulSelection = async (isVideoComplete: boolean) => {
    if (!apolloClient || !eventId) {
      return;
    }

    setIsLoading(true);
    await submitVisitSuccessfulSelection({
      apolloClient,
      eventId,
      isVideoComplete,
      videoEnd: new Date(),
    });
    setIsLoading(false);

    if (isVideoComplete) {
      setIsFinishedSuccessfully(true);
    } else {
      setIsFinishedSuccessfully(false);
      closeDialog();
    }
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth className={styles.container}>
        <DialogTitle classes={{ root: styles.title }}>Post-visit survey</DialogTitle>
        {isLoading ? (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        ) : (
          <DialogContent classes={{ root: styles.content }}>
            {!isFinishedSuccessfully ? (
              <VisitFinishedPrompt
                handleIsVisitSuccessfulSelection={handleIsVisitSuccessfulSelection}
              />
            ) : (
              <VisitDifficulty
                eventId={eventId}
                goBack={() => setIsFinishedSuccessfully(false)}
                closeDialog={closeDialog}
                visitType={visitType}
              />
            )}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

const VideoEndDialog = () => {
  const flags = useContext(FeatureFlagContext);
  const useVideoEndDialogV2 = flags[VIDEO_END_DIALOG_V2];
  if (useVideoEndDialogV2) {
    return <VideoEndDialog2 />;
  } else {
    return <VideoEndDialogV1 />;
  }
};

export default VideoEndDialog;
