import startCase from 'lodash/startCase';
import React from 'react';

import NoteField from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/renderers/NoteField';

export default ({ model, classes, schema }) => {
  return (
    <>
      <span>{schema.title}</span>
      <div className={classes.itemDetails}>
        {Object.keys(schema.properties).map(prop => {
          const field = schema.properties[prop];
          const title = field.title || startCase(prop);
          if (prop === 'notes') return null;
          return (
            <span key={title}>
              {title}
              {': '}
              {model[prop] === true ? 'Yes' : 'No'}
            </span>
          );
        })}
      </div>
      {model.notes && (
        <div className={classes.marginTop}>
          <NoteField model={model} classes={classes} schema={schema.properties.notes} />
        </div>
      )}
    </>
  );
};
