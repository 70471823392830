import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { parseISO } from 'date-fns';
import debounce from 'lodash/debounce';
import React, { useContext, useState } from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import { NextEventDate } from 'src/components/pages/eventShow/NextEventDate';
import scheduleTableStyles from 'src/components/pages/eventShow/ScheduleTable.styles';
import { isSlotAfterCutoff } from 'src/components/pages/eventShow/isSlotAfterCutoff';
import { createSuggestedEvent } from 'src/components/pages/eventShow/scheduleSuggestions/createSuggestedEvent';
import { useScheduleSuggestions } from 'src/components/pages/eventShow/scheduleSuggestions/useScheduleSuggestions';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import TimeSlotTable from 'src/scheduling/components/TimeSlotTable';
import type { TimeSlot } from 'src/scheduling/types';
import { Priority } from 'src/scheduling/types/TimeSlot';

type ScheduleTableProps = {
  event: { id: string };
  patientId: string;
  timezone: string;
};

type NextEventDetails = {
  id: string;
  start: string;
  timezone: string;
};

const ScheduleTable = (props: ScheduleTableProps) => {
  const { event, patientId, timezone } = props;

  const classes = scheduleTableStyles();

  const apolloContext = useContext(ApolloClientContext);

  const [timeOfDay, setTimeOfDay] = useState<string | null>(null);
  const [visitTitle, setVisitTitle] = useState<string | null>(null);
  const [visitType, setVisitType] = useState('prescriber_followup');
  const [duration, setDuration] = useState<number | null>(null);
  const [includeBackupTimes, setIncludeBackupTimes] = useState<boolean>(true);
  const [includeRnFollowups, setIncludeRnFollowups] = useState<boolean>(true);
  const [nextEvent, setNextEvent] = useState<NextEventDetails | null>(null);
  const [nextEventError, setNextEventError] = useState<string | null>(null);
  const [nextEventLoading, setNextEventLoading] = useState<boolean>(false);

  const scheduleSuggestionsQuery = {
    eventId: event.id,
    visitType,
    duration,
    includeRnFollowups,
  };

  const {
    error: scheduleLoadingError,
    isLoading,
    isValidating,
    suggestedTimeSlots,
  } = useScheduleSuggestions(scheduleSuggestionsQuery);

  const createEvent = (timeSlot: TimeSlot) => {
    if (!apolloContext.apolloClient) {
      setNextEventError('Missing Apollo Client!');
      return;
    }

    setNextEventLoading(true);
    setNextEventError(null);

    createSuggestedEvent({
      apolloClient: apolloContext.apolloClient,
      timeSlot,
      patientId,
      visitTitle,
      timezone,
    })
      .then(result => {
        setNextEvent(result.data.createEvent);
      })
      .catch(error => {
        setNextEventError(String(error));
      })
      .finally(() => {
        setNextEventLoading(false);
      });
  };

  const onDurationChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const numberRegEx = /^[0-9\b]+$/;
    if (e.target.value === '') {
      setDuration(null);
    } else if (numberRegEx.test(e.target.value)) {
      setDuration(parseInt(e.target.value, 10));
    }
  };
  const debouncedOnDurationChange = debounce(onDurationChange, 500);

  return (
    <div>
      {nextEventError && <ErrorAlert message={nextEventError} />}
      {nextEvent?.id && (
        <NextEventDate
          eventId={nextEvent.id}
          start={parseISO(nextEvent.start)}
          timezone={nextEvent.timezone}
        />
      )}
      {!nextEvent?.id && (
        <>
          <div className={classes.controlRow}>
            <TextField
              type="text"
              id="visit-title"
              label="Visit title"
              onChange={e => setVisitTitle(e.target.value)}
              value={visitTitle}
              fullWidth
            />
          </div>
          <div className={classes.controlRow}>
            <FormControl>
              <InputLabel id="visit-type-label">Visit type</InputLabel>
              <Select
                style={{ minWidth: 200 }}
                labelId="visit-type-label"
                value={visitType}
                onChange={e => setVisitType(String(e.target.value))}
              >
                <MenuItem value="prescriber_followup">Follow-up</MenuItem>
                <MenuItem value="prescriber_bridge_care">Team Follow-up</MenuItem>
                <MenuItem value="prescriber_paneling_followup">Panel Transfer</MenuItem>
              </Select>
            </FormControl>
            {/*
              The minWidth here ensures that the floating label always fits over the control;
              We force the label to shrink all the time because both Chrome and Firefox will
              display a format string (`-- : -- --`) in the field and Chrome also adds a little
              clock icon, so we never want the input label inside the field itself.
            */}
            <TextField
              style={{ minWidth: '6rem' }}
              type="time"
              label="Start time"
              value={timeOfDay}
              onChange={e => setTimeOfDay(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              type="number"
              id="duration"
              label="Duration"
              onChange={debouncedOnDurationChange}
            />
          </div>
          <div>
            <FormControlLabel
              label="Include backup availability"
              control={
                <Switch
                  checked={includeBackupTimes}
                  onChange={e => {
                    setIncludeBackupTimes(e.target.checked);
                  }}
                />
              }
            />
            <FormControlLabel
              label="Include RN follow-ups"
              control={
                <Switch
                  checked={includeRnFollowups}
                  onChange={e => {
                    setIncludeRnFollowups(e.target.checked);
                  }}
                />
              }
            />
          </div>
          <TimeSlotTable
            isLoading={isLoading || isValidating || nextEventLoading}
            timeSlots={suggestedTimeSlots
              .filter(slot => includeBackupTimes || slot.priority !== Priority.Backup)
              .filter(isSlotAfterCutoff(timezone, timeOfDay))}
            timezone={timezone}
            onClick={createEvent}
            displayClickToLoadSlots={false}
            error={scheduleLoadingError}
            withDurations
          />
        </>
      )}
    </div>
  );
};

export default ScheduleTable;
