import { withStyles, FormControl, InputBase, InputLabel } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import PatientReferenceControl from 'src/components/forms/controls/PatientReference';
import ProviderReferenceControl from 'src/components/forms/controls/providerReference';
import { EVENT_ATTENDEE_CONSTRAINTS } from 'src/shared/client/forms/validation/event';
import { getPreferredFullName } from 'src/shared/stores/resource';

class AttendeeControl extends React.Component {
  getAllAttendees = () => {
    const {
      field: { value = [] },
      fixed,
    } = this.props;
    return [...value, ...(fixed || [])];
  };

  getAttendeesByType = type =>
    this.getAllAttendees().filter(attendee => attendee.__typename === type);

  getUniqueAttendees = attendees =>
    attendees.filter(
      (attendee, index, self) => index === self.findIndex(a => a.id === attendee.id),
    );

  setAttendeeValue = async (type, attendees = []) => {
    const {
      field: { name },
      form: { setFieldValue },
    } = this.props;
    const newAttendees = !Array.isArray(attendees) ? [attendees] : attendees;
    setFieldValue(
      name,
      this.getUniqueAttendees([
        ...this.getAttendeesByType(type === 'Patient' ? 'Provider' : 'Patient'),
        ...newAttendees.filter(attendee => !!attendee),
      ]),
    );
  };

  getErrors = type => {
    const {
      field: { name },
      form: { errors },
    } = this.props;
    const filteredErrors = Object.values(EVENT_ATTENDEE_CONSTRAINTS)
      .filter(error => error.appliesTo === type && error.message === errors[name])
      .map(error => error.message);
    return filteredErrors.length ? { [type]: filteredErrors[0] } : {};
  };

  getTouched = type => {
    const {
      field: { name },
      form: { touched },
    } = this.props;
    const errors = this.getErrors(type);
    return errors[type] && touched[name] && touched[name].length
      ? { [type]: touched[name][0] }
      : {};
  };

  setTouched = () => {
    const {
      field: { name },
      form: { setFieldTouched },
    } = this.props;
    setFieldTouched(name);
  };

  render() {
    const {
      form,
      fixed,
      className,
      classes,
      editEventDropdownStyling,
      showPatients = true,
      allowMultipleProviders = true,
      patientRequired = false,
    } = this.props;

    const formOverrides = {
      setFieldValue: this.setAttendeeValue,
      setFieldTouched: this.setTouched,
    };

    const Provider = this.getAttendeesByType('Provider');

    const fixedPatient = (fixed || [])
      .filter(attendee => attendee.__typename === 'Patient')
      .map(getPreferredFullName)
      .pop();

    const Patient = fixedPatient ? [] : this.getAttendeesByType('Patient');

    return (
      <div className={className}>
        {(showPatients || fixedPatient) && (
          <div className={classNames(classes.patientControl, editEventDropdownStyling)}>
            {fixedPatient ? (
              <FormControl>
                <InputLabel shrink htmlFor="fixed-patient">
                  Patient
                </InputLabel>
                <InputBase
                  className={classes.nakedControl}
                  defaultValue={fixedPatient}
                  id="fixed-patient"
                />
              </FormControl>
            ) : (
              <PatientReferenceControl
                field={{ name: 'Patient', value: Patient }}
                form={{
                  ...form,
                  ...formOverrides,
                  errors: this.getErrors('Patient'),
                  touched: this.getTouched('Patient'),
                }}
                label={`Patient ${patientRequired ? '*' : ''}`}
              />
            )}
          </div>
        )}
        <div className={editEventDropdownStyling}>
          <ProviderReferenceControl
            field={{ name: 'Provider', value: Provider }}
            form={{
              ...form,
              ...formOverrides,
              errors: this.getErrors('Provider'),
              touched: this.getTouched('Provider'),
            }}
            isMulti={allowMultipleProviders}
            fixed={fixed?.filter(attendee => attendee.__typename === 'Provider')}
            label="Providers *"
          />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  patientControl: {
    marginBottom: theme.spacing(4),
  },
  nakedControl: {
    marginTop: theme.spacing(2),
  },
});

export default withStyles(styles)(AttendeeControl);
