import { format, isValid, parseISO } from 'date-fns';
import React from 'react';

import { getPreferredFullName, UserNames } from 'src/shared/stores/resource';

type UpdateSummaryProps = {
  at?: Date | string | null;
  className?: string;
  by?: UserNames | null;
  label: string;
};

export const UpdateSummary = (props: UpdateSummaryProps) => {
  const { at, by } = props;

  if (!at && !by) {
    // This update hasn't happened
    return null;
  }

  const updateContent: string[] = [];

  if (by) {
    const preferredName = getPreferredFullName(by);
    updateContent.push(`by ${preferredName}`);
  }

  if (at) {
    let atDate: Date | string = at;
    if (typeof atDate === 'string') {
      atDate = parseISO(atDate);
    }
    if (isValid(atDate)) {
      updateContent.push(`on ${format(atDate, 'MMM d, p')}`);
    }
  }

  return (
    <span className={props.className}>
      {props.label} {updateContent.join(' ')}
    </span>
  );
};
