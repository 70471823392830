import gql from 'graphql-tag';

export const UPLOAD_FILE = gql`
  mutation UploadFile($data: Upload!) {
    createFile(data: $data) {
      id
      filename
      contentType
      url
    }
  }
`;

export const MESSAGE_CHART_DOCUMENT_TO_PATIENT = gql`
  mutation ($id: ID!) {
    messageChartDocumentToPatient(id: $id)
  }
`;
