import gql from 'graphql-tag';

import { FILE_SUMMARY } from 'src/stores/fragments/files';
import { USER_LIMITED_DISPLAY, USER_SUMMARY } from 'src/stores/fragments/users';

export const ACTIVITY_EVENT_DETAIL_QUERY = gql`
  query PatientActivityEventDetail($eventId: ID) {
    eventInstance(id: $eventId) {
      id
      appointmentNotes
      attendees {
        ...UserSummary
        ... on Patient {
          preferredName
          enrollmentStatus {
            status
          }
        }
      }
      createdAt
      createdByDisplay {
        ...UserLimitedDisplay
      }
      createdVia
      countersignedAt
      countersignedByDisplay {
        ...UserLimitedDisplay
      }
      duration
      eventResults {
        type
        version
        results
      }
      hasVisitNotes
      isNightingale
      scheduleChangeNotes
      scheduleChangeReason
      signedAt
      signedByDisplay {
        ...UserLimitedDisplay
      }
      start
      status
      subType
      timezone
      title
      type
      patientResults {
        ... on PatientResultsLab {
          barcode
          barcodePhoto {
            ...FileSummary
          }
          photos {
            ...FileSummary
          }
        }
      }
      updatedAt
      updatedByDisplay {
        ...UserLimitedDisplay
      }
    }
  }
  ${FILE_SUMMARY}
  ${USER_LIMITED_DISPLAY}
  ${USER_SUMMARY}
`;
