"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ifany = ifany;
var _if = require("./if");
function ifany(...args) {
  const options = args.pop();
  if (args.some(arg => (0, _if.ifContextChartProperty)(arg))) return options.fn(this);
  return options.inverse(this);
}