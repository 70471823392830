import isNumber from 'lodash/isNumber';
import * as yup from 'yup';

import { ChartPropertyTypes } from 'src/nightingale/types/types';

/**
 * Email Validation
 */
const validEmail = yup.string().email('This is not a valid email address.');

/**
 * Phone Validation
 */
const phoneRegex = /^\d{10}$/;
const validPhone = yup.string().matches(phoneRegex, {
  message: 'This is not a valid 10-digit phone number.',
  excludeEmptyString: true,
});

/**
 * Date Validation
 * Valid date range: 01/01/1850 - 12/31/2999
 * Format: YYYY-MM-DD
 */
const validDate = yup.date().min('1850-01-01').max('2999-12-31').nullable();

/**
 * Integer Validation
 */
function validateMinMax(errorMessage, min, max) {
  function validate(value) {
    const { path, createError } = this;
    const parsedValue = parseInt(value, 10);

    if (Number.isNaN(parsedValue)) return true;
    if (isNumber(min) && parsedValue < min) return createError({ path, message: errorMessage });
    if (isNumber(max) && parsedValue > max) return createError({ path, message: errorMessage });

    return true;
  }

  return this.test(`test-integer-range`, errorMessage, validate);
}

declare module 'yup' {
  interface StringSchema {
    integerRange(format: string, min: number, max: number): StringSchema;
  }
}

yup.addMethod(yup.string, 'integerRange', validateMinMax);

const validInteger = ({ min, max }) =>
  yup.string().integerRange(`This must be a whole number between ${min} and ${max}.`, min, max);

/**
 * Validation map
 */
export const ValidationSchemas = {
  [ChartPropertyTypes.Date]: () => validDate,
  [ChartPropertyTypes.Email]: () => validEmail,
  [ChartPropertyTypes.Phone]: () => validPhone,
  [ChartPropertyTypes.Integer]: validInteger,
};
