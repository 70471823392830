import React from 'react';

type Maybe<T> = T | null | undefined;

export type QuestionDetails = {
  question?: Maybe<string>;
};

type QuestionProps = {
  visitReasonDetails: QuestionDetails;
};

export const Question = ({ visitReasonDetails }: QuestionProps) => {
  if (!visitReasonDetails.question || visitReasonDetails.question.length <= 0) {
    return null;
  }

  return (
    <li>
      <span className="label">Add’l:</span> {visitReasonDetails.question}
    </li>
  );
};
