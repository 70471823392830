import { makeStyles } from '@material-ui/core/styles';
import { addDays } from 'date-fns';
import gql from 'graphql-tag';
import compact from 'lodash/compact';
import { observer } from 'mobx-react';
import React from 'react';
import useSWR from 'swr';

import { dateStringToBoulderStartOfDay } from 'src/businessHours/dateStringToBoulderStartOfDay';
import VisitSummary from 'src/components/pages/visitSummary';
import { PatientSubmittedResultsTable } from 'src/labs/PatientSubmittedResultsTable';
import { useSWRPatientSubmittedResults } from 'src/labs/useSWRPatientSubmittedResults';
import PatientOverview from 'src/nightingale/pages/PatientOverview';
import { SCHEDULE_CHANGE_REASONS } from 'src/shared/util/events';
import { EVENT_INSTANCE_ALL } from 'src/stores/fragments/events';
import { Event } from 'src/stores/models/event';
import { EventInstancesAllResponse } from 'src/stores/queries/events';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const BEGINNING_OF_TIME = new Date(0); // Arbitrary date before any possible Boulder data.
const END_OF_TIME = new Date('9999-12-31'); // Largest possible state but it is not at the end of time because our graphql is limited by section 5.6. https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
const CANCELLED_RECURRENCE: keyof typeof SCHEDULE_CHANGE_REASONS = 'canceled_recurrence';

const GET_EVENT_INSTANCES_FOR_EXPORT = gql`
  query EventInstancesForExport(
    $user: ID
    $timeRangeStart: DateTime
    $timeRangeEnd: DateTime
    $timezone: String!
    $activeOnly: Boolean
  ) {
    eventInstances(
      timezone: $timezone
      where: {
        attendees_some: $user
        start_gte: $timeRangeStart
        start_lt: $timeRangeEnd
        active_only: $activeOnly
      }
      orderBy: start_DESC
    ) {
      ...EventInstanceAll
    }
  }
  ${EVENT_INSTANCE_ALL}
`;

const PageExport: React.FC<{ rootStore: RootStore }> = ({
  rootStore: {
    routerStore: {
      routerState: {
        params: { id: patientId },
        queryParams,
      },
    },
  },
}) => {
  const { data: testResultsData, isLoading: testResultsIsLoading } =
    useSWRPatientSubmittedResults(patientId);

  const timeRangeStart = queryParams?.startDate
    ? dateStringToBoulderStartOfDay(queryParams.startDate)
    : BEGINNING_OF_TIME;

  const timeRangeEnd = queryParams?.endDate
    ? addDays(dateStringToBoulderStartOfDay(queryParams.endDate), 1)
    : END_OF_TIME;

  const { data: { eventInstances } = {} } = useSWR<EventInstancesAllResponse>(
    [
      GET_EVENT_INSTANCES_FOR_EXPORT,
      {
        user: patientId,
        timeRangeStart,
        timeRangeEnd,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        activeOnly: true,
      },
    ],
    { revalidateOnFocus: false },
  );
  const eventsForExport = compact(eventInstances).filter(
    event => event.scheduleChangeReason !== CANCELLED_RECURRENCE,
  );

  const classes = useStyles();
  return (
    <div className={classes.body}>
      <div className={classes.overview}>
        <PatientOverview showSearch={false} patientId={patientId} />
      </div>
      <div className={classes.results}>
        <PatientSubmittedResultsTable
          data={testResultsData}
          isLoading={testResultsIsLoading}
          readOnly
        />
      </div>
      {eventsForExport.length
        ? eventsForExport.map(event =>
            event.hasVisitNotes ? (
              <div className={classes.event} key={`visitNote-${event.id}`}>
                <VisitSummary event={Event.create(event)} patientId={patientId} />
              </div>
            ) : null,
          )
        : null}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  body: {
    maxWidth: 600,
  },
  overview: {
    padding: theme.spacing(3),
    pageBreakAfter: 'always',
  },
  results: {
    padding: theme.spacing(3),
    pageBreakAfter: 'always',
  },
  event: {
    padding: theme.spacing(3),
    pageBreakAfter: 'always',
  },
}));

export const __test__ = { GET_EVENT_INSTANCES_FOR_EXPORT };

export default inject<typeof PageExport>('rootStore')(observer(PageExport));
