"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datediffcalendar = datediffcalendar;
var _dateFns = require("date-fns");
var _dateFnsTz = require("date-fns-tz");
var _dateCompatabilityShim = require("./dateCompatabilityShim");
function datediffcalendar(from, to, timezone) {
  const fromDate = (0, _dateCompatabilityShim.parseUnknownDate)(from);
  const toDate = (0, _dateCompatabilityShim.parseUnknownDate)(to);
  if (!(0, _dateFns.isValid)(fromDate) || !(0, _dateFns.isValid)(toDate)) {
    return undefined;
  }
  const zonedFrom = (0, _dateFnsTz.utcToZonedTime)(fromDate, timezone);
  const zonedTo = (0, _dateFnsTz.utcToZonedTime)(toDate, timezone);
  return Math.abs((0, _dateFns.differenceInCalendarDays)(zonedFrom, zonedTo));
}