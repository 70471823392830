import { makeStyles } from '@material-ui/styles';
import { Field, Formik } from 'formik';
import React from 'react';

import { PrimaryButton } from 'src/components/general/PrimaryButton';
import { SecondaryButton } from 'src/components/general/SecondaryButton';
import { PatientSubmittedTestResult } from 'src/labs/types';
import { BooleanControl } from 'src/nightingale/components/ChartProperty/controls/Boolean/BooleanControl';
import { TextControl } from 'src/nightingale/components/ChartProperty/controls/Text/TextControl';

export const EditLabResultForm: React.FC<{
  images: PatientSubmittedTestResult['images'];
  item: Partial<PatientSubmittedTestResult['result']>;
  onCancel: () => void;
  onSave: (values: Partial<PatientSubmittedTestResult['result']>) => Promise<void>;
}> = ({ images, item, onCancel, onSave }) => {
  const classes = useStyles();

  return (
    <>
      <figure className={classes.images}>
        {images.map((image, index) => (
          <img
            className={classes.resultImage}
            src={image.url}
            alt={`Lab result upload #${index + 1}`}
          />
        ))}
      </figure>

      <Formik
        initialValues={item}
        onSubmit={(values, actions) =>
          onSave(values).finally(() => {
            actions.setSubmitting(false);
          })
        }
      >
        {({ handleSubmit, isSubmitting, dirty }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Field label="Result valid?" name="verified" component={FormBooleanControl} />
            <Field label="Bup positive?" name="bupPositive" component={FormBooleanControl} />
            <Field label="Additional Notes" name="notes" multiline component={FormTextControl} />

            <span className={classes.buttons}>
              <div>
                <SecondaryButton onClick={() => onCancel()}>Cancel</SecondaryButton>
                <PrimaryButton
                  className={classes.button}
                  disabled={isSubmitting || !dirty}
                  type="submit"
                >
                  Save
                </PrimaryButton>
              </div>
            </span>
          </form>
        )}
      </Formik>
    </>
  );
};

const FormBooleanControl = ({ field, form, label }) => (
  <BooleanControl
    label={label}
    name={field.name}
    onChangeValue={newVal => {
      form.setFieldValue(field.name, newVal);
    }}
    value={field.value}
  />
);

const FormTextControl = ({ field, form, label }) => (
  <TextControl
    label={label}
    multiline
    name={field.name}
    onChangeValue={newVal => {
      form.setFieldValue(field.name, newVal);
    }}
    value={field.value || ''}
  />
);

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    '& .MuiFormControl-root': {
      marginBottom: 16,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 20,
  },
  button: {
    marginLeft: 8,
  },
  images: {
    alignItems: 'center',
    columnGap: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 0 20px',
    rowGap: 20,
  },
  resultImage: {
    display: 'block',
    height: 'auto',
    margin: 0,
    marginBottom: '20px',
    maxWidth: '90vh',
    width: '100%',
  },
});
