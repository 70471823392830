import set from 'lodash/set';

import { parseNumericQueryParam, unrecognizedQueryParam } from 'src/util/params';

export const queryParamsToUpdateArg = qp => {
  // Initialize with an empty `where` to ensure the list filter gets properly reset if needed
  const updateArg = { where: {} };

  Object.entries(qp).forEach(([key, value]) => {
    switch (key) {
      case 'prescriber':
      case 'coordinator':
      case 'peer':
      case 'caseManager':
      case 'enrollmentStatus_status':
        set(updateArg, `where.${key}_some`, value);
        break;
      case 'order':
      case 'orderBy':
        set(updateArg, key, value);
        break;
      case 'page':
      case 'rowsPerPage': {
        set(updateArg, key, parseNumericQueryParam(value));
        break;
      }
      default:
        console.warn(unrecognizedQueryParam(key));
    }
  });

  return updateArg;
};
