import { Button, makeStyles, Theme } from '@material-ui/core';
import max from 'lodash/max';
import React, { useContext, useEffect, useMemo, useCallback, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import FeatureFlag from 'src/components/featureflags/featureFlag';
import ErrorAlert from 'src/components/general/ErrorAlert';
import { REFRESH_FLOW_ON_INTERACTION } from 'src/featureFlags/currentFlags';
import { ChartInteractionContext } from 'src/nightingale/components/ChartInteraction/ChartInteractionContext';
import { ChartUpdateContext } from 'src/nightingale/components/ChartUpdateContext/ChartUpdateContext';
import { ConditionalContextProvider } from 'src/nightingale/components/ConditionalContext/ConditionalContext';
import { Flow } from 'src/nightingale/components/Flow/Flow';
import { useModal } from 'src/nightingale/components/Modal/useModal';
import { SideBySideSnapshotSnackbar } from 'src/nightingale/components/SnapshotSnackbar/SideBySideSnapshotSnackbar';
import { SnapshotSnackbar } from 'src/nightingale/components/SnapshotSnackbar/SnapshotSnackbar';
import ChartLoadingSpinner from 'src/nightingale/components/common/ChartLoadingSpinner/ChartLoadingSpinner';
import { getConditions } from 'src/nightingale/conditionals';
import { getFlowStepType } from 'src/nightingale/data/Flow.utils';
import { GET_ADDABLE_FLOWS } from 'src/nightingale/data/queries/getAddableFlows';
import {
  Flow as TFlow,
  FlowStep,
  FlowStepType,
  InteractionKind,
} from 'src/nightingale/types/types';

const useStyles = makeStyles<Theme, { isModifyingFlow: boolean }>({
  // Same as ChartOverview
  container: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'flex-start',
    opacity: ({ isModifyingFlow }) => (isModifyingFlow ? 0.5 : 1),
  },
  // Same as ChartOverviewSection
  chartElementsContainer: {
    marginBottom: '16px',
    width: '100%',
  },
  refreshFlowButton: {
    marginBottom: 20,
  },
});

type ChartInteractionProps = {
  disableEdit?: boolean;
  snackbarComponent?: typeof SnapshotSnackbar | typeof SideBySideSnapshotSnackbar;
};

export const ChartInteractionView: React.VFC<ChartInteractionProps> = ({
  disableEdit,
  snackbarComponent = SnapshotSnackbar,
}) => {
  const [isModifyingFlow, setIsModifyingFlow] = useState(false);

  const styles = useStyles({ isModifyingFlow });

  const {
    addFlowToInteraction,
    context: conditionalContext,
    error,
    kind,
    interaction,
    interactionReferenceId,
    isLoading,
    mutateChartPropertyValues,
    patientId,
    refreshFlowOnInteraction,
    refreshPatientSnapshot,
    removeFlowFromInteraction,
  } = useContext(ChartInteractionContext);
  const { flow, updatedChartProperties, id } = interaction || {};

  const { lastUpdate } = useContext(ChartUpdateContext);

  useEffect(() => {
    mutateChartPropertyValues();
  }, [lastUpdate]);

  const [snapshotCtaDismissedCutoffTime, setSnapshotCtaDismissedCutoffTime] = useState(
    new Date('1970-01-01'),
  );

  const readOnly = !!disableEdit && !isModifyingFlow;
  const showSnapshotCta =
    !readOnly &&
    updatedChartProperties?.some(
      cpv => cpv.createdAt && new Date(cpv.createdAt) > snapshotCtaDismissedCutoffTime,
    );

  const handleAddFlow = useCallback(
    async (selection: { value?: string } = {}, parentFlowIndex?: number) => {
      const flowId = selection?.value;
      if (!flowId) return;

      setIsModifyingFlow(true);
      await addFlowToInteraction(flowId, parentFlowIndex);
      setIsModifyingFlow(false);
    },
    [addFlowToInteraction],
  );

  const handleRemoveFlow = useCallback(
    async (flowId: string) => {
      if (!flowId) return;

      setIsModifyingFlow(true);
      await removeFlowFromInteraction(flowId);
      setIsModifyingFlow(false);
    },
    [removeFlowFromInteraction],
  );

  const handleRefreshFlow = useCallback(async () => {
    setIsModifyingFlow(true);
    await refreshFlowOnInteraction();
    setIsModifyingFlow(false);
  }, [refreshFlowOnInteraction]);

  const existingNestedFlowIds = useMemo(
    () => (flow ? flow.elements.reduce(existingFlowReducer, [flow.id]) : []),
    [flow],
  );
  const { data: { addableFlows = [] } = {} } = useSWRImmutable(GET_ADDABLE_FLOWS);
  const allAddableFlowIds = addableFlows.map(addableFlow => addableFlow.id);
  const selectableFlowOptions = addableFlows
    .filter((addableFlow: TFlow) => !existingNestedFlowIds.includes(addableFlow.id))
    .sort((a, b) => a.label.localeCompare(b.label));

  const modalProps = useModal();
  const { toggleModal } = modalProps;
  const showSnapshotModal = () => toggleModal(true);
  const { context: chartContext } = useContext(ChartInteractionContext);
  const updatedPropertyLabels = (updatedChartProperties ?? []).map(({ propertyName }) => {
    const prop = chartContext?.[propertyName];
    return typeof prop === 'object' && 'propertyLabel' in prop
      ? (prop.propertyLabel as string)
      : propertyName;
  });

  const SnackbarComponent = snackbarComponent;

  const displayError = !(
    kind === InteractionKind.Pebble &&
    error?.message.includes('Unable to create interaction for interactionKey')
  );

  return (
    <div className={styles.container} data-testid="chart-interaction-container">
      {showSnapshotCta && (
        <SnackbarComponent
          onAccept={refreshPatientSnapshot}
          onDismiss={() => {
            if (updatedChartProperties?.length) {
              setSnapshotCtaDismissedCutoffTime(
                max(updatedChartProperties.map(cpv => new Date(cpv.createdAt))) as Date,
              );
            }
          }}
          updatedPropertyLabels={updatedPropertyLabels}
        />
      )}

      <FeatureFlag name={REFRESH_FLOW_ON_INTERACTION}>
        <Button
          data-testid="refresh-flow-button"
          className={styles.refreshFlowButton}
          variant="contained"
          color="primary"
          onClick={handleRefreshFlow}
        >
          Refresh flows
        </Button>
      </FeatureFlag>

      <div className={styles.chartElementsContainer}>
        {isLoading ? (
          <ChartLoadingSpinner message="Loading..." />
        ) : (
          <>
            {error && displayError ? (
              <ErrorAlert message="Error loading sections." error={error} />
            ) : null}
            {flow && patientId && conditionalContext ? (
              <ConditionalContextProvider
                conditionalContext={conditionalContext}
                allConditions={getConditions(flow)}
              >
                <Flow
                  flow={flow}
                  interactionId={id}
                  interactionKind={kind}
                  interactionReferenceId={interactionReferenceId ?? undefined}
                  onRefreshSnapshot={showSnapshotCta ? showSnapshotModal : undefined}
                  patientId={patientId}
                  readOnly={readOnly}
                  selectableFlows={selectableFlowOptions}
                  onAddFlow={handleAddFlow}
                  onRemoveFlow={handleRemoveFlow}
                  allAddableFlowIds={allAddableFlowIds}
                />
              </ConditionalContextProvider>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

function existingFlowReducer(flowIds: string[], flowStep: FlowStep) {
  if (getFlowStepType(flowStep) === FlowStepType.Flow) {
    return (flowStep as TFlow).elements.reduce(existingFlowReducer, [
      ...flowIds,
      (flowStep as TFlow).id,
    ]);
  } else {
    return flowIds;
  }
}
