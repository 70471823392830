import gql from 'graphql-tag';

import { PROVIDER_ALL } from 'src/stores/fragments/providers';

export const ADD_MONITORED_PROVIDER = gql`
  mutation AddMonitoredProvider($id: ID!) {
    monitorProvider(id: $id) {
      ...ProviderAll
    }
  }
  ${PROVIDER_ALL}
`;
