import { Theme, makeStyles } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

export const useButtonStyles = makeStyles<Theme>(theme => ({
  button: {
    borderColor: `${Colors.Gray6}55`,
    borderRadius: theme.spacing(0.5),
    color: Colors.Gray6,
    fontSize: theme.typography.fontSize * 0.7,
    textTransform: 'none',
  },
  iconButton: {
    border: 'solid 1px',
    height: 25,
    width: 25,
  },
  neutral: {
    borderColor: `${Colors.BlueSpruce}55`,
    color: Colors.BlueSpruce,
  },
  success: {
    borderColor: `${Colors.FruitSalad}55`,
    color: Colors.FruitSalad,
  },
  warning: {
    borderColor: `${Colors.Ronchi}55`,
    color: Colors.Ronchi,
  },
  error: {
    borderColor: `${Colors.Coral}55`,
    color: Colors.Coral,
  },
}));
