import React from 'react';

import { multilineStringDef } from 'src/components/forms/schemas/util';

// This site explains more about this assessment.
const ASAM_SITE_URL = `https://www.asamcontinuum.org/knowledgebase/what-are-the-six-dimensions-of-the-asam-criteria/`;
// Link to notion page with questions to answer during this assessment. Has the same questions displayed in the tooltips.
const ASAM_ASSESSMENT_QUESTIONS_URL = `https://www.notion.so/boulder/ASAM-Assessment-11bd7fab476a4eee88ed3ad744f97941`;

export default {
  type: 'object',
  title: 'ASAM Assessment',
  properties: {
    questions: {
      customField: (
        <div style={{ marginTop: 20 }}>
          <a href={ASAM_ASSESSMENT_QUESTIONS_URL} target="_blank" rel="noopener noreferrer">
            ASAM Assessment questions
          </a>
        </div>
      ),
    },
    dimension1: {
      ...multilineStringDef,
      title: 'Acute Intoxication and/or Withdrawal Potential',
      htmlTooltip: `
      <p>What risk is associated with the patient’s current level of acute intoxication?</p>
      <p>Is there serious risk of severe withdrawal symptoms or seizures based on the patient’s previous withdrawal history, amount, frequency, and recency of discontinuation or significant reduction of alcohol or other drug use?</p>
      <p>Are there current signs of withdrawal?</p>
      <p>Does the patient have supports to assist in ambulatory detoxification if medically safe?</p>
      `,
    },
    dimension2: {
      ...multilineStringDef,
      title: 'Biomedical Conditions and Complications',
      htmlTooltip: `
      <p>Are there current physical illnesses other than withdrawal, that need to be addressed or which complicate treatment?</p>
      <p>Are there chronic illnesses which might be exacerbated by withdrawal?</p>
      <p>Are there chronic conditions that affect treatment?</p>
      `,
    },
    dimension3: {
      ...multilineStringDef,
      title: 'Emotional, Behavioral or Cognitive Conditions and Complications',
      htmlTooltip: `
      <p>Are there current psychiatric illnesses or psychological, behavioral or emotional problems that need to be addressed or which complicate treatment?</p>
      <p>Are there chronic conditions that affect treatment?</p>
      <p>Do any emotional/behavioral problems appear to be an expected part of addiction illness or do they appear to be separate?</p>
      <p>Even if connected to addiction, are they severe enough to warrant specific mental health treatment?</p>
      <p>Is the patient suicidal, and if so, what is the lethality?</p>
      `,
    },
    dimension4: {
      ...multilineStringDef,
      title: 'Readiness to Change',
      htmlTooltip: `
      <p>Does the patient feel coerced into treatment or actively object to receiving treatment?</p>
      <p>How ready is the patient to change?</p>
      <p>If willing to accept treatment, how strongly does the patient disagree with others’ perception that s/he has an addiction problem?</p>
      <p>Is the patient compliant to avoid a negative consequence (externally motivated), or internally distressed in a self-motivated way about his/her alcohol or other drug use problems?</p>
      `,
    },
    dimension5: {
      ...multilineStringDef,
      title: 'Return to Use, Continued Use or Continued Problem Potential',
      htmlTooltip: `
      <p>Is the patient in immediate danger of continued severe distress and drinking/drugging behavior?</p>
      <p>Does the patient have any skills for how to cope with his/her SUD problems?</p>
      <p>What severity of problems and further distress will potentially continue or reappear, if the patient is not successfully engaged into treatment at this time?</p>
      <p>How aware is the patient of return to use triggers?</p>
      <p>What is the patient’s level of current craving?</p>
      `,
    },
    dimension6: {
      ...multilineStringDef,
      title: ' Recovery and Living Environment',
      htmlTooltip: `
      <p>Are there any dangerous family, significant other, living or school/working situations threatening treatment engagement and success?</p>
      <p>Does the patient have supportive friendship, financial or educational/vocational resources to improve the likelihood of successful treatment?</p>
      <p>Are there barriers to access to treatment such as transportation or child care responsibilities?</p>
      <p>Are there legal vocational, social service agency or criminal justice mandates that may enhance motivation for engagement into treatment?</p>
      `,
    },
    link: {
      displayOnly: true,
      title: '',
      renderer: {
        component: () => {
          return (
            <a rel="noreferrer" target="_blank" href={ASAM_SITE_URL}>
              ASAM CONTINUUM Website
            </a>
          );
        },
      },
    },
  },
  required: [],
};
