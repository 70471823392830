/* This is one of the recommended ways to support custom field
 * components in uniforms, and allows us to customize our
 * mapping for which fields get rendered with which components
 *
 * See: https://uniforms.tools/docs/examples-custom-fields
 */
import React from 'react';
import { connectField, filterDOMProps } from 'uniforms';

/* eslint-disable import/no-named-default */
import { AutoField as OriginalAutoField } from 'uniforms-material';

/* eslint-enable */

/*
 * There is an existing issue where NestField (for objects) and
 * ListItemField (used by ListField for arrays) use the default
 * AutoField instead of the one passed into AutoForm, which
 * would be necessary for rendering child properties with custom
 * fields. So we override them with our own version with the same name.
 * This also creates a dependency cycle that we need to disable the
 * corresponding lint rule for.
 */

/* eslint-disable import/no-cycle */
import AutocompleteField from 'src/components/forms/fields/autocompleteField';
import ConditionalField from 'src/components/forms/fields/conditionalField';
import DateField from 'src/components/forms/fields/dateField';
import DateTimeField from 'src/components/forms/fields/dateTimeField';
import ExplicitEmptyField from 'src/components/forms/fields/explicitEmptyField';
import ImageField from 'src/components/forms/fields/imageField';
import ListField from 'src/components/forms/fields/listField';
import NestField from 'src/components/forms/fields/nestField';
import RichTextField from 'src/components/forms/fields/richTextField/';
import SectionField from 'src/components/forms/fields/sectionField';
import SelectWithDependentsField from 'src/components/forms/fields/selectWithDependents';
import SelectWithOtherField from 'src/components/forms/fields/selectWithOtherField';
import TextField from 'src/components/forms/fields/textField';
import FieldGroup from 'src/components/general/fieldGroup';
import IconTooltip from 'src/components/general/iconTooltip';

/* eslint-enable */

// Register an custom properties so that they get filtered out
filterDOMProps.register('allowOther');
filterDOMProps.register('displayOnly');
filterDOMProps.register('hideRendering');
filterDOMProps.register('htmlTooltip');
filterDOMProps.register('tooltip');
filterDOMProps.register('wrapperStyle');
filterDOMProps.register('findDependentValue');
filterDOMProps.register('dependsOn');
filterDOMProps.register('disableOnDependent');
filterDOMProps.register('displayOnDependent');
filterDOMProps.register('changeOnDependent');

const SELECT_PLACEHOLDER = 'Select...';

// Determine whether a custom React Component should be used to
// represent a field type based on the props passed in
function getCustomFieldFromProps(props) {
  if (props) {
    if (props.labelOnly) {
      return ({ label }) => (
        <FieldGroup label={label} alwaysShow>
          <span />
        </FieldGroup>
      );
    }
    if (props.taggedText) {
      return RichTextField;
    }
    if (props.allowedValues && props.field.allowOther) {
      return SelectWithOtherField;
    }
    if (props.field.explicitEmptyField) {
      return ExplicitEmptyField;
    }
    if (props.field.autocomplete) {
      return AutocompleteField;
    } else if (props.field.image) {
      return ImageField;
    }
    if (
      props.fieldType === Object &&
      Object.entries(props.properties).some(([, item]) => item.dependsOn)
    ) {
      return ConditionalField;
    }
    if (props.field.format) {
      switch (props.field.format) {
        case 'date':
          return DateField;
        case 'date-time':
          return DateTimeField;
        case 'section':
          return SectionField;
        case 'selectable':
          return SelectWithDependentsField;
        default:
          break;
      }
    }
    if (!props.allowedValues) {
      switch (props.fieldType) {
        case String:
          return TextField;
        case Array:
          return ListField;
        case Object:
          return NestField;
        default:
          break;
      }
    }
  }
  return undefined;
}

const getCustomPropsFromProps = props => {
  const customProps = { ...props };

  // In the v2.3.1 release of Uniforms, the placeholder or label text of the field is always set as
  // the first option in the drop down. The idea behind this is that it gives the user a mechanism
  // to cancel their selection. However, for us this meant that the field labels were being used
  // which in many cases was confusing. This property standardizes the value to something less
  // confusing.
  if (props.field.enum && !props.placeholder) {
    customProps.placeholder = SELECT_PLACEHOLDER;
  }

  if (props.field.format === 'selectable') {
    customProps.placeholder = SELECT_PLACEHOLDER;
  }

  return customProps;
};

/*
 * Given the properties for this field, return the React component
 * that should be used to display this field. This will be either
 * one of our custom field types or one of the default field types
 * (identified by AutoField), depending on the props.
 */
const getFieldComponentFromProps = props => {
  // Since this is a display only field we shouldn't render it in the form
  if (props && props.displayOnly === true) {
    return null;
  }

  if (props.customField) {
    return props.customField;
  }

  const Component = getCustomFieldFromProps(props) || OriginalAutoField;
  const customProps = getCustomPropsFromProps(props);

  let WrapperComponent;
  const wrapperProps = {};

  if (customProps.field?.tooltip) {
    WrapperComponent = IconTooltip;
    wrapperProps.title = customProps.field.tooltip;
  } else if (customProps.field?.htmlTooltip) {
    WrapperComponent = IconTooltip;
    /* eslint-disable react/no-danger */
    wrapperProps.title = (
      <div dangerouslySetInnerHTML={{ __html: customProps.field.htmlTooltip }} />
    );
    /* eslint-enable react/no-danger */
  }

  if (customProps.wrapperStyle) {
    wrapperProps.style = customProps.wrapperStyle;
  }

  if (Object.keys(wrapperProps).length) {
    if (WrapperComponent) {
      return (
        <WrapperComponent {...wrapperProps}>
          <Component {...customProps} />
        </WrapperComponent>
      );
    } else {
      return (
        <div {...wrapperProps}>
          <Component {...customProps} />
        </div>
      );
    }
  } else {
    return <Component {...customProps} />;
  }
};

/*
 * This connects the component we want to use to the form-specific
 * logic (e.g. validation, error messaging, onChange handlers, etc.)
 *
 * NOTE: The option includeInChain is true by default and has to do
 * with how uniforms manages it's context. We need it to be false
 * in order to support multi-level objects (they use the same setting
 * in their default ListField and NestFields, and it's suggested
 * in the example for custom AutoFields.
 */
const AutoField = connectField(getFieldComponentFromProps, {
  ensureValue: false,
  includeInChain: false,
  initialValue: false,
});

export default AutoField;
