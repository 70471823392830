import React, { useContext } from 'react';

import ReferenceControl from 'src/components/forms/controls/reference';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import ProviderDataService from 'src/data/providers/ProviderDataService';

const ProviderReference = ({ loadOptions, ...props }) => {
  const { apolloClient } = useContext(ApolloClientContext);

  if (!apolloClient) {
    return null;
  }

  const providerDataService = new ProviderDataService(apolloClient);

  return (
    <ReferenceControl
      {...props}
      labelFn={option => `${option.firstName} ${option.lastName}`}
      valueFn={option => option.id}
      loadOptions={loadOptions || providerDataService.search.bind(providerDataService)}
    />
  );
};

export default ProviderReference;
