import { DocumentNode } from 'graphql';
import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import { useEffect, useMemo } from 'react';
import useSWR, { KeyedMutator, useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { useDeepCompareMemo } from 'use-deep-compare';

import {
  GET_ALL_CHART_PROPERTIES,
  QueryResult as AllChartPropertiesQueryResult,
} from 'src/nightingale/data//queries/getAllChartProperties';
import {
  withLabel,
  withSummarization,
  withValue,
  withValues,
} from 'src/nightingale/data/ChartElement.utils';
import { getFlowStepType } from 'src/nightingale/data/Flow.utils';
import {
  GET_CHART_PROPERTY_VALUES_FOR_INTERACTION,
  QueryResult as InteractionChartPropertyValuesQueryResult,
} from 'src/nightingale/data/queries/getChartPropertyValuesForInteraction';
import {
  GET_INTERACTION_SCHEMA,
  QueryResult as InteractionQueryResult,
} from 'src/nightingale/data/queries/getInteractionSchema';
import { makeSummarizationContext } from 'src/nightingale/summarization';
import { AdditionalContext, Context } from 'src/nightingale/summarization/types';
import {
  FlowStepType,
  Interaction,
  ChartPropertyValue,
  AnyChartProperty,
  InteractionKind,
  ChartPropertyWithValue,
} from 'src/nightingale/types/types';
import useRootStore from 'src/stores/useRootStore';

const getElementsWithValues = (elements, chartValues, summarizationContext) =>
  elements.map(element => {
    if (getFlowStepType(element) === FlowStepType.ChartElement) {
      const preparedElement = withLabel(withValues(element, chartValues));
      return withSummarization(preparedElement, summarizationContext);
    } else {
      return {
        ...element,
        elements: getElementsWithValues(element.elements, chartValues, summarizationContext),
      };
    }
  });

/**
 * Stitches the interaction schema and chart property values together.
 */
const getInteractionWithValues = (
  interaction,
  chartPropertyValues,
  summarizationContext,
): Interaction => {
  const chartValues = keyBy(chartPropertyValues, ({ propertyName }) => propertyName);

  const elementsWithValues = getElementsWithValues(
    interaction.flow.elements,
    chartValues,
    summarizationContext,
  );

  return {
    ...interaction,
    flow: { ...interaction.flow, elements: elementsWithValues },
  };
};

/**
 * Detect stale patient chart values in the interaction and return them.
 *
 * If any CPV has a createdAt timestamp greater than the interaction's patientSnapshotTime AND that
 * same CPV was NOT created as part of this interaction, then we are showing an outdated value for
 * that CP within the interaction's chart element summaries.
 */
const findUpdatedChartProperties = (
  { id: interactionId, patientSnapshotTime }: Interaction,
  patientLatestChartPropertyValues: Array<
    Pick<ChartPropertyValue, 'interactionId' | 'propertyName'> &
      Required<Pick<ChartPropertyValue, 'createdAt'>>
  >,
): Array<
  Pick<ChartPropertyValue, 'interactionId' | 'propertyName'> &
    Required<Pick<ChartPropertyValue, 'createdAt'>>
> =>
  patientLatestChartPropertyValues.filter(
    cpv =>
      // patientSnapshotTime is mistakenly typed as a Date but it's really a string since it came
      // straight out of a GraphQL query result
      new Date(cpv.createdAt) > new Date(patientSnapshotTime as unknown as string) &&
      cpv.interactionId !== interactionId,
  );

const KEY_INTERACTION_SCHEMA_PREFIX = 'interaction_schema';
const KEY_CHART_PROPERTY_VALUES_PREFIX = 'chart_property_values';

const getSWRKey = (prefix: string, identifier: string): string => `${prefix}${identifier}`;

export const getSWRInteractionSchemaKey = (referenceId: string): string =>
  getSWRKey(KEY_INTERACTION_SCHEMA_PREFIX, referenceId);

export const getSWRChartPropertyValuesKey = (patientId: string, referenceId: string): string =>
  getSWRKey(KEY_CHART_PROPERTY_VALUES_PREFIX, `${patientId}${referenceId}`);

type DefaultFetcher<ReturnType = unknown> = ([query, variables]: [
  DocumentNode,
  Record<string, unknown> | undefined,
]) => Promise<ReturnType>;

/**
 * Hook that gets the interaction schema and all chart property values
 * from the BFF, stitches them together and adds parsed summaries.
 */
export const useSWRInteraction = ({
  referenceId,
  interactionKey,
  patientId,
  additionalSummarizationContext,
  kind,
}: {
  referenceId: string;
  interactionKey: string;
  patientId: string;
  additionalSummarizationContext?: Partial<AdditionalContext>;
  kind: InteractionKind;
}): {
  data?: { interaction: Interaction; context: Context };
  error?: any;
  isLoading: boolean;
  mutateChartPropertyValues: KeyedMutator<InteractionChartPropertyValuesQueryResult>;
  mutateInteraction: KeyedMutator<InteractionQueryResult>;
} => {
  const { fetcher } = useSWRConfig();

  const { data: chartPropertyData, error: chartPropertyDataError } =
    useSWRImmutable<AllChartPropertiesQueryResult>(GET_ALL_CHART_PROPERTIES);
  const {
    data: interactionData,
    error: interactionDataError,
    mutate: mutateInteraction,
  } = useSWR<InteractionQueryResult>(
    getSWRInteractionSchemaKey(referenceId),
    () => {
      return (fetcher as DefaultFetcher<InteractionQueryResult>)([
        GET_INTERACTION_SCHEMA,
        {
          referenceId,
          interactionKey,
          kind,
        },
      ]);
    },
    { revalidateIfStale: false, revalidateOnFocus: false },
  );
  const patientSnapshotTime = interactionData?.interaction?.patientSnapshotTime;
  const {
    data: cpvData,
    error: cpvDataError,
    mutate: mutateChartPropertyValues,
  } = useSWR<InteractionChartPropertyValuesQueryResult>(
    interactionData ? getSWRChartPropertyValuesKey(patientId, referenceId) : null,
    () =>
      (fetcher as DefaultFetcher<InteractionChartPropertyValuesQueryResult>)([
        GET_CHART_PROPERTY_VALUES_FOR_INTERACTION,
        {
          patientId,
          patientSnapshotTime,
          referenceId,
        },
      ]),
  );

  const rootStore = useRootStore();

  // The CPV query is not keyed on the snapshot time, to avoid new loaders
  // every time that argument changes. Instead we invalidate the cache manually
  // if the snapshot time changes.
  useEffect(() => {
    mutateChartPropertyValues();
  }, [patientSnapshotTime]);

  let error = chartPropertyDataError || interactionDataError || cpvDataError;
  const isLoading = !(chartPropertyData && interactionData && cpvData) && !error;

  let allChartProperties: AnyChartProperty[] = [];
  if (chartPropertyData) {
    try {
      allChartProperties = JSON.parse(chartPropertyData.allChartProperties);
      if (!Array.isArray(allChartProperties)) {
        throw new Error('received invalid chart definitions response');
      }
    } catch (e) {
      error = e;
    }
  }
  const { interaction } = interactionData || {};
  const {
    chartPropertyValues = [],
    interactionValues = [],
    patientLatestChartPropertyValues = [],
    patient = { id: patientId, enrollmentDate: null, treatmentStartDate: null },
  } = cpvData || {};

  const updatedChartProperties = useDeepCompareMemo(() => {
    if (!interaction || !patientLatestChartPropertyValues.length) return [];
    return findUpdatedChartProperties(interaction, patientLatestChartPropertyValues);
  }, [interaction, patientLatestChartPropertyValues]);

  const context = useDeepCompareMemo(
    () =>
      makeSummarizationContext(chartPropertyValues, allChartProperties, {
        patient,
        provider: {
          id: rootStore.auth.user?.id || 'unknown',
          timezone: rootStore.auth.user?.timezone || 'America/Los_Angeles',
        },
        ...additionalSummarizationContext,
      }),
    [chartPropertyValues, allChartProperties, patient, referenceId],
  );

  const fullInteractionValues = useDeepCompareMemo(() => {
    const allChartPropertiesByName = keyBy(allChartProperties, 'name');

    return compact(
      interactionValues.map(cpv => {
        const definition = allChartPropertiesByName[cpv.propertyName];
        return definition ? (withValue(definition, cpv) as ChartPropertyWithValue) : undefined;
      }),
    );
  }, [interactionValues, allChartProperties]);

  const parsedInteraction = useDeepCompareMemo(() => {
    if (!interaction) return { updatedChartProperties } as Interaction;
    return {
      ...getInteractionWithValues(interaction, chartPropertyValues, context),
      updatedChartProperties,
      interactionValues: fullInteractionValues,
    };
  }, [interaction, chartPropertyValues, context, updatedChartProperties]);

  const parsedData = useMemo(
    () => ({
      interaction: parsedInteraction,
      context,
    }),
    [parsedInteraction, context],
  );

  return {
    data: parsedData,
    error,
    isLoading,
    mutateChartPropertyValues,
    mutateInteraction,
  };
};
