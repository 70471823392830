import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connectField, injectName, joinName } from 'uniforms';

/* eslint-disable import/no-cycle */
import AutoField from 'src/components/forms/fields/autoField';

/* eslint-enable */

const SectionField = ({ children, classes, fields, itemProps, label, name }) => (
  <div className={classes.sectionContainer}>
    {label && <h1 className={classes.sectionLabel}>{label}</h1>}
    {children
      ? injectName(name, children)
      : fields.map(key => <AutoField key={key} name={joinName(name, key)} {...itemProps} />)}
  </div>
);

const styles = {
  sectionContainer: {
    paddingBottom: 30,
  },
  sectionLabel: {
    fontSize: 20,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#dddddd',
  },
};

export default withStyles(styles)(
  connectField(SectionField, {
    ensureValue: false,
    includeInChain: false,
  }),
);
