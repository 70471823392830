import { Button, makeStyles } from '@material-ui/core';
import { Videocam } from '@material-ui/icons';
import { useRouterStore } from 'mobx-state-router';
import React, { useCallback, useContext, useState } from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import ErrorAlert from 'src/components/general/ErrorAlert';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { ACCEPT_MUTATION } from 'src/dropInClinic/queries';
import { AcceptMutationArgs, AcceptMutationResponse } from 'src/dropInClinic/types';
import { ViewChartButton } from 'src/myDayToday/components/ViewChartButton';
import { BoulderColors } from 'src/util/brand';

type AcceptPopInSuggestionProps = {
  patientId: string;
  requestId: string;
};

const AcceptPopInClinicSuggestionButton = (props: AcceptPopInSuggestionProps) => {
  const styles = useStyles();

  const { apolloClient } = useContext(ApolloClientContext);
  const [requestUpdateError, setRequestUpdateError] = useState(null);
  const router = useRouterStore();

  const handleAccept = useCallback(
    async (id: string) => {
      setRequestUpdateError(null);
      if (!apolloClient) {
        return;
      }

      try {
        const response = await apolloClient.mutate<AcceptMutationResponse, AcceptMutationArgs>({
          mutation: ACCEPT_MUTATION,
          variables: { requestId: id },
        });

        const eventId = response.data?.acceptDropInClinicRequest.eventId;
        if (eventId) {
          const videoUrl = generateRouteUrl('eventVc', { event: eventId });
          window.open(videoUrl, '_blank');
          router.goTo('showEvent', { params: { id: eventId } });
        }
      } catch (e) {
        setRequestUpdateError(e);
      }
    },
    [apolloClient, setRequestUpdateError],
  );

  return (
    <div className={styles.details}>
      {requestUpdateError && (
        <ErrorAlert message="Error updating request" error={requestUpdateError} />
      )}
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'flex-end' }}>
        <ViewChartButton patientId={props.patientId} />
        <Button className={styles.acceptButton} onClick={() => handleAccept(props.requestId)}>
          <Videocam />
          Accept and Start
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    gap: 8,
    flexGrow: 1,
  },
  acceptButton: {
    backgroundColor: BoulderColors.Peach5,
    color: 'white',
    alignSelf: 'flex-end',
    '& svg': {
      marginRight: 6,
    },
    '&:hover': {
      backgroundColor: BoulderColors.Peach5,
    },
  },
});

export default AcceptPopInClinicSuggestionButton;
