export const subTypes = [
  'general',
  'staff_meeting',
  'prescriber_followup',
  'prescriber_initial',
  'prescriber_note',
  'prescriber_reengagement_visit',
  'prescriber_paneling_followup',
  'prescriber_bridge_care',
  'prescriber_drop_in_followup',
  'prescriber_drop_in_intake',
  'prescriber_drop_in_reengagement',
  'prescriber_mental_health_assessment',
  'peer_coach_visit',
  'peer_coach_drop_in_visit',
  'peer_followup',
  'peer_intake',
  'peer_recovery_group',
  'peer_note',
  'mam_visit',
  'oft_visit',
  'rcv',
  'video_test',
  'inperson_general',
  'note',
  'onboarding_checklist',
  'phone_call',
  'rcv_setup',
  'inquiry_potential_patient',
  'inquiry_current_patient',
  'inquiry_covid19_screening',
  'inquiry_referrer',
  'inquiry_webform',
  'inquiry_webform_call',
  'inquiry_inapp_call',
  'inquiry_inapp_enrollment',
  'inquiry_other',
  'prescriber_covid19_screening',
  'blocked',
  'blocked_admin',
  'blocked_drop_in_admin',
  'blocked_flex_holiday',
  'blocked_holiday',
  'blocked_internal',
  'blocked_meeting',
  'blocked_personal',
  'case_manager_consultation',
  'case_manager_intake',
  'case_manager_follow_up',
  'case_manager_note',
  'case_manager_transition',
  'case_manager_drop_in_visit',
  'care_advocate_intake',
  'care_advocate_drop_in_intake',
  'care_advocate_drop_in_oft',
  'hold_new_patient',
  'hold_paneling_follow_up',
  'registered_nurse',
  'registered_nurse_bridge_care',
  'registered_nurse_drop_in_followup',
  'registered_nurse_reengagement',
  'bridge_prescription_request',
  'registered_nurse_drop_in_reengagement',
  'google_event',
  'discharge_summary',
] as const;

const subTypeLookup = new Set<string>(subTypes);

export type SubType = (typeof subTypes)[number];

export function isSubType(maybeSubType: string | null | undefined): maybeSubType is SubType {
  return !!maybeSubType && subTypeLookup.has(maybeSubType);
}

export const __test__ = {
  subTypes,
};
