import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NoteAddIcon from '@material-ui/icons/NoteAddOutlined';
import IconNotes from '@material-ui/icons/Notes';
import classNames from 'classnames';
import React from 'react';

import { useButtonStyles } from 'src/components/general/PatientMenu/highlights/useButtonStyles';
import { useSWRPatientHighlights } from 'src/components/general/PatientMenu/highlights/useSWRPatientHighlights';
import useTooltipStyles from 'src/components/general/PatientMenu/useTooltipStyles';

const FYI_MAX_LENGTH = 500;

const Fyi: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { data } = useSWRPatientHighlights(patientId);
  const tooltipStyles = useTooltipStyles();
  const styles = useButtonStyles();

  if (!data?.patientHighlights) {
    return null;
  }

  const content = data.patientHighlights.fyi?.content;
  const isFlagged = data.patientHighlights.fyi?.isFlagged === true;

  const buttonClassNames = [styles.button, styles.iconButton];
  if (isFlagged && content) {
    buttonClassNames.push(styles.error);
  } else {
    buttonClassNames.push(styles.neutral);
  }

  return (
    <Tooltip
      title={content ? trimmedFyi(content) : <em>Click to add FYI</em>}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a href={`/patients/${patientId}/overview#fyi`}>
        {content ? (
          <IconButton className={classNames(buttonClassNames)} size="small">
            <IconNotes style={{ height: 16, width: 16 }} />
          </IconButton>
        ) : (
          <IconButton className={classNames(buttonClassNames)} size="small">
            <NoteAddIcon
              aria-label="Add a Patient FYI Sticky Note"
              style={{ height: 16, width: 16 }}
            />
          </IconButton>
        )}
      </a>
    </Tooltip>
  );
};

function trimmedFyi(note: string) {
  if (note.length < FYI_MAX_LENGTH) {
    return `FYI: ${note}`;
  }

  return `FYI: ${note.substring(0, FYI_MAX_LENGTH)}…`;
}

export default Fyi;
