import { format, formatInTimeZone } from 'date-fns-tz';
import type { DateRangeFormatFunction, Formats } from 'react-big-calendar';

function createDayRangeHeaderFormat(timeZone: string): DateRangeFormatFunction {
  return ({ start, end }) =>
    [
      format(start, 'MMMM dd', { timeZone }),
      start.getMonth() === end.getMonth()
        ? format(end, 'dd (zzz)', { timeZone })
        : format(end, 'MMMM dd (zzz)', { timeZone }),
    ].join(' - ');
}

function createAgendaHeaderFormat(timeZone: string): DateRangeFormatFunction {
  return ({ start, end }) => {
    return `${format(start, 'P', { timeZone })} — ${format(end, 'P (zzz)', { timeZone })}`;
  };
}

function createTimeRangeFormat(timeZone: string): DateRangeFormatFunction {
  return ({ start, end }) =>
    [
      formatInTimeZone(start, timeZone, 'h:mm a'),
      formatInTimeZone(end, timeZone, 'h:mm a zzz'),
    ].join(' – ');
}

export function createFormats(timeZone: string): Formats {
  /**
   * We need to be thoughtful here about whether to use format() or formatInTimeZone(). When
   * react-big-calendar wants to display date information without a particular time, it will use a
   * Date set to 00:00:00 in the system's local timezone. If the timezone we're using for display is
   * west of the system's local timezone, though, 00:00:00 "today" here will be late in the evening
   * "yesterday" there. So if we use formatInTimezone in that context, something that ought to say
   * "Wednesday", for example, would actually say "Tuesday."
   */
  return {
    // Day of the month; date label in month view
    dateFormat: (date: Date) => format(date, 'dd'),
    // Day of the week; column headers in week view
    dayFormat: (date: Date) => format(date, 'dd E'),
    // Week day name format; column headers in month view
    weekdayFormat: (date: Date) => format(date, 'EEE'),
    // Timestamps to the left of the calendar
    timeGutterFormat: 'h:mm a zzz',
    // Main header for month view
    monthHeaderFormat: (date: Date) => format(date, 'MMMM yyyy (zzz)', { timeZone }),
    // Main header for week view
    dayRangeHeaderFormat: createDayRangeHeaderFormat(timeZone),
    // Main header for day view
    dayHeaderFormat: (date: Date) => format(date, 'EEEE MMM dd (zzz)', { timeZone }),
    // Main header for agenda view
    agendaHeaderFormat: createAgendaHeaderFormat(timeZone),
    // Time range shown when dragging to create events
    selectRangeFormat: createTimeRangeFormat(timeZone),
    // Date format shown in agenda view
    agendaDateFormat: 'MMM d, yyyy',
    // Standalone times when shown in agenda view
    agendaTimeFormat: 'h:mm a zzz',
    // Time range displayed in the agenda view
    agendaTimeRangeFormat: createTimeRangeFormat(timeZone),
    // Time range displayed on event cards in the calendar
    eventTimeRangeFormat: createTimeRangeFormat(timeZone),
  };
}
