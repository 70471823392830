export default {
  type: 'object',
  title: 'Care Advocate: OFT Results',
  properties: {
    result: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
    additionalNotes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
