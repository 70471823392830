import { format, isValid } from 'date-fns';

export const DATE_FORMAT_DISPLAY = 'MM/dd/yyyy';

export class MaybeDate {
  constructor(
    private date: Date,
    private input: string,
  ) {}

  serializeForData(): string {
    return isValid(this.date) ? format(this.date, 'yyyy-MM-dd') : this.input;
  }

  serializeForDisplay(): string {
    return isValid(this.date) ? format(this.date, DATE_FORMAT_DISPLAY) : this.input;
  }

  get value() {
    return this.date;
  }
}
