import { formatInTimeZone } from 'date-fns-tz';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isValid from 'date-fns/isValid';
import React from 'react';

type NextEventDateProps = {
  eventId: string;
  start: Date;
  timezone: string;
};

export const NextEventDate = ({ eventId, start, timezone }: NextEventDateProps) => {
  const originDate = new Date();

  if (!isValid(start)) {
    return null;
  }

  const nextEventFormatted = formatInTimeZone(start, timezone, "MMM d, yyyy 'at' h:mm a zzz");

  const numberOfDays = differenceInCalendarDays(start, originDate);
  const dayText = numberOfDays === 1 ? 'day' : 'days';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
      <a href={`/events/${eventId}`} target="_blank" rel="noreferrer">
        Visit scheduled for {nextEventFormatted}
      </a>
      <div>
        Patient needs {numberOfDays} {dayText} of medication
      </div>
    </div>
  );
};
