import { gql } from 'src/generated/gql';

export const PATIENT_SHIPPING_ADDRESS_QUERY = gql(`
  query PatientShippingAddress($patientId: ID!) {
    staff_getPatientShippingAddress(patientId: $patientId) {
      houselessStatus
      address {
        firstName
        lastName
        address1
        address2
        zip
        city
        state
      }
    }
  }
`);
