export default {
  type: 'object',
  title: 'Inquiry: Call Details',
  properties: {
    callStartTime: {
      type: 'string',
    },
    callEndTime: {
      type: 'string',
    },
  },
  required: ['callStartTime', 'callEndTime'],
};
