import Typography from '@material-ui/core/Typography';
import React from 'react';

import PatientOverviewLink from 'src/components/general/patientOverviewLink';

const notes = {
  type: 'string',
  uniforms: {
    multiline: true,
  },
};

const renderer = key => ({
  component: ({ model, classes }) => (
    <div className={classes.itemDetails}>
      <span>{model[key].selectionKey === 'yes' ? 'Yes' : 'No'}</span>
      <span>
        {model[key].additional && (
          <Typography variant="body2" className={classes.multiLineText}>
            {model[key].additional.notes}
          </Typography>
        )}
      </span>
    </div>
  ),
});

/* eslint-disable react/no-unescaped-entities */
export default {
  type: 'object',
  title: 'Prescriber: Subjective (Initial)',
  properties: {
    reviewedSubstanceUse: {
      type: 'boolean',
      title: 'Reviewed/updated "Substance use"',
      label: (
        <span>
          Reviewed/updated "
          <PatientOverviewLink section="substanceUse">Substance Use</PatientOverviewLink>"
        </span>
      ),
    },
    infectionConcern: {
      type: 'object',
      title: 'Concern for infection related to use',
      format: 'selectable',
      renderer: {
        component: ({ model, classes }) => (
          <>
            {model.infectionConcern.selectionKey === 'yes' && (
              <>
                <span>Yes</span>
                {model.infectionConcern.additional && (
                  <Typography variant="body2" className={classes.multiLineText}>
                    {model.infectionConcern.additional.notes}
                  </Typography>
                )}
              </>
            )}
            {model.infectionConcern.selectionKey === 'no' && <span>No</span>}
          </>
        ),
      },
      properties: {
        yes: {
          type: 'object',
          properties: {
            notes,
          },
        },
        no: true,
      },
    },
    ivduRisk: {
      type: 'object',
      title: 'IV Drug Use Risk',
      format: 'selectable',
      renderer: renderer('ivduRisk'),
      properties: {
        yes: {
          type: 'object',
          properties: { notes },
        },
        no: {
          type: 'object',
          properties: { notes },
        },
      },
    },
    pregnancyRisk: {
      type: 'object',
      title: 'Pregnancy Risk',
      format: 'selectable',
      renderer: renderer('pregnancyRisk'),
      properties: {
        yes: {
          type: 'object',
          properties: { notes },
        },
        no: {
          type: 'object',
          properties: { notes },
        },
      },
    },
    serologies: {
      ...notes,
      title: 'Serologies Tested in the Past',
    },
    desiresBuprenorphine: {
      type: 'string',
      title: 'Desires Buprenorphine',
      enum: ['Yes', 'No'],
    },
    buprenorphineAllergy: {
      type: 'string',
      title: 'Allergy to Buprenorphine',
      enum: ['Yes', 'No'],
    },
    pastTreatment: {
      ...notes,
      title: 'Past treatment experience',
    },
    pastMedication: {
      ...notes,
      title: 'Past medication experience',
    },
    otherConcerns: {
      ...notes,
      title: 'Other concerns today',
    },
    previousUsage: {
      type: 'integer',
      min: 0,
      max: 30,
      title: 'Previous month opioid usage (days)',
      tooltip:
        '"Of the last 30 days, how many have you used opioids (other than any prescribed medications)?"',
      uniforms: {
        fullWidth: true,
        margin: 'dense',
      },
    },
    reviewedMedical: {
      type: 'boolean',
      title: 'Reviewed/updated "Medical"',
      label: (
        <span>
          Reviewed/updated "<PatientOverviewLink section="medical">Medical</PatientOverviewLink>"
        </span>
      ),
    },
    reviewedTeam: {
      type: 'boolean',
      title: 'Reviewed/updated "Team"',
      label: (
        <span>
          Reviewed/updated "<PatientOverviewLink section="team">Team</PatientOverviewLink>"
        </span>
      ),
    },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
/* eslint-enable */
