import v100Schema from 'src/components/forms/schemas/eventResult/inquiryCallDetails/1.0.0';

const { ...v100Properties } = v100Schema.properties;

export default {
  ...v100Schema,
  properties: {
    ...v100Properties,
    callerId: {
      type: 'string',
      format: 'phone',
      title: 'Incoming Caller ID #',
    },
  },
};
