import { makeStyles } from '@material-ui/core/';
import React from 'react';

import Colors from 'src/nightingale/Colors';

export const InsertedTag: React.FC = ({ children }) => {
  const styles = useStyles();
  return <span className={styles.insertedTag}>{children}</span>;
};

const useStyles = makeStyles({
  insertedTag: {
    color: Colors.Gray8,
    backgroundColor: Colors.Gray2,
  },
});
