import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';
import UpArrow from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 2,
    marginRight: 4,
  },
  icon: {
    width: 18,
    height: 18,
    cursor: (props: StyleProps) => (props.noEdit ? 'auto' : 'pointer'),
  },
});

interface StyleProps {
  noEdit?: boolean;
}

interface IncrementorProps {
  setValue: (value: string) => void;
  value: number;
  readOnly?: boolean;
  disabled?: boolean;
}

export const Incrementor: React.FC<IncrementorProps> = ({
  setValue,
  value,
  readOnly,
  disabled,
}) => {
  const noEdit = readOnly || disabled;
  const styleProps: StyleProps = { noEdit };
  const styles = useStyles(styleProps);
  const increment = () => {
    if (noEdit) return;
    if (value) {
      setValue(String(value + 1));
    } else {
      setValue('1');
    }
  };
  const decrement = () => {
    if (noEdit) return;
    if (value) {
      setValue(String(value - 1));
    } else {
      setValue('0');
    }
  };
  return (
    <div className={styles.container} color="red">
      <SvgIcon
        component={UpArrow}
        onClick={increment}
        viewBox="0 -5 24 24"
        data-testid="integer-increment"
        className={styles.icon}
      />
      <SvgIcon
        component={DownArrow}
        onClick={decrement}
        viewBox="0 5 24 24"
        data-testid="integer-decrement"
        className={styles.icon}
      />
    </div>
  );
};
