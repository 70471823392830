import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles } from '@material-ui/core/styles';
import type { ClassNameMap } from '@material-ui/styles';
import React, { useState } from 'react';

import { MenuPatient } from 'src/generated/gql/graphql';
import Colors from 'src/nightingale/Colors';
import { MagnifyIcon } from 'src/patientHeader/components/MagnifyIcon';
import { getFullName } from 'src/patientHeader/domain/getFullName';

const photoStyles = () =>
  createStyles({
    profilePhotoButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'block',
      outline: 'none',
      position: 'relative',
    },
    profilePhoto: {
      background: Colors.BoulderBlue,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      borderColor: Colors.BoulderBlue,
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      boxSizing: 'border-box',
      color: Colors.White,
      display: 'block',
      height: 50,
      imageResolution: '30dpi',
      margin: 0,
      width: 50,
      // `cover` will center the image in the provided space and crop any overflow
      objectFit: 'cover',
    },
    embiggenedProfilePhoto: {
      display: 'block',
      height: 'auto',
      maxWidth: '100%',
      width: '100%',
    },
  });

export const PatientAvatar = withStyles(photoStyles)(({
  patient,
  classes,
}: {
  patient: MenuPatient;
  classes: ClassNameMap;
}) => {
  const [embiggenProfile, setEmbiggenProfile] = useState(false);

  if (!patient.profilePhoto) return <div />;

  const photoUrl = patient.profilePhoto;
  const altText = getFullName(patient);

  return (
    <>
      <div className={`${classes.profilePhotoButton}`} onClick={() => setEmbiggenProfile(true)}>
        <MagnifyIcon />
        <figure
          className={classes.profilePhoto}
          style={{ backgroundImage: `url(${photoUrl})` }}
          title={altText}
        />
      </div>
      <Dialog open={embiggenProfile} onClose={() => setEmbiggenProfile(false)}>
        <DialogContent>
          <img
            data-testid="profile-avatar-large"
            className={classes.embiggenedProfilePhoto}
            src={photoUrl}
            alt={altText}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});
