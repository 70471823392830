import { getPreferredFullName, getPreferredFirstName } from 'src/shared/stores/resource';

/**
 * Returns the appropriate name formatting
 * depending on the type of message.
 *
 * @param {UserDisplay}
 * @returns {string}
 */
export function getDisplayNameForMessage(user, type) {
  if (type === 'VideoConferenceMessage') {
    return user.patientFacingDisplayName || user.firstName;
  } else if (type === 'TextMessage') {
    return user.patientFacingDisplayName || getPreferredFullName(user);
  } else {
    // Uknown message type
    return '';
  }
}

/**
 * Returns the display name to be shown for a user in the conversation list
 * that is in a patient's "Other Messages"
 *
 * @param {UserDisplay}
 * @returns {string}
 */
export function getDisplayNameWithPatients(user) {
  if (user.patientFacingDisplayName) {
    return `${getPreferredFullName(user)} (${user.patientFacingDisplayName})`;
  } else {
    return getPreferredFullName(user);
  }
}

/**
 * Returns the display name to be shown for Providers in the conversation list
 * in Staff Messages
 *
 * @param {UserDisplay}
 * @returns {string}
 */
export function getDisplayNameWithoutPatients(user) {
  if (user.patientFacingDisplayName) {
    return `${getPreferredFirstName(user)} (${user.patientFacingDisplayName})`;
  } else {
    return getPreferredFirstName(user);
  }
}
