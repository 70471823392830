import useTypedSWR from 'src/components/general/useTypedSWR';
import { PATIENT_INTERNAL_ID_QUERY } from 'src/components/pages/zendesk/usePatientInternalId.gql';

/**
 * Fetches a patient's internal Boulder ID using their external Zendesk ID.
 */
export const usePatientInternalId = (zendeskExternalId: string) => {
  const { data, error } = useTypedSWR([PATIENT_INTERNAL_ID_QUERY, { zendeskExternalId }]);

  return {
    data: data?.staff_getPatientByZendeskExternalId?.id ?? null,
    isLoading: !data && !error,
    error,
  };
};
