import { Button, Tooltip } from '@material-ui/core';
import IconPeople from '@material-ui/icons/People';
import { differenceInDays, intlFormatDistance } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';

import {
  startOfDayInBoulderTimezone,
  startOfTodayInBoulderTimezone,
} from 'src/businessHours/timezone';
import type { PatientHighlightsQuery } from 'src/components/general/PatientMenu/highlights//patientHighlights.gql';
import { useButtonStyles } from 'src/components/general/PatientMenu/highlights/useButtonStyles';
import { useSWRPatientHighlights } from 'src/components/general/PatientMenu/highlights/useSWRPatientHighlights';
import useTooltipStyles from 'src/components/general/PatientMenu/useTooltipStyles';

const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const LastCompletedVisitDate: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { data } = useSWRPatientHighlights(patientId);
  const tooltipStyles = useTooltipStyles();
  const styles = useButtonStyles();

  if (!data) return null;

  const { link, text, tooltip } = getLastVisitDetails(patientId, data);

  return (
    <Tooltip
      title={tooltip}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a href={link}>
        <Button
          className={styles.button}
          fullWidth
          size="small"
          startIcon={<IconPeople data-testid="last-completed-visit-date-icon" />}
          variant="outlined"
        >
          {text}
        </Button>
      </a>
    </Tooltip>
  );
};

const getLastVisitDetails = (
  patientId: string,
  data: PatientHighlightsQuery,
): {
  link?: string;
  text: string;
  tooltip: string;
} => {
  const lastCompletedVisit = data?.patientHighlights?.lastCompletedVisit;
  if (!lastCompletedVisit) return { text: 'None', tooltip: 'None' };

  const { date, id, providers } = lastCompletedVisit;
  const lastCompletedVisitDate = date ? startOfDayInBoulderTimezone(date) : null;
  const today = startOfTodayInBoulderTimezone();

  let daysSinceLastVisit: number | null = null;
  let timeDistance: string | null = null;

  if (lastCompletedVisitDate) {
    daysSinceLastVisit = differenceInDays(lastCompletedVisitDate, today);

    timeDistance =
      Math.abs(daysSinceLastVisit) >= 1
        ? intlFormatDistance(lastCompletedVisitDate, today, { unit: 'day' })
        : 'today';
  }

  return {
    link: id ? `/patients/${patientId}/activity#activity-${id}` : undefined,
    text: lastCompletedVisitDate
      ? formatInTimeZone(lastCompletedVisitDate, localZone, 'MM/dd/yyyy')
      : 'None',
    tooltip: timeDistance
      ? `Last clinical visit: ${timeDistance}${
          providers?.length ? `, with ${providers.join(', ')}` : ''
        }`
      : 'None',
  };
};

export default LastCompletedVisitDate;
