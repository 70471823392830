import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import pick from 'lodash/pick';
import React, { useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import PatientReference from 'src/components/forms/controls/PatientReference';
import DateTimePicker from 'src/components/forms/controls/dateTimePicker';
import ProviderReference from 'src/components/forms/controls/providerReference';
import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import FieldGroup from 'src/components/general/fieldGroup';
import { TURN_OFF_PEBBLES } from 'src/featureFlags/currentFlags';
import EditPebbleForm from 'src/pebbles/components/EditPebbleForm';
import PebbleHistory from 'src/pebbles/components/PebbleHistory';
import PebbleParticipantView from 'src/pebbles/components/PebbleParticipantView';
import { Pebble } from 'src/pebbles/types';
import { usePebbleTopics } from 'src/pebbles/usePebbleTopics';
import {
  transformPebbleForDisplay,
  PEBBLE_STATUS_OPTIONS,
  PEBBLE_VALIDATOR,
  PEBBLE_PRIORITY_OPTIONS,
} from 'src/util/pebbles';

type PebbleFormData = Pick<Pebble, 'id' | 'status' | 'assignee'>;

type EditPebbleDetailsProps = {
  formId: string;
  onCancel: () => void;
  onSave: (values: PebbleFormData) => Promise<void>;
  pebble: Pebble;
  setEditing: (name: string, value: boolean) => void;
  editingSectionName: string;
};

const PebbleDetailsEdit: React.FC<EditPebbleDetailsProps> = ({
  formId,
  onCancel,
  onSave,
  pebble,
  setEditing,
  editingSectionName,
}) => {
  const flags = useContext(FeatureFlagContext);
  const editStyles = useEditStyles();
  const formStyles = useFormStyles();
  const topics = usePebbleTopics();

  const { createdBy, patientName } = transformPebbleForDisplay(pebble);

  // (CHX-771) As a basic initial solution to avoid mixing patient IDs in one pebble's CPV data, you
  // may only edit the pebble's patient if one hasn't been assigned yet.
  const mayEditPatient = !pebble.participant;

  return (
    <Paper elevation={2} className={formStyles.formPadding} data-testid="pebble-edit-form">
      <EditPebbleForm
        item={pick(pebble, [
          'id',
          'title',
          'assignee',
          'participant',
          'monitors',
          'topic',
          'status',
          'reminder',
          'link',
          'priority',
        ])}
        validationSchema={PEBBLE_VALIDATOR}
        onSave={onSave}
        onCancel={onCancel}
        buttonSize="small"
        formId={formId}
      >
        {!flags[TURN_OFF_PEBBLES] && (
          <FormikEffect
            onChange={(current: { dirty: boolean }) => {
              setEditing(editingSectionName, current.dirty);
            }}
          />
        )}
        <Field label="Title" name="title" component={TextControl} placeholder="Pebble Name..." />
        <div className={formStyles.fieldRowSpacing}>
          <div className={editStyles.displayOnlyField}>
            <FieldGroup label="Created By" noUpperCase>
              <Typography variant="body2">{createdBy}</Typography>
            </FieldGroup>
          </div>
          <div className={editStyles.displayOnlyField}>
            {pebble.link && (
              <FieldGroup label="About" noUpperCase>
                <Link
                  className={formStyles.eventLink}
                  target="_blank"
                  href={pebble.link}
                  color="inherit"
                  underline="always"
                >
                  {pebble.link.includes('conversation') ? 'Conversation' : 'Visit'}
                </Link>
              </FieldGroup>
            )}
          </div>
        </div>
        <div className={formStyles.fieldRowSpacing}>
          <div className={formStyles.fieldInRow}>
            {mayEditPatient ? (
              <Field
                label="Patient"
                name="participant"
                component={PatientReference}
                placeholder="Select Patient..."
              />
            ) : (
              <FieldGroup label="Patient" noUpperCase>
                <PebbleParticipantView name={patientName} participant={pebble.participant} />
              </FieldGroup>
            )}
          </div>
          <div className={formStyles.dropdown}>
            <Field label="Topic *" name="topic" component={SelectControl} options={topics} />
          </div>
        </div>
        <div className={formStyles.fieldRowSpacing}>
          <div className={editStyles.datePicker}>
            <Field
              name="reminder"
              component={({ form: { setFieldValue }, field: { value } }) => {
                return (
                  <DateTimePicker
                    onChange={val => setFieldValue('reminder', val)}
                    value={value}
                    label="Reminder"
                  />
                );
              }}
            />
          </div>

          <div className={formStyles.dropdown}>
            <Field
              name="status"
              component={SelectControl}
              label="Status *"
              options={PEBBLE_STATUS_OPTIONS}
            />
          </div>
        </div>
        <div className={formStyles.fieldRowSpacing}>
          <div className={formStyles.fieldInRow}>
            <Field
              label="Monitored By"
              name="monitors"
              isMulti
              component={ProviderReference}
              placeholder="Select Provider(s)..."
            />
          </div>
          <div className={formStyles.fieldInRow}>
            <Field
              label="Assigned to *"
              name="assignee"
              component={ProviderReference}
              placeholder="Select Provider..."
            />
          </div>
        </div>
        <div className={formStyles.fieldRowSpacing}>
          <div className={formStyles.fieldInRow}>
            <Field
              name="priority"
              component={SelectControl}
              label="Priority"
              options={PEBBLE_PRIORITY_OPTIONS}
            />
          </div>
        </div>
        <FieldGroup label="Notes" classes={{ value: formStyles.leftBorder }} noUpperCase>
          {pebble.history && <PebbleHistory history={pebble.history} />}
          <Field
            name="note"
            component={TextControl}
            multiline
            label="Add a note"
            className={formStyles.note}
          />
        </FieldGroup>
      </EditPebbleForm>
    </Paper>
  );
};

const useEditStyles = makeStyles(() => ({
  datePicker: {
    marginTop: 16,
    marginBottom: 16,
    width: '45%',
  },
  displayOnlyField: {
    marginTop: -4,
    width: '45%',
  },
}));

const useFormStyles = makeStyles(() => ({
  formPadding: {
    padding: 40,
  },
  leftBorder: {
    borderLeft: '2px solid #ddd',
    paddingLeft: '10px',
  },
  dropdown: {
    width: '45%',
  },
  fieldRowSpacing: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 5,
    marginBottom: 5,
  },
  fieldInRow: {
    marginTop: 12,
    marginBottom: 16,
    width: '45%',
  },
  note: {
    marginTop: -5,
  },
  eventLink: {
    fontSize: 14,
  },
}));

export default PebbleDetailsEdit;
