import { SvgIcon, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import React from 'react';

import { useButtonStyles } from 'src/components/general/PatientMenu/highlights/useButtonStyles';
import useTooltipStyles from 'src/components/general/PatientMenu/useTooltipStyles';
import { ReactComponent as ZendeskIcon } from 'src/components/icons/zendesk.svg';

const ZendeskProfileLink: React.FC<{
  url: string | null;
}> = ({ url }) => {
  const tooltipStyles = useTooltipStyles();
  const styles = useButtonStyles();

  if (!url) return null;

  const buttonClassNames = [styles.button, styles.iconButton];

  return (
    <Tooltip
      title="Zendesk"
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a href={url} target="_blank" rel="noopener noreferrer" title="Patient's Zendesk profile">
        <IconButton className={classNames(buttonClassNames)} size="small">
          <SvgIcon component={ZendeskIcon} style={{ height: 16, width: 16 }} />
        </IconButton>
      </a>
    </Tooltip>
  );
};

export default ZendeskProfileLink;
