"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateadd = dateadd;
var _dateFns = require("date-fns");
var _dateCompatabilityShim = require("./dateCompatabilityShim");
function dateadd(date, interval, maybeUnit) {
  const parsedDated = (0, _dateCompatabilityShim.parseUnknownDate)(date);
  const parsedInterval = Number(interval);
  if (!(0, _dateFns.isValid)(parsedDated) || !Number.isInteger(parsedInterval)) {
    return date;
  }
  let unit = (0, _dateCompatabilityShim.normalizeDateUnit)(maybeUnit);
  if (typeof maybeUnit === 'object' && maybeUnit !== null) {
    unit = 'days';
  }
  const newDate = (0, _dateFns.add)(parsedDated, {
    [unit]: parsedInterval
  });
  return (0, _dateFns.format)(newDate, 'yyyy-MM-dd');
}