import { Paper } from '@material-ui/core';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import type { UnscheduledRequest } from 'src/dropInClinic/hooks/useScheduleLogs';

const UnscheduledRequests = ({
  unscheduledRequests,
}: {
  unscheduledRequests: UnscheduledRequest[];
}) => {
  return (
    <div>
      {unscheduledRequests.map((request: UnscheduledRequest) => {
        const toolTip = `Reason: ${request.reason}`;
        return (
          <Tooltip title={toolTip}>
            <Paper
              elevation={2}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <div style={{ padding: 4, justifyContent: 'center' }}>{request.requestId}</div>
            </Paper>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default UnscheduledRequests;
