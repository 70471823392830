import { differenceInDays, parseISO } from 'date-fns';

import QUOTES from 'src/shared/affirmations/dailyAffirmations';

export const startDate = parseISO('2020-07-09');

export function getAffirmation() {
  const today = new Date();
  const dateIndex = differenceInDays(startDate, today) % QUOTES.length;

  // reference JSON file with Daily Affirmations
  const quote = QUOTES[dateIndex]?.quote;
  const source = QUOTES[dateIndex]?.source?.toUpperCase();
  const doc = QUOTES[dateIndex]?.sourceDocument?.toUpperCase();
  return { quote, source, doc };
}

export function generateAttribution(affirmation) {
  if (affirmation.doc) {
    return `—${affirmation.source}, ${affirmation.doc}`;
  } else if (affirmation.source) {
    return `—${affirmation.source}`;
  } else {
    return null;
  }
}
