import React from 'react';

import v120Schema from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.2.0';
import PatientOverviewLink from 'src/components/general/patientOverviewLink';

// Remove the old updatedTier property which will be replaced with updatedEnrollmentAndCarePlan
const {
  notes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatedTier,
  ...v120Properties
} = v120Schema.properties;

export default {
  ...v120Schema,
  properties: {
    ...v120Properties,
    updatedEnrollmentAndCarePlan: {
      type: 'boolean',
      title: 'Updated Enrollment Status and Care Plan',
      label: (
        <PatientOverviewLink section="general">
          Updated Enrollment Status and Care Plan
        </PatientOverviewLink>
      ),
    },
    notes,
  },
};
