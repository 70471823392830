import { makeStyles } from '@material-ui/core';
import pickBy from 'lodash/pickBy';
import React, { useContext, useState } from 'react';

import { SectionHeader } from 'src/nightingale/components//SectionHeader/SectionHeader';
import { ChartElement } from 'src/nightingale/components/ChartElement/ChartElement';
import { HighlightedText } from 'src/nightingale/components/ChartSearch/HighlightedText';
import { ConditionalContext } from 'src/nightingale/components/ConditionalContext/ConditionalContext';
import type { ChartOverviewSection as TChartOverviewSection } from 'src/nightingale/types/types';

const useStyles = makeStyles({
  container: {
    marginBottom: '16px',
    width: '100%',
  },
});

export const ChartOverviewSection: React.FC<TChartOverviewSection> = ({
  elements,
  hidden,
  label,
}) => {
  const [editing, setEditing] = useState<{ [key: string]: boolean }>({});
  const styles = useStyles();
  const { conditionalMap } = useContext(ConditionalContext);

  if (hidden && Object.keys(editing).length < 1) return null;

  return (
    <div className={styles.container}>
      <SectionHeader>
        <HighlightedText text={label ?? ''} />
      </SectionHeader>
      {elements.map(element => {
        if (element.hidden && !editing[element.name]) return null;
        if (element.id in conditionalMap && !conditionalMap[element.id]) {
          return null;
        }

        return (
          <ChartElement
            key={element.name}
            definition={element}
            onEdit={name => {
              setEditing(previousEditing => {
                if (previousEditing[name]) return previousEditing;
                return { ...previousEditing, [name]: true };
              });
            }}
            onView={name => {
              setEditing(previousEditing => {
                if (!previousEditing[name]) return previousEditing;
                return pickBy(previousEditing, (_value, key) => key !== name);
              });
            }}
          />
        );
      })}
    </div>
  );
};
