import React from 'react';

import PatientOverviewLink from 'src/components/general/patientOverviewLink';

export default {
  type: 'object',
  title: 'Prescriber: Update Patient History',
  properties: {
    reviewedUpdatedMedicalStatus: {
      type: 'boolean',
      renderer: {
        title: 'Reviewed/updated medical history',
      },
      label: (
        <span>
          Reviewed/updated <PatientOverviewLink section="medical">medical</PatientOverviewLink>{' '}
          history (allergies, medications and medical problems)
        </span>
      ),
    },
    reviewedUpdatedSubstanceUse: {
      type: 'boolean',
      renderer: {
        title: 'Reviewed/updated substance use',
      },
      label: (
        <span>
          Reviewed/updated{' '}
          <PatientOverviewLink section="substanceUse">substance use</PatientOverviewLink>
        </span>
      ),
    },
    // TODO uncomment once there is a Patient Overview Social Status section to link to
    // reviewedUpdatedSocialStatus: {
    //   type: 'boolean',
    //   title: 'Reviewed/updated social status',
    // },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
