import { SvgIconProps } from '@material-ui/core';
import IconIceCream from '@material-ui/icons/AcUnit';
import IconDotsCircleHorizontal from '@material-ui/icons/Adjust';
import IconCookie from '@material-ui/icons/BlurCircular';
import IconCloud from '@material-ui/icons/CloudQueue';
import IconCoffee from '@material-ui/icons/LocalCafe';
import React, { forwardRef } from 'react';

import { useTeamIconStyles } from 'src/chat/useChatTeamIconStyles';

type IconProps = SvgIconProps;

type ChatTeamIconProps = {
  backgroundColor?: string;
  iconName?: string;
  isInteractive?: boolean;
  onClick?: () => void;
  size?: number;
};

const chatTeamIconMap = {
  cloud: (props: IconProps) => <IconCloud {...props} />,
  coffee: (props: IconProps) => <IconCoffee {...props} />,
  cookie: (props: IconProps) => <IconCookie {...props} />,
  icecream: (props: IconProps) => <IconIceCream {...props} />,
};

const defaultTeamIcon = (childProps: IconProps) => <IconDotsCircleHorizontal {...childProps} />;

export const ChatTeamIcon = forwardRef<HTMLDivElement, ChatTeamIconProps>(
  ({ backgroundColor, iconName, isInteractive, size = 40, ...props }, ref) => {
    const styles = useTeamIconStyles({ backgroundColor, isInteractive, size: size + 5 });

    let iconFn = defaultTeamIcon;

    if (iconName) {
      iconFn = chatTeamIconMap[iconName] ?? iconFn;
    }

    return (
      <div style={styles} ref={ref}>
        {iconFn({ fontSize: size >= 40 ? 'large' : 'small', ...props })}
      </div>
    );
  },
);
