import { isValid, parseISO } from 'date-fns';
import compact from 'lodash/compact';

import useTypedSWR from 'src/components/general/useTypedSWR';
import {
  ActivityDataEvent,
  ActivityType,
  PatientActivityEvent,
  ProviderAttendee,
} from 'src/components/pages/patientActivity/types';
import { ACTIVITY_EVENTS_QUERY } from 'src/components/pages/patientActivity/useSWRActivityEvents.gql';
import { TYPES, scheduleSummary, isAllDay, getAllSubTypes } from 'src/shared/util/events';
import { generateEventNotes } from 'src/stores/events/util';

function useSWRActivityEvents(patientId: string) {
  const { data: eventInstancesData, error } = useTypedSWR([ACTIVITY_EVENTS_QUERY, { patientId }], {
    revalidateOnFocus: false,
  });

  const data = eventInstancesData
    ? (eventInstancesData?.patientActivityEvents || []).map(transformEvent)
    : undefined;

  return { data, error };
}

function transformEvent(event: PatientActivityEvent): ActivityDataEvent {
  const { status, subType, type } = event;
  let signedAt = event.signedAt ? parseISO(event.signedAt) : null;
  if (!isValid(signedAt)) {
    signedAt = null;
  }
  let updatedAt = event.updatedAt ? parseISO(event.updatedAt) : null;
  if (!isValid(updatedAt)) {
    updatedAt = null;
  }

  return {
    eventId: event.id,
    name: subType || type || 'unknown',
    type: ActivityType.Event,
    label: (subType && SUB_TYPES[subType]?.label) || (type && TYPES[type]?.label) || 'unknown',
    start: scheduleSummary({
      ...event,
      allDay: isAllDay(type),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      start: event.start!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      timezone: event.timezone!,
    }),
    attendees: getProviderAttendees(event),
    eventNotes: generateEventNotes({ ...event, updatedAt, signedAt }),
    status: status ?? 'unknown',
    event,
    eventStatusInfo: {
      ...event,
      allDay: isAllDay(type),
    },
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    sortDateEpoch: parseISO(event.start!).getTime(),
  };
}

const SUB_TYPES = getAllSubTypes();

function getProviderAttendees(event: PatientActivityEvent): ProviderAttendee[] {
  return compact(event.attendees).filter(isProviderAttendee);
}

function isProviderAttendee(
  obj: PatientActivityEvent['attendees'][number],
): obj is ProviderAttendee {
  return obj?.__typename === 'Provider';
}

export default useSWRActivityEvents;
