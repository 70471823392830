export default {
  type: 'object',
  title: 'Prescriber: Initial Visit Wrap Up',
  properties: {
    enrollmentOutcome: {
      type: 'string',
      enum: [
        'Enrolled',
        'Not a good candidate for treatment with Boulder',
        'Patient not interested',
      ],
    },
    discussedPeerCoachReachingOut: {
      type: 'boolean',
    },
    followUpVisitScheduled: {
      type: 'boolean',
    },
    prescriptionsSent: {
      type: 'boolean',
    },
    welcomeMessageSent: {
      type: 'boolean',
    },
    peerCoachingTeamNotifiedOfNewEnrollee: {
      type: 'boolean',
    },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: ['enrollmentOutcome'],
};
