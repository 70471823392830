import React from 'react';

import ClearableSelect from 'src/components/forms/controls/clearableSelect';
import ReferenceControl from 'src/components/forms/controls/reference';
import { states as statesArray } from 'src/components/forms/schemas/definitions';
import { getAllSubTypes } from 'src/shared/util/events';

const SUB_TYPES = getAllSubTypes();
const subTypeOptions = Object.entries(SUB_TYPES).map(([subTypeKey, obj]) => {
  return {
    label: obj.label,
    value: subTypeKey,
  };
});

const stateOptionsArray = statesArray.map(state => ({
  label: state,
  value: state,
}));

export const SubTypeReference = props => (
  <ReferenceControl {...props} loadOptions={async () => subTypeOptions} />
);

export const StateReference = props => (
  <ReferenceControl {...props} loadOptions={async () => stateOptionsArray} />
);

const booleanSelectOptions = [
  // Using strings for the values instead of booleans, since they get used to update the query params,
  // and that's what we get when loading values directly from the URL.
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];
export const BooleanSelect = props => <ClearableSelect {...props} options={booleanSelectOptions} />;
BooleanSelect.optionForValue = value => booleanSelectOptions.find(opt => opt.value === value);

export function getMultiSelectFilterField(label, key, component, labelFn) {
  return {
    key,
    props: {
      isClearable: true,
      isMulti: true,
      label,
      name: key,
      placeholder: 'Select...',
    },
    component,
    labelFn,
  };
}

export function getSingleSelectFilterField(label, key, component, labelFn) {
  return {
    key,
    props: {
      label,
      name: key,
    },
    component,
    labelFn,
  };
}
