import { format, parseISO } from 'date-fns';
import gql from 'graphql-tag';
import MaterialTable from 'material-table';
import React from 'react';
import useSWR from 'swr';

const PATIENT_SUBMISSION_QUERY = gql`
  query PatientSubmissions {
    claimsPatientSubmissions {
      patientId
      request
      response
      createdAt
    }
  }
`;

type Json = string | number | boolean | null | Json[] | { [key: string]: Json };

// We only care about a small subset of the details
// of the actual Kareo response at this point -- just
// enough to detect errors -- so that is all these
// types define.
type PartialErrorResponse = {
  IsError: boolean;
};

type PartialSecurityResponse = {
  SecurityResultSuccess: boolean;
};

type PartialUpdatePatientResult = {
  ErrorResponse: PartialErrorResponse;
  SecurityResponse: PartialSecurityResponse;
};

type PartialKareoResponse = {
  UpdatePatientResult: PartialUpdatePatientResult;
  ErrorResponse: PartialErrorResponse;
  SecurityResponse: PartialSecurityResponse;
};

type PatientSubmissionGraphQL = {
  createdAt: string;
  patientId: string;
  request?: Json;
  response?: PartialKareoResponse;
};

type PatientSubmission = {
  createdAt: Date;
  patientId: string;
  request?: Json;
  response?: PartialKareoResponse;
};

const formatResponse = (response: any): string => {
  return JSON.stringify(response, null, 2);
};

const formatRequest = (request: any): string => {
  return JSON.stringify(request, null, 2);
};

export default function PatientSubmisions() {
  const { data } = useSWR<{ claimsPatientSubmissions?: PatientSubmissionGraphQL[] }>(
    PATIENT_SUBMISSION_QUERY,
  );
  if (!data?.claimsPatientSubmissions) {
    return null;
  }
  const transformedSubmissions: PatientSubmission[] = data.claimsPatientSubmissions.map(
    submission => ({
      ...submission,
      createdAt: parseISO(submission.createdAt),
      type: submission.response?.UpdatePatientResult ? 'Update' : 'Create',
      isError:
        submission.response?.ErrorResponse?.IsError ||
        submission.response?.UpdatePatientResult?.ErrorResponse?.IsError ||
        (!submission.response?.SecurityResponse?.SecurityResultSuccess &&
          !submission.response?.UpdatePatientResult?.SecurityResponse?.SecurityResultSuccess)
          ? 'Yes'
          : 'No',
    }),
  );

  return (
    <MaterialTable
      options={{ filtering: true, pageSize: 20 }}
      data={transformedSubmissions}
      columns={[
        {
          title: 'Created At',
          field: 'createdAt',
          filtering: false,
          render: row => (row.createdAt ? format(row.createdAt, 'yyyy-MM-dd HH:mm') : ''),
        },
        { title: 'Type', field: 'type', filtering: false },
        { title: 'Patient ID', field: 'patientId', filtering: false },
        { title: 'Is Error?', field: 'isError', filtering: false },
      ]}
      detailPanel={row => (
        <div>
          {!row.request && <div>No request</div>}
          {row.request && <pre>{formatRequest(row.request)}</pre>}
          <hr />
          {!row.response && <div>No response</div>}
          {row.response && <pre>{formatResponse(row.response)}</pre>}
        </div>
      )}
    />
  );
}
