import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react';
import React, { useContext, useMemo, useState } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';
import PebbleDetailsForm from 'src/pebbles/components/PebbleDetailsForm';
import { preparePebbleSaveData } from 'src/pebbles/components/preparePebbleSaveData';
import useSWRPebble from 'src/pebbles/components/useSWRPebble';
import { UPDATE_PEBBLE } from 'src/pebbles/queries';
import type { Pebble as PebbleType } from 'src/pebbles/types';
import { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const Pebble: React.FC<{
  id: string;
  rootStore: RootStore;
  onSave?: () => void;
  canEditInteraction: boolean;
}> = ({ id, rootStore, onSave, canEditInteraction }) => {
  const { setEditing: rootStoreSetEditing } = rootStore;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { apolloClient } = useContext(ApolloClientContext);

  const { data: pebbleResult, mutatePebble } = useSWRPebble(id);

  const pebble = useMemo<PebbleType | undefined>(() => pebbleResult ?? undefined, [pebbleResult]);

  if (!pebble) {
    return null;
  }

  const editingSectionName = getEditingSectionName(id);

  const updatePebble = async newValues => {
    if (!apolloClient) {
      return;
    }

    const valuesToSave = preparePebbleSaveData(newValues, pebble);
    if (isEmpty(valuesToSave)) {
      return;
    }

    await apolloClient.mutate({
      mutation: UPDATE_PEBBLE,
      variables: { data: valuesToSave, id: pebble.id },
    });
  };

  const cancelEditing = () => {
    rootStoreSetEditing(editingSectionName, false);
  };

  return (
    <PebbleDetailsForm
      key={id}
      pebble={pebble}
      onEdit={() => {
        if (isEditing) {
          cancelEditing();
        }
        setIsEditing(!isEditing);
      }}
      isEditing={isEditing}
      isEditingPebbleDetails={!!rootStore.isEditingByName.get(editingSectionName)}
      setEditing={rootStoreSetEditing}
      editingSectionName={editingSectionName}
      onSave={newValues => {
        return updatePebble(newValues).then(() => {
          mutatePebble();
          setIsEditing(false);
          cancelEditing();
          onSave?.();
        });
      }}
      onCancel={() => {
        setIsEditing(false);
        cancelEditing();
      }}
      formId={`pebble-details-form-${id}`}
      canEditInteraction={canEditInteraction}
    />
  );
};

const getEditingSectionName = (pebbleId: string) => {
  return `Pebble Details ${pebbleId}`;
};

export default inject<typeof Pebble>('rootStore')(observer(Pebble));
