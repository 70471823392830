import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';

const CheckboxControl = ({ className, disabled, field, label, ...props }) => {
  return (
    <FormControlLabel
      checked={field.value}
      className={className}
      control={<Checkbox {...props} {...field} />}
      disabled={disabled}
      label={label}
    />
  );
};

export default CheckboxControl;
