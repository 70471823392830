import { Checkbox, CircularProgress, FormControlLabel, FormGroup } from '@material-ui/core';
import { DoneAll } from '@material-ui/icons';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';

import { PrimaryButton } from 'src/components/general/PrimaryButton';
import { SecondaryButton } from 'src/components/general/SecondaryButton';
import { useVideoEndDialogStyles } from 'src/components/general/videoEndDialog/VideoEndDialog.styles';
import { LikertScaleInput } from 'src/components/general/videoEndDialog/likertScaleInput';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { TextControl } from 'src/nightingale/components/ChartProperty/controls/Text/TextControl';
import { labelForEventType } from 'src/shared/util/events';

const submitDifficulty = async ({
  apolloClient,
  eventId,
  difficulty,
  difficultyExplanation,
  otherDifficultyExplanation,
}: {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  eventId: string;
  difficulty: number | null;
  difficultyExplanation: string[];
  otherDifficultyExplanation: string | null;
}) =>
  apolloClient.mutate({
    mutation: gql`
      mutation ($data: EventInput!, $id: ID!) {
        updateEvent(data: $data, id: $id) {
          id
        }
      }
    `,
    variables: {
      data: {
        difficulty,
        difficultyExplanation,
        otherDifficultyExplanation,
      },
      id: eventId,
    },
  });

const difficultyExplanationMap = {
  complexConditions: 'Complex condition(s)',
  newPrescriptions: 'New prescription(s)',
  emotionalComplexity: 'Emotional complexity',
  other: 'Other',
};

export const VisitDifficulty = ({ eventId, goBack, closeDialog, visitType }) => {
  const styles = useVideoEndDialogStyles();

  const [difficulty, setDifficulty] = useState<number | null>(null);
  const [difficultyExplanationBooleanMap, setDifficultyExplanationBooleanMap] = useState({
    complexConditions: false,
    newPrescriptions: false,
    emotionalComplexity: false,
    other: false,
  });
  const [otherDifficultyExplanation, setOtherDifficultyExplanation] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { apolloClient } = useContext(ApolloClientContext);

  const handleSubmit = async () => {
    if (!apolloClient) {
      return;
    }

    const difficultyExplanation = Object.keys(difficultyExplanationBooleanMap).filter(
      key => difficultyExplanationBooleanMap[key],
    );

    setIsLoading(true);
    await submitDifficulty({
      apolloClient,
      eventId,
      difficulty,
      difficultyExplanation,
      otherDifficultyExplanation: difficultyExplanationBooleanMap.other
        ? otherDifficultyExplanation
        : null,
    });
    setIsLoading(false);

    closeDialog();
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <p className={styles.prompt}>How difficult was this visit?</p>
          {visitType && (
            <div className={styles.promptSubText}>
              (compared to other{' '}
              <i className={styles.upperCase}>{`${labelForEventType(null, visitType)}s`}</i>)
            </div>
          )}
          <p className={styles.promptHelperText}>Use your gut instinct.</p>
          <p className={styles.promptHelperText}>
            This information will help us improve the assignment algorithm.
          </p>

          <LikertScaleInput
            selectedNumber={difficulty}
            selectNumber={newDifficulty => {
              setDifficulty(newDifficulty);
            }}
            minText="Not very difficult at all"
            maxText="Very difficult"
          />

          {difficulty && difficulty >= 4 && (
            <div>
              <div className={styles.subPrompt}>
                What made it difficult? (Select all) (Optional)
              </div>
              <FormGroup className={styles.checkboxGroup}>
                {Object.keys(difficultyExplanationBooleanMap).map(explanation => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={difficultyExplanationBooleanMap[explanation]}
                        onChange={event =>
                          setDifficultyExplanationBooleanMap({
                            ...difficultyExplanationBooleanMap,
                            [explanation]: event.target.checked,
                          })
                        }
                        className={styles.checkbox}
                      />
                    }
                    label={
                      <span className={styles.content}>
                        {difficultyExplanationMap[explanation]}
                      </span>
                    }
                  />
                ))}
                {difficultyExplanationBooleanMap.other && (
                  <div className={styles.otherExplanation}>
                    <TextControl
                      label="Please explain"
                      name="Other"
                      value={otherDifficultyExplanation ?? undefined}
                      onChangeValue={setOtherDifficultyExplanation}
                    />
                  </div>
                )}
              </FormGroup>
            </div>
          )}

          <div className={styles.footer}>
            <SecondaryButton className={styles.upperCase} onClick={() => goBack()}>
              Back
            </SecondaryButton>
            <PrimaryButton
              className={styles.upperCase}
              onClick={handleSubmit}
              startIcon={<DoneAll />}
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
      )}
    </>
  );
};
