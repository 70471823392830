import React from 'react';
import { AutoForm as UniformsAutoForm } from 'uniforms-material';

import {
  fromSelectWithDependentsFieldFormat,
  toSelectWithDependentsFieldFormat,
  taggedTextFromDraftJsFieldFormat,
  taggedTextToDraftJsFieldFormat,
} from 'src/util/schema';

export default class AutoForm extends React.Component {
  constructor(props) {
    super(props);
    this.transformModel = this.transformModel.bind(this);
  }

  transformModel(mode, model) {
    const { modelTransform, schema } = this.props;
    let result = model;

    if (mode === 'form') {
      result = toSelectWithDependentsFieldFormat(schema, result);
      result = taggedTextToDraftJsFieldFormat(schema, result);
    }

    if (modelTransform && typeof modelTransform === 'function') {
      result = modelTransform(mode, result);
    }

    if (mode === 'validate') {
      result = fromSelectWithDependentsFieldFormat(schema, result, true);
      result = taggedTextFromDraftJsFieldFormat(schema, result);
    }

    if (mode === 'submit') {
      result = fromSelectWithDependentsFieldFormat(schema, result);
      result = taggedTextFromDraftJsFieldFormat(schema, result);
    }

    return result;
  }

  render() {
    const { formRef, ...props } = this.props;
    return <UniformsAutoForm {...props} ref={formRef} modelTransform={this.transformModel} />;
  }
}
