import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import FileField from 'src/components/forms/controls/file';

const styles = () => ({
  imagePreview: { width: '100%', objectFit: 'contain' },
});

const ImageRenderComponent = withStyles(styles)(({ file, classes }) => (
  <>{file && <img src={file.url} alt="preview" className={classes.imagePreview} />}</>
));

export default props => (
  <FileField {...props} accept="image/*" renderComponent={ImageRenderComponent} />
);
