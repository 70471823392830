import { makeStyles } from '@material-ui/core/styles';

import Colors from 'src/nightingale/Colors';

export const useStyles = makeStyles(theme => ({
  scrollPaper: {
    maxHeight: 'calc(100% - 70px)', // Leave space at the bottom for the snapshot snackbar
  },
  title: {
    padding: theme.spacing(2),
    margin: 0,

    '& h2': {
      fontFamily: 'Tenor Sans',
      fontSize: 26,
      lineHeight: 1.3,
      color: Colors.Gray7,
      textTransform: 'capitalize',
    },
  },
  content: {
    paddingBottom: 0,
    paddingTop: 0,
    padding: theme.spacing(2),
    background: `
      linear-gradient(${Colors.White} 33%, rgba(255,255,255, 0)),
      linear-gradient(rgba(255,255,255, 0), ${Colors.White} 66%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.2), rgba(0,0,0,0)),
      radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.2), rgba(0,0,0,0)) 0 100%
    `,
    backgroundColor: Colors.White,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local, local, scroll, scroll',
    backgroundSize: '100% 45px, 100% 45px, 100% 15px, 100% 15px',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  entries: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  entry: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  entryValue: {
    flexGrow: 2,
    width: '90%',
    padding: 0,
    paddingBottom: theme.spacing(2),
    verticalAlign: 'top',
    fontFamily: 'Nunito Sans',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  complexProperty: {
    backgroundColor: '#EDF0F2',
    padding: '8px',
  },
  notes: {
    color: Colors.Gray6,
    fontStyle: 'italic',
  },
  entryMeta: {
    alignItems: 'center',
    fontFamily: 'Nunito Sans',
    minWidth: 200,
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  editorName: {
    color: Colors.Gray6,
  },
  entryActions: {
    minWidth: 95,
    flexGrow: 1,
    verticalAlign: 'top',
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  button: {
    alignItems: 'center',
    backgroundColor: Colors.Stillwater,
    border: 'none',
    borderRadius: 0,
    color: Colors.White,
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    padding: '8px 16px',
    textTransform: 'uppercase',

    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
  },
  restoreButton: {
    backgroundColor: Colors.Stillwater,
    border: 'none',
    borderRadius: 0,
    color: Colors.White,
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    padding: '8px 16px',
    textTransform: 'uppercase',

    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
  },
  switch: {
    display: 'flex',
    alignContent: 'flex-end',
    paddingBottom: 20,
    paddingRight: 13,
  },
  otherVisitLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  footer: {
    padding: theme.spacing(2),
  },
  closeButton: {
    backgroundColor: Colors.Gray7,
  },
  noHistory: {
    fontStyle: 'italic',
  },
}));
