import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classNames from 'classnames';
import React from 'react';

import { VerificationStatus, type MenuPatient } from 'src/generated/gql/graphql';
import { useButtonStyles } from 'src/patientHeader/components/badges/styles/useButtonStyles';
import { useTooltipStyles } from 'src/patientHeader/components/badges/styles/useTooltipStyles';

/** A mapping of verification statuses to their corresponding icons and tooltips */
const verificationIconDefinitions = {
  [VerificationStatus.Verified]: {
    icon: CheckOutlined,
    color: 'neutral',
    tooltip: 'ID Verified',
  },
  [VerificationStatus.NotVerified]: {
    // NotVerified is deprecated, we shouldn't see it, make it look like Pause if we do
    icon: CloseOutlinedIcon,
    color: 'error',
    tooltip: 'ID Pause',
  },
  [VerificationStatus.AtRisk]: {
    icon: ReportProblemOutlinedIcon,
    color: 'warning',
    tooltip: 'ID Review At Risk',
  },
  [VerificationStatus.UnderReview]: {
    icon: ReportProblemOutlinedIcon,
    color: 'warning',
    tooltip: 'ID Under Review',
  },
  [VerificationStatus.AssistanceRequested]: {
    icon: PanToolOutlinedIcon,
    color: 'neutral',
    tooltip: 'ID Assistance Requested',
  },
  [VerificationStatus.IdPause]: {
    icon: CloseOutlinedIcon,
    color: 'error',
    tooltip: 'ID Pause',
  },
};

export const IdVerificationStatus: React.FC<{
  patient: Pick<MenuPatient, 'id' | 'verificationStatus'>;
  activeTab: string;
}> = ({ patient, activeTab }) => {
  if (!patient.verificationStatus) {
    return null;
  }
  const verificationIcon = verificationIconDefinitions[patient.verificationStatus];
  if (!verificationIcon) {
    return null;
  }

  const tooltipStyles = useTooltipStyles();
  const buttonStyles = useButtonStyles();

  return (
    <Tooltip
      title={verificationIcon.tooltip}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a
        href={
          activeTab === 'overview'
            ? `#identification`
            : `/patients/${patient.id}/overview#identification`
        }
      >
        <IconButton
          className={classNames([
            buttonStyles.button,
            buttonStyles.iconButton,
            buttonStyles[verificationIcon.color],
          ])}
        >
          {React.createElement(verificationIcon.icon)}
        </IconButton>
      </a>
    </Tooltip>
  );
};
