import v110Schema from 'src/components/forms/schemas/eventResult/peerCoachTelehealthPrecheck/1.1.0';

const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  patientLocation,
  confirmedLocalPD,
  ...v110Properties
} = v110Schema.properties;

export default {
  ...v110Schema,
  properties: {
    ...v110Properties,
    currentLocation: {
      type: 'string',
      tooltip: '"Where are you joining this call from?"',
      uniforms: {
        multiline: true,
      },
    },
    confirmedLocalPD,
  },
  required: ['currentLocation'],
};
