import React from 'react';

import PatientOverviewLink from 'src/components/general/patientOverviewLink';

export default {
  type: 'object',
  title: 'Onboarding: Checklist',
  properties: {
    anyUrgentSocialNeedsIdentified: { type: 'boolean' },
    discussedInsuranceEstimates: {
      type: 'boolean',
      title: 'Discussed Insurance Estimates',
      label: (
        <span>
          Discussed{' '}
          <PatientOverviewLink section="reference">Insurance Estimates</PatientOverviewLink>
        </span>
      ),
    },
    nextClinicianVisitScheduled: { type: 'boolean', title: 'Next Clinician visit scheduled' },
    firstPrescriptionSent: { type: 'boolean' },
    welcomeEmailSent: { type: 'boolean' },
    welcomeMessageSent: { type: 'boolean' },
    peerCoachingTeamNotifiedOfNewEnrollee: { type: 'boolean' },
    peerCoachHasBeenAssignedInCareTeam: { type: 'boolean' },
    peerCoachHasReachedOutToNewEnrollee: { type: 'boolean' },
    firstAppLoginConfirmed: { type: 'boolean' },
    consentFormsSigned: { type: 'boolean' },
    clinicianHasMessagedToCheckInOnInduction: { type: 'boolean' },
    clinicianHasReceivedAResponseToInductionCheckIn: {
      type: 'boolean',
      title: 'Clinician has received a response to induction check-in',
    },
    firstPrescriptionPickedUp: { type: 'boolean' },
    inductionStarted: { type: 'boolean' },
    requestForBoulderBoxSent: {
      type: 'boolean',
      title: 'Request for Boulder Box sent (after follow up visit)',
    },
    notes: { type: 'string', uniforms: { multiline: true } },
  },
  required: [],
};
