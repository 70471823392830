/**
 * Allow simple side effects in Formik forms, like when value A changes, update value B.
 * This is based on https://github.com/jaredpalmer/formik-effect, but that library is currently
 * broken so applying the same approach ourselves, per
 * https://github.com/jaredpalmer/formik/issues/766
 */
import { connect } from 'formik';
import * as React from 'react';

class FormikEffect extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.formik !== this.props.formik) {
      this.props.onChange(this.props.formik, prevProps.formik);
    }
  }

  render() {
    return null;
  }
}

export default connect(FormikEffect);
