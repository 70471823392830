import isNil from 'lodash/isNil';
import React, { createContext, useCallback, useState } from 'react';

export const STORAGE_ITEM_NAME = 'sideVisits';

export type SideVisitMap = Record<string, string | null>;

export const SideVisitContext = createContext<{
  sideVisits: SideVisitMap;
  setSideVisit: (patientId: string, eventId: string) => void;
  clearSideVisit: (patientId: string) => void;
}>({
  sideVisits: {},
  setSideVisit: () => {},
  clearSideVisit: () => {},
});

export const SideVisitContextProvider: React.FC = ({ children }) => {
  const [sideVisits, setSideVisits] = useState<SideVisitMap>(getSideVisitsFromSessionStorage());

  /**
   * Sets the side visit for the given patient to the given event ID. Persists via sessionStorage (per tab).
   *
   * @param {string} patientId The ID of the patient
   * @param {string} eventId The ID of the visit to show on the right side on Patient pages
   */
  const setSideVisit = useCallback(
    (patientId: string, eventId: string): void => {
      if (!patientId || sideVisits[patientId] === eventId) return;
      const updatedSideVisits = { ...sideVisits, [patientId]: eventId };
      sessionStorage.setItem(STORAGE_ITEM_NAME, JSON.stringify(updatedSideVisits));
      setSideVisits(updatedSideVisits);
    },
    [sideVisits],
  );

  /**
   * Clears the side visit for the given patient from sessionStorage and from state.
   *
   * @param {string} patientId The ID of the patient
   */
  const clearSideVisit = useCallback(
    (patientId: string): void => {
      if (!patientId || isNil(sideVisits[patientId])) return;
      const updatedSideVisits = { ...sideVisits, [patientId]: null };
      sessionStorage.setItem(STORAGE_ITEM_NAME, JSON.stringify(updatedSideVisits));
      setSideVisits(updatedSideVisits);
    },
    [sideVisits],
  );

  return (
    <SideVisitContext.Provider value={{ sideVisits, setSideVisit, clearSideVisit }}>
      {children}
    </SideVisitContext.Provider>
  );
};

/**
 * Gets the side visit map from sessionStorage, if it exists.
 */
const getSideVisitsFromSessionStorage = (): SideVisitMap => {
  const sideVisitStorageItem = sessionStorage.getItem(STORAGE_ITEM_NAME);
  if (!sideVisitStorageItem) return {};

  const sideVisit: SideVisitMap = JSON.parse(sideVisitStorageItem);
  return sideVisit || {};
};
