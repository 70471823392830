import isNil from 'lodash/isNil';
import set from 'lodash/set';
import startCase from 'lodash/startCase';
import { RouterState } from 'mobx-state-router';

import ProviderDataService from 'src/data/providers/ProviderDataService';
import { getAllSubTypes } from 'src/shared/util/events';
import { BooleanSelect } from 'src/util/filters';
import {
  compactArray,
  parseNumericQueryParam,
  unrecognizedQueryParam,
  parseDateOnlyQueryParam,
  parseArrayQueryParam,
} from 'src/util/params';

const SUB_TYPES = getAllSubTypes();

type ParamSelectOption = { value: string; label: string };

export const getValuesFromQueryParams = async (queryParams, rootStore, apolloClient) => {
  /* eslint-disable camelcase */ // To work with query param names that map to filter values
  const values: Partial<{
    changeControlNumber: string;
    endDate: string;
    eventSubType: ParamSelectOption[];
    hasReleaseOfInformation: ParamSelectOption | null;
    meta_status: ParamSelectOption[];
    meta_type: ParamSelectOption[];
    patientId: Array<{
      firstName: string;
      id: string;
      lastName: string;
    }>;
    patientInformation_address_state: ParamSelectOption[];
    payor_key: Array<{ key: string; name: string }>;
    renderingProviderInformation_npi: unknown[];
    startDate: string;
  }> = {};

  const {
    endDate,
    eventSubType,
    patientId,
    patientInformation_address_state,
    payor_key,
    startDate,
    changeControlNumber,
    hasReleaseOfInformation,
    meta_status,
    meta_type,
    renderingProviderInformation_npi,
  } = queryParams;

  const eventSubTypes = compactArray(eventSubType);
  const patientIds = compactArray(patientId);
  const payor_keys = compactArray(payor_key);
  const states = compactArray(patientInformation_address_state);
  const statuses = compactArray(meta_status);
  const types = compactArray(meta_type);
  const npis = compactArray(renderingProviderInformation_npi);

  if (states.length) {
    values.patientInformation_address_state = states.map(state => {
      return {
        value: state,
        label: state,
      };
    });
  }

  if (eventSubTypes.length) {
    values.eventSubType = eventSubTypes.map(subType => {
      return {
        value: subType,
        label: SUB_TYPES[subType].label,
      };
    });
  }

  if (patientIds.length) {
    values.patientId = await Promise.all(patientIds.map(id => rootStore.getPatientById(id)));
  }

  if (payor_keys.length) {
    values.payor_key = await rootStore.searchPayors(undefined, payor_keys);
  }

  if (startDate) {
    values.startDate = startDate;
  }

  if (endDate) {
    values.endDate = endDate;
  }

  if (changeControlNumber) {
    values.changeControlNumber = changeControlNumber;
  }

  values.hasReleaseOfInformation = BooleanSelect.optionForValue(hasReleaseOfInformation) || null;

  if (statuses.length) {
    values.meta_status = statuses.map(s => {
      return {
        value: s,
        label: startCase(s),
      };
    });
  }

  if (types.length) {
    values.meta_type = types.map(t => {
      return {
        value: t,
        label: startCase(t.toLowerCase()),
      };
    });
  }

  if (npis.length) {
    const providerDataService = new ProviderDataService(apolloClient);

    values.renderingProviderInformation_npi = await providerDataService.getProvidersByNpi(npis);
  }

  return values;
  /* eslint-enable camelcase */
};

export const queryParamsToUpdateArg = (queryParams: RouterState['queryParams']) => {
  // Initialize with an empty `where` to ensure the list filter gets properly reset if needed
  const updateArg: { where: Record<string, unknown> } = { where: {} };

  const mapDateQueryParamToWhere = {
    startDate: 'localDate_gte',
    endDate: 'localDate_lte',
  };

  Object.entries(queryParams).forEach(([key, value]) => {
    if (isNil(value)) {
      return;
    }

    switch (key) {
      case 'eventSubType':
      case 'patientId':
      case 'patientInformation_address_state':
      case 'meta_status':
      case 'renderingProviderInformation_npi':
      case 'meta_type':
        set(updateArg, `where.${key}_any`, compactArray(value));
        break;
      case 'changeControlNumber':
        set(updateArg, `where.${key}_some`, value);
        break;
      case 'hasReleaseOfInformation':
        set(updateArg, `where.${key}_some`, value === 'true');
        break;
      case 'payor_key':
        set(
          updateArg,
          `where.${key}_any`,
          parseArrayQueryParam(value, item => parseNumericQueryParam(item)),
        );
        break;
      case 'startDate':
      case 'endDate':
        set(updateArg, `where.${mapDateQueryParamToWhere[key]}`, parseDateOnlyQueryParam(value));
        break;
      case 'page':
      case 'rowsPerPage': {
        set(updateArg, key, parseNumericQueryParam(value));
        break;
      }
      default:
        console.warn(unrecognizedQueryParam(key));
    }
  });

  return updateArg;
};
