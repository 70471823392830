import { gql } from 'src/generated/gql';

export const LOAD_PATIENT_HEADER = gql(`
    query LoadPatientHeader($id: ID!) {
      menuPatient(id: $id) {
        id
        consentsToVoicemails
        dob
        firstName
        homeAddress {
          street1
          street2
          city
          state
          zip
        }
        isVerified
        isOnPayorWaitlist
        lastName
        phone
        preferredName
        profilePhoto
        pronouns
        timezone
        verificationStatus
        zendeskUrl
      }
    }
  `);
