import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import React from 'react';

import ErrorScreen from 'src/components/general/ErrorScreen';

/**
 * @see {@link https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/}
 */
const ErrorBoundary: React.FC = ({ children }) => (
  <SentryErrorBoundary
    fallback={({ error, componentStack }) => (
      <ErrorScreen error={error} info={{ componentStack: componentStack ?? '' }} />
    )}
  >
    {children}
  </SentryErrorBoundary>
);

export default ErrorBoundary;
