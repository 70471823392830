import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import React from 'react';

// SdCardAlert is in MUI Icons 5.0, but an attempted upgrade to 5.0 hit weird JSX errors, so here
// are our own copies for now.
import SdCardAlertIcon from 'src/components/icons/SdCardAlert';
import SdCardAlertOutlinedIcon from 'src/components/icons/SdCardAlertOutlined';

export default function ClaimStatusIcon({ status }) {
  const icons = {
    unknown: <InsertDriveFileOutlinedIcon />,
    pending: <DescriptionOutlinedIcon />,
    held: <InsertDriveFileOutlinedIcon />,
    'needs-update': <SdCardAlertOutlinedIcon />,
    submitted: <InsertDriveFileIcon />,
    rejected: <SdCardAlertIcon />,
  };
  return icons[status];
}
