import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type ApolloClient from 'apollo-client';
import React from 'react';

/**
 * Context for sharing apolloClient
 */
export const ApolloClientContext = React.createContext<{
  apolloClient?: ApolloClient<NormalizedCacheObject>;
}>({});
