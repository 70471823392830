"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.count = count;
var _handlebars = _interopRequireDefault(require("handlebars"));
var _initial = _interopRequireDefault(require("lodash/initial"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function count(...args) {
  return (0, _initial.default)(args).filter(val => val !== 'false' && !_handlebars.default.Utils.isEmpty(Boolean(val))).length;
}