import cloneDeep from 'lodash/cloneDeep';

import v150Schema from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.5.0';

const v160Schema = cloneDeep(v150Schema);

v160Schema.properties.referrer.properties.referralSourceType.enum = [
  ...v150Schema.properties.referrer.properties.referralSourceType.enum.filter(
    v => v !== 'Radio' && v !== 'Billboard',
  ),
];

v160Schema.properties.referrer.properties.referralSourceType.enum.push(
  'The Providers Smartphone App',
);

export default v160Schema;
