import cloneDeep from 'lodash/cloneDeep';

import v160Schema from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.6.0';

const v170Schema = cloneDeep(v160Schema);

v170Schema.properties.referrer.properties.referralSourceType.enum = [
  ...v160Schema.properties.referrer.properties.referralSourceType.enum.filter(
    v =>
      v !== 'Provider' &&
      v !== 'Other Social Media' &&
      v !== 'News Coverage' &&
      v !== 'Corrections/Parole Officer' &&
      v !== 'Residential Program' &&
      v !== 'Evergreen Health' &&
      v !== 'The Providers Smartphone App' &&
      v !== 'In-Person Event',
  ),
];

v170Schema.properties.referrer.properties.referralSourceType.enum.push(
  'A Boulder employee',
  'Instagram',
  'Another Facility',
  'ED or Inpatient/hospital referral',
  'Legal system',
);

export default v170Schema;
