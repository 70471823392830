import { Paper, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import {
  IconArrowBigRightLines,
  IconCalendarShare,
  IconClipboardCheck,
  IconClock,
  IconDropletCheck,
  IconInfoSquareRounded,
  IconRotate2,
  IconUserCircle,
  IconUserPlus,
  IconUserStar,
  IconVaccineBottleOff,
  IconFriends,
  IconBolt,
} from '@tabler/icons-react';
import { format } from 'date-fns';
import React, { useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { ActionButtons } from 'src/dropInClinic/ActionButtons';
import { Duration } from 'src/dropInClinic/Duration';
import { EndorsementNotifications } from 'src/dropInClinic/EndorsementNotifications';
import { PatientDetails } from 'src/dropInClinic/PatientDetails';
import { ProviderName } from 'src/dropInClinic/ProviderName';
import { useStyles } from 'src/dropInClinic/RequestRow.styles';
import { RequestType } from 'src/dropInClinic/RequestType';
import {
  DropInClinicPatient,
  DropInClinicProvider,
  UnscheduledRequestReason,
} from 'src/dropInClinic/types';
import { VisitReason as VisitReasonType } from 'src/dropInClinic/visitReason';
import { VisitReason as LegacyVisitReason } from 'src/dropInClinic/visitReason.component';
import { VisitReason } from 'src/dropInClinic/visitReason/VisitReason';
import {
  DISPLAY_STRAIGHTFORWARD_REQUEST_ICON,
  DROP_IN_ATC_INTERFACE,
} from 'src/featureFlags/currentFlags';
import Colors from 'src/nightingale/Colors';
import { UserNames } from 'src/shared/stores/resource';
import { ProviderRole } from 'src/stores/users/userType';

const REQUEST_TYPE_ICON = {
  FOLLOW_UP: <IconCalendarShare />,
  INTAKE: <IconUserPlus />,
  OFT: <IconDropletCheck />,
  PEER_VISIT: <IconUserCircle />,
  REENGAGEMENT: <IconRotate2 />,
  CASE_MANAGER_VISIT: <IconFriends />,
};

const unscheduledReasonToText = {
  [UnscheduledRequestReason.INTERNAL_ERROR]: 'A scheduling error occurred',
  [UnscheduledRequestReason.UNPREPARED_INTAKE]: 'Intake not yet prepared',
  [UnscheduledRequestReason.NO_CARE_ADVOCATE]: 'No Care Advocate available to complete prep',
  [UnscheduledRequestReason.NO_CREDENTIALED_PROVIDER]: 'No credentialed Provider available',
  [UnscheduledRequestReason.NO_TIME]: 'Credentialed Providers at capacity',
  [UnscheduledRequestReason.SINGLE_PROVIDER_OVERBOOKED]: 'Credentialed Provider at capacity',
};

const PlainTooltip = withStyles(() => ({
  tooltip: {
    margin: 0,
    padding: 0,
    maxWidth: 220,
    backgroundColor: 'transparent',
    fontSize: '0.875rem',
  },
}))(Tooltip);

type RequestTypeColumnProps = {
  requestType: string;
};

const RequestTypeColumn = React.memo<RequestTypeColumnProps>(({ requestType }) => {
  const classes = useStyles({});

  return (
    <div className={classes.requestType}>
      {REQUEST_TYPE_ICON[requestType]}
      <RequestType requestType={requestType} />
    </div>
  );
});

export type RequestRowQueueEntry = {
  categories: string[];
  status: string;
  acceptable: boolean;
  insuranceIsAcceptable: boolean | undefined;
  requestId: string;
  requestType: string;
  stateIsAcceptable: boolean | undefined;
  patient: DropInClinicPatient;
  queuedAt: Date;
  preparedBy: UserNames | null;
  legacyReason: VisitReasonType | null;
  reason: { details?: Record<string, any> } | null;
};

type RequestRowProps = {
  entry: RequestRowQueueEntry;
  providerRole: ProviderRole | null;
  currentTime: Date;
  mutate: () => void;
  onRemoveRequest: (requestId: string) => void;
  onUpdateError: (error: any) => void;
  additionalNotes?: string;
  suggestedProvider?: DropInClinicProvider | null;
  suggestedTime?: string | null;
  unscheduledReason?: string | null;
};

export const RequestRow = ({
  additionalNotes,
  currentTime,
  entry,
  mutate,
  onRemoveRequest,
  onUpdateError,
  providerRole,
  suggestedProvider,
  suggestedTime,
  unscheduledReason,
}: RequestRowProps) => {
  const { categories, patient, queuedAt, requestId, requestType, status, reason } = entry;

  const flags = useContext(FeatureFlagContext);
  const atcInterface = !!flags[DROP_IN_ATC_INTERFACE];
  const displayStraightforwardRequestIcon = !!flags[DISPLAY_STRAIGHTFORWARD_REQUEST_ICON];

  const custom = useStyles({ atcInterface });
  const hasNote = !!additionalNotes;

  const classList = [custom.row];

  if (hasNote) classList.push('with-note');
  if (unscheduledReason) {
    classList.push(custom.unscheduledRow);
  }

  const isStraightforward = reason?.details?.straightforward?.isStraightforward;

  return (
    <div className={classList.join(' ')}>
      <div className={custom.removeButton}>
        <Tooltip title="Remove this patient from the queue">
          <IconButton onClick={() => onRemoveRequest(requestId)} size="small">
            <CloseIcon fontSize="small" data-testid="remove-request" />
          </IconButton>
        </Tooltip>
      </div>
      <div className={custom.indicator}>
        {hasNote && (
          <PlainTooltip title={<Paper style={{ padding: 8 }}>{additionalNotes}</Paper>}>
            <a href={`/patients/${entry.patient.id}/overview#fyi`}>
              <IconInfoSquareRounded color={Colors.Coral} />
            </a>
          </PlainTooltip>
        )}
        {displayStraightforwardRequestIcon && isStraightforward && (
          <PlainTooltip
            title={
              <Paper style={{ padding: 8 }}>
                We predict this visit will be less complex and potentially shorter than 20 minutes
              </Paper>
            }
          >
            <IconBolt />
          </PlainTooltip>
        )}
      </div>
      <div className={custom.name}>
        <PatientDetails patient={patient} categories={categories} />
        {unscheduledReason && (
          <span className={custom.unscheduledWarning}>
            <WarningIcon />
            <div>{unscheduledReasonToText[unscheduledReason]}</div>
          </span>
        )}
        {suggestedProvider && (
          <div className={custom.scheduleSuggestion}>
            <IconUserStar size={14} />
            <ProviderName provider={suggestedProvider} />
            <span>&nbsp;at about {suggestedTime}</span>
          </div>
        )}
      </div>
      <div className={custom.visitReason}>
        <LegacyVisitReason reason={entry.legacyReason} />
        <VisitReason visitReasonDetails={entry.reason?.details ?? {}} />
      </div>
      <RequestTypeColumn requestType={requestType} />
      <div className={custom.waitingTime}>
        <IconClock />
        <Duration date={queuedAt} currentTime={currentTime} />
      </div>
      <div className={custom.medicationDate}>
        {patient.medicationRunOutDate && (
          <>
            <IconVaccineBottleOff />
            {format(patient.medicationRunOutDate, 'E MMM dd, yyyy')}
          </>
        )}
      </div>
      <div className={custom.currentStatus}>
        {status === 'PREPARING' && (
          <>
            <IconArrowBigRightLines />
            Prep in progress
          </>
        )}
        {status === 'PREPARED' && (
          <>
            <IconClipboardCheck />
            Prep complete
          </>
        )}
        {entry.preparedBy && (
          <div>
            <ProviderName provider={entry.preparedBy} />
          </div>
        )}
      </div>
      <div className={custom.actions}>
        <ActionButtons
          entry={entry}
          mutate={mutate}
          providerRole={providerRole}
          setRequestUpdateError={onUpdateError}
        />
        <EndorsementNotifications entry={entry} providerRole={providerRole} />
      </div>
    </div>
  );
};
