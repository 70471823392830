import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'required',
  },
});

export const PATIENT_CONVERSATION_VALIDATOR = Yup.object().shape({
  conversationPatient: Yup.object().required().nullable(),
});

export const STAFF_CONVERSATION_VALIDATOR = Yup.object().shape({
  conversationProviders: Yup.array().min(1, 'must select one participant'),
});

export const PATIENT_CHAT_CONVERSATION_VALIDATOR = Yup.object().shape({
  conversationAttendees: Yup.array(),
});
