import { makeStyles } from '@material-ui/core';
import MuiIconButton from '@material-ui/core/IconButton';
import React, { useEffect, useRef } from 'react';

import Colors from 'src/nightingale/Colors';

/**
 * Styles
 */
const useStyles = makeStyles({
  button: {
    backgroundColor: ({ isError, isSelected }: StyleProps) => {
      if (isError) {
        return Colors.Coral;
      } else if (isSelected) {
        return Colors.BlueSpruce;
      } else {
        return Colors.Gray3;
      }
    },
    height: 40,
    width: 40,
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
  },
  disabled: {
    backgroundColor: `${Colors.Gray2} !important`,
  },
});

type StyleProps = { isError: boolean; isSelected: boolean };

/**
 * Props
 */
export interface IconButtonProps {
  autoFocus?: boolean;
  classes?: { [key: string]: string };
  'data-testid'?: string;
  isDisabled?: boolean;
  isError?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

/**
 * Simple wrapper and styling for Material UI's `MuiIconButton`
 */
export const IconButton: React.FC<IconButtonProps> = ({
  autoFocus,
  children,
  classes,
  'data-testid': dataTestId = 'IconButton',
  isDisabled = false,
  isError = false,
  isSelected = false,
  onClick,
  ...rest
}) => {
  const styles = useStyles({ isError, isSelected });
  const btnRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (autoFocus) btnRef?.current?.focus();
  }, []);
  return (
    <MuiIconButton
      data-testid={dataTestId}
      disabled={isDisabled}
      disableFocusRipple
      classes={{
        root: styles.button,
        disabled: styles.disabled,
        ...classes,
      }}
      onClick={onClick}
      ref={btnRef}
      {...rest}
    >
      {children}
    </MuiIconButton>
  );
};
