import type { SubType } from 'src/events/visitTypes';

const standardDuration = 30;
const nonstandardDurations = new Map<SubType, number>([
  ['peer_recovery_group', 60],
  ['prescriber_initial', 60],
  ['prescriber_drop_in_followup', 20],
  ['prescriber_drop_in_intake', 75],
]);

export function getDefaultDurationFromSubType(
  subType: SubType,
  shorterOftSlots: boolean | undefined,
): number {
  if (['prescriber_bridge_care', 'prescriber_followup'].includes(subType)) {
    return 20;
  }
  if (shorterOftSlots && subType === 'oft_visit') {
    return 20;
  }
  if (['registered_nurse', 'registered_nurse_bridge_care'].includes(subType)) {
    return 20;
  }

  return nonstandardDurations.get(subType) ?? standardDuration;
}
