import { parseISO } from 'date-fns';
import { types } from 'mobx-state-tree';

export const DateType = types.custom<string, Date>({
  name: 'Date',
  fromSnapshot(value) {
    return parseISO(value);
  },
  toSnapshot(value) {
    return value.toISOString();
  },
  isTargetType(value) {
    return value instanceof Date;
  },
  getValidationMessage(value) {
    return !Number.isNaN(Date.parse(value)) ? '' : `${value} doesn't look like a date`;
  },
});
