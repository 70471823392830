import gql from 'graphql-tag';

import { EVENT_INSTANCE_ALL } from 'src/stores/fragments/events';
import { USER_LIMITED_DISPLAY } from 'src/stores/fragments/users';

export const RESCHEDULE_EVENT = gql`
  mutation RescheduleEvent($id: ID!, $data: RescheduleEventInput!) {
    rescheduleEvent(id: $id, data: $data) {
      originalEvent {
        ...EventInstanceAll
      }
    }
  }
  ${EVENT_INSTANCE_ALL}
`;

export const UPDATE_EVENT_RECURRENCE = gql`
  mutation UpdateEventRecurrence($id: ID!, $data: EventInput!) {
    updateRecurrence(id: $id, data: $data) {
      originalEvent {
        ...EventInstanceAll
      }
    }
  }
  ${EVENT_INSTANCE_ALL}
`;

export const CANCEL_RECURRING_EVENT = gql`
  mutation CancelRecurringEvent($id: ID!) {
    cancelRecurringEvent(id: $id)
  }
`;

export const SETUP_EVENT_VIDEO_CONFERENCE = gql`
  mutation SetupEventVideoConference($id: ID!) {
    setupEventVideoConference(id: $id) {
      vc {
        sessionId
        token
      }
    }
  }
`;

export const IS_VISIT_CODE_USED = gql`
  mutation IsVisitCodeUsed($visitCodeId: ID!) {
    staff_isVisitCodeUsed(visitCodeId: $visitCodeId)
  }
`;

export const INVITE_EVENT_GUEST_BY_VISIT_CODE = gql`
  mutation InviteEventGuestByVisitCode($eventId: ID!) {
    staff_inviteEventGuestByVisitCode(eventId: $eventId) {
      id
      code
    }
  }
`;

export const INVITE_EVENT_GUEST_BY_EMAIL = gql`
  mutation InviteEventGuestByEmail($email: String!, $eventId: ID!) {
    staff_inviteEventGuestByEmail(email: $email, eventId: $eventId)
  }
`;

export const VERIFY_EVENT_VISIT_CODE = gql`
  mutation VerifyEventVisitCode($code: String!) {
    staff_verifyEventVisitCode(code: $code)
  }
`;

/**
 * Things that update when signing or un-signing
 */
const EVENT_SIGNING_UPDATES = gql`
  fragment EventSigningUpdates on Event {
    id
    signedByDisplay {
      ...UserLimitedDisplay
    }
    signedAt
    countersignedByDisplay {
      ...UserLimitedDisplay
    }
    countersignedAt
    status
  }
  ${USER_LIMITED_DISPLAY}
`;

/**
 * Things that update when countersigning or un-countersigning
 */
const EVENT_COUNTERSIGNING_UPDATES = gql`
  fragment EventCountersigningUpdates on Event {
    id
    countersignedByDisplay {
      ...UserLimitedDisplay
    }
    countersignedAt
  }
  ${USER_LIMITED_DISPLAY}
`;

export const VALIDATE_REQUIRED_VISIT_PROPERTIES = gql`
  query ValidateRequiredVisitProperties($id: ID!) {
    validateRequiredVisitProperties(id: $id)
  }
`;

export const SIGN_AND_COMPLETE = gql`
  mutation SignAndComplete($id: ID!, $appointmentNotes: String) {
    signAndCompleteEvent(id: $id, appointmentNotes: $appointmentNotes) {
      ...EventSigningUpdates
    }
  }
  ${EVENT_SIGNING_UPDATES}
`;

export const UNSIGN_AND_UNCOMPLETE = gql`
  mutation UnsignAndComplete($id: ID!) {
    unsignAndUncompleteEvent(id: $id) {
      ...EventSigningUpdates
    }
  }
  ${EVENT_SIGNING_UPDATES}
`;

export const COUNTERSIGN = gql`
  mutation Countersign($id: ID!) {
    countersignEvent(id: $id) {
      ...EventCountersigningUpdates
    }
  }
  ${EVENT_COUNTERSIGNING_UPDATES}
`;

export const UNCOUNTERSIGN = gql`
  mutation Uncountersign($id: ID!) {
    uncountersignEvent(id: $id) {
      ...EventCountersigningUpdates
    }
  }
  ${EVENT_COUNTERSIGNING_UPDATES}
`;
