import { ErrorBoundary } from '@sentry/react';
import React from 'react';

import ScheduleTable from 'src/components/pages/eventShow/ScheduleTable';
import { SectionHeader } from 'src/nightingale/components/SectionHeader/SectionHeader';

const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const APPLICABLE_EVENT_SUBTYPES: Set<string | null> = new Set([
  'prescriber_initial',
  'prescriber_reengagement_visit',
  'prescriber_paneling_followup',
  'prescriber_bridge_care',
  'prescriber_followup',
  'prescriber_drop_in_followup',
  'prescriber_drop_in_intake',
  'prescriber_drop_in_reengagement',
  'registered_nurse',
  'registered_nurse_bridge_care',
  'registered_nurse_drop_in_followup',
  'registered_nurse_drop_in_reengagement',
]);

type SchedulingSuggestionsSectionProps = {
  className: string;
  event: { id: string; subType: string | null };
  patient: { id?: string | null; timezone?: string | null };
};

export const SchedulingSuggestionsSection = ({
  className,
  event,
  patient,
}: SchedulingSuggestionsSectionProps) => {
  if (!patient.id || !APPLICABLE_EVENT_SUBTYPES.has(event.subType)) {
    return null;
  }

  return (
    <div className={className}>
      <SectionHeader>Scheduling Suggestions</SectionHeader>
      <ErrorBoundary
        fallback={<div>We were unable to load scheduling suggestions at this time</div>}
      >
        <ScheduleTable
          event={event}
          patientId={patient.id}
          timezone={patient.timezone ?? DEFAULT_TIMEZONE}
        />
      </ErrorBoundary>
    </div>
  );
};
