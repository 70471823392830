import { gql } from 'src/generated/gql';

export const CAN_PATIENT_BE_SCHEDULED = gql(`
  query CanPatientBeScheduledForClinic(
    $patientId: String!
    $requestTypes: [staff_DropInClinicRequestType!]!
  ) {
    staff_canPatientBeScheduledForClinic(patientId: $patientId, requestTypes: $requestTypes) {
      canBeScheduled
      canBeScheduledToday
      willUnscheduleAnotherPatient
      requestType
      availabilityWindowEnd
    }
  }
`);
