import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { IconCalendarTime } from '@tabler/icons-react';
import classNames from 'classnames';
import React from 'react';

import { PatientHighlightVisitCadence } from 'src/generated/gql/graphql';
import { useSWRPatientHighlights } from 'src/patientHeader/components/badges/queries/useSWRPatientHighlights';
import { useButtonStyles } from 'src/patientHeader/components/badges/styles/useButtonStyles';
import { useTooltipStyles } from 'src/patientHeader/components/badges/styles/useTooltipStyles';
import { theme as appTheme } from 'src/theme';

export const VisitCadence: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const tooltipStyles = useTooltipStyles();
  const buttonStyles = useButtonStyles();
  const styles = useStyles();
  const buttonClassNames = [buttonStyles.button, buttonStyles.neutral];

  const { data: highlightsData } = useSWRPatientHighlights(patientId);

  if (!highlightsData) return null; // loading

  const visitCadence = highlightsData?.patientHighlights?.visitCadence;
  const tooltipText = getTooltipText(visitCadence);
  const label = getLabelText(visitCadence);

  return (
    <Tooltip
      title={tooltipText}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
    >
      <a href={`/patients/${patientId}/overview#clinicalCarePlanSimplified`}>
        <Button
          classes={{ startIcon: styles.startIcon }}
          className={classNames(buttonClassNames)}
          fullWidth
          size="small"
          startIcon={<IconCalendarTime size={appTheme.typography.fontSize} />}
          variant="outlined"
        >
          {label}
        </Button>
      </a>
    </Tooltip>
  );
};

const shortenedLabels = {
  declined_care: 'decl.',
  ineligible_for_clinical_care: 'inelig.',
};

function getLabelText(visitCadence: PatientHighlightVisitCadence | undefined | null): string {
  if (!visitCadence) {
    return '+';
  } else if (visitCadence.value === 'other') {
    return 'other';
  } else if (visitCadence.value && shortenedLabels[visitCadence.value]) {
    return shortenedLabels[visitCadence.value];
  }

  return visitCadence.label;
}

function getTooltipText(visitCadence: PatientHighlightVisitCadence | undefined | null) {
  if (!visitCadence) {
    return <em>Click to add visit cadence</em>;
  } else if (visitCadence.value === 'other') {
    // Remove 'Other:' from beginning of label
    const formattedLabel = visitCadence.label.replace(/^Other: /, '');
    return `Visit cadence: ${formattedLabel}`;
  }

  return `Visit cadence: ${visitCadence.label}`;
}

const useStyles = makeStyles({
  startIcon: {
    marginRight: 3,
  },
});
