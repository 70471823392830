import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { Subscription } from 'apollo-client/util/Observable';
import keyBy from 'lodash/fp/keyBy';
import { useEffect, useRef, useState } from 'react';
import useSWR, { KeyedMutator } from 'swr';

import {
  GET_CHAT_TEAMS_FOR_CONVERSATIONS_QUERY,
  MONITOR_CHAT_TEAM_ASSIGNMENTS_QUERY,
} from 'src/chat/queries';

type ConversationAssignment = {
  conversationId: string;
  teamId: string | null;
};

type ConversationTeamAssignmentGql = {
  monitorChatTeamAssignments: ConversationAssignment;
};

type ChatTeamsForConversationsGql = {
  getChatTeamsForConversations: ConversationAssignment[];
};

type UseChatTeamAssignmentsReturn = [
  Record<string, ConversationAssignment>,
  KeyedMutator<ChatTeamsForConversationsGql>,
];

export const useChatTeamAssignments = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  conversationIdList: string[],
): UseChatTeamAssignmentsReturn => {
  const activeSubscription = useRef<Subscription>();
  const [assignmentMap, setAssigmentMap] = useState({});
  const [subscriptionUpdate, setSubscriptionUpdate] = useState<
    ConversationTeamAssignmentGql | null | undefined
  >(null);

  const { data, mutate } = useSWR<ChatTeamsForConversationsGql>([
    GET_CHAT_TEAMS_FOR_CONVERSATIONS_QUERY,
    { conversationIdList },
  ]);

  // These method names are a bit confusing -- we are first `subscribe`-ing
  // to the GQL updates, which returns an observable. Then we `subscribe`
  // to the observable, which notifies us when something has been received
  // by the GQL subscription
  if (!activeSubscription.current) {
    activeSubscription.current = apolloClient
      ?.subscribe<ConversationTeamAssignmentGql>({ query: MONITOR_CHAT_TEAM_ASSIGNMENTS_QUERY })
      .subscribe(({ data: updateData }) => setSubscriptionUpdate(updateData));
  }

  // When we receive a new list of conversation assignments we *replace*
  // the assignment map completely
  useEffect(() => {
    setAssigmentMap(keyBy('conversationId', data?.getChatTeamsForConversations ?? []));
  }, [data]);

  // When we receive an update from the GQL subscription we merge it
  // into the existing assignment map
  useEffect(() => {
    setAssigmentMap(lastAssignmentMap => {
      const updateData = subscriptionUpdate?.monitorChatTeamAssignments
        ? [subscriptionUpdate.monitorChatTeamAssignments]
        : [];
      const updateMap = keyBy('conversationId', updateData);

      return { ...lastAssignmentMap, ...updateMap };
    });
  }, [subscriptionUpdate]);

  return [assignmentMap, mutate];
};
