import cloneDeep from 'lodash/cloneDeep';

import v130Schema from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.3.0';

const v140Schema = cloneDeep(v130Schema);
// Current diagnosis is now a required field. There is currently no existing pattern
// for making arrays required. To accomplish this we set a default value, make minItems 1,
// and require 'Problem' (otherwise the user can still save the empty 'Current Diagnosis').
// The user could remove the default value, but will still be unable to save.
v140Schema.properties.medicalProblems.default = [{ current: true }];
v140Schema.properties.medicalProblems.minItems = 1;
v140Schema.properties.medicalProblems.items.required = ['problem'];

export default v140Schema;
