import React from 'react';
import { connectField, joinName, BaseField } from 'uniforms';

/* eslint-disable import/no-cycle */
import AutoField from 'src/components/forms/fields/autoField';
import NestField from 'src/components/forms/fields/nestField';

/* eslint-enable */

const ConditionalField = (
  { name, field: { properties, ...fieldProps }, findValue, ...props },
  { uniforms: { onChange } },
) => {
  /**
   * This contains a mapping of the properties that are subscribed to
   * changes in other properties, as defined in `changeOnDependent`.
   * This allows those properties to make changes if necessary, i.e.
   * to clear the data if another property they are dependent on has
   * changed unexpectedly.
   */
  const changeOnDependentMap = {};
  Object.keys(properties).forEach(key => {
    const field = properties[key];
    if (field.dependsOn && field.changeOnDependent) {
      if (!Object.keys(changeOnDependentMap).includes(field.dependsOn)) {
        changeOnDependentMap[field.dependsOn] = [];
      }
      changeOnDependentMap[field.dependsOn].push({
        dependentKey: key,
        changeOnDependent: field.changeOnDependent,
      });
    }
  });

  /**
   * The intention of this method is to update the form's model which triggers the rerender of all
   * of the child fields. This allows any sibling fields to make use of the dependsOn field since
   * they will be rerendered and thus able to check the dependent field's value.
   * @param {string} key the name of the field that is being proxied
   */
  const getChangeHandler = key => {
    return value => {
      onChange(joinName(name, key), value); // Call the form onChange to update the model

      // Allow other properties that depend on this one to update their model if necessary
      if (changeOnDependentMap[key] && !!changeOnDependentMap[key].length) {
        changeOnDependentMap[key].forEach(({ dependentKey, changeOnDependent }) =>
          onChange(
            joinName(name, dependentKey),
            changeOnDependent(findDependentValue(dependentKey), value),
          ),
        );
      }
    };
  };

  const findDependentValue = key => findValue(joinName(name, key));

  const isFieldDisabled = field =>
    !!field.disabled ||
    (field.dependsOn && field.disableOnDependent && !findValue(joinName(name, field.dependsOn)));

  const isFieldDisplayed = field =>
    !field.dependsOn ||
    !field.displayOnDependent ||
    field.displayOnDependent(findDependentValue(field.dependsOn));

  return (
    <NestField name={name} field={{ properties, ...fieldProps }} {...props}>
      {Object.entries(properties).map(
        ([key, field]) =>
          isFieldDisplayed(field) && (
            <AutoField
              key={key}
              name={key}
              onChange={getChangeHandler(key)}
              disabled={isFieldDisabled(field)}
              findDependentValue={findDependentValue}
            />
          ),
      )}
    </NestField>
  );
};

ConditionalField.contextTypes = BaseField.contextTypes;

export default connectField(ConditionalField, {
  includeInChain: false,
});
