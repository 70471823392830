import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import EditChartDocument from 'src/components/forms/resources/editChartDocument';
import type { ChartDocument } from 'src/components/pages/patientShow/documents/types';

const EditChartDocumentDialog: React.VFC<{
  item: ChartDocument;
  onCancel: () => void;
  onSave: (values: Partial<ChartDocument>) => void;
}> = ({ onCancel, onSave, item }) => {
  if (!item) {
    return null;
  }

  const classes = useStyles();

  return (
    <Dialog open maxWidth="md" fullWidth onClose={onCancel}>
      <DialogTitle>{item.id ? 'Edit Document' : 'Add Document'}</DialogTitle>
      <DialogContent>
        <DialogContentText>* indicates required fields</DialogContentText>
        <EditChartDocument
          item={item}
          onCancel={onCancel}
          onSave={onSave}
          classes={{
            buttons: classes.buttons,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});

export default EditChartDocumentDialog;
