import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {
  differenceInMinutes,
  endOfDay,
  getHours,
  getMinutes,
  isEqual,
  setHours,
  setMinutes,
  startOfDay,
  startOfHour,
  subHours,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useEffect, useState, FunctionComponent, useRef, useContext } from 'react';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { createLocalizer, createFormats } from 'src/calendars';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { getEventDisplayTitle } from 'src/events/getEventDisplayTitle';
import FindATimeService from 'src/scheduling/services/FindATimeService';
import Attendee from 'src/scheduling/types/Attendee';
import { FindATimeEvent } from 'src/scheduling/types/findATime';
import { getExactFullName } from 'src/shared/stores/resource';
import {
  isAllDay,
  getStartTime,
  getEndTime,
  AVAILABILITY_SUB_TYPE_ITEMS,
} from 'src/shared/util/events';
import { timeSpansOverlap } from 'src/stores/events/util';
import { colors as calendarColors, getEventStyles } from 'src/util/calendar';
import { parseUnknownDate } from 'src/util/parseUnknownDate';
import { localZone } from 'src/util/timezones';

export type FindATimeProps = {
  attendees: Attendee[];
  start: Date;
  duration: number; // Minutes
  setFieldValue: (fieldName: string, newValue: unknown) => void;
  timezone?: string;
};

const FindATime: FunctionComponent<FindATimeProps> = ({ setFieldValue, timezone, ...props }) => {
  const tz = timezone ?? localZone;
  const localizer = createLocalizer(tz);

  const isMounted = useRef(true);
  const [initialScrollTime, setInitialScrollTime] = useState<Date>();
  const [start, setStart] = useState(props.start);
  const [duration, setDuration] = useState(props.duration);
  const [day, setDay] = useState(startOfDay(start));
  const [attendees, setAttendees] = useState(props.attendees);
  const [schedules, setSchedules] = useState<FindATimeCalendarItem[]>([]);

  const { apolloClient } = useContext(ApolloClientContext);
  if (!apolloClient) {
    return null;
  }

  const findATimeService = new FindATimeService(apolloClient);

  const loadData = async () => {
    const findATimeEvents = await findATimeService.getFindATimeEvents(
      attendees.map(resource => resource.id),
      day,
      endOfDay(day),
    );

    if (!isMounted.current) {
      return;
    }

    setSchedules(
      Object.entries(findATimeEvents).flatMap(([attendeeId, events]) =>
        events.map(event => ({
          ...event,
          allDay: isAllDay(event.type),
          attendeeId,
        })),
      ),
    );
  };

  useEffect(() => {
    // Keep track of whether the component is mounted to avoid memory leaks.
    // https://stackoverflow.com/questions/56442582/react-hooks-cant-perform-a-react-state-update-on-an-unmounted-component/56443045#56443045
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setInitialScrollTime(subHours(startOfHour(props.start), 1));
  }, []);

  useEffect(() => {
    setAttendees(props.attendees);
  }, [props.attendees]);

  useEffect(() => {
    if (props.start) {
      const startDay = startOfDay(props.start);
      if (!isEqual(day, startDay)) {
        setDay(startDay);
      }
      setStart(props.start);
    }
  }, [props.start]);

  useEffect(() => {
    if (props.duration) {
      setDuration(props.duration);
    }
  }, [props.duration]);

  const classes = useStyles();

  useEffect(fixTimeIndicatorsEffect(`.${classes.root} .rbc-current-time-indicator`), [duration]);

  useEffect(fixTimeIndicatorsEffect('rbc-current-time-indicator'), [attendees, duration]);

  useEffect(() => {
    if (attendees.length) {
      loadData();
    } else {
      // Not currently possible in the UI when there's a fixed attendee, but just incase
      setSchedules([]);
    }
  }, [attendees, day]);

  return (
    <>
      <FormControl fullWidth className={classes.root}>
        <Calendar<FindATimeCalendarItem, AttendeeResource>
          className={classes.calendar}
          date={day}
          defaultView="day"
          scrollToTime={initialScrollTime}
          views={['day']}
          localizer={localizer}
          events={schedules}
          getNow={() => start}
          onView={() => {}}
          onNavigate={onNavigate}
          resources={attendees.map(resourceMap)}
          resourceAccessor={event => event.attendeeId}
          resourceIdAccessor="attendeeId"
          resourceTitleAccessor="attendeeFullName"
          startAccessor={getStartTime}
          endAccessor={getEndTime}
          titleAccessor={event =>
            event.type === 'availability' ? '' : getEventDisplayTitle(event as any, tz)
          }
          onSelectSlot={selectSlot}
          formats={createFormats(tz)}
          selectable
          eventPropGetter={event => {
            return setEventStyling(event, classes, props);
          }}
        />
      </FormControl>
    </>
  );

  function onNavigate(date: Date) {
    setFieldValue(
      'start',
      setMinutes(setHours(date, getHours(props.start)), getMinutes(props.start)),
    );
  }

  function selectSlot(select: { start: string | Date; end: string | Date }) {
    if (select.start) {
      setFieldValue('start', select.start);
    }

    if (select.end) {
      setFieldValue(
        'duration',
        differenceInMinutes(parseUnknownDate(select.end), parseUnknownDate(select.start)),
      );
    }
  }

  function fixTimeIndicatorsEffect(timeIndicatorClassName: string) {
    return () => {
      if (duration) {
        // Hacking the styles on a component provided by react-big-calendar
        const timeIndicators = document.getElementsByClassName(timeIndicatorClassName);

        if (timeIndicators.length) {
          for (let i = 0; i < timeIndicators.length; i++) {
            const indicator = timeIndicators[i] as HTMLElement;
            indicator.style.height = `${(duration / 60) * 80}px`;
          }
        }
      }

      // Quick fix to restore base calendar line...
      return () => {
        const originalTimeIndicators = document.getElementsByClassName(
          'rbc-current-time-indicator',
        );
        if (originalTimeIndicators.length) {
          for (let i = 0; i < originalTimeIndicators.length; i++) {
            const indicator = originalTimeIndicators[i] as HTMLElement;
            indicator.style.height = '1px';
          }
        }
      };
    };
  }
};

/**
 * The representation used for each column of the schedule comparison
 */
type AttendeeResource = {
  attendeeId: string;
  attendeeFullName: string;
};

function setEventStyling(
  event: FindATimeCalendarItem,
  classes: { [key: string]: string },
  props: {
    attendees: Attendee[];
    start: Date;
    duration: number; // Minutes
  },
) {
  const final = {
    style: {
      ...getEventStyles('day')(event).style,
    },
  } as any;

  if (event.type === 'availability') {
    final.className = classes.availability;
    final.style.color = '#ddd';
    final.style.width = '100%';
    if (event.subType && event.subType in AVAILABILITY_SUB_TYPE_ITEMS) {
      final.style.borderColor = AVAILABILITY_SUB_TYPE_ITEMS[event.subType].backgroundColor;
      // 90% opacity value is E6 in hex
      final.style.backgroundColor =
        AVAILABILITY_SUB_TYPE_ITEMS[event.subType].backgroundColor.concat('E6');
    } else {
      final.style.borderColor = 'white';
      final.style.backgroundColor = 'rgba(255, 255, 255, 0.9)';
    }
  } else if (timeSpansOverlap({ start: props.start, duration: props.duration }, event)) {
    final.style.backgroundColor = 'rgb(251 232 230)';
    final.style.color = 'rgb(226,82,64)';
    final.style.borderColor = 'rgb(226, 82, 64)';
  }
  return final;
}

/**
 * Turns an attendee from the form into a "resource" for the calendar
 */
function resourceMap(attendee: Attendee) {
  const timeZone = attendee.timezone
    ? `(${formatInTimeZone(new Date(), attendee.timezone, 'z')})`
    : '';
  return {
    attendeeId: attendee.id,
    attendeeFullName: `${getExactFullName(attendee)} ${timeZone}`,
  };
}

/**
 * The objects we use to display events in the React Big Calendar component
 */
type FindATimeCalendarItem = {
  allDay: boolean;
  attendeeId: string;
} & FindATimeEvent;

const useStyles = makeStyles(theme => ({
  root: {
    // Should be provided by <Field> instead, but currently being overridden by the render implementation
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    // Ensure we have small labels on all pages
    '& .rbc-label': {
      fontSize: 12,
    },

    // Hide all day event space since there are none displayed for Find-a-Time
    '& .rbc-allday-cell': {
      display: 'none',
    },
    '& .rbc-time-view .rbc-header': {
      borderBottom: 'none',
    },
    '& .rbc-time-header-content > .rbc-row.rbc-row-resource': {
      borderBottom: 'none',
    },
    '& .rbc-time-view': {
      border: 'none',
    },
    '& .rbc-time-header.rbc-overflowing': {
      borderRight: 'none;',
    },
    '& .rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter':
      {
        borderRight: 'none;',
      },
    '& .rbc-toolbar .rbc-toolbar-label': {
      textAlign: 'left',
    },
    '& .rbc-toolbar .rbc-btn-group': {
      // Hide "Today" button since we're highjacking the getNow functionaliy for it's styling
      '& :first-child': {
        display: 'none',
      },
    },
    '& .rbc-day-slot .rbc-time-slot': {
      background: `repeating-linear-gradient( -45deg, ${calendarColors.OFF_WHITE}, ${calendarColors.OFF_WHITE} 12px, ${calendarColors.LIGHT_GREY} 12px, ${calendarColors.LIGHT_GREY} 28px );`,
      borderRight: '1px solid #f7f7f7',
      marginRight: 5,
    },
    '& .rbc-day-slot .rbc-timeslot-group': {
      borderTop: `1px solid ${calendarColors.OFF_WHITE}`,
    },
    '& .rbc-timeslot-group': {
      minHeight: 80,
    },
    '& .rbc-time-content > * + * > *': {
      borderLeft: 'none',
      borderBottom: 'none',
    },
    '& .rbc-time-content': {
      maxHeight: 300,
      overflowY: 'scroll',
      borderTop: 'none',
    },
    '& .rbc-current-time-indicator': {
      // Styles for the "selected range" indicator
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '& .rbc-event': {
      // Allows click events on the event to go "through" to the slot behind it,
      // which lets us position the selected range.
      pointerEvents: 'none',
    },
    '& .rbc-event-label': {
      // Only looking at one day at a time, don't need the time for now...
      display: 'none',
    },
  },
  calendar: {
    width: '100%',
  },
  availability: {
    // Otherwize calculated by the DayLayoutAlgorithm within react-big-calendar
    // TODO: We should be able to override that cleaner on a newer version
    width: '105% !important',
  },
}));

export default FindATime;
