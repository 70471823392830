import { gql } from 'src/generated/gql';

export const GET_BLOCK_LIST = gql(`
  query GetBlockList {
    getClinicBlockList {
      provider {
        firstName
        lastName
      }
      blockList {
        startTime
        duration
        isFree
        hasSingleProvider
        patient {
          firstName
          lastName
          preferredName
        }
        request {
          requestTime
          requestType
        }
      }
    }
  }
`);
