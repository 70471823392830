import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'required',
  },
});

export const EMAIL_INVITE_VALIDATOR = Yup.object().shape({
  email: Yup.string().email().nullable().required(),
});
