import React from 'react';

import Pebble from 'src/pebbles/components/Pebble';

const PebbleDetail: React.VFC<{
  classes: Record<string, string>;
  pebbleId: string;
  canEditInteraction: boolean;
}> = ({ classes, pebbleId, canEditInteraction }) => {
  return (
    <div className={classes.summary} data-testid="pebble-detail">
      <Pebble id={pebbleId} canEditInteraction={canEditInteraction} />
    </div>
  );
};

export default PebbleDetail;
