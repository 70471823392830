import { parseISO } from 'date-fns';
import pick from 'lodash/pick';
import set from 'lodash/set';

import type { RootStore } from 'src/stores/root';
import { compactArray, parseNumericQueryParam, unrecognizedQueryParam } from 'src/util/params';
import { PEBBLE_PRIORITY_MAP, PEBBLE_STATUS_MAP, PEBBLE_TOPIC_MAP } from 'src/util/pebbles';

const PEBBLE_DATE_FILTER_PARAMS = [
  'createdAt_gte',
  'createdAt_lt',
  'updatedAt_gte',
  'updatedAt_lt',
  'reminder_gt',
  'reminder_lte',
];

type PebbleParams<T> = {
  assignee?: string;
  createdBy?: string;
  monitors?: string;
  participant?: string;
  topic?: T[];
  status?: T[];
  priority?: T[];
};
type SelectOption = { value: string; label: string };
type PebbleValuesFromQueryParams = PebbleParams<SelectOption>;
type PebbleQueryParams = PebbleParams<string>;

export const getValuesFromQueryParams = async (
  queryParams: PebbleQueryParams,
  rootStore: RootStore,
) => {
  /* eslint-disable camelcase */ // To work with query param names that map to filter values
  const values: PebbleValuesFromQueryParams = {};

  const { assignee, createdBy, monitors, participant, status, topic, priority } = queryParams;

  const statuses = status ? compactArray(status) : [];
  const topics = topic ? compactArray(topic) : [];
  const priorities = priority ? compactArray(priority) : [];

  if (assignee) {
    values.assignee = await rootStore.getProviderById(assignee);
  }

  if (createdBy) {
    values.createdBy = await rootStore.getProviderById(createdBy);
  }

  if (monitors) {
    values.monitors = await rootStore.getProviderById(monitors);
  }

  if (participant) {
    values.participant = await rootStore.getPatientById(participant);
  }

  if (topics.length) {
    values.topic = topics.map(topicValue => ({
      value: topicValue,
      label: PEBBLE_TOPIC_MAP[topicValue],
    }));
  }

  if (statuses.length) {
    values.status = statuses.map(stat => {
      return {
        value: stat,
        label: PEBBLE_STATUS_MAP[stat],
      };
    });
  }

  if (priorities.length) {
    values.priority = priorities.map(priorityValue => {
      return {
        value: priorityValue,
        label: PEBBLE_PRIORITY_MAP[priorityValue],
      };
    });
  }

  return { ...values, ...pick(queryParams, PEBBLE_DATE_FILTER_PARAMS) };
  /* eslint-enable camelcase */
};

export const queryParamsToUpdateArg = (queryParams: { [key: string]: any }) => {
  // Initialize with an empty `where` to ensure the list filter gets properly reset if needed
  const updateArg = { where: {} };

  Object.entries(queryParams).forEach(([key, value]) => {
    switch (key) {
      case 'status':
        set(updateArg, `where.${key}_some`, compactArray(value));
        break;
      case 'topic':
        set(updateArg, `where.${key}_in`, compactArray(value));
        break;
      case 'priority':
        set(updateArg, `where.${key}_some`, compactArray(value));
        break;
      case 'participant':
      case 'createdBy':
      case 'assignee':
      case 'monitors':
        set(updateArg, `where.${key}_some`, value);
        break;
      case 'page':
      case 'rowsPerPage': {
        set(updateArg, key, parseNumericQueryParam(value));
        break;
      }
      case 'createdAt_gte':
      case 'createdAt_lt':
      case 'updatedAt_gte':
      case 'updatedAt_lt':
      case 'reminder_gt':
      case 'reminder_lte': {
        set(updateArg, ['where', key], parseISO(value));
        break;
      }
      default:
        console.warn(unrecognizedQueryParam(key));
    }
  });

  return updateArg;
};
