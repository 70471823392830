import { startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export function startOfDayInTimezone(date: Date, timezone: string): Date {
  // adjust the given time by an amount corresponding to the difference between the system's local
  // timezone and the one provided. This only makes a difference if the adjustment will move it
  // across the system's local timezone's midnight.
  const adjusted = utcToZonedTime(date, timezone);

  // get the start of the day in the system's local timezone
  const start = startOfDay(adjusted);

  // do the timezone conversion in reverse
  return zonedTimeToUtc(start, timezone);
}
