import gql from 'graphql-tag';

import type { SummarizationPatient } from 'src/nightingale/summarization';
import { ChartPropertyValue } from 'src/nightingale/types/types';

export const GET_CHART_DEFINITION_SCHEMA = gql`
  query getChartOverview($patientId: ID!) {
    chartPropertyValues: chartProperties(patientId: $patientId) {
      propertyName
      value
      notes
      isEmpty
    }
    overview: chartOverviewJson
    chartProperties: allChartPropertiesJson
    patient: patientSummary(patientId: $patientId) {
      id
      enrollmentDate: estimatedEnrollmentDate
      treatmentStartDate
    }
    missingRequiredFields: validateRequiredOverviewProperties(patientId: $patientId)
  }
`;

export type QueryResult = {
  chartPropertyValues: ChartPropertyValue[];
  overview: string;
  chartProperties: string;
  patient: SummarizationPatient;
  missingRequiredFields: string[];
};
