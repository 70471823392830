import { gql } from 'src/generated/gql';

export const ENCOUNTER_SUBMISSION_QUERY = gql(`
  query EncounterSubmissions($where: SubmissionWhereInput!) {
    claimsEncounterSubmissions(where: $where) {
      eventId
      state
      errors
      createdAt
    }
  }
`);
