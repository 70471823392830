import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import pick from 'lodash/pick';
import React, { useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ProviderReference from 'src/components/forms/controls/providerReference';
import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import EditableSectionHeader from 'src/components/forms/editableSectionHeader';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import FieldGroup from 'src/components/general/fieldGroup';
import { TURN_OFF_PEBBLES } from 'src/featureFlags/currentFlags';
import { ChartInteractionContextProvider } from 'src/nightingale/components/ChartInteraction/ChartInteractionContext';
import { ChartInteractionView } from 'src/nightingale/components/ChartInteraction/ChartInteractionView';
import { FlowMappingsContext } from 'src/nightingale/components/Flow/FlowMappingsContext';
import { InteractionKind } from 'src/nightingale/types/types';
import CopyLinkButton from 'src/pebbles/components/CopyLinkButton';
import EditPebbleForm from 'src/pebbles/components/EditPebbleForm';
import PebbleHistory from 'src/pebbles/components/PebbleHistory';
import PebbleParticipantView from 'src/pebbles/components/PebbleParticipantView';
import { Pebble } from 'src/pebbles/types';
import {
  transformPebbleForDisplay,
  PEBBLE_STATUS_OPTIONS,
  PEBBLE_TOPIC_MAP,
  PEBBLE_VIEW_VALIDATOR,
  PEBBLE_PRIORITY_MAP,
} from 'src/util/pebbles';

const materialTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0, // default
      sm: 600, // default
      md: 1000,
      lg: 1200, // default
      xl: 1536, // default
    },
  },
});

type PebbleFormData = Pick<Pebble, 'id' | 'status' | 'assignee'>;

type ViewPebbleDetailsProps = {
  formId: string;
  isEditingPebbleDetails: boolean;
  onCancel: () => void;
  onEdit: () => void;
  onSave: (values: PebbleFormData) => Promise<void>;
  pebble: Pebble;
  setEditing: (name: string, value: boolean) => void;
  editingSectionName: string;
  canEditInteraction: boolean;
};

const PebbleDetailsView: React.FC<ViewPebbleDetailsProps> = ({
  formId,
  isEditingPebbleDetails,
  onCancel,
  onEdit,
  onSave,
  pebble,
  setEditing,
  editingSectionName,
  canEditInteraction,
}) => {
  const patientId = pebble.participant?.id;
  const { createdAt, createdBy, patientName, monitors, topic, title, reminder, link, priority } =
    transformPebbleForDisplay(pebble);
  const isLocked = pebble.status === 'completed' || pebble.status === 'wont_do';

  const flags = useContext(FeatureFlagContext);
  const { flowMappingKeys } = useContext(FlowMappingsContext);
  // The pebble is only considered nightingale if it has a corresponding flow mapping.
  const isNightingale = !!(pebble.topic && flowMappingKeys.includes(pebble.topic));

  const viewStyles = useViewStyles();
  const formStyles = useFormStyles();

  return (
    <div className={viewStyles.viewContainer} data-testid="pebble-details-form">
      <Paper elevation={2} className={formStyles.formPadding}>
        <div className={viewStyles.copyLinkButton}>
          <CopyLinkButton pebbleId={pebble.id} />
        </div>
        <EditableSectionHeader
          title={title}
          onEdit={onEdit}
          hideEditIcon={flags[TURN_OFF_PEBBLES]}
        />
        <ThemeProvider theme={materialTheme}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <FieldGroup label="Created By" noUpperCase>
                <Typography variant="body2">{createdBy || '—'}</Typography>
                <Typography variant="body2" className={viewStyles.secondaryDescription}>
                  {createdAt ? format(createdAt, 'MMM d p') : ''}
                </Typography>
              </FieldGroup>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FieldGroup label="Topic" noUpperCase>
                <Typography variant="body2">{topic ? PEBBLE_TOPIC_MAP[topic] : '—'}</Typography>
              </FieldGroup>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FieldGroup label="Patient" noUpperCase>
                <PebbleParticipantView name={patientName} participant={pebble.participant} />
              </FieldGroup>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FieldGroup label="Monitored By" noUpperCase>
                <Typography variant="body2">
                  {monitors.length === 0
                    ? '—'
                    : monitors.map(mon => `${mon.firstName} ${mon.lastName}`).join(', ')}
                </Typography>
              </FieldGroup>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FieldGroup label="Reminder" noUpperCase>
                <Typography variant="body2">{reminder || '—'}</Typography>
              </FieldGroup>
            </Grid>
            {link && (
              <Grid item xs={12} md={6} lg={3}>
                <FieldGroup label="About" noUpperCase>
                  <Link
                    className={formStyles.eventLink}
                    target="_blank"
                    href={link}
                    color="inherit"
                    underline="always"
                  >
                    {link.includes('conversation') ? 'Conversation' : 'Visit'}
                  </Link>
                </FieldGroup>
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={3}>
              <FieldGroup label="Priority" noUpperCase>
                <Typography variant="body2">
                  {priority ? PEBBLE_PRIORITY_MAP[priority] : '—'}
                </Typography>
              </FieldGroup>
            </Grid>
          </Grid>
        </ThemeProvider>
        <EditPebbleForm
          item={pick(pebble, ['id', 'status', 'assignee'])}
          onSave={onSave}
          onCancel={onCancel}
          savingDisabled={!isEditingPebbleDetails}
          buttonSize="small"
          formId={formId}
          resetAfterSave
          resetAfterCancel
          validationSchema={PEBBLE_VIEW_VALIDATOR}
        >
          {!flags[TURN_OFF_PEBBLES] && (
            <FormikEffect
              onChange={(current: { dirty: boolean }) => {
                setEditing(editingSectionName, current.dirty);
              }}
            />
          )}
          <div className={formStyles.fieldRowSpacing}>
            <div className={formStyles.fieldInRow}>
              <Field
                label="Assigned to *"
                name="assignee"
                component={ProviderReference}
                placeholder="Select Provider..."
              />
            </div>
            <div className={formStyles.dropdown}>
              <Field
                name="status"
                component={SelectControl}
                label="Status *"
                options={PEBBLE_STATUS_OPTIONS}
              />
            </div>
          </div>
          <FieldGroup label="Notes" classes={{ value: formStyles.leftBorder }} noUpperCase>
            {pebble.history && <PebbleHistory history={pebble.history} />}
            <Field
              name="note"
              component={TextControl}
              multiline
              label="Add a note"
              className={formStyles.note}
            />
          </FieldGroup>
        </EditPebbleForm>
        {flags.showPebbleInteraction && patientId && isNightingale ? (
          <ChartInteractionContextProvider
            patientId={patientId}
            interactionReferenceId={pebble.id}
            interactionKey={pebble.topic}
            isNightingale
            kind={InteractionKind.Pebble}
          >
            <ChartInteractionView disableEdit={!canEditInteraction || isLocked} />
          </ChartInteractionContextProvider>
        ) : null}
      </Paper>
    </div>
  );
};

const useViewStyles = makeStyles(() => ({
  viewContainer: {
    marginBottom: 30,
  },
  secondaryDescription: {
    fontSize: 12,
    color: '#888888',
    fontStyle: 'italic',
  },
  copyLinkButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const useFormStyles = makeStyles(() => ({
  formPadding: {
    padding: 40,
  },
  leftBorder: {
    borderLeft: '2px solid #ddd',
    paddingLeft: '10px',
  },
  dropdown: {
    width: '45%',
  },
  fieldRowSpacing: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 5,
    marginBottom: 5,
  },
  fieldInRow: {
    marginTop: 12,
    marginBottom: 16,
    width: '45%',
  },
  note: {
    marginTop: -5,
  },
  eventLink: {
    fontSize: 14,
  },
}));

export default PebbleDetailsView;
