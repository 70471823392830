import gql from 'graphql-tag';

import type { SummarizationPatient } from 'src/nightingale/summarization';
import type { ChartPropertyValue } from 'src/nightingale/types/types';

export const GET_CHART_PROPERTY_VALUES_FOR_INTERACTION = gql`
  query GetChartPropertyValuesForInteraction(
    $patientId: ID!
    $patientSnapshotTime: DateTime
    $referenceId: ID!
  ) {
    patientLatestChartPropertyValues: chartProperties(
      patientId: $patientId
      propertyKind: PATIENT
    ) {
      createdAt
      interactionId
      propertyName
    }
    interactionValues: chartProperties(
      patientId: $patientId
      interactionReferenceId: $referenceId
    ) {
      interactionId
      isEmpty
      propertyName
      notes
      value
    }
    chartPropertyValues: interactionChartProperties(
      patientId: $patientId
      interactionReferenceId: $referenceId
      patientSnapshotTime: $patientSnapshotTime
    ) {
      action
      propertyName
      value
      notes
      isEmpty
      interactionId
    }
    patient: patientSummary(patientId: $patientId) {
      id
      enrollmentDate: estimatedEnrollmentDate
      treatmentStartDate
    }
  }
`;

export type QueryResult = {
  patientLatestChartPropertyValues: Array<
    Pick<ChartPropertyValue, 'interactionId' | 'propertyName'> &
      Required<Pick<ChartPropertyValue, 'createdAt'>>
  >;
  interactionValues: Array<
    Pick<ChartPropertyValue, 'propertyName' | 'interactionId' | 'isEmpty' | 'notes' | 'value'>
  >;
  chartPropertyValues: Array<
    Pick<
      ChartPropertyValue,
      'action' | 'propertyName' | 'value' | 'notes' | 'isEmpty' | 'interactionId'
    >
  >;
  patient: SummarizationPatient;
};
