import isEqual from 'lodash/isEqual';
import type { Dispatch, SetStateAction } from 'react';
import React, { createContext, useState, useEffect } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

import { evaluateConditions } from 'src/nightingale/conditionals';
import type { ConditionalMap } from 'src/nightingale/conditionals';
import { getUpdatedSummarizationContext } from 'src/nightingale/summarization';
import { Context } from 'src/nightingale/summarization/types';
import type { AnyChartProperty, ChartPropertyValue, Condition } from 'src/nightingale/types/types';

type ConditionalState = {
  /** A summarization context suitable for use in conditionals */
  conditionalContext: Context;

  /** A mapping from IDs to should-show indicators */
  conditionalMap: ConditionalMap;

  /** Does the given chart property satisfy all conditions to be shown on the chart? */
  isPropertyShown: (property: AnyChartProperty) => boolean;

  /**
   * A setter for the conditionalContext
   *
   * Careful: in theory this isn't necessarily slow, but in practice it can cause large slow re-renders.
   * Be mindful of when and where you call it.
   */
  setConditionalContext?: Dispatch<SetStateAction<Context>>;

  /**
   * A higher-performance alternative to setConditionalContext that updates the conditionalMap iff
   * the given changes would change one of the conditions' outcomes
   *
   * Does *not* update the summarization context.
   */
  updateConditionalMap?: (
    updates: ChartPropertyValue[],
    propertyDefinitions: AnyChartProperty[],
  ) => void;
};

/**
 * Stores our summarization context state in a React Context (name clash!) for
 * use in evaluating Conditional templates.
 */
export const ConditionalContext = createContext<ConditionalState>({
  conditionalContext: {},
  conditionalMap: {},
  isPropertyShown: () => false,
  setConditionalContext: () => {},
  updateConditionalMap: () => {},
});

/**
 * Provider state for Conditional Context Provider and updates internal state
 * on external changes based on deep comparison.
 */
export const ConditionalContextProvider: React.FC<{
  conditionalContext: Context;
  allConditions: Condition[];
}> = ({ children, conditionalContext: passedConditionalContext, allConditions }) => {
  const [conditionalContext, setConditionalContext] = useState<Context>(passedConditionalContext);
  const [conditionalMap, setConditionalMap] = useState<ConditionalMap>(
    evaluateConditions(allConditions, passedConditionalContext),
  );

  useEffect(() => {
    // For some unknown reason I am seeing an extra render or two with
    // useDeepCompareEffect that I am not seeing with isEqual. So favoring
    // isEqual here.
    if (isEqual(conditionalContext, passedConditionalContext)) return;
    setConditionalContext(passedConditionalContext);
  }, [passedConditionalContext]);

  useDeepCompareEffect(() => {
    const updatedConditionalMap = evaluateConditions(allConditions, conditionalContext);
    if (!isEqual(conditionalMap, updatedConditionalMap)) {
      setConditionalMap(updatedConditionalMap);
    }
  }, [conditionalContext, allConditions]);

  const updateConditionalMap = (updates: ChartPropertyValue[], definitions: AnyChartProperty[]) => {
    const updatedContext = getUpdatedSummarizationContext(conditionalContext, updates, definitions);
    const updatedConditionalMap = evaluateConditions(allConditions, updatedContext);
    setConditionalMap(updatedConditionalMap);
  };

  function isPropertyShown(property: AnyChartProperty) {
    return !(conditionalMap[property.id] === false);
  }

  return (
    <ConditionalContext.Provider
      value={{
        conditionalContext,
        setConditionalContext,
        updateConditionalMap,
        conditionalMap,
        isPropertyShown,
      }}
    >
      {children}
    </ConditionalContext.Provider>
  );
};
