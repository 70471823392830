import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError, ErrorResponse } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import schema from 'src/generated/schema.json';
import { GraphQLError, OperationDefinitionNode } from 'graphql';
import stringify from 'json-stringify-safe';

// TODO For some reason, if you import this directly from the app, you get a different instance so
// when the upload link does instanceof ReactNativeFile, it returns false and doesn't do the upload.
// As a hack workaround, re-export it here and then use this version instead of importing directly.
export { ReactNativeFile } from 'apollo-upload-client';
export { ApolloError } from 'apollo-client';

/**
 * Initialize an instance of the Apollo Client pointing to the API server. We can't use the simple Boost
 * version because we want to support subscriptions via websockets, so instead this is copied and pasted
 * from https://www.apollographql.com/docs/react/advanced/boost-migration.html.
 *
 * apiHost: the hostname of the boulder api, can also include a port
 * apiSecure: boolean indicating whether to use https or http
 */
export function createApolloClient(
  apiHost: string,
  apiSecure: boolean,
  extraHeaders: Record<string, () => string>,
  onErrorWS: (error: Error | undefined) => void = error => {},
  onUnauthorized: () => void = () => {},
) {
  const headerLink = createHeaderLink(extraHeaders);

  const httpLink = createUploadLink({
    uri: `http${apiSecure ? 's' : ''}://${apiHost}`,
    credentials: 'include',
    fetch: (uri: string, options: RequestOptions) => {
      if (options.useUpload) {
        return uploadFetch(uri, options);
      }
      return fetch(uri, options);
    },
  });

  const connectionCallback = (error, result) => {
    if (error) {
      onErrorWS(error);
    }
  };

  const wsLink = new WebSocketLink({
    uri: `ws${apiSecure ? 's' : ''}://${apiHost}`, // TODO for prod
    options: {
      reconnect: true,
      lazy: true,
      connectionCallback,
      connectionParams: () => extraHeaders,
    },
  });

  const splitLink = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(makeDefaultErrorHandler(onUnauthorized)),
      headerLink,
      splitLink,
    ]),
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: schema,
      }),
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  return client;
}

function graphQLErrorLogMessages(
  graphQLErrors: readonly GraphQLError[],
  operationName: string,
) {
  return graphQLErrors.map(
    ({ message, locations, path }) =>
      `[GraphQL error]: Operation: ${operationName}, Message: ${message}, Location: ${stringify(
        locations,
      )}, Path: ${path}`,
  );
}

function networkErrorMessage(networkError: ErrorResponse['networkError']) {
  if (!(networkError as any)?.response) {
    return `[Network error]: No response: ${networkError}`;
  }

  const { status, headers } = (networkError as any).response as Response;
  return `[Network error]: Status ${status}, content-type: ${headers?.get('Content-Type')}`;
}

function makeDefaultErrorHandler(onUnauthorized: () => void) {
  return function defaultOnError({ operation, graphQLErrors, networkError }: ErrorResponse) {
    let unauthorized = false;
    if (graphQLErrors) {
      unauthorized = graphQLErrors.some(err => err.extensions?.code === 'FORBIDDEN');
      const messages = graphQLErrorLogMessages(graphQLErrors, operation.operationName);
      messages.forEach(msg => console.log(msg));
    }
    if (networkError) {
      console.log(networkErrorMessage(networkError));
      if ('statusCode' in networkError && networkError.statusCode === 401) {
        unauthorized = true;
      }
    }
    if (unauthorized) {
      onUnauthorized();
    }
  };
}

/**
 * Create a link to add headers to the graphql request, with
 * the value given by a function that will be called on each
 * request.
 *
 * @param {headerName: headerValueFn} headerFns
 */
function createHeaderLink(headerFns: { [key: string]: () => string }) {
  return setContext((_, { headers }) => {
    const newHeaders = Object.entries(headerFns).map(([header, headerFn]) => {
      const val = headerFn?.();
      return val ? { [header]: val } : null;
    });

    return {
      headers: Object.assign({}, headers, ...newHeaders),
    };
  });
}

type RequestOptions = RequestInit & {
  onAbortPromise?: Promise<unknown>;
  useUpload?: boolean;
  onUploadProgress?: (this: XMLHttpRequest, ev: ProgressEvent<EventTarget>) => any;
};

function uploadFetch(url: string, options: RequestOptions): Promise<Response> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.onload = () => {
      const opts: { status: number; statusText: string; headers: Headers; url?: string } = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() ?? ''),
      };
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
      const body = xhr.response ?? xhr.responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach(key => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onUploadProgress;
    }

    if (options.onAbortPromise) {
      options.onAbortPromise.then(() => {
        xhr?.abort?.();
      });
    }

    xhr.send(options.body as any);
  });
}

/**
 * Fetch doesn't support upload progress or upload cancellation, so we're using
 * a solution suggested here:
 * @see {@link https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-468318261}
 */
function parseHeaders(rawHeaders: string) {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach(line => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
}
