import { makeStyles } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import classNames from 'classnames';
import React from 'react';

import { ActivityType } from 'src/components/pages/patientActivity/types';
import Colors from 'src/nightingale/Colors';

export const PatientActivityIcon: React.FC<{ type: ActivityType }> = ({ type }) => {
  const classnames = useStyles();
  switch (type) {
    case ActivityType.Event:
      return <PeopleAltIcon className={classNames(classnames.icon, classnames.iconEvent)} />;
    case ActivityType.Task:
      return <PhoneAndroidIcon className={classNames(classnames.icon, classnames.iconTask)} />;
    case ActivityType.Pebble:
      return (
        <AssignmentTurnedInIcon className={classNames(classnames.icon, classnames.iconPebble)} />
      );
    default:
      return null;
  }
};

const useStyles = makeStyles({
  icon: {
    backgroundColor: Colors.BlueSpruce,
    borderRadius: '100%',
    boxSizing: 'border-box',
    display: 'inline-block',
    fill: Colors.White,
    height: 24,
    marginRight: 7,
    padding: 5,
    verticalAlign: 'middle',
    width: 24,
  },
  iconEvent: {
    backgroundColor: '#70259f',
  },
  iconTask: {
    backgroundColor: '#a45014',
  },
  iconPebble: {
    backgroundColor: '#148898',
  },
});
