import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

import { colors } from 'src/util/colors';

/**
 * Button that shows an overlaid progress animation when loading=true.
 */
const Submit = ({ classes, inline, warn, children, disabled = false, loading, ...props }) => (
  <div className={classNames({ [classes.wrapper]: true, [classes.inline]: inline })}>
    <Button
      {...props}
      className={classNames({ [classes.warn]: warn })}
      disabled={disabled || loading}
    >
      {children}
    </Button>
    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
  </div>
);

const styles = theme => ({
  inline: {
    display: 'inline',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  warn: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
      backgroundColor: colors.warningHover,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.warning.main,
    },
  },
});

export default withStyles(styles)(Submit);
