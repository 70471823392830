import { noteField } from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.1.0';
import v111Schema from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.1.1';

const { outcome, ...v111Properties } = v111Schema.properties;

// Remove "Ineligible (geography)" and "Ineligible (unsupported payment type)" dropdown options
// to combine into "Ineligible (payor/geography)" option
const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ineligibleGeography,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ineligibleUnsupportedPaymentType,
  unableToConnect,
  seekingInfoForOthers,
  other,
  ...outcomeProperties
} = outcome.properties;

const v120Schema = {
  ...v111Schema,
  properties: {
    ...v111Properties,
    outcome: {
      type: 'object',
      format: 'selectable',
      properties: {
        ...outcomeProperties,
        // Add more options to the Outcome dropdown
        ineligiblePayorOrGeography: {
          title: 'Ineligible (payor/geography)',
          ...noteField,
        },
        duplicate: {
          ...noteField,
        },
        notInterestedWithoutPrescribing: {
          title: 'Not interested without prescribing',
          ...noteField,
        },
        currentPatient: {
          title: 'Current Patient',
          ...noteField,
        },
        // Reorder some existing options
        unableToConnect,
        seekingInfoForOthers,
        other,
      },
    },
  },
  required: ['callType'],
};

export default v120Schema;
