import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import MaterialTable from 'material-table';
import { observer } from 'mobx-react';
import type { RouterStore } from 'mobx-state-router';
import React, { FunctionComponent } from 'react';

import Filters from 'src/components/pages/automation/filters';
import { parseQueryParams } from 'src/components/pages/automation/params';
import { makeUpdateQueryParams } from 'src/components/pages/automation/updateQueryParams';
import {
  useSWRAutomationJobs,
  AutomationJob,
} from 'src/components/pages/automation/useSWRAutomationJobs';
import { useSWRAutomationRules } from 'src/components/pages/automation/useSWRAutomationRules';
import type { AutomationRule } from 'src/components/pages/automation/useSWRAutomationRules';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import { inject } from 'src/util/inject';

type AutomationProps = {
  rootStore: {
    routerStore: RouterStore;
  };
};

const Automation: FunctionComponent<AutomationProps> = ({
  rootStore: {
    routerStore,
    routerStore: { routerState },
  },
}) => {
  const classes = useStyles();
  const queryParams = parseQueryParams(routerState.queryParams);
  const { data: automationRulesResult } = useSWRAutomationRules();
  const { data: automationJobsResult } = useSWRAutomationJobs({
    where: queryParams.filter,
    limit: queryParams.rowsPerPage,
    offset: queryParams.rowsPerPage * queryParams.page,
  });
  const { automationRules } = automationRulesResult || {};
  const { automationJobs = [] } = automationJobsResult || {};

  const updateQueryParams = makeUpdateQueryParams(routerStore);

  return (
    <div className={classes.root}>
      <TitleBar title="Automation" />
      <div className={classes.filtersContainer}>
        <Filters
          filterValues={queryParams.filter}
          rules={automationRules as AutomationRule[]}
          handleFiltersChange={filter => updateQueryParams({ ...filter, page: 0 })}
        />
      </div>
      <MaterialTable<AutomationJob>
        columns={[
          {
            title: 'Rule',
            field: 'rule',
            render: rowData => <>{rowData.rule?.title}</>,
          },
          {
            title: 'Patient',
            field: 'patientId',
          },
          {
            title: 'Run At',
            field: 'date',
            render: rowData => <>{format(parseISO(rowData.date), 'PPP p')}</>,
          },
          {
            title: 'Status',
            field: 'status',
          },
        ]}
        data={automationJobs}
        page={queryParams.page}
        totalCount={Infinity}
        detailPanel={rowData => (
          <div className={classes.details}>
            <div>
              <h3>Trigger Context:</h3>
              <pre>{JSON.stringify(rowData.triggerContext, null, 2)}</pre>
            </div>
            {rowData.results && (
              <div>
                <h3>Results:</h3>
                <pre>{JSON.stringify(rowData.results, null, 2)}</pre>
              </div>
            )}
            {rowData.failureReason && (
              <div>
                <h3>Failure Reason:</h3>
                <pre>{JSON.stringify(rowData.failureReason, null, 2)}</pre>
              </div>
            )}
          </div>
        )}
        onChangePage={page => updateQueryParams({ page })}
        onChangeRowsPerPage={rowsPerPage => updateQueryParams({ rowsPerPage })}
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        onRowClick={(event, rowData, togglePanel) => togglePanel && togglePanel()}
        options={{
          header: true,
          toolbar: false,
          paging: true,
          pageSize: queryParams.rowsPerPage,
          rowStyle: () => ({
            background: 'rgba(237,237,237,1)',
          }),
          search: false,
          sorting: false,
          showFirstLastPageButtons: false,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to}',
          },
        }}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  filtersContainer: {
    paddingBottom: 25,
  },
  small: {
    fontSize: '80%',
    fontStyle: 'oblique',
  },
  details: {
    padding: theme.spacing(3),
    maxWidth: '90vw', // hack way to prevent horizontal scrolling
    overflowX: 'scroll',
  },
}));

export default inject<typeof Automation>('rootStore')(observer(Automation));
