import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import SignalConnectionTelemetryDataPanel, {
  TelemetrySignalSection,
} from 'src/components/pages/pageElements/videoConference/SignalConnectionTelemetryDataPanel';

type SignalData = {
  telemetry: TelemetrySignalSection[];
};

type SignalConnection = {
  connectionId: string;
  streamName: string | undefined; // from stream.name, should be patientId if patient, '' if visitor and {Provider Name} - {Provider Role} for providers
  signalData: SignalData | undefined;
  lastUpdated: Date | undefined;
};

interface SignalConnectionTelemetryDataPanelGroupProps {
  signalConnections: SignalConnection[];
}

const SignalConnectionTelemetryDataPanelGroup: FunctionComponent<
  SignalConnectionTelemetryDataPanelGroupProps
> = ({ signalConnections }) => {
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState<string | boolean>(false);

  const handleChange = useCallback(
    connectionId => (event, newExpanded) => {
      setExpandedPanel(newExpanded ? connectionId : false);
    },
    [],
  );

  const validSignalConnections = useMemo(
    () =>
      signalConnections
        // only show data for signalConnections with signalData to display
        .filter(
          signalConnection =>
            signalConnection !== undefined &&
            signalConnection.streamName !== '' &&
            signalConnection.signalData !== undefined,
        ),
    [signalConnections],
  );

  return (
    <div id="patientTelemetryPanelGroup" className={classes.panelGroup}>
      {validSignalConnections.map(signalConnection => (
        <SignalConnectionTelemetryDataPanel
          telemetryData={signalConnection?.signalData?.telemetry}
          connectionId={signalConnection.connectionId}
          lastUpdated={signalConnection.lastUpdated}
          handleChange={handleChange}
          expanded={expandedPanel}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles({
  panelGroup: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    zIndex: 2,
  },
});

export default SignalConnectionTelemetryDataPanelGroup;
