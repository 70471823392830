import cloneDeep from 'lodash/cloneDeep';

import v110Schema from 'src/components/forms/schemas/eventResult/inquiryFormDetails/1.1.0';

const v120Schema = cloneDeep(v110Schema);
delete v120Schema.properties;

export default {
  ...v120Schema,
  properties: {
    ...v110Schema.properties,
    isWaitlisted: {
      type: 'boolean',
    },
  },
};
