import React, { memo } from 'react';

import { UserNames } from 'src/shared/stores/resource';

type ProviderNameProps = {
  provider?: UserNames;
};

export const ProviderName = memo(({ provider }: ProviderNameProps) => {
  return (
    <span>{provider ? `${provider.firstName} ${provider.lastName}` : 'Unknown Provider'}</span>
  );
});
