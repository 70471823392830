"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateformat = dateformat;
var _dateFns = require("date-fns");
var _dateCompatabilityShim = require("./dateCompatabilityShim");
function dateformat(dateString, possibleFormat) {
  const date = dateString ? (0, _dateCompatabilityShim.parseUnknownDate)(dateString) : null;
  let formatPattern;
  switch (possibleFormat) {
    case 'MM/DD/yyyy':
    case 'MM/DD/YYYY':
      formatPattern = 'MM/dd/yyyy';
      break;
    case 'MM/DD/YY':
      formatPattern = 'MM/dd/yy';
      break;
    case 'MMM D, YYYY':
    case 'MMM DD, YYYY':
      formatPattern = 'PP';
      break;
    default:
      formatPattern = null;
      break;
  }
  if (typeof possibleFormat === 'object' && possibleFormat !== null) {
    formatPattern = 'PP';
  }
  return (0, _dateFns.isValid)(date) && Boolean(formatPattern) ? (0, _dateFns.format)(date, formatPattern) : String(dateString);
}