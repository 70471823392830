import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { addDays, format, isValid as isValidDate, parseISO, subDays } from 'date-fns';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import React from 'react';

import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import Field from 'src/components/forms/field';
import rruleToString, { ordinal, SET_POS } from 'src/shared/util/rruleToString';

const MONTH_DAYS = [];
for (let i = 1; i <= 31; i++) {
  MONTH_DAYS.push(i);
}

const TYPES = {
  SCHEDULED: 'scheduled',
};

const FREQUENCY = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];
const WEEKDAYS_NAMES = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const WeekButtonBar = props => (
  <ToggleButtonGroup
    value={props.field.value}
    onChange={(evt, values) => props.form.setFieldValue(props.field.name, values)}
    onBlur={() => props.form.setFieldTouched(props.field.name)}
    style={{ ...props.style, verticalAlign: 'bottom', display: 'inline-block' }}
  >
    {WEEKDAYS.map(day => (
      <ToggleButton value={day} key={day}>
        {WEEKDAYS_NAMES[day]}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);

const Interval = props => (
  <>
    <Field
      name="recurrence.interval"
      component={TextControl}
      type="number"
      className={props.classes.interval}
      inputProps={{ min: 1 }}
    />
    {props.unit + (props.interval > 1 ? 's' : '')}
  </>
);

const ScheduledDetails = props => {
  switch (props.freq) {
    case FREQUENCY.DAILY:
      return (
        <>
          Every
          <Interval unit="day" classes={props.classes} />
        </>
      );
    case FREQUENCY.WEEKLY:
      return (
        <>
          Every
          <Interval unit="week" classes={props.classes} />
          &nbsp;on
          <Field name="recurrence.byDay" component={WeekButtonBar} style={{ marginLeft: 15 }} />
        </>
      );
    case FREQUENCY.MONTHLY:
      return (
        <>
          Every
          <Interval unit="month" classes={props.classes} />
          &nbsp;on the
          <Field>
            {({ form }) => (
              <SelectControl
                field={{
                  name: 'recurrence.byMonthDay',
                  value:
                    form.values.recurrence.byMonthDay ||
                    `SETPOS:${form.values.recurrence.bySetPos}`,
                  onChange: evt => {
                    const { value } = evt.target;
                    if (typeof value === 'number') {
                      form.setFieldValue('recurrence.byMonthDay', parseInt(value, 10));
                      form.setFieldValue('recurrence.bySetPos', null);
                    } else {
                      form.setFieldValue('recurrence.byMonthDay', null);
                      form.setFieldValue(
                        'recurrence.bySetPos',
                        parseInt(value.substr('setpos:'.length), 10),
                      );
                    }
                  },
                  onBlur: () => form.setFieldTouched('recurrence.byMonthDay'),
                }}
                form={form}
                className={props.classes.byMonthDay}
                fullWidth={false}
                options={[
                  ...Object.keys(SET_POS).map(pos => ({
                    value: `SETPOS:${pos}`,
                    label: `${SET_POS[pos]}...`,
                  })),
                  ...MONTH_DAYS.map(day => ({ value: day, label: ordinal(day) })),
                ]}
              />
            )}
          </Field>
          {props.bySetPos && (
            <Field
              name="recurrence.byDayForMonth"
              component={SelectControl}
              className={props.classes.byDayForMonth}
              fullWidth={false}
              options={WEEKDAYS.map(day => ({ value: day, label: WEEKDAYS_NAMES[day] }))}
            />
          )}
        </>
      );
    default:
      return null;
  }
};

const Details = props => (
  <span style={{ marginTop: 15, marginLeft: 8 }}>
    <ScheduledDetails {...props} />
  </span>
);

const RecurringEditor = ({ classes, rrule }) => {
  let frequencyValues;
  switch (rrule.type) {
    case TYPES.SCHEDULED:
      frequencyValues = [FREQUENCY.DAILY, FREQUENCY.WEEKLY, FREQUENCY.MONTHLY];
      break;
    default:
      frequencyValues = [];
  }

  return (
    <div className={classes.root}>
      <Field name="recurrence.type">
        {({ field, form }) => {
          return (
            <SelectControl
              field={{
                ...field,
                onChange: evt => {
                  if (evt.target.value) {
                    /**
                     * recurrence is initially set to null. In order to surface error messages for its fields on
                     * form submission if the event is recurring, they must be specified in initialValues which
                     * can be set on resetting the form
                     * @see {@link https://formik.org/docs/guides/form-submission#pre-submit}
                     */
                    form.resetForm({
                      values: {
                        ...form.values,
                        recurrence: {
                          type: evt.target.value,
                          freq: null,
                          interval: null,
                          byMonthDay: null,
                          bySetPos: null,
                          byDayForMonth: null,
                          until: null,
                        },
                      },
                    });
                  } else {
                    form.setFieldValue('recurrence', null);
                  }
                },
              }}
              form={form}
              className={classes.formControl}
              label="Schedule Type"
              displayEmpty
              options={[
                { value: '', label: "Doesn't repeat" },
                { value: 'scheduled', label: 'Scheduled' },
              ]}
            />
          );
        }}
      </Field>
      {rrule.type && (
        <Field name="recurrence.freq">
          {({ field, form }) => (
            <SelectControl
              field={field}
              form={form}
              className={classes.freq}
              label="Repeat"
              displayEmpty
              options={frequencyValues.map(freqVal => ({
                value: freqVal,
                label: startCase(freqVal),
              }))}
            />
          )}
        </Field>
      )}
      {rrule.type && rrule.freq && (
        <>
          <Details
            type={rrule.type}
            freq={rrule.freq}
            interval={rrule.interval}
            byDay={rrule.byDay || []}
            byDayForMonth={rrule.byDayForMonth}
            byMonthDay={rrule.byMonthDay}
            bySetPos={rrule.bySetPos}
            classes={classes}
          />
          <Field name="recurrence.until">
            {({ form }) => {
              const until = rrule.until ? parseISO(rrule.until) : null;
              const { errors, touched } = form;
              return (
                <KeyboardDatePicker
                  label="Ends On *"
                  className={classes.formControl}
                  onChange={value => {
                    if (value && isValidDate(value)) {
                      const transformedValue = addDays(value, 1);
                      form.setFieldValue(
                        'recurrence.until',
                        format(transformedValue, 'yyyy-MM-dd'),
                      );
                    } else if (value) {
                      form.setFieldValue('recurrence.until', value.toString());
                    }
                  }}
                  value={until && isValidDate(until) ? subDays(until, 1) : until}
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  autoOk
                  error={get(touched, 'recurrence.until') && get(errors, 'recurrence.until')}
                  helperText={
                    get(touched, 'recurrence.until') ? get(form.errors, 'recurrence.until') : null
                  }
                />
              );
            }}
          </Field>
        </>
      )}
    </div>
  );
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  nonLabelled: {
    marginLeft: 8,
    marginTop: 18,
  },
  narrow: {
    width: 60,
    paddingLeft: 10,
    paddingRight: 10,
  },
  freq: {
    width: 200,
    margin: 8,
    verticalAlign: 'inherit',
  },
  interval: {
    width: 60,
    paddingLeft: 10,
    paddingRight: 10,
    verticalAlign: 'inherit',
  },
  byMonthDay: {
    marginLeft: 15,
    marginRight: 15,
    verticalAlign: 'inherit',
    width: 100,
  },
  byDayForMonth: {
    marginRight: 15,
    verticalAlign: 'inherit',
    width: 150,
  },
});

const StyledRecurringEditor = withStyles(styles)(RecurringEditor);

const RecurrenceControl = ({
  classes,
  isValid,
  label,
  recurrence,
  start,
  duration,
  timezone,
  allDay,
}) => (
  <FormControl fullWidth className={classes.root}>
    <InputLabel shrink>{label}</InputLabel>
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
        {isValid && rruleToString(recurrence, allDay, start, duration, timezone)}
      </AccordionSummary>
      <AccordionDetails>
        <StyledRecurringEditor
          rrule={recurrence || {}}
          allDay={allDay}
          start={start}
          duration={duration}
        />
      </AccordionDetails>
    </Accordion>
  </FormControl>
);

const recurrenceStyles = theme => ({
  root: {
    // Should be provided by <Field> instead, but currently being overridden by the render implementation
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  accordion: {
    marginTop: 20,
  },
  accordionSummary: {
    color: '#999999',
    fontStyle: 'italic',
    fontSize: '90%',
  },
});

export default withStyles(recurrenceStyles)(RecurrenceControl);
