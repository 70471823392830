import {
  FormLabel,
  makeStyles,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import useSWR from 'swr';

import ErrorAlert from 'src/components/general/ErrorAlert';
import { WindowDisplay } from 'src/dropInClinic/WindowDisplay';
import { deserializeAvailabilityGraphql } from 'src/dropInClinic/deserializeGraphql';
import { AVAILABILITY_QUERY } from 'src/dropInClinic/queries';
import { AvailabilityQueryReturn, Overview, ProviderAvailability } from 'src/dropInClinic/types';
import Colors from 'src/nightingale/Colors';

const StateAvailability = ({
  state,
  overview,
  providerAvailability,
}: {
  state: string;
  overview: Overview;
  providerAvailability: ProviderAvailability[];
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.stateSection} key={state}>
      <List dense>
        <div className={overview.hasAvailability ? '' : classes.noAvailability}>
          <ListSubheader id={`availability-${state}-header`} color="primary">
            <b>{state}</b>{' '}
            {overview.windows.map(window => (
              <WindowDisplay window={window} />
            ))}
          </ListSubheader>
        </div>
        {providerAvailability.map(availability => (
          <AvailabilityItem
            dropInProvider={availability.dropInProvider}
            availability={availability.availability}
          />
        ))}
      </List>
    </Paper>
  );
};

const AvailabilityItem = ({ dropInProvider, availability }: ProviderAvailability) => {
  return (
    <ListItem>
      <ListItemText>
        {dropInProvider.firstName} {dropInProvider.lastName}
      </ListItemText>
      <ListItemSecondaryAction>
        <WindowDisplay window={availability} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export const ProviderAvailabilities = () => {
  const [searchDate, setSearchDate] = useState(new Date());
  const { data, error } = useSWR<AvailabilityQueryReturn>([
    AVAILABILITY_QUERY,
    { date: searchDate },
  ]);

  const classes = useStyles();

  if (!data) {
    return <div className={classes.root}>Fetching availability</div>;
  }

  const availabilityByState = data.getClinicAvailabilitiesByDate
    ? deserializeAvailabilityGraphql(data.getClinicAvailabilitiesByDate)
    : {};

  return (
    <div className={classes.root}>
      {error && <ErrorAlert message="Error fetching availability." error={error} />}
      <span className={classes.header}>
        <FormLabel>Pop-In Provider Availability</FormLabel>
        <DatePicker label="Search Availability" value={searchDate} onChange={setSearchDate} />
      </span>
      {Object.entries(availabilityByState).map(([state, generalAvailability]) => (
        <StateAvailability
          state={state}
          overview={generalAvailability.overview}
          providerAvailability={generalAvailability.providerAvailability}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: 500,
  },
  noAvailability: {
    backgroundColor: Colors.Coral,
  },
  stateSection: {
    marginTop: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
