import React from 'react';

import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { TextInput } from 'src/nightingale/components/ChartProperty/controls/Text/TextInput';
import { ControlProps, TextChartProperty } from 'src/nightingale/types/types';

export interface TextControlProps extends ControlProps<TextChartProperty> {
  id?: string;
  multiline?: boolean;
}

/**
 * Text Control component. Supports single and multiline.
 */
export const TextControl: React.FC<TextControlProps> = ({
  description,
  hasEmptyValue,
  isRequired,
  label,
  onChangeValue,
  value,
  ...rest
}) => (
  <LabeledFormControl
    hasEmptyValue={hasEmptyValue}
    htmlFor={rest.id}
    isRequired={isRequired}
    label={label}
  >
    <TextInput onChange={onChangeValue} placeholder={description} value={value} {...rest} />
  </LabeledFormControl>
);
