import keyBy from 'lodash/keyBy';
import upperFirst from 'lodash/upperFirst';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { CLINIC_QUEUE_SETTINGS_QUERY } from 'src/dropInClinic/queueSettings/queueSettings.gql';
import { Staff_QueueSchedule as QueueSchedule } from 'src/generated/gql/graphql';

export type QueueVisitLimit = {
  state: string;
  requestType: string;
  numVisitsLimit?: number;
};

type ActiveQueueSetting = {
  [name: string]: {
    name: string;
    value: unknown;
  };
};

/*
 * An alias that just means "all of the states combined".
 */
const allStatesAlias = 'global';

/*
 * All pop in clinic states where we set a limit on the number of visits.
 */
const states = ['AK', 'CO', 'NC', 'OH', 'OR', 'WA'];

/*
 * All request types where we set a limit on the number of visits.
 * Note that "oft" is not included here because we have decommissioned the OFT pop in request type.
 */
const requestTypes = ['intake', 'followUp', 'peerVisit', 'reengagement'];

/*
 * A hook that will return a conveniently formatted collection of the current queue visit limits
 * for each state and request type. Note that this will also return the "global" limits, which
 * can be identified by the state being set to 'global'. This is "a bit of a hack",
 * but it's a convenient way to get the global limits included in the same collection.
 */
export const useCurrentQueueLimits = () => {
  const { data: activeQueueSettingsData } = useTypedSWR([
    CLINIC_QUEUE_SETTINGS_QUERY,
    { schedule: QueueSchedule.Active },
  ]);
  const activeQueueSettings = activeQueueSettingsData
    ? keyBy(activeQueueSettingsData.staff_clinicQueueSettings, 'name')
    : {};

  return transformQueueLimits(activeQueueSettings);
};

/*
 * Transforms the shape of the activeQueueSettings data returned from the server into
 * something more convenient for the staff app.
 */
const transformQueueLimits = (activeQueueSettings: ActiveQueueSetting) =>
  [allStatesAlias, ...states].flatMap(state =>
    requestTypes.map(requestType => {
      const settingName = `${state}${upperFirst(requestType)}Limit`;
      const numVisitsLimit = Number(activeQueueSettings[settingName]?.value as string);
      return { state, requestType, numVisitsLimit };
    }),
  );
