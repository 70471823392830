import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import React from 'react';

import type { UserSearchResult } from 'src/stores/queries/userSearch';
import { colors } from 'src/util/colors';
import { getStateAbbreviation } from 'src/util/usStates';

const UserSelectItem = ({ person }: { person: UserSearchResult }) => {
  const isProvider = person.__typename === 'Provider';
  const classes = useStyles();
  if (isProvider) {
    return (
      <div className={classes.providerInfo}>
        {person.firstName} {person.lastName} (Provider)
      </div>
    );
  } else {
    return (
      <div>
        {person.firstName} {person.preferredName && <strong>{`"${person.preferredName}" `}</strong>}
        {person.lastName}
        <div className={classes.additionalPatientInfo}>
          {person.homeAddress?.state && (
            <strong>{getStateAbbreviation(person.homeAddress?.state)}</strong>
          )}
          {person.dob && <span>{format(parseISO(person.dob), 'MM/dd/yy')}</span>}
        </div>
      </div>
    );
  }
};

const useStyles = makeStyles({
  additionalPatientInfo: {
    color: colors.mediumGray,
    fontSize: '80%',
    lineHeight: '80%',

    // Comma-separating the different infos
    '& > ::before': {
      content: '", "',
    },
    '& :first-child::before': {
      content: '""',
    },
  },
  providerInfo: {
    color: colors.mediumGray,
  },
});

export default UserSelectItem;
