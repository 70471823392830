import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import React from 'react';

import { Schedule } from 'src/dropInClinic/queueSettings/Schedule';
import SettingForm from 'src/dropInClinic/queueSettings/SettingForm';
import { useQueueSettings } from 'src/dropInClinic/queueSettings/useQueueSettings';
import useApolloClient from 'src/shared/client/useApolloClient';

/** Shown in the form if there's no saved value for it yet */
const defaultMaximumQueueLength = 0;

type QueueLimitsFormsProps = {
  /** The function to call when one of the values is updated */
  onSubmit: (
    schedule: Schedule,
    apolloClient: ApolloClient<NormalizedCacheObject>,
  ) => (e: React.FormEvent) => Promise<void>;
  /** The set of settings to render forms for, for each of active, weekday, and weekend settings */
  settingNames: string[];
};

/** Renders the 3 columns of queue limits forms */
const QueueLimitsForms: React.FC<QueueLimitsFormsProps> = ({ onSubmit, settingNames }) => {
  const { activeQueueSettings, weekdayQueueSettings, weekendQueueSettings } = useQueueSettings();
  const apolloClient = useApolloClient();

  return (
    <>
      {[Schedule.ACTIVE, Schedule.WEEKDAY, Schedule.WEEKEND].map((schedule: Schedule) => {
        const queueSettings = {
          [Schedule.ACTIVE]: activeQueueSettings,
          [Schedule.WEEKDAY]: weekdayQueueSettings,
          [Schedule.WEEKEND]: weekendQueueSettings,
        }[schedule];

        return (
          <div>
            {settingNames.map(settingName => (
              <SettingForm
                key={settingName}
                settingName={settingName}
                maximumQueueLength={
                  (queueSettings[settingName]?.value as number) || defaultMaximumQueueLength
                }
                onSubmit={onSubmit(schedule, apolloClient)}
              />
            ))}
          </div>
        );
      })}
    </>
  );
};

export default QueueLimitsForms;
