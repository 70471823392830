import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';

import logger from 'src/shared/util/logger';
import useRootStore from 'src/stores/useRootStore';

/** Time in ms between checks for if the code has been used yet */
const VERIFY_VISIT_CODE_INTERVAL = 10 * 1000; // 10 seconds

type VisitCode = {
  /** The code the guest can use to join the call */
  code: string;
  /** The ID of this visit code */
  id: string;
};

type InviteViaCodeProps = {
  /** The ID of the event we're inviting to the video for */
  eventId: string;
  /** Called when someone has joined using the current visit code */
  onJoin: () => void;
};

const InviteViaCode: React.FC<InviteViaCodeProps> = ({ eventId, onJoin }) => {
  const classes = useStyles();

  const { generating, visitCode } = useVisitCodeApi(eventId, onJoin);

  return (
    <>
      <p className={generating ? classes.italic : undefined}>
        Tell the guest to open <span className={classes.visitUrl}>visit.boulder.care</span> and
        enter this Visit ID:
      </p>
      {generating && <CircularProgress className={classes.progress} />}
      {visitCode && (
        <h1 className={classes.visitCode}>
          {`${visitCode.code.substring(0, 3)} ${visitCode.code.substring(3, 6)}`}
        </h1>
      )}
      {!generating && (
        <div className={classes.italic}>
          This code is only valid while this window is open. Waiting for participant to join...{' '}
          <CircularProgress className={classes.loadingVisitCode} size={12} />
        </div>
      )}
    </>
  );
};

function useVisitCodeApi(eventId: string, onJoin: () => void) {
  const rootStore = useRootStore();
  const [generating, setGenerating] = useState(true);

  const [visitCode, setVisitCode] = useState<VisitCode | null>(null);

  const generateVisitCode = useCallback(async (): Promise<VisitCode> => {
    try {
      return await rootStore.events.inviteEventGuestByVisitCode(eventId);
    } catch (e) {
      logger.warn('Failed to generate visit code for event: ', e);
      // Allow anyone calling this to properly handle the error as well.
      throw e;
    }
  }, [rootStore.events, eventId]);

  const isVisitCodeUsed = useCallback(
    async (visitCodeId: string): Promise<boolean> => {
      try {
        return await rootStore.events.isVisitCodeUsed(visitCodeId);
      } catch (e) {
        logger.warn('Failed to send email invitation: ', e);
        // Allow anyone calling this to properly handle the error as well.
        throw e;
      }
    },
    [rootStore.events],
  );

  // Generate a visit code for the event
  useEffect(() => {
    (async () => {
      const newVisitCode = await generateVisitCode();

      setVisitCode(newVisitCode);
      setGenerating(false);
    })();
  }, [rootStore.events, eventId]);

  // Signal that someone has joined when we see that the visit code has been used
  useEffect(() => {
    const interval = setInterval(async () => {
      if (!visitCode) {
        return;
      }

      const visitCodeIsUsed = await isVisitCodeUsed(visitCode.id);
      if (visitCodeIsUsed) {
        onJoin();
        clearInterval(interval);
      }
    }, VERIFY_VISIT_CODE_INTERVAL);

    return () => clearInterval(interval);
  });

  return { generating, visitCode };
}

const useStyles = makeStyles({
  italic: {
    fontStyle: 'italic',
  },
  visitUrl: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  progress: {
    padding: 10,
  },
  visitCode: {
    letterSpacing: 20,
  },
  loadingVisitCode: {
    verticalAlign: 'middle',
  },
});

export default InviteViaCode;
