import { RouterLink as MobxStateRouterLink } from 'mobx-state-router';
import type { RouterLinkProps } from 'mobx-state-router';
import React from 'react';

// Used when link needs to be styled as text or when components can be passed in as
// a prop i.e. ListItem. Otherwise use RouteLink component for buttons.

const RouterLink: React.FC<RouterLinkProps> = ({ children, ...props }) => {
  // Implemented styling this way because RouterLink expects className to be
  // different from our current implementation
  // Source: https://nareshbhatia.github.io/mobx-state-router/docs/api-router-link

  const style = {
    textDecoration: 'none',
  };

  return (
    <MobxStateRouterLink style={style} {...props}>
      {children}
    </MobxStateRouterLink>
  );
};

/**
 * RouterLink doesn't forward refs to the underlying link, so for e.g. material ui
 * components based on ButtonBase that require a component that can accept refs,
 * we can use this one which wraps the link in a div.
 */
export const RouterLinkDiv = React.forwardRef<HTMLDivElement, RouterLinkProps>((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

export default RouterLink;
