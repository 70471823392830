import get from 'lodash/get';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';

import { addNameAndVersionChecks } from 'src/components/forms/addNameAndVersionChecks';
import baseRenderer from 'src/components/forms/renderers/base';
import schemas from 'src/components/forms/schemas';
import baseValidator from 'src/components/forms/validators/base';

export const getSchemaNames = schema => {
  return Object.keys(schemas[schema]).filter(name => !getSchema(schema, name).disabled);
};

export const getSchemaBridge = addNameAndVersionChecks((schema, name, version) => {
  const schemaInstance = getSchema(schema, name, version);
  const validatorCreateFn = getValidator(schema, name, version);
  return new JSONSchemaBridge(schemaInstance, validatorCreateFn(schemaInstance));
});

export const getSchema = addNameAndVersionChecks((schema, name, version) => {
  return version
    ? get(schemas[schema][name], version)
    : schemas[schema][name].latest || schemas[schema][name];
});

const getValidator = addNameAndVersionChecks(() => baseValidator);

export const getRenderer = addNameAndVersionChecks(() => baseRenderer);

const invalidProperties = ['label', 'renderer', 'tooltip'];

export const getTrueJsonSchema = addNameAndVersionChecks((schema, name, version) => {
  const jsonSchema = getSchema(schema, name, version);
  const updateProps = obj => {
    const newObj = {};
    Object.keys(obj).forEach(key => {
      if (!invalidProperties.includes(key)) {
        if (!Array.isArray(obj[key]) && typeof obj[key] === 'object') {
          newObj[key] = updateProps(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    });
    return newObj;
  };
  return updateProps(jsonSchema);
});
