"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ifContextChartProperty = void 0;
exports.overloadIf = overloadIf;
var _handlebars = require("handlebars");
function overloadIf(...args) {
  const options = args.pop();
  if (ifContextChartProperty(args[0])) return options.fn(this);
  return options.inverse(this);
}
const ifContextChartProperty = property => property?.propertyName ? !_handlebars.Utils.isEmpty(Object.keys(property?.properties || {})) || !_handlebars.Utils.isEmpty(property?.items) || !_handlebars.Utils.isEmpty(property?.value) : !_handlebars.Utils.isEmpty(property);
exports.ifContextChartProperty = ifContextChartProperty;