import formatDistance from 'date-fns/formatDistance';
import { format } from 'date-fns/fp';
import React, { memo } from 'react';

import Tooltip from 'src/components/general/Tooltip';

type DurationProps = {
  currentTime: Date;
  date: Date;
};

export const Duration = memo(({ date, currentTime }: DurationProps) => {
  return (
    <Tooltip title={format('h:mm:ss a', date)}>
      <span>{formatDistance(date, currentTime)}</span>
    </Tooltip>
  );
});
