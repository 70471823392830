import React from 'react';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { InteractionFlowMapping } from 'src/generated/gql/graphql';
import { FLOW_MAPPINGS_QUERY } from 'src/nightingale/components/Flow/useSWRFlowMappings.gql';
import { InteractionKind } from 'src/nightingale/types/types';

export const FlowMappingsContext = React.createContext<{
  flowMappingKeys: Array<InteractionFlowMapping['interactionKey']>;
}>({ flowMappingKeys: [] });

/**
 * Given an interaction kind, fetches and stores all of the interactions keys for the corresponding
 * flow mappings.
 */
export const FlowMappingsContextProvider: React.FC<{ kind: InteractionKind }> = ({
  kind,
  children,
}) => {
  const { data } = useTypedSWR([FLOW_MAPPINGS_QUERY, { kind }], { revalidateOnFocus: false });

  return (
    <FlowMappingsContext.Provider
      value={{
        flowMappingKeys: data?.flowMappingsByKind?.map(mapping => mapping.interactionKey) || [],
      }}
    >
      {children}
    </FlowMappingsContext.Provider>
  );
};
