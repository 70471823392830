import { RequiredChartPropertyConfig } from '@boulder-package/chart-definition-types';

import type { TaggedTextValue } from 'src/util/taggedText';

/**
 * Enums
 */
export enum PhiTypes {
  Text = 'TEXT',
  Safe = 'SAFE',
  Name = 'NAME',
  Date = 'DATE',
}

export enum ChartPropertyKind {
  /**
   * A property describing an ongoing attribute of the patient, like a birthday or first name
   *
   * These can change over time, like pregnancy status or current insurance, but once set, they apply
   * to that patient on an ongoing basis until they are changed. They will generally be captured during
   * an Interaction, but are more like updates to the Patient record than updates to the Interaction itself.
   */
  Patient = 'PATIENT',

  /**
   * A property capturing a temporal attribute of the patient
   *
   * These describe a specific Interaction or the patient at the time of the Interaction, like the patient's
   * current location or an NPS score (since the NPS score is associated with a particular date).
   */
  Interaction = 'INTERACTION',
}

export enum ChartElementKind {
  /** An ongoing ChartElement whose home is the patient chart */
  Patient = 'PATIENT',
  /** A temporal ChartElement having to do with a particular interaction */
  Interaction = 'INTERACTION',
}

export enum ChartPropertyAction {
  Confirm = 'CONFIRM',
  Update = 'UPDATE',
}

export enum ChartPropertyTypes {
  Boolean = 'BOOLEAN',
  CheckboxList = 'CHECKBOX_LIST',
  Date = 'DATE',
  DateTime = 'DATETIME',
  Email = 'EMAIL',
  Integer = 'INT',
  List = 'LIST',
  LongText = 'LONG_TEXT',
  Object = 'OBJECT',
  Phone = 'PHONE',
  Select = 'SELECT',
  SelectAsync = 'SELECT_ASYNC',
  SelectMulti = 'SELECT_MULTI',
  SelectMultiAsync = 'SELECT_MULTI_ASYNC',
  TaggedText = 'TAGGED_TEXT',
  Text = 'TEXT',
}

export type LabelType =
  | 'interaction-header'
  | 'interaction-header-with-icon'
  | 'label'
  | 'patient-header'
  | 'patient-header-with-icon';

/**
 * Basic chart property data model definition on how data from Dato is formatted.
 */
export interface BasicChartProperty<ValueType> {
  action?: ChartPropertyAction;
  afterMarkAsNone?: () => void;
  allowNone?: boolean;
  alwaysShowNotes?: boolean;
  autoFocus?: boolean;
  createdAt?: string;
  default?: { value: any };
  description?: string;
  disabled?: boolean;
  id: string;
  interactionId?: string;
  isEmpty?: boolean;
  kind?: ChartPropertyKind;
  label?: string;
  name: string;
  notes?: string | null;
  onError?: (hasError: boolean) => void;
  setPropertyValue?: (
    cpv: Partial<Pick<ChartPropertyValue, 'value' | 'notes' | 'isEmpty'>>,
  ) => void;
  phiType?: PhiTypes;
  placeholder?: string;
  readOnly?: boolean;
  type: ChartPropertyTypes;
  updatedAt?: string;
  value?: ValueType;
}

/**
 * Control props helper
 */
export type ControlProps<ChartProperty> = {
  hasEmptyValue?: boolean;
  id?: string;
  isRequired?: boolean;
  onChangeValue?: (value: unknown) => void;
} & Omit<
  ChartProperty,
  | 'allowNone'
  | 'alwaysShowNotes'
  | 'createdAt'
  | 'id'
  | 'isEmpty'
  | 'interactionId'
  | 'notes'
  | 'phiType'
  | 'setPropertyValue'
  | 'showNotes'
  | 'type'
  | 'updatedAt'
>;

/**
 * ChartProperty: Boolean
 */
export interface BooleanChartProperty extends BasicChartProperty<boolean> {
  type: ChartPropertyTypes.Boolean;
  canBeUndefined?: boolean;
}

/**
 * ChartProperty: CheckboxList
 */
export interface CheckboxItem {
  label: string;
  value: string;
}

export interface CheckboxListChartProperty extends BasicChartProperty<CheckboxItem[]> {
  type: ChartPropertyTypes.CheckboxList;
  options: CheckboxItem[];
}

/**
 * ChartProperty: Date
 */
export interface DateChartProperty extends BasicChartProperty<string> {
  type: ChartPropertyTypes.Date;
}

/**
 * ChartProperty: DateTime
 */
export interface DateTimeChartProperty extends BasicChartProperty<Date> {
  type: ChartPropertyTypes.DateTime;
}

/**
 * ChartProperty: Email
 */
export interface EmailChartProperty extends BasicChartProperty<string> {
  type: ChartPropertyTypes.Email;
}

/**
 * ChartProperty: Integer
 */
export interface IntegerChartProperty extends BasicChartProperty<number> {
  type: ChartPropertyTypes.Integer;
  min?: number;
  max?: number;
}

/**
 * ChartProperty: List
 */
export interface ListChartProperty extends BasicChartProperty<Array<{ [key: string]: any }>> {
  type: ChartPropertyTypes.List;
  conditions: Array<Condition<SimpleChartProperty>> | null;
  properties: SimpleChartProperty[];
  listOrder?: {
    /** The direction of the sort */
    direction: SortOrder;
    /**
     * A Handlebars template representing the fields on a list element that will be used to sort the
     * items in that list.
     *
     * Example: For a `Person` object, `${{currentItem.lastName}}, ${{currentItem.firstName}}`
     * indicates that we want to sort a list of such objects by each person's last name, and
     * secondarily by their first name.
     */
    expression: string;
    /**
     * Text that can be displayed to a staff app user, describing how the items in the list are
     * sorted.
     */
    helpText: string;
  };
}

/**
 * ChartProperty: Object
 */
export interface ObjectChartProperty extends BasicChartProperty<{ [key: string]: any }> {
  type: ChartPropertyTypes.Object;
  conditions: Array<Condition<SimpleChartProperty>> | null;
  properties: SimpleChartProperty[];
}

/**
 * ChartProperty: Phone
 */
export interface PhoneChartProperty extends BasicChartProperty<string> {
  type: ChartPropertyTypes.Phone;
}

/**
 * ChartProperty: Select
 */
export const SELECT_OPTION_OTHER = 'other';
export type SelectOption = {
  label?: string;
  value: string;
  otherValue?: string;
  vanity?: string;
};
export type SelectOptionsList = SelectOption[];
export type Selection = SelectOption | SelectOptionsList | null;

interface BaseSelectChartProperty extends BasicChartProperty<Selection> {
  allowOther?: boolean;
  type:
    | ChartPropertyTypes.Select
    | ChartPropertyTypes.SelectAsync
    | ChartPropertyTypes.SelectMulti
    | ChartPropertyTypes.SelectMultiAsync;
}

export interface SelectStaticChartProperty extends BaseSelectChartProperty {
  options: SelectOptionsList;
}

export enum SelectAsyncOptionsSources {
  ICD10Codes = 'ICD10_CODES',
  MedicalProblems = 'MEDICAL_PROBLEMS',
  Medications = 'MEDICATIONS',
  Patients = 'PATIENTS',
  Payors = 'PAYORS',
  Providers = 'PROVIDERS',
}

export interface SelectAsyncChartProperty extends BaseSelectChartProperty {
  optionsSource: SelectAsyncOptionsSources;
}

/**
 * ChartProperty: Text
 */
export interface TextChartProperty extends BasicChartProperty<string> {
  type: ChartPropertyTypes.Text | ChartPropertyTypes.LongText;
}

export interface TaggedTextChartProperty extends BasicChartProperty<TaggedTextValue> {
  type: ChartPropertyTypes.TaggedText;
  tags: string[];
}

/**
 * All the ChartProperties
 */
export type AnyChartProperty =
  | BooleanChartProperty
  | CheckboxListChartProperty
  | DateChartProperty
  | DateTimeChartProperty
  | EmailChartProperty
  | IntegerChartProperty
  | ListChartProperty
  | ObjectChartProperty
  | PhoneChartProperty
  | SelectAsyncChartProperty
  | SelectStaticChartProperty
  | TextChartProperty
  | TaggedTextChartProperty;

/**
 * Simple ChartProperties, can be children of complex ones (list, object)
 */
export type SimpleChartProperty =
  | BooleanChartProperty
  | CheckboxListChartProperty
  | DateChartProperty
  | DateTimeChartProperty
  | EmailChartProperty
  | IntegerChartProperty
  | PhoneChartProperty
  | SelectAsyncChartProperty
  | SelectStaticChartProperty
  | TextChartProperty;

/**
 * Basic chart element data model definition on how data from Dato is formatted.
 */
export type ChartElement = {
  id: string;
  __typename?: string;
  createdAt?: string;
  help?: string;
  /**
   * Hides element in search
   */
  hidden?: boolean;
  inlineHelp?: string;
  label?: string;
  name: string;
  parsedInlineHelp?: string;
  properties: AnyChartProperty[];
  /**
   * Summary (handlebars expression) converted to HTML
   */
  summarization?: string;
  /**
   * Handlebars expression
   */
  summary: string;
  conditions: Array<Condition<AnyChartProperty>> | null;
};

/**
 * Chart Overview Section
 */
export type ChartOverviewSection = {
  id: string;
  conditions: Array<Condition<ChartElement>> | null;
  elements: ChartElement[];
  hidden?: boolean;
  label?: string;
  name: string;
  requiredChartPropertiesConfig?: RequiredChartPropertiesConfig;
};

/**
 * Chart Property Value object format
 */
export type ChartPropertyValue = {
  propertyName: string;
  value: any;
  notes: string | null;
  isEmpty: boolean;
  createdAt?: string;
  interactionId?: string;
  action?: ChartPropertyAction;
};

/** The parts of a CPV that have changed */
export type ChartPropertyValueChangeSet = Pick<ChartPropertyValue, 'propertyName'> &
  Partial<Omit<ChartPropertyValue, 'propertyName'>>;

/**
 * A chart property definition that has been augmented with data from a CPV
 *
 * @see withValue (src/nightingale/data/ChartElement.utils.ts) for an example of when we do this.
 */
export type ChartPropertyWithValue<T extends AnyChartProperty = AnyChartProperty> = Omit<
  T,
  'value'
> &
  Pick<ChartPropertyValue, 'action' | 'interactionId' | 'isEmpty' | 'value'>;

export enum InteractionKind {
  Visit = 'VISIT',
  Pebble = 'PEBBLE',
  Task = 'TASK',
  Automation = 'AUTOMATION',
}

export type Interaction = {
  id: string;
  referenceId: string;
  kind: InteractionKind;
  flow: Flow;
  patientSnapshotTime: Date;
  updatedChartProperties: Array<
    Pick<ChartPropertyValue, 'propertyName' | 'interactionId'> &
      Required<Pick<ChartPropertyValue, 'createdAt'>>
  >;
  /** The values only set in _this_ interaction */
  interactionValues: ChartPropertyWithValue[];
};

export enum FlowStepType {
  ChartElement = 'ChartElement',
  Flow = 'Flow',
}

export type FlowStep = ChartElement | Flow;

export interface Flow {
  __typename?: string;
  id: string;
  name: string;
  label?: string | null;
  header?: string;
  elements: FlowStep[];
  conditions: Array<Condition<FlowStep>> | null;
  requiredChartPropertiesConfig?: RequiredChartPropertiesConfig;
}

export type RequiredChartPropertiesConfig = RequiredChartPropertyConfig[];

/**
 * A valid target for a Condition
 */
export type ConditionTarget = ChartElement | Flow | AnyChartProperty;

export type Condition<TTarget extends ConditionTarget = ConditionTarget> = {
  /**
   * A Handlebars template representing the condition, where the string "true" resolves to true, false otherwise.
   */
  displayIf: string;

  /**
   * An object containing the ID of the item that the condition should apply to.
   */
  target: Pick<TTarget, 'id'>;
};

/** Represents the direction of a sort: ascending or descending */
export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}
