import v100Schema from 'src/components/forms/schemas/eventResult/prescriberTelehealthPrecheck/1.0.0';

const { location, ...v100Properties } = v100Schema.properties;
location.title = 'Participant Location';

export default {
  ...v100Schema,
  properties: {
    ...v100Properties,
    location,
    currentLocation: {
      type: 'string',
      tooltip: '"Where are you joining this call from?"',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: ['currentLocation'],
};
