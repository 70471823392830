import omit from 'lodash/omit';
import React from 'react';

import {
  BooleanControl,
  BooleanControlProps,
} from 'src/nightingale/components/ChartProperty/controls/Boolean/BooleanControl';
import {
  CheckboxListControl,
  CheckboxListControlProps,
} from 'src/nightingale/components/ChartProperty/controls/CheckboxList/CheckboxListControl';
import {
  DateControl,
  DateControlProps,
} from 'src/nightingale/components/ChartProperty/controls/Date/DateControl';
import {
  EmailControl,
  EmailControlProps,
} from 'src/nightingale/components/ChartProperty/controls/Email/EmailControl';
import {
  IntegerControl,
  IntegerControlProps,
} from 'src/nightingale/components/ChartProperty/controls/Integer/IntegerControl';
import {
  ListControl,
  ListControlProps,
} from 'src/nightingale/components/ChartProperty/controls/List/ListControl';
import {
  ObjectControl,
  ObjectControlProps,
} from 'src/nightingale/components/ChartProperty/controls/Object/ObjectControl';
import {
  PhoneControl,
  PhoneControlProps,
} from 'src/nightingale/components/ChartProperty/controls/Phone/PhoneControl';
import {
  SelectAsyncControlProps,
  SelectControl,
  SelectStaticControlProps,
} from 'src/nightingale/components/ChartProperty/controls/Select/SelectControl';
import {
  TaggedTextControl,
  TaggedTextControlProps,
} from 'src/nightingale/components/ChartProperty/controls/TaggedText/TaggedTextControl';
import {
  TextControl,
  TextControlProps,
} from 'src/nightingale/components/ChartProperty/controls/Text/TextControl';
import { AnyChartProperty, ChartPropertyTypes } from 'src/nightingale/types/types';

export type AnyControlProps =
  | SelectAsyncControlProps
  | BooleanControlProps
  | CheckboxListControlProps
  | DateControlProps
  | EmailControlProps
  | IntegerControlProps
  | ListControlProps
  | ObjectControlProps
  | PhoneControlProps
  | SelectStaticControlProps
  | TextControlProps
  | TaggedTextControlProps;

export const createControlProps = (chartProperty: AnyChartProperty): AnyControlProps =>
  omit(chartProperty, [
    'afterMarkAsNone',
    'allowNone',
    'alwaysShowNotes',
    'calculatedChartProperty',
    'calculation',
    'calculationDependencies',
    'createdAt',
    'interactionId',
    'isEmpty',
    'notes',
    'phiType',
    'setPropertyValue',
    'showNotes',
    'type',
    'updatedAt',
  ]) as AnyControlProps;

/**
 * Control Factory
 */
export const ControlFactory: React.FC<{ type: ChartPropertyTypes; props: AnyControlProps }> = ({
  type,
  props,
}) => {
  switch (type) {
    case ChartPropertyTypes.Boolean:
      return <BooleanControl {...(props as BooleanControlProps)} />;
    case ChartPropertyTypes.CheckboxList:
      return <CheckboxListControl {...(props as CheckboxListControlProps)} />;
    case ChartPropertyTypes.Date:
      return <DateControl {...(props as DateControlProps)} />;
    case ChartPropertyTypes.Email:
      return <EmailControl {...(props as EmailControlProps)} />;
    case ChartPropertyTypes.Text:
      return <TextControl {...(props as TextControlProps)} />;
    case ChartPropertyTypes.Integer:
      return <IntegerControl {...(props as IntegerControlProps)} />;
    case ChartPropertyTypes.List:
      return <ListControl {...(props as ListControlProps)} />;
    case ChartPropertyTypes.LongText:
      return <TextControl {...(props as TextControlProps)} multiline />;
    case ChartPropertyTypes.Object:
      return <ObjectControl {...(props as ObjectControlProps)} />;
    case ChartPropertyTypes.Phone:
      return <PhoneControl {...(props as PhoneControlProps)} />;
    case ChartPropertyTypes.Select:
      return <SelectControl {...(props as SelectStaticControlProps)} />;
    case ChartPropertyTypes.SelectAsync:
      return <SelectControl {...(props as SelectAsyncControlProps)} />;
    case ChartPropertyTypes.SelectMulti:
      return <SelectControl {...(props as SelectStaticControlProps)} allowMultiple />;
    case ChartPropertyTypes.SelectMultiAsync:
      return <SelectControl {...(props as SelectAsyncControlProps)} allowMultiple />;
    case ChartPropertyTypes.TaggedText:
      return <TaggedTextControl {...(props as TaggedTextControlProps)} />;
    default:
      // console.warn(`ChartProperty.Control() – Unknown Control type called: ${type}`);
      return null;
  }
};
