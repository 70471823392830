import {
  EventResultFieldTypes,
  EventResult,
} from 'src/components/forms/schemas/eventResult/eventResult.d';
import { multilineStringDef } from 'src/components/forms/schemas/util';

export default {
  type: EventResultFieldTypes.OBJECT,
  title: 'Case Manager: Intake',
  properties: {
    emergencyReferral: {
      type: EventResultFieldTypes.STRING,
      title: 'Emergency Referral?',
      enum: ['Yes', 'No', 'Other'],
      allowOther: true,
    },
    reasonsForReferral: multilineStringDef,
    pastSuccessfulInterventions: multilineStringDef,
    patientStrengths: multilineStringDef,
    notes: multilineStringDef,
  },
} as EventResult;
