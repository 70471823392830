import type { ClassNameMap } from '@material-ui/styles';
import React from 'react';
import * as Yup from 'yup';

import DateControl from 'src/components/forms/controls/date';
import FileControl from 'src/components/forms/controls/file';
import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import type {
  ChartDocument,
  ChartDocumentType,
} from 'src/components/pages/patientShow/documents/types';

const TYPES: Array<{ label: string; value: ChartDocumentType }> = [
  { label: 'Drug Test (OFT/UDS)', value: 'drug_test' },
  { label: 'Id Card', value: 'id_card' },
  { label: 'Insurance Card', value: 'insurance_card' },
  { label: 'Insurance Notices', value: 'insurance_notices' },
  { label: 'Lab Order', value: 'lab_order' },
  { label: 'Lab Results', value: 'lab_results' },
  { label: 'Legal Documents', value: 'legal_documents' },
  { label: 'Outside Record', value: 'outside_record' },
  { label: 'Prior Authorization', value: 'prior_authorization' },
  { label: 'Profile Photo', value: 'profile_photo' },
  { label: 'ROI', value: 'roi' },
  { label: 'RX Backordered', value: 'rx_backordered' },
  { label: 'RX Clarification', value: 'rx_clarification' },
  { label: 'RX Refill Request', value: 'rx_refill_request' },
  { label: 'Other', value: 'other' },
];

const VALIDATOR = Yup.object().shape({
  file: Yup.object().nullable().required(),
  title: Yup.string().nullable(),
  type: Yup.string()
    .oneOf([null, ...TYPES.map(type => type.value)])
    .nullable(),
  date: Yup.string().nullable().required(),
});

const EditChartDocument: React.VFC<{
  classes: ClassNameMap;
  item: ChartDocument;
  onCancel: () => void;
  onSave: (values: Partial<ChartDocument>) => void;
}> = ({ item, ...props }) => (
  <EditForm {...props} item={item} validationSchema={VALIDATOR}>
    {!item.id && (
      <Field name="file" component={FileControl} accept=".pdf" label="File (PDF only)*" />
    )}
    <Field name="title" component={TextControl} label="Title" />
    <Field name="type" component={SelectControl} label="Type" options={TYPES} />
    <Field name="date" component={DateControl} label="Date*" />
  </EditForm>
);

export default EditChartDocument;
