import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

import { CompletedVisitProgress } from 'src/dropInClinic/completedVisits/CompletedVisitProgress';
import { CompletedVisitTable } from 'src/dropInClinic/completedVisits/CompletedVisitsTable';
import { useCompletedVisitStatistics } from 'src/dropInClinic/hooks/useCompletedVisitStatistics';
import { useCompletedVisits } from 'src/dropInClinic/hooks/useCompletedVisits';

export const CompletedVisits = () => {
  const { completedVisits, error } = useCompletedVisits();
  const statisticItems = useCompletedVisitStatistics(completedVisits);

  if (error) {
    return <div>Error loading completed visits: {error.message}</div>;
  }

  if (!completedVisits) {
    return <div>Loading completed visits</div>;
  }

  return (
    <>
      <CompletedVisitProgress data={statisticItems} />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Completed Visits</AccordionSummary>
        <AccordionDetails>
          <CompletedVisitTable completedVisits={completedVisits} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
