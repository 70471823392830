import { ApolloError } from 'apollo-client';
import { parseISO } from 'date-fns';
import isNil from 'lodash/isNil';
import negate from 'lodash/negate';

import { ENCOUNTER_SUBMISSION_QUERY } from 'src/claims/useEncounterSubmissions.gql';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { SubmissionWhereInput } from 'src/generated/gql/graphql';

type EncounterSubmission = {
  eventId: string;
  state: string;
  errors: string | null;
  createdAt: Date;
};

type EncounterSubmissionsResponse =
  | {
      encounterSubmissions: EncounterSubmission[];
      error: undefined;
    }
  | {
      encounterSubmissions: null;
      error: ApolloError | undefined;
    };

export function useEncounterSubmissions(where: SubmissionWhereInput): EncounterSubmissionsResponse {
  const { data, error } = useTypedSWR(
    Object.values(where).every(negate(isNil)) ? [ENCOUNTER_SUBMISSION_QUERY, { where }] : null,
  );
  if (!data?.claimsEncounterSubmissions) {
    return { error, encounterSubmissions: null };
  }

  return {
    error: undefined,
    encounterSubmissions: data.claimsEncounterSubmissions.map(submission => ({
      eventId: submission.eventId,
      state: submission.state,
      errors: submission.errors,
      createdAt: parseISO(submission.createdAt),
    })),
  };
}
