import Typography from '@material-ui/core/Typography';
import { format, parseISO } from 'date-fns';
import React from 'react';

import { multilineStringDef } from 'src/components/forms/schemas/util';

export default {
  type: 'object',
  title: 'Prescriber: Subjective (Reengagement)',
  properties: {
    chiefComplaint: {
      type: 'object',
      title: 'Chief Complaint',
      renderer: {
        component: ({ model }) => {
          const {
            interestedInSuboxone,
            interestedInAudMedications,
            followUpSuboxone,
            followUpAudMedication,
            other,
            otherNotes,
          } = model.chiefComplaint;
          return (
            <Typography variant="body2">
              {!!interestedInSuboxone && <div>Interested in starting Suboxone: Yes</div>}
              {!!interestedInAudMedications && <div>Interested in AUD medications: Yes</div>}
              {!!followUpSuboxone && <div>Follow up Suboxone: Yes</div>}
              {!!followUpAudMedication && <div>Follow up AUD medication: Yes</div>}
              {!!other && !!otherNotes && <div>Other: {otherNotes}</div>}
            </Typography>
          );
        },
      },
      properties: {
        interestedInSuboxone: {
          type: 'boolean',
          title: 'Interested in starting Suboxone',
        },
        interestedInAudMedications: {
          type: 'boolean',
          title: 'Interested in AUD medications',
        },
        followUpSuboxone: {
          type: 'boolean',
          title: 'Follow up Suboxone',
        },
        followUpAudMedication: {
          type: 'boolean',
          title: 'Follow up AUD medication',
        },
        other: {
          type: 'boolean',
          title: 'Other',
        },
        otherNotes: {
          type: 'string',
          title: 'Other Notes',
          dependsOn: 'other',
          displayOnDependent: other => other,
          changeOnDependent: (currentValue, other) => (other ? currentValue : ''),
          uniforms: {
            type: 'string',
          },
        },
      },
    },
    previousRx: {
      title: 'Previous Rx',
      ...multilineStringDef,
    },
    lastClinicianVisit: {
      type: 'string',
      title: 'Last Clinician visit',
      format: 'date',
      renderer: {
        component: ({ model, classes }) => (
          <Typography variant="body2" className={classes.itemDetails}>
            <span>{format(parseISO(model.lastClinicianVisit), 'PP')}</span>
          </Typography>
        ),
      },
    },
    intervalHistory: {
      title: 'Interval history since last visit',
      ...multilineStringDef,
    },
    usage: {
      type: 'object',
      properties: {
        recentSubstanceUse: {
          type: 'object',
          properties: {},
          taggedText: true,
          renderer: {
            component: ({ model, classes }) => (
              <div>
                Recent substance use:{' '}
                <Typography variant="body2" className={classes.multiLineText}>
                  {model.recentSubstanceUse.text}
                </Typography>
                <br />
              </div>
            ),
          },
          hashTags: [
            'Denies use of other opioids.',
            'Denies using other substances or medications.',
          ],
          title: 'Recent substance use',
        },
        reportsIvDrugUse: {
          type: 'object',
          title: 'Reports IV drug use',
          format: 'selectable',
          renderer: {
            component: ({ model, classes }) => {
              const {
                selectionKey,
                additional,
              }: {
                selectionKey: string;
                additional: {
                  concernWithUse: string;
                  notes: string;
                };
              } = model.reportsIvDrugUse;

              // casing for this "yes" must match the one in concernWithUse.enum
              const concernedWithUse = additional?.concernWithUse === 'Yes';
              const hasNotes = !!additional?.notes;
              return (
                <>
                  {!!selectionKey && (
                    // casing for this "yes" must match the keys in reportsIvDrugUse.properties
                    <span>Reports IV drug use: {selectionKey === 'yes' ? 'Yes' : 'No'}</span>
                  )}
                  {(concernedWithUse || hasNotes) && (
                    <div className={classes.itemDetails}>
                      {concernedWithUse && (
                        <span>
                          Concern for infections or complications related to use:{' '}
                          {additional.concernWithUse}
                        </span>
                      )}
                      <span />
                      {additional.notes && (
                        <Typography variant="body2" className={classes.multiLineText}>
                          {additional.notes}
                        </Typography>
                      )}
                    </div>
                  )}
                </>
              );
            },
          },
          properties: {
            yes: {
              type: 'object',
              properties: {
                concernWithUse: {
                  type: 'string',
                  title: 'Concern for infections or complications related to use',
                  enum: ['Yes', 'No'],
                },
                notes: multilineStringDef,
              },
            },
            no: true,
          },
        },
        previousMonthOpioidUsageDays: {
          type: 'integer',
          title: 'Previous month opioid usage (days)',
          renderer: {
            component: ({ model }) => (
              <p>Previous month opioid usage (days): {model.previousMonthOpioidUsageDays}</p>
            ),
          },
          tooltip:
            '"Of the last 30 days, how many times have you used opioids (other than any prescribed medications)?"',
          uniforms: {
            fullWidth: true,
            margin: 'dense',
          },
        },
      },
    },
    notes: multilineStringDef,
  },
};
