import gql from 'graphql-tag';

export const TEAM_ACTIVITY = gql`
  fragment teamActivity on TeamActivity {
    latestCompletedVisit {
      start
      status
      scheduleChangeReason
      scheduleChangeNotes
    }
    latestMissedVisit {
      start
      status
      scheduleChangeReason
      scheduleChangeNotes
    }
    nextVisit {
      start
    }
  }
`;
