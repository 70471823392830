import {
  add,
  isWithinInterval,
  isEqual as isDatesEqual,
  isValid,
  format,
  parseISO,
} from 'date-fns';
import upperFirst from 'lodash/upperFirst';

import { getPreferredFullName } from 'src/shared/stores/resource';
import type { UserNames } from 'src/shared/stores/resource';
import {
  EVENT_STATUSES,
  UNSCHEDULED_EVENT_STATUSES,
  SCHEDULE_CHANGE_REASONS,
} from 'src/shared/util/events';
import TimeSpan from 'src/stores/events/types/TimeSpan';

export function timeSpansOverlap(ts1: TimeSpan, ts2: TimeSpan) {
  const ts1Start = ts1.start instanceof Date ? ts1.start : parseISO(ts1.start);
  const ts2Start = ts2.start instanceof Date ? ts2.start : parseISO(ts2.start);
  const ts1End = add(ts1Start, { minutes: ts1.duration || undefined });
  const ts2End = add(ts2Start, { minutes: ts2.duration || undefined });
  return (
    isDatesEqual(ts1Start, ts2Start) ||
    isWithinInterval(ts1Start, { start: ts2Start, end: ts2End }) ||
    isWithinInterval(ts2Start, { start: ts1Start, end: ts1End })
  );
}

const isUnscheduled = event => UNSCHEDULED_EVENT_STATUSES.includes(event.status);

const eventNotesForUnscheduledEvents = (event: NotesEvent) => {
  const { scheduleChangeNotes, scheduleChangeReason, status } = event;

  let eventNotes = '';
  if (status) {
    eventNotes += `${upperFirst(status)}`;
  }

  if (scheduleChangeReason) {
    eventNotes += ` (${SCHEDULE_CHANGE_REASONS[scheduleChangeReason].label})`;
  }

  if (scheduleChangeNotes) {
    eventNotes += `: ${scheduleChangeNotes}`;
  }

  return eventNotes;
};

const isCompleted = event =>
  event.status === EVENT_STATUSES.COMPLETED &&
  event.signedAt &&
  (event.signedBy || event.signedByDisplay);

const eventNotesForCompletedEvent = (event: NotesEvent) => {
  const { appointmentNotes, signedAt, signedBy, signedByDisplay } = event;
  const firstName = signedBy?.firstName || signedByDisplay?.firstName;
  const lastName = signedBy?.lastName || signedByDisplay?.lastName;

  let eventNotes = '';
  if (signedAt) {
    eventNotes += `Signed & Completed on ${format(
      signedAt instanceof Date ? signedAt : parseISO(signedAt),
      'MMM d, h:mma',
    )} by ${firstName} ${lastName}`;
  }

  if (appointmentNotes) {
    eventNotes += `: ${appointmentNotes}`;
  }

  return eventNotes;
};

const generateEventNotesFromStatus = (event: NotesEvent) => {
  const { status } = event;
  if (!status) {
    return null;
  }
  if (isUnscheduled(event)) {
    return eventNotesForUnscheduledEvents(event);
  }
  if (isCompleted(event)) {
    return eventNotesForCompletedEvent(event);
  }
  return upperFirst(status);
};

interface NotesEvent {
  updatedAt: Date | null;
  updatedByDisplay: UserNames | null;
  status: string | null;
  scheduleChangeNotes: string | null;
  scheduleChangeReason: string | null;
  appointmentNotes: string | null;
  signedAt: Date | null;
  signedBy?: UserNames | null;
  signedByDisplay: UserNames | null;
}

const generateLastUpdatedNote = (event: NotesEvent) => {
  const at = event.updatedAt;
  const by = event.updatedByDisplay;

  if (!by && !at) {
    return null;
  }

  const updateContent: string[] = [];

  if (by) {
    updateContent.push(`by ${getPreferredFullName(by)}`);
  }

  if (at) {
    let atDate: Date | string = at;
    if (typeof atDate === 'string') {
      atDate = parseISO(atDate);
    }
    if (isValid(atDate)) {
      updateContent.push(format(atDate, 'MMM d, p'));
    }
  }

  return updateContent.length ? `Last updated ${updateContent.join(' ')}` : null;
};

export const generateEventNotes = (event: NotesEvent) => {
  return [generateEventNotesFromStatus(event), generateLastUpdatedNote(event)]
    .filter(x => x)
    .join('; ');
};
