import gql from 'graphql-tag';

export type PebblesCountGqlResult = {
  pebblesCount: number;
};

export const PEBBLE_COUNT_QUERY = gql`
  query pebblesCount($participantId: ID!) {
    pebblesCount(where: { status_nin: [completed, wont_do], participant_some: $participantId })
  }
`;

export type PatientPebbleUpdatedSubscriptionResult = {
  patientPebbleUpdated: {
    patientId: string;
  };
};

export const SUBSCRIBE_TO_PATIENT_PEBBLE_UPDATED = gql`
  subscription {
    patientPebbleUpdated {
      patientId
    }
  }
`;

export const UPDATE_PEBBLE = gql`
  mutation UpdatePebble($data: PebbleInput!, $id: ID!) {
    updatePebble(data: $data, id: $id) {
      id
    }
  }
`;
