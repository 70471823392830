import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import React from 'react';
import { AutoFields, SubmitField } from 'uniforms-material';

import AutoField from 'src/components/forms/fields/autoField';
import ServerValidatedAutoForm from 'src/components/forms/fields/serverValidatedAutoForm';
import Tooltip from 'src/components/general/Tooltip';
import { DISABLED_TOOLTIP } from 'src/shared/util/events';
import { colors } from 'src/util/colors';

const SubmitButtons = ({ className, classes, handleSubmit, handleCancel, savingDisabled }) => (
  <div className={className}>
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      className={classes.button}
      onClick={handleCancel}
      tabIndex="-1"
    >
      Cancel
      <CancelIcon className={classes.rightIcon} />
    </Button>
    <SubmitField
      variant="contained"
      color="primary"
      size="small"
      className={classes.button}
      onClick={handleSubmit}
      disabled={savingDisabled}
    >
      Save
      <SaveIcon className={classes.rightIcon} />
    </SubmitField>
  </div>
);

class EditableModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
    };

    this.ref = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setEditing = this.setEditing.bind(this);
  }

  componentDidMount() {
    const {
      name,
      rootStore: {
        routerStore: {
          routerState: {
            queryParams: { scrollTo },
          },
        },
      },
    } = this.props;

    if (name && scrollTo && name === scrollTo) {
      this.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    this.setEditing(this.props.initializeEditing);
  }

  handleChange(key, value) {
    if (this.props.handleChange) {
      this.props.handleChange(key, value);
    }
  }

  handleStartEdit() {
    this.setEditing(true);
  }

  handleSubmitClick(event) {
    return this.props.onSubmitClick?.(event, this.formRef.getModel(), () => this.formRef.submit());
  }

  handleSave(result) {
    return this.props.onSubmit?.(result);
  }

  handleSaveSuccess() {
    this.setEditing(false);
    if (this.props.handleSaveSuccess) {
      this.props.handleSaveSuccess();
    }
  }

  handleDelete() {
    this.setEditing(false);
    return this.props.onDelete?.();
  }

  handleCancel() {
    this.setEditing(false);
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      if (['text', 'number', 'tel'].includes(event.target.type)) {
        event.preventDefault();
      } else if (event.target.type === 'checkbox') {
        event.preventDefault();
        event.target.click();
      } else if (event.target.matches?.('[class*="MuiSelect-select"]')) {
        // Make pressing enter in a select dropdown the same as pressing down debugger;
        event.target.click();
      }
    }
  }

  setEditing(value) {
    this.setState({ isEditing: value });
    this.props.rootStore.setEditing(this.props.name, value || false);
  }

  render() {
    const {
      children,
      classes,
      model,
      name,
      schema,
      title,
      hideHeader,
      onDelete,
      disableEdit = false, // render object details for viewing; not a form!
      savingDisabled = false, // render as a form, but disable save buttons
      disableTooltip = DISABLED_TOOLTIP,
    } = this.props;

    // Instead of having to manually configure autoFocus in the first property
    // for every schema rendered with this component, do it here.
    if (schema.schema.properties) {
      const firstPropertyKey = Object.keys(schema.schema.properties)[0];
      schema.schema.properties[firstPropertyKey].autoFocus = true;
    }

    return (
      <div ref={this.ref} className={classes.container} data-testid={`editable-model-${name}`}>
        {this.state.isEditing ? (
          <div className={classes.formContainer}>
            <ServerValidatedAutoForm
              formRef={el => {
                // Get the Uniforms <AutoForm> for onSubmitClick handlers
                this.formRef = el;
              }}
              model={model ?? {}}
              schema={schema}
              onChange={this.handleChange}
              onSubmit={this.handleSave}
              onSubmitSuccess={this.handleSaveSuccess}
              showInlineError
              onKeyDown={this.handleKeyDown}
            >
              {!hideHeader && (
                <div className={classes.header}>
                  <Typography className={classes.heading} variant="h6">
                    {title || schema.schema.title}
                  </Typography>
                  <SubmitButtons
                    className={classNames(classes.cancelAndSubmit, classes.topButtons)}
                    classes={classes}
                    handleSubmit={this.handleSubmitClick}
                    handleCancel={this.handleCancel}
                    savingDisabled={savingDisabled}
                  />
                </div>
              )}
              <AutoFields className={classes.editableFields} autoField={AutoField} />
              <SubmitButtons
                className={classes.bottomButtons}
                classes={classes}
                handleSubmit={this.handleSubmitClick}
                handleCancel={this.handleCancel}
                savingDisabled={savingDisabled}
              />
            </ServerValidatedAutoForm>
          </div>
        ) : (
          <div className={classes.formContainer}>
            <div className={classes.header}>
              <Typography className={classes.heading} variant="h6">
                {title || schema.schema.title}
              </Typography>
              <Tooltip title={disableEdit ? disableTooltip : ''}>
                <span>
                  <IconButton
                    aria-label="Edit"
                    disabled={disableEdit}
                    className={classNames(classes.clickableIcon, classes.topButtons)}
                    onClick={this.handleStartEdit}
                  >
                    <EditIcon />
                  </IconButton>
                  {onDelete && (
                    <IconButton
                      disabled={disableEdit}
                      className={classNames(classes.clickableIcon, classes.topButtons)}
                      onClick={this.handleDelete}
                      tabIndex="-1"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </span>
              </Tooltip>
            </div>
            {children}
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  '@global': {
    '[class*="MuiInputLabel-shrink"]': {
      textTransform: 'uppercase',
    },
  },
  cancelAndSubmit: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
  },
  clickableIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 18,
    '&:hover': {
      cursor: 'pointer',
    },
    padding: 5,
  },
  container: {
    marginBottom: 30,
  },
  heading: {
    color: colors.taupe,
    flex: 2,
    paddingRight: 10,
  },
  header: {
    alignItems: 'flex-end',
    fontSize: 20,
    height: 40,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: colors.taupe,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  editableFields: {
    '& > *': {
      ':not([class*="MuiCheckbox"])': {
        marginTop: 20,
      },
    },
    '& [class*="MuiCheckbox"]': {
      marginTop: -theme.spacing(1.3),
      marginBottom: -theme.spacing(1.3),
      marginLeft: theme.spacing(1),
    },
    '& [class*="MuiRadio"]': {
      marginLeft: theme.spacing(1),
    },
  },
  topButtons: {
    paddingBottom: 5,
  },
  bottomButtons: {
    paddingTop: 20,
  },
  button: {
    paddingBottom: 5,
    '&:not(:first-child)': {
      marginLeft: 5,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  '@media print': {
    topButtons: {
      display: 'none',
    },
  },
});

export default withStyles(styles)(inject('rootStore')(EditableModel));
