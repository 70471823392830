import { Button, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { requestTypeMap } from 'src/dropInClinic/RequestType';
import { useFormStyles } from 'src/dropInClinic/queueSettings/form.styles';

type DurationFormProps = {
  requestType: string;
  duration: number;
  onSubmit: (e: React.FormEvent) => Promise<void>;
};

const DurationForm: React.FC<DurationFormProps> = ({ requestType, duration, onSubmit }) => {
  const classes = useFormStyles();
  const [value, setValue] = useState(duration);

  useEffect(() => {
    setValue(duration);
  }, [duration]);

  const handleChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  return (
    <form key={`${requestType}`} onSubmit={onSubmit} className={classes.form}>
      <TextField
        name={`${requestType}`}
        value={value}
        onChange={handleChange}
        label={`${requestTypeMap[requestType] ?? requestType}`}
      />
      <Button variant="contained" color="primary" type="submit" className={classes.button}>
        Update
      </Button>
    </form>
  );
};

export default DurationForm;
