import gql from 'graphql-tag';

import { HistoricalValue } from 'src/nightingale/components/ChartPropertyHistory/types';

export const GET_PROPERTY_HISTORY = gql`
  query GetPropertyHistory($patientId: ID!, $propertyName: String!, $interactionReferenceId: ID) {
    propertyHistory(
      patientId: $patientId
      propertyName: $propertyName
      interactionReferenceId: $interactionReferenceId
    ) {
      action
      createdAt
      editorName
      interactionReferenceId
      isEmpty
      notes
      value
    }
  }
`;

export type GetPropertyHistoryVariables = {
  patientId: string;
  propertyName: string;
  interactionReferenceId?: string;
};

export type GetPropertyHistoryResult = { propertyHistory: HistoricalValue[] };
