import { Button, Tooltip } from '@material-ui/core';
import ColorizeIcon from '@material-ui/icons/Colorize';
import classnames from 'classnames';
import React from 'react';

import { useButtonStyles } from 'src/components/general/PatientMenu/highlights/useButtonStyles';
import { useSWRPatientHighlights } from 'src/components/general/PatientMenu/highlights/useSWRPatientHighlights';
import useTooltipStyles from 'src/components/general/PatientMenu/useTooltipStyles';
import { DspStatus } from 'src/drugScreening/types';

const ScreeningStatusLabelMap = {
  [DspStatus.Complete]: 'complete',
  [DspStatus.OnTrack]: 'on track',
  [DspStatus.AtRisk]: 'at risk',
  [DspStatus.RegulatoryPause]: 'reg. pause',
};

const ScreeningStatusColorMap = {
  [DspStatus.Complete]: 'neutral',
  [DspStatus.OnTrack]: 'success',
  [DspStatus.AtRisk]: 'warning',
  [DspStatus.RegulatoryPause]: 'error',
};

export const DrugScreeningStatusLabel = ({ screeningStatus, defaultLabel = '+' }) => {
  const styles = useButtonStyles();

  const shortText = ScreeningStatusLabelMap[screeningStatus] ?? defaultLabel;
  const statusClass = ScreeningStatusColorMap[screeningStatus] ?? styles.neutral;

  return (
    <span className={styles[statusClass] ?? styles.neutral} data-dsp-status={screeningStatus}>
      {shortText}
    </span>
  );
};

const DrugScreeningPeriodStatus: React.FC<{ patientId: string }> = ({ patientId }) => {
  const { data } = useSWRPatientHighlights(patientId);
  const styles = useButtonStyles();
  const tooltipStyles = useTooltipStyles();

  if (!data?.patientHighlights) {
    return null; // loading
  }

  if (!data.patientHighlights.hasOudDiagnosis) {
    return null; // DSP is only relevant when patient has OUD diagnosis
  }

  const label = data.patientHighlights.dspStatus?.label ? (
    `Drug screening status: ${data.patientHighlights.dspStatus?.label}`
  ) : (
    <em>Click to add drug screening status</em>
  );
  const value = data.patientHighlights.dspStatus?.value;

  const shortText = value ? ScreeningStatusLabelMap[value] : '+';

  const statusClass = value ? ScreeningStatusColorMap[value] : 'neutral';

  const buttonClassNames = [styles.button, styles[statusClass]];

  return (
    <Tooltip
      title={label}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: classnames(tooltipStyles.tooltip),
      }}
    >
      <a href={`/patients/${patientId}/overview#drugScreeningPlan`}>
        <Button
          className={classnames(buttonClassNames)}
          fullWidth
          size="small"
          startIcon={<ColorizeIcon data-testid="dsp-status-icon" />}
          variant="outlined"
        >
          {shortText}
        </Button>
      </a>
    </Tooltip>
  );
};

export default DrugScreeningPeriodStatus;
