import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { BaseCSSProperties } from '@material-ui/styles/withStyles';
import { observer } from 'mobx-react';
import React from 'react';
import * as Yup from 'yup';

import CheckboxControl from 'src/components/forms/controls/checkbox';
import DateControl from 'src/components/forms/controls/date';
import ImageFileControl from 'src/components/forms/controls/imageFile';
import PhoneControl from 'src/components/forms/controls/phone';
import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import { states } from 'src/components/forms/schemas/definitions';
import { nestedStyle } from 'src/components/forms/schemas/util';
import { TIMEZONES } from 'src/util/timezones';

// TODO - why does this have to be included in every file?
Yup.setLocale({
  mixed: {
    required: 'required',
  },
});

const TEAM_ROLE_OPTIONS = [
  { label: 'Clinician', value: 'clinician' },
  { label: 'Care Advocate', value: 'care_advocate' },
  { label: 'Peer Coach', value: 'peer_coach' },
  { label: 'Billing Specialist', value: 'billing_specialist' },
  { label: 'Case Manager', value: 'case_manager' },
  { label: 'Registered Nurse', value: 'registered_nurse' },
  { label: 'Enrollment Specialist', value: 'enrollment_specialist' },
  { label: 'Navigator', value: 'navigator' },
  { label: 'Other Staff', value: 'other_staff' },
];

const STATE_OPTIONS = states.map(state => ({ label: state, value: state }));

const VALIDATOR = Yup.object().shape({
  firstName: Yup.string().nullable().required(),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().nullable().required(),
  email: Yup.string().nullable().required(),
  dob: Yup.date().nullable().required(),
  phone: Yup.string()
    .nullable()
    .matches(/^\d{10}$/, 'invalid phone number'),
  address: Yup.object()
    .shape({
      street1: Yup.string().nullable(),
      street2: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable().oneOf(states),
      zip: Yup.string().nullable(),
    })
    .nullable(),
  timezone: Yup.string().nullable().required(),
  teamRole: Yup.string().nullable().required(),
  npi: Yup.string().nullable(),
  taxId: Yup.string().nullable(),
  taxonomyCode: Yup.string().nullable(),
  isInactive: Yup.bool().nullable(),
  bio: Yup.string().nullable(),
  patientFacingDisplayName: Yup.string().nullable(),
});

const EditProvider = props => {
  const classes = useStyles();
  return (
    <EditForm {...props} validationSchema={VALIDATOR}>
      <Field
        name="firstName"
        component={TextControl}
        label="First Name *"
        data-testid="provider-firstname"
      />
      <Field
        name="middleName"
        component={TextControl}
        label="Middle Name"
        data-testid="provider-middlename"
      />
      <Field name="lastName" component={TextControl} label="Last Name *" />
      <Field name="email" component={TextControl} label="Email *" />
      <Field name="dob" component={DateControl} label="Birthday *" />
      <Field name="phone" component={PhoneControl} label="Phone" />
      <InputLabel shrink className={classes.label}>
        Address
      </InputLabel>
      <div className={classes.nested}>
        <Field name="address.street1" component={TextControl} label="Street 1" />
        <Field name="address.street2" component={TextControl} label="Street 2" />
        <Field name="address.city" component={TextControl} label="City" />
        <Field
          name="address.state"
          component={SelectControl}
          label="State"
          options={STATE_OPTIONS}
        />
        <Field name="address.zip" component={TextControl} label="Zip" />
      </div>
      <Field name="npi" component={TextControl} label="NPI" />
      <Field name="taxId" component={TextControl} label="Tax ID" />
      <Field name="taxonomyCode" component={TextControl} label="Taxonomy Code" />
      <Field name="timezone" component={SelectControl} label="Timezone *" options={TIMEZONES} />
      <Field
        name="teamRole"
        component={SelectControl}
        label="Role *"
        options={TEAM_ROLE_OPTIONS}
        data-testid="team-role"
      />
      <Field name="pronouns" component={TextControl} label="Pronouns (e.g. They/Them)" />
      <Field
        name="patientFacingDisplayName"
        component={TextControl}
        label="Patient-Facing Display Name"
      />
      <Field name="avatar" component={ImageFileControl} label="Avatar" />
      <Field
        name="bio"
        component={TextControl}
        multiline
        inputProps={{ maxLength: 5000 }}
        label="Bio"
      />
      <Field name="isInactive" component={CheckboxControl} label="Inactive" />
    </EditForm>
  );
};

const useStyles = makeStyles({
  nested: {
    ...(nestedStyle as BaseCSSProperties),
    borderLeftStyle: 'solid',
    marginTop: 16,

    '& > *': {
      marginTop: 16,
    },

    '& :first-child': {
      marginTop: 0,
    },
  },
  label: {
    marginTop: 16,
  },
});

export default observer(EditProvider);
