import isEqual from 'lodash/isEqual';
import React, { useState, useEffect } from 'react';

import { isEmptyItem } from 'src/nightingale/components/ChartProperty/controls/List//ListControl.utils';
import { ListControlProps } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.types';
import { ListControlEdit } from 'src/nightingale/components/ChartProperty/controls/List/ListControlEdit';
import { ListControlView } from 'src/nightingale/components/ChartProperty/controls/List/ListControlView';

export type { ListControlProps } from 'src/nightingale/components/ChartProperty/controls/List/ListControl.types';

/**
 * List Control component
 */
export const ListControl: React.FC<ListControlProps> = ({
  autoFocus,
  conditions,
  disabled,
  hasEmptyValue,
  interactionId,
  isRequired,
  label,
  listOrder,
  name,
  onChangeMode,
  onChangeValue,
  onError,
  properties,
  value = [],
}) => {
  const [internalValue, setInternalValue] = useState(internalValueFromValue(value));
  const [editingItemId, setEditingItemId] = useState<string | null>(null);

  useEffect(() => {
    const newInternalValue = internalValueFromValue(value);
    // Keep up with changes made to the value from outside this component (namely the history modal).
    // But first, check if the values are equal, which prevents broken circular logic where:
    // * User modifies `internalValue`
    // * That propagates out via `onChangeValue`
    // * We get re-rendered with a new `value` and close the editor while user is still editing
    if (!isEqual(internalValue, newInternalValue)) {
      setInternalValue(newInternalValue);
      // When the value is updated from outside this component, it supercedes whatever edit we were in
      // the middle of, so get out of edit mode and revert back to viewing the full list.
      setEditingItemId(null);
    }
  }, [value]);

  const isEditing = editingItemId !== null;

  /**
   * Keeps track of whether or not the editor has been opened
   *
   * We need to ensure that when the editor is closed, we return focus to the control (specifically,
   * the "Add item" button). Otherwise, we can lose focus from the entire ChartElement, resulting in
   * an unintended state where its data cannot be saved unless focus is explicitly given first. See
   * CX-343 for more.
   */
  const [wasEditing, setWasEditing] = useState(false);

  const chooseEditingItem = (itemId: string) => {
    setEditingItemId(itemId);
    if (itemId !== null) {
      setWasEditing(true);
    }
  };

  const updateInternalValue = (newValue: typeof value) => {
    setInternalValue(newValue);
    const nonEmptyValue = newValue.filter(item => !isEmptyItem(item));
    if (!isEqual(nonEmptyValue, value) && onChangeValue) {
      onChangeValue(nonEmptyValue);
    }
  };

  useEffect(() => {
    // Let ancestors know we changed modes.
    if (onChangeMode) onChangeMode(isEditing);
  }, [isEditing]);

  return isEditing ? (
    <ListControlEdit
      conditions={conditions}
      disabled={disabled}
      editingItemId={editingItemId}
      hasEmptyValue={hasEmptyValue}
      interactionId={interactionId}
      isRequired={isRequired}
      label={label}
      name={name}
      onError={onError}
      properties={properties}
      setEditingItemId={chooseEditingItem}
      setInternalValue={updateInternalValue}
      value={internalValue}
    />
  ) : (
    <ListControlView
      autoFocus={wasEditing || autoFocus}
      conditions={conditions}
      disabled={disabled}
      hasEmptyValue={hasEmptyValue}
      interactionId={interactionId}
      isRequired={isRequired}
      label={label}
      listOrder={listOrder}
      name={name}
      properties={properties}
      setEditingItemId={chooseEditingItem}
      setInternalValue={updateInternalValue}
      value={internalValue}
    />
  );
};

function internalValueFromValue(
  value: ListControlProps['value'],
): NonNullable<ListControlProps['value']> {
  return value || [];
}
