"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractReadableValue = exports.convertChartPropertiesToContextParameters = exports.__test__ = void 0;
var _dateFnsTz = require("date-fns-tz");
var _imask = _interopRequireDefault(require("imask"));
var _fromPairs = _interopRequireDefault(require("lodash/fromPairs"));
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _keyBy = _interopRequireDefault(require("lodash/keyBy"));
var _lowerCase = _interopRequireDefault(require("lodash/lowerCase"));
var _startCase = _interopRequireDefault(require("lodash/startCase"));
var _chartDefinitionTypes = require("@boulder-package/chart-definition-types");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const SELECT_OPTION_OTHER = 'other';
const titleCase = str => (0, _startCase.default)((0, _lowerCase.default)(str));
const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const extractReadableValue = (type, value, context) => {
  switch (type) {
    case _chartDefinitionTypes.ChartPropertyType.Select:
    case _chartDefinitionTypes.ChartPropertyType.SelectAsync:
      return value?.label || value?.value || '';
    case _chartDefinitionTypes.ChartPropertyType.CheckboxList:
    case _chartDefinitionTypes.ChartPropertyType.SelectMulti:
    case _chartDefinitionTypes.ChartPropertyType.SelectMultiAsync:
      return Array.isArray(value) ? value.map(option => option?.label || option?.value || '').join(', ') : '';
    case _chartDefinitionTypes.ChartPropertyType.Boolean:
      if (typeof value === 'boolean') return value ? 'Yes' : 'No';
      return '';
    case _chartDefinitionTypes.ChartPropertyType.Phone:
      return formatPhone(value || '');
    case _chartDefinitionTypes.ChartPropertyType.Datetime:
      return (0, _dateFnsTz.formatInTimeZone)(value, context?.timezone || localZone, "MMM d, yyyy 'at' p (zz)");
    default:
      if (typeof value === 'number') return value.toString();
      if (typeof value === 'string') return value;
      return '';
  }
};
exports.extractReadableValue = extractReadableValue;
const phoneFormatter = _imask.default.createMask({
  mask: [{
    mask: '(000) 000-0000'
  }, {
    mask: /.*/
  }]
});
const formatPhone = phone => phoneFormatter.resolve(phone);
function contextChartPropertyScalarToString() {
  return this.textValue || '';
}
const getBaseChartPropertyContextParameter = ({
  label,
  name
}, {
  isEmpty,
  notes
}) => ({
  isEmpty: isEmpty ?? false,
  notes,
  propertyLabel: label || titleCase(name),
  propertyName: name
});
const convertChartPropertyScalarToContextParameter = (property, cpv) => {
  const parameter = {
    ...getBaseChartPropertyContextParameter(property, cpv),
    textValue: '',
    toString: contextChartPropertyScalarToString
  };
  if (parameter.isEmpty) return parameter;
  if ([_chartDefinitionTypes.ChartPropertyType.Select, _chartDefinitionTypes.ChartPropertyType.SelectAsync].includes(property.type)) {
    const option = (0, _isNil.default)(cpv.value) ? {
      value: cpv.value
    } : cpv.value;
    parameter.value = option.value === SELECT_OPTION_OTHER && 'otherValue' in option ? option.otherValue : option.value;
  } else {
    parameter.value = cpv.value;
  }
  parameter.textValue = extractReadableValue(property.type, cpv.value);
  return parameter;
};
const convertChartPropertyToContextParameter = (property, value) => {
  if (!property) return null;
  switch (property.type) {
    case _chartDefinitionTypes.ChartPropertyType.List:
      return {
        ...getBaseChartPropertyContextParameter(property, value),
        items: value.isEmpty ? [] : (value.value || []).map(listValues => property.properties.map(childProperty => ({
          [childProperty.name]: convertChartPropertyScalarToContextParameter(childProperty, {
            value: listValues[childProperty.name]
          })
        })).reduce((acc, prop) => ({
          ...acc,
          ...prop
        }), {}))
      };
    case _chartDefinitionTypes.ChartPropertyType.Object:
      return {
        ...getBaseChartPropertyContextParameter(property, value),
        properties: property.properties.map(childProperty => ({
          [childProperty.name]: convertChartPropertyScalarToContextParameter(childProperty, {
            isEmpty: value.isEmpty,
            value: (value.value || {})[childProperty.name]
          })
        })).reduce((acc, prop) => ({
          ...acc,
          ...prop
        }), {})
      };
    case _chartDefinitionTypes.ChartPropertyType.TaggedText:
      return {
        ...getBaseChartPropertyContextParameter(property, value),
        value: {
          tags: value.value?.tags,
          text: value.value?.text
        },
        textValue: value.value?.text,
        toString: contextChartPropertyScalarToString
      };
    default:
      return convertChartPropertyScalarToContextParameter(property, value);
  }
};
const convertChartPropertiesToContextParameters = (properties, values) => {
  const valuesByPropertyName = (0, _keyBy.default)(values, 'propertyName');
  return (0, _fromPairs.default)(properties.map(property => [property.name, convertChartPropertyToContextParameter(property, valuesByPropertyName[property.name] || {
    value: null,
    isEmpty: false
  })]));
};
exports.convertChartPropertiesToContextParameters = convertChartPropertiesToContextParameters;
const __test__ = exports.__test__ = {
  convertChartPropertyScalarToContextParameter
};