import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import React from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import Tooltip from 'src/components/general/Tooltip';

const CopyLinkButton: React.FC<{ pebbleId: string }> = ({ pebbleId }) => {
  return (
    <Tooltip title="Copy pebble link">
      <IconButton
        onClick={() => {
          const generatedUrl = `${window.location.origin}${generateRouteUrl('pebble', {
            id: pebbleId,
          })}`;
          navigator.clipboard.writeText(generatedUrl);
        }}
      >
        <LinkIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyLinkButton;
