import { gql } from 'src/generated/gql';

export const ACTIVITY_TASKS_QUERY = gql(`
  query PatientActivityTasks($patientId: ID!) {
    tasks(patientId: $patientId) {
      taskId
      createdAt
      completedAt
      flow {
        label
        name
      }
    }
  }
`);
