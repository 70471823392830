import { parseISO } from 'date-fns';
import gql from 'graphql-tag';
import useSWR from 'swr';

import type { SubType } from 'src/events/visitTypes';
import { TimeSlot } from 'src/scheduling/types';
import { Priority } from 'src/scheduling/types/TimeSlot';

type ScheduleSuggestionsInput = {
  eventId: string;
  visitType: string;
  duration: number | null;
  includeRnFollowups: boolean;
};

type TimeSlotGQL = {
  duration: number;
  priority: Priority;
  provider: {
    id: string;
    firstName: string;
    lastName: string;
  };
  start: string;
  subType: SubType;
};

type GetSuggestedTimeSlotsGQL = {
  getSuggestedTimeSlots: TimeSlotGQL[];
};

const deserializeScheduleSuggestions = (timeSlots: TimeSlotGQL[]): TimeSlot[] => {
  return timeSlots.map(timeSlot => ({
    duration: timeSlot.duration,
    firstName: timeSlot.provider.firstName,
    lastName: timeSlot.provider.lastName,
    priority: timeSlot.priority,
    providerId: timeSlot.provider.id,
    restrictions: '',
    start: parseISO(timeSlot.start),
    subType: timeSlot.subType,
  }));
};

const GET_SUGGESTED_TIME_SLOTS_QUERY = gql`
  query getSuggestedTimeSlots(
    $subType: String!
    $eventId: String!
    $duration: Int
    $includeRnFollowups: Boolean
  ) {
    getSuggestedTimeSlots(
      subType: $subType
      eventId: $eventId
      duration: $duration
      includeRnFollowups: $includeRnFollowups
    ) {
      start
      duration
      priority
      subType
      provider {
        id
        firstName
        lastName
      }
    }
  }
`;

export const useScheduleSuggestions = (query: ScheduleSuggestionsInput) => {
  const { data, error, isValidating } = useSWR<GetSuggestedTimeSlotsGQL>([
    GET_SUGGESTED_TIME_SLOTS_QUERY,
    {
      eventId: query.eventId,
      subType: query.visitType,
      duration: query.duration,
      includeRnFollowups: query.includeRnFollowups,
    },
  ]);

  return {
    error,
    isLoading: !data,
    isValidating,
    suggestedTimeSlots: deserializeScheduleSuggestions(data?.getSuggestedTimeSlots ?? []),
  };
};
