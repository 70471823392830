import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import MaterialTable from 'material-table';
import { useRouterStore } from 'mobx-state-router';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ErrorAlert from 'src/components/general/ErrorAlert';
import ChartActivity from 'src/components/pages/patientActivity/ChartActivity';
import EventDetail from 'src/components/pages/patientActivity/EventDetail';
import { PatientActivityIcon } from 'src/components/pages/patientActivity/PatientActivityIcon';
import { PatientExportDialog } from 'src/components/pages/patientActivity/PatientExportDialog';
import PebbleDetail from 'src/components/pages/patientActivity/PebbleDetail';
import TaskDetail from 'src/components/pages/patientActivity/TaskDetail';
import ViewsButtonGroup, {
  ACTIVITY_VIEW_QUERY_PARAM,
} from 'src/components/pages/patientActivity/ViewsButtonGroup';
import { filterActivityData } from 'src/components/pages/patientActivity/filterActivityData';
import { View, ActivityType } from 'src/components/pages/patientActivity/types';
import type { ActivityData } from 'src/components/pages/patientActivity/types';
import useSWRActivityEvents from 'src/components/pages/patientActivity/useSWRActivityEvents';
import useSWRActivityPebbles from 'src/components/pages/patientActivity/useSWRActivityPebbles';
import useSWRActivityTasks from 'src/components/pages/patientActivity/useSWRActivityTasks';
import { PatientShowContext } from 'src/components/pages/patientShow/PatientShowContext';
import { ACTIVITY_VIEWS, OLD_ACTIVITY_FILTERS } from 'src/featureFlags/currentFlags';
import { FlowMappingsContextProvider } from 'src/nightingale/components/Flow/FlowMappingsContext';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';
import { InteractionKind } from 'src/nightingale/types/types';
import { getExactFullName } from 'src/shared/stores/resource';
import { UNSCHEDULED_EVENT_STATUSES } from 'src/shared/util/events';
import { getCalendarEventStyles } from 'src/util/calendar';

const PatientActivity: React.VFC<{
  patientId: string;
}> = ({ patientId }) => {
  // Look for a query param designating the activity view to display, or use the default
  const { routerState } = useRouterStore();
  const viewQueryParam: View | undefined = routerState.queryParams[ACTIVITY_VIEW_QUERY_PARAM];
  const [view, setView] = useState<View>(viewQueryParam || View.Default);
  const [finishedRenderingData, setFinishedRenderingData] = useState<boolean>(false);
  const [exportModalShowing, setExportModalShowing] = useState<boolean>(false);

  const classes = useStyles();
  const flags = useContext(FeatureFlagContext);
  const { urlFragment } = useContext(PatientShowContext);

  const { data: events, error: eventsError } = useSWRActivityEvents(patientId);
  const { data: tasks, error: tasksError } = useSWRActivityTasks(patientId);
  const { data: pebbles, error: pebblesError } = useSWRActivityPebbles(patientId);

  const isLoading = !events || !tasks || !pebbles;

  const activityData: ActivityData[] = [
    ...(events || []),
    ...(tasks || []),
    ...(pebbles || []),
  ].sort((a, b) => (a.sortDateEpoch < b.sortDateEpoch ? 1 : -1));

  const allLabels: Record<string, string> = {};
  const allProviders: Record<string, string> = {};

  if (flags[OLD_ACTIVITY_FILTERS]) {
    activityData.forEach(item => {
      allLabels[item.label] = item.label;
      if (item.attendees) {
        item.attendees.forEach(attendee => {
          allProviders[attendee.id] = getExactFullName(attendee);
        });
      }
    });
  }

  const filteredActivityData = useMemo(
    () => (flags[ACTIVITY_VIEWS] ? filterActivityData(activityData, view) : activityData),
    [activityData, view, flags[ACTIVITY_VIEWS]],
  );

  const tableRef = useRef(null);

  useEffect(() => {
    // Used to determine when to attempt automatic scrolling to a linked item. Using `isLoading` directly
    // could lead to buggy behavior, because `isLoading` could be false (all of the data is available),
    // but the data has not yet been rendered. This ensures that the data is rendered and will be found
    // when querying for the item.
    setFinishedRenderingData(!isLoading);
  }, [isLoading]);

  // Expand the row with the linked item. Scrolling to it is handled in the ChartActivity component.
  useEffect(() => {
    if (!tableRef?.current || isLoading || !urlFragment) {
      return;
    }

    const dashIndex = urlFragment.indexOf('-');
    const idFromUrl = urlFragment.slice(dashIndex + 1);
    const index = findRowIndexForUrlItem(filteredActivityData, idFromUrl);

    if (index === -1) {
      return;
    }

    // See: https://github.com/mbrn/material-table/issues/1340
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore MaterialTable types are not maintained.
    tableRef.current.onToggleDetailPanel([index], tableRef.current.props.detailPanel[0].render);
  }, [tableRef, filteredActivityData, urlFragment]);

  // Shrink things to better fit side-by-side.
  const cellStyle = { padding: '0.5vw', fontSize: '14px' };

  return (
    <>
      <div className={classes.header}>
        <PageTitle>
          Activity{' '}
          <Button onClick={() => setExportModalShowing(true)} title="Generate chart export">
            <GetAppIcon />{' '}
          </Button>
        </PageTitle>
        {flags[ACTIVITY_VIEWS] ? <ViewsButtonGroup view={view} setView={setView} /> : null}
      </div>
      {eventsError || tasksError || pebblesError ? (
        <ErrorAlert
          message="Error loading patient activity."
          error={eventsError || tasksError || pebblesError}
        />
      ) : null}
      <ChartActivity finishedRenderingData={finishedRenderingData}>
        <FlowMappingsContextProvider kind={InteractionKind.Pebble}>
          <MaterialTable<ActivityData>
            tableRef={tableRef}
            data={filteredActivityData}
            columns={[
              {
                title: 'Date',
                field: 'start',
                cellStyle,
                filtering: false,
                render: rowData => (
                  <div
                    className={
                      UNSCHEDULED_EVENT_STATUSES.includes(rowData.status)
                        ? classes.strikethrough
                        : undefined
                    }
                    data-activity-item-id={getItemIdForScroll(rowData)}
                  >
                    {rowData.start}
                  </div>
                ),
              },
              {
                title: 'Type',
                field: 'label',
                cellStyle,
                lookup: allLabels,
                render: rowData => (
                  <div
                    className={classNames(
                      classes.type,
                      UNSCHEDULED_EVENT_STATUSES.includes(rowData.status)
                        ? classes.strikethrough
                        : null,
                    )}
                  >
                    <PatientActivityIcon type={rowData.type} />
                    {rowData.label}
                  </div>
                ),
              },
              {
                title: 'Providers',
                field: 'attendees',
                cellStyle,
                lookup: allProviders,
                customFilterAndSearch: (filteredAttendeeIds, rowData) => {
                  return filteredAttendeeIds.every(attendeeId =>
                    rowData.attendees.some(attendee => attendee.id === attendeeId),
                  );
                },
                render: rowData => <div>{rowData.attendees.map(getExactFullName).join(', ')}</div>,
              },
              {
                title: 'Summary',
                field: 'eventNotes',
                cellStyle,
                filtering: false,
                width: '25%',
                render: rowData => <div className={classes.eventNotes}>{rowData.eventNotes}</div>,
              },
            ]}
            detailPanel={[
              {
                tooltip: 'Details',
                render: rowData => {
                  switch (rowData.type) {
                    case ActivityType.Event:
                      return (
                        <EventDetail
                          eventId={rowData.eventId as string}
                          patientId={patientId}
                          classes={classes}
                        />
                      );
                    case ActivityType.Task:
                      return (
                        <TaskDetail
                          taskId={rowData.taskId}
                          name={rowData.name as string}
                          patientId={patientId}
                          classes={classes}
                        />
                      );
                    case ActivityType.Pebble:
                      return (
                        <PebbleDetail
                          pebbleId={rowData.pebbleId}
                          classes={classes}
                          canEditInteraction={false}
                        />
                      );
                    default:
                      return null;
                  }
                },
              },
            ]}
            isLoading={isLoading}
            onRowClick={(event, rowData, togglePanel) => togglePanel?.()}
            options={{
              toolbar: false,
              paging: false,
              filtering: flags[OLD_ACTIVITY_FILTERS],
              search: false,
              headerStyle: { display: 'default' },
              sorting: false,
              rowStyle: rowData => {
                const style = getCalendarEventStyles(rowData.eventStatusInfo);
                delete style.textDecoration;
                return { ...style, borderLeftWidth: '8px' } as React.CSSProperties;
              },
            }}
          />
        </FlowMappingsContextProvider>
      </ChartActivity>

      <PatientExportDialog
        isOpen={exportModalShowing}
        close={() => setExportModalShowing(false)}
        patientId={patientId}
      />
    </>
  );
};

/** Returns the formatted activity item id needed for automatic scrolling to a linked item. */
function getItemIdForScroll(rowData: ActivityData) {
  const anchor = 'activity';
  switch (rowData.type) {
    case ActivityType.Event:
      return `${anchor}-${rowData.eventId}`;
    case ActivityType.Task:
      return `${anchor}-${rowData.taskId}`;
    case ActivityType.Pebble:
      return `${anchor}-${rowData.pebbleId}`;
    default:
      return '';
  }
}
function findRowIndexForUrlItem(tableData: ActivityData[], idFromUrl: string) {
  return tableData.findIndex(rowData => {
    switch (rowData.type) {
      case ActivityType.Event:
        return idFromUrl === rowData.eventId;
      case ActivityType.Task:
        return idFromUrl === rowData.taskId;
      case ActivityType.Pebble:
        return idFromUrl === rowData.pebbleId;
      default:
        return false;
    }
  });
}

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  type: {
    fontWeight: 'bold',
  },
  strikethrough: {
    textDecoration: 'line-through',
  },
  eventNotes: {
    fontStyle: 'italic',
    textDecoration: 'none',
    maxWidth: 400,
  },
  summary: {
    padding: 15,
  },
});

export default PatientActivity;
