"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.plural = plural;
var _pluralize = _interopRequireDefault(require("pluralize"));
var _length = require("./length");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function plural(value, noun, possibleOverride) {
  const count = typeof value === 'number' ? value : (0, _length.length)(value);
  if (typeof possibleOverride === 'string') return count === 1 ? noun : possibleOverride;
  return (0, _pluralize.default)(noun, count);
}