import { multilineStringDef } from 'src/components/forms/schemas/util';

export default {
  type: 'object',
  title: 'Case Manager: Transition',
  properties: {
    reasonForTransition: {
      type: 'string',
      title: 'Reason for transition out of case management?',
      enum: [
        'Patient met their goals',
        'Patient no longer interested in case management',
        'Patient no longer in active care with Boulder (unenrolled, paused, or inactive)',
        'Patient has missed multiple visits',
        'Other',
      ],
    },
    transitionNotes: {
      type: 'string',
      title: 'Reason for transition notes',
      uniforms: {
        multiline: true,
      },
    },
    success: {
      type: 'string',
      title: 'Will patient be successful? Why / why not?',
      uniforms: {
        multiline: true,
      },
    },
    attendance: {
      type: 'string',
      title: 'Did patient attend previous visits?',
      enum: ['Yes', 'No', 'Other'],
    },
    attendanceNotes: multilineStringDef,
    treatment: {
      type: 'string',
      title: 'Is patient still receiving treatment with Boulder?',
      enum: ['Yes', 'No', 'Other'],
    },
    treatmentNotes: multilineStringDef,
    supports: {
      type: 'string',
      title: 'Does patient have supports?',
      enum: ['Yes', 'No', 'Other'],
    },
    supportsNotes: multilineStringDef,
    resources: {
      type: 'string',
      title: 'Did patient receive resources?',
      enum: ['Yes', 'No', 'Other'],
    },
    resourcesNotes: multilineStringDef,
  },
  required: ['reasonForTransition'],
};
