import Typography from '@material-ui/core/Typography';
import React from 'react';

export default ({ model, classes, schema }) => (
  <>
    <span>{schema.title}</span>
    {model.notes && (
      <Typography variant="body2" className={classes.multiLineText}>
        {model.notes}
      </Typography>
    )}
  </>
);
