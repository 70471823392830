import { types, getEnv, getRoot, flow, Instance } from 'mobx-state-tree';

import { configCatFlags } from 'src/featureFlags/configCat';
import { TURN_OFF_PEBBLES } from 'src/featureFlags/currentFlags';
import createListModel from 'src/shared/stores/createListModel';
import { DateType, Provider, Patient } from 'src/shared/stores/resource';
import Auth from 'src/stores/auth';
import {
  LOAD_FULL_PEBBLE,
  LOAD_PEBBLES,
  COUNT_PEBBLES_FOR_BADGE,
} from 'src/stores/queries/pebbles';
import { SUBSCRIBE_TO_UPDATED_PEBBLE } from 'src/stores/subscriptions/pebbles';
import {
  transformPebbleForDisplay,
  PEBBLE_TOPIC_MAP,
  PEBBLE_STATUS_MAP,
  PEBBLE_PRIORITY_MAP,
} from 'src/util/pebbles';

type RootStore = {
  auth: Instance<typeof Auth>;
};

export const PebbleStatus = types.enumeration('PebbleStatus', Object.keys(PEBBLE_STATUS_MAP));
export const PebblePriority = types.enumeration('PebblePriority', Object.keys(PEBBLE_PRIORITY_MAP));

// TODO: purge 'test' values from db
export const PebbleTopic = types.enumeration('PebbleTopic', [
  'test',
  ...Object.keys(PEBBLE_TOPIC_MAP),
]);

export const PebbleStringHistory = types.model('PebbleStringHistory', {
  updatedAt: DateType,
  updatedBy: Provider,
  value: types.maybeNull(types.string),
});

export const PebbleStatusHistory = types.model('PebbleStatusHistory', {
  updatedAt: DateType,
  updatedBy: Provider,
  value: PebbleStatus,
});

export const PebbleProviderHistory = types.model('PebbleProviderHistory', {
  updatedAt: DateType,
  updatedBy: Provider,
  value: types.maybeNull(Provider),
});

export const PebbleHistory = types.model('PebbleHistory', {
  title: types.optional(types.array(PebbleStringHistory), []),
  assignee: types.optional(types.array(PebbleProviderHistory), []),
  status: types.optional(types.array(PebbleStatusHistory), []),
  note: types.optional(types.array(PebbleStringHistory), []),
});

export const Pebble = types.model('Pebble', {
  id: types.string,
  createdAt: DateType,
  createdBy: Provider,
  updatedAt: types.maybeNull(DateType),
  updatedBy: types.maybeNull(Provider),
  title: types.maybeNull(types.string),
  assignee: types.maybeNull(Provider),
  participant: types.maybeNull(Patient),
  monitors: types.optional(types.array(Provider), []),
  priority: types.maybeNull(PebblePriority),
  status: PebbleStatus,
  topic: types.maybeNull(PebbleTopic),
  note: types.maybeNull(types.string),
  history: types.maybeNull(PebbleHistory),
  reminder: types.maybeNull(DateType),
  link: types.maybeNull(types.string),
});

export type PebbleType = Instance<typeof Pebble>;

export const PebblesList = createListModel('PebblesList', Pebble, LOAD_PEBBLES).views(self => ({
  get materialTableDataFormat() {
    return self.items.map(transformPebbleForDisplay);
  },
}));

const PebblesDomain = types
  .model('PebblesDomain', {
    list: types.optional(PebblesList, { orderBy: 'updatedAt', order: 'desc' }),
    myNewPebblesWithFutureRemindersCount: types.optional(types.integer, 0),
    myNewPebblesWithoutRemindersCount: types.optional(types.integer, 0),
    myPastDuePebblesCount: types.optional(types.integer, 0),
    myPebbleBadgeCount: types.optional(types.integer, 0),
  })
  .actions(self => ({
    setMyNewPebblesWithFutureRemindersCount(count) {
      self.myNewPebblesWithFutureRemindersCount = count;
    },
    setMyNewPebblesWithoutRemindersCount(count) {
      self.myNewPebblesWithoutRemindersCount = count;
    },
    setMyPastDuePebblesCount(count) {
      self.myPastDuePebblesCount = count;
    },
  }))
  .actions(self => ({
    getMyPebbleBadgeCount: flow(function* getMyPebbleBadgeCount() {
      if (configCatFlags.latestFlags[TURN_OFF_PEBBLES]) {
        return;
      }
      const myId = getRoot<RootStore>(self).auth.user?.id;
      // Counts pebbles that are new, assigned to the current user, not created by them, and
      // have reminders in the future. This avoids including past due pebbles and counting them twice.
      const myNewPebblesWithFutureRemindersWhere = {
        assignee_some: myId,
        createdBy_nin: myId,
        status_some: 'new',
        reminder_gt: new Date().toISOString(),
      };
      // Counts pebbles that are new, assigned to the current user, not created by them, and
      // have no reminder.
      const myNewPebblesWithoutRemindersWhere = {
        assignee_some: myId,
        createdBy_nin: myId,
        status_some: 'new',
        // We pass in 'none' since null values are ignored in the whereToFilter function. There is
        // a workaround by using 'none' instead.
        reminder_some: 'none',
      };
      // Counts pebbles with past due reminders that do not have statuses won't do or completed.
      const myPastDuePebblesWhere = {
        reminder_lte: new Date().toISOString(),
        assignee_some: myId,
        status_nin: ['wont_do', 'completed'],
      };

      const res = yield getEnv(self).apolloClient.query({
        query: COUNT_PEBBLES_FOR_BADGE,
        variables: {
          newPebblesWithFutureRemindersWhere: myNewPebblesWithFutureRemindersWhere,
          newPebblesWithoutRemindersWhere: myNewPebblesWithoutRemindersWhere,
          pastDuePebblesWhere: myPastDuePebblesWhere,
        },
      });

      self.setMyNewPebblesWithFutureRemindersCount(res.data.newPebblesWithFutureRemindersCount);
      self.setMyNewPebblesWithoutRemindersCount(res.data.newPebblesWithoutRemindersCount);
      self.setMyPastDuePebblesCount(res.data.pastDuePebblesCount);

      self.myPebbleBadgeCount =
        self.myNewPebblesWithFutureRemindersCount +
        self.myNewPebblesWithoutRemindersCount +
        self.myPastDuePebblesCount;
    }),
    createPebble: flow(function* createPebble(pebble) {
      if (configCatFlags.latestFlags[TURN_OFF_PEBBLES]) {
        // I don't know if this is reachable via the UI with pebbles turned off,
        // but just in case, avoid creating pebbles.
        return null;
      }
      const res = yield getEnv(self).crudService.create('Pebble', pebble);
      if (res) {
        return res.id;
      }
      return null;
    }),
    getFullPebble: flow(function* getFullPebble(id) {
      const res = yield getEnv(self).apolloClient.query({
        query: LOAD_FULL_PEBBLE,
        variables: {
          id,
        },
      });
      return res.data.pebble;
    }),
  }))
  .actions(self => {
    let pebbleUpdatedSubscription;

    return {
      updatePebble: flow(function* updatePebble(newValues, pebble) {
        if (configCatFlags.latestFlags[TURN_OFF_PEBBLES]) {
          // I don't know if this is reachable via the UI with pebbles turned off,
          // but just in case, avoid updating pebbles.
          return null;
        }
        const res = yield getEnv(self).crudService.update('Pebble', newValues, pebble);
        yield self.list.load();
        if (res) {
          return self.getFullPebble(res.id);
        }
        return null;
      }),
      // eslint-disable-next-line require-yield
      subscribeToUpdates: flow(function* subscribeToUpdates() {
        if (configCatFlags.latestFlags[TURN_OFF_PEBBLES]) {
          pebbleUpdatedSubscription = null;
          return;
        }
        pebbleUpdatedSubscription = getEnv(self)
          .apolloClient.subscribe({
            query: SUBSCRIBE_TO_UPDATED_PEBBLE,
          })
          .subscribe({
            next() {
              self.getMyPebbleBadgeCount();
            },
            error(err) {
              console.info(err);
            },
          });
      }),
      unsubscribeFromUpdates() {
        if (pebbleUpdatedSubscription) {
          pebbleUpdatedSubscription.unsubscribe();
        }
      },
    };
  })
  .actions(self => ({
    // eslint-disable-next-line require-yield
    afterAttach: flow(function* afterAttach() {
      self.subscribeToUpdates();
    }),
    beforeDestroy() {
      self.unsubscribeFromUpdates();
    },
  }));

export type PebblesDomainType = Instance<typeof PebblesDomain>;

export default PebblesDomain;
