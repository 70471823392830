import { differenceInDays } from 'date-fns';

import { MOTIVATIONAL_PATIENT_QUOTES } from 'src/myDayToday/motivationalPatientQuotesData';

export const startDate = new Date('2024-01-01');

export const getMotivationalQuote = (): { quote: string; author?: string } => {
  const today = new Date();
  const dateIndex = differenceInDays(today, startDate) % MOTIVATIONAL_PATIENT_QUOTES.length;

  const quote = MOTIVATIONAL_PATIENT_QUOTES[dateIndex];
  return quote;
};
