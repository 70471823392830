import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';

const Documents = ({ classes, documents }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);

  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          {documents.map(({ file: { id, url, contentType, filename } }) => (
            <TableRow
              key={id}
              className={classes.tableRow}
              onClick={() => setSelectedDocument(url)}
            >
              <TableCell>{`${filename}.${contentType?.split('/')[1]}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedDocument && (
        <Dialog open maxWidth="lg" fullWidth onClose={() => setSelectedDocument(null)}>
          <DialogTitle className={classes.dialogTitle}>
            <IconButton onClick={() => setSelectedDocument(null)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <iframe
              width="100%"
              height="100%"
              className={classes.dialogIframe}
              // Don't allow the toolbar to be displayed, as we're trying to discourage
              // users from downloading/printing/sharing PHI
              // See https://www.adobe.com/content/dam/acom/en/devnet/acrobat/pdfs/pdf_open_parameters.pdf
              src={`${selectedDocument}#toolbar=0`}
              title={selectedDocument}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const styles = () => ({
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
  },
  dialogTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    overflow: 'hidden',
  },
  dialogIframe: {
    height: 'calc(70vh - 96px)',
  },
});

export default withStyles(styles)(inject('rootStore')(observer(Documents)));
