import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';

import type { Pebble } from 'src/pebbles/types';

/**
 * Transforms pebble data to the format required by the API. Specifically for pebbles, we can assume
 * that all plain objects are references and must be mapped to an id. Same with the only array
 * for pebbles, monitors. Heavily based on the existing prepareData function with the exception that
 * this function does not require a MobX model instance.
 */
export const preparePebbleSaveData = (newValues: Partial<Pebble>, originalPebble: Pebble) => {
  const changedValues = {};

  Object.keys(newValues).forEach(key => {
    if (key === 'id') {
      // Omit the id because it is immutable
    } else if (newValues[key] === originalPebble[key]) {
      // Omit any unchanged values that are not objects or arrays
    } else if (isArray(newValues[key])) {
      const newIds = newValues[key].filter(val => !isNil(val)).map(val => val.id);
      const originalIds = originalPebble[key].filter(val => !isNil(val)).map(val => val.id);
      // Array has changed
      if (!isEqual(newIds, originalIds)) {
        changedValues[key] = newIds;
      }
    } else if (isPlainObject(newValues[key]) || isPlainObject(originalPebble[key])) {
      if (newValues[key]) {
        if (newValues[key].id !== originalPebble[key]?.id) {
          // Object is reference that has changed
          changedValues[key] = newValues[key].id;
        }
      } else if (originalPebble[key]) {
        // Object has been cleared
        changedValues[key] = null;
      }
    } else {
      // Assume everything else is a scalar and should be written as is
      changedValues[key] = newValues[key];
    }
  });

  return changedValues;
};
