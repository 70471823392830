import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const Tooltip: React.FC<TooltipProps> = React.forwardRef((props, ref) => (
  <MuiTooltip ref={ref} {...props} />
));

const styles = () => ({
  tooltip: {
    fontSize: '14px',
  },
});

export default withStyles(styles)(Tooltip);
