import { differenceInMinutes, isValid, parseISO } from 'date-fns';
import React from 'react';

import v100Schema from 'src/components/forms/schemas/eventResult/telehealthWrapUp/1.0.0';

// Reassign visitDuration to read-only calculated field instead of free text field
const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visitDuration,
  ...v100Properties
} = v100Schema.properties;

const VisitDuration = ({ model }) => {
  if (model.startTime && model.endTime) {
    let startTime = parseISO(model.startTime);
    if (!isValid(startTime)) {
      // Unfortunately, startTime has sometimes been serialized with toString() rather than
      // toISOString(), so we need to support both formats.
      startTime = new Date(model.startTime);
    }
    const duration = differenceInMinutes(parseISO(model.endTime), startTime);
    return <> {duration} </>;
  } else return null;
};

export default {
  ...v100Schema,
  properties: {
    startTime: {
      type: 'string',
      format: 'date-time',
    },
    endTime: {
      type: 'string',
      format: 'date-time',
    },
    visitDuration: {
      type: 'integer',
      title: 'Visit Duration (min)',
      displayOnly: true,
      renderer: {
        component: VisitDuration,
      },
    },
    ...v100Properties,
  },
  required: ['startTime', 'endTime'],
};

export const __test__ = {
  VisitDuration,
};
