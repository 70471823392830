import { parseISO } from 'date-fns';

/**
 * Takes an unknown input and tries to cast it as a date, similar to
 * `moment(input)`. It's not 100% a drop-in replacement for moment(), though,
 * since moment falls back to new Date() for non-ISO strings – this will return
 * Invalid Date where moment would just warn and DWIM.
 *
 * @deprecated This is an old moment compatability-shim and should not be used
 * in new code.
 */
export const parseUnknownDate = (input: Date | number | string | unknown): Date => {
  if (input instanceof Date) return input;
  if (typeof input === 'string') return parseISO(input);
  return new Date(input as any);
};
