import gql from 'graphql-tag';

import { Interaction } from 'src/nightingale/types/types';

export const ADD_FLOW_TO_INTERACTION = gql`
  mutation AddFlowToInteraction(
    $interactionReferenceId: String!
    $flowId: String!
    $parentFlowIndex: Int
  ) {
    addFlowToInteraction(
      interactionReferenceId: $interactionReferenceId
      flowId: $flowId
      parentFlowIndex: $parentFlowIndex
    ) {
      id
    }
  }
`;

export type QueryResult = {
  addFlowToInteraction: Interaction;
};
