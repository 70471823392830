// Schema imports
import asamAssessment from 'src/components/forms/schemas/eventResult/asamAssessment';
import assessmentCdcHealthRelatedQualityOfLife from 'src/components/forms/schemas/eventResult/assessmentCdcHealthRelatedQualityOfLife';
import careAdvocateOftResults from 'src/components/forms/schemas/eventResult/careAdvocateOftResults';
import careAdvocatePatientCheckIn from 'src/components/forms/schemas/eventResult/careAdvocatePatientCheckIn';
import careAdvocateSynchronousMam from 'src/components/forms/schemas/eventResult/careAdvocateSynchronousMam';
import careAdvocateSynchronousOft from 'src/components/forms/schemas/eventResult/careAdvocateSynchronousOft';
import careAdvocateTelehealthPrecheck from 'src/components/forms/schemas/eventResult/careAdvocateTelehealthPrecheck';
import caseManagementTransition from 'src/components/forms/schemas/eventResult/caseManagementTransition';
import caseManagerIntake from 'src/components/forms/schemas/eventResult/caseManagerIntake';
import caseManagerNote from 'src/components/forms/schemas/eventResult/caseManagerNote';
import caseManagerReferral from 'src/components/forms/schemas/eventResult/caseManagerReferral';
import dsm5OudAssessment from 'src/components/forms/schemas/eventResult/dsm5OudAssessment';
import general from 'src/components/forms/schemas/eventResult/general';
import generalVisitAssessment from 'src/components/forms/schemas/eventResult/generalVisitAssessment';
import inquiryCallDetails from 'src/components/forms/schemas/eventResult/inquiryCallDetails';
import inquiryFormDetails from 'src/components/forms/schemas/eventResult/inquiryFormDetails';
import inquiryPotentialPatient from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient';
import notes from 'src/components/forms/schemas/eventResult/notes';
import onboardingChecklist from 'src/components/forms/schemas/eventResult/onboardingChecklist';
import onboardingRcvSetupDetails from 'src/components/forms/schemas/eventResult/onboardingRcvSetupDetails';
import onboardingRcvSetupIntro from 'src/components/forms/schemas/eventResult/onboardingRcvSetupIntro';
import onboardingRcvWrapUp from 'src/components/forms/schemas/eventResult/onboardingRcvWrapUp';
import onboardingReferralDetails from 'src/components/forms/schemas/eventResult/onboardingReferralDetails';
import onboardingSocialNeeds from 'src/components/forms/schemas/eventResult/onboardingSocialNeeds';
import patientSafetyScreener from 'src/components/forms/schemas/eventResult/patientSafetyScreener';
import peerCoachTelehealthPrecheck from 'src/components/forms/schemas/eventResult/peerCoachTelehealthPrecheck';
import peerCoachVisit from 'src/components/forms/schemas/eventResult/peerCoachVisit';
import prescriberAssessmentFollowUp from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp';
import prescriberAssessmentInitial from 'src/components/forms/schemas/eventResult/prescriberAssessmentInitial';
import prescriberInitialVisitWrapUp from 'src/components/forms/schemas/eventResult/prescriberInitialVisitWrapUp';
import prescriberObjective from 'src/components/forms/schemas/eventResult/prescriberObjective';
import prescriberOhioChecklist from 'src/components/forms/schemas/eventResult/prescriberOhioChecklist';
import prescriberPlanFollowUp from 'src/components/forms/schemas/eventResult/prescriberPlanFollowUp';
import prescriberPlanInitial from 'src/components/forms/schemas/eventResult/prescriberPlanInitial';
import prescriberPlanReengagement from 'src/components/forms/schemas/eventResult/prescriberPlanReengagement';
import prescriberSubjectiveFollowUp from 'src/components/forms/schemas/eventResult/prescriberSubjectiveFollowUp';
import prescriberSubjectiveReengagement from 'src/components/forms/schemas/eventResult/prescriberSubjectiveReengagement';
import prescriberTelehealthPrecheck from 'src/components/forms/schemas/eventResult/prescriberTelehealthPrecheck';
import prescriberUpdatePatientHistory from 'src/components/forms/schemas/eventResult/prescriberUpdatePatientHistory';
import prescriberVisitPrep from 'src/components/forms/schemas/eventResult/prescriberVisitPrep';
import sows from 'src/components/forms/schemas/eventResult/sows';
import subjectiveInitial from 'src/components/forms/schemas/eventResult/subjectiveInitial';
import telehealthWrapUp from 'src/components/forms/schemas/eventResult/telehealthWrapUp';

export default {
  asamAssessment,
  inquiryCallDetails,
  inquiryFormDetails,
  inquiryPotentialPatient,
  onboardingRcvSetupIntro,
  onboardingRcvSetupDetails,
  onboardingSocialNeeds,
  onboardingReferralDetails,
  onboardingRcvWrapUp,
  onboardingChecklist,
  careAdvocateSynchronousOft,
  careAdvocateSynchronousMam,
  careAdvocatePatientCheckIn,
  careAdvocateTelehealthPrecheck,
  careAdvocateOftResults,
  assessmentCdcHealthRelatedQualityOfLife,
  prescriberPlanFollowUp,
  prescriberAssessmentFollowUp,
  prescriberInitialVisitWrapUp,
  prescriberObjective,
  prescriberOhioChecklist,
  prescriberSubjectiveFollowUp,
  prescriberSubjectiveReengagement,
  prescriberUpdatePatientHistory,
  prescriberPlanInitial,
  prescriberPlanReengagement,
  prescriberAssessmentInitial,
  dsm5OudAssessment,
  subjectiveInitial,
  prescriberTelehealthPrecheck,
  prescriberVisitPrep,
  telehealthWrapUp,
  peerCoachTelehealthPrecheck,
  peerCoachVisit,
  general,
  generalVisitAssessment,
  sows,
  notes,
  patientSafetyScreener,
  caseManagementTransition,
  caseManagerNote,
  caseManagerReferral,
  caseManagerIntake,
};
