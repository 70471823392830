import v100Schema from 'src/components/forms/schemas/eventResult/prescriberAssessmentFollowUp/1.0.0';

// Remove the old assessmentNotes property which will be replaced by `notes`.
const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  assessmentNotes,
  ...v100Properties
} = v100Schema.properties;

export default {
  ...v100Schema,
  properties: {
    ...v100Properties,
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
};
