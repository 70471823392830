import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { format, isValid, parseISO } from 'date-fns';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import PatientReferenceControl from 'src/components/forms/controls/PatientReference';
import ClearableSelect from 'src/components/forms/controls/clearableSelect';
import DateControl from 'src/components/forms/controls/date';
import ProviderReferenceControl from 'src/components/forms/controls/providerReference';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import Accordion from 'src/components/pages/pageElements/accordion';
import FilterSummary from 'src/components/pages/pageElements/filterSummary';
import { usePebbleTopics } from 'src/pebbles/usePebbleTopics';
import { getMultiSelectFilterField, getSingleSelectFilterField } from 'src/util/filters';
import { getValuesFromQueryParams } from 'src/util/params/pebbles';
import { PEBBLE_PRIORITY_OPTIONS, PEBBLE_STATUS_OPTIONS } from 'src/util/pebbles';

export default function PebblesListFilters({ queryParams, handleFiltersChange, rootStore }) {
  const classes = usePebblesListFiltersStyles();
  // Filter values keep track of what we last applied in the search request
  const [filterValues, setFilterValues] = useState({});
  // Form values keep track of what we're currently selecting in the UI, so that
  // we may kick off another search request when we're ready
  const [localValues, setLocalValues] = useState({});

  // Show/hide condition for the convenient "filter" button that appears only
  // when one of the "x" remove buttons in the summary are used.
  const [showQuickFilter, setShowQuickFilter] = useState(false);

  useEffect(() => {
    (async () => {
      const values = await getValuesFromQueryParams(queryParams, rootStore);

      setFilterValues(values);
      setLocalValues(values);
    })();
  }, [
    // Initial filter values are driven by the query param state
    queryParams,
    // rootStore needs to be mentioned so that possible changes to getProviderById are accounted for.
    // Deeper changes like receiving a chat message will not trigger the Effect.
    rootStore,
  ]);

  const validationSchema = Yup.object().shape({
    participant: Yup.object().nullable(),
    assignee: Yup.object().nullable(),
    createdBy: Yup.object().nullable(),
    monitors: Yup.object().nullable(),
    topic: Yup.string().nullable(),
    status: Yup.string().nullable(),
    priority: Yup.string().nullable(),
  });

  const PebbleTopicField = props => {
    const topics = usePebbleTopics();
    return <ClearableSelect options={topics} isMulti {...props} />;
  };
  const PebbleStatusField = props => (
    <ClearableSelect options={PEBBLE_STATUS_OPTIONS} isMulti {...props} />
  );
  const PebblePriorityField = props => (
    <ClearableSelect options={PEBBLE_PRIORITY_OPTIONS} isMulti {...props} />
  );

  const formatDateFilterValue = date => {
    const parsedDate = parseISO(date);
    return isValid(parsedDate) ? format(parsedDate, 'PP') : '';
  };

  const filterFields = [
    getSingleSelectFilterField('Participants', 'participant', PatientReferenceControl),
    getSingleSelectFilterField('Assigned to', 'assignee', ProviderReferenceControl),
    getSingleSelectFilterField('Created by', 'createdBy', ProviderReferenceControl),
    getSingleSelectFilterField('Monitored by', 'monitors', ProviderReferenceControl),
    getMultiSelectFilterField('Topic', 'topic', PebbleTopicField),
    getMultiSelectFilterField('Status', 'status', PebbleStatusField),
    getMultiSelectFilterField('Priority', 'priority', PebblePriorityField),
    getSingleSelectFilterField(
      'Created After',
      'createdAt_gte',
      DateControl,
      formatDateFilterValue,
    ),
    getSingleSelectFilterField(
      'Created Before',
      'createdAt_lt',
      DateControl,
      formatDateFilterValue,
    ),
    getSingleSelectFilterField(
      'Updated After',
      'updatedAt_gte',
      DateControl,
      formatDateFilterValue,
    ),
    getSingleSelectFilterField(
      'Updated Before',
      'updatedAt_lt',
      DateControl,
      formatDateFilterValue,
    ),
    getSingleSelectFilterField(
      'With Reminder After',
      'reminder_gt',
      DateControl,
      formatDateFilterValue,
    ),
    getSingleSelectFilterField(
      'With Reminder Before',
      'reminder_lte',
      DateControl,
      formatDateFilterValue,
    ),
  ];

  // Object used to clear all values later by intentionally mapping every
  // filter field to null
  const clearedFiltersValue = mapValues(keyBy(filterFields, 'key'), () => null);

  const hasUnappliedChanges = filterFields.some(filter => {
    const appliedValue = filterValues[filter.key];
    const unappliedValue = localValues[filter.key];
    const match =
      // Either both filters match,
      isEqual(appliedValue, unappliedValue) ||
      // Or neither filter is currently selected
      (isNil(appliedValue) && isNil(unappliedValue));
    return !match;
  });

  function resetLocalValues() {
    setLocalValues(filterValues);
    setShowQuickFilter(false);
  }

  function applyUpdatedValues(updatedValues) {
    handleFiltersChange(updatedValues);
    setShowQuickFilter(false);
  }

  function clearAllLocalValues() {
    setLocalValues(clearedFiltersValue);
    if (hasUnappliedChanges) {
      setShowQuickFilter(hasUnappliedChanges);
    }
  }

  function removeLocalFilter(filterKey, multiFilterSubValue) {
    let newFilterValue = null;
    const generalFilterKey = filterKey.split('-')[0];
    if (Array.isArray(localValues[generalFilterKey])) {
      newFilterValue = localValues[generalFilterKey].filter(
        subValue => subValue !== multiFilterSubValue,
      );
    }
    setLocalValues({
      ...localValues,
      [generalFilterKey]: newFilterValue,
    });
  }

  return (
    <FormControl fullWidth className={classes.accordionContainer}>
      <div className={classes.formLabel}>
        <InputLabel shrink>Filtered By</InputLabel>
      </div>
      <Accordion
        additionalButtons={[
          {
            label: 'Clear All',
            onClick: clearAllLocalValues,
          },
        ]}
        submitLabel="Filter"
        summary={
          <FilterSummary
            filterFields={filterFields}
            localValues={localValues}
            hasUnappliedChanges={hasUnappliedChanges}
            removeLocalFilter={removeLocalFilter}
            onFilter={applyUpdatedValues}
            setShowQuickFilter={setShowQuickFilter}
            showQuickFilter={showQuickFilter}
          />
        }
        onCancel={resetLocalValues}
        onSubmit={() => applyUpdatedValues(localValues)}
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={localValues}
          enableReinitialize
          onSubmit={noop} // Submission is handled in the Accordian onSubmit
        >
          {() => (
            // TODO: Do we need / should we have FormikEffect with an onChange handler here to set local values
            <div className={classes.formContainer}>
              <FormikEffect
                onChange={(current, prev) => {
                  // Update local values for fields that don't offer onChange but use `form.setFieldValue()` deeper down (i.e. ReferenceControl)
                  if (current.values !== prev.values) {
                    setLocalValues(current.values);
                  }
                }}
              />
              {filterFields.map(filterField => (
                <Field
                  key={filterField.key}
                  component={filterField.component}
                  value={localValues[filterField.key]}
                  {...filterField.props}
                />
              ))}
            </div>
          )}
        </Formik>
      </Accordion>
    </FormControl>
  );
}

const usePebblesListFiltersStyles = makeStyles({
  accordionContainer: {
    // Required to make input label appear
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    '& > *': {
      display: 'inline-flex',
      marginBottom: 30,
      marginRight: 30,
      verticalAlign: 'inherit',
      width: 300,
    },
  },
  formLabel: {
    height: 20,
  },
  careTeamLabel: {
    fontStyle: 'italic',
  },
  wordWrapped: {
    minWidth: 100,
  },
});
