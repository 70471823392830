import { MenuItem, Select, Theme } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';

import { CLAIMS_RESUBMIT_EVENT } from 'src/claims/claimsResubmitEvent.gql';
import { useEncounterSubmissions } from 'src/claims/useEncounterSubmissions';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import Colors from 'src/nightingale/Colors';
import { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

// Note: these should match the server's enum in src/claims/domain/types.ts:Status
const CLAIM_STATUSES = [
  'unknown',
  'duplicate',
  'feature-off',
  'configuration-error',
  'gateway-disabled',
  'pending',
  'held',
  'needs-update',
  'submitted',
  'rejected',
];
export const DEFAULT_STATUSES = ['held', 'needs-update', 'rejected'];

const StateFilter = ({ columnDef, onFilterChanged, defaultStatuses }) => {
  const [value, setValue] = useState(defaultStatuses);
  const {
    tableData: { id },
  } = columnDef;
  return (
    <Select
      style={{ maxWidth: 150 }}
      multiple
      value={value}
      onChange={e => {
        const newValue: any = e.target.value;
        setValue(newValue);
        onFilterChanged(id, newValue);
      }}
    >
      {CLAIM_STATUSES.map(state => (
        <MenuItem key={state} value={state}>
          {state}
        </MenuItem>
      ))}
    </Select>
  );
};

const EncounterSubmisions: React.FC<{
  rootStore: RootStore;
}> = ({
  rootStore: {
    routerStore: {
      routerState: { queryParams },
    },
  },
}) => {
  const styles = useStyles();

  const { apolloClient } = useContext(ApolloClientContext);

  const { encounterSubmissions } = useEncounterSubmissions({
    createdAt: queryParams.createdAt,
    state: queryParams.state,
  });
  if (!encounterSubmissions) {
    return null;
  }

  const getStatusFilterDefault = () => {
    if (queryParams.state) {
      return Array.isArray(queryParams.state) ? queryParams.state : [queryParams.state];
    }

    return DEFAULT_STATUSES;
  };
  const defaultStatuses = getStatusFilterDefault();

  return (
    <div className={styles.table}>
      <MaterialTable
        title="Encounter Submissions"
        options={{ filtering: true, pageSize: 20 }}
        data={encounterSubmissions}
        columns={[
          {
            title: 'Created At',
            field: 'createdAt',
            filtering: false,
            render: row => (row.createdAt ? format(row.createdAt, 'yyyy-MM-dd HH:mm') : ''),
          },
          { title: 'Event Id', field: 'eventId', filtering: false },
          {
            title: 'State',
            field: 'state',
            filtering: true,
            defaultFilter: defaultStatuses,
            filterComponent: props => <StateFilter defaultStatuses={defaultStatuses} {...props} />,
            customFilterAndSearch: (term, rowData) => (term || []).includes(rowData.state),
          },
          { title: 'Errors', field: 'errors', filtering: false },
        ]}
        actions={[
          {
            icon: Refresh,
            tooltip: 'Resubmit to Kareo',
            onClick: async (evt, rowOrRows) => {
              if (Array.isArray(rowOrRows)) {
                console.warn('resubmit only handles single rows');
                return;
              }
              const { eventId } = rowOrRows;
              await apolloClient?.mutate({
                mutation: CLAIMS_RESUBMIT_EVENT,
                variables: {
                  eventId,
                },
              });
            },
          },
        ]}
      />
    </div>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  table: {
    '& .MuiToolbar-gutters': {
      padding: theme.spacing(2),
      minHeight: 0,
      width: 'auto',
    },
    '& .MuiTypography-h6': {
      color: Colors.Gray6,
      fontFamily: 'Tenor Sans',
      fontSize: 26,
      fontWeight: 300,
      lineHeight: 1.45,
      margin: 0,
      textTransform: 'capitalize',
    },
    padding: theme.spacing(3),
  },
}));

export default inject<typeof EncounterSubmisions>('rootStore')(observer(EncounterSubmisions));
