/**
 * These are the Boulder brand colors.
 *
 * They differ from the Nighingale colors, and are used in parts of the patient app
 * as well as in branding materials outside the patient and staff apps.
 *
 * @link https://www.figma.com/file/wRLYtkYIeEEMvS3pESF0lS/1.-Boulder-Brand-Kit?type=design&node-id=210-52&mode=design&t=d60sd0TlclZXnCfm-0
 */
export enum BoulderColors {
  White = '#FFFFFF',

  Blue1 = '#E8F4FA',
  Blue2 = '#D1E2EB',
  Blue3 = '#AEC5D4',
  Blue4 = '#507796',
  Blue5 = '#003057',
  Blue6 = '#002441',

  Gray1 = '#FAF2EB',
  Gray2 = '#F0E5DD',
  Gray3 = '#D6CABF',
  Gray4 = '#A89A8D',
  Gray5 = '#696158',
  Gray6 = '#3D3934',

  Peach1 = '#FAEDE8',
  Peach2 = '#FFDBCD',
  Peach3 = '#F5BEA9',
  Peach4 = '#ED9D7B',
  Peach5 = '#A45014',
  Peach6 = '#543B28',
}
