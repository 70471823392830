import { inject as mobxInject } from 'mobx-react';
import { VFC } from 'react';

/**
 * Wrap the given function component in a type that declares any properties mobx will inject are
 * optional. Mobx's inject function allows the component to assume the injected store will be
 * present, as if it were a required prop, and also allows the component's consumers to ignore that
 * prop entirely, as if it were optional. However, it doesn't inform the type system about the
 * prop's dual nature, which is where this wrapper comes in.
 */
function inject<Component extends VFC, Props = Parameters<Component>[0]>(
  ...stores: Array<string & keyof Props>
) {
  return (component: Component) =>
    mobxInject(...stores)(component as VFC<Optional<Props, (typeof stores)[number]>>);
}

type Optional<Type, Key extends keyof Type> = Omit<Type, Key> & {
  [key in Key]+?: Type[Key];
};

export { inject };
