import { format, sub, add, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

type Frequency = 'daily' | 'weekly' | 'monthly';

interface BaseRecurrenceRule {
  freq: Frequency;
  interval: number;
  type: 'scheduled';
  until?: string; // YYYY-MM-DD
}

interface RecurrenceRuleDaily extends BaseRecurrenceRule {
  freq: 'daily';
}

interface RecurrenceRuleWeekly extends BaseRecurrenceRule {
  freq: 'weekly';
  byDay: [number, ...number[]];
}

interface RecurrenceRuleMonthlyBySetPos extends BaseRecurrenceRule {
  freq: 'monthly';
  bySetPos: keyof typeof SET_POS;
  byDayForMonth: number;
}
interface RecurrenceRuleMonthlyByMonthDay extends BaseRecurrenceRule {
  freq: 'monthly';
  byMonthDay: number;
}

type RecurrenceRule =
  | RecurrenceRuleDaily
  | RecurrenceRuleWeekly
  | RecurrenceRuleMonthlyBySetPos
  | RecurrenceRuleMonthlyByMonthDay;

export const SET_POS = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  '-1': 'last',
};

export function ordinal(i: number) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

function unit(freq: Frequency) {
  switch (freq) {
    case 'daily':
      return 'day';
    case 'weekly':
      return 'week';
    case 'monthly':
      return 'month';
    default:
      return '';
  }
}

/**
 * week, 2nd week, 3rd week...
 */
function unitWithCount(freq: Frequency, interval: number) {
  if (interval > 1) {
    return `${ordinal(interval)} ${unit(freq)}`;
  }
  return unit(freq);
}

/**
 * 0 => Mon, 6 => Sun
 */
function formatDay(day: number) {
  return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][day];
}

/**
 * [0] => Mon
 * [0, 1] => Mon, Tue
 * [0, 2, 4] => Mon, Wed, Fri
 * [0, 1, 2] => Mon–Wed
 * [5, 6, 0] => Sat–Mon
 * [0, 1, 2, 3, 4, 5, 6] => Mon-Sun
 */
function weekDays(daysArgs: [number, ...number[]]) {
  const days = daysArgs.slice(0).sort();

  // Case 1: 0/1/2 days
  if (days.length < 3) {
    return days.map(formatDay).join(', ');
  }

  // Case 2: show all days as MON-SUN (this avoids an infinite loop in the next case)
  if (days.length === 7) {
    return `${formatDay(days[0])}–${formatDay(days[6])}`;
  }

  // Case 3: show consecutive days as range, eg MON-WED or SAT-TUE
  const copy = days.slice(0);
  // while the first and the last are consecutive days (eg MO/SU), shift the first day to the end.
  // this effectively transforms MO,SA,SU into SA,SU,MO so we can more easily see whether it's
  // consecutive
  while (copy[copy.length - 1] - copy[0] === 6) {
    copy.push((copy.shift() as number) + 7);
  }
  // now check if it's consecutive
  if (copy[0] + copy.length - 1 === copy[copy.length - 1]) {
    return `${formatDay(copy[0])}–${formatDay(copy[copy.length - 1])}`;
  }

  // Case 4: non-consecutive
  return days.map(formatDay).join(', ');
}

/**
 * 3rd Wed
 * Mon, Tue
 */
function scheduledWeekly(rrule: RecurrenceRuleWeekly) {
  if (rrule.interval !== undefined && rrule.interval > 1) {
    return `${ordinal(rrule.interval)} ${weekDays(rrule.byDay)}`;
  }
  return weekDays(rrule.byDay);
}

/**
 * 3rd month on the 2nd
 * month on the first Thursday
 */
function scheduledMonthly(rrule: RecurrenceRuleMonthlyBySetPos | RecurrenceRuleMonthlyByMonthDay) {
  if ((rrule as RecurrenceRuleMonthlyByMonthDay).byMonthDay) {
    return `${unitWithCount(rrule.freq, rrule.interval)} on the ${ordinal(
      (rrule as RecurrenceRuleMonthlyByMonthDay).byMonthDay,
    )}`;
  }
  return `${unitWithCount(rrule.freq, rrule.interval)} on the ${
    SET_POS[(rrule as RecurrenceRuleMonthlyBySetPos).bySetPos]
  } ${formatDay((rrule as RecurrenceRuleMonthlyBySetPos).byDayForMonth)}`;
}

function scheduledBase(rrule: RecurrenceRule) {
  switch (rrule.freq) {
    case 'daily':
      return `every ${unitWithCount(rrule.freq, rrule.interval)}`;
    case 'weekly':
      return `every ${scheduledWeekly(rrule)}`;
    case 'monthly':
      return `every ${scheduledMonthly(rrule)}`;
    default:
      return '';
  }
}

function scheduled(
  rrule: RecurrenceRule,
  allDay: boolean,
  start: Date,
  duration: number,
  timezone: string,
) {
  let ret = scheduledBase(rrule);
  if (!allDay) {
    ret += ` from ${formatInTimeZone(start, timezone, 'p')} to ${formatInTimeZone(
      add(start, { minutes: duration }),
      timezone,
      'p z',
    )}`;
  }
  return ret;
}

function isComplete(rrule: RecurrenceRule) {
  if (!rrule || !rrule.type || !rrule.freq) {
    return false;
  }
  if (
    rrule.type === 'scheduled' &&
    rrule.freq === 'weekly' &&
    (!rrule.byDay || rrule.byDay.length < 1)
  ) {
    return false;
  }
  return true;
}

export default function rruleToString(
  rrule: RecurrenceRule | null,
  allDay: boolean,
  start: Date,
  duration: number,
  timezone: string,
) {
  if (!rrule || !rrule.type) {
    return "Doesn't repeat";
  }
  if (!isComplete(rrule)) {
    return '';
  }

  let rruleString = '';
  switch (rrule.type) {
    case 'scheduled':
      rruleString = `Scheduled ${scheduled(rrule, allDay, start, duration, timezone)}`;
      break;
    default:
      return '';
  }
  if (rrule.until) {
    rruleString += ` ends ${format(sub(parseISO(rrule.until), { days: 1 }), 'M/d')}`;
  }
  return rruleString;
}
