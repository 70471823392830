"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextSource = void 0;
let ContextSource = exports.ContextSource = function (ContextSource) {
  ContextSource["Staff"] = "Staff";
  ContextSource["Patient"] = "Patient";
  ContextSource["Automations"] = "Automations";
  ContextSource["Unknown"] = "Unknown";
  return ContextSource;
}({});