import { observer } from 'mobx-react';
import React from 'react';
import * as Yup from 'yup';

import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';

const VideoFeedbackForm = props => (
  <EditForm
    {...props}
    saveButtonText="Submit"
    validationSchema={Yup.object().shape({
      message: Yup.string(),
    })}
  >
    <div>
      Please share any details about what happened during the video call and what you or the patient
      experienced.
    </div>
    <Field
      name="message"
      component={TextControl}
      multiline
      label="DO NOT include any PHI here."
      onKeyDown={e => e.stopPropagation()}
    />
  </EditForm>
);

export default observer(VideoFeedbackForm);
