"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.length = length;
var _isArrayLikeObject = _interopRequireDefault(require("lodash/isArrayLikeObject"));
var _isNil = _interopRequireDefault(require("lodash/isNil"));
var _isPlainObject = _interopRequireDefault(require("lodash/isPlainObject"));
var _size = _interopRequireDefault(require("lodash/size"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function length(value) {
  if ((0, _isArrayLikeObject.default)(value) || (0, _isPlainObject.default)(value)) return (0, _size.default)(value);
  if ((0, _isNil.default)(value)) return 0;
  return 1;
}