import { gql } from 'src/generated/gql';

export const GET_NEXT_EVENT_CARD_QUERY = gql(`
  query staff_getNextEventCard($searchWindow: SearchWindowInput!, $providerId: ID!) {
    staff_getNextEventCard(searchWindow: $searchWindow, providerId: $providerId) {
      id
      requestId
      classification
      title
      start
      duration
      type
      subType
      visitReason
      patient {
        id
        firstName
        lastName
        preferredName
        profileUrl
        dspStatus
        lastVisit
        state
        carePlan
        fyi
        medicationRunOutDate
      }
    }
  }
`);

export const GET_MAIN_FOCUS_AREA_QUERY = gql(`
  query getMainFocusAreaQuery($searchWindow: SearchWindowInput!, $providerId: ID!) {
    staff_getNextEventCard(searchWindow: $searchWindow, providerId: $providerId) {
      id
      requestId
      classification
      title
      start
      duration
      type
      subType
      visitReason
      reasonDetails
      patient {
        id
        firstName
        lastName
        preferredName
        profileUrl
        dspStatus
        lastVisit
        state
        carePlan
        fyi
        medicationRunOutDate
      }
    }
    staff_getProviderUnsignedVisits(providerId: $providerId) {
      id
      attendeesDisplay {
        firstName
        lastName
        preferredName
        userType
      }
    }
  }
`);
