import { makeStyles } from '@material-ui/styles';
import React from 'react';

type AddressProps = {
  address: {
    // These might be coming from GraphQL, which is where the nulls come from
    street1?: string | null;
    street2?: string | null;
    streetLine1?: string | null;
    streetLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
  };
  displayInline?: boolean;
};

const Address: React.FC<AddressProps> = ({ address, displayInline }) => {
  const classes = useStyles();

  // streetLine1 & streetLine2 properties are for claims addresses
  const street1 = address.street1 || address.streetLine1;
  const street2 = address.street2 || address.streetLine2;
  return (
    <div className={displayInline ? classes.displayInline : classes.itemDetails}>
      {street1 && <span>{street1} </span>}
      {street2 && <span>{street2} </span>}
      {(address.city || address.state || address.zip) && (
        <span>
          {address.city && <>{address.city}</>}
          {address.city && address.state && <>, </>}
          {address.state && <>{address.state}</>}
          {(address.city || address.state) && <>&nbsp;</>}
          {address.zip && <>{address.zip}</>}
        </span>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  itemDetails: {
    '& > span': {
      display: 'table',
    },
  },
  displayInline: {
    display: 'inline',
  },
});

export default Address;
