import { addMinutes, format } from 'date-fns';
import React from 'react';

type VisitTimeFrameProps = {
  start: Date;

  /** The length of the visit in minutes */
  duration: number;
};

export const VisitTimeFrame = ({ start, duration }: VisitTimeFrameProps) => {
  const end = addMinutes(start, duration);
  const startMeridian = format(start, 'aaa');
  const endMeridian = format(end, 'aaa');

  return (
    <div>
      {format(start, 'EEEE, MMMM d')} &middot;{' '}
      {startMeridian === endMeridian &&
        `${format(start, 'h:mm')} - ${format(end, 'h:mm')} ${endMeridian}`}
      {startMeridian !== endMeridian &&
        `${format(start, 'h:mm')} ${startMeridian} - ${format(end, 'h:mm')} ${endMeridian}`}
    </div>
  );
};
