const qolOptions = [
  "Don't Know/Not Sure",
  'Declined to Answer',
  ...[...Array(31).keys()].map(String),
];

export default {
  type: 'object',
  title: 'Assessment: CDC Health-Related Quality of Life',
  disabled: true,
  properties: {
    generalHealth: {
      type: 'string',
      enum: [
        'Excellent',
        'Very Good',
        'Good',
        'Fair',
        'Poor',
        "Don't Know/Not Sure",
        'Declined to Answer',
      ],
      tooltip:
        '"Would you say that your general health is excellent, very good, good, fair, or poor?"',
    },
    physicalHealthDaysNotGood: {
      type: 'string',
      enum: qolOptions,
      title: 'Physical Health: Days Not Good',
      tooltip:
        '"Now thinking about your physical health, which includes physical illness and injury, for how many days during the past 30 days was your physical health NOT good?"',
    },
    mentalHealthDaysNotGood: {
      type: 'string',
      enum: qolOptions,
      title: 'Mental Health: Days Not Good',
      tooltip:
        '"Now thinking about your mental health, which includes stress, depression, and problems with emotions, for how many days during the past 30 days was your mental health NOT good?"',
    },
    daysKeptFromUsualActivities: {
      type: 'string',
      enum: qolOptions,
      title: 'Days Kept From Usual Activities',
      tooltip:
        '(Skip if above are both 0) "During the past 30 days, for about how many days did poor physical or mental health keep you from doing your usual activities, such as self-care, work, or recreation?"',
    },
  },
  required: [],
};
