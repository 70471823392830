import { Button, makeStyles } from '@material-ui/core';
import { EventNote } from '@material-ui/icons';
import React, { useCallback } from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import { BoulderColors } from 'src/util/brand';

type ViewChartButtonProps = {
  patientId: string;
};

export const ViewChartButton = ({ patientId }: ViewChartButtonProps) => {
  const styles = useStyles();

  const viewChart = useCallback(() => {
    const patientUrl = generateRouteUrl('showPatient', { id: patientId });
    window.open(patientUrl, '_blank');
  }, [patientId]);

  return (
    <Button className={styles.viewButton} onClick={() => viewChart()}>
      <EventNote />
      View Chart
    </Button>
  );
};

const useStyles = makeStyles({
  viewButton: {
    backgroundColor: BoulderColors.Blue3,
    color: 'white',
    '& svg': {
      marginRight: 6,
    },
    '&:hover': {
      backgroundColor: BoulderColors.Blue3,
    },
  },
});
