import { SvgIcon, makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';

import { ReactComponent as RequiredBadge } from 'src/nightingale/assets/required_badge.svg';
import LabelIcon from 'src/nightingale/components/ChartElement/LabelIcon';
import { useStyles as useLabeledFormControlStyles } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { LabelType } from 'src/nightingale/types/types';

const useStyles = makeStyles({
  cardHeaderContainer: {
    padding: 0,
  },
  badge: {
    right: -10,
    top: 3,
  },
  icon: {
    '&, :hover &&': {
      opacity: 1,
    },
  },
});

export const ChartElementLabel: React.FC<{
  labelType: LabelType;
  label?: string;
  hasMissingRequiredFields?: boolean;
}> = ({ labelType, label, hasMissingRequiredFields }) => {
  const styles = useStyles();
  const labelStyles = useLabeledFormControlStyles({ labelType });

  return (
    <CardHeader
      data-testid={`chart-element-${label}`}
      classes={{ title: labelStyles.header, root: styles.cardHeaderContainer }}
      title={
        <>
          <LabelIcon hasMissingRequiredFields={hasMissingRequiredFields} labelType={labelType} />
          {hasMissingRequiredFields && !labelType.includes('with-icon') ? (
            <Badge
              badgeContent={
                <SvgIcon className={styles.icon} component={RequiredBadge} fontSize="small" />
              }
              classes={{ anchorOriginTopRightRectangle: styles.badge }}
              data-testid="label-icon-required-badge"
            >
              {label}
            </Badge>
          ) : (
            label
          )}
        </>
      }
    />
  );
};
