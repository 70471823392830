import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import debounce from 'lodash/debounce';
import React, { useEffect, useState, useCallback, useMemo } from 'react';

const TextControl = ({
  field,
  form: { touched, errors },
  label,
  disabled,
  type = 'text',
  className,
  multiline,
  showValidationOnChange = false,
  ...props
}) => (
  <TextField
    {...props}
    {...field}
    type={type}
    className={className}
    value={field.value || ''}
    error={getIn(errors, field.name) && (getIn(touched, field.name) || showValidationOnChange)}
    helperText={(getIn(touched, field.name) || showValidationOnChange) && getIn(errors, field.name)}
    label={label}
    disabled={disabled}
    fullWidth
    multiline={multiline}
  />
);

export const DebouncedTextControl = ({
  field: { onChange, value: fieldValue, ...field },
  ...props
}) => {
  const [value, setValue] = useState(fieldValue);
  const debouncedOnChange = useMemo(() => debounce(onChange, 750), [onChange]);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue, setValue]);

  const internalOnChange = useCallback(
    (event, ...args) => {
      setValue(event.target.value);
      // react <= 16 pools events, so we must call persist to
      // tell it not to return this event back to the pool
      event.persist?.();
      debouncedOnChange(event, ...args);
    },
    [setValue, debouncedOnChange],
  );

  return <TextControl field={{ ...field, onChange: internalOnChange, value }} {...props} />;
};

export default TextControl;
