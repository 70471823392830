import { inject, observer } from 'mobx-react';
import React from 'react';

const ProviderCalendarLink = ({ children, rootStore: { generateRouteUrl, auth } }) => {
  const providerUrl = generateRouteUrl('showProvider', { id: auth.user.id });
  return (
    <a href={providerUrl} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default inject('rootStore')(observer(ProviderCalendarLink));
