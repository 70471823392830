import { LocationOn } from '@material-ui/icons';
import React from 'react';

import { BoulderColors } from 'src/util/brand';
import { STATES } from 'src/util/usStates';

type StateBadgeProps = {
  state: string;
};

const getAbbreviation = (state: string) => STATES[state]?.abbreviation ?? state;

export const StateBadge = ({ state }: StateBadgeProps) => {
  return (
    <div
      style={{
        border: `1px solid #466C8A55`,
        color: BoulderColors.Gray6,
        borderRadius: 4,
        display: 'flex',
        padding: 3,
        gap: 3,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        lineHeight: '100%',
      }}
    >
      <LocationOn style={{ width: 18, height: 18 }} />
      {getAbbreviation(state)}
    </div>
  );
};
