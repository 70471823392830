"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datetimeformat = datetimeformat;
var _dateFnsTz = require("date-fns-tz");
var _dateFns = require("date-fns");
const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
function datetimeformat(input, options) {
  const date = input ? (0, _dateFns.parseISO)(input) : null;
  return (0, _dateFns.isValid)(date) ? (0, _dateFnsTz.formatInTimeZone)(date, options.hash.timezone || localZone, "MMM d, yyyy 'at' p (zz)") : String(input);
}