import Typography from '@material-ui/core/Typography';
import React from 'react';

/**
 * Renders multiline text values with the label above.
 * @param {string} text - The text value of the field
 * @param {string} label - The label to display over the field
 * @param {Object} classes - An object containing class names
 * @param {string} classes.multiLineText - The class name to use for a
 *   multi-line text display
 */
const NotesWithHeader = ({ text, label, classes }) => {
  return text ? (
    <div>
      {label}:
      <Typography variant="body2" className={classes.multiLineText}>
        {text}
      </Typography>
      <br />
    </div>
  ) : null;
};

export default {
  type: 'object',
  title: 'Prescriber: Subjective (Follow Up)',
  properties: {
    currentRx: {
      type: 'object',
      title: 'Current RX',
      renderer: {
        component: ({ model, classes }) => (
          <div>
            {model.currentRx.rx && (
              <NotesWithHeader text={model.currentRx.rx} label="RX" classes={classes} />
            )}
            {model.currentRx.adherence && <p>Adherence: {model.currentRx.adherence}</p>}
            {model.currentRx.symptoms && (
              <NotesWithHeader
                text={model.currentRx.symptoms.text}
                label="Symptoms"
                classes={classes}
              />
            )}
            {model.currentRx.sideEffects && (
              <NotesWithHeader
                text={model.currentRx.sideEffects.text}
                label="Side Effects"
                classes={classes}
              />
            )}
            {model.currentRx.patientConcerns && (
              <NotesWithHeader
                text={model.currentRx.patientConcerns.text}
                label="Patient Concerns"
                classes={classes}
              />
            )}
          </div>
        ),
      },
      properties: {
        rx: {
          type: 'string',
          title: 'RX',
          uniforms: {
            multiline: true,
          },
        },
        adherence: {
          type: 'string',
          allowOther: true,
          enum: ['Taking as prescribed'],
        },
        symptoms: {
          type: 'object',
          properties: {},
          taggedText: true,
          hashTags: ['Denies withdrawal or cravings.'],
          default: {
            text: 'Denies withdrawal or cravings.',
            tags: ['Denies withdrawal or cravings.'],
          },
        },
        sideEffects: {
          type: 'object',
          properties: {},
          taggedText: true,
          hashTags: ['Denies sedation, constipation or mood effects of buprenorphine.'],
          tooltip: 'eg constipation, mood effects, sedation',
        },
        patientConcerns: {
          type: 'object',
          properties: {},
          taggedText: true,
          hashTags: ['Patient concerned that they may need increased dose.', 'None.'],
          default: {
            text: 'None.',
            tags: ['None.'],
          },
        },
      },
    },
    usage: {
      type: 'object',
      properties: {
        recentSubstanceUse: {
          type: 'object',
          properties: {},
          taggedText: true,
          renderer: {
            component: ({ model, classes }) => (
              <NotesWithHeader
                text={model.recentSubstanceUse.text}
                label="Recent substance use"
                classes={classes}
              />
            ),
          },
          hashTags: [
            'Denies use of other opioids.',
            'Denies using other substances or medications.',
          ],
          title: 'Recent substance use',
        },
        reportsIvDrugUse: {
          type: 'object',
          title: 'Reports IV drug use',
          format: 'selectable',
          renderer: {
            component: ({ model, classes }) => (
              <>
                {model.reportsIvDrugUse.selectionKey && (
                  <span>
                    Reports IV drug use:{' '}
                    {model.reportsIvDrugUse.selectionKey === 'yes' ? 'Yes' : 'No'}
                  </span>
                )}
                {model.reportsIvDrugUse.additional &&
                  (model.reportsIvDrugUse.additional.concernWithUse ||
                    model.reportsIvDrugUse.additional.notes) && (
                    <div className={classes.itemDetails}>
                      {model.reportsIvDrugUse.additional.concernWithUse && (
                        <span>
                          Concern for infections or complications related to use:{' '}
                          {model.reportsIvDrugUse.additional.concernWithUse}
                        </span>
                      )}
                      <span />
                      {model.reportsIvDrugUse.additional.notes && (
                        <Typography variant="body2" className={classes.multiLineText}>
                          {model.reportsIvDrugUse.additional.notes}
                        </Typography>
                      )}
                    </div>
                  )}
              </>
            ),
          },
          properties: {
            yes: {
              type: 'object',
              properties: {
                concernWithUse: {
                  type: 'string',
                  title: 'Concern for infections or complications related to use',
                  enum: ['Yes', 'No'],
                },
                notes: {
                  type: 'string',
                  uniforms: {
                    multiline: true,
                  },
                },
              },
            },
            no: true,
          },
        },
        previousMonthOpioidUsageDays: {
          type: 'integer',
          title: 'Previous month opioid usage (days)',
          renderer: {
            component: ({ model }) => (
              <p>Previous month opioid usage (days): {model.previousMonthOpioidUsageDays}</p>
            ),
          },
          tooltip:
            '"Of the last 30 days, how many times have you used opioids (other than any prescribed medications)?"',
          uniforms: {
            fullWidth: true,
            margin: 'dense',
          },
        },
      },
    },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
