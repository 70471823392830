import gql from 'graphql-tag';

export const REGENERATE_CLAIM = gql`
  mutation RegenerateClaim($claimId: ID!) {
    regenerateClaimById(claimId: $claimId) {
      id
    }
  }
`;

export const SUBMIT_CLAIM = gql`
  mutation SubmitClaim($claimData: JSON!, $claimId: ID) {
    submitClaim(claimData: $claimData, claimId: $claimId)
  }
`;

export const TRANSLATE_CLAIM = gql`
  mutation TranslateClaim($claimId: ID!) {
    translateClaim(claimId: $claimId) {
      id
    }
  }
`;
