import { inject } from 'mobx-react';
import React from 'react';

import ReferenceControl from 'src/components/forms/controls/reference';

const PayorReference = ({ rootStore, ...props }) => (
  <ReferenceControl
    {...props}
    labelFn={option => option.name}
    valueFn={option => option.key}
    loadOptions={rootStore.searchPayors}
    message="Payor"
  />
);

export default inject('rootStore')(PayorReference);
