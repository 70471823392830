import { ContextSource, evaluateBooleanExpression } from '@boulder-package/expressions';
import flatMap from 'lodash/flatMap';
import reduce from 'lodash/reduce';

import type { Context } from 'src/nightingale/summarization/types';
import type {
  AnyChartProperty,
  ChartElement,
  ChartOverviewSection,
  Condition,
  Flow,
  ListChartProperty,
} from 'src/nightingale/types/types';
import { ChartPropertyTypes } from 'src/nightingale/types/types';

/**
 * A map from chart entity IDs to whether they should be shown. A true or a missing entry both
 * indicate "do show it"; only an explicit false indicates "don't show it".
 */
export type ConditionalMap = Record<string, boolean>;

type ChartDef = AnyChartProperty | ChartElement | ChartOverviewSection | Flow;

const isListProperty = (def: ChartDef): def is ListChartProperty =>
  'type' in def && def.type === ChartPropertyTypes.List;

export function getConditions(def: ChartDef): Condition[] {
  /**
   * List properties' conditions can't be precomputed from outside an individual entry in the list,
   * because they want to show/hide child properties on a per-entry basis. Rather than evaluating
   * expressions whose output we can't trust, we'll just omit them here and deal with them in
   * list-specific code.
   */
  if (!('conditions' in def) || isListProperty(def)) {
    return [];
  }
  return [
    ...(def.conditions ?? []),
    ...('elements' in def ? flatMap(def.elements, getConditions) : []),
    ...('properties' in def ? flatMap(def.properties, getConditions) : []),
  ];
}

export function evaluateConditions(allConditions: Condition[], context: Context) {
  return reduce<Condition, ConditionalMap>(
    allConditions,
    (accum, condition) => ({
      ...accum,
      [condition.target.id]: evaluateBooleanExpression(condition.displayIf, {
        ...context,
        $source: ContextSource.Staff,
      }),
    }),
    {},
  );
}
