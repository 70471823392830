import { Theme, makeStyles } from '@material-ui/core';
import Input, { InputProps } from '@material-ui/core/Input';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ClassNameMap } from '@material-ui/styles';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { ReactComponent as NoIcon } from 'src/nightingale/assets/no.svg';
import { ReactComponent as YesIcon } from 'src/nightingale/assets/yes.svg';
import { IconButton } from 'src/nightingale/components/common/IconButton/IconButton';

const getSelectedBackgroundColor = (isSelected: boolean | null, disabled?: boolean) => {
  if (disabled && isSelected) return Colors.Gray5;
  if (disabled) return Colors.Gray3;
  if (isSelected) return Colors.BlueSpruce;
  return Colors.Gray3;
};

/**
 * Styles
 */
const useStyles = makeStyles<
  Theme,
  {
    isSelected: boolean | null;
    disabled?: boolean;
  }
>({
  container: {
    display: 'flex',
    marginTop: 5,
    alignItems: 'center',
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'default'),
  },
  iconText: {
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '145%',
    marginLeft: 16,
  },
  button: {
    height: 34,
    width: 34,
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
    margin: 5,
  },
  yesButton: {
    '&.MuiButtonBase-root': {
      backgroundColor: ({ disabled, isSelected }) =>
        getSelectedBackgroundColor(isSelected, disabled),
      '&:focus': {
        outline: `2px solid ${Colors.Stillwater}`,
      },
    },
  },
  noButton: {
    '&.MuiButtonBase-root': {
      backgroundColor: ({ disabled, isSelected }) =>
        getSelectedBackgroundColor(!isSelected && !isNil(isSelected), disabled),
      '&:focus': {
        outline: `2px solid ${Colors.Stillwater}`,
      },
    },
  },
  disabled: {
    '&.MuiButtonBase-root': {
      cursor: 'not-allowed',
      position: 'relative',

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '-10%',
        left: '50%',
        height: '120%',
        width: 2,
        transform: 'rotate(-45deg) translateX(-1px)',
        backgroundColor: Colors.White,
        transformOrigin: 'center center',
      },
    },
  },
  input: {
    '&, label  + &': {
      marginTop: 0,
    },
  },
});

/**
 * Extend props from Material UI
 * @src https://material-ui.com/api/input/
 */
export interface BooleanInputProps
  extends Pick<InputProps, 'autoFocus' | 'id' | 'disabled' | 'inputRef' | 'name'> {
  onChange: (value: boolean | null) => void;
  isSelected: boolean | null;
}
const getDisplayText = (isSelected: boolean | null): string => {
  if (isNil(isSelected)) return '';
  return isSelected ? 'Yes' : 'No';
};

const BooleanInputComponent: React.FC<{
  autoFocus?: boolean;
  disabled?: boolean;
  onNo: () => void;
  onYes: () => void;
  styles: ClassNameMap<'button' | 'container' | 'yesButton' | 'noButton' | 'iconText' | 'disabled'>;
  text: string;
}> = ({ autoFocus, disabled, onNo, onYes, styles, text }) => (
  <div className={styles.container}>
    <div data-testid="boolean-input">
      <IconButton
        autoFocus={autoFocus}
        data-testid="boolean-input-yes"
        isDisabled={disabled}
        onClick={onYes}
        classes={{ root: classNames(styles.button, styles.yesButton), disabled: styles.disabled }}
      >
        <SvgIcon component={YesIcon} viewBox="0.5 0 30 30" fontSize="large" />
      </IconButton>
      <IconButton
        data-testid="boolean-input-no"
        isDisabled={disabled}
        onClick={onNo}
        classes={{ root: classNames(styles.button, styles.noButton), disabled: styles.disabled }}
      >
        <SvgIcon component={NoIcon} viewBox="0 0 30 30" fontSize="large" />
      </IconButton>
    </div>
    <div>
      <span className={styles.iconText} data-testid="icon-text">
        {text}
      </span>
    </div>
  </div>
);

export const BooleanInput: React.FC<BooleanInputProps> = ({
  autoFocus,
  disabled,
  onChange,
  isSelected,
  ...rest
}) => {
  const styles = useStyles({ isSelected, disabled });

  const handleButtonPress = (value: boolean | null) => {
    if (!onChange) return;
    onChange(value);
  };

  return (
    <Input
      color="primary"
      disableUnderline
      disabled={disabled}
      fullWidth
      margin="dense"
      classes={{ root: styles.input }}
      inputComponent={BooleanInputComponent}
      inputProps={{
        autoFocus,
        disabled,
        onNo: () => handleButtonPress(false),
        onYes: () => handleButtonPress(true),
        styles,
        text: getDisplayText(isSelected),
      }}
      {...rest}
    />
  );
};
