import Typography from '@material-ui/core/Typography';
import React from 'react';

import v100Schema from 'src/components/forms/schemas/eventResult/prescriberSubjectiveFollowUp/1.0.0';

const { ...v100Properties } = v100Schema.properties;

export default {
  ...v100Schema,
  properties: {
    chiefComplaint: {
      type: 'object',
      title: 'Chief Complaint',
      renderer: {
        component: ({ model }) => {
          const {
            interestedInSuboxone,
            interestedInAudMedications,
            followUpSuboxone,
            followUpAudMedication,
            other,
            otherNotes,
          } = model.chiefComplaint;
          return (
            <Typography variant="body2">
              <div>{interestedInSuboxone && 'Interested in starting Suboxone: Yes'}</div>
              <div>{interestedInAudMedications && 'Interested in AUD medications: Yes'}</div>
              <div>{followUpSuboxone && 'Follow up Suboxone: Yes'}</div>
              <div>{followUpAudMedication && 'Follow up AUD medication: Yes'}</div>
              <div>{other && otherNotes && `Other: ${otherNotes}`}</div>
            </Typography>
          );
        },
      },
      properties: {
        interestedInSuboxone: {
          type: 'boolean',
          title: 'Interested in starting Suboxone',
        },
        interestedInAudMedications: {
          type: 'boolean',
          title: 'Interested in AUD medications',
        },
        followUpSuboxone: {
          type: 'boolean',
          title: 'Follow up Suboxone',
        },
        followUpAudMedication: {
          type: 'boolean',
          title: 'Follow up AUD medication',
        },
        other: {
          type: 'boolean',
          title: 'Other',
        },
        otherNotes: {
          type: 'string',
          title: 'Other Notes',
          dependsOn: 'other',
          displayOnDependent: other => other,
          changeOnDependent: (currentValue, other) => (other ? currentValue : ''),
          uniforms: {
            type: 'string',
          },
        },
      },
    },
    ...v100Properties,
  },
  required: [],
};
