import React from 'react';

import { multilineStringDef, Rx } from 'src/components/forms/schemas/util';

function rxOptionDef(title: string, rxKey: string, notesKey: string) {
  return {
    type: 'object',
    title,
    renderer: {
      component: ({ model, classes }) => {
        return <Rx rxText={title} rx={model[rxKey]} notes={model[notesKey]} classes={classes} />;
      },
    },
    properties: {
      [rxKey]: {
        title: 'RX',
        ...multilineStringDef,
      },
      [notesKey]: {
        title: 'Notes',
        ...multilineStringDef,
      },
    },
  };
}
export default {
  type: 'object',
  title: 'Prescriber: Plan (Reengagement)',
  properties: {
    rx: {
      type: 'object',
      format: 'selectable',
      properties: {
        resumePreviousRx: rxOptionDef('Resume previous prescription', 'resumeRx', 'resumeRxNotes'),
        resumePreviousRxAtNewDose: rxOptionDef(
          'Resume prescription at new dose or formulation',
          'resumeRxNewDose',
          'resumeRxNewDoseNotes',
        ),
        newRx: rxOptionDef('New prescription', 'newRx', 'newRxNotes'),
      },
    },
    oftMamPillCount: {
      title: 'OFT/MAM/Pill Count',
      ...multilineStringDef,
    },
    followup: {
      type: 'string',
      title: 'Follow Up',
      allowOther: true,
      enum: ['1 week', '2 weeks', '3 weeks', '4 weeks', '1 month', '2 months', '3 months'],
    },
    patientReview: {
      type: 'object',
      renderer: {
        component: ({ model, classes }) => (
          <div className={classes.itemDetails}>
            <span>
              Reviewed medication safety:{' '}
              {model.patientReview.medicationSafety === true ? 'Yes' : 'No'}
            </span>
            <span>
              Reviewed Narcan access: {model.patientReview.narcanAccess === true ? 'Yes' : 'No'}
            </span>
            <span>
              Reviewed how to contact Care Team:{' '}
              {model.patientReview.howToContact === true ? 'Yes' : 'No'}
            </span>
            <span>
              Peer Coach notified of reengagement:{' '}
              {model.patientReview.peerCoachNotified === true ? 'Yes' : 'No'}
            </span>
          </div>
        ),
      },
      properties: {
        medicationSafety: {
          type: 'boolean',
          title:
            'Reviewed medication safety (safe storage, risk of alcohol or benzodiazepine use, other risks and benefits)',
        },
        narcanAccess: {
          type: 'boolean',
          title: 'Reviewed Narcan access',
        },
        howToContact: {
          type: 'boolean',
          title: 'Reviewed how to contact Care Team',
        },
        peerCoachNotified: {
          type: 'boolean',
          title: 'Peer Coach notified of reengagement',
        },
      },
    },
    notes: multilineStringDef,
  },
};
