import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';

import {
  states,
  INQUIRY_FORM_REQUEST_TYPES,
  PAYMENT_TYPES,
} from 'src/components/forms/schemas/definitions';
import Documents from 'src/components/general/documents';
import { isLocalhost } from 'src/shared/util/helpers';
import { parseUnknownDate } from 'src/util/parseUnknownDate';

const DateTime = ({ model, key }) => (
  <>
    {model[key] && (
      <Typography variant="body1">
        {format(parseUnknownDate(model[key]), 'eee, MMM d, yyyy p z')}
      </Typography>
    )}
  </>
);

export default {
  type: 'object',
  title: 'Inquiry: Form Details',
  disabled: !isLocalhost,
  disableEdit: true,
  properties: {
    requestType: {
      type: 'string',
      enum: INQUIRY_FORM_REQUEST_TYPES,
    },
    goals: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
    state: {
      type: 'string',
      enum: states,
    },
    insuranceType: {
      type: 'string',
      enum: ['Commercial', 'Medicare/Medicaid', 'Uninsured', 'Selfpay'],
    },
    potentialParticipantsRelationshipToInsuranceHolder: {
      type: 'string',
    },
    treatmentType: {
      type: 'string',
    },
    firstName: {
      type: 'string',
    },
    preferredName: {
      type: 'string',
    },
    lastName: {
      type: 'string',
    },
    pronouns: {
      type: 'string',
    },
    gender: {
      type: 'string',
    },
    genderDiffersFromID: {
      type: 'boolean',
    },
    birthdate: {
      type: 'string',
    },
    phoneNumber: {
      type: 'string',
      format: 'phone',
    },
    email: {
      type: 'string',
      format: 'email',
    },
    communicationOptIn: {
      type: 'string',
    },
    streetAddress: {
      type: 'string',
    },
    city: {
      type: 'string',
    },
    homeState: {
      type: 'string',
    },
    zip: {
      type: 'string',
    },
    currentlyHouseless: {
      type: 'string',
    },
    differentMailingaddress: {
      type: 'string',
    },
    mailingStreetAddress: {
      type: 'string',
    },
    mailingCity: {
      type: 'string',
    },
    mailingState: {
      type: 'string',
    },
    mailingZip: {
      type: 'string',
    },
    pharmacyName: {
      type: 'string',
    },
    pharmacyAddress: {
      type: 'string',
    },
    paymentType: {
      type: 'string',
      renderer: {
        component: ({ model }) => model.paymentType && <>{PAYMENT_TYPES[model.paymentType]}</>,
      },
    },
    referralCode: {
      type: 'string',
    },
    requestedVisitTime: {
      type: 'string',
      renderer: {
        component: DateTime,
      },
    },
    additional: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
    otherTreatment: {
      type: 'string',
    },
    interestFromParticipant: {
      type: 'string',
    },
    relationshipToParticipant: {
      type: 'string',
    },
    referrerFirstName: {
      type: 'string',
    },
    referrerLastName: {
      type: 'string',
    },
    referrerPracticeName: {
      type: 'string',
    },
    referrerPhoneNumber: {
      type: 'string',
      format: 'phone',
    },
    referralSourceType: {
      type: 'string',
    },
    formDocuments: {
      type: 'array',
      title: 'Attached Files',
      displayOnly: true,
      renderer: {
        component: ({ model }) => {
          if (!model.formDocuments) {
            return null;
          }
          return (
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <Documents documents={model.formDocuments} />
            </ul>
          );
        },
      },
    },
    formSubmissionTime: {
      type: 'string',
      renderer: {
        component: DateTime,
      },
    },
    utm_medium: {
      type: 'string',
    },
    utm_source: {
      type: 'string',
    },
    utm_campaign: {
      type: 'string',
    },
    utm_content: {
      type: 'string',
    },
    utm_term: {
      type: 'string',
    },
    session_id: {
      type: 'string',
    },
    user_id: {
      type: 'string',
    },
  },
};
