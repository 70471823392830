"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InteractionKind = void 0;
let InteractionKind = exports.InteractionKind = function (InteractionKind) {
  InteractionKind["Visit"] = "VISIT";
  InteractionKind["Pebble"] = "PEBBLE";
  InteractionKind["Task"] = "TASK";
  InteractionKind["Automation"] = "AUTOMATION";
  return InteractionKind;
}({});