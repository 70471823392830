import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import type { Instance, SnapshotOut } from 'mobx-state-tree';
import React from 'react';
import * as Yup from 'yup';

import DateAndTimesControl from 'src/components/forms/controls/dateAndTimes';
import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import type { EditFormProps } from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import FindATimeControl from 'src/scheduling/components/findATime';
import {
  SELECTABLE_SCHEDULE_CHANGE_REASONS,
  isAllDay,
  isPlaceholderEvent,
  scheduleSummary,
} from 'src/shared/util/events';
import type { EventInstance } from 'src/stores/models/event';

const RESCHEDULE_EVENT_VALIDATOR = Yup.object().shape({
  scheduleChangeReason: Yup.string()
    .oneOf([...Object.keys(SELECTABLE_SCHEDULE_CHANGE_REASONS)])
    .nullable()
    .required(),
  start: Yup.date().nullable().required(),
  duration: Yup.number()
    .integer()
    .nullable()
    .min(0, 'invalid time')
    .when('type', {
      is: type => !isAllDay(type),
      then: Yup.number().required(),
    }),
  timezone: Yup.string()
    .nullable()
    .when('type', {
      is: type => !isAllDay(type),
      then: Yup.string().required(),
    }),
  scheduleChangeNotes: Yup.string().nullable(),
});

const RescheduleEvent: React.FC<{
  disableDatepicker: boolean;
  item: Instance<EventInstance> | SnapshotOut<EventInstance>;
  onCancel?: EditFormProps['onCancel'];
  onSave?: EditFormProps['onSave'];
}> = ({ disableDatepicker, item, onCancel, onSave }) => {
  const classes = useStyles();
  return (
    <EditForm
      // The item prop is used to set initial values in the formik form
      item={{
        ...item,
        ...(isPlaceholderEvent(item) ? { scheduleChangeReason: 'scheduling_error' } : {}),
      }}
      onCancel={onCancel}
      onSave={onSave}
      validationSchema={RESCHEDULE_EVENT_VALIDATOR}
    >
      <Field
        component={SelectControl}
        label="Reason *"
        labelFn={option => SELECTABLE_SCHEDULE_CHANGE_REASONS[option].label}
        name="scheduleChangeReason"
        options={Object.keys(SELECTABLE_SCHEDULE_CHANGE_REASONS)}
        valueFn={option => option}
      />
      {disableDatepicker ? (
        <>
          <div className={classes.label}>Rescheduled To:</div>
          <div className={classes.disabled}>{scheduleSummary(item.rescheduledTo)}</div>
        </>
      ) : (
        <>
          <Field
            allDay={() => isAllDay(item.type)}
            component={DateAndTimesControl}
            durationName="duration"
            label="Reschedule To *"
            startName="start"
            timezoneName="timezone"
          />
          <Field>
            {({ form }) => (
              <FindATimeControl
                attendees={form.values.attendees}
                duration={form.values.duration}
                setFieldValue={form.setFieldValue}
                start={form.values.start}
              />
            )}
          </Field>
        </>
      )}
      <Field component={TextControl} label="Notes" name="scheduleChangeNotes" />
    </EditForm>
  );
};

const useStyles = makeStyles({
  label: {
    color: 'rgba(0, 0, 0, 0.38)',
    marginBottom: 10,
    textTransform: 'uppercase',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  disabled: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.38)',
    color: 'rgba(0, 0, 0, 0.38)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    paddingBottom: 5,
  },
});

export default observer(RescheduleEvent);
