import { makeStyles } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { PatientAvatar } from 'src/patientHeader/components/PatientAvatar';
import { PatientHeaderButtons } from 'src/patientHeader/components/PatientHeaderButtons';
import { PatientIdentity } from 'src/patientHeader/components/PatientIdentity';
import { usePatientHeaderData } from 'src/patientHeader/hooks/usePatientHeaderData';

export const PatientHeader = ({
  patientId,
  activeTab,
}: {
  patientId: string;
  activeTab: string;
}) => {
  const classes = useStyles();

  const { patient } = usePatientHeaderData(patientId);
  if (!patient) return null;

  return (
    <div className={classes.headerContainer}>
      <PatientAvatar patient={patient} />
      <PatientIdentity patient={patient} />
      <PatientHeaderButtons patientId={patientId} activeTab={activeTab} />
    </div>
  );
};

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'stretch',
    width: '100%',
    minHeight: 80,
    backgroundColor: Colors.Stormcloud,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.Gray3,
    top: 0,
    zIndex: 2,
    padding: '8px 25px 8px 25px',
    gap: 10,
    boxSizing: 'border-box',
  },
});
