import { makeStyles } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

interface ErrorAlertProps {
  message: string;
  error?: any;
  title?: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message, error, title }) => {
  const styles = useStyles();

  const alertMessage = `${message} Please file a helpdesk ticket and include a screenshot of this error (if no PHI is
    in the screenshot).`;

  return (
    <Alert
      classes={{ root: styles.container, message: styles.message }}
      data-testid="error-container"
      severity="error"
    >
      {title && <AlertTitle classes={{ root: styles.title }}>{title}</AlertTitle>}
      {alertMessage}
      <pre className={styles.code}>
        <code>{error?.toString() || error?.message || JSON.stringify(error, null, 2)}</code>
      </pre>
    </Alert>
  );
};

const useStyles = makeStyles({
  container: {
    marginBottom: 20,
    fontFamily: 'Nunito Sans',
  },
  title: {
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
  },
  message: {
    fontSize: 16,
  },
  code: {
    whiteSpace: 'pre-wrap',
  },
});

export default ErrorAlert;
