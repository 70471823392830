import { format } from 'date-fns';
import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { KeyedMutator } from 'swr';

import { RequestTaskButton } from 'src/components/pages/patientShow/documents/RequestTaskButton';
import type {
  PatientDocumentsResponse,
  TreatmentAgreement,
} from 'src/components/pages/patientShow/documents/types';
import { ApolloClientContext } from 'src/data/ApolloClientContext';

// When updating also remember to update the server at src/patientAgreements/patientAgreements.eventListener.ts
const LATEST_TREATMENT_AGREEMENT_DOCUMENT_VERSION = '4';

export const TreatmentAgreementRequest: React.FC<{
  patientId: string;
  treatmentAgreement: TreatmentAgreement | null | undefined;
  onRequest: KeyedMutator<PatientDocumentsResponse>;
}> = ({ patientId, treatmentAgreement, onRequest }) => {
  const { apolloClient } = useContext(ApolloClientContext);

  const requestTreatmentAgreement = async () => {
    await apolloClient?.mutate({
      mutation: gql`
        mutation ($data: PatientInput!, $id: ID!) {
          updatePatient(data: $data, id: $id) {
            id
          }
        }
      `,
      variables: {
        data: {
          treatmentAgreement: {
            documentVersion: LATEST_TREATMENT_AGREEMENT_DOCUMENT_VERSION,
          },
        },
        id: patientId,
      },
    });
    onRequest();
  };

  if (treatmentAgreement?.completedAt) {
    return <span>Signed on {format(new Date(treatmentAgreement.completedAt), 'PP')}</span>;
  } else if (treatmentAgreement?.createdAt) {
    return <span>Requested on {format(new Date(treatmentAgreement.createdAt), 'PP')}</span>;
  } else {
    return (
      <RequestTaskButton onClick={requestTreatmentAgreement} text="Request Treatment Agreement" />
    );
  }
};

export default TreatmentAgreementRequest;
