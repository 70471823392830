import { addMinutes, isAfter, isBefore, subMinutes } from 'date-fns';

import type { EventInstance } from 'src/stores/models/event';

/**
 * Determines if a given calendar slot time should be represented as part of the given event
 *
 * @param {Date} slotTime Date representing the time of a react-big-calendar slot
 * @param {Event} event The Event (MST model) to check
 * @returns {Boolean} Whether or not the slot is part of the event
 */
export const isSlotDuringEvent = (slotTime: Date, event: EventInstance): boolean => {
  if (!event.start || !event.duration) {
    // If there's no duration, it's an all-day event, in which case it doesn't overlap with any
    // slots ever. There *should* always be a start in this context, but TS wants us to check.
    return false;
  }
  return (
    isAfter(slotTime, subMinutes(event.start, 1)) &&
    isBefore(slotTime, addMinutes(event.start, event.duration))
  );
};
