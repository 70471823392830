import React from 'react';

import { EventTypeLabel } from 'src/myDayToday/components/EventSubType';
import { useScheduledEventCardStyles } from 'src/myDayToday/components/ScheduledEventCard.styles';
import { ScheduledEventCardHeader } from 'src/myDayToday/components/ScheduledEventCardHeader';
import { VisitTimeFrame } from 'src/myDayToday/components/VisitTimeFrame';
import { useScheduledEventTiming } from 'src/myDayToday/components/useScheduledEventTiming';

type PartialEvent = {
  duration: number;
  start: Date;
  subType: string;
  title?: string | null;
};

type NonPatientEventCardProps = {
  currentTime: Date;
  event: PartialEvent;
};

const NonPatientEventCard = (props: NonPatientEventCardProps) => {
  const classes = useScheduledEventCardStyles();

  const eventTiming = useScheduledEventTiming(props.currentTime, props.event.start);

  const containerClasses = [classes.container, eventTiming];

  return (
    <div className={containerClasses.join(' ')} data-testid="scheduled-non-patient-event-card">
      <div className="header">
        <ScheduledEventCardHeader
          currentTime={props.currentTime}
          start={props.event.start}
          timing={eventTiming}
        />
      </div>
      <div className="body">
        <div className="details">
          <div className="nonPatientEventType">
            <EventTypeLabel subType={props.event.subType} />
          </div>
          <div className="nonPatientEventTitle">{props.event.title}</div>
          <div className="visitTimeFrame">
            <VisitTimeFrame start={props.event.start} duration={props.event.duration} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonPatientEventCard;
