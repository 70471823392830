import { useContext, useMemo } from 'react';

import { ChartContext } from 'src/nightingale/components/ChartContext/ChartContext';
import { ChartInteractionContext } from 'src/nightingale/components/ChartInteraction/ChartInteractionContext';
import RequiredChartProperties from 'src/nightingale/requiredChartProperties/RequiredChartProperties';
import { RequiredChartPropertiesContext } from 'src/nightingale/requiredChartProperties/RequiredChartPropertiesContext';

/**
 * React hook that provides a RequiredChartProperties API based on the context
 *
 * A "context" here is the combined chart context and e.g. the Flow where required fields
 * are defined.
 *
 * If any Context state is unavailable, the API will simply be without the information until
 * re-render (i.e. reporting fields as not required in the meantime).
 */
export default function useRequiredChartProperties(): RequiredChartProperties {
  const requiredChartPropsContext = useContext(RequiredChartPropertiesContext);
  const chartContext = useContext(ChartContext);
  const { interactionValues } = useContext(ChartInteractionContext);

  return useMemo(
    () =>
      new RequiredChartProperties(
        requiredChartPropsContext.requiredChartProperties ?? [],
        chartContext.interactionId,
        interactionValues ?? [],
      ),
    [
      requiredChartPropsContext.requiredChartProperties,
      chartContext.interactionId,
      interactionValues,
    ],
  );
}
