import type IMask from 'imask';
import omit from 'lodash/omit';
import React from 'react';
import { IMaskInput } from 'react-imask';

/**
 * Text input that provides a prettfied format for phone numbers.
 *
 * NOTE: This component ignores its onChange prop! The prop is allowed, in order to maintain a
 * compatible interface with material-ui, but it will have no effect, because passing onChange
 * handlers to the underlying imask library causes inconsistent behavior. Instead, provide an
 * onAccept handler; its third argument will be a DOM event comparable to the ones provided for
 * onChange.
 */
interface PhoneInputProps {
  onAccept: (
    value: string,
    maskRef: IMask.InputMask<IMask.AnyMaskedOptions>,
    e: InputEvent,
  ) => void;
}

export default function PhoneInput(props: PhoneInputProps) {
  return (
    <IMaskInput {...omit(props, 'onChange')} mask="(000) 000-0000" unmask placeholder={'\u2000'} />
  );
}
