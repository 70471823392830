"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.age = age;
var _dateFns = require("date-fns");
function age(dob) {
  const today = new Date();
  const birthdate = (0, _dateFns.parseISO)(dob);
  if (!(0, _dateFns.isValid)(birthdate)) return '';
  const years = (0, _dateFns.differenceInYears)(today, birthdate);
  return `${years}`;
}