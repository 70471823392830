import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import { Formik } from 'formik';
import React, { Component } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import TextControl from 'src/components/forms/controls/text';
import Field from 'src/components/forms/field';
import InviteViaCode from 'src/components/pages/pageElements/videoConference/InviteViaCode';
import { REQUEST_A_PEER } from 'src/featureFlags/currentFlags';
import { EMAIL_INVITE_VALIDATOR } from 'src/shared/client/forms/validation/videoInvite';
import logger from 'src/shared/util/logger';
import { colors } from 'src/util/colors';

/**
 * Used to signify the tab hasn't been changed, and we should have the default tab selected
 *
 * The actual default relies on the feature flags, so we need to use this temporary value
 * until we can determine the actual default tab.
 */
const DEFAULT_TAB = 'default';

class InviteGuestModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: DEFAULT_TAB,
      copied: false,
      submitted: false,
    };
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  handleChangeTab = (evt, value) => {
    this.setState({ tab: value });
  };

  copyVideoURL = async guestUrl => {
    try {
      await navigator.clipboard.writeText(guestUrl);
      this.setState({ copied: true });
    } catch (err) {
      logger.error('Failed to copy: ', err);
    }
  };

  sendEmailInvite = async (values, actions) => {
    try {
      await this.props.inviteGuestByEmail(values.email);
      actions.setSubmitting(false);
      this.setState({ submitted: true });
    } catch {
      actions.setSubmitting(false);
      actions.setFieldError(
        'email',
        'An unexpected error occurred and the email could not be sent. \nPlease close the modal and try again.',
      );
    }
  };

  sendPeerInvite = async (values, actions) => {
    try {
      await this.props.invitePeerWithReason(values.peerReason);
      actions.setSubmitting(false);
      this.setState({ submitted: true });
    } catch {
      actions.setSubmitting(false);
      actions.setFieldError(
        'peerReason',
        'An unexpected error occurred and the peer invite request could not be sent. \nPlease close the modal and try again.',
      );
    }
  };

  static contextType = FeatureFlagContext;

  render() {
    const { guestUrl, classes, onCancel } = this.props;
    const { tab: tabState, copied, submitted } = this.state;

    const flags = this.context;
    const isRequestAPeerEnabled = flags[REQUEST_A_PEER];

    // eslint-disable-next-line no-nested-ternary
    const tab = tabState === DEFAULT_TAB ? (isRequestAPeerEnabled ? 'peer' : 'code') : tabState;

    return (
      <Dialog open fullWidth onClose={onCancel}>
        <Paper elevation={2} variant="outlined">
          <Tabs
            className={classes.tabs}
            value={tab}
            onChange={this.handleChangeTab}
            variant="fullWidth"
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: 'white',
              },
            }}
          >
            {isRequestAPeerEnabled && <Tab label="Peer" value="peer" />}
            <Tab label="Code" value="code" />
            <Tab label="Email" value="email" />
            <Tab label="URL" value="url" />
          </Tabs>
        </Paper>
        <DialogTitle>Invite a guest to join this visit</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {tab === 'email' && (
            <Formik
              initialValues={{ email: '' }}
              onSubmit={(values, actions) => this.sendEmailInvite(values, actions)}
              validationSchema={EMAIL_INVITE_VALIDATOR}
            >
              {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <form className={classes.twoColumn}>
                  <Field name="email" component={TextControl} label="Email" />
                  <Button
                    className={classes.rightButton}
                    color="primary"
                    size="medium"
                    variant="contained"
                    disabled={!isValid || !dirty || isSubmitting || submitted}
                    padding=" "
                    onClick={handleSubmit}
                  >
                    {isSubmitting && <CircularProgress />}
                    {submitted && <Check />}
                    {!isSubmitting && <span>{submitted ? 'Sent' : 'Email Link'}</span>}
                  </Button>
                </form>
              )}
            </Formik>
          )}
          {tab === 'url' && (
            <div className={classes.twoColumn}>
              <div>
                <span className={classes.urlLabel}>url</span>
                <p className={classes.urlText}>{guestUrl}</p>
              </div>
              <Button
                className={classes.rightButton}
                size="medium"
                color="primary"
                variant="contained"
                onClick={() => this.copyVideoURL(guestUrl)}
                disabled={copied}
              >
                {copied && <Check />}
                {copied ? 'Copied' : 'Copy Link'}
              </Button>
            </div>
          )}
          {tab === 'code' && <InviteViaCode eventId={this.props.eventId} onJoin={onCancel} />}
          {isRequestAPeerEnabled && tab === 'peer' && (
            <Formik
              initialValues={{ peerReason: '' }}
              onSubmit={(values, actions) => this.sendPeerInvite(values, actions)}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form className={classes.twoColumn}>
                  <Field name="peerReason" component={TextControl} label="Reason (optional)" />
                  <Button
                    className={classes.rightButton}
                    color="primary"
                    size="medium"
                    variant="contained"
                    disabled={isSubmitting || submitted}
                    padding=" "
                    onClick={handleSubmit}
                  >
                    {isSubmitting && <CircularProgress />}
                    {submitted && <Check />}
                    {!isSubmitting && <span>{submitted ? 'Sent' : 'Invite'}</span>}
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = () => ({
  dialogContent: {
    padding: '0 24px 24px', // Keep old material UI settings
  },
  urlLabel: {
    color: 'grey',
    textTransform: 'uppercase',
  },
  urlText: {
    wordBreak: 'break-word',
  },
  tabs: {
    backgroundColor: colors.primary,
    color: 'white',
  },
  twoColumn: {
    display: 'flex',
  },
  rightButton: {
    height: '100%',
    marginLeft: 30,
    minWidth: 120,
  },
});

export default withStyles(styles)(InviteGuestModal);
