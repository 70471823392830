import React, { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Context for pages having to do with the patient
 */
export const PatientShowContext = React.createContext<{
  /** The thing that scrolls in this part of the page */
  pageContentsRef?: React.RefObject<HTMLDivElement>;
  /** The fragment, representing which chart element to scroll to */
  urlFragment?: string;
}>({});

export const PatientShowContextProvider: React.FC = ({ children }) => {
  const pageContentsRef = useRef<HTMLDivElement>(null);
  const [urlFragment, setUrlFragment] = useState<string | undefined>(
    getUrlFragment(window.location.hash),
  );

  const handleHashChange = useCallback(() => {
    setUrlFragment(getUrlFragment(window.location.hash));
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [handleHashChange]);

  return (
    <PatientShowContext.Provider
      value={{
        pageContentsRef,
        urlFragment,
      }}
    >
      {children}
    </PatientShowContext.Provider>
  );
};

/**
 * Gets the the URL fragment (hash without the '#')
 *
 * @returns The fragment or undefined if there isn't one
 */
function getUrlFragment(hash: string): string | undefined {
  if (!hash?.includes('#')) return undefined;
  return hash.replace('#', '');
}
