import { GET_VISIT_REASON } from 'src/components/forms/hooks/useVisitReason.gql';
import useTypedSWR from 'src/components/general/useTypedSWR';

export const useVisitReason = (eventId: string | undefined | null): { details: any } | null => {
  const { data } = useTypedSWR([
    eventId ? GET_VISIT_REASON : null,
    {
      eventId,
    },
  ]);

  if (!data) {
    return null;
  }

  return data.staff_getVisitReasonFromEvent;
};
