import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { getSnapshot } from 'mobx-state-tree';
import React, { ComponentPropsWithoutRef, MouseEventHandler } from 'react';

import AttendeeControl from 'src/components/forms/controls/attendee';
import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import { useVisitReason } from 'src/components/forms/hooks/useVisitReason';
import FieldGroup from 'src/components/general/fieldGroup';
import RouteLink from 'src/components/general/routeLink';
import { VisitReason } from 'src/dropInClinic/visitReason/VisitReason';
import {
  EVENT_DETAILS_VALIDATOR,
  isPatientRequired,
} from 'src/shared/client/forms/validation/event';
import { labelForEventType } from 'src/shared/util/events';
import { EventInstance } from 'src/stores/models/event';

const EventSubType = ({ eventType, subType, label }) => (
  <FieldGroup label={label}>
    <Typography variant="body2">{labelForEventType(eventType, subType)}</Typography>
  </FieldGroup>
);

const AttendeeList = ({ attendees, label, classes }) => (
  <FieldGroup label={label}>
    <Typography variant="body2">
      {attendees.map((attendee, index) => (
        <RouteLink
          route={attendee.__typename === 'Provider' ? 'showProvider' : 'showPatient'}
          routeParams={[{ id: attendee.id }]}
          key={attendee.id}
          className={classes.attendees}
          newWindow
        >
          {attendee.preferredFullName}
          {index + 1 < attendees.length ? ', ' : ''}
        </RouteLink>
      ))}
    </Typography>
  </FieldGroup>
);

const ViewEventDetails: React.FC<{
  event: EventInstance;
}> = ({ event: { id, title, attendees } }) => {
  const visitReason = useVisitReason(id);
  const styles = useEventDetailsFormStyles();

  const providers = attendees.filter(attendee => attendee.__typename === 'Provider');
  return (
    <>
      {title && (
        <FieldGroup label="Title">
          <Typography style={{ wordBreak: 'break-word' }} variant="body2">
            {title}
          </Typography>
        </FieldGroup>
      )}
      {!!providers.length && (
        <AttendeeList attendees={providers} label="Providers" classes={styles} />
      )}

      {visitReason && (
        <div className={styles.visitReason}>
          <div className="formLabel">Visit Reason</div>
          <VisitReason visitReasonDetails={visitReason.details} />
        </div>
      )}
    </>
  );
};

const FormFields = ({ eventType, subType, fixedAttendees }) => {
  const styles = useFormFieldStyles();

  return (
    <>
      <EventSubType eventType={eventType} subType={subType} label="Visit Type" />
      <Field
        name="title"
        className={styles.formComponent}
        component={TextControl}
        label="Title"
        onKeyDown={e => e.stopPropagation()}
      />
      <div className={styles.formComponent}>
        <Field
          name="attendees"
          component={AttendeeControl}
          fixed={fixedAttendees.map(attendee => getSnapshot(attendee))}
          patientRequired={isPatientRequired(subType)}
        />
      </div>
    </>
  );
};

const useFormFieldStyles = makeStyles({
  formComponent: {
    marginTop: 24,
  },
});

const EditEventDetails: React.FC<{
  event: EventInstance;
  onSave?: (values: unknown) => Promise<unknown>;
  onCancel?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  formId?: string;
  savingDisabled?: boolean;
}> = ({ event, onSave, onCancel, formId, savingDisabled }) => {
  const styles = useEventDetailsFormStyles();
  const patientAttendees = event?.attendees.filter(attendee => attendee?.__typename === 'Patient');

  return (
    <EditForm
      item={event}
      classes={{
        buttons: styles.buttons,
        button: styles.button,
      }}
      validationSchema={EVENT_DETAILS_VALIDATOR}
      onSave={onSave}
      onCancel={onCancel}
      buttonSize="small"
      formId={formId}
      savingDisabled={savingDisabled}
    >
      <FormFields
        eventType={event.type}
        subType={event.subType}
        fixedAttendees={patientAttendees}
      />
    </EditForm>
  );
};

type EditEventProps = ComponentPropsWithoutRef<typeof EditEventDetails>;
type ViewEventProps = ComponentPropsWithoutRef<typeof ViewEventDetails>;

const EventDetailsForm: React.FC<
  ({ isEditing: true } & EditEventProps) | ({ isEditing?: false } & ViewEventProps)
> = ({ isEditing, ...props }) =>
  isEditing ? <EditEventDetails {...props} /> : <ViewEventDetails {...props} />;

const useEventDetailsFormStyles = makeStyles({
  attendees: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    marginRight: 5,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  // styling is taken/adapted from the FieldGroup styling
  visitReason: {
    marginTop: 20,
    '& .formLabel': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 12,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase',
    },
    '& ul': {
      marginTop: 5,
      fontSize: '0.875rem',
    },
  },
});

export const __test__ = { EventSubType };

export default EventDetailsForm;
