import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';

type smsMessage = {
  status: string;
  body: string;
  dateSent: string;
  sid: string;
};

const GET_PATIENT_SMS_MESSAGES = gql`
  query GetPatientSmsMessages($id: String!) {
    getSMSMessages(patientId: $id)
  }
`;

const SmsMessages = ({ patientId }) => {
  const { apolloClient } = useContext(ApolloClientContext);

  const [smsMessages, setSmsMessages] = useState<smsMessage[]>([]);

  useEffect(() => {
    if (!patientId) return;
    getSMSMessages();
  }, [patientId]);

  const getSMSMessages = async () => {
    const query = await apolloClient?.query({
      query: GET_PATIENT_SMS_MESSAGES,
      variables: { id: patientId },
    });

    const messages: smsMessage[] = query?.data?.getSMSMessages;
    setSmsMessages(messages);
  };

  return (
    <table
      style={{
        border: '1px solid black',
        marginTop: 24,
        borderSpacing: '0 15px',
        borderCollapse: 'collapse',
      }}
    >
      <tbody>
        {smsMessages.map(message => (
          <tr key={message.sid} style={styles.messagesRow}>
            <td style={styles.messageDate}>
              {new Date(message.dateSent).toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </td>
            <td style={styles.messageBody}>{message.body}</td>
            <td style={styles.messageStatus}>{message.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const styles = {
  messagesTable: {
    border: '1px solid black',
    marginTop: 24,
    borderSpacing: '0 15px',
    borderCollapse: 'collapse',
  },
  messagesRow: {
    margin: 8,
    border: '1px solid black',
  },
  messageDate: {
    padding: '0 16px',
  },
  messageBody: {
    padding: '8px 16px',
    border: '1px solid black',
    maxWidth: 400,
  },
  messageStatus: {
    padding: '0 16px',
  },
} as const;

export default SmsMessages;
