import { Subscription } from 'apollo-client/util/Observable';
import { inject, observer } from 'mobx-react';
import { useContext, useEffect, useRef } from 'react';

import * as BN from 'src/components/general/BrowserNotification';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { SUBSCRIBE_TO_PATIENT_JOINED_CALL } from 'src/stores/subscriptions/events';

const SUBSCRIBED_TO_PATIENT_JOINED_CALL = 'SUBSCRIBED_TO_PATIENT_JOINED_CALL';

const getStorageValue = (): number => {
  return Number(localStorage.getItem(SUBSCRIBED_TO_PATIENT_JOINED_CALL));
};

const setStorageValue = (val: string) => {
  localStorage.setItem(SUBSCRIBED_TO_PATIENT_JOINED_CALL, val);
};

const PatientJoinCallSubscription = props => {
  const id = useRef<number>(Date.now());
  const patientJoinedCallSubscription = useRef<Subscription>();

  const {
    rootStore: {
      auth: { user },
      generateRouteUrl,
    },
  } = props;

  const { apolloClient } = useContext(ApolloClientContext);

  const beforeWindowUnload = () => {
    // if our tab had the subscription then clear local storage on close
    if (id.current === getStorageValue()) {
      setStorageValue(null);
    }
  };

  const onClick = (eventId: string) => () => {
    if (eventId) {
      const generatedUrl = generateRouteUrl('eventVc', {
        event: eventId,
      });
      window.open(generatedUrl, '_blank');
    }
  };

  const subscribe = () => {
    setStorageValue(String(id.current));
    patientJoinedCallSubscription.current = apolloClient
      ?.subscribe({
        query: SUBSCRIBE_TO_PATIENT_JOINED_CALL,
        variables: { providerId: user.id },
      })
      .subscribe({
        next({ data }) {
          const { patientJoinedCall } = data;
          BN.show(
            'Boulder Care Staff App',
            {
              renotify: true,
              tag: 'BoulderCare:Staff',
              requireInteraction: true,
              body: 'Patient joined. Click here to go to that visit.',
              icon: '/logoBrowserNotification.png',
              image: '/logoBrowserNotification.png',
            },
            onClick(patientJoinedCall?.eventId),
          );
        },
        error(err) {
          console.log('patient joined call subscription error', err);
        },
      });
  };

  const onStorageUpdate = ({ key, newValue, oldValue }: StorageEvent) => {
    if (key !== SUBSCRIBED_TO_PATIENT_JOINED_CALL) {
      return;
    }

    // pick up subscription when one is dropped
    if (!newValue || newValue === 'null') {
      subscribe();
      return;
    }

    // we had the previous subscription and a different tab is trying to take it
    if (Number(oldValue) === id.current) {
      if (id.current > Number(newValue)) {
        // we are the newer tab - reset the state
        setStorageValue(String(id.current));
      } else {
        // we are the older tab - unsubscribe
        patientJoinedCallSubscription?.current?.unsubscribe();
      }
    }
  };

  useEffect(() => {
    subscribe();
    return () => {
      patientJoinedCallSubscription?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeWindowUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeWindowUnload);
    };
  }, []);

  useEffect(() => {
    BN.requestPermission();
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  return null;
};

export default inject('rootStore')(observer(PatientJoinCallSubscription));
