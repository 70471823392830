import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { useContext } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';

/**
 * React hook for accessing our Apollo client
 *
 * NOTE: Must be used from components descendend from or at the level of <Main>,
 * since the related Context is setup just above it.
 */
export default function useApolloClient(): ApolloClient<NormalizedCacheObject> {
  const { apolloClient } = useContext(ApolloClientContext);

  if (!apolloClient) {
    throw new Error(
      'useApolloClient requires apolloClient to be provided via the ApolloClientContext',
    );
  }

  return apolloClient;
}
