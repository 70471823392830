import { withStyles } from '@material-ui/core/styles';
import pick from 'lodash/pick';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { getSchemaBridge, getRenderer, getSchema } from 'src/components/forms';
import EditableModel from 'src/components/forms/editableModel';

type Patient = { id: string; preferenceSendHrqolSurveys: boolean };

type HrqolProps = {
  rootStore: {
    patients: {
      savePatient: (Patient) => Patient;
      patient: Patient;
    };
  };
  classes: { [key: string]: string };
};

type HrqolState = {
  patient: Patient;
};

class Hrqol extends Component<HrqolProps, HrqolState> {
  constructor(props) {
    super(props);
    const {
      rootStore: {
        patients: { patient },
      },
    } = props;
    this.state = {
      patient,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(formData) {
    const updatedPatient = formData;

    this.setState(prevState => ({
      patient: { ...prevState.patient, ...updatedPatient },
    }));

    return this.props.rootStore.patients.savePatient({
      ...updatedPatient,
      id: this.state.patient.id,
    });
  }

  render() {
    const { classes } = this.props;
    const { patient } = this.state;

    const schemaName = 'hrqol';
    const version = 'latest';
    const RenderComponent = getRenderer('patient', schemaName, version);
    const schema = getSchema('patient', schemaName, version);
    const schemaBridge = getSchemaBridge('patient', schemaName, version);

    const model = pick(patient, ...Object.keys(schemaBridge.schema.properties));

    return (
      <div className={classes.root}>
        {patient && (
          <EditableModel
            key={schemaName}
            name={schemaName}
            model={model}
            schema={schemaBridge}
            onSubmit={this.handleSubmit}
          >
            <RenderComponent model={model} schema={schema} toplevel />
          </EditableModel>
        )}
      </div>
    );
  }
}

const styles = () => ({
  root: {
    maxWidth: 700,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(Hrqol)));
