import gql from 'graphql-tag';

import { USER_SUMMARY } from 'src/stores/fragments/users';

export const CURRENT_USER = gql`
  query CurrentUser {
    me: me2 {
      user {
        ...UserSummary
      }
    }
  }
  ${USER_SUMMARY}
`;
