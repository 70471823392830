"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.containsAny = containsAny;
var _intersectionBy = _interopRequireDefault(require("lodash/intersectionBy"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const getValue = elem => elem?.propertyName ? elem.value : elem;
function containsAny(arr, ...args) {
  const {
    fn,
    inverse
  } = args.pop();
  const getReturn = res => {
    if (fn) {
      if (res) {
        return fn(this);
      } else {
        return inverse(this);
      }
    }
    return res;
  };
  const intersectingValues = (0, _intersectionBy.default)(Array.isArray(arr) ? arr : [arr], args, getValue);
  return getReturn(intersectingValues.length > 0);
}