import { zonedTimeToUtc } from 'date-fns-tz';

import { startOfDayInTimezone } from 'src/businessHours/startOfDayInTimezone';

/**
 * The timezone in which Boulder Care's business functions generally operate.
 */
export const BOULDER_TIMEZONE = 'America/Los_Angeles';

/**
 * Given a date-only string that is intended to represent a date in Boulder's time zone, returns a
 * `Date` at the beginning of the day on that date in Boulder's time zone.
 */
export function startOfDayInBoulderTimezone(dateOnlyIso: string): Date {
  if (!dateOnlyIso.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return new Date(''); // Invalid Date
  }
  return zonedTimeToUtc(dateOnlyIso, BOULDER_TIMEZONE);
}

/**
 * Returns a `Date` at the beginning of the current day in Boulder's time zone.
 */
export function startOfTodayInBoulderTimezone(): Date {
  return startOfDayInTimezone(new Date(), BOULDER_TIMEZONE);
}
