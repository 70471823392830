import { useSWRPatientHighlights } from 'src/components/general/PatientMenu/highlights/useSWRPatientHighlights';

/**
 * Hook for querying the bupe run-out date
 *
 * @param {string} patientId The patient to get the run-out date for.
 * @returns {(string | undefined)} The date, formatted 'yyyy-MM-dd', or undefined if it's not available yet.
 */
export default function useBupeRunOut(patientId: string): string | undefined {
  const patientHighlights = useSWRPatientHighlights(patientId);

  return patientHighlights.data?.patientHighlights?.bupeRunOutDate ?? undefined;
}
