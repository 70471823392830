import { Button, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { useFormStyles } from 'src/dropInClinic/queueSettings/form.styles';

type FormProps = {
  maximumQueueLength: number;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  settingName: string;
};

const SettingForm: React.FC<FormProps> = ({ maximumQueueLength, onSubmit, settingName }) => {
  const classes = useFormStyles();
  const [value, setValue] = useState(maximumQueueLength);

  useEffect(() => {
    setValue(maximumQueueLength);
  }, [maximumQueueLength]);

  const handleChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  return (
    <form key={settingName} onSubmit={onSubmit} className={classes.form}>
      <TextField
        name={settingName}
        value={value}
        onChange={handleChange}
        label={settingName} // TODO: Replace with a more user-friendly label? Or just show a label in the left column
      />
      <Button variant="contained" color="primary" type="submit" className={classes.button}>
        Update
      </Button>
    </form>
  );
};

export default SettingForm;
