import { useEffect, useRef } from 'react';

/**
 * `useEffect` hook that only triggers after mounting, similar to the
 * old-school `componentDidUpdate`.
 * @see {@link https://stackoverflow.com/a/57941438}
 */
export const useDidUpdateEffect = (func: () => void, deps: any[]) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
