import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useState } from 'react';

/**
 * Based on eventShow.BannerComponent, but with a dismiss button.
 * Currently lives alongside only usage (PossibleDuplicateChartsBanner)
 */
export const DismissableBannerComponent: React.FC<{
  className?: string;
  open: boolean;
}> = ({ className, open, children: message }) => {
  const classes = useDismissableBannerStyles();
  const [isDismissed, setIsDismissed] = useState<boolean>(false);
  return (
    <Snackbar
      className={className}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open && !isDismissed}
    >
      <SnackbarContent
        classes={{ root: classes.content }}
        message={
          <div className={classes.banner}>
            <ErrorIcon fontSize="small" style={{ paddingRight: 10 }} />
            {message}{' '}
            <CloseIcon
              onClick={() => {
                setIsDismissed(true);
              }}
              fontSize="small"
              style={{ paddingLeft: 20 }}
            />
          </div>
        }
      />
    </Snackbar>
  );
};

const useDismissableBannerStyles = makeStyles({
  banner: {
    display: 'flex',
    textAlign: 'left',
  },
  content: { backgroundColor: '#F07562' },
});
