import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import { ReactComponent as AddEventIcon } from 'src/components/icons/add_event.svg';
import { inject } from 'src/util/inject';

interface EventCreateIconProps {
  rootStore: {
    events;
    auth: { user };
  };
}

const EventCreateIcon: React.VFC<EventCreateIconProps> = function EventCreateIcon({
  rootStore: {
    events,
    auth: { user },
  },
}) {
  return (
    <IconButton onClick={() => events.createEvent(user, {})} data-testid="create-event-button">
      <Tooltip title="Add Event">
        <SvgIcon component={AddEventIcon} />
      </Tooltip>
    </IconButton>
  );
};

export default inject<typeof EventCreateIcon>('rootStore')(EventCreateIcon);
