import { observer } from 'mobx-react';
import * as React from 'react';
import { ReactNode, useState, useEffect, useCallback } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { configCatFlags } from 'src/featureFlags/configCat';
import { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const FeatureFlagProvider = ({
  rootStore: { auth },
  children,
}: {
  rootStore: RootStore;
  children: ReactNode | JSX.Element;
}) => {
  const [flags, setFlags] = useState<Record<string, boolean>>(configCatFlags.latestFlags);
  const setFlagCallback = useCallback((e: CustomEvent) => setFlags(e.detail), [setFlags]);
  useEffect(() => {
    configCatFlags.addEventListener('change', setFlagCallback);
    return () => {
      configCatFlags.removeEventListener('change', setFlagCallback);
    };
  }, [setFlagCallback]);
  useEffect(() => {
    if (auth.initialized) {
      configCatFlags.setUserObject(auth.user?.id ? { identifier: auth.user.id } : undefined);
    }
  }, [auth.initialized, auth.user?.id]);

  return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>;
};

export default inject<typeof FeatureFlagProvider>('rootStore')(observer(FeatureFlagProvider));
