import GraphqlAdapter from 'src/data/types/GraphqlAdapter';
import { LOOKUP_PATIENTS, PatientLookupResult } from 'src/stores/queries/userSearch';

export type PatientQueryResult = {
  patientLookup: PatientLookupResult[];
};

/**
 * Service for retrieving data about patients
 */
export default class PatientDataService {
  constructor(private graphql: GraphqlAdapter) {}

  /**
   * Searches for patients using the provided query string
   *
   * @param query The string to use to search.
   */
  async search(query: string): Promise<PatientLookupResult[]> {
    if (!query) {
      return [];
    }
    const { data: { patientLookup: items = [] } = {} } =
      await this.graphql.query<PatientQueryResult>({
        query: LOOKUP_PATIENTS,
        variables: { q: query },
      });

    return items;
  }
}
