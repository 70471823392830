import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import {
  GET_PAYORS_FOR_STATE,
  QueryResult as PayorsQueryResult,
} from 'src/scheduling/queries/getPayorsForState';
import type { SchedulingPayor } from 'src/scheduling/types';

export const usePayorsForState = (
  state: string | undefined | null,
): Record<string, SchedulingPayor> => {
  const { data: payorsResponse } = useSWRImmutable<PayorsQueryResult>([
    GET_PAYORS_FOR_STATE,
    { state },
  ]);

  const payorsByKey: Record<string, SchedulingPayor> = useMemo(
    () => keyBy(payorsResponse?.payorsForState, 'key'),
    [payorsResponse],
  );

  return payorsByKey;
};
