import { Paper, makeStyles } from '@material-ui/core/';
import React from 'react';

export const SuggestionList: React.FC = ({ children }) => {
  const styles = useStyles();
  return (
    <Paper elevation={2} className={styles.suggestionList}>
      {children}
    </Paper>
  );
};

const useStyles = makeStyles({
  suggestionList: {
    zIndex: 1000,
    left: 0,
    right: 0,
    marginTop: 7, // to match the editor's paddingBottom
    position: 'absolute',
  },
});
