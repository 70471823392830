import { useCallback } from 'react';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { WAITLIST_PAYORS } from 'src/waitlist/waitlistPayors.gql';

export const useWaitlistPayors = () => {
  const { data, mutate } = useTypedSWR([WAITLIST_PAYORS, {}]);
  const refetch = useCallback(() => mutate(), [mutate]);
  return {
    waitlistPayors: data?.staff_getProspectivePatientCountsByPayor || [],
    refetch,
  };
};
