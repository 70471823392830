import { withStyles } from '@material-ui/core/styles';
import escapeStringRegexp from 'escape-string-regexp';
import PropTypes from 'prop-types';
import React from 'react';

import Autocomplete from 'src/components/forms/controls/autocomplete';
import EditableModel from 'src/components/forms/editableModel';
import { colors } from 'src/util/colors';

class SelectableModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edited: false,
      selection: null,
      selectedSchema: null,
      model: {},
    };

    this.handleSelection.bind(this);
  }

  handleSelection = selection => {
    this.setState({
      model: this.props.handleSelection ? this.props.handleSelection(selection) : {},
      selection,
      selectedSchema: selection.value,
    });
  };

  handleSubmit = model => {
    this.setState({ model });
    return this.props.handleSubmit?.(this.state.selectedSchema, model);
  };

  handleSubmitSuccess = () => {
    return this.props.handleSubmitSuccess?.();
  };

  handleChange = () => {
    if (!this.state.edited) {
      this.setState({
        edited: true,
      });
    }
  };

  handleCancel = () => {
    return this.props.handleCancel?.();
  };

  render() {
    const {
      classes,
      schemaNames,
      currentSchemaTypes,
      labelFn,
      schemaBridgeFn,
      schemaFn,
      rendererFn,
      savingDisabled = false,
    } = this.props;
    const { edited, selectedSchema, selection, model } = this.state;
    const RenderComponent = selectedSchema ? rendererFn(selectedSchema) : null;

    // Don't show a schema if it's already been configured in the visit notes
    const sortedOptions = schemaNames
      .map(schemaKey =>
        currentSchemaTypes.includes(schemaKey)
          ? null
          : {
              value: schemaKey,
              label: labelFn(schemaKey),
            },
      )
      .filter(result => result !== null)
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <div className={classes.container}>
        {!edited && (
          <div className={classes.headerContainer}>
            <Autocomplete
              classes={{
                singleValue: classes.editableHeader,
                placeholder: classes.editablePlaceholder,
              }}
              variant="h6"
              options={sortedOptions}
              onChange={newSelection => this.handleSelection(newSelection)}
              filterOption={(option, inputValue) => {
                const escapedString = escapeStringRegexp(inputValue);
                // Allow for
                //  - Search on both the label and the value
                //  - Close matches even when special characters are omitted
                return (
                  !!option.label
                    .replace(/[^\w\s]/gi, '')
                    .toLowerCase()
                    .match(escapedString.toLowerCase()) ||
                  !!option.value.toLowerCase().match(escapedString.toLowerCase()) ||
                  !!option.label.toLowerCase().match(escapedString.toLowerCase())
                );
              }}
              value={selection}
            />
          </div>
        )}
        {selectedSchema && model && (
          <EditableModel
            initializeEditing
            hideHeader={!edited}
            model={model}
            schema={schemaBridgeFn(selectedSchema)}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            handleSaveSuccess={this.handleSubmitSuccess}
            handleChange={this.handleChange}
            savingDisabled={savingDisabled}
          >
            <RenderComponent model={model} schema={schemaFn(selectedSchema)} toplevel />
          </EditableModel>
        )}
      </div>
    );
  }
}

SelectableModel.propTypes = {
  classes: PropTypes.shape({}),
  currentSchemaTypes: PropTypes.arrayOf(PropTypes.string),
  handleCancel: PropTypes.func,
  handleSelection: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleSubmitSuccess: PropTypes.func,
  labelFn: PropTypes.func.isRequired,
  rendererFn: PropTypes.func.isRequired,
  savingDisabled: PropTypes.bool,
  schemaBridgeFn: PropTypes.func,
  schemaFn: PropTypes.func.isRequired,
  schemaNames: PropTypes.arrayOf(PropTypes.string),
};

const styles = () => ({
  container: {
    marginBottom: 30,
  },
  editableHeader: {
    color: colors.taupe,
    fontSize: '1.25rem',
    marginBottom: -1,
  },
  editablePlaceholder: {
    fontSize: '1.25rem',
    marginBottom: -1,
  },
  headerContainer: {
    // This causes the header dropdown to align with the header in editableModel, so there's no
    // janky-ness when it swaps over
    marginTop: 5,
  },
});

export default withStyles(styles)(SelectableModel);
