"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayraw = displayraw;
var _handlebars = _interopRequireDefault(require("handlebars"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function displayraw(value) {
  const dumped = JSON.stringify(value ?? null, null, 2);
  return new _handlebars.default.SafeString(`<pre>${_handlebars.default.escapeExpression(dumped)}</pre>`);
}