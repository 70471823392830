import cloneDeep from 'lodash/cloneDeep';

import { noteField } from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.1.0';
import v130Schema from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/1.3.0';

const v140Schema = cloneDeep(v130Schema);
let { enum: referralSourceEnum } = v140Schema.properties.referrer.properties.referralSourceType;
referralSourceEnum.push('Evergreen Health');
// Add 'A Boulder Patient' as third option in referral dropdown
referralSourceEnum = referralSourceEnum.splice(2, 0, 'A Boulder Patient');
v140Schema.properties.outcome.properties.declinedCostProhibitive = {
  title: 'Declined (Cost Prohibitive)',
  ...noteField,
};

export default v140Schema;
