import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import type ApolloClient from 'apollo-client';
import startCase from 'lodash/startCase';
import pluralize from 'pluralize';

import {
  SEARCH_ICD10_CODES,
  SEARCH_MEDICATIONS,
  SEARCH_PROBLEMS,
  SEARCH_PAYORS,
} from 'src/nightingale/data/queries/searchOptions';
import { SelectAsyncOptionsSources, SelectOptionsList } from 'src/nightingale/types/types';
import crudService from 'src/shared/services/crud';

type gqlOption = {
  key: string;
  name: string;
};

type crudOption = {
  firstName: string;
  lastName: string;
  preferredName?: string;
  id: string;
};

/*
 * CRUD
 * Async options retrieved using crudService
 */
const crudSelectors = {
  [SelectAsyncOptionsSources.Patients]: ['id', 'firstName', 'lastName', 'preferredName'],
  [SelectAsyncOptionsSources.Providers]: ['id', 'firstName', 'lastName'],
};

const crudSelectOptions = async (
  searchTerm: string,
  optionsSource: string,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  const numResults = 20;
  const crudClient = crudService(client);
  const modelName = startCase(pluralize.singular(optionsSource.toLowerCase()));

  try {
    const { items } = await crudClient.getMany(
      modelName,
      { first: numResults, where: { q: searchTerm || null } },
      crudSelectors[optionsSource],
    );
    return items;
  } catch (err) {
    console.warn(`Error fetching select options from the server`, err);
    return [];
  }
};

const formatCrudOptionsList = (options: crudOption[]): SelectOptionsList => {
  return options.map(option => ({
    value: option.id,
    label: option.preferredName
      ? `${option.preferredName} [${option.firstName}] ${option.lastName}`
      : `${option.firstName} ${option.lastName}`,
  }));
};

/*
 * GQL
 * Async options retrieved using graphql
 */
const gqlQueries = {
  [SelectAsyncOptionsSources.ICD10Codes]: SEARCH_ICD10_CODES,
  [SelectAsyncOptionsSources.MedicalProblems]: SEARCH_PROBLEMS,
  [SelectAsyncOptionsSources.Medications]: SEARCH_MEDICATIONS,
  [SelectAsyncOptionsSources.Payors]: SEARCH_PAYORS,
};

const gqlSelectOptions = async (
  searchTerm: string,
  optionsSource: string,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  if (!gqlQueries[optionsSource]) {
    console.warn(`graphql query is not defined for ${optionsSource}`);
    return [];
  }

  try {
    const {
      data: { options },
    } = await client.query({
      query: gqlQueries[optionsSource],
      variables: { q: searchTerm },
    });
    return options;
  } catch (err) {
    console.warn(`Error fetching select options from the server`, err);
    return [];
  }
};

const formatGqlOptionsList = (options: gqlOption[]): SelectOptionsList => {
  return options.map(option => ({
    value: option.key,
    label: option.name,
  }));
};

const formatIcd10CodesGqlOptionsList = (options: gqlOption[]): SelectOptionsList => {
  return options.map(option => ({
    value: option.key,
    label: `${option.name} [${option.key}]`,
  }));
};

/*
 * main
 */
export const searchOptionsAPI = async (
  searchTerm: string,
  optionsSource: string,
  apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<SelectOptionsList> => {
  const shouldUseCrudService = Object.keys(crudSelectors).includes(optionsSource);

  if (shouldUseCrudService) {
    const crudQueryResults = await crudSelectOptions(searchTerm, optionsSource, apolloClient);
    return formatCrudOptionsList(crudQueryResults);
  } else {
    const gqlQueryResults = await gqlSelectOptions(searchTerm, optionsSource, apolloClient);
    if (optionsSource === SelectAsyncOptionsSources.ICD10Codes) {
      return formatIcd10CodesGqlOptionsList(gqlQueryResults);
    }
    return formatGqlOptionsList(gqlQueryResults);
  }
};
