import { makeStyles, Theme } from '@material-ui/core/styles';

import Colors from 'src/nightingale/Colors';
import { BoulderColors } from 'src/util/brand';

interface StyleProps {
  atcInterface?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>(() => ({
  row: {
    boxSizing: 'border-box',
    fontSize: '0.875rem',
    minHeight: 75,
    maxHeight: 150,

    backgroundImage: 'url("bwGradient.jpg")',
    backgroundBlendMode: 'color-burn',
    backgroundColor: Colors.Gray1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    border: `1px solid ${Colors.Gray1}`,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    padding: '4px 14px',
    margin: '1.25ex 0',

    borderRadius: '2px',

    '& svg': {
      marginRight: '0.25em',
      flexShrink: 0,
    },

    '&:hover': {
      boxShadow: `0 0 2px 1px ${Colors.Gray5}`,
    },

    color: Colors.Gray7,

    '& a': {
      color: Colors.BlueSpruce,
    },
  },
  removeButton: {
    flexGrow: 0,
    flexShrink: 0,
    width: 32,

    '& svg': {
      margin: 0,
      color: Colors.Gray3,
      '&:hover': {
        color: Colors.Gray6,
      },
    },
  },
  indicator: {
    flexGrow: 0,
    flexShrink: 0,
    width: 32,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    margin: '-7px 8px -7px 0px',
    padding: '0 1px',

    '& svg': {
      margin: 0,
    },
    '.with-note &': {
      backgroundColor: '#F7E496',
      border: '1px solid #FDF7DE',
      borderTopWidth: 0,
      borderRadius: '0 0 4px 4px',
      boxShadow: `
        0 1px 2px ${Colors.Gray5},
        inset 0 -2px 3px #FDF7DE
      `,
    },
  },
  name: {
    flexGrow: 4,
    flexShrink: 4,
    flexBasis: '20em',
    minWidth: '20em',
  },
  unscheduledRow: {
    backgroundColor: ({ atcInterface }) => (atcInterface ? '#FAEDEB' : undefined),
    borderLeftColor: ({ atcInterface }) => (atcInterface ? Colors.Coral : undefined),
    borderLeftWidth: '4px',
    paddingLeft: '11px',
  },
  unscheduledWarning: {
    color: ({ atcInterface }) => (atcInterface ? Colors.Coral : undefined),
    fontWeight: ({ atcInterface }) => (atcInterface ? 'bold' : undefined),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '1ex',
  },
  visitReason: {
    flexGrow: 4,
    flexShrink: 4,
    flexBasis: '20em',
    minWidth: '20em',
    alignSelf: 'center',
    maxHeight: 140,
    overflowY: 'auto',
    scrollbarColor: `${Colors.Gray5} #FF000000`,
    letterSpacing: '0.157px',
    color: BoulderColors.Gray5,
    fontSize: '12px',
    lineHeight: '145%',

    '& ul': {
      paddingLeft: '1em',
    },

    '& .label': {
      fontWeight: 600,
      '&::after': {
        content: '" "',
      },
    },
  },
  waitingTime: {
    flexGrow: 0,
    flexBasis: '10em',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  requestType: {
    flexGrow: 0,
    flexBasis: '12em',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  medicationDate: {
    padding: '0px 8px',
    flexGrow: 0,
    flexBasis: '12em',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  currentStatus: {
    flexGrow: 0,
    flexBasis: '12em',

    display: 'grid',
    gridTemplateColumns: '32px auto',

    '& > svg': {
      gridArea: '1 / 1 / 3 / 1',
      alignSelf: 'center',
    },
  },
  scheduleSuggestion: {
    flexGrow: 1,
    flexBasis: '15em',

    marginTop: '1ex',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actions: {
    flexGrow: 1,
    flexBasis: '10em',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));
