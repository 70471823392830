import { observer } from 'mobx-react';
import React from 'react';
import * as Yup from 'yup';

import SelectControl from 'src/components/forms/controls/select';
import TextControl from 'src/components/forms/controls/text';
import EditForm from 'src/components/forms/editForm';
import Field from 'src/components/forms/field';
import { SELECTABLE_SCHEDULE_CHANGE_REASONS } from 'src/shared/util/events';

const CANCEL_EVENT_VALIDATOR = Yup.object().shape({
  scheduleChangeReason: Yup.string()
    .oneOf([...Object.keys(SELECTABLE_SCHEDULE_CHANGE_REASONS)])
    .nullable()
    .required(),
  scheduleChangeNotes: Yup.string().nullable(),
});

const CancelEvent = props => (
  <EditForm {...props} validationSchema={CANCEL_EVENT_VALIDATOR}>
    <Field
      name="scheduleChangeReason"
      component={SelectControl}
      label="Reason *"
      options={Object.keys(SELECTABLE_SCHEDULE_CHANGE_REASONS)}
      valueFn={option => option}
      labelFn={option => SELECTABLE_SCHEDULE_CHANGE_REASONS[option].label}
    />
    <Field
      name="scheduleChangeNotes"
      component={TextControl}
      label="Notes"
      onKeyDown={e => e.stopPropagation()}
    />
  </EditForm>
);

export default observer(CancelEvent);
