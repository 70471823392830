"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  evaluateExpression: true,
  evaluateHtmlExpression: true,
  evaluateBooleanExpression: true,
  chartPropertyContext: true,
  textValue: true
};
exports.chartPropertyContext = chartPropertyContext;
exports.evaluateBooleanExpression = evaluateBooleanExpression;
exports.evaluateExpression = evaluateExpression;
exports.evaluateHtmlExpression = evaluateHtmlExpression;
exports.textValue = textValue;
var _template = _interopRequireDefault(require("./template/template"));
var _conversion = require("./context/conversion");
var _format = require("./format/format");
var _types = require("./types");
Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types[key];
    }
  });
});
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function evaluateExpression(expression, context) {
  return (0, _template.default)(expression, context);
}
function evaluateHtmlExpression(expression, context) {
  return (0, _format.format)(evaluateExpression(expression, context));
}
const EXPRESSION_TRUE_RESULT = 'true';
function evaluateBooleanExpression(expression, context) {
  return evaluateExpression(expression, context).toLowerCase().trim() === EXPRESSION_TRUE_RESULT;
}
function chartPropertyContext(chartProperties, chartPropertyValues) {
  return (0, _conversion.convertChartPropertiesToContextParameters)(chartProperties, chartPropertyValues);
}
function textValue(type, value, context) {
  return (0, _conversion.extractReadableValue)(type, value, context);
}