import React from 'react';

import { states } from 'src/components/forms/schemas/definitions';
import PhoneNumber from 'src/components/general/phoneNumber';

export default {
  type: 'object',
  title: 'Peer Coach: Telehealth Precheck',
  properties: {
    confirmedPatientPrivacy: {
      type: 'boolean',
      title: 'Confirmed No Privacy Concerns',
      tooltip:
        '"Are you alone? If not, who are you with, and are you comfortable speaking in front of them?"',
    },
    patientLocation: {
      type: 'object',
      tooltip: '"Are you at your primary residence?"',
      renderer: {
        component: ({ model, classes }) => (
          <div className={classes.itemDetails}>
            <span>
              {model.patientLocation.city}
              {model.patientLocation.city && model.patientLocation.state && ', '}
              {model.patientLocation.state}
            </span>
            {model.patientLocation.policePhone && (
              <span>
                Police Phone: <PhoneNumber number={model.patientLocation.policePhone} />
              </span>
            )}
          </div>
        ),
      },
      properties: {
        city: {
          type: 'string',
        },
        state: {
          type: 'string',
          enum: states,
        },
        policePhone: {
          type: 'string',
          format: 'phone',
          label: 'Local Police Phone',
        },
      },
    },
    confirmedNonSuicidal: {
      type: 'boolean',
      title: 'Confirmed Non-Suicidal',
      tooltip: '"Are you having any thoughts of suicide?"',
    },
    availableTime: {
      type: 'integer',
      minimum: 0,
      title: 'Available time (min)',
      tooltip: '"How much time do you have today?"',
    },
  },
  required: [],
};
