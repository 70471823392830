import React from 'react';

import { multilineStringDef } from 'src/components/forms/schemas/util';

export default {
  type: 'object',
  title: 'Referral to Case Manager',
  properties: {
    complexities: {
      type: 'object',
      renderer: {
        component: ({ model, schema }) => (
          <ul>
            {Object.keys(model.complexities).map(key => {
              if (!schema.properties[key] || model.complexities[key] !== true) return null;
              const { title } = schema.properties[key];
              return <li key={key}>{title}</li>;
            })}
          </ul>
        ),
      },
      properties: {
        suicidality: { type: 'boolean', title: 'Suicidality' },
        historyOfSuicidalIdeation: { type: 'boolean', title: 'History of suicidal ideation' },
        historyOfTrauma: { type: 'boolean', title: 'History of trauma' },
        mentalIllness: { type: 'boolean', title: 'Mental illness' },
        insufficientCare: {
          type: 'boolean',
          title: 'Insufficient mental healthcare or primary care',
        },
        activeSubstanceUse: { type: 'boolean', title: 'Active substance use' },
        returnedToUse: { type: 'boolean', title: 'Returned to substance use in past 90 days' },
        seekingInpatientTreatment: { type: 'boolean', title: 'Seeking inpatient treatment' },
        dischargeWithResources: {
          type: 'boolean',
          title: 'Recent discharge from hospital (with resources)',
        },
        dischargeWithoutResources: {
          type: 'boolean',
          title: 'Recent discharge from hospital (without resources)',
        },
        healthProblems: { type: 'boolean', title: 'Chronic or acute health problems' },
        hivOrAids: { type: 'boolean', title: 'HIV or AIDS' },
        pregnancy: { type: 'boolean', title: 'Pregnancy' },
        physicalDisabilities: { type: 'boolean', title: 'Physical disabilities' },
        learningDisabilities: { type: 'boolean', title: 'Learning disabilities' },
        careDiscrimination: {
          type: 'boolean',
          title: 'Patient feels their sexual orientation or race is a barrier to care',
        },
        domesticViolence: { type: 'boolean', title: 'Domestic violence' },
        houselessness: { type: 'boolean', title: 'Houselessness' },
        soonHouseless: { type: 'boolean', title: 'About to lose housing / become houseless' },
        lackOfFood: { type: 'boolean', title: 'Lack of food' },
        lackOfTech: { type: 'boolean', title: 'Lack of technology for telehealth treatment' },
        lackOfTransportation: { type: 'boolean', title: 'Lack of transportation' },
        lackOfIncome: { type: 'boolean', title: 'Lack of income' },
        lackOfHealthInsurance: { type: 'boolean', title: 'Lack of health insurance' },
        recentRelease: { type: 'boolean', title: 'Recent release from incarceration' },
        paroleProbationCases: {
          type: 'boolean',
          title: 'Parole, probation, or legal cases pending',
        },
        involvementWithDrugCourt: { type: 'boolean', title: 'Involvement with drug court' },
        pastIncarceration: { type: 'boolean', title: 'Past incarceration' },
        childrenInStateCustody: { type: 'boolean', title: 'Children in state custody' },
        illegalActivities: { type: 'boolean', title: 'Involvement in illegal activities' },
        poorMedicationManagement: { type: 'boolean', title: 'Poor medication management' },
        twoOrMoreMissedVisits: { type: 'boolean', title: 'Two or more missed OFT or MAM visits' },
        careCoordination: {
          type: 'boolean',
          title: 'Care coordination team has attempted multiple interventions unsuccessfully',
        },
      },
    },
    referralAssessment: {
      displayOnly: true,
      renderer: {
        component: ({ model }) => {
          const referralResult = getAssessmentResults(model);
          return referralResult ? <div>{referralResult}</div> : null;
        },
      },
    },
    pastInterventions: multilineStringDef,
    notes: multilineStringDef,
  },
};

const criticalComplexities = [
  'suicidality',
  'dischargeWithoutResources',
  'pregnancy',
  'domesticViolence',
  'soonHouseless',
  'lackOfFood',
  'lackOfTech',
  'twoOrMoreMissedVisits',
];

const referralResultMessages = {
  emergencyMessage:
    'Emergency: send a Slack message to Case Manager immediately, note emergency referral',
  recommendedMessage: 'Recommended: Case Manager will be notified',
  notRecommendedMessage: 'Not recommended: reach out to Case Manager for consultation if needed',
};

function getAssessmentResults(model) {
  // if any critical complexities → emergency
  // if 3+ complexities → referral recommended
  // if <3 complexities → referral not recommended
  let referralResult = null;

  if (model.complexities) {
    const complexities = [];
    Object.entries(model.complexities).forEach(([complexity, value]) => {
      if (value === true) complexities.push(complexity);
    });
    if (complexities.length === 0) referralResult = null;
    else if (complexities.some(complexity => criticalComplexities.indexOf(complexity) >= 0))
      referralResult = referralResultMessages.emergencyMessage;
    else if (complexities.length >= 3) referralResult = referralResultMessages.recommendedMessage;
    else if (complexities.length < 3) referralResult = referralResultMessages.notRecommendedMessage;
  }
  return referralResult;
}

export const __test__ = {
  criticalComplexities,
  getAssessmentResults,
  referralResultMessages,
};
