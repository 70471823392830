import React from 'react';

import Engaged from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/renderers/Engaged';
import NoteField from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/renderers/NoteField';
import ReferralSource from 'src/components/forms/schemas/eventResult/inquiryPotentialPatient/renderers/ReferralSource';
import PatientOverviewLink from 'src/components/general/patientOverviewLink';
import RouteLink from 'src/components/general/routeLink';

const boolean = { type: 'boolean' };
const notes = {
  notes: {
    type: 'string',
    uniforms: {
      multiline: true,
    },
  },
};
export const noteField = {
  type: 'object',
  renderer: {
    component: NoteField,
  },
  properties: notes,
};

export default {
  type: 'object',
  title: 'Inquiry: Potential Participant',
  properties: {
    ...notes,
    callType: {
      type: 'string',
      allowOther: true,
      enum: [
        'Incoming (they called)',
        'Outgoing (returning a voicemail)',
        'Outgoing (responding to a callback form)',
        'Outgoing (responding to webchat)',
        'Outgoing (responding to Facebook inquiry)',
        'Outgoing (following up on a previous phone call)',
      ],
    },
    referrer: {
      type: 'object',
      title: 'How did you hear about Boulder?',
      renderer: {
        component: ReferralSource,
      },
      properties: {
        referralSourceType: {
          type: 'string',
          allowOther: true,
          enum: [
            'Provider',
            'Insurer',
            'Word of Mouth ',
            'Facebook',
            'Other Social Media',
            'Web Search',
            'News Coverage',
            'Corrections/Parole Officer',
            'Residential Program',
          ],
        },
        referralSourceName: {
          type: 'string',
        },
      },
    },
    outcome: {
      type: 'object',
      format: 'selectable',
      properties: {
        engaged: {
          type: 'object',
          title: 'Engaged',
          renderer: {
            component: Engaged,
          },
          properties: {
            confirmedSeekingOudTreatment: {
              ...boolean,
              title: 'Confirmed seeking OUD and/or AUD treatment',
            },
            confirmedServiceAreaEligibility: boolean,
            confirmedOver18YearsOfAge: boolean,
            patientProfileCreated: {
              ...boolean,
              renderer: {
                title: 'Patient profile created and added to this event',
              },
              label: (
                <span>
                  <RouteLink route="createPatient" newWindow>
                    Patient profile
                  </RouteLink>{' '}
                  created and added to this event
                </span>
              ),
            },
            discussedInsurance: {
              ...boolean,
              renderer: {
                title: 'Gathered insurer name, and ID number if possible',
              },
              label: (
                <span>
                  Gathered{' '}
                  <PatientOverviewLink section="reference">insurer name</PatientOverviewLink>, and
                  ID number if possible
                </span>
              ),
            },
            confirmedAccessToSmartphone: boolean,
            confirmedAccessToWifi: boolean,
            confirmedAccessToTransportation: boolean,
            confirmedSafeHousing: boolean,
            completedAcuityAssessment: {
              ...boolean,
              title:
                'Completed acuity assessment (suicidality, pregnancy, ER/hospitalization, detox/rehab, criminal justice, overdose)',
            },
            clinicianVisitScheduled: boolean,
            appSetupComplete: boolean,
            consentsSigned: boolean,
            onboardingChecklistCreated: boolean,
            ...notes,
          },
        },
        interestedNotReady: {
          title: 'Interested but not ready',
          ...noteField,
        },
        notInterestedAtThisTime: {
          title: 'Not interested at this time',
          ...noteField,
        },
        ineligibleAge: {
          title: 'Ineligible (age)',
          ...noteField,
        },
        ineligibleGeography: {
          title: 'Ineligible (geography)',
          ...noteField,
        },
        ineligibleUnsupportedPaymentType: {
          title: 'Ineligible (unsupported payment type)',
          ...noteField,
        },
        ineligibleNotSeekingOudTreatment: {
          title: 'Ineligible (not seeking OUD treatment)',
          ...noteField,
        },
        unableToConnect: {
          title: 'Unable to connect',
          ...noteField,
        },
        seekingInfoForOthers: {
          title: 'Seeking information for others',
          ...noteField,
        },
        other: {
          title: 'Other',
          ...noteField,
        },
      },
    },
  },
  required: ['callType', 'outcome'],
};
