import { Button, CircularProgress, Theme, makeStyles } from '@material-ui/core';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import React, { useState } from 'react';

export const RequestTaskButton: React.FC<{ onClick: () => void; text: string }> = ({
  onClick,
  text,
}) => {
  const [isRequestingTask, setIsRequestingTask] = useState(false);
  const classes = useStyles();

  const handleOnClick = async () => {
    setIsRequestingTask(true);
    try {
      await onClick();
    } finally {
      setIsRequestingTask(false);
    }
  };

  return (
    <Button
      classes={{ root: classes.button }}
      color="secondary"
      onClick={handleOnClick}
      size="small"
      startIcon={
        isRequestingTask ? (
          <CircularProgress size={12} color="inherit" />
        ) : (
          <MobileScreenShareIcon className={classes.icon} />
        )
      }
      variant="outlined"
    >
      {text}
    </Button>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  button: {
    '&.MuiButton-root': {
      marginBottom: theme.spacing(1),
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}));
