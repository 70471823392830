import { withStyles } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { format, parseISO } from 'date-fns';
import { inject } from 'mobx-react';
import React, { Component } from 'react';

import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import { EVENT_STATUSES } from 'src/shared/util/events';

class AvailabilityPopup extends Component {
  constructor(props) {
    super(props);
    this.state = { showCancelDialog: false };
  }

  getAvailabilityInstance = () => {
    const { calendar, item } = this.props;
    return calendar.availableInstanceById(item.id);
  };

  onCancelInstance = async () => {
    const {
      rootStore: { events },
      onClose,
    } = this.props;
    const availability = this.getAvailabilityInstance();
    await events.updateEventAndSave(availability, {
      ...availability,
      status: EVENT_STATUSES.CANCELED,
    });
    onClose();
  };

  onCancelRecurrence = async () => {
    const {
      rootStore: { events },
      onClose,
    } = this.props;
    const availability = this.getAvailabilityInstance();
    await events.cancelRecurrence(availability);
    onClose();
  };

  render() {
    const { item, itemElement, position, onClose } = this.props;
    const { showCancelDialog } = this.state;

    if (!item) {
      return null;
    }

    const cancelRecurrence = {
      title: 'Cancel Recurrence',
      useConfirmDialog: true,
      modalContents:
        'Are you sure that you would like to cancel this and all future instances of this event?',
      submitLabel: 'Cancel Recurrence',
      cancelLabel: `Don't Cancel`,
      isDestructive: true,
    };

    const isRecurring = item.recurrence?.type === 'scheduled';

    return (
      <Menu
        open
        anchorEl={itemElement}
        anchorPosition={position}
        anchorReference={position ? 'anchorPosition' : 'anchorEl'}
        onClose={() => onClose()}
      >
        <MenuItem onClick={this.onCancelInstance}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary={isRecurring ? 'Cancel This Availability' : 'Cancel'} />
        </MenuItem>
        {isRecurring && (
          <MenuItem
            onClick={() => {
              this.setState({ showCancelDialog: true });
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText
              primary={`Cancel Recurrence Starting ${format(parseISO(item.start), 'M/d')} ...`}
            />
          </MenuItem>
        )}
        {showCancelDialog && (
          <ConfirmDialog
            isDestructive={cancelRecurrence.isDestructive}
            onSubmit={this.onCancelRecurrence}
            onCancel={() => {
              this.setState({ showCancelDialog: false });
              onClose();
            }}
            submitLabel={cancelRecurrence.submitLabel}
            cancelLabel={cancelRecurrence.cancelLabel}
          >
            {cancelRecurrence.modalContents}
          </ConfirmDialog>
        )}
      </Menu>
    );
  }
}

const styles = () => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});

export default withStyles(styles)(inject('rootStore')(AvailabilityPopup));
