import { isValid, parseISO } from 'date-fns';
import { inject } from 'mobx-react';
import React from 'react';

import { INVITE_GUEST_PEER } from 'src/components/pages/eventVc.gql';
import VideoConference from 'src/components/pages/pageElements/videoConference';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import logger from 'src/shared/util/logger';

class EventVC extends React.Component {
  static contextType = ApolloClientContext;

  constructor(props) {
    super(props);
    this.state = {
      event: null,
    };
  }

  async componentDidMount() {
    const event = await this.props.rootStore.events.getEventInstance(
      this.props.rootStore.routerStore.routerState.params.event,
    );
    this.setState({ event });
  }

  inviteGuestByEmail = async email => {
    try {
      await this.props.rootStore.events.inviteEventGuestByEmail(email, this.state.event.id);
    } catch (e) {
      logger.warn('Failed to send email invitation: ', e);
      // Allow anyone calling this to properly handle the error as well.
      throw e;
    }
  };

  invitePeerWithReason = async reason => {
    try {
      const { apolloClient } = this.context;
      await apolloClient.mutate({
        mutation: INVITE_GUEST_PEER,
        variables: { eventId: this.state.event.id, reason },
      });
    } catch (e) {
      logger.warn('Failed to send peer invitation: ', e);
      // Allow anyone calling this to properly handle the error as well.
      throw e;
    }
  };

  render() {
    const { event } = this.state;

    if (!event) {
      return null;
    }

    const startDateTime = parseISO(event.start);
    const startTime = isValid(startDateTime) ? startDateTime.valueOf() : undefined;

    return (
      <VideoConference
        attendees={event.attendees}
        enableSharing
        eventId={event.id}
        guestUrl={event.vc.guestUrl}
        inviteGuestByEmail={this.inviteGuestByEmail}
        invitePeerWithReason={this.invitePeerWithReason}
        sessionId={event.vc.sessionId}
        startTime={startTime}
        token={event.vc.token}
      />
    );
  }
}

export default inject('rootStore')(EventVC);
