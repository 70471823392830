import gql from 'graphql-tag';

import { ProviderRole } from 'src/stores/users/userType';

export const SEARCH_USERS = gql`
  query UserSearch($q: String!) {
    userSearch(q: $q) {
      id
      firstName
      lastName
      ... on Patient {
        preferredName
        dob
        homeAddress {
          state
        }
      }
    }
  }
`;

export const LOOKUP_PATIENTS = gql`
  query PatientLookup($q: String!) {
    patientLookup(q: $q) {
      id
      firstName
      lastName
      preferredName
      dob
      homeAddress {
        state
      }
      timezone
    }
  }
`;

export const LOOKUP_PROVIDERS = gql`
  query ProviderLookup($q: String!) {
    providerLookup(q: $q) {
      id
      firstName
      lastName
      npi
      teamRole
      timezone
    }
  }
`;

/**
 * A result returned from one of the user search queries
 */
export type UserSearchResult = {
  __typename: 'Patient' | 'Provider';
  id?: string;
  firstName?: string;
  preferredName?: string;
  lastName?: string;
  homeAddress: {
    state?: string;
  };
  dob?: string;
};

export type ProviderLookupResult = {
  id: string;
  firstName: string;
  lastName: string;
  npi: string | null;
  teamRole: ProviderRole | null;
  timezone?: string;
};

export type PatientLookupResult = {
  id: string;
  firstName: string;
  lastName: string;
  preferredName: string | null;
  dob: string;
  homeAddress: {
    state: string | null;
  };
  timezone: string | null;
};
