import * as React from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';

class FeatureFlag extends React.Component {
  static contextType = FeatureFlagContext;

  render() {
    const { name, children = null, defaultChildren = null, notTargeted = false } = this.props;
    const flags = this.context;

    if (notTargeted) {
      return flags[name] ? defaultChildren : children;
    }
    return flags[name] ? children : defaultChildren;
  }
}

export function FeatureFlagNotTargeted(props) {
  return <FeatureFlag {...props} notTargeted />;
}

export default FeatureFlag;
