export const latest = {
  type: 'object',
  title: 'Health-Related Quality of Life (Monthly Check-In)',
  properties: {
    preferenceSendHrqolSurveys: {
      type: 'boolean',
      title: 'Send surveys?',
      tooltip:
        'Start sending Monthly Check-In (Health-Related Quality of Life) surveys to this participant. It is important for the participant to fill out their first survey before their first Clinician visit.',
    },
  },
  required: [],
};
