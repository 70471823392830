import { KeyboardDatePickerProps, KeyboardDateTimePicker } from '@material-ui/pickers';
import { isValid } from 'date-fns';
import React, { useState } from 'react';

// The value of this constant is arbitrary. The onChange method needs to recieve a nonempty string,
// otherwise if time.toISOString returns undefined (e.g. if time is only a partial, and therefore
// invalid, string), the datetime input field will be cleared out
const INVALID_PLACEHOLDER = 'X';

const DateTimePicker: React.FC<
  Partial<KeyboardDatePickerProps> & {
    disabled?: boolean;
    error?: boolean;
    errorMessage?: string;
    label: string;
    name?: string;
    onChange: KeyboardDatePickerProps['onChange'];
    placeholder?: string;
    showInlineError?: boolean;
    value?: string;
  }
> = ({
  disabled,
  error,
  errorMessage,
  label,
  name,
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  placeholder,
  showInlineError,
  value,
  ...props
}) => {
  const [inputError, setInputError] = useState(null);
  const formattedValue = value ? new Date(value) : null;

  return (
    <KeyboardDateTimePicker
      variant="inline"
      disabled={!!disabled}
      name={name}
      value={formattedValue}
      onChange={time => {
        if (inputError) {
          setInputError(null);
        }

        if (!time) {
          onChange(time);
        } else if (isValid(time)) {
          onChange(time.toISOString());
        } else {
          onChange(INVALID_PLACEHOLDER);
        }
      }}
      error={!!error || !!inputError}
      onError={setInputError}
      label={label}
      format="MM/dd/yyyy hh:mm a"
      placeholder="MM/DD/YYYY HH:MM AM/PM"
      invalidLabel={error && showInlineError ? errorMessage : undefined}
      invalidDateMessage="Date must be formatted like: 01/31/2019 08:00 AM"
      autoOk
      fullWidth
      {...props}
    />
  );
};

export default DateTimePicker;
