import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import classNames from 'classnames';
import React from 'react';

import { useButtonStyles } from 'src/patientHeader/components/badges/styles/useButtonStyles';
import { useTooltipStyles } from 'src/patientHeader/components/badges/styles/useTooltipStyles';

export const CopyPatientId: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const tooltipStyles = useTooltipStyles();
  const styles = useButtonStyles();

  return (
    <Tooltip
      title={<em>Copy patient ID</em>}
      placement="right"
      enterDelay={300}
      enterNextDelay={300}
      leaveTouchDelay={300}
      classes={{
        tooltip: tooltipStyles.tooltip,
      }}
      data-testid="copy-patient-id"
    >
      <IconButton
        className={classNames([styles.button, styles.iconButton, styles.neutral])}
        onClick={() => {
          navigator.clipboard.writeText(patientId);
        }}
      >
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
};
