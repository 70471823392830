import v100Schema from 'src/components/forms/schemas/eventResult/careAdvocateTelehealthPrecheck/1.0.0';

const { ...v100Properties } = v100Schema.properties;

export default {
  ...v100Schema,
  properties: {
    ...v100Properties,
    currentLocation: {
      type: 'string',
      tooltip: '"Where are you joining this call from?"',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: ['currentLocation'],
};
