import { getPreferredFullName } from 'src/shared/stores/resource';

/**
 * Transform a list of users into a single human readable string describing the list
 * given IDs to exclude, a transform for each user object, and the characters to
 * combine them with. By default, we return a comma-separated sentence containing
 * every accounts preferred full name.
 *
 * @param {*} users List of User accounts to compile
 * @param {*} options (Optional) Object containing the following properties:
 *   excludedIds (Optional) Array of User IDs to exclude when compiling the list
 *   nameTransform (Optional) Function to run on each User to generate the name
 *   joinChar (Optional) The string to combine the names with
 */
export const getReadableUserList = (users = [], options = {}) => {
  const { excludedIds = [], nameTransform = getPreferredFullName, joinChar = ', ' } = options;

  return users
    .filter(user => !excludedIds.includes(user.id))
    .map(nameTransform)
    .join(joinChar);
};

/**
 * Picks the first patient out of a list of event attendees, then returns the patients
 * preferred full name.
 * Returns null if the attendees list is not an array, or if a patient entry cannot be
 * found in the attendees list.
 *
 * @param {Object[]} attendees List of User (resource) records
 */
export const getPatientAttendeeName = attendees => {
  if (!Array.isArray(attendees)) {
    return null;
  }

  const patientAttendee = attendees.find(attendee => attendee.__typename === 'Patient');
  const patientAttendeeName = patientAttendee ? getPreferredFullName(patientAttendee) : null;
  return patientAttendeeName;
};
