import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import EditProvider from 'src/components/forms/resources/editProvider';

class ProviderCreate extends Component {
  handleSave = provider =>
    this.props.rootStore.providers.saveProvider(provider).then(() => {
      this.props.rootStore.routerStore.goTo('providers');
    });

  handleCancel = () => {
    this.props.rootStore.routerStore.goTo('providers');
  };

  render() {
    const {
      classes,
      rootStore: {
        providers: { provider },
      },
    } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h6">Create Provider</Typography>
        <Typography variant="caption">* indicates required fields</Typography>
        {provider && (
          <EditProvider item={provider} onSave={this.handleSave} onCancel={this.handleCancel} />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 400,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(ProviderCreate)));
