import { addMinutes, format, isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { SubType } from 'src/events/visitTypes';
import { getPreferredFullName } from 'src/shared/stores/resource';
import { BLOCKED_EVENT_SUB_TYPES, labelForEventType } from 'src/shared/util/events';
import { EventInstance } from 'src/stores/models/event';

export const getEventDisplayTitle = (event: EventInstance, timezone: string) => {
  const eventLabel = labelForEventType(event.type, event.subType);
  const timeDisplay = getTimeDisplay(event, timezone);

  const firstPatient = event.attendees.find(
    attendee => attendee.__typename === 'Patient' || attendee.__typename === 'MyDayTodayPatient',
  );

  if (event && firstPatient) {
    return `${eventLabel}: ${getPreferredFullName(firstPatient)} ${timeDisplay}`;
  }

  if (event.subType && BLOCKED_EVENT_SUB_TYPES.includes(event.subType as SubType) && event.title) {
    return `${eventLabel}: ${event.title} ${timeDisplay}`;
  }

  return `${eventLabel}: ${timeDisplay}`;
};

export const getEventDisplayTitleWithoutPatient = (event: EventInstance, timezone: string) => {
  const eventLabel = labelForEventType(event.type, event.subType);
  const timeDisplay = getTimeDisplay(event, timezone);

  return `${eventLabel} ${timeDisplay}`;
};

export const getTimeDisplay = (event: EventInstance, timezone: string): string => {
  if (!event.start || !event.duration || !isValid(event.start)) {
    return '';
  }

  const eventStart = new Date(formatInTimeZone(event.start, timezone, 'yyyy-MM-dd HH:mm:ss'));

  const end = addMinutes(eventStart, event.duration);
  const startMeridian = format(eventStart, 'aaa');
  const endMeridian = format(end, 'aaa');
  const displayStartMeridian = startMeridian !== endMeridian;

  return `${format(eventStart, 'h:mm')} ${
    displayStartMeridian ? `${startMeridian} ` : ''
  }- ${format(end, 'h:mm')} ${endMeridian}`;
};
