export const TIMEZONES = [
  { label: 'US: Eastern Time', value: 'America/New_York' },
  { label: 'US: Central Time', value: 'America/Chicago' },
  { label: 'US: Mountain Time', value: 'America/Denver' },
  { label: 'US: Pacific Time', value: 'America/Los_Angeles' },
  { label: 'US: Alaska Time', value: 'America/Anchorage' },
  { label: 'US: Aleutian Time', value: 'America/Adak' },
] as const;

export type TimezoneName = (typeof TIMEZONES)[number]['value'];
export type TimezoneLabel = (typeof TIMEZONES)[number]['label'];

export const TIMEZONE_LABELS = TIMEZONES.reduce((result, item) => {
  result[item.value] = item.label; // eslint-disable-line no-param-reassign
  return result;
}, {});

export const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
