import debounce from 'lodash/debounce';
import type { RouterStore } from 'mobx-state-router';

type UpdateQueryParams = (queryParams: Record<string, string | number>) => void;

/**
 * Create a function that can be used to update the current query string
 *
 * This handles debouncing so you can call the resulting function multiple times and the query
 * will only get updated once.
 */
export function makeUpdateQueryParams(routerStore: RouterStore): UpdateQueryParams {
  let queryParamsChangeBatch = {};

  const finalizeUpdateQueryParams = debounce(() => {
    routerStore.goTo(routerStore.routerState.routeName, {
      params: routerStore.routerState.params,
      queryParams: {
        ...routerStore.routerState.queryParams,
        ...queryParamsChangeBatch,
      },
    });
    queryParamsChangeBatch = {};
  }, 0);

  return (queryParams: Record<string, string | number>) => {
    Object.assign(queryParamsChangeBatch, queryParams);
    finalizeUpdateQueryParams();
  };
}
