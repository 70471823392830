import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { clone } from 'mobx-state-tree';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

interface PebbleCreateIconProps {
  classes: {
    icon: string;
  };
  rootStore: RootStore;
}

const PebbleCreateIcon: React.FC<PebbleCreateIconProps> = function PebbleCreateIcon({
  classes,
  rootStore,
}) {
  const {
    pebbles: {
      ui: { toggleCreateDialog },
    },
  } = rootStore;
  return (
    <IconButton
      onClick={() =>
        toggleCreateDialog({
          participant: rootStore.patients?.patient ? clone(rootStore.patients.patient) : null,
        })
      }
      data-testid="create-pebble-button"
    >
      <Tooltip title="Create Pebble">
        <PlaylistAddIcon className={classes.icon} />
      </Tooltip>
    </IconButton>
  );
};

const styles = () => ({
  icon: {
    color: 'white',
  },
});

export default withStyles(styles)(inject<typeof PebbleCreateIcon>('rootStore')(PebbleCreateIcon));
