import { Typography, Button, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import { format, formatDistance } from 'date-fns';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import { getType } from 'mobx-state-tree';
import React from 'react';
import { Avatar } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';

import ConversationListItem from 'src/chat/ConversationListItem';
import featureFlagContext from 'src/components/featureflags/featureFlagContext';
import ChatPebbleCreateIcon from 'src/components/general/ChatPebbleCreateIcon';
import Tooltip from 'src/components/general/Tooltip';
import ConversationView from 'src/components/pages/pageElements/ConversationView';
import DeferredConversationList from 'src/components/pages/pageElements/DeferredConversationList';
import ZendeskLinkPanel from 'src/components/pages/pageElements/ZendeskLinkPanel';
import ConversationList from 'src/components/pages/pageElements/conversationList';
import { CHAT_PEBBLE_BUTTON } from 'src/featureFlags/currentFlags';
import logger from 'src/shared/util/logger';
import { getDisplayNameForMessage } from 'src/util/chat';
import { colors } from 'src/util/colors';

// When the user minimizes the window or switches to another tab, the Page Visibility API emits an event.
// Depending on the browser, the page visibility event will have one of the following names:
// 'visibilitychange', 'msvisibilitychange' or 'webkitvisibilitychange'.
// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
let pageVisibilityEvent;
if (typeof document.hidden !== 'undefined') {
  pageVisibilityEvent = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  pageVisibilityEvent = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  pageVisibilityEvent = 'webkitvisibilitychange';
}

class Chat extends React.Component {
  static contextType = featureFlagContext;

  inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      /** An Object mapping from converation ID to the Input component's text.
       * Note: A new chat's ID is null, so that's a reserved key here
       * @type {Object.<string,string>}
       * @see {@link https://bouldercare.atlassian.net/browse/BA-1180|created in BA-1180}
       * @see {@link https://www.notion.so/boulder/Staff-app-acceptance-tests-d2201bb0687f47259ee26b15c3f51662#d5e1171bcf0841f2b31f416639dac96c|Acceptance Tests}
       */
      inputValue: {},
      sending: false,
    };
  }

  componentDidMount() {
    // CARE-281: When the user minimizes the window or switches to another tab, the browser API emits an event denoting
    // that the document is inactive or not visible. We are tracking the visibility of the page in order to send an 'unread message' email notification to the provider
    // in the event that they receive a new chat message while the chat page is minimized or the browser is on another tab.
    // Depending on browser this event may have one of following the names; visibilitychange, msvisibilitychange or webkitvisibilitychange.
    document.addEventListener(pageVisibilityEvent, this.markConversationsRead);
    window.addEventListener('focus', this.markConversationsRead);
  }

  componentDidUpdate() {
    this.markConversationsRead();
  }

  componentWillUnmount() {
    document.removeEventListener(pageVisibilityEvent, this.markConversationsRead);
    window.removeEventListener('focus', this.markConversationsRead);
  }

  setInputValue = debounce((conversationID, textValue = '') => {
    const setInputValueActual = prevState => ({
      inputValue: {
        ...prevState.inputValue,
        [conversationID]: textValue,
      },
    });
    this.setState(setInputValueActual);
  }, 500);

  handleScroll = async evt => {
    const { currentConversation } = this.props;
    if (currentConversation && !currentConversation.loadingEarlier && evt.target.scrollTop === 0) {
      await currentConversation.loadEarlierMessages();
    }
  };

  handleSendTextMessage = () =>
    this.sendMessage(async conversation => {
      const message = await conversation.sendTextMessage(this.state.inputValue[conversation.id]);

      // check if the conversation is null, ie it was new and we've navigated away
      // eslint-disable-next-line react/no-string-refs
      if (this.inputRef.current?.input) {
        this.inputRef.current.clear(); // eslint-disable-line react/no-string-refs
        this.setInputValue(conversation.id);
      }
      return message;
    });

  handleInputChange = evt => {
    if (!evt.target || evt.FAKE_EVENT) {
      return;
    }
    const { currentConversation } = this.props;
    // conversation.id will be null for a new conversation
    this.setInputValue(currentConversation.id, evt.target.value);
  };

  /** Makes sure the Input value is set when selecting a conversation.
   * Note: implemented as a wrapper on `props.handleSelectConversation`
   * {@see ../chat.js|Chat Page (provides handleSelectConversation function)}
   */
  handleSelectConversationLocal = conversation => {
    this.props.handleSelectConversation(conversation);
    this.setInputValueFromState(conversation.id);
  };

  /** Makes sure the Input value is set when selecting a NEW conversation.
   * Note: implemented as a wrapper on `props.handleSelectNewConversation`
   * {@see ../chat.js|Chat Page (provides handleSelectNewConversation function)}
   */
  handleSelectNewConversationLocal = conversation => {
    this.props.handleSelectNewConversation(conversation);
    // conversation ID of a new conversation is null
    this.setInputValueFromState(null);
  };

  /** Sets Input component's value from inputValue state for a conversation.
   * Note: we can't set the input.value alone to refresh the component & see our
   *   changes. In order to do this, we've instead mimicked the component's own
   *   {@link https://github.com/Detaysoft/react-chat-elements/blob/f95508b976617f92d69c55b7022746cbdf035f3a/src/Input/Input.js#L40-L47|clear function},
   *   creating a fake event & triggering the onChange event handler
   */
  setInputValueFromState = conversationID => {
    // eslint-disable-next-line react/no-string-refs
    const refInput = this.inputRef.current?.input;
    if (!refInput) {
      return;
    }

    const event = {
      FAKE_EVENT: true,
      target: refInput,
    };
    refInput.value = this.state.inputValue[conversationID] ?? '';
    this.inputRef.current.onChange(event);
  };

  markConversationsRead = () => {
    const { currentConversation, allConversationsWithId, allOtherConversationsWithId, user } =
      this.props;

    const users = currentConversation
      ? [...currentConversation.users, ...(currentConversation.otherProviders || [])]
      : [];
    if (
      users.some(participant => participant.user.id === user.id) &&
      document.visibilityState === 'visible' &&
      document.hasFocus()
    ) {
      allConversationsWithId(currentConversation.id).forEach(conv => conv.markRead());

      //  CARE-250: Because we are at times managing two chat stores we want to make sure that
      //  both stores are in sync as to what messages have been read. This is conditional
      //  because there are cases where only the root chat store has been loaded and the
      //  particpantChat store has not. This happens when you navigate to the regular chat
      //  before navigating to the partcipantChat. In those cases there will be no
      //  other partcipantChat store and so, allOtherConversationsWithId will be undefined.
      if (allOtherConversationsWithId) {
        allOtherConversationsWithId(currentConversation.id).forEach(conv => conv.markRead());
      }
    }
  };

  /**
   * Utility method for converting a list of messages into the data source that the
   * MessageList component expects.
   */
  messagesToDataSource = getName => {
    const { currentConversation, user, classes, handleOpenVideoConversation } = this.props;
    if (!currentConversation) {
      return [];
    }

    const {
      reverseMessages,
      mostRecentVideoMessage,
      lastReadMessages,
      hasPatients,
      avatarUrlForUser,
    } = currentConversation;

    const flags = this.context;
    const isChatPebbleCreationEnabled = flags[CHAT_PEBBLE_BUTTON];

    return reverseMessages.map(message => {
      const messageType = getType(message).name;
      let displayName;
      switch (messageType) {
        case 'VideoConferenceMessage': {
          displayName = getName(message.from, messageType);

          if (message === mostRecentVideoMessage) {
            if (handleOpenVideoConversation) {
              return {
                type: 'system',
                text: `${displayName} started a video conversation. Click to join.`,
                date: message.createdAt,
                onClick: () => handleOpenVideoConversation(message),
                className: classes.clickable,
              };
            } else {
              return {
                type: 'system',
                text: `${displayName} started a video conversation.`,
                date: message.createdAt,
              };
            }
          } else {
            return {
              type: 'system',
              text: `${displayName} hosted a video conversation.`,
              date: message.createdAt,
            };
          }
        }
        case 'TextMessage': {
          // From now format is generally better for skimmability, but occasionally the clinical team
          // needs to know exactly when a particular message was sent, so make that available on hover.
          const dateElement = (
            <Tooltip title={format(message.createdAt, 'MMM d, yyyy p')}>
              <div>{`${formatDistance(new Date(), message.createdAt)} ago`}</div>
            </Tooltip>
          );

          const isFromPatient = message.from.__typename === 'Patient';
          const isFromCurrentUser = message.from.id === user.id;
          const isSystemMessage = message.displayMode === 'system';

          const patientConversationDisplay = isFromPatient ? 'left' : 'right';
          const staffConversationDisplay = isFromCurrentUser ? 'right' : 'left';
          const messagePosition = hasPatients
            ? patientConversationDisplay
            : staffConversationDisplay;

          const lastReadAvatars = lastReadMessages[message.id] && (
            <div
              className={classNames(classes.avatarContainer, classes[`${messagePosition}Message`])}
            >
              {lastReadMessages[message.id].map(cUser => {
                const readByDisplayName = getName(cUser.user, messageType);
                const avatar = cUser.user.id !== user.id && (
                  <Tooltip title={`Read by ${readByDisplayName}`} key={cUser.user.id}>
                    {/* can't put tooltip on Avatar, so including div here */}
                    <div>
                      <Avatar
                        src={avatarUrlForUser(cUser.user.id)}
                        className={classNames(classes.lastReadAvatar)}
                      />
                    </div>
                  </Tooltip>
                );
                return avatar;
              })}
            </div>
          );

          const showChatPebbleButton =
            isChatPebbleCreationEnabled && isFromPatient && !isSystemMessage;

          displayName = getName(message.from, messageType);

          return {
            position: messagePosition,
            type: isSystemMessage ? 'system' : 'text',
            text: message.text,
            copiableDate: true, // required to get MessageItem to render dateString
            dateString: dateElement,
            avatar: avatarUrlForUser(message.from.id),
            title: displayName,
            className: classNames(classes.messageBox, classes[`${messagePosition}Message`], {
              'is-patient-message': isFromPatient,
              'is-staff-message': !isFromPatient && !isFromCurrentUser,
              'is-system-message': isSystemMessage,
            }),
            renderAddCmp: () => (
              <>
                {lastReadAvatars}
                {showChatPebbleButton ? (
                  <ChatPebbleCreateIcon message={message} conversation={currentConversation} />
                ) : null}
              </>
            ),
          };
        }
        default: {
          logger.error(`Unknown message type: ${getType(message).name}`);
          return null;
        }
      }
    });
  };

  /**
   * Determines if the current user has the ability to message in the current
   * conversation. Added for the participant chat where the current user is able to read
   * messages they are not in, but cannot send messages unless they are monitoring them.
   */
  userMessagingAbility() {
    const { currentConversation, user: currentUser, sortedConversations } = this.props;
    const conversationsWithoutUser = sortedConversations?.filter(({ users }) =>
      users.every(({ user: { id } }) => id !== currentUser.id),
    );

    const isOtherConversations = conversationsWithoutUser?.some(
      convo => convo.id === currentConversation.id,
    );

    let userMessagingEnabled = true;
    if (isOtherConversations) {
      // Checks if conversation is monitored to determine messaging ability
      userMessagingEnabled = currentConversation.users.some(({ user }) =>
        currentUser.providersMonitored.some(monitoredProvider => monitoredProvider.id === user.id),
      );
    }

    return userMessagingEnabled;
  }

  /**
   * Wrapper method for sending messages of various types. Takes a callback function that takes
   * the conversation, sends the messages, and returns the sent message.
   */
  async sendMessage(sendFn) {
    this.setState({ sending: true });
    const { currentConversation } = this.props;

    try {
      const message = await sendFn(currentConversation);

      // If this was a new conversation, redirect to the real conversation now that it has an id.
      if (!currentConversation.id) {
        this.handleSelectConversationLocal(message.conversation);
      }
    } catch (err) {
      logger.error(err);
      this.props.showErrorMessage('Unable to send message - please try again.');
    } finally {
      this.setState({ sending: false });
    }
  }

  render() {
    const {
      classes,
      currentConversation,
      newConversation,
      conversationLists,
      conversationListButtons,
    } = this.props;
    const { inputValue, sending } = this.state;

    return (
      <div className={classes.root}>
        <Paper elevation={2} className={classes.sidebar} square>
          {this.props.patientId && <ZendeskLinkPanel patientId={this.props.patientId} />}
          {conversationLists.map(list => {
            const ListComponent = list.dataLoader ? DeferredConversationList : ConversationList;
            return (
              <ListComponent
                testID={list.key}
                handleSelectConversation={this.handleSelectConversationLocal}
                currentConversation={currentConversation}
                {...list}
              >
                {list.hasNewConversation && (
                  <ConversationListItem
                    conversation={newConversation}
                    onClick={this.handleSelectNewConversationLocal}
                    currentConversation={currentConversation}
                  />
                )}
              </ListComponent>
            );
          })}
          {conversationListButtons?.map(listButton => {
            const IconComponent = listButton.icon;
            return (
              <Button
                key={listButton.label}
                data-testid={listButton.label}
                className={classNames(classes.conversationListButtonContainer, {
                  [classes.conversationListButtonContainerActive]: listButton.isActive,
                })}
                onClick={listButton.onClick}
              >
                {IconComponent && <IconComponent className={classes.conversationListButtonIcon} />}
                <Typography variant="body2" className={classes.conversationListButtonLabel}>
                  {listButton.label}
                </Typography>
              </Button>
            );
          })}
        </Paper>
        {currentConversation && (
          <ConversationView
            inputRef={this.inputRef}
            conversation={currentConversation}
            dataSource={this.messagesToDataSource(getDisplayNameForMessage)}
            actionsDisabled={sending}
            onInputChange={this.handleInputChange}
            onInputSubmit={this.handleSendTextMessage}
            onScroll={this.handleScroll}
            submitDisabled={!inputValue[currentConversation.id]}
            submitLoading={sending}
            userMessagingEnabled={this.userMessagingAbility()}
            showArchiveButton={currentConversation?.canBeArchivedByCurrentUser}
            showArchiveForAllButton={currentConversation?.canBeArchivedForAllByCurrentUser}
          />
        )}
      </div>
    );
  }
}

const styles = theme => {
  const lightWarning = lighten(theme.palette.warning.main, 0.6);
  const lighterWarning = lighten(theme.palette.warning.main, 0.8);

  return {
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      '& .rce-container-mbox': {
        display: 'flex',
        flexDirection: 'column-reverse',
        overflow: 'initial',
      },
      '& .is-system-message .rce-smsg-text': {
        textAlign: 'left',
        wordBreak: 'break-all',
      },
      '& .rce-mbox-body': {
        paddingBottom: 10,
      },

      '& .rce-mbox': {
        marginTop: 10,
        marginBottom: 10,
        maxWidth: 500,
      },

      '& .rce-mbox-title': {
        color: 'inherit',
        fontWeight: 700,
        userSelect: 'auto',
      },

      '& .rce-mbox-title:hover': {
        cursor: 'text',
        textDecoration: 'none',
      },

      '& .rce-container-input': {
        borderTop: '1px solid #dddddd',
      },

      '& .rce-container-citem.has-unread': {
        fontWeight: 'bold',
      },

      '& .rce-container-citem.selected > .rce-citem': {
        backgroundColor: '#dddddd',
      },

      '& .rce-avatar': {
        objectFit: 'cover',
        borderRadius: '50%',
      },

      '& .rce-smsg': {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: '#dddddd',
        borderRadius: 0,
        boxShadow: 'none',
      },
    },
    conversationListButtonContainer: {
      alignItems: 'center',
      backgroundColor: '#F3F3F3',
      display: 'flex',
      // Same as default padding for buttons, but on margin for white "borders"
      margin: '6px 8px',
      minHeight: 40,
      padding: 0,
      position: 'relative',
      color: colors.taupe,

      '&:hover': {
        backgroundColor: '#DDDDDD',
      },
    },
    conversationListButtonContainerActive: {
      color: '#fff',
      backgroundColor: colors.taupe,
      '&:hover': {
        backgroundColor: colors.darkGray,
      },
    },
    conversationListButtonIcon: {
      paddingRight: 5,
    },
    sidebar: {
      width: 375,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
      zIndex: 1, // the dropshadow should cover the contents panel
      '& .rce-container-citem.selected.is-patient-conversation > .rce-citem ': {
        backgroundColor: lightWarning,
      },
    },
    chatList: {
      width: '100%',
    },
    messageList: {
      padding: 10,
      paddingBottom: 0,
      paddingTop: 0,
      flex: 1,
      overflowY: 'scroll',
    },
    messageBox: {
      whiteSpace: 'pre-line',
      '&.is-system-message': {
        maxWidth: 500,
      },
      '&.is-patient-message': {
        '& .rce-mbox': {
          backgroundColor: lighterWarning,
          '& .rce-mbox-left-notch, & .rce-mbox-right-notch': {
            fill: lighterWarning,
          },
        },
      },
      '&.is-staff-message': {
        '& .rce-mbox': {
          backgroundColor: '#F3F3F3',
          '& .rce-mbox-left-notch, & .rce-mbox-right-notch': {
            fill: '#F3F3F3',
          },
        },
      },
    },
    clickable: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    lastReadAvatar: {
      width: 25,
      height: 25,
      marginLeft: 5,
      marginRight: 5,
    },
    avatarContainer: {
      marginLeft: 12,
    },
    leftMessage: {
      alignItems: 'flex-start',
      '& Avatar': {
        flexDirection: 'row',
      },
    },
    rightMessage: {
      alignItems: 'flex-end',
    },
  };
};

export default withStyles(styles)(observer(Chat));
