"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notes = notes;
var _filter = _interopRequireDefault(require("lodash/filter"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function notes() {
  const elementProperties = this.$chartElement ? this.$chartElement.properties.map(property => property.name) : Object.keys(this);
  const format = elementProperties.length === 1 ? prop => `*${prop.notes}*` : prop => `*${prop.propertyLabel}*: ${prop.notes}`;
  return (0, _filter.default)(this, (prop, name) => !!prop.notes && elementProperties.includes(name)).map(format).join('\n');
}