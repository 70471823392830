import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import upperFirst from 'lodash/upperFirst';
import type { SWRResponse } from 'swr';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { ActivityDataTask, ActivityType } from 'src/components/pages/patientActivity/types';
import { ACTIVITY_TASKS_QUERY } from 'src/components/pages/patientActivity/useSWRActivityTasks.gql';
import type { Flow } from 'src/nightingale/types/types';

function useSWRActivityTasks(patientId: string): {
  data?: ActivityDataTask[];
  error?: SWRResponse['error'];
} {
  // make sure the hook runs unconditionally, to avoid errors
  const { data: tasksData, error } = useTypedSWR([ACTIVITY_TASKS_QUERY, { patientId }], {
    revalidateOnFocus: false,
  });

  const data = tasksData ? prepareTasks(tasksData.tasks) : undefined;

  return { data, error };
}

const prepareTasks = (tasks: TaskInteraction[]): ActivityDataTask[] => {
  return tasks.map(task => {
    const taskDate = task.completedAt || task.createdAt;
    const status = task.completedAt ? 'complete' : 'scheduled';
    return {
      taskId: task.taskId,
      name: task.flow.name,
      label: `Pt. Task: ${task.flow.label}`,
      type: ActivityType.Task,
      start: taskDate
        ? formatInTimeZone(
            taskDate,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            'MMM d, yyyy h:mma zzz',
          )
        : '',
      attendees: [],
      eventNotes: upperFirst(status),
      status,
      eventStatusInfo: {
        status,
        start: taskDate,
        allDay: false,
        duration: 0,
      },
      sortDateEpoch: taskDate ? parseISO(taskDate).getTime() : 0,
    };
  });
};

type TaskInteraction = {
  taskId: string;
  createdAt: string | null;
  completedAt: string | null;
  flow: Pick<Flow, 'label' | 'name'>;
};

export default useSWRActivityTasks;
