import { DocumentNode } from 'graphql';
import isEqual from 'lodash/isEqual';
import { types, getEnv, flow, IAnyType } from 'mobx-state-tree';

type QueryVariables = {
  first: number;
  skip: number;
  where?: Record<string, unknown>;
  orderBy?: string;
};

export default function createListModel<ItemModelType extends IAnyType>(
  name: string,
  itemType: ItemModelType,
  query: DocumentNode,
) {
  return types
    .model(name, {
      items: types.optional(types.array(itemType), []),
      order: types.optional(types.enumeration('Order', ['asc', 'desc']), 'asc'),
      orderBy: types.maybeNull(types.string),
      page: types.optional(types.integer, 0),
      rowsPerPage: types.optional(types.integer, 10),
      where: types.frozen(),
    })
    .actions(self => ({
      load: flow(function* load(additionalVariables = {}) {
        if (!query) {
          return;
        }

        const variables: QueryVariables = {
          first: self.rowsPerPage,
          skip: self.rowsPerPage * self.page,
        };

        if (self.where) {
          variables.where = self.where;
        }

        if (self.orderBy) {
          variables.orderBy = `${self.orderBy}_${self.order.toUpperCase()}`;
        }

        const results = yield getEnv(self).apolloClient.query({
          query,
          variables: { ...variables, ...additionalVariables },
        });

        self.items = results.data.items;
      }),
    }))
    .actions(self => ({
      reload: flow(function* reload(additionalVariables) {
        self.items.splice(0);
        return yield self.load(additionalVariables);
      }),
      update(updates, additionalVariables = {}) {
        if (
          updates.page === undefined &&
          (!isEqual(self.where, updates.where) || !isEqual(self.rowsPerPage, updates.rowsPerPage))
        ) {
          updates.page = 0;
        }
        Object.assign(self, updates);

        return self.load(additionalVariables);
      },
    }));
}
