import { gql } from 'src/generated/gql';

/** Gets all queue settings for the given schedule (Active, Weekday, etc.) */
export const CLINIC_QUEUE_SETTINGS_QUERY = gql(`
  query GetClinicQueueSettings($schedule: staff_QueueSchedule!) {
    staff_clinicQueueSettings(schedule: $schedule) {
      name
      value
      dataType
    }
  }
`);
