import gql from 'graphql-tag';
import keyBy from 'lodash/fp/keyBy';
import useSWR from 'swr';

type ChatTeamDetails = {
  id: string;
  name: string;
  color: string;
  icon: string;
};

type GetChatTeamsGql = {
  getChatTeams: ChatTeamDetails[];
};

type ChatTeamDictionary = Record<string, ChatTeamDetails>;

const CHAT_TEAM_QUERY = gql`
  query {
    getChatTeams {
      id
      name
      color
      icon
    }
  }
`;

export const useChatTeams = (): ChatTeamDictionary => {
  const { data } = useSWR<GetChatTeamsGql>(CHAT_TEAM_QUERY);

  const teamList = data?.getChatTeams ?? [];

  return keyBy('id', teamList);
};
