import gql from 'graphql-tag';

import type { SchedulingPayor } from 'src/scheduling/types';

export const GET_PAYORS_FOR_STATE = gql`
  query getPayorsForState($state: String) {
    payorsForState: getPayorsForState(state: $state) {
      key
      name
      invertStates
      credential {
        id
        name
      }
      states {
        id
        name
      }
    }
  }
`;

export type QueryResult = {
  payorsForState: SchedulingPayor[];
};
