import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ClinicTabIconOutlined = ({ className }: { className: string }) => {
  return (
    <SvgIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path d="M13.5 22.9148C11.7 22.9148 10.1667 22.2815 8.9 21.0148C7.63333 19.7481 7 18.2148 7 16.4148V15.8398C5.56667 15.6065 4.375 14.9356 3.425 13.8273C2.475 12.719 2 11.4148 2 9.91479V3.91479H5V2.91479H7V6.91479H5V5.91479H4V9.91479C4 11.0148 4.39167 11.9565 5.175 12.7398C5.95833 13.5231 6.9 13.9148 8 13.9148C9.1 13.9148 10.0417 13.5231 10.825 12.7398C11.6083 11.9565 12 11.0148 12 9.91479V5.91479H11V6.91479H9V2.91479H11V3.91479H14V9.91479C14 11.4148 13.525 12.719 12.575 13.8273C11.625 14.9356 10.4333 15.6065 9 15.8398V16.4148C9 17.6648 9.4375 18.7273 10.3125 19.6023C11.1875 20.4773 12.25 20.9148 13.5 20.9148C14.75 20.9148 15.8125 20.4773 16.6875 19.6023C17.5625 18.7273 18 17.6648 18 16.4148V14.7398C17.4167 14.5398 16.9375 14.1815 16.5625 13.6648C16.1875 13.1481 16 12.5648 16 11.9148C16 11.0815 16.2917 10.3731 16.875 9.78979C17.4583 9.20646 18.1667 8.91479 19 8.91479C19.8333 8.91479 20.5417 9.20646 21.125 9.78979C21.7083 10.3731 22 11.0815 22 11.9148C22 12.5648 21.8125 13.1481 21.4375 13.6648C21.0625 14.1815 20.5833 14.5398 20 14.7398V16.4148C20 18.2148 19.3667 19.7481 18.1 21.0148C16.8333 22.2815 15.3 22.9148 13.5 22.9148ZM19 12.9148C19.2833 12.9148 19.5208 12.819 19.7125 12.6273C19.9042 12.4356 20 12.1981 20 11.9148C20 11.6315 19.9042 11.394 19.7125 11.2023C19.5208 11.0106 19.2833 10.9148 19 10.9148C18.7167 10.9148 18.4792 11.0106 18.2875 11.2023C18.0958 11.394 18 11.6315 18 11.9148C18 12.1981 18.0958 12.4356 18.2875 12.6273C18.4792 12.819 18.7167 12.9148 19 12.9148Z" />
    </SvgIcon>
  );
};

export default ClinicTabIconOutlined;
