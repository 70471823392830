import useTypedSWR from 'src/components/general/useTypedSWR';
import { GET_STATE_AND_PAYOR } from 'src/scheduling/hooks/useStateAndPayor.gql';

/**
 * Custom hook for retrieving the listed state and payor for a patient
 *
 * Uses SWR to load the query.
 *
 * @param patientId The patient's ID, if available.
 *
 * @returns The state & payor names, which may each be undefined if none were found or if there was no patient.
 */

export const useStateAndPayor = (
  patientId: string | undefined | null,
  effectiveDate: Date | undefined,
): {
  patientState: string | null;
  patientPayorKey: string | null;
  patientPayorName: string | null;
} => {
  const { data } = useTypedSWR([
    patientId ? GET_STATE_AND_PAYOR : null,
    {
      patientId,
      effectiveDate: effectiveDate?.toISOString() || null,
    },
  ]);

  if (!data) {
    return { patientState: null, patientPayorKey: null, patientPayorName: null };
  }

  return {
    patientState: data.getPatientStateAndPayor?.state || null,
    patientPayorKey: data.getPatientStateAndPayor?.effectiveInsurance?.value || null,
    patientPayorName: data.getPatientStateAndPayor?.effectiveInsurance?.label || null,
  };
};
