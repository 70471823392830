import gql from 'graphql-tag';

import { FILE_SUMMARY } from 'src/stores/fragments/files';
import { USER_SUMMARY_LIMITED } from 'src/stores/fragments/users';

export const PROVIDER_ALL = gql`
  fragment ProviderAll on Provider {
    ...UserSummaryLimited
    middleName
    email
    dob
    phone
    address {
      street1
      street2
      city
      state
      zip
    }
    timezone
    avatar {
      ...FileSummary
    }
    teamRole
    npi
    taxId
    taxonomyCode
    isInactive
    bio
    patientFacingDisplayName
  }
  ${USER_SUMMARY_LIMITED}
  ${FILE_SUMMARY}
`;
