import useTypedSWR from 'src/components/general/useTypedSWR';
import { GET_PATIENT_CREDENTIAL_CRITERIA } from 'src/scheduling/hooks/usePatientCredentialCriteria.gql';

/**
 * Custom hook for retrieving the listed state and payor for a patient
 *
 * Uses SWR to load the query.
 *
 * @param patientId The patient's ID, if available.
 *
 * @returns The state & payor names, which may each be undefined if none were found or if there was no patient.
 */

export const usePatientCredentialCriteria = (
  patientId: string | undefined | null,
  effectiveDate: Date | undefined,
): {
  state: string | null;
  credentialId: string | null;
} => {
  const { data } = useTypedSWR([
    patientId ? GET_PATIENT_CREDENTIAL_CRITERIA : null,
    {
      patientId,
      effectiveDate: effectiveDate?.toISOString() || null,
    },
  ]);

  if (!data) {
    return { state: null, credentialId: null };
  }

  return {
    state: data.getPatientCredentialCriteria?.state || null,
    credentialId: data.getPatientCredentialCriteria?.credentialId || null,
  };
};
