import useTypedSWR from 'src/components/general/useTypedSWR';
import { LIST_PATIENT_SUBMITTED_LAB_RESULTS_QUERY } from 'src/labs/useSWRPatientSubmittedResults.gql';

export function useSWRPatientSubmittedResults(patientId: string) {
  const { data, error, mutate } = useTypedSWR([
    LIST_PATIENT_SUBMITTED_LAB_RESULTS_QUERY,
    {
      patientId,
    },
  ]);

  return {
    data: data?.staff_listLabResults ?? [],
    isLoading: !data && !error,
    error,
    mutate,
  };
}
