import {
  ContextSource,
  chartPropertyContext,
  evaluateExpression,
} from '@boulder-package/expressions';
import compact from 'lodash/compact';

import { convertChartProperty } from 'src/nightingale/summarization/conversion';
import { ListChartProperty, SimpleChartProperty, SortOrder } from 'src/nightingale/types/types';

export function sortValues(
  expression: string,
  direction: SortOrder,
  properties: ListChartProperty['properties'],
  values: Array<{
    [key: string]: any;
  }>,
): Array<{
  [key: string]: any;
}> {
  const sortValuesByIndex = values.map((value, index) => {
    const context = createContextForListItem(value, properties);
    const valueToSortBy = evaluateExpression(expression, context);

    return { index, valueToSortBy };
  });

  sortValuesByIndex.sort((a, b) => {
    if (a.valueToSortBy > b.valueToSortBy) {
      return direction === SortOrder.Ascending ? 1 : -1;
    }
    if (a.valueToSortBy < b.valueToSortBy) {
      return direction === SortOrder.Ascending ? -1 : 1;
    }
    return 0;
  });

  return sortValuesByIndex.map(value => values[value.index]);
}

function createContextForListItem(
  value: { [key: string]: any },
  properties: SimpleChartProperty[],
) {
  const currentItem = chartPropertyContext(
    compact(properties.map(convertChartProperty)),
    Object.keys(value).map(propertyName => ({
      isEmpty: undefined,
      notes: undefined,
      propertyName,
      value: value[propertyName],
    })),
  );

  return { $currentItem: currentItem, $source: ContextSource.Staff };
}
