import logger from 'src/shared/util/logger';

// currentPermission: from Notification.permission, one of `granted`, `denied`,
// or `default`
// requestFn: Notification.requestPermission
async function getNotifyPermission(currentPermission, requestFn) {
  if (currentPermission === 'granted') {
    return true;
  }

  if (currentPermission === 'denied') {
    return false;
  }

  const newPermission = await requestFn();
  return newPermission === 'granted';
}

export async function requestPermission() {
  const canNotify = getNotifyPermission(Notification.permission, Notification.requestPermission);
  if (!canNotify) {
    // this case shouldn't be reachable by our staff app
    //   Notification permissions policy. If it is the case,
    //   we should definitely let the staff app member know
    // eslint-disable-next-line no-alert
    alert(
      'Notifications permission denied. Please grant this permission for notifications to work as expected',
    );
  }
  return canNotify;
}

const audio = new Audio('https://freesound.org/data/previews/66/66136_606715-lq.mp3');

export async function show(title, notificationOptions, onClick) {
  if (!(await requestPermission())) {
    logger.info('Could not get permission to show BrowserNotification');
    return;
  }

  const notification = new Notification(title, notificationOptions);
  notification.onshow = () => {
    const response = audio.play();
    if (response !== undefined) {
      response.catch(error => {
        console.error('Something went wrong, notification sound not played', { error });
      });
    }
  };
  notification.onclick = () => {
    window.focus();
    notification.close();
    if (onClick) {
      onClick();
    }
  };
  // eslint-disable-next-line consistent-return
  return notification;
}

// exports below only for testing
export const __test__ = {
  getNotifyPermission,
};
