import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import type { ReactHTML } from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles<Theme, { noPadding?: boolean }>({
  container: {
    backgroundColor: Colors.ChartGray,
    boxSizing: 'border-box',
    padding: ({ noPadding }) => (noPadding ? 0 : 25),
  },
});

export const PageContainer: React.FC<{ component?: keyof ReactHTML; noPadding?: boolean }> = ({
  children,
  component = 'main',
  noPadding = false,
}) => {
  const styles = useStyles({ noPadding });
  return React.createElement(component, { className: styles.container }, children);
};
