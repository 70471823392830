import { makeStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';

import RouterLink from 'src/components/general/routerLink';
import Colors from 'src/nightingale/Colors';
import { BoulderColors } from 'src/util/brand';

type UnsignedVisit = {
  id: string;
  attendeesDisplay: Array<{
    userType: string;
    firstName: string;
    lastName: string;
    preferredName: string | null;
  } | null>;
};

type UnsignedVisitsCardProps = {
  providerId: string;
  unsignedVisits: UnsignedVisit[];
};

type PatientNameProps = {
  firstName: string;
  lastName: string;
  preferredName?: string | null;
};

const PatientName = (props: PatientNameProps) => {
  // Patient's name is prepended with "For"; e.g., "For Jane Roe"
  const name = ['For', props.firstName];

  if (props.preferredName) {
    name.push(`"${props.preferredName}"`);
  }

  name.push(props.lastName);

  return <div>{name.join(' ')}</div>;
};

const UnsignedVisitsCard = (props: UnsignedVisitsCardProps) => {
  const styles = useStyles();

  const numUnsignedVisits = props.unsignedVisits.length ?? 0;
  return (
    <div className={styles.container} data-testid="unsigned-visits-card">
      <div className={styles.header}>Sign and Complete Visit Notes ({numUnsignedVisits})</div>
      {props.unsignedVisits.map(visit => {
        const patient = visit.attendeesDisplay.find(attendee => attendee?.userType === 'Patient');
        return (
          <RouterLink
            className={styles.visitButton}
            routeName="showEvent"
            params={{ id: visit.id }}
            data-testid={`unsigned-visit-${visit.id}`}
            key={visit.id}
          >
            <span>
              <div className={styles.buttonTitle}>Sign and Complete Visit Note</div>
              {patient && (
                <div className={styles.patientName}>
                  <PatientName {...patient} />
                </div>
              )}
            </span>
            <div className={styles.arrow}>
              <ChevronRight />
            </div>
          </RouterLink>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({
  arrow: {
    width: '22.446px',
    height: '24px',
    color: Colors.Gray6,
  },
  buttonTitle: {
    alignSelf: 'stretch',
    color: Colors.Taupe,
    fontFamily: 'Nunito Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '145%',
    letterSpacing: '0.201px',
  },
  container: {
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: BoulderColors.Gray2,
    background: Colors.White,
    display: 'flex',
    width: '675px',
    padding: '24px 36px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    flexShrink: 0,
    alignSelf: 'stretch',
  },
  error: {
    color: BoulderColors.Gray6,
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontWeight: 600,
  },
  loading: {
    color: BoulderColors.Gray6,
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontWeight: 400,
  },
  header: {
    color: BoulderColors.Blue4,
    fontFamily: 'Nunito Sans',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '145%',
    letterSpacing: '0.201px',
  },
  patientName: {
    alignSelf: 'stretch',
    color: Colors.Gray6,
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '145%',
    letterSpacing: '0.201px',
  },
  visitButton: {
    border: '1px solid',
    borderColor: BoulderColors.Gray2,
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
});

export default UnsignedVisitsCard;
