import gql from 'graphql-tag';
import { useContext, useCallback } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';

export const SEND_BULK_PATIENT_NOTIFICATIONS = gql`
  mutation ($patientIds: [String!]!, $message: String!) {
    sendBulkPatientNotifications(patientIds: $patientIds, message: $message)
  }
`;

export const useSendBulkNotifications = () => {
  const { apolloClient } = useContext(ApolloClientContext);

  const sendBulkNotifications = useCallback(
    async ({ patientIds, message }) => {
      if (!apolloClient || !message || !patientIds?.length) return;
      await apolloClient.mutate({
        mutation: SEND_BULK_PATIENT_NOTIFICATIONS,
        variables: {
          patientIds,
          message,
        },
      });
    },
    [apolloClient],
  );

  return { sendBulkNotifications };
};
