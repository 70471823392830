import { observer } from 'mobx-react';
import React from 'react';

import useMyDayToday from 'src/myDayToday/hooks/useMyDayToday';
import useRootStore from 'src/stores/useRootStore';

/**
 * Determines where to redirect the user when they come to the root page of the staff app. If the
 * useMyDayToday hook returns true, they'll go there, otherwise they'll go to the legacy view,
 * which is the provider calendar view.
 *
 * This is temporary until the new My Day Today feature is used by all providers,
 * at which time this component can go away.
 */
const InterstitialLandingPage: React.FC = () => {
  const rootStore = useRootStore();
  const myDayTodayEnabled = useMyDayToday();

  const providerId = rootStore.auth.user?.id;
  if (myDayTodayEnabled) {
    rootStore.routerStore.goTo('myDayToday', {
      params: { id: providerId },
    });
  } else {
    rootStore.routerStore.goTo('showProvider', {
      params: { id: providerId },
    });
  }
  return null;
};

export default observer(InterstitialLandingPage);
