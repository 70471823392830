import React from 'react';

type Maybe<T> = T | null | undefined;

export type RefillsDetails = {
  adequateBupe?: boolean;
  needRefill?: boolean;
  outOf?: Maybe<string[]>;
};

type RefillsProps = {
  visitReasonDetails: RefillsDetails;
};

export const Refills = ({ visitReasonDetails }: RefillsProps) => {
  if (!visitReasonDetails.needRefill) {
    return null;
  }

  const medicationList = visitReasonDetails.outOf ?? [];
  const medicationText = medicationList
    .map(rx =>
      rx.match(/buprenorphine/i) && !visitReasonDetails.adequateBupe ? `${rx} (out)` : rx,
    )
    .join(', ');

  return (
    <li>
      <span className="label">Needs refill:</span> {medicationText}
    </li>
  );
};
