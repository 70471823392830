import { useEffect, useRef } from 'react';

/**
 * `useEffect` hook that only triggers after unmounting, similar to the
 * old-school `componentDidUnmount`.
 * @see {@link https://stackoverflow.com/a/65940606}
 */
export const useUnmountEffect = (func: () => void, deps: any[]) => {
  const isUnmounting = useRef(false);
  useEffect(() => {
    return () => {
      isUnmounting.current = true;
    };
  }, []);
  useEffect(() => {
    return () => {
      if (!isUnmounting.current) return;
      func();
    };
  }, deps);
};
