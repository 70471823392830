import React from 'react';

import { PatientNameProps } from 'src/myDayToday/types/EventCardPatient';

export const PatientName = (props: PatientNameProps) => {
  const name = [props.firstName];

  if (props.preferredName) {
    name.push(`"${props.preferredName}"`);
  }

  name.push(props.lastName);

  return <div>{name.join(' ')}</div>;
};
