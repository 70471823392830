import Colors from 'src/nightingale/Colors';

type UseTeamIconStylesOptions = {
  backgroundColor?: string;
  isInteractive?: boolean;
  size: number;
};

// makeStyles leads to very poor render performance, and this icon is rendered
// within every chat list item in the provider's conversation list. Possibly hundreds
// of instances. So we removed makeStyles and just return a simple object to be
// injected as a "style" prop.
export const useTeamIconStyles = ({
  backgroundColor,
  isInteractive,
  size,
}: UseTeamIconStylesOptions) => ({
  alignItems: 'center',
  backgroundColor: backgroundColor ?? Colors.Gray1,
  borderRadius: size,
  cursor: isInteractive ? 'pointer' : 'inherit',
  display: 'flex',
  height: size,
  justifyContent: 'center',
  width: size,
});
