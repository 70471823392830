import {
  EVENT_STATUSES,
  EVENT_COLORS,
  isPast,
  AVAILABILITY_SUB_TYPE_ITEMS,
  BLOCKED_EVENT_SUB_TYPES,
} from 'src/shared/util/events';

export const colors = {
  OFF_WHITE: '#FAFAFA',
  LIGHT_GREY: '#E8E8E8',
  MEDIUM_GREY: '#DDDDDD',
};

export const getEventStyles = currentView => event => {
  let style;
  if (currentView === 'agenda' || currentView === 'customAgenda') {
    style = getAgendaEventStyles(event);
  } else if (event.type === 'availability') {
    style = getAvailabilityStyles(event);
  } else {
    style = getCalendarEventStyles(event);
  }
  return { style };
};

const BASE_STYLE = {
  color: 'black',
  borderRadius: 0,
};

const getAgendaEventStyles = event => {
  const style = Object.assign({}, BASE_STYLE);
  if ([EVENT_STATUSES.CANCELED, EVENT_STATUSES.RESCHEDULED].includes(event.status)) {
    style.textDecoration = 'line-through';
    style.opacity = '0.5';
  }
  return style;
};

const getAvailabilityStyles = event => {
  const hasSubtype = event.subType in AVAILABILITY_SUB_TYPE_ITEMS;
  const style = Object.assign({}, BASE_STYLE, {
    backgroundColor: hasSubtype
      ? AVAILABILITY_SUB_TYPE_ITEMS[event.subType].backgroundColor
      : EVENT_COLORS.WHITE,
    borderColor: hasSubtype ? AVAILABILITY_SUB_TYPE_ITEMS[event.subType].color : null,
  });
  if (event.status === EVENT_STATUSES.CANCELED) {
    style.display = 'none';
  }
  return style;
};

export const getCalendarEventStyles = event => {
  const style = Object.assign({}, BASE_STYLE, {
    borderLeftStyle: 'solid',
    borderLeftWidth: '4px',
    paddingTop: 5,
  });
  if ([EVENT_STATUSES.CANCELED, EVENT_STATUSES.RESCHEDULED].includes(event.status)) {
    style.textDecoration = 'line-through';
    style.opacity = '0.5';
    style.borderColor = EVENT_COLORS.GRAY;
    style.backgroundColor = EVENT_COLORS.LIGHT_GRAY;
  } else if (BLOCKED_EVENT_SUB_TYPES.includes(event.subType)) {
    style.borderColor = EVENT_COLORS.BEIGE;
    style.backgroundColor = EVENT_COLORS.LIGHT_BEIGE;
  }

  // If the event is still scheduled, but exists in the past then it is displayed differently so
  // that the provider knows to update it.
  else if (
    [EVENT_STATUSES.SCHEDULED, EVENT_STATUSES.CONFIRMED].includes(event.status) &&
    isPast(event)
  ) {
    style.borderColor = EVENT_COLORS.PEACH;
    style.backgroundColor = EVENT_COLORS.WHITE;
  } else if (event.status === EVENT_STATUSES.CONFIRMED) {
    style.borderColor = EVENT_COLORS.GREEN;
    style.backgroundColor = EVENT_COLORS.WHITE;
  } else if (event.status === EVENT_STATUSES.SCHEDULED) {
    style.borderColor = EVENT_COLORS.BLUE;
    style.backgroundColor = EVENT_COLORS.WHITE;
  } else {
    style.borderColor = EVENT_COLORS.GREEN;
    style.backgroundColor = EVENT_COLORS.LIGHT_GREEN;
  }

  return style;
};
