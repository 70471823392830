import { observer } from 'mobx-react';
import React from 'react';

import { useStyles } from 'src/components/pages/eventShow/eventShow.styles';
import VisitNotes from 'src/components/pages/pageElements/visitNotes';
import { PageContainer } from 'src/nightingale/components/common/PageContainer/PageContainer';
import type { RootStore } from 'src/stores/root';
import { inject } from 'src/util/inject';

const EventShow: React.FC<{ rootStore: RootStore }> = ({ rootStore }) => {
  const {
    events: { event: maybeEvent },
  } = rootStore;
  const classes = useStyles({ isNightingale: !!maybeEvent?.isNightingale });

  if (!maybeEvent?.id) {
    return null;
  }

  return (
    <PageContainer>
      <div className={classes.root}>
        <VisitNotes />
      </div>
    </PageContainer>
  );
};

export default inject<typeof EventShow>('rootStore')(observer(EventShow));
