import React from 'react';

const cachedScripts: string[] = [];

/**
 * Loads third party script and keeps track of which scripts have been loaded
 * before so we can skip loading an already loaded script.
 *
 * @see {@link https://github.com/sesilio/react-script-loader-hoc/blob/master/src/index.js}
 */
const loadScript = (src: string) => {
  cachedScripts.push(src);

  const script = document.createElement('script');
  script.src = src;
  script.async = true;

  const promise = new Promise<typeof src>((resolve, reject) => {
    script.addEventListener('load', () => resolve(src));
    script.addEventListener('error', e => reject(e));
  }).catch(error => {
    const index = cachedScripts.indexOf(src);
    if (index >= 0) cachedScripts.splice(index, 1);
    script.remove();
    throw error;
  });

  document.body.appendChild(script);

  return promise;
};

/**
 * Generates a higher order component that loads the scripts given before
 * rendering its wrapped component.
 *
 * @see {@link https://github.com/sesilio/react-script-loader-hoc/blob/master/src/index.js}
 */
const withScripts = (...scriptSrcs: string[]) =>
  function createHOC<Props>(WrappedComponent: React.ComponentType<Props>) {
    class WithScriptsHOC extends React.Component<
      Props,
      {
        // scriptsLoaded: boolean;
        scriptsLoadedSuccessfully: boolean;
      }
    > {
      _isMounted = false;

      constructor(props) {
        super(props);

        this.state = {
          // scriptsLoaded: false,
          scriptsLoadedSuccessfully: false,
        };
      }

      componentDidMount() {
        this._isMounted = true;
        this.loadScripts(scriptSrcs);
      }

      componentWillUnmount() {
        this._isMounted = false;
      }

      loadScripts = srcs => {
        const promises = srcs
          .filter(src => !cachedScripts.includes(src))
          .map(src => loadScript(src));

        let success = true;
        Promise.all(promises)
          .catch(() => {
            success = false;
          })
          .then(() => {
            if (!this._isMounted) {
              return;
            }

            this.setState({
              // scriptsLoaded: true,
              scriptsLoadedSuccessfully: success,
            });
          });
      };

      render() {
        return this.state.scriptsLoadedSuccessfully ? <WrappedComponent {...this.props} /> : null;
      }
    }

    return WithScriptsHOC;
  };

export default withScripts;
