import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connectField, filterDOMProps, joinName, BaseField } from 'uniforms';
import { SelectField } from 'uniforms-material';

/* eslint-disable import/no-cycle */
import AutoField from 'src/components/forms/fields/autoField';
import { styles as nestFieldStyles } from 'src/components/forms/fields/nestField';
import { getFieldTitle } from 'src/components/forms/renderers/base';

/* eslint-enable */

class SelectWithDependentsField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allowedValues: [],
      selectedValue: '',
    };

    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getOptionLabel = this.getOptionLabel.bind(this);
  }

  componentDidMount() {
    const {
      value,
      field: { properties },
    } = this.props;
    const allowedValues = Object.keys(properties);
    const selectedValue = this.getSelectedValue(value);
    this.setState({
      allowedValues,
      selectedValue,
      optionFields:
        selectedValue && properties[selectedValue] && properties[selectedValue].properties
          ? Object.keys(properties[selectedValue].properties)
          : false,
    });
  }

  handleSelectFieldChange(value) {
    const {
      field: { properties },
      name,
    } = this.props;

    if (value === this.state.selectedValue) {
      return;
    }

    let optionFields = false;
    if (value && properties[value]) {
      if (typeof properties[value] !== 'object') {
        this.setFieldValue(value);
      } else if (properties[value].type === 'object') {
        optionFields = Object.keys(properties[value].properties);
        this.setFieldValue(value, {});
      } else {
        throw new Error(`Unknown schema type for selection: ${joinName(name, value)}`);
      }
    }

    this.setState({
      selectedValue: value,
      optionFields,
    });
  }

  getOptionLabel(option) {
    const {
      field: { properties },
    } = this.props;
    return option in properties && getFieldTitle(properties[option], option);
  }

  getSelectedValue(value) {
    const keys = value ? Object.keys(value) : [];
    return keys.pop();
  }

  setFieldValue(key, value) {
    // Using the context.onChange instead of the onChange property passed into the field allows the
    // actual model state (which is what gets sent to onSubmit when the form is submitted) to be
    // controlled separately from the field's native handling of model state.
    const {
      uniforms: { onChange },
    } = this.context;
    const { name } = this.props;
    onChange(name, { [key]: value || true });
  }

  render() {
    const { name, classes, list, placeholder, ...props } = this.props;
    const { allowedValues, selectedValue } = this.state;

    return (
      <div className={list ? classes.nestedFieldsInList : null}>
        <SelectField
          allowedValues={allowedValues}
          onChange={this.handleSelectFieldChange}
          name={name}
          placeholder={placeholder}
          value={selectedValue}
          transform={this.getOptionLabel}
          {...filterDOMProps(props)}
        />
        {this.state.optionFields && (
          <div className={classes.otherFieldContainer}>
            {this.state.optionFields.map(key => (
              <AutoField key={key} name={joinName(name, selectedValue, key)} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  otherFieldContainer: {
    paddingLeft: 18,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: '#dddddd',
    marginTop: 10,
  },
  nestedFieldsInList: {
    ...nestFieldStyles.nestedFieldsInList,
    width: '100%',
  },
};

SelectWithDependentsField.defaultProps = {
  fullWidth: true,
};

SelectWithDependentsField.contextTypes = BaseField.contextTypes;

export default withStyles(styles)(
  connectField(SelectWithDependentsField, {
    includeInChain: false,
    overrideValue: true,
  }),
);
