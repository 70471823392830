export default {
  type: 'object',
  title: 'Onboarding: Social Needs',
  disabled: true,
  properties: {
    confirmedAccessToTransportation: { type: 'boolean' },
    confirmedSafeHousing: { type: 'boolean' },
    confirmedAccessToWifi: { type: 'boolean' },
    confirmedAccessToASmartphone: { type: 'boolean' },
    notes: {
      type: 'string',
      uniforms: {
        multiline: true,
      },
    },
  },
  required: [],
};
