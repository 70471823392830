import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, isValid, parseISO } from 'date-fns';
import React from 'react';
import { connectField, filterDOMProps } from 'uniforms';

const DateField = ({
  disabled,
  error,
  errorMessage,
  label,
  name,
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  placeholder,
  showInlineError,
  value,
  ...props
}) => (
  <KeyboardDatePicker
    disabled={!!disabled}
    name={name}
    // Adding null here makes the field appear empty when nothing is selected,
    // as undefined will default to showing today's date instead
    value={value ? parseISO(value) : null}
    onChange={newValue => {
      if (disabled) return;
      onChange(isValid(newValue) ? format(newValue, 'yyyy-MM-dd') : newValue);
    }}
    error={!!error}
    label={label}
    placeholder="MM/DD/YYYY"
    invalidLabel={error && showInlineError && errorMessage}
    format="MM/dd/yyyy"
    invalidDateMessage="Date must be formatted like: 01/31/2019"
    autoOk
    fullWidth
    {...filterDOMProps(props)}
  />
);

export default connectField(DateField);
