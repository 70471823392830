"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datediffhuman = datediffhuman;
var _dateFns = require("date-fns");
function datediffhuman(date) {
  const then = (0, _dateFns.parseISO)(date);
  if (!(0, _dateFns.isValid)(then)) {
    return '';
  }
  return (0, _dateFns.formatDistanceToNow)(then, {
    addSuffix: true
  });
}