import startCase from 'lodash/startCase';

import { getExactFullName, UserNames } from 'src/shared/stores/resource';
import { ClaimInstance } from 'src/stores/claims';

/**
 * Takes in a claim item from Mobx and restructures/renames the properties to match
 * the field names the material table columns expect.
 */
export function transformClaimForMaterialTable(claim: ClaimInstance): TableRowData {
  return {
    // Table columns
    date: claim.localDate,
    cptCodes: claim.cptCodes,
    name: claim.patientInformation
      ? getExactFullName(claim.patientInformation as unknown as UserNames)
      : null,
    payor: claim.payor?.name,
    state: claim.patientInformation?.address?.state,
    status: claim.meta.status,
    statusDisplay: startCase(claim.meta.status),
    type: claim.meta.type,

    // Expanded row data, button action info, etc. just come from the original claim
    claim,
  };
}

export type TableRowData = {
  /**
   * The original claim object
   */
  claim: ClaimInstance;
  cptCodes: string[];
  date?: string | null;
  name?: string | null;
  payor?: string | null;
  state?: string | null;
  status: string;
  statusDisplay: string;
  type: string;
};

/**
 * The type of claim represented by an object or table row
 *
 * Copied from src/claims/domain/types.ts#Type
 */
export enum ClaimType {
  /** A clinical visit involving a patient and provider. */
  Visit = 'VISIT',
  /** Messages sent to us by patients which may qualify as care to be billed for. */
  Messaging = 'MESSAGING',
}
