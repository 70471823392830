import gql from 'graphql-tag';

export const SEARCH_MEDICATIONS = gql`
  query SearchMedications($q: String!) {
    medications(q: $q) {
      key
      name
    }
  }
`;

export const SEARCH_PROBLEMS = gql`
  query SearchProblems($q: String!) {
    problems(q: $q) {
      key
      name
    }
  }
`;

export const SEARCH_PAYORS = gql`
  query SearchPayors($q: String, $keys: [String]) {
    payors: payors(q: $q, keys: $keys) {
      key
      name
    }
  }
`;
