"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _handlebars = _interopRequireDefault(require("handlebars"));
require("./handlebars-helpers/handlebars-helpers");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const compileCache = {};
function template(input, context) {
  if (!(input in compileCache)) {
    compileCache[input] = _handlebars.default.compile(input);
  }
  return compileCache[input](context);
}
var _default = exports.default = template;