import { Button, makeStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import isNil from 'lodash/fp/isNil';
import React, { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { ApolloClientContext } from 'src/data/ApolloClientContext';
import Colors from 'src/nightingale/Colors';
import { BooleanInput } from 'src/patientVerification/components/booleanInput';

const IS_PATIENT_VERIFIED = gql`
  query IsPatientVerified($patientId: ID!) {
    isPatientVerified(patientId: $patientId)
  }
`;

const SET_PATIENT_VERIFIED = gql`
  mutation SetPatientVerification($patientId: ID!, $verified: Boolean!) {
    setPatientVerification(patientId: $patientId, verified: $verified)
  }
`;

type VerifyIdentityProps = {
  patientId: string;
};

export const VerifyIdentityControl: React.VFC<VerifyIdentityProps> = ({ patientId }) => {
  const styles = useStyles();
  const [editing, setEditing] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [verified, setVerified] = useState<boolean | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const { apolloClient } = useContext(ApolloClientContext);
  const { data, error, mutate } = useSWR<{ isPatientVerified: boolean }>(
    [IS_PATIENT_VERIFIED, { patientId }],
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    setVerified(data?.isPatientVerified ?? null);
  }, [data]);

  if (error) {
    return <div>{formatError(error)}</div>;
  } else if (!data || !apolloClient) {
    return <div>Loading verification status...</div>;
  }

  return (
    <section
      onBlur={event => {
        // selecting one of the yes/no buttons will temporarily blur this container, so we need to
        // give the new focus-target time to settle, then check to see if we've really been blurred
        const { currentTarget } = event;
        requestAnimationFrame(async () => {
          if (currentTarget.contains(document.activeElement)) {
            return;
          }

          if (!dirty || isNil(verified)) {
            setDirty(false);
            setEditing(false);
            return;
          }

          try {
            await apolloClient.mutate({
              mutation: SET_PATIENT_VERIFIED,
              variables: { patientId, verified },
            });
            setSaveError(null);
            setDirty(false);
          } catch (err) {
            setSaveError(formatError(err));
          }
          mutate({ isPatientVerified: !!verified });
          setEditing(false);
        });
      }}
    >
      {editing ? (
        <div className={styles.editor} data-testid="verification-editor">
          Identity verified?
          <div className={styles.control}>
            <BooleanInput
              isSelected={verified}
              onChange={(newVerified: boolean) => {
                setDirty(true);
                setVerified(newVerified);
              }}
            />
          </div>
        </div>
      ) : (
        <Button onClick={() => setEditing(true)}>
          {verified ? 'Identity verified' : 'Identity not verified'}
        </Button>
      )}
      {saveError ? <div>Error saving verification: {saveError}</div> : null}
    </section>
  );
};

function formatError(error: any): string {
  if ('message' in error && typeof error.message === 'string') {
    return error.message;
  }
  return String(error);
}

const useStyles = makeStyles({
  editor: {
    backgroundColor: '#FFFFFF',
    maxWidth: '300px',
    paddingLeft: '10px',
    outline: `2px solid ${Colors.Stillwater}`,
  },
  control: {
    display: 'inline-block',
  },
});
