import gql from 'graphql-tag';
import { useState, useContext } from 'react';

import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { requestTypeMap } from 'src/dropInClinic/RequestType';

const ENQUEUE_DROP_IN_PATIENT = gql`
  mutation DropInClinicEnqueuePatient(
    $requestType: staff_DropInClinicRequestType!
    $patientId: ID!
  ) {
    staff_enqueueDropInPatient(requestType: $requestType, patientId: $patientId) {
      warnings
    }
  }
`;

type ClinicActionSuccess = {
  state: 'success';
  warning?: string;
};

type ClinicActionError = {
  state: 'error';
  errors?: string[];
};

type ClinicActionNoAvailability = {
  state: 'no_availability';
};

type ClinicActionLoading = {
  state: 'loading';
  requestType: keyof typeof requestTypeMap;
};

export type ClinicActionState =
  | ClinicActionSuccess
  | ClinicActionError
  | ClinicActionNoAvailability
  | ClinicActionLoading;

export const useClinicActions = patientId => {
  const { apolloClient } = useContext(ApolloClientContext);
  const [state, setState] = useState<ClinicActionState | null>(null);
  const createRequest = async (requestType: keyof typeof requestTypeMap) => {
    setState({ state: 'loading', requestType });
    try {
      const res = await apolloClient?.mutate({
        mutation: ENQUEUE_DROP_IN_PATIENT,
        variables: {
          requestType,
          patientId,
        },
        errorPolicy: 'all',
      });

      if (
        res?.errors?.length === 1 &&
        res.errors[0].extensions?.code === 'DROP_IN_CLINIC_NO_AVAILABILITY'
      ) {
        setState({ state: 'no_availability' });
        return;
      }

      if (res?.errors && res?.errors.length > 0) {
        setState({ state: 'error', errors: res.errors.map(error => error.message) });
        return;
      }

      setState({ state: 'success', warning: res?.data.warning });
    } catch (e) {
      setState(null);
      throw e;
    }
  };

  return { state, createRequest };
};
