import { withStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import gql from 'graphql-tag';
import React from 'react';
import 'react-chat-elements/dist/main.css';
import useSWR from 'swr';

import FeatureFlag from 'src/components/featureflags/featureFlag';
import { ZENDESK_PATIENT_SIDEBAR_LINK } from 'src/featureFlags/currentFlags';
import Colors from 'src/nightingale/Colors';

const GET_PATIENT_ZENDESK_URL = gql(`
  query LoadZendeskPatientUrl($id: ID!) {
    menuPatient(id: $id) {
      zendeskUrl
    }
  }
`);

const ZendeskLinkPanel = props => {
  const { classes, patientId } = props;

  const { data = {}, error } = useSWR([GET_PATIENT_ZENDESK_URL, { id: patientId }]);

  const zendeskUrl = data?.menuPatient?.zendeskUrl;

  if (error || !zendeskUrl) return null;
  return (
    <div className={classes.zendeskPanel}>
      <a href={zendeskUrl} className={classes.linkText}>
        Conversations in Zendesk <OpenInNewIcon className={classes.icon} />
      </a>
    </div>
  );
};

const ZendeskLinkPanelFlagGate = props => (
  <FeatureFlag name={ZENDESK_PATIENT_SIDEBAR_LINK}>
    <ZendeskLinkPanel {...props} />
  </FeatureFlag>
);
const styles = () => {
  return {
    zendeskPanel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '40px',
      padding: '8px',
      boxShadow: `0 0 4px ${Colors.Gray3}`,
      zIndex: 1,
    },
    linkText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: Colors.BlueSpruce,
      fontFamily: 'Arial',
      fontSize: 16,
      letterSpacing: 0.25,
      textDecorationLine: 'underline',
    },
    icon: {
      height: 16,
      width: 16,
    },
  };
};

export default withStyles(styles)(ZendeskLinkPanelFlagGate);
