import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { parseISO } from 'date-fns';
import React, { useRef } from 'react';

import Colors from 'src/nightingale/Colors';
import {
  DATE_FORMAT_DISPLAY,
  MaybeDate,
} from 'src/nightingale/components/ChartProperty/controls/Date/MaybeDate';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { useControlState } from 'src/nightingale/hooks/useControlState';
import { useDidUpdateEffect } from 'src/nightingale/hooks/useDidUpdateEffect';
import { useShowValidationError } from 'src/nightingale/hooks/useShowValidationError';
import { useValidationForPropertyType } from 'src/nightingale/hooks/useValidation';
import { ControlProps, DateChartProperty, ChartPropertyTypes } from 'src/nightingale/types/types';

export type DateControlProps = ControlProps<DateChartProperty>;

/**
 * Styles
 */
const useStyles = makeStyles({
  input: {
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    '& ::placeholder': {
      color: Colors.Gray4,
      fontStyle: 'italic',
    },
    marginTop: 5,
  },
  underline: {
    '&.MuiInput-underline::before': {
      borderColor: Colors.Gray2,
    },
    '&.MuiInput-underline::after': {
      borderColor: Colors.BlueSpruce,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: `1px solid ${Colors.BlueSpruce}`,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled)::after': {
      borderBottom: `2px solid ${Colors.BlueSpruce}`,
    },
  },
  error: {
    '&.MuiInput-underline:after': {
      borderColor: Colors.Coral,
    },
  },
  helperText: {
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '145%',
    marginTop: 4,
    '&, &.Mui-error': {
      color: Colors.Coral,
    },
  },
  iconRoot: {
    marginLeft: -10,
    marginRight: -8,
  },
  container: {
    // mui is adding overflow hidden to the container when opening the date picker
    // force overflow visible to prevent a visual bug (date control jumps up)
    overflow: 'visible !important',
  },
  disabled: {
    backgroundColor: Colors.Gray1,
    cursor: 'not-allowed',
    color: Colors.Gray6,

    '$underline&::before': {
      borderColor: Colors.Gray3,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
  },
});

/**
 * Date Control component. Supports numeric input or date selection from the calendar popup
 * @see {@link https://material-ui-pickers.dev/api/KeyboardDatePicker}
 */
export const DateControl: React.FC<DateControlProps> = ({
  hasEmptyValue,
  label,
  isRequired,
  onChangeValue,
  onError,
  placeholder = 'MM/DD/YYYY',
  value,
  ...rest
}) => {
  const containerRef = useRef(null);
  const styles = useStyles();
  const [internalValue, setInternalValue] = useControlState<MaybeDate | null>(
    value ? new MaybeDate(parseISO(value), value) : null,
  );
  useDidUpdateEffect(() => {
    onChangeValue?.(internalValue?.serializeForData() ?? null);
  }, [internalValue]);

  const validationError = useValidationForPropertyType(
    internalValue?.value,
    ChartPropertyTypes.Date,
  );
  const [showError, setIsTouched, setHasSeenErrors] = useShowValidationError(value);
  useDidUpdateEffect(() => {
    const hasValidationError = !!validationError?.errors?.length;
    if (hasValidationError) setHasSeenErrors(true);
    if (onError) onError(hasValidationError);
  }, [validationError]);

  const hasError = showError && !!validationError?.errors?.length;
  const errorMessage = showError ? validationError?.message : '';

  return (
    <div ref={containerRef} className={styles.container}>
      <LabeledFormControl hasEmptyValue={hasEmptyValue} isRequired={isRequired} label={label}>
        <KeyboardDatePicker
          DialogProps={{ container: containerRef.current }}
          FormHelperTextProps={{ classes: { root: styles.helperText } }}
          InputAdornmentProps={{ position: 'start' }}
          InputProps={{
            classes: {
              root: styles.input,
              underline: styles.underline,
              error: styles.error,
              disabled: styles.disabled,
            },
            autoComplete: 'off',
          }}
          KeyboardButtonProps={{
            classes: { root: styles.iconRoot },
          }}
          autoOk
          data-testid="date-control"
          error={hasError}
          format={DATE_FORMAT_DISPLAY}
          fullWidth
          helperText={errorMessage}
          onChange={(date: Date | null, input: string) => {
            setInternalValue(date ? new MaybeDate(date, input) : null);
          }}
          placeholder={placeholder}
          showTodayButton
          onBlur={() => setIsTouched(true)}
          value={internalValue}
          inputValue={internalValue?.serializeForDisplay()}
          {...rest}
        />
      </LabeledFormControl>
    </div>
  );
};
