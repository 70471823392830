"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.join = join;
var _identity = _interopRequireDefault(require("lodash/identity"));
var _initial = _interopRequireDefault(require("lodash/initial"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function join(separator, ...args) {
  return (0, _initial.default)(args).filter(_identity.default).join(separator);
}