import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import PatientReference from 'src/components/forms/controls/PatientReference';
import ProviderReference from 'src/components/forms/controls/providerReference';
import Submit from 'src/components/forms/controls/submit';
import TextControl from 'src/components/forms/controls/text';
import Field from 'src/components/forms/field';
import IconTooltip from 'src/components/general/iconTooltip';

const BULK_MESSAGE_VALIDATOR = Yup.object().shape({
  participants: Yup.array() // prettier-ignore
    .nullable()
    .required(),
  providers: Yup.array().nullable(),
  message: Yup.string() // prettier-ignore
    .nullable()
    .required(),
});

const participantTooltip =
  'Your message will be sent to each of the selected participants separately. A new thread will be created if one does not yet exist for the recipients of each message.';

const providerTooltip =
  'If you select additional providers, they will be included in each participant message.';

const steps = [
  {
    title: 'Select Recipients',
    nextDisabled: values => !values.participants || values.participants.length === 0,
    component: (values, classes, searchAdditionalProviders) => (
      <div className={classes.recipientsForm}>
        <IconTooltip title={participantTooltip}>
          <Field
            label="Participants *"
            name="participants"
            component={PatientReference}
            isMulti
            placeholder="Select Participants..."
          />
        </IconTooltip>
        <br />
        <IconTooltip title={providerTooltip}>
          <Field
            label="Additional Providers"
            name="providers"
            component={ProviderReference}
            isMulti
            loadOptions={searchAdditionalProviders}
            placeholder="Select Additional Providers..."
          />
        </IconTooltip>
      </div>
    ),
  },
  {
    title: 'Draft Message',
    nextDisabled: values => !values.message || values.message.length === 0,
    component: () => (
      <Field
        label="Message *"
        name="message"
        component={TextControl}
        multiline
        placeholder="Type message to send..."
      />
    ),
  },
  {
    title: 'Confirm and Send',
    component: (values, classes) => (
      <>
        <Typography>
          Send the following message to {values.participants?.length}{' '}
          {values.participants?.length === 1 ? 'participant' : 'participants'}?
        </Typography>
        {values.providers?.length > 0 && (
          <Typography>
            <i>
              ({values.providers.length} additional{' '}
              {values.providers.length === 1 ? 'provider' : 'providers'} will be included in each
              message)
            </i>
          </Typography>
        )}
        <br />
        <Typography className={classes.messagePreview}>{values.message}</Typography>
      </>
    ),
  },
];

/**
 * DEPRECATED: This functionality is hidden when REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY FF is true
 * Can remove along w/ FF
 */
const SendBulkMessageDialog = ({ classes, onReset, onSubmit, searchAdditionalProviders }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Formik
      onReset={onReset}
      onSubmit={onSubmit}
      validationSchema={BULK_MESSAGE_VALIDATOR}
      initialValues={{}}
    >
      {({ handleSubmit, handleReset, isValid, dirty, isSubmitting, values }) => (
        <Dialog open maxWidth="sm" fullWidth onClose={handleReset}>
          <DialogTitle>Send a message to multiple participants...</DialogTitle>
          <DialogContent>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map(step => (
                <Step key={step.title}>
                  <StepLabel className={classes.stepLabel}>{step.title}</StepLabel>
                  <StepContent>
                    <Typography component="div">
                      {step.component(values, classes, searchAdditionalProviders)}
                    </Typography>
                    <div className={classes.buttonGroup}>
                      <Button disabled={activeStep === 0} onClick={handleBack}>
                        Back
                      </Button>
                      {activeStep === steps.length - 1 ? (
                        <Submit
                          inline
                          color="primary"
                          warn
                          variant="contained"
                          size="small"
                          onClick={handleSubmit}
                          disabled={!isValid || !dirty || isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              <CircularProgress size={16} />
                              <Typography variant="body2" className={classes.submittingCopy}>
                                Sending...
                              </Typography>
                            </>
                          ) : (
                            // Weird usage of feature, but technically possible and grammatically accurate
                            <Typography variant="body2">
                              Send to Patient{values.participants.length > 1 && 's'}
                            </Typography>
                          )}
                        </Submit>
                      ) : (
                        <Button
                          disabled={step.nextDisabled?.(values)}
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </DialogContent>
          <DialogActions>
            <Button variant="text" size="small" onClick={handleReset}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

const styles = () => ({
  buttonGroup: {
    paddingTop: 20,
  },
  finePrint: {
    fontSize: 14,
  },
  messagePreview: {
    // Imitating the border/padding styling used by the stepper component
    borderLeft: '1px solid #bdbdbd',
    color: '#555',
    paddingLeft: 20,
    whiteSpace: 'pre-line',
  },
  stepLabel: {
    fontSize: 16,
  },
  recipientsForm: {
    // Allowing space for tooltip to appear
    paddingLeft: 5,
  },
  submittingCopy: {
    paddingLeft: 8,
  },
});

export default withStyles(styles)(SendBulkMessageDialog);
