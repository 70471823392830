import React from 'react';

type PatientPebblesCountState = {
  count: number;
};

const PatientPebblesCountContext = React.createContext<PatientPebblesCountState>({
  count: 0,
});

export default PatientPebblesCountContext;
