export default {
  type: 'object',
  title: 'Onboarding: Referral Details',
  properties: {
    referrerName: { type: 'string' },
    referrerTitle: { type: 'string' },
    referrerPractice: { type: 'string' },
    referrerPhoneNumber: { type: 'string', format: 'phone' },
    referrerIsDeaLicensed: { title: 'Referrer is DEA-licensed', type: 'boolean' },
    referrerPhysicalExam: {
      title: 'Referrer has completed a physical exam and confirmed medical stability for treatment',
      type: 'boolean',
    },
    notes: { type: 'string', uniforms: { multiline: true } },
  },
  required: [],
};
