import { makeStyles } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  title: {
    color: Colors.Gray6,
    fontFamily: 'Tenor Sans',
    fontSize: 26,
    fontWeight: 300,
    lineHeight: 1,
    marginBottom: '25px',
    textTransform: 'capitalize',

    '&:first-child': {
      marginTop: 0,
    },
  },
});

export const PageTitle: React.FC = ({ children }) => {
  const styles = useStyles();
  return <h1 className={styles.title}>{children}</h1>;
};
