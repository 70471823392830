/* eslint-disable consistent-return */

/* eslint-disable func-names */
import every from 'lodash/every';
import flatMap from 'lodash/flatMap';
import includes from 'lodash/includes';
import sumBy from 'lodash/sumBy';
import { types, flow, getEnv, getSnapshot } from 'mobx-state-tree';

import { configCatFlags } from 'src/featureFlags/configCat';
import { REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY } from 'src/featureFlags/currentFlags';
import { Conversation, ConversationInstance } from 'src/stores/chat/conversation';
import { convertResourceToUserDisplay, Provider, Resource } from 'src/stores/chat/user';
import type { ProviderInstance, ResourceInstance } from 'src/stores/chat/user';
import { SEND_BULK_MESSAGE, SEND_MESSAGE_IN_NEW_CONVERSATION } from 'src/stores/mutations/messages';
import {
  LOAD_CONVERSATION,
  LOAD_DIRECT_CONVERSATIONS,
  NUM_CONVERSATIONS_UNREAD,
  LOAD_MONITORED_CONVERSATIONS,
  LOAD_PROVIDER_CONVERSATIONS,
} from 'src/stores/queries/conversations';
import { LOAD_MESSAGE } from 'src/stores/queries/messages';
import {
  SUBSCRIBE_TO_NEW_MESSAGES,
  SUBSCRIBE_TO_UPDATED_CONVERSATION,
  SUBSCRIBE_TO_READ_CONVERSATION,
} from 'src/stores/subscriptions/chat';

const Chat = types
  .model('Chat', {
    // Copy of the logged in user
    currentUser: Provider,
    // Person whose conversations we're looking at
    chatOwner: Resource,
    currentConversationId: types.maybeNull(types.string),
    subscribeToUpdates: types.optional(types.boolean, true),
    conversationsById: types.optional(types.map(Conversation), {}),
    monitoredChats: types.optional(types.array(types.late(() => Chat)), []),
    newConversation: types.maybeNull(Conversation),
  })
  .volatile(() => ({
    conversationsLoaded: false,
    numWithUnread: 0,
  }))
  .views(self => ({
    /**
     * Get conversations map as an array
     */
    get conversations() {
      return Array.from(self.conversationsById.values());
    },
  }))
  .views(self => ({
    /**
     * Get conversations sorted by most recent message.
     */
    get sortedConversations() {
      return self.conversations.slice().sort((a, b) => {
        const aDate = a?.lastMessageAt || new Date();
        const bDate = b?.lastMessageAt || new Date();
        return bDate.getTime() - aDate.getTime();
      });
    },
  }))
  .views(self => ({
    /**
     * Get conversations containing a patient, sorted by most recent message.
     */
    get sortedPatientConversations() {
      return self.sortedConversations.filter(conversation => conversation.hasPatients);
    },
  }))
  .views(self => ({
    /**
     * Get patient conversations that are not set as archived by the current
     * user.
     */
    get sortedUnarchivedPatientConversations() {
      return self.sortedPatientConversations.filter(
        conversation => !conversation.isArchivedForCurrentUser,
      );
    },

    /**
     * Get patient conversations that have been set as archived by the current
     * user.
     */
    get sortedArchivedPatientConversations() {
      return self.sortedPatientConversations.filter(
        conversation => conversation.isArchivedForCurrentUser,
      );
    },

    /**
     * Get conversations containing only staff members, sorted by most recent message.
     */
    get sortedStaffConversations() {
      return self.sortedConversations.filter(conversation => !conversation.hasPatients);
    },

    /**
     * Count the number of patient conversations that have unread messages.
     */
    get numWithPatientUnread() {
      return sumBy(self.conversations, conversation =>
        conversation.hasPatients && conversation.hasUnread ? 1 : 0,
      );
    },

    /**
     * Get the conversation with the given id.
     */
    conversation(id) {
      return self.conversationsById.get(id);
    },

    /**
     * Get the last monitored conversation with the given id.
     */
    monitoredConversation(id) {
      let match;
      self.monitoredChats.forEach(chat => {
        if (chat.conversation(id)) {
          match = chat.conversation(id);
        }
      });
      return match;
    },

    /**
     * Get the 1-1 conversation with someone with given id.
     */
    directConversationWith(id) {
      return self.conversations.find(conversation => {
        return (
          conversation.otherProviderUsers.length === 0 &&
          conversation.users.some(user => user.userDisplay.id === id)
        );
      });
    },
  }))
  .views(self => ({
    /**
     * Count the number of patient conversations that are not set as archived by the current
     * user and have unread messages.
     */
    get numWithUnarchivedPatientUnread() {
      return sumBy(self.sortedUnarchivedPatientConversations, conversation =>
        conversation.hasUnread ? 1 : 0,
      );
    },

    /**
     * Count the number of staff only conversations that have unread messages.
     */
    get numWithStaffUnread() {
      return sumBy(self.conversations, conversation =>
        !conversation.hasPatients && conversation.hasUnread ? 1 : 0,
      );
    },

    /**
     * Get the conversation currently selected
     */

    get currentConversation() {
      if (self.currentConversationId === 'create') {
        return self.newConversation;
      } else if (self.currentConversationId) {
        return (
          self.conversation(self.currentConversationId) ||
          self.monitoredConversation(self.currentConversationId)
        );
      } else {
        return null;
      }
    },

    /**
     * Get the conversation that we think the current user will be
     * most interested in being directly linked to in the left menu.
     * Specifically, we check for a match in the following order:
     *    - A 1-1 conversation between the current user and the chat owner
     *    - The most recent conversation containing both users
     *    - A newly created conversation
     */
    get defaultConversationWithCurrentUser() {
      if (self.currentUser.id !== self.chatOwner.id) {
        return (
          self.directConversationWith(self.currentUser.id) ||
          self.sortedConversations.find(convo =>
            convo.users.some(conversationUser => conversationUser.user?.id === self.currentUser.id),
          ) || { id: 'create' }
        );
      }
      return null;
    },

    /**
     * Get all my conversations and monitored conversations as a single list
     */
    get allConversations() {
      return [...self.conversations, ...flatMap(self.monitoredChats, chat => chat.conversations)];
    },
  }))
  .views(self => ({
    /**
     * Get all conversations (my conversations and monitored conversations) with id.
     * @param {*} id
     */
    allConversationsWithId(id) {
      return self.allConversations.filter(conv => conv.id === id);
    },

    /*
     * Get the monitored conversations for the provider with the given id
     */
    monitoredChatsFor(id) {
      return self.monitoredChats.find(chat => chat.chatOwner.id === id);
    },
  }))
  .actions(self => ({
    /**
     * Internal callback used to fetch the number of unread messages
     */
    loadUnreadMessageCount: flow(function* loadUnreadMessageCount() {
      const response = yield getEnv(self).apolloClient.query({
        query: NUM_CONVERSATIONS_UNREAD,
      });
      if (response.data.numConversationsUnread !== undefined) {
        self.numWithUnread = response.data.numConversationsUnread;
      }
    }),
    mergeConversations(conversationsToMerge) {
      self.conversationsById.merge(conversationsToMerge.map(conv => [conv.id, conv]));
    },
  }))
  .actions(self => ({
    loadProviderConversations: flow(function* loadProviderConversations(loadArchived?: boolean) {
      const response = yield getEnv(self).apolloClient.query({
        query: LOAD_PROVIDER_CONVERSATIONS,
        variables: {
          id: self.chatOwner.id,
          archived: loadArchived,
        },
      });

      const { providerConversations } = response.data;

      self.mergeConversations(providerConversations);
    }),
    loadMonitoredConversations: flow(function* loadMonitoredConversations(providerId: string) {
      const response = yield getEnv(self).apolloClient.query({
        query: LOAD_MONITORED_CONVERSATIONS,
        variables: { id: self.chatOwner.id, providerId },
      });

      const { monitoredConversations } = response.data;

      if (monitoredConversations) {
        let monitoredChat = self.monitoredChatsFor(providerId);
        if (!monitoredChat) {
          const providerToMonitor = self.currentUser.providersMonitored.find(
            provider => provider.id === providerId,
          );
          if (providerToMonitor) {
            self.monitoredChats.push({
              currentUser: getSnapshot(self.currentUser),
              chatOwner: getSnapshot(providerToMonitor),
              subscribeToUpdates: false,
            });
            monitoredChat = self.monitoredChats[self.monitoredChats.length - 1];
          } else {
            throw new Error('The request provider is not currently monitored.');
          }
        }
        monitoredChat.mergeConversations(monitoredConversations);
      }
    }),
  }))
  .actions(self => ({
    loadConversation: flow(function* loadConversation(id) {
      if (!self.conversationsLoaded) {
        yield self.loadProviderConversations(false);

        if (self.conversationsById.has(id)) {
          // This is a direct conversation with the current user so it was loaded with the call to
          // loadProviderConversations and we're done.
          return;
        }
      }

      if (id !== 'create') {
        const response = yield getEnv(self).apolloClient.query({
          query: LOAD_CONVERSATION,
          variables: { id },
        });
        const { conversations = [] } = response.data;
        const conversation = conversations[0];

        if (conversation) {
          const chatOwner = conversation.users.find(
            user => user.userDisplay.id === self.chatOwner.id,
          );
          if (chatOwner) {
            // This is a direct conversation, but it might be archived. If it is archived then check
            // to see if it has already been loaded. If not then load all archived conversations,
            // otherwise, update the loaded conversation with the new data

            if (!self.conversationsById.has(id)) {
              // It's an archived conversation, but archived conversations haven't been loaded
              // so go ahead and load all archived conversations which will also include the current
              // conversation being loaded.
              yield self.loadProviderConversations(chatOwner.archived);
            } else {
              self.conversationsById.set(id, conversation);
            }

            // We only want to do the work to see if this is a monitored conversation if the chat that
            // we are looking at is owned by the current user which is, as of this comment being
            // written, is the only case where monitored conversation are handled.
          } else if (self.chatOwner.id === self.currentUser.id) {
            // This is a monitored conversation so for each provider in the list of users we need
            // to check whether or not we're monitoring them and if we are then check to see if
            // their conversations are loaded yet.
            const directConversationUserIds = conversation.users.map(user => user.userDisplay.id);
            const monitoredProviders = self.currentUser.providersMonitored.filter(provider =>
              directConversationUserIds.includes(provider.id),
            );

            const monitoredChatsToLoadPromises = [];
            monitoredProviders.forEach(provider => {
              if (self.monitoredChatsFor(provider.id)) {
                self.monitoredChatsFor(provider.id).conversationsById.set(id, conversation);
              } else {
                monitoredChatsToLoadPromises.push(self.loadMonitoredConversations(provider.id));
              }
            });

            yield Promise.all(monitoredChatsToLoadPromises);
          }
        }
      }
    }),
  }))
  .actions(self => ({
    /**
     * Internal callback used when a new message is received via our newMessageSubscription.
     */
    _handleNewMessage: flow(function* _handleNewMessage({ conversation: { id } }) {
      let conversations = self.allConversationsWithId(id);
      const wasUnread = conversations.length ? conversations[0].hasUnread : null;

      // If this is the first message in a new conversation, we need to fetch the conversation info.
      if (!conversations.length) {
        yield self.loadConversation(id);
        conversations = self.allConversationsWithId(id);
      }

      if (!conversations.length) {
        // network request failed?
        return;
      }

      // Here we could just add the message itself to the conversation, but then there's lots
      // of complexity around keeping the local state in sync with the db and making sure we
      // don't accidentally miss a message or get things out of order. Instead, just ask the
      // server for the latest.
      yield Promise.all(conversations.map(conv => conv.loadLatestMessages()));

      if (wasUnread === null) {
        // Can't deduce the change to the unread count in this scenario because we couldn't see the
        // convo's `hasUnread` prior to it getting the new message, so do the more expensive server
        // query for the unread count.
        yield self.loadUnreadMessageCount();
        return;
      }

      // Incrementally update the unread count by comparing `hasUnread` before and after the new
      // message happened. There's some chance of getting out of sync with the server, but it's
      // worth it because counting unreads on the server has been a performance hog for a long
      // time, with barriers to optimization.
      if (!wasUnread && conversations[0].hasUnread) {
        self.numWithUnread += 1;
      } else if (wasUnread && !conversations[0].hasUnread) {
        // Rare, since we're being notified of a new message, but possible due to races between
        // the multiple API calls
        self.numWithUnread -= 1;
      }
    }),

    /**
     * Internal callback used when a conversation is updated via our conversationUpdatedSubscription.
     */
    _handleConversationUpdated: flow(function* _handleConversationUpdated(updatedConversation) {
      yield self.loadUnreadMessageCount();
      const conversations = self.allConversationsWithId(updatedConversation.id);

      // If we're being updated about a conversation we don't have yet, fetch it.
      if (!conversations.length) {
        yield self.loadConversation(updatedConversation.id);
      } else {
        conversations.forEach(conv => conv.setMeta(updatedConversation));
      }
    }),

    /**
     * Internal callback used when a conversation is updated via our conversationReadSubscription.
     */
    _handleConversationRead: flow(function* _handleConversationRead(readConversation) {
      const conversations = self.allConversationsWithId(readConversation.id);

      // If we're being updated about a conversation we don't have yet, fetch it.
      if (!conversations.length) {
        yield self.loadConversation(readConversation.id);
      } else {
        conversations.forEach(conv => conv.setMeta(readConversation));
      }
    }),

    /**
     * Load or refresh the list of conversations in a participant's chat view
     */
    loadParticipantConversations: flow(function* loadParticipantConversations() {
      const response = yield getEnv(self).apolloClient.query({
        query: LOAD_DIRECT_CONVERSATIONS,
        variables: { id: self.chatOwner.id },
      });

      self.mergeConversations(response.data.conversations);
    }),
    loadMessagesForSelectedConversation: flow(function* loadMessagesForSelectedConversation() {
      if (self.currentConversation) {
        const conversations = self.allConversationsWithId(self.currentConversationId);
        yield Promise.all(conversations.map(conv => conv.loadLatestMessages()));
      }
    }),
    /**
     * Create a temporary new converstion that will get saved and assigned an
     * ID when the first message is sent
     */
    createNewConversationWith(...otherUsers: ResourceInstance[]) {
      const newConversation = Conversation.create({
        loaded: true,
        users: [
          {
            userDisplay: convertResourceToUserDisplay(getSnapshot(self.currentUser)),
          },
          ...otherUsers.map(otherUser => ({
            userDisplay: convertResourceToUserDisplay(getSnapshot(otherUser)),
          })),
        ],
      });
      self.newConversation = newConversation;
      return newConversation;
    },
  }))
  .actions(self => ({
    /**
     * Load or refresh the list of conversations that directly involve the user.
     */
    loadDirectConversations: flow(function* loadDirectConversations(
      filterChatConversations?: (
        currentUser: ProviderInstance,
        conversation: ConversationInstance,
      ) => boolean,
    ) {
      const response = yield getEnv(self).apolloClient.query({
        query: LOAD_DIRECT_CONVERSATIONS,
        variables: { id: self.chatOwner.id },
      });

      // TODO: This filters the results of the LOAD_DIRECT_CONVERSATIONS query, but should
      // be done on the server-side instead in the long-term.
      let loadedConversations = response.data.conversations;
      if (filterChatConversations) {
        const { currentUser } = self;
        loadedConversations = loadedConversations.filter(conversation =>
          filterChatConversations(currentUser, conversation),
        );
      }

      self.mergeConversations(loadedConversations);
      yield self.loadMessagesForSelectedConversation();
    }),
  }))
  .actions(self => {
    let newMessageSubscription;
    let conversationUpdatedSubscription;
    let conversationReadSubscription;

    return {
      /**
       * Immediately create a subscription so we get notified every time there's a new message.
       */
      // eslint-disable-next-line require-yield
      afterAttach: flow(function* afterAttach() {
        if (!self.subscribeToUpdates) {
          return;
        }
        // Fetch conversations immediately so that unread counters are populated
        self.loadUnreadMessageCount().catch(() => {});

        function updateConversationSubscriptions() {
          const isChatSubscribed = !configCatFlags.latestFlags[REMOVE_LEGACY_CHAT_STAGE1_VIEW_ONLY];
          if (isChatSubscribed) {
            if (!newMessageSubscription) {
              newMessageSubscription = getEnv(self)
                .apolloClient.subscribe({
                  query: SUBSCRIBE_TO_NEW_MESSAGES,
                })
                .subscribe({
                  next(data) {
                    self._handleNewMessage(data.data.newMessage);
                  },
                  error(err) {
                    console.info(err);
                  },
                });
            }

            if (!conversationUpdatedSubscription) {
              conversationUpdatedSubscription = getEnv(self)
                .apolloClient.subscribe({
                  query: SUBSCRIBE_TO_UPDATED_CONVERSATION,
                })
                .subscribe({
                  next(data) {
                    self._handleConversationUpdated(data.data.conversationUpdated);
                  },
                  error(err) {
                    console.info(err);
                  },
                });
            }

            if (!conversationReadSubscription) {
              conversationReadSubscription = getEnv(self)
                .apolloClient.subscribe({
                  query: SUBSCRIBE_TO_READ_CONVERSATION,
                })
                .subscribe({
                  next(data) {
                    self._handleConversationRead(data.data.conversationRead.conversation);
                  },
                  error(err) {
                    console.info(err);
                  },
                });
            }
          } else {
            if (newMessageSubscription) {
              newMessageSubscription.unsubscribe();
              newMessageSubscription = undefined;
            }
            if (conversationUpdatedSubscription) {
              conversationUpdatedSubscription.unsubscribe();
              conversationUpdatedSubscription = undefined;
            }
            if (conversationReadSubscription) {
              conversationReadSubscription.unsubscribe();
              conversationReadSubscription = undefined;
            }
          }
        }

        configCatFlags.addEventListener('change', updateConversationSubscriptions);
      }),

      beforeDestroy() {
        if (newMessageSubscription) {
          newMessageSubscription.unsubscribe();
        }
        if (conversationUpdatedSubscription) {
          conversationUpdatedSubscription.unsubscribe();
        }
        if (conversationReadSubscription) {
          conversationReadSubscription.unsubscribe();
        }
      },

      monitorProvider: function monitorProvider(provider) {
        self.currentUser.providersMonitored.push(provider);
      },

      /**
       * Choose and load the correct set of conversations depending on who we are and whose chat
       * we're looking at.
       */
      loadAllConversations: flow(function* loadAllConversations() {
        const userIsChatOwner = self.currentUser.id === self.chatOwner.id;

        if (!userIsChatOwner) {
          yield self.loadParticipantConversations();
        } else {
          yield self.loadProviderConversations(false);
        }
        yield self.loadMessagesForSelectedConversation();
        self.conversationsLoaded = true;
      }),

      removeMonitoredConversationFor(providerToUnmonitor) {
        if (self.monitoredChatsFor(providerToUnmonitor.id)) {
          const removedIndex = self.monitoredChats.findIndex(
            chat => chat.chatOwner.id === providerToUnmonitor.id,
          );
          self.monitoredChats.splice(removedIndex, 1);
        }
      },

      /**
       * Find or create a conversation between the current user and the user(s) given.
       */
      conversationWith(...otherUsers) {
        const ids = [
          self.currentUser.id,
          ...otherUsers.filter(user => user.id !== self.currentUser.id).map(user => user.id),
        ];
        const conversation = self.conversations.find(
          conv =>
            conv.users.length === ids.length &&
            every(conv.users, user => includes(ids, user.userDisplay.id)),
        );

        if (conversation) {
          return conversation;
        } else {
          return self.createNewConversationWith(...otherUsers);
        }
      },

      /**
       * Send a message from self.newConversation, which requires creating a new conversation.
       */
      sendMessageInNewConversation: flow(function* (messageData) {
        if (self.newConversation) {
          const response = yield getEnv(self).apolloClient.mutate({
            mutation: SEND_MESSAGE_IN_NEW_CONVERSATION,
            variables: {
              fromId: self.currentUser.id,
              toIds: self.newConversation.otherUsers.map(user => user.userDisplay.id),
              ...messageData,
            },
          });

          // Refresh the conversation list to fetch the new message.
          yield self.loadDirectConversations();
          self.newConversation = null;
          return response.data.createMessage;
        }
      }),

      /**
       * Send a single message to multiple participants
       */
      sendBulkMessage: flow(function* (text, toIds, otherRecipientIds) {
        yield getEnv(self).apolloClient.mutate({
          mutation: SEND_BULK_MESSAGE,
          variables: {
            otherRecipientIds,
            fromId: self.currentUser.id,
            toIds,
            text,
          },
        });

        // Refresh conversation list to see the newly sent messages
        yield self.loadDirectConversations();
      }),

      /**
       * Utility method for fetching a single message by id. Unlike the rest, this just returns
       * it rather than writing it to the model, allowing the caller to manage state. Need to think
       * about whether this model makes sense or if the result should always end up in the store.
       */
      getMessage: flow(function* getMessage(messageId) {
        const response = yield getEnv(self).apolloClient.query({
          query: LOAD_MESSAGE,
          variables: {
            messageId,
          },
        });
        return response.data.message;
      }),

      /*
       * Select a conversation by id, loading the latest messages
       */
      selectConversation: flow(function* selectConversation(conversationId) {
        self.currentConversationId = conversationId;
        if (!self.currentConversation) {
          yield self.loadConversation(conversationId);
        }
        yield self.loadMessagesForSelectedConversation();
      }),
    };
  });

export default Chat;
