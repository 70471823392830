import { makeStyles } from '@material-ui/styles';
import { format, isPast, isValid } from 'date-fns';
import React from 'react';

import { colors } from 'src/util/colors';

const useStyles = makeStyles({
  runOutLabel: {
    '&.past': {
      color: colors.muiRed,
      fontWeight: 600,
    },
  },
});

export const MedicationRunOutLabel = (props: { runOutDate?: Date }) => {
  const classes = useStyles();
  const classList = [classes.runOutLabel];

  if (!props.runOutDate || !isValid(props.runOutDate)) {
    return <span>--</span>;
  }

  if (isPast(props.runOutDate)) {
    classList.push('past');
  }

  return <span className={classList.join(' ')}>{format(props.runOutDate, 'M/d/yyyy')}</span>;
};
