import { gql } from 'src/generated/gql';

export const SCHEDULE_LOGS_QUERY = gql(`
  query GetScheduleLogs($searchDate: DateTime) {
    staff_getScheduleLogs(searchDate: $searchDate) {
      timestamp
      initiatedBy {
        name
        role
      }
      type
      log
    }
  }
`);
