import { gql } from 'src/generated/gql';

export const GET_PATIENT_CREDENTIAL_CRITERIA = gql(`
  query GetPatientCredentialCriteria($patientId: ID!, $effectiveDate: DateTime) {
    getPatientCredentialCriteria(patientId: $patientId, effectiveDate: $effectiveDate) {
      state
      credentialId
    }
  }
`);
