import { Theme, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useRef } from 'react';

import Colors from 'src/nightingale/Colors';
import { ChartElementConfirm } from 'src/nightingale/components/ChartElement/ChartElement.Confirm';
import { ChartElementEditor } from 'src/nightingale/components/ChartElement/ChartElement.Editor';
import { ChartElementErrorBoundary } from 'src/nightingale/components/ChartElement/ChartElement.ErrorBoundary';
import { ChartElementSnackbar } from 'src/nightingale/components/ChartElement/ChartElement.SnackbarError';
import { ChartElementView } from 'src/nightingale/components/ChartElement/ChartElement.View';
import { getMessageForError } from 'src/nightingale/components/ChartElement/ChartElement.utils';
import { ChartElementProps } from 'src/nightingale/components/ChartElement/types';
import { useChartElement } from 'src/nightingale/components/ChartElement/useChartElement';

export const ChartElementAlt: React.FC<ChartElementProps> = props => (
  <ChartElementErrorBoundary definition={props.definition}>
    <ChartElementInner {...props} />
  </ChartElementErrorBoundary>
);

const ChartElementInner: React.FC<ChartElementProps> = ({ definition, onEdit, onView }) => {
  const viewerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    clearSnackbarError,
    confirmChartElement,
    definitionWithValues,
    enterChartElement,
    focusChartElement,
    hasValidationError,
    isReadOnly,
    isSaving,
    isFlagged,
    hasWarning,
    leaveChartElement,
    saveError,
    setPropertyValue,
    setToEditMode,
    setValidationErrorForProperty,
    snackbarError,
    startEditing,
    uiMode,
  } = useChartElement({
    containerRef,
    definition,
    onEdit,
    onView,
  });

  const styles = useStyles();
  const viewerClassNames = [styles.viewer];
  if (isFlagged) {
    viewerClassNames.push(styles.isFlagged);
  } else if (hasWarning) {
    viewerClassNames.push(styles.hasWarning);
  }

  return (
    <section
      aria-selected={uiMode !== 'view'}
      className={styles.container}
      data-chart-item-type="ChartElement"
      data-chart-item-name={definition.name}
      data-testid="chart-element-container"
      data-test-name={definitionWithValues.name}
      onBlur={event => {
        const { currentTarget } = event;

        // @src https://muffinman.io/blog/catching-the-blur-event-on-an-element-and-its-children/
        // Give browser time to focus the next element
        requestAnimationFrame(() => {
          // Check if the new focused element is a child of the original container
          if (!currentTarget.contains(document.activeElement)) {
            leaveChartElement();
          }
        });
      }}
      onFocus={event => {
        const { currentTarget } = event;
        requestAnimationFrame(() => {
          if (viewerRef?.current && (childHasFocus(viewerRef.current) || hasFocus(currentTarget))) {
            enterChartElement();
          }
        });
      }}
      ref={containerRef}
      role="tab"
      tabIndex={isReadOnly ? undefined : 0}
    >
      <ChartElementSnackbar error={snackbarError} clearError={clearSnackbarError} />
      {uiMode === 'edit' && (
        <div className={styles.editorContainer}>
          <div data-testid="chart-element-editor-container" className={styles.editor} key="editor">
            <ChartElementEditor
              definition={definitionWithValues}
              focusChartElement={focusChartElement}
              hasSaveError={!!saveError}
              isSaving={isSaving}
              onValidationError={setValidationErrorForProperty}
              saveErrorMessage={getMessageForError(saveError)}
              setPropertyValue={setPropertyValue}
              altEnabled
            />
          </div>
        </div>
      )}
      {uiMode === 'confirm' && (
        <div className={styles.editorContainer}>
          <div
            className={styles.editor}
            data-testid="chart-element-confirmer-container"
            key="confirmer"
            ref={viewerRef}
          >
            <ChartElementConfirm
              definition={definitionWithValues}
              onConfirm={() => confirmChartElement()}
              onEdit={() => setToEditMode()}
            />
          </div>
        </div>
      )}
      <div
        className={classNames(viewerClassNames)}
        data-testid="chart-element-viewer-container"
        key="viewer"
        ref={viewerRef}
      >
        <ChartElementView
          definition={definitionWithValues}
          hasSaveError={!!saveError}
          hasValidationError={hasValidationError}
          isSaving={isSaving}
          startEditing={startEditing}
        />
      </div>
    </section>
  );
};

const useStyles = makeStyles<Theme>(theme => {
  const highlight = (color: string) => ({
    background: `${color}1E`, // Adjusting alpha to reach ≥3:1 contrast ratio with the help text
    border: `${theme.spacing(0.25)}px solid ${color}`,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  });

  return {
    container: {
      '&:focus': {
        outline: `2px solid ${Colors.Stillwater}`,
      },
      position: 'relative',
      top: 0,
      left: 0,
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    isFlagged: highlight(Colors.Coral),
    hasWarning: highlight(Colors.YellowUiWarn),
    viewer: {
      animation: `$fadeIn 500ms ease-out`,
    },
    editor: {
      animation: `$fadeIn 500ms ease-out`,
      left: 0,
      right: 0,
      top: 0,
      position: 'absolute',
    },
    editorContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 10000,
    },
  };
});

/** Does the given element contain the element that has focus? */
function childHasFocus(element: HTMLElement) {
  return element.contains(document.activeElement);
}

/** Does this element have focus? */
function hasFocus(element: HTMLElement): boolean {
  return element === document.activeElement;
}
