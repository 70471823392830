import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react';
import React from 'react';

import EditAvailabilityForm from 'src/components/forms/resources/editAvailability';
import EditEventForm from 'src/components/forms/resources/editEvent/editEvent';

const EditEventDialog = ({ onCancel, onSave, onNavigate, item, fixedAttendee }) => {
  if (!item) {
    return null;
  }

  if (item.type === 'availability') {
    return (
      <Dialog open maxWidth="md" fullWidth onClose={onCancel}>
        <DialogTitle>Available</DialogTitle>
        <DialogContent>
          <EditAvailabilityForm item={item} onCancel={onCancel} onSave={onSave} />
        </DialogContent>
      </Dialog>
    );
  } else {
    return (
      <Dialog open maxWidth="md" fullWidth onClose={onCancel}>
        <DialogTitle>New Event</DialogTitle>
        <DialogContent>
          <DialogContentText>* indicates required fields</DialogContentText>
          <EditEventForm
            item={item}
            fixedAttendee={fixedAttendee}
            onCancel={onCancel}
            onSave={onSave}
            onNavigate={onNavigate}
          />
        </DialogContent>
      </Dialog>
    );
  }
};

export default observer(EditEventDialog);
