import gql from 'graphql-tag';

import type { SCHEDULE_CHANGE_REASONS } from 'src/shared/util/events';
import { FILE_SUMMARY } from 'src/stores/fragments/files';
import { USER_LIMITED_DISPLAY, USER_SUMMARY } from 'src/stores/fragments/users';
import type { UserLimitedDisplay, UserSummary } from 'src/stores/fragments/users';

const EVENT_LIMITED = gql`
  fragment EventLimited on Event {
    id
    title
    type
    subType
    signedByDisplay {
      ...UserLimitedDisplay
    }
    signedAt
    countersignedByDisplay {
      ...UserLimitedDisplay
    }
    countersignedAt
    recurrence {
      type
      freq
      interval
      byDay
      byMonthDay
      bySetPos
      byDayForMonth
      until
    }
    scheduleChangeReason
    createdByDisplay {
      ...UserLimitedDisplay
    }
    createdAt
    createdVia
    scheduleChangeNotes
    appointmentNotes
    start
    duration
    timezone
    status
    attendees {
      id
      firstName
      lastName
      ... on Patient {
        preferredName
      }
    }
    vc {
      sessionId
      token
      guestUrl
    }
    rescheduledTo {
      id
      type
      start
      duration
      timezone
    }
  }
  ${USER_LIMITED_DISPLAY}
`;

const EVENT_SUMMARY = gql`
  fragment EventSummary on Event {
    id
    title
    type
    subType
    isNightingale
    signedByDisplay {
      ...UserLimitedDisplay
    }
    signedAt
    countersignedByDisplay {
      ...UserLimitedDisplay
    }
    countersignedAt
    recurrence {
      type
      freq
      interval
      byDay
      byMonthDay
      bySetPos
      byDayForMonth
      until
    }
    scheduleChangeReason
    createdByDisplay {
      ...UserLimitedDisplay
    }
    createdAt
    createdVia
    scheduleChangeNotes
    appointmentNotes
    start
    duration
    timezone
    status
    attendees {
      ...UserSummary
      ... on Patient {
        preferredName
        enrollmentStatus {
          status
        }
      }
    }
    vc {
      sessionId
      token
      guestUrl
    }
    patientResults {
      ... on PatientResultsLab {
        barcode
        barcodePhoto {
          ...FileSummary
        }
        photos {
          ...FileSummary
        }
      }
    }
    eventResults {
      type
      version
      results
    }
    hasVisitNotes
    updatedAt
    updatedByDisplay {
      ...UserLimitedDisplay
    }
  }
  ${FILE_SUMMARY}
  ${USER_SUMMARY}
  ${USER_LIMITED_DISPLAY}
`;

// dates/times are always strings in the response; types provided to indicate what format to expect
type DateTimeResponse = string;
type DateOnlyResponse = string;

export interface Attendee extends UserSummary {
  __typename: string;
  preferredName?: string | null;
  enrollmentStatus?: {
    status: string | null;
  } | null;
}

interface EventSummary {
  id: string;
  title: string | null;
  type:
    | 'lab'
    | 'appointment_virtual'
    | 'appointment_inperson'
    | 'availability'
    | 'medication_adherence'
    | 'medication_count'
    | 'other'
    | null;
  subType: string | null;
  isNightingale: boolean | null;
  signedByDisplay: UserLimitedDisplay | null;
  signedAt: DateTimeResponse | null;
  countersignedByDisplay: UserLimitedDisplay | null;
  countersignedAt: DateTimeResponse | null;
  recurrence: {
    type: 'scheduled';
    freq: 'daily' | 'weekly' | 'monthly';
    interval: number | null;
    byDay: number[] | null; // strictly this is (number | null)[] | null, but we've historically assumed non-null entries
    byMonthDay: number | null;
    bySetPos: number | null;
    byDayForMonth: number | null;
    until: DateOnlyResponse | null;
  } | null;
  scheduleChangeReason: keyof typeof SCHEDULE_CHANGE_REASONS | null;
  createdByDisplay: UserLimitedDisplay | null;
  createdAt: DateTimeResponse | null;
  createdVia: string | null;
  scheduleChangeNotes: string | null;
  appointmentNotes: string | null;
  start: DateTimeResponse | null;
  duration: number | null;
  timezone: string | null;
  allDay: boolean;
  status: 'scheduled' | 'rescheduled' | 'confirmed' | 'canceled' | 'complete' | null;
  attendees: Attendee[]; // strictly this is (Attendee | null)[], but we've historically assumed non-null entries
  vc: {
    sessionId: string;
    token: string; // strictly this is nullable, but we've historically assumed it's non-null
    guestUrl: string | null;
  } | null;
  // TODO: the results fields are hideously complex. Can we avoid defining them forever?
  patientResults: any;
  eventResults: any;
  hasVisitNotes: boolean | null;
  updatedAt: DateTimeResponse | null;
  updatedByDisplay: UserLimitedDisplay | null;
}

const EVENT_ALL = gql`
  fragment EventAll on Event {
    ...EventSummary
    rescheduledTo {
      ...EventSummary
    }
  }
  ${EVENT_SUMMARY}
`;

export interface EventAll extends EventSummary {
  rescheduledTo: EventSummary | null;
}

export const EVENT_INSTANCE_LIMITED = gql`
  fragment EventInstanceLimited on Event {
    ...EventLimited
  }
  ${EVENT_LIMITED}
`;

export const EVENT_INSTANCE_ALL = gql`
  fragment EventInstanceAll on Event {
    ...EventAll
  }
  ${EVENT_ALL}
`;
