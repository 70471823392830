import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';

import {
  ControlFactory,
  createControlProps,
} from 'src/nightingale/components/ChartProperty/controls/ControlFactory';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { ConditionalContext } from 'src/nightingale/components/ConditionalContext/ConditionalContext';
import { getEmptyValueForChartProperty } from 'src/nightingale/data/ChartProperty.utils';
import useRequiredChartProperties from 'src/nightingale/requiredChartProperties/useRequiredChartProperties';
import type { ControlProps, ObjectChartProperty } from 'src/nightingale/types/types';

export type ObjectControlProps = ControlProps<ObjectChartProperty> & { interactionId?: string };

/**
 * Styles
 */
const useStyles = makeStyles({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    padding: 0,
    marginTop: 16,
  },
});

/**
 * Object Control component
 */
export const ObjectControl: React.FC<ObjectControlProps> = ({
  autoFocus,
  disabled,
  interactionId,
  label,
  name,
  onChangeValue,
  onError,
  properties,
  value,
}) => {
  const styles = useStyles();

  const { conditionalMap } = useContext(ConditionalContext);
  const requiredChartProperties = useRequiredChartProperties();

  return (
    <LabeledFormControl label={label}>
      <ol className={styles.list}>
        {properties.map((property, index) => {
          if (property.id in conditionalMap && !conditionalMap[property.id]) {
            return null;
          }

          const isRequired = requiredChartProperties.isRequired(name, property.name);

          const hasEmptyValue = requiredChartProperties.isMissing(
            { ...property, value: value?.[property.name] },
            {
              name,
              interactionId,
            },
          );

          return (
            <li className={styles.listItem} key={property.name}>
              <ControlFactory
                props={{
                  ...createControlProps(property),
                  autoFocus: autoFocus && index === 0,
                  disabled,
                  hasEmptyValue,
                  isRequired,
                  onChangeValue: newValue =>
                    onChangeValue?.({ ...value, [property.name]: newValue }),
                  onError,
                  value: value?.[property.name] ?? getEmptyValueForChartProperty(property.type),
                }}
                type={property.type}
              />
            </li>
          );
        })}
      </ol>
    </LabeledFormControl>
  );
};
