import { makeStyles } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

/** Shared table styles so the tables all look and act the same */
const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: Colors.Gray2,
    },
  },
  tableCell: {
    wordBreak: 'break-all',
  },
  tableActions: {
    display: 'block',
    marginTop: -10,
    marginBottom: -10,
  },
  tableHead: {
    padding: '4px 10px',
    textTransform: 'uppercase',
  },
});

export default useStyles;
