import React from 'react';

import { inject } from 'src/util/inject';

// Use if need to render a link with a button and want to use right click
// to open a new tab.
const RouteLink = ({
  children,
  rootStore: { generateRouteUrl },
  route,
  routeParams = [],
  newWindow,
  className,
  ...props
}: {
  children: string | React.ReactElement | React.ReactChildren;
  rootStore: {
    generateRouteUrl: (
      route: string,
      params?: { [key: string]: string },
      queryParams?: { [key: string]: string },
    ) => string;
  };
  route: string;
  routeParams: Array<{ [key: string]: string }>;
  newWindow?: boolean;
  className?: string;
} & Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>>) => (
  /* eslint-disable react/jsx-no-target-blank */
  <a
    href={generateRouteUrl(route, ...routeParams)}
    target={newWindow ? '_blank' : undefined}
    rel={newWindow ? 'noopener noreferrer' : undefined}
    className={className}
    {...props}
  >
    {children}
  </a>
  /* eslint-enable react/jsx-no-target-blank */
);

export default inject<typeof RouteLink>('rootStore')(RouteLink);
