import { UserNames } from 'src/shared/stores/resource';

export type MinimalCalendarEventData = {
  attendees: UserNames[];
  duration: number;
  start: Date;
  status: string;
  subType: string;
  type: string;
};

export const generatePopInEvent = (
  nextEvent: {
    classification: string;
    patient?: UserNames | null;
    duration: number;
    subType: string;
    type: string;
  } | null,
): MinimalCalendarEventData | null => {
  if (!nextEvent || nextEvent.classification !== 'dropIn') {
    return null;
  }

  return {
    attendees: nextEvent.patient ? [nextEvent.patient] : [],
    duration: nextEvent.duration,
    start: new Date(),
    status: 'scheduled',
    subType: nextEvent.subType,
    type: nextEvent.type,
  };
};
