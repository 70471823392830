import Typography from '@material-ui/core/Typography';
import React from 'react';

import v120Schema from 'src/components/forms/schemas/eventResult/subjectiveInitial/1.2.0';

const { ...v120Properties } = v120Schema.properties;

export default {
  ...v120Schema,
  properties: {
    chiefComplaint: {
      type: 'object',
      title: 'Chief Complaint',
      renderer: {
        component: ({ model }) => {
          const { interestedInSuboxone, interestedInAudMedications, other, otherNotes } =
            model.chiefComplaint;
          const mapToLabel = {
            undefined: 'No',
            true: 'Yes',
            false: 'No',
          };
          return (
            <Typography variant="body2">
              <span>{`Interested in starting Suboxone: ${mapToLabel[interestedInSuboxone]}`}</span>
              <br />
              <span>{`Interested in AUD medications: ${mapToLabel[interestedInAudMedications]}`}</span>
              <br />
              <span>{other && otherNotes && `Other: ${otherNotes}`}</span>
            </Typography>
          );
        },
      },
      properties: {
        interestedInSuboxone: {
          type: 'boolean',
          title: 'Interested in starting Suboxone',
        },
        interestedInAudMedications: {
          type: 'boolean',
          title: 'Interested in AUD medications',
        },
        other: {
          type: 'boolean',
          title: 'Other',
        },
        otherNotes: {
          type: 'string',
          title: 'Other Notes',
          dependsOn: 'other',
          displayOnDependent: other => other,
          changeOnDependent: (currentValue, other) => (other ? currentValue : ''),
          uniforms: {
            type: 'string',
          },
        },
      },
    },
    ...v120Properties,
  },
  required: [],
};
