import React from 'react';

import Autocomplete from 'src/components/forms/controls/autocomplete';

const ClearableSelect = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  onValueClick,
  options,
  placeholder,
  isMulti,
}) => (
  <Autocomplete
    name={name}
    value={value}
    error={errors[name] && touched[name]}
    helperText={touched[name] && errors[name]}
    onBlur={() => setFieldTouched(name)}
    onChange={newValue => setFieldValue(name, newValue)}
    onValueClick={onValueClick}
    options={options}
    getOptionLabel={option => `${option.label}`}
    getOptionValue={option => option.value}
    label={label}
    placeholder={placeholder}
    isClearable
    isMulti={isMulti}
    menuPortalTarget={document.body}
  />
);

export default ClearableSelect;
