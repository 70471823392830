import { makeStyles } from '@material-ui/core';
import { Warning as ErrorIcon } from '@material-ui/icons';
import React from 'react';

import type { VisitReason as Reason } from 'src/dropInClinic/visitReason';
import Colors from 'src/nightingale/Colors';

interface VisitReasonProps {
  reason: Reason | null;
}

export const VisitReason: React.VFC<VisitReasonProps> = ({ reason }) => {
  if (!reason) {
    return null;
  }

  const Details: React.VFC<typeof reason> = ReasonDetails[reason.reason];
  return <Details {...reason} />;
};

const ReasonDetails: {
  [K in Reason['reason']]: React.VFC<Reason & { reason: K }>;
} = {
  outOfMedication: ({ needMedications }) => {
    const classes = useStyles();
    return (
      <div>
        <div>Needs refill</div>
        <ul className={classes.medicationList}>
          {needMedications.map(med => (
            <li key={med}>{med}</li>
          ))}
        </ul>
      </div>
    );
  },
  medicationQuestion: ({ question }) => {
    const classes = useStyles();
    return (
      <div>
        <div>Question about medication</div>
        <div className={classes.patientQuestion}>{question}</div>
      </div>
    );
  },
  discussion: ({ question }) => {
    const classes = useStyles();
    return (
      <div>
        <div>Requested clinician discussion</div>
        <div className={classes.patientQuestion}>{question}</div>
      </div>
    );
  },
  receivedText: ({ sufficientBupe }) => {
    const classes = useStyles();
    return (
      <div>
        <div>Received text notification</div>
        {sufficientBupe ? null : (
          <div className={classes.patientQuestion}>
            <ErrorIcon className={classes.warningIcon} />
            Less than 2 days bup remaining
          </div>
        )}
      </div>
    );
  },
};

const useStyles = makeStyles(() => ({
  patientQuestion: {
    marginLeft: '14px',
    fontStyle: 'italic',
  },
  medicationList: {
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '28px', // 2 * patientQuestion.marginLeft to keep them aligned
    paddingLeft: '0',
  },
  warningIcon: {
    verticalAlign: 'middle',
    color: Colors.Coral,
  },
}));
