import { RequiredChartPropertyConfig, RequiredWhen } from '@boulder-package/chart-definition-types';
import isNil from 'lodash/isNil';

import { isEmptyValue, isMarkedAsNone } from 'src/nightingale/data/ChartProperty.utils';
import {
  AnyChartProperty,
  ChartPropertyValue,
  ChartPropertyWithValue,
  RequiredChartPropertiesConfig,
} from 'src/nightingale/types/types';

/** Encapsulates logic related to required fields */
export default class RequiredChartProperties {
  /**
   * The names of properties that have values for _this_ interaction
   *
   * We need to examine it e.g. when deciding if a required-always field has been filled in
   * this time around or if it was from a different interaction.
   *
   * This matters because we might have a situation where we saved a value for this interaction,
   * but then another interaction came along and changed it again. If we're just doing a naïve
   * check on interactionId, it would look like we don't have one for this interaction. See CHX-1117.
   */
  private readonly propertiesWithValuesForThisInteraction: Set<string>;

  /**
   * Initializes an instance of this API
   *
   * @param {RequiredChartPropertiesConfig} requiredChartPropertiesConfig The config from Dato defining the
   * required fields for this part of the chart
   * @param {string} [chartInteractionId] The interaction ID of the chart the fields appear on. If not provided,
   * will assume required-always fields are missing for this interaction.
   * @param {ChartPropertyWithValue[]} [interactionValues] The values set in _this_ interaction. If not provided,
   * will assume all required-always fields are missing for this interaction.
   * @memberof RequiredChartProperties
   */
  constructor(
    private readonly requiredChartPropertiesConfig: RequiredChartPropertiesConfig,
    /**
     * The interaction ID of the chart the fields appear on
     *
     * We need to examine it e.g. when deciding if a required-always field has been filled in
     * this time around or if it was from a different interaction.
     */
    private readonly chartInteractionId?: string,
    interactionValues?: ChartPropertyWithValue[],
  ) {
    this.propertiesWithValuesForThisInteraction = new Set(
      interactionValues?.map(item => item.name),
    );
  }

  /**
   * Checks if the named chart property (or its child) is required
   *
   * For top-level chart properties, just provide `name`. If it's a List or Object chart property
   * and we want to check for requiredness of a child of it, provide `name` for the parent and
   * `childName` for the child.
   *
   * @param {string} name The chart property name or parent chart property name of a child
   * @param {string} [childName] If it's a child chart property we're interested in, its name
   */
  isRequired(name: string, childName?: string): boolean {
    return this.requiredChartPropertiesConfig.some(config =>
      childName
        ? config.name === name && config.child === childName
        : config.name === name && (isNil(config.child) || config.child === ''),
    );
  }

  /**
   * Checks if the given chart property (with value) is missing a required value
   *
   * Consults required chart properties, the type of requirements (always vs. once),
   * and the chart vs. CPV's interaction. Marking as none doesn't count as empty.
   *
   * Some awkwardness is revealed in the types here: `AnyChartProperty` has its genesis
   * in the chart definitions, rather than with the stored CPVs (type `ChartPropertyValue`).
   * However, in the places where we use this method, we are working with chart definitions
   * that have been augmented with properties from their corresponding latest CPV for the
   * patient. Hence the need to pick the `value` from `ChartPropertyValue`.
   *
   * Note also that if this is used with child chart properties, you need to ensure `property`
   * has been augmented with `value`, since children don't get those usually.
   *
   * @param property The chart property (with value) to check
   * @param [parentProperty] If `property` is a child, the parent of it
   */
  isMissing(
    property: Pick<AnyChartProperty, 'interactionId' | 'isEmpty' | 'name'> &
      Pick<ChartPropertyValue, 'value'>,
    parentProperty?: Pick<AnyChartProperty, 'interactionId' | 'name'>,
  ): boolean {
    let requiredChartProperty: RequiredChartPropertyConfig | undefined;
    let interactionId: string | undefined;
    let propertyToCheck: Pick<AnyChartProperty, 'isEmpty' | 'value'>;

    if (parentProperty) {
      requiredChartProperty = this.requiredChartPropertiesConfig.find(
        c => c.name === parentProperty.name && c.child === property.name,
      );
      interactionId = parentProperty.interactionId;
      // `isEmpty` is always false because there's no mark-as-none for children
      propertyToCheck = { ...property, isEmpty: false };
    } else {
      requiredChartProperty = this.requiredChartPropertiesConfig.find(
        c => c.name === property.name && (isNil(c.child) || c.child === ''),
      );
      interactionId = property.interactionId;
      propertyToCheck = property;
    }

    if (!requiredChartProperty) {
      // This property isn't even required
      return false;
    }

    if (
      requiredChartProperty.requiredWhen === RequiredWhen.Always &&
      this.chartInteractionId !== interactionId &&
      !this.isValueSavedForThisInteraction(parentProperty ?? property)
    ) {
      // We always need to get a value, and the value we have is from a different interaction.
      return true;
    }

    return !isMarkedAsNone(propertyToCheck) && isEmptyValue(propertyToCheck.value);
  }

  /** Have we saved a value for this chart property for this interaction? */
  private isValueSavedForThisInteraction(property: Pick<AnyChartProperty, 'name'>) {
    return this.propertiesWithValuesForThisInteraction.has(property.name);
  }
}
