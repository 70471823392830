import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';

import { TimeSlot } from 'src/scheduling/types';

const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isSlotAfterCutoff =
  (patientTimezone: string, cutoffTimeString: string | null) => (slot: Pick<TimeSlot, 'start'>) => {
    if (!cutoffTimeString) {
      return true;
    }

    // At this point the value in slot.start is a Date object in the TZ of the
    // browser; we convert from browser TZ to UTC then from UTC to pt TZ
    const slotTime = utcToZonedTime(zonedTimeToUtc(slot.start, browserTz), patientTimezone);
    const slotDate = format(slotTime, 'yyyy-MM-dd');

    const paddedCutoffTimeString =
      cutoffTimeString.length < 5 ? `0${cutoffTimeString}` : cutoffTimeString;

    // Note the absence of a `Z` on the ISO string -- we don't want any timezone
    // adjustment applied to this string; if you entered "2:00 PM" in the time
    // field that meant 2 PM in pt's timezone -- no need to adjust it here
    const slotCutoffString = `${slotDate}T${paddedCutoffTimeString}`;
    const slotCutoff = parseISO(slotCutoffString);

    if (!isValid(slotCutoff)) {
      console.warn(`Invalid cut-off time for scheduler (${slotCutoffString})`);
      return true;
    }

    // Even though they will appear local because of JavaScript's Date...
    // situation slotCutoff and slotTime now both express times in the pt's TZ
    return isEqual(slotCutoff, slotTime) || isAfter(slotTime, slotCutoff);
  };
