import { TIMEZONES, TIMEZONE_LABELS } from 'src/util/timezones';

export default {
  type: 'object',
  title: 'Onboarding: RCV Setup Details',
  disabled: true,
  properties: {
    referrerName: { type: 'string' },
    referrerTitle: { type: 'string' },
    referrerPractice: { type: 'string' },
    referrerPhoneNumber: { type: 'string', format: 'phone' },
    referrerEmail: { type: 'string', format: 'email' },
    rcvDate: { type: 'string', format: 'date', title: 'RCV Date' },
    rcvTime: {
      type: 'string',
      title: 'RCV Time (e.g. 12:00PM)',
    },
    rcvTimezone: {
      type: 'string',
      options: TIMEZONES,
      title: 'RCV Timezone',
      renderer: {
        component: ({ model }) => TIMEZONE_LABELS[model.rcvTimezone],
      },
    },
    rcvVisitScheduledOnCliniciansCalendar: {
      type: 'boolean',
      title: `RCV visit scheduled on Clinician's calendar`,
    },
    rcvProcessReviewedWithReferrer: {
      type: 'boolean',
      title: 'RCV process reviewed with Referrer',
    },
    rcvVideoLinkEmailedToReferrer: { type: 'boolean', title: 'RCV video link emailed to Referrer' },
    notes: { type: 'string', uniforms: { multiline: true } },
  },
  required: [],
};
