import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FeedbackIcon from '@material-ui/icons/Feedback';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Styles } from '@material-ui/styles';
import { differenceInMilliseconds, format, isPast, isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import gql from 'graphql-tag';
import * as humanizeDuration from 'humanize-duration';
import upperFirst from 'lodash/upperFirst';
import { inject, observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import useSWR from 'swr';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import {
  ENROLLMENT_STATUS_OPTIONS,
  CARE_PLAN_OPTIONS,
} from 'src/components/forms/schemas/definitions';
import Tooltip from 'src/components/general/Tooltip';
import SortableList, { ListBodyRow, ListHeadRow } from 'src/components/general/sortableList';
import DailyAffirmation from 'src/components/pages/pageElements/dailyAffirmation';
import PatientsListFilters from 'src/components/pages/pageElements/patientsListFilters';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import type { Patient } from 'src/shared/stores/resource';
import { SCHEDULE_CHANGE_REASONS, isAllDay } from 'src/shared/util/events';
import type { EventInstance } from 'src/stores/models/event';
import type { RootStore } from 'src/stores/root';
import { colors } from 'src/util/colors';
import { parseUnknownDate } from 'src/util/parseUnknownDate';
import { getReadableUserList } from 'src/util/users';

const EMPTY_CELL_WIDTH = 30;
const CARE_TEAM_WIDTH = {
  // Tentatively reasonable settings - adjust as we learn more
  min: 110,
};
const CARE_PLAN_WIDTH = {
  // Exactly as wide as needed to show the longest word, "Bi-annually", and the plan type (OFT) for care advocate care plans
  exact: 110,
};
const TIME_AGO_CELL_WIDTH = {
  // Exactly as wide as needed to show the usual phrase, "X months ago"
  min: 88,
};

const CARE_PLAN_DISPLAY_NAMES = {
  ...CARE_PLAN_OPTIONS,
  bi_annually: 'Bi-annually',
  annually: 'Annually',
  n_a: 'N/A',
  other: 'Other',
  // One word instead of multiple to be concise
  declined_care: 'Declined',
};

type HistoryActivityTableCellProps = {
  /**
   * CSS classes that can be used within the cell
   */
  classes: Record<string, string>;

  /**
   * The activity to represent in this cell
   */
  activity: EventInstance;

  /**
   * True when the activity is the latest missed visit
   */
  isLastMissedActivity?: boolean;
};

const HistoryActivityTableCell: FunctionComponent<HistoryActivityTableCellProps> = ({
  classes,
  activity,
  isLastMissedActivity,
}) => {
  if (!activity) {
    return <DashedCell classes={classes} />;
  }

  const firstLine = isLastMissedActivity
    ? upperFirst(activity.status ?? undefined)
    : getTimeSinceOrUntil(activity.start);

  let secondLine: string | null = null;
  if (activity.start && isValid(activity.start)) {
    secondLine = isAllDay(activity.type)
      ? formatInTimeZone(activity.start, 'UTC', 'MMM d')
      : format(activity.start, 'MMM d');
  }

  const { scheduleChangeReason, scheduleChangeNotes } = activity;

  let tooltipText;
  if (scheduleChangeReason) {
    tooltipText = SCHEDULE_CHANGE_REASONS[scheduleChangeReason].label;
  }
  if (scheduleChangeNotes) {
    tooltipText += `: ${scheduleChangeNotes}`;
  }

  return (
    <div className={classes.cellContainer}>
      {isLastMissedActivity && (
        <Tooltip title={tooltipText} className={classes.infoTooltip}>
          <InfoIcon color="action" />
        </Tooltip>
      )}
      <div>
        <Typography variant="body2">{firstLine}</Typography>
        <Typography variant="body2" className={classes.secondaryActivityText}>
          {secondLine}
        </Typography>
      </div>
    </div>
  );
};

const DashedCell = ({ classes }) => <span className={classes.secondaryActivityText}>&mdash;</span>;

type PatientsPageProps = {
  rootStore: RootStore;
};

class Patients extends React.Component<PatientsPageProps & { classes: Record<string, string> }> {
  static contextType = FeatureFlagContext;

  handleDelete = item => {
    this.props.rootStore.patients.deletePatient(item);
  };

  handleFiltersChange = newValue => {
    const filters = Object.keys(newValue).reduce((result, key) => {
      // eslint-disable-next-line no-param-reassign
      result[key] = newValue[key]?.id || newValue[key]?.value;
      return result;
    }, {});

    this.updateQueryParams({
      ...filters,
      page: undefined,
    });
  };

  handleRequestSort = (orderBy, order) => {
    this.updateQueryParams({
      orderBy,
      order,
    });
  };

  handleChangePage = page => {
    this.updateQueryParams({
      page,
    });
  };

  handleChangeRowsPerPage = rowsPerPage => {
    this.updateQueryParams({
      rowsPerPage: rowsPerPage.toString(),
      page: undefined,
    });
  };

  updateQueryParams = queryParamsChanges => {
    this.props.rootStore.routerStore.goTo(this.props.rootStore.routerStore.routerState.routeName, {
      params: this.props.rootStore.routerStore.routerState.params,
      queryParams: {
        ...this.props.rootStore.routerStore.routerState.queryParams,
        ...queryParamsChanges,
      },
    });
  };

  render() {
    const {
      classes,
      rootStore: {
        patients: { list },
        routerStore: {
          routerState: { queryParams },
        },
      },
    } = this.props;

    const careTeamRow = (key, provider) => (
      <div className={classes.careTeamRow}>
        <Typography variant="body2" className={classes.careTeamLabel}>
          {key}:
        </Typography>
        <Typography variant="body2" className={classes.careTeamName}>
          {provider?.exactFullName || 'unassigned'}
        </Typography>
      </div>
    );

    const EMPTY_CELL = {
      noCellBorder: true,
      width: { exact: EMPTY_CELL_WIDTH },
    };

    function getColumnHeadings(): ListHeadRow[] {
      return [
        {
          key: 'group-column-headings',
          fields: [
            {
              key: 'overview',
              label: <Typography variant="h6">Overview</Typography>,
              colSpan: 7,
              noCellBorder: true,
              underline: true,
            },
            { ...EMPTY_CELL, key: 'Empty Grouped Overview Heading' },
            {
              key: 'activity',
              label: <Typography variant="h6">Activity</Typography>,
              colSpan: 4,
              noCellBorder: true,
              underline: true,
            },
          ],
        },
        {
          key: 'column-headings',
          fields: [
            {
              label: 'Name',
              sortField: 'lastName',
            },
            {
              label: 'Location',
              sortField: 'homeAddress_state',
            },
            {
              label: 'FYI',
              sortField: 'fyi',
            },
            {
              key: 'Enrollment Status',
              label: (
                <>
                  Enrollment
                  <br />
                  Status
                </>
              ),
            },
            {
              key: 'Last Activity',
              label: (
                <>
                  Last
                  <br />
                  Activity
                </>
              ),
            },
            {
              key: 'Care Team',
              label: (
                <>
                  Care
                  <br />
                  Team
                </>
              ),
            },
            {
              key: 'Care Plan',
              label: (
                <>
                  Care
                  <br />
                  Plan
                </>
              ),
            },
            { ...EMPTY_CELL, key: 'Empty Overview Heading', noCellBorder: false },
            {
              key: 'Last Completed Visit',
              label: (
                <>
                  Last Completed
                  <br />
                  Visit
                </>
              ),
            },
            {
              key: 'Last Missed Visit',
              label: (
                <>
                  Last Missed
                  <br />
                  Visit
                </>
              ),
            },
            {
              key: 'Next Visit',
              label: (
                <>
                  Next
                  <br />
                  Visit
                </>
              ),
            },
          ],
        },
      ];
    }

    function getRowDetails(): Array<ListBodyRow<Patient>> {
      const rowDetails: Array<ListBodyRow<Patient>> = [
        {
          key: 'details',
          fields: [
            {
              label: 'Name',
              routeKey: 'patientOverview',
              rowSpan: 3,
              alignContentsTop: true,
              value: ({ item }) =>
                item.exactFullName ? (
                  <div>
                    {item.preferredName ? (
                      <>
                        <Typography variant="body2">{item.preferredName}</Typography>
                        <Typography variant="body2">[{item.firstName}]</Typography>
                      </>
                    ) : (
                      <Typography variant="body2">{item.firstName}</Typography>
                    )}
                    <Typography variant="body2">{item.lastName}</Typography>
                  </div>
                ) : null,
            },
            {
              label: 'Location',
              routeKey: 'patientOverview',
              rowSpan: 3,
              alignContentsTop: true,
              value: ({ item }) => {
                if (item.homeAddress) {
                  const { city, state } = item.homeAddress;
                  const location =
                    (state && city && (
                      <>
                        {city}, <br /> {state}
                      </>
                    )) ||
                    (state && `${state}`) ||
                    (city && `${city}`);
                  return <Typography variant="body2">{location}</Typography>;
                } else {
                  return null;
                }
              },
            },
            {
              label: 'FYI',
              routeKey: 'patientOverview',
              rowSpan: 3,
              alignContentsTop: true,
              width: {
                // "FYI" text + sorting arrow is 48px wide exactly, padding makes up for 20px
                // and this column always only contains an icon, so choosing the exact width.
                exact: 28,
              },
              value: ({ item }) =>
                item.fyi ? (
                  <Tooltip title={item.fyi}>
                    <FeedbackIcon color="action" />
                  </Tooltip>
                ) : null,
            },
            {
              label: 'Enrollment Status',
              routeKey: 'patientOverview',
              rowSpan: 3,
              alignContentsTop: true,
              width: {
                // Smallest width with our current options that doesn't break up a word
                exact: 84,
              },
              value: ({ item }) => ENROLLMENT_STATUS_OPTIONS[item.enrollmentStatus?.status] || null,
            },
            {
              label: 'Last Activity',
              routeKey: 'patientOverview',
              alignContentsTop: true,
              rowSpan: 3,
              width: {
                exact: 90,
              },
              value: ({ item }) => {
                return <LastActivity classes={classes} patientId={item.id} />;
              },
            },
            {
              label: 'Clinician',
              routeKey: 'patientOverview',
              noCellBorder: true,
              width: CARE_TEAM_WIDTH,
              alignContentsTop: true,
              value: ({ item }) => careTeamRow('CL', item.prescriber),
            },
            {
              label: 'Clinician Care Plan',
              routeKey: 'patientOverview',
              noCellBorder: true,
              width: CARE_PLAN_WIDTH,
              alignContentsTop: true,
              value: ({ item }) => {
                if (!item.clinicalCarePlan) return <DashedCell classes={classes} />;
                const { plan } = item.clinicalCarePlan;
                return plan ? (
                  CARE_PLAN_DISPLAY_NAMES[plan] || plan
                ) : (
                  <DashedCell classes={classes} />
                );
              },
            },
            {
              key: 'Empty Overview',
              rowSpan: 3,
              width: { exact: EMPTY_CELL_WIDTH },
              value: () => null,
            },
          ],
        },
        {
          key: 'CA',
          fields: [
            {
              label: 'Care Advocate',
              routeKey: 'patientOverview',
              noCellBorder: true,
              width: CARE_TEAM_WIDTH,
              alignContentsTop: true,
              value: ({ item }) => careTeamRow('CA', item.coordinator),
            },
            {
              label: 'Care Advocate Care Plan',
              routeKey: 'patientOverview',
              noCellBorder: true,
              width: CARE_PLAN_WIDTH,
              alignContentsTop: true,
              value: ({ item: { careAdvocateCarePlan } }) =>
                careAdvocateCarePlan?.oftPlan ? (
                  <InlinePlanDetail
                    classes={classes}
                    detail={
                      CARE_PLAN_DISPLAY_NAMES[careAdvocateCarePlan.oftPlan] ||
                      careAdvocateCarePlan.oftPlan
                    }
                    planName="OFT"
                  />
                ) : (
                  <DashedCell classes={classes} />
                ),
            },
          ],
        },
        {
          key: 'PC',
          fields: [
            {
              label: 'Peer Coach',
              routeKey: 'patientOverview',
              width: CARE_TEAM_WIDTH,
              value: ({ item }) => careTeamRow('PC', item.peer),
            },
            {
              label: 'Peer Coach Care Plan',
              routeKey: 'patientOverview',
              width: CARE_PLAN_WIDTH,
              value: ({ item }) => {
                if (!item.peerCoachCarePlan) return <DashedCell classes={classes} />;
                const { plan } = item.peerCoachCarePlan;
                return plan ? (
                  CARE_PLAN_DISPLAY_NAMES[plan] || plan
                ) : (
                  <DashedCell classes={classes} />
                );
              },
            },
          ],
        },
      ];

      ['prescriberActivity', 'coordinatorActivity', 'peerActivity'].forEach(
        (teamMemberActivity, index) => {
          rowDetails[index].fields.push(
            {
              label: 'Last Completed Visit',
              routeKey: 'patientHistory',
              noCellBorder: teamMemberActivity !== 'peerActivity',
              width: TIME_AGO_CELL_WIDTH,
              alignContentsTop: teamMemberActivity === 'prescriberActivity',
              value: ({ item }) => {
                return (
                  <HistoryActivityTableCell
                    activity={item[teamMemberActivity]?.latestCompletedVisit}
                    classes={classes}
                  />
                );
              },
            },
            {
              label: 'Last Missed Visit',
              routeKey: 'patientHistory',
              noCellBorder: teamMemberActivity !== 'peerActivity',
              width: {
                // Exactly as much needed for tooltip icon + "Rescheduled" text
                min: 105,
              },
              alignContentsTop: teamMemberActivity === 'prescriberActivity',
              value: ({ item }) => {
                return (
                  <HistoryActivityTableCell
                    activity={item[teamMemberActivity]?.latestMissedVisit}
                    isLastMissedActivity
                    classes={classes}
                  />
                );
              },
            },
            {
              label: 'Next Visit',
              routeKey: 'patientHistory',
              noCellBorder: teamMemberActivity !== 'peerActivity',
              width: TIME_AGO_CELL_WIDTH,
              alignContentsTop: teamMemberActivity === 'prescriberActivity',
              value: ({ item }) => {
                return (
                  <HistoryActivityTableCell
                    activity={item[teamMemberActivity]?.nextVisit}
                    classes={classes}
                  />
                );
              },
            },
          );
        },
      );

      return rowDetails;
    }

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <TitleBar
            title="Patients"
            actionTooltip="Add Patient"
            routeName="createPatient"
            data-testid="add-patient"
          />
          <DailyAffirmation />
        </div>
        <div className={classes.filtersContainer}>
          <PatientsListFilters
            queryParams={queryParams}
            handleFiltersChange={this.handleFiltersChange}
            rootStore={this.props.rootStore}
          />
        </div>

        <SortableList
          resourceList={list}
          routes={{
            patientOverview: {
              routeName: 'showPatient',
              getRouteParams: (item: Patient) => ({ id: item.id }),
            },
            patientHistory: {
              routeName: 'showPatient',
              getRouteParams: (item: Patient) => ({ id: item.id, tab: 'activity' }),
            },
            patientChat: {
              routeName: 'showPatient',
              getRouteParams: (item: Patient) => ({ id: item.id, tab: 'conversation' }),
            },
          }}
          tableHeadRows={getColumnHeadings()}
          tableBodyRows={getRowDetails()}
          handleRequestSort={this.handleRequestSort}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

const MILISECONDS_IN_A_MONTH = 1000 * 60 * 60 * 24 * 30;

const getTimeSinceOrUntil = (start: Date | string | null) => {
  const startDate = parseUnknownDate(start);
  const isInThePast = isPast(startDate);
  const timeSinceOrUntil = isInThePast
    ? differenceInMilliseconds(new Date(), startDate)
    : differenceInMilliseconds(startDate, new Date());

  // If time until next visit is in days, hours, or minutes, only show single units by
  // checking if it's been less than a month. Otherwise, display two units.
  const largest = timeSinceOrUntil < MILISECONDS_IN_A_MONTH ? 1 : 2;
  const humanizedTime = `${humanizeDuration(timeSinceOrUntil, {
    largest,
    units: ['y', 'mo', 'd', 'h', 'm'],
    round: true,
  })}`;

  let timeSinceString;
  if (isInThePast) {
    timeSinceString = `${humanizedTime} ago`;
  } else {
    timeSinceString = `in ${humanizedTime}`;
  }
  const timeArray = timeSinceString.split(', ');

  return (
    (timeArray.length === 2 && (
      <>
        {timeArray[0]},
        <br />
        {timeArray[1]}
      </>
    )) || <>{timeArray[0]}</>
  );
};

const getActivityDescription = (patientId, latestActivity) => {
  const { __typename, message, event } = latestActivity;
  let otherUsers = '';
  let userArray = [];

  switch (__typename) {
    case 'ReadMessageActivity':
      return `Read message from ${message?.from.firstName} ${message?.from.lastName}`;
    case 'SentMessageActivity':
      userArray = message?.conversation.users.map(({ user }) => user);
      otherUsers = getReadableUserList(userArray, { excludedIds: [patientId] });
      return `Sent message to ${otherUsers}`;
    case 'EventActivity':
      otherUsers = getReadableUserList(event?.attendees, { excludedIds: [patientId] });
      return `Attended visit with ${otherUsers}`;
    default:
      return null;
  }
};

const InlinePlanDetail = ({ classes, detail, planName }) => {
  return (
    <div className={classes.inlinePlanContainer}>
      <Typography variant="body2" display="inline">
        {detail}
      </Typography>
      <Typography variant="caption" display="inline" className={classes.careAdvocateCarePlanType}>
        {planName}
      </Typography>
    </div>
  );
};

const LastActivity = ({ classes, patientId }) => {
  const [showLoadButton, setShowLoadButton] = React.useState(true);

  const { data } = useSWR<PatientLatestActivityResponse>(
    !showLoadButton ? [PATIENT_LATEST_ACTIVITY, { id: patientId }] : null,
  );
  const latestActivity = data?.patientLatestActivity;

  const onClick = async e => {
    // stop the event propagation to prevent a redirect to the overview
    // @see {@link https://stackoverflow.com/a/28840550}
    e.stopPropagation();
    e.preventDefault();

    setShowLoadButton(false);
  };

  if (showLoadButton) {
    return (
      <Button
        onClick={e => onClick(e)}
        color="primary"
        variant="outlined"
        className={classes.lastActivityButton}
      >
        Click to load
      </Button>
    );
  } else if (latestActivity === undefined) {
    return <CircularProgress />;
  } else if (latestActivity) {
    const timeSince = getTimeSinceOrUntil(latestActivity.occurredAt);
    const activityDescription = getActivityDescription(patientId, latestActivity);
    return (
      <div>
        <Typography variant="body2">{timeSince}</Typography>
        <Typography variant="body2" className={classes.secondaryActivityText}>
          {activityDescription}
        </Typography>
      </div>
    );
  } else {
    return <Typography variant="body2">No Activity</Typography>;
  }
};

const PATIENT_LATEST_ACTIVITY = gql`
  query PatientLatestActivity($id: ID!) {
    patientLatestActivity(id: $id) {
      occurredAt
      ... on ReadMessageActivity {
        message {
          from {
            firstName
            lastName
          }
        }
      }
      ... on SentMessageActivity {
        message {
          conversation {
            users {
              user {
                firstName
                lastName
              }
            }
          }
        }
      }
      ... on EventActivity {
        event {
          start
          attendees {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

type PatientLatestActivityResponse = {
  patientLatestActivity: PatientLatestActivity;
};

type PatientLatestActivity = {
  occurredAt: string | null;
};

const styles: Styles<Theme, any> = theme => ({
  title: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'space-between',
  },
  filtersContainer: {
    paddingBottom: 25,
  },
  separateLines: {
    // Must be used within a display: block element
    display: 'table-caption',
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  infoTooltip: {
    fontSize: 16,
    marginRight: 5,
  },
  secondaryActivityText: {
    fontSize: 12,
    color: colors.darkGray,
  },
  secondaryActivityTextUnread: {
    fontSize: 12,
    fontWeight: 700,
    color: colors.warningHover,
  },
  root: {
    padding: theme.spacing(3),
  },
  team: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  careTeamRow: {
    display: 'flex',
  },
  careTeamLabel: {
    fontStyle: 'italic',
    wordBreak: 'keep-all',
  },
  careTeamName: {
    paddingLeft: 3,
  },
  careAdvocateCarePlanType: {
    fontStyle: 'italic',
    color: colors.darkGray,
    marginLeft: 4,
  },
  inlinePlanContainer: {
    whiteSpace: 'nowrap',
  },
  lastActivityButton: {
    height: 50, // prevents clickableCellContents styling in sortableList from stretching the button
    textTransform: 'none',
  },
});

export default withStyles(styles)(inject('rootStore')(observer(Patients)));
