import gql from 'graphql-tag';

export const MESSAGE_ALL = gql`
  fragment MessageAll on Message {
    id
    createdAt
    from {
      id
      firstName
      lastName
      ... on Patient {
        preferredName
      }
      ... on Provider {
        patientFacingDisplayName
      }
    }
    ... on TextMessage {
      text
      displayMode
    }
    ... on VideoConferenceMessage {
      vc {
        sessionId
        token
      }
    }
  }
`;
