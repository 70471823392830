import { Badge, makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { ClassNameMap } from '@material-ui/styles';
import React from 'react';

import { ReactComponent as RequiredBadge } from 'src/nightingale/assets/required_badge.svg';
import { LabelType } from 'src/nightingale/types/types';

/**
 * The icon to display by the chart element label based on the type (interaction vs patient).
 */
const Icon: React.FC<{
  labelType?: LabelType;
  styles: ClassNameMap;
}> = ({ labelType = 'label', styles }) => {
  if (labelType.includes('patient')) {
    return (
      <AssignmentIndIcon className={styles.headerIcon} data-testid="patient-chart-element-icon" />
    );
  } else if (labelType.includes('interaction')) {
    return (
      <EventNoteIcon className={styles.headerIcon} data-testid="interaction-chart-element-icon" />
    );
  } else {
    return null;
  }
};

/**
 * The icon to display by the chart element label with the option to display an asterisk alerting the user of
 * missing required chart properties.
 */
const LabelIcon: React.FC<{
  hasMissingRequiredFields?: boolean;
  labelType: LabelType;
}> = ({ hasMissingRequiredFields, labelType }) => {
  const styles = useStyles();

  if (!labelType.includes('with-icon')) {
    return null;
  }

  if (hasMissingRequiredFields) {
    return (
      <Badge
        badgeContent={
          <SvgIcon className={styles.icon} component={RequiredBadge} fontSize="small" />
        }
        classes={{ anchorOriginTopRightRectangle: styles.badge }}
        data-testid="label-icon-required-badge"
      >
        <Icon labelType={labelType} styles={styles} />
      </Badge>
    );
  }

  return <Icon labelType={labelType} styles={styles} />;
};

const useStyles = makeStyles({
  badge: {
    right: 3,
    top: 2,
  },
  headerIcon: {
    display: 'inline-block',
    height: 19,
    marginRight: 5,
    position: 'relative',
    top: -2,
    verticalAlign: 'top',
    width: 19,
    '&, :hover &&': {
      opacity: 1,
    },
  },
  icon: {
    '&, :hover &&': {
      opacity: 1,
    },
  },
});

export default LabelIcon;
