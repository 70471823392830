import gql from 'graphql-tag';

import { Priority } from 'src/scheduling/types/TimeSlot';

export const GET_AVAILABLE_TIME_SLOTS = gql`
  query GetAvailableTimeSlots(
    $searchWindow: SearchWindowInput!
    $credentialCriteria: CredentialCriteria!
    $selectedProviderIds: [ID!]!
    $subType: String!
    $duration: Int!
  ) {
    getAvailableTimeSlots(
      searchWindow: $searchWindow
      credentialCriteria: $credentialCriteria
      selectedProviderIds: $selectedProviderIds
      subType: $subType
      duration: $duration
    ) {
      start
      duration
      provider {
        firstName
        lastName
        providerId
        restrictions
      }
      priority
    }
  }
`;

export type QueryResult = {
  getAvailableTimeSlots: Array<{
    /**
     * ISO date string when the slot starts
     */
    start: string;

    /**
     * The length of the slot in minutes
     */
    duration: number;

    provider: {
      firstName?: string;
      lastName?: string;
      providerId: string;
      restrictions?: string;
    };
    priority?: Priority;
  }>;
};
