import React, { useState } from 'react';

/**
 * Context for tracking when the last known update to the patient's chart was made. Doesn't need to
 * differentiate patients from one another since we only view one patient's chart view(s) at a time.
 *
 * The value isn't important; this serves more like an event emitter that components can track to
 * update themselves. A Date is just something that's easily updated with a new unique value.
 */
export const ChartUpdateContext = React.createContext<{
  lastUpdate?: Date;
  updateLastUpdate?: () => void;
}>({
  lastUpdate: undefined,
  updateLastUpdate: undefined,
});

export const ChartUpdateContextProvider: React.FC = ({ children }) => {
  const [lastUpdate, setLastUpdate] = useState(new Date());
  return (
    <ChartUpdateContext.Provider
      value={{
        lastUpdate,
        updateLastUpdate: () => {
          setLastUpdate(new Date());
        },
      }}
    >
      {children}
    </ChartUpdateContext.Provider>
  );
};
