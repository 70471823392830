import Button from '@material-ui/core/Button';
import type { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

import Colors from 'src/nightingale/Colors';

export const SecondaryButton: React.FC<
  Pick<ButtonProps, 'children' | 'className' | 'disabled' | 'onClick'>
> = ({ children, className, disabled, onClick, ...rest }) => {
  const styles = useButtonStyles();
  return (
    <Button
      classes={{ root: classNames(styles.iconButton, className) }}
      data-testid="action-button"
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

const useButtonStyles = makeStyles({
  iconButton: {
    alignItems: 'center',
    backgroundColor: Colors.White,
    border: 'none',
    borderRadius: 0,
    color: Colors.BlueSpruce,
    cursor: 'pointer',
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    margin: 0,
    padding: '8px 16px',
    textTransform: 'uppercase',

    '&:hover': {
      backgroundColor: Colors.Mist,
    },
  },
});
