import { Theme, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Styles } from '@material-ui/core/styles/withStyles';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ChatIcon from '@material-ui/icons/Chat';
import Check from '@material-ui/icons/Check';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { Formik } from 'formik';
import debounce from 'lodash/debounce';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { useState, FunctionComponent } from 'react';

import TextControl from 'src/components/forms/controls/text';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import Tooltip from 'src/components/general/Tooltip';
import RouteLink from 'src/components/general/routeLink';
import ClaimRowDetail from 'src/components/pages/pageElements/ClaimRowDetail';
import ClaimStatusIcon from 'src/components/pages/pageElements/ClaimStatusIcon';
import ClaimsListFilters from 'src/components/pages/pageElements/claimsListFilters';
import TitleBar from 'src/components/pages/pageElements/titleBar';
import { ApiError, ClaimsStoreInstance } from 'src/stores/claims';
import { ClaimType, TableRowData } from 'src/util/claims';
import { colors } from 'src/util/colors';

type ClaimsPageProps = {
  classes: {
    actionError: string;
    actionResponse: string;
    actionSuccess: string;
    detailPanel: string;
    expandedRowButtons: string;
    filtersContainer: string;
    leftColumn: string;
    rightColumn: string;
    root: string;
    status: string;
    svgButtonGroup: string;
  };
  rootStore: {
    claims: ClaimsStoreInstance;
    routerStore: any;
  };
};

const DECEASED_PARTICIPANT_TOOLTIP = 'Cannot submit claim for deceased participant';
const UNKNOWN_STATUS = 'unknown';

const Claims: FunctionComponent<ClaimsPageProps> = ({
  classes,
  rootStore,
  rootStore: {
    claims,
    claims: {
      list,
      regenerateClaimById,
      submitClaim,
      translateClaim,
      // observing apiErrors seems to be finicky, and this is required to get that working
      // properly, even though we don't directly use the param.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      apiErrors,
    },
    routerStore,
    routerStore: { routerState },
  },
}) => {
  let queryParamsChangeBatch = {};
  const [isGenerated, setIsGenerated] = useState({});
  const debouncedQueryParamsChange = debounce(() => {
    updateQueryParams(queryParamsChangeBatch);
    queryParamsChangeBatch = {};
  }, 0);

  const classForStatus = (status: string): string => classes[`status-${status}`];

  return (
    <div className={classes.root}>
      <TitleBar title="Claims" />
      <div className={classes.filtersContainer}>
        <ClaimsListFilters
          queryParams={routerState.queryParams}
          handleFiltersChange={handleFiltersChange}
          rootStore={rootStore}
        />
      </div>
      <MaterialTable<TableRowData>
        columns={[
          {
            title: 'Visit date',
            field: 'date',
            render: rowData => <>{rowData.date ? format(parseISO(rowData.date), 'PP') : ''}</>,
          },
          {
            title: 'CPT Codes',
            field: 'cptCodes',
            render: rowData =>
              rowData.cptCodes?.length > 0 && <>CPT: {rowData.cptCodes.join(', ')}</>,
          },
          {
            title: 'Participant Name',
            field: 'name',
          },
          {
            title: 'State',
            field: 'state',
          },
          {
            title: 'Payor',
            field: 'payor',
          },
          {
            title: 'Type',
            field: 'type',
            render: rowData => {
              switch (rowData.type) {
                case ClaimType.Visit:
                  return 'Visit';
                case ClaimType.Messaging:
                  return 'Messaging';
                default:
                  return null;
              }
            },
          },
          {
            title: 'Claim Status',
            field: 'status',
            render: rowData => (
              <div className={`${classes.status} ${classForStatus(rowData.status)}`}>
                <ClaimStatusIcon status={rowData.status} /> {rowData.statusDisplay}
              </div>
            ),
          },
        ]}
        data={list.materialTableDataFormat}
        page={list.page}
        totalCount={Infinity}
        detailPanel={rowData => {
          let validatedForSubmission = true;
          const isLegacyClaim = rowData.status === UNKNOWN_STATUS;
          if (isLegacyClaim) {
            if (!isGenerated[rowData.claim.id]) {
              // Not yet validated for insurance or other data issues; don't show
              validatedForSubmission = false;
            }
            // else: edge case -- button was clicked, yet status is still unknown.
            // Either the backend validation/translation failed, or
            // claimStatus flag is off in the backend (weird). Show the JSON so
            // we don't block work from getting done.
          }
          return (
            <div className={classes.detailPanel}>
              <div className={classes.leftColumn}>
                {validatedForSubmission ? (
                  <div>
                    <div>
                      <ClaimJsonTextForm
                        rowData={rowData}
                        submitClaim={submitClaim}
                        classes={classes}
                      />
                    </div>
                    <div className={classes.svgButtonGroup}>
                      <RegenerateClaim
                        className={classes.expandedRowButtons}
                        claimId={rowData.claim.id}
                        regenerateClaim={regenerateClaimById}
                        variant="outlined"
                        color="secondary"
                      />
                      <CopyClaimJson
                        className={classes.expandedRowButtons}
                        json={rowData.claim.copyableJson.json}
                        variant="outlined"
                        color="secondary"
                      />
                    </div>
                  </div>
                ) : (
                  <div className={classes.svgButtonGroup}>
                    <GenerateClaim
                      buttonLabel="Generate Claim JSON"
                      generateClaim={translateClaim}
                      setIsGenerated={(val: boolean) =>
                        setIsGenerated({ ...isGenerated, [rowData.claim.id]: val })
                      }
                      claimId={rowData.claim.id}
                      className={classes.expandedRowButtons}
                      color="primary"
                      IconComponent={SendIcon}
                      variant="contained"
                    />
                  </div>
                )}
                <ClaimActionResult
                  errors={claims.apiErrors[rowData.claim.id]}
                  errorClassName={classNames(classes.actionResponse, classes.actionError)}
                  successClassName={classNames(classes.actionResponse, classes.actionSuccess)}
                />
              </div>
              <div className={classes.rightColumn}>
                <ClaimRowDetail rowData={rowData} />
                <div />
                <div className={classes.svgButtonGroup}>
                  <RouteLink
                    route="showPatient"
                    routeParams={[{ id: rowData.claim.patientId }]}
                    className={classes.expandedRowButtons}
                    newWindow
                  >
                    <Button variant="outlined" color="secondary">
                      Overview
                      <AssignmentIndIcon />
                    </Button>
                  </RouteLink>

                  {rowData.type === ClaimType.Visit && (
                    <RouteLink
                      route="showEvent"
                      routeParams={[{ id: rowData.claim.eventId }]}
                      className={classes.expandedRowButtons}
                      newWindow
                    >
                      <Button variant="outlined" color="secondary">
                        Visit Note
                        <Assignment />
                      </Button>
                    </RouteLink>
                  )}

                  {rowData.type === ClaimType.Messaging && (
                    <RouteLink
                      route="showPatient"
                      routeParams={[
                        {
                          id: rowData.claim.patientId,
                          tab: 'conversation',
                        },
                      ]}
                      className={classes.expandedRowButtons}
                      newWindow
                    >
                      <Button variant="outlined" color="secondary">
                        Messages
                        <ChatIcon />
                      </Button>
                    </RouteLink>
                  )}
                </div>
              </div>
            </div>
          );
        }}
        onChangePage={changePageHandler}
        onChangeRowsPerPage={changeRowsPerPageHandler}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        options={{
          header: false,
          toolbar: false,
          paging: true,
          pageSize: list.rowsPerPage || 10,
          rowStyle: () => ({
            background: 'rgba(237,237,237,1)',
          }),
          search: false,
          sorting: false,
          showFirstLastPageButtons: false,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to}',
          },
        }}
      />
    </div>
  );

  function changePageHandler(page) {
    enqueueQueryParamsChange({ page });
  }

  function changeRowsPerPageHandler(rowsPerPage) {
    enqueueQueryParamsChange({ rowsPerPage });
  }

  function enqueueQueryParamsChange(queryParamsToAdd) {
    Object.assign(queryParamsChangeBatch, queryParamsToAdd);
    debouncedQueryParamsChange();
  }

  function getQueryFilterValue(value) {
    if (typeof value === 'string') {
      return value;
    }
    return value?.npi || value?.id || value?.value || value?.key;
  }

  function handleFiltersChange(newValue) {
    const filtersQueryParams = Object.keys(newValue).reduce((result, key) => {
      // eslint-disable-next-line no-param-reassign
      result[key] = Array.isArray(newValue[key])
        ? newValue[key].map(val => getQueryFilterValue(val))
        : getQueryFilterValue(newValue[key]);
      return result;
    }, {});

    updateQueryParams({ ...filtersQueryParams, page: 0 });
  }

  function updateQueryParams(queryParamsChanges) {
    routerStore.goTo(routerState.routeName, {
      params: routerState.params,
      queryParams: {
        ...routerState.queryParams,
        ...queryParamsChanges,
      },
    });
  }
};

const styles: Styles<Theme, any> = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  actionResponse: {
    padding: '1px 15px',
  },
  actionError: {
    backgroundColor: 'rgba(244,112,112,0.1)',
  },
  actionSuccess: {
    backgroundColor: 'rgba(157,210,174,0.2)',
  },
  detailPanel: {
    display: 'flex',
  },
  expandedRowButtons: {
    textDecoration: 'none',
    marginRight: 15,
  },
  svgButtonGroup: {
    paddingBottom: 30,
    '& svg': {
      marginLeft: 5,
    },
  },
  leftColumn: {
    minWidth: 500, // Override flexbox's math
    width: 500,
    padding: 10,
  },
  filtersContainer: {
    paddingBottom: 25,
  },
  rightColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 30,

    '& > div': {
      flex: '50%',
    },
  },
  jsonTextArea: {
    fontSize: 14,
  },
  jsonTextFormContainer: {
    marginBottom: 15,
  },
  jsonErrorField: {
    fontWeight: 'bold',
  },
  status: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '& > svg': {
      marginRight: 4,
    },
  },
  'status-unknown': {
    color: colors.mediumGray,
  },
  'status-pending': {
    color: colors.mediumGray,
  },
  'status-held': {
    color: colors.mediumGray,
  },
  'status-needs-update': {
    color: colors.muiRed,
  },
  'status-submitted': {
    color: colors.taupe,
  },
  'status-rejected': {
    color: colors.muiRed,
  },
});

export default withStyles(styles)(inject('rootStore')(observer(Claims)));

function ClaimJsonTextForm({ rowData, classes, submitClaim }) {
  const [jsonText, setJsonText] = useState(
    JSON.stringify(JSON.parse(rowData.claim.copyableJson.json), null, 4),
  );
  const hasErrors = !!rowData.claim.copyableJson.errors?.length;
  return (
    <Formik
      initialValues={{
        jsonText,
      }}
      validate={values => {
        if (values.jsonText) {
          try {
            JSON.parse(values.jsonText);
          } catch (e) {
            return { jsonText: e.message };
          }
        }
        return {};
      }}
    >
      {() => (
        <div className={classes.jsonTextFormContainer}>
          <FormikEffect
            onChange={current => {
              setJsonText(current.values.jsonText);
            }}
          />
          <Field
            name="jsonText"
            component={TextControl}
            label="Candid Claim JSON"
            multiline
            showValidationOnChange
            InputProps={{
              classes: {
                input: classes.jsonTextArea,
              },
            }}
            rowsMax={25}
          />
          <div className={classes.svgButtonGroup}>
            <ClaimActionButton
              buttonLabel="Submit"
              claimAction={submitClaim}
              claimId={rowData.claim.id}
              claimData={jsonText}
              className={classes.expandedRowButtons}
              disabled={rowData.claim.patientInformation?.isDeceased}
              disabledTooltip={DECEASED_PARTICIPANT_TOOLTIP}
              color="primary"
              IconComponent={SendIcon}
              variant="contained"
            />
          </div>
          {hasErrors && (
            <div>
              <Typography variant="h5">Errors:</Typography>
              {rowData.claim.copyableJson.errors.map(error => (
                <div key={rowData.claim.id + error.fieldDescription} className={classes.jsonError}>
                  <span className={classes.jsonErrorField}>{error.fieldDescription}</span>:{' '}
                  <span className={classes.jsonErrorMessage}>{error.errorMessage}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </Formik>
  );
}

function CopyClaimJson({ json, ...buttonProps }) {
  // This is really similar to the "Copy Link" button in InviteGuestModal

  const [copied, setCopied] = useState(false);

  return (
    <Button onClick={copyClaimJson} disabled={copied} {...buttonProps}>
      {copied ? (
        <>
          <Check />
          <>Copied</>
        </>
      ) : (
        <>
          <>Copy Claim JSON</>
          <FileCopyIcon />
        </>
      )}
    </Button>
  );

  async function copyClaimJson() {
    try {
      // Postman uses 4-space indents by default
      await navigator.clipboard.writeText(JSON.stringify(JSON.parse(json), null, 4));
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    } finally {
      // Enable copying again after 3 seconds
      setTimeout(() => setCopied(false), 3000);
    }
  }
}

function RegenerateClaim({ claimId, regenerateClaim, ...buttonProps }) {
  const [loading, setLoading] = useState(false);

  return (
    <Button onClick={onClick} disabled={loading} {...buttonProps}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <>Regenerate Claim</>
          <RefreshIcon />
        </>
      )}
    </Button>
  );

  async function onClick() {
    try {
      setLoading(true);
      await regenerateClaim(claimId);
    } catch (err) {
      console.error('Failed to regenerate: ', err);
    } finally {
      // This actually happens in milliseconds but it makes the button look
      // broken, and also can accidentally result in (harmless) double-clicks
      setTimeout(() => setLoading(false), 1000);
    }
  }
}

function GenerateClaim({
  buttonLabel,
  generateClaim,
  claimId,
  IconComponent,
  setIsGenerated,
  ...buttonProps
}) {
  const [loading, setLoading] = useState(false);

  return (
    <span>
      <Button onClick={onClick} disabled={loading} {...buttonProps}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <>{buttonLabel}</>
            <IconComponent />
          </>
        )}
      </Button>
    </span>
  );

  async function onClick() {
    try {
      setLoading(true);
      setIsGenerated(true);
      await generateClaim(claimId);
    } catch (err) {
      console.error('Failed to generate: ', err);
    } finally {
      // This actually happens in milliseconds but it makes the button look
      // broken, and also can accidentally result in (harmless) double-clicks
      setTimeout(() => setLoading(false), 1000);
    }
  }
}

function ClaimActionButton({
  buttonLabel,
  claimAction,
  claimId,
  claimData,
  disabled,
  disabledTooltip,
  IconComponent,
  ...buttonProps
}) {
  const [loading, setLoading] = useState(false);

  return (
    <Tooltip title={disabled && disabledTooltip ? disabledTooltip : ''}>
      <span>
        <Button onClick={onClick} disabled={loading || disabled} {...buttonProps}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <>{buttonLabel}</>
              <IconComponent />
            </>
          )}
        </Button>
      </span>
    </Tooltip>
  );

  async function onClick() {
    try {
      setLoading(true);
      await claimAction(claimId, claimData);
    } catch (err) {
      console.error(`Failed to ${buttonLabel}: `, err);
    }
    setLoading(false);
  }
}

function ClaimActionResult({
  errors,
  errorClassName,
  successClassName,
}: {
  errors: ApiError[];
  errorClassName: string;
  successClassName: string;
}) {
  if (errors === undefined) {
    // we don't know the result yet
    return null;
  }

  return errors.length ? (
    <div className={errorClassName}>
      <h4>Submission Response</h4>
      Errors:
      <ol>
        {errors.map(err => {
          const prefix = err.field ? `${err.field}: ` : '';
          return <li key={err.field}>{`${prefix}${err.description}`}</li>;
        })}
      </ol>
    </div>
  ) : (
    <div className={successClassName}>
      <h4>Submission Response</h4>
      <p>Submission successful!</p>
    </div>
  );
}
