"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ifnil = ifnil;
var _isNil = _interopRequireDefault(require("lodash/isNil"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ifnil(value, options) {
  return (0, _isNil.default)(value) ? options.fn(this) : options.inverse(this);
}