import SvgIcon from '@material-ui/core/SvgIcon';
import { createStyles, withStyles } from '@material-ui/core/styles';
import type { ClassNameMap } from '@material-ui/styles';
import React from 'react';

import Tooltip from 'src/components/general/Tooltip';
import { VerificationStatus } from 'src/generated/gql/graphql';
import { ReactComponent as AssistanceRequestedSvg } from 'src/patientVerification/icons/assistance-requested.svg';
import { ReactComponent as AtRiskSvg } from 'src/patientVerification/icons/at-risk.svg';
import { ReactComponent as NotVerifiedSvg } from 'src/patientVerification/icons/not-verified.svg';
import { ReactComponent as UnderReviewSvg } from 'src/patientVerification/icons/under-review.svg';
import { ReactComponent as VerifiedSvg } from 'src/patientVerification/icons/verified.svg';

// @deprecated: used in current patient menu which has been replaced by a new design behind a FF

/** A mapping of verification statuses to their corresponding icons and tooltips */
const verificationIconDefinitions = {
  [VerificationStatus.Verified]: {
    icon: VerifiedSvg,
    tooltip: 'ID verified',
  },
  [VerificationStatus.NotVerified]: {
    icon: NotVerifiedSvg,
    tooltip: 'ID unverified',
  },
  [VerificationStatus.AtRisk]: {
    icon: AtRiskSvg,
    tooltip: 'ID review at risk',
  },
  [VerificationStatus.UnderReview]: {
    icon: UnderReviewSvg,
    tooltip: 'ID under review',
  },
  [VerificationStatus.AssistanceRequested]: {
    icon: AssistanceRequestedSvg,
    tooltip: 'ID assistance requested',
  },
  [VerificationStatus.IdPause]: {
    icon: NotVerifiedSvg,
    tooltip: 'ID pause',
  },
};

const verificationIconStyles = () =>
  createStyles({
    verification: {
      position: 'absolute',
      bottom: 9,
      right: 20,
      width: 31, // the width of the SVG icons imported above
      height: 31, // the height of the SVG icons imported above
    },
  });

/**
 * Wraps the given icon component in a consistently styled SVGIcon container with a tooltip
 */
const VerificationStatusSvg = withStyles(verificationIconStyles)(
  ({
    classes,
    icon,
    testId,
    tooltip,
  }: {
    classes: ClassNameMap;
    icon: React.FunctionComponent;
    testId: string;
    tooltip: string;
  }) => (
    <Tooltip title={tooltip}>
      <SvgIcon className={classes.verification} data-testid={testId} viewBox="0 0 31 31">
        {React.createElement(icon)}
      </SvgIcon>
    </Tooltip>
  ),
);

/**
 * Renders the appropriate icon based on the given verification status
 */
const VerificationStatusIcon = ({ status }: { status?: VerificationStatus | null }) => {
  if (!status) {
    return null;
  }

  const verificationIconDefinition = verificationIconDefinitions[status];
  return (
    <VerificationStatusSvg
      icon={verificationIconDefinition.icon}
      testId={status}
      tooltip={verificationIconDefinition.tooltip}
    />
  );
};

export default VerificationStatusIcon;
